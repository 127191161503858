import React, { useState, useEffect, useRef  } from "react";
import SalaryHistory from './SalaryHistory';
import { BsArrowLeft } from 'react-icons/bs';
import "./Leav.css";
import "react-datepicker/dist/react-datepicker.css";
import "./Leav.css";

import { NavLink, Navigate, useNavigate, useParams } from 'react-router-dom';
import GrantSalary from "./GrantSalary";
export default function Chat() {
  const [value20, setvalue20] = useState("grantSalary");
  const {id} = useParams();
  const {name} = useParams();
  const {department} = useParams();
  const navigate = useNavigate();
  const {state_id} = useParams();
  const {enr_id} = useParams();
  const {department_id} = useParams();

  // alert(department_id);

  const [value, setvalue] = useState(' ')
  // alert(state_id);

  useEffect(() => {
    if (state_id == 2) {
      setvalue20('SalaryHistory');
    }
  }, [state_id]);
  
  // useEffect(() => {
  //   setvalue('SalaryDisbursement')
  // }, []);




  return (
    <>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-2 bg-white sid" style={{ backgroundColor: "#fff", height: "100vh", }}>
            <p className='ms-4  ' style={{ fontWeight: "600", color: "#3C3C3C", fontSize: "25px", marginTop: "40px" }}>  
              <BsArrowLeft onClick={()=>navigate(-1)} className='ps-1' style={{ color: '#000000', fontSize: "25px", cursor: "pointer" }} />
             Salary</p>
            <span className='mt-3' onClick={() => { setvalue20('grantSalary') }}
              style={{ display: "flex", backgroundColor: value20 == "grantSalary" ? "#8147E7" : "", border: "none",  height: "45px", borderRadius: "5px", }}>
              <p className='pt-2' style={{ zIndex: '999', paddingLeft: "10px", color: value20 === "grantSalary" ? "#fff":"black", fontWeight: "400", cursor: "pointer" }}>Grant Salary</p></span>

            <span className='mt-3' onClick={() => { setvalue20('SalaryHistory') }}
              style={{ display: "flex", backgroundColor: value20 == "SalaryHistory" ? "#8147E7" : "",  height: "45px", borderRadius: "5px",  }}>
              <p className='pt-2' style={{ zIndex: '999', paddingLeft: "10px", color: value20 === "SalaryHistory" ? "#fff":"black", fontWeight: "400", cursor: "pointer" }}>Salary History</p></span>

            
          </div>





          {/* <div className="col-10" style={{ background: "rgb(240 245 254)", }}> */}
            {
              value20 === 'grantSalary' ? <><GrantSalary id={id} name={name} department={department} enr_id={enr_id} department_id={department_id} /></> : <></>
            }
            <div className="col-10" style={{ background: "rgb(240 245 254)", }}>

            {
              value20 === 'SalaryHistory' ? <><SalaryHistory /></> : <></>
            }
            </div>
          {/* </div> */}
        </div>



      </div>
    </>
  );
}