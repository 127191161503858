import React,{useEffect,useState}from 'react'
import Lottie from 'lottie-react'
import A2 from '../images/paa.json';
import '../App.css'
import { API_URL } from "../env";
import { ToastContainer, toast } from "react-toastify";





function ChangePassword() {

const [c_password, setC_password] = useState()
const [password, setPassword] = useState()


const password_update= async()=>{
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

  
  var raw = JSON.stringify({
    "password": password,
    "c_password": c_password
  });
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  fetch(`${API_URL}/admin/profile/change/password`, requestOptions)
    .then(response => response.json())
    .then(result => {console.log(result)
    if(result.status == 1){
      setPassword('')
      setC_password('')
      toast(result.msg);
    }else{
      toast(result.msg);
    }
    })
    .catch(error => console.log('error', error));
}


  return (
    
    <>
      <ToastContainer />
      <div className='container card-sadows mt-5 ' style={{borderRadius:"10px"}} >
      <div className='row mt-5' >

      <div className='col-6'   >
        <div style={{borderRight:"1px solid black"}}>

      <Lottie animationData={A2} style={{width:"50%",height:"50%"}} />
        </div>

      </div>
      <div className='col-6 mt-5 px-5' >
        <h2>Change Password</h2>
      <div class="form-group">
    <label for="exampleInputPassword1">Password</label>
    <input type="password"
     class="form-control"
      id="exampleInputPassword1"
       placeholder="Password"
        value={password}
        onChange={(e) => {
                            setPassword(e.target.value);
                          }}

        />
  </div>
      <div class="form-group mt-4">
    <label for="exampleInputPassword1">Confirm Password</label>
    <input
     type="password"
      class="form-control" 
      id="exampleInputPassword1"
       placeholder="Password"
        value={c_password}
        onChange={(e) => {
                            setC_password(e.target.value);
                          }}
        />
  </div>
  <button type="submit" class="btn btn-primary mt-4 mb-2 " onClick={()=>password_update()}>Submit</button>
      </div>
      </div>
    </div>
    </>
  )
}

export default ChangePassword