import React, { createContext,useEffect,useState } from 'react';
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import { isFocusable } from '@testing-library/user-event/dist/utils';
// import { API_URL } from './MyComponent';


// const {API_URL,img_url} =env;

import { API_URL,img_url } from '../env';
//import { useNavigate } from 'react-router-dom';


const AuthContext = createContext();

const initProfile = {
  name: "",
  banner: "",
  email:"",
  phone:"",
  address:""
}


const initToken = localStorage.getItem("token") || "";


const AuthProvider = ({ children }) => {
  // const [state,dispatch] = useReducer(profileReducer,initProfile)
  const [apiToken, setApiToken] = useState(initToken);
  const [profile, setProfile] = useState(initProfile);
  const [ShowLoader, setShowLoader] = useState(false);
  const [message, setmessage] = useState();
  const [Adname, setAdname] = useState(localStorage.getItem("userName"));
  //const navigate = useNavigate();

  // new
  const [stuPayLoader, setStuPayLoader] = useState(false);
  const [value, setvalue] = useState("Missed");
  



  const AdminName = async () => {
    console.log('token', apiToken);
    var myHeaders = new Headers();
    myHeaders.append("authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/loginUserdetail`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        if (result.status == "001") {
          setAdname(result.profile[0].name);
        }
      })
      .catch(error => console.log('error', error));

  }


  const profileGet = async () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    await fetch(`${API_URL}/preferences_get`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status == "001") {
          //  console.log(result,'ghvhjnj');
          const { logo } = result;
          // console.log('lg',logo);
          setProfile({
            ...profile,
            name: logo.institute_name,
            banner: logo.logo,
            addresss:logo.address,
            phone_no:logo.phone,
            email_id:logo.email
          })

         
        }
        else{
          console.log('error',result);
        }

      })
      .catch(error => console.log('error', error));

  }

  const profileUpdater = async(updatedProfile) => {
    
    // console.log('inside context', updatedProfile);
    var formdata = new FormData();
    Object.entries(updatedProfile).forEach(([key, value]) => formdata.append(key, value))
    // formdata.append("institute name", "tipsg");
    // formdata.append("logo", fileInput.files[0], "/C:/Users/faizy/Downloads/wallpaper/500-PNG.png");
    formdata.append("preferences_id", "1");


    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${API_URL}/preferences_update`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result);
        if (result.status == 1) {

          // setProfile({
          //   ...updatedProfile
          // })
          profileGet();
        } else {
          // console.log(result.message);
          setShowLoader(true);
          setmessage(result.message);
        }
      }
      )
      .catch(error => console.log('error', error));
  }

  const login = (token) => {
    console.log(token,'tokn')
    localStorage.setItem("token", token);
    setApiToken(token);
    
  };

  const logout = () => {
    localStorage.removeItem('api_url');
    setApiToken('');
  };




  useEffect(() => {
    // profileGet();
    ///Checktoken();
  }, [])
  

  return (
    <>
      <AuthContext.Provider value={{ apiToken, login, logout, profile, profileGet, profileUpdater,AdminName,Adname, stuPayLoader, setStuPayLoader , value , setvalue }}>  
        {children}
      </AuthContext.Provider>


      <ToastContainer position="top-end" className="p-3" >
        <Toast onClose={() => setShowLoader(false)} show={ShowLoader} delay={8000} autohide style={{ backgroundColor: "#000" }}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">error</strong>
            <small>1 mins ago</small>
          </Toast.Header>
          <Toast.Body className="text-white">{message}</Toast.Body>
        </Toast>
      </ToastContainer>


    </>

  );
};

export { AuthContext, AuthProvider };
