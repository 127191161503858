import React, { useState, useEffect } from 'react';
import Header from './header';
import SideBar from './sidebar';
import Chart from "react-apexcharts";
import { FiDownloadCloud, FiCamera, FiMail, FiPhone, FiCalendar } from 'react-icons/fi';
import { Clock } from './svgicon';
import Table from 'react-bootstrap/Table';
// import IOI from '../images/iconu.png';
import { AiOutlineLink } from 'react-icons/ai';
import { NavLink ,useNavigate} from 'react-router-dom';
import { BiCalendar } from 'react-icons/bi';
import { IoEyeOutline } from "react-icons/io5";
import { AuthContext } from './AuthContext';
import { useContext } from 'react';
// import { API_URL } from './MyComponent';
import { useRequireAuth } from './RequireAuth';
import { API_URL,img_url } from '../env';


let marksgraph = {
  options: {
    colors: ['#85FFDA'],
    chart: {
      id: "bar"
    },
    stroke: {
      colors: ['#40997E']
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [2022, 2023]
    },
  },
  series: [
    {
      name: "series-1",
      data: [0,0]
    }
  ]
};

let tabledata = [
  {
    id: '20BHI10035',
    name: 'Himanshu Rajwat',
    remark: 'Attendance'
  },
  {
    id: '20BHI10035',
    name: 'Himanshu Rajwat',
    remark: 'Attendance'
  },
  {
    id: '20BHI10035',
    name: 'Himanshu Rajwat',
    remark: 'Attendance'
  },
  {
    id: '20BHI10035',
    name: 'Himanshu Rajwat',
    remark: 'Attendance'
  },
  {
    id: '20BHI10035',
    name: 'Himanshu Rajwat',
    remark: 'Attendance'
  },
  {
    id: '20BHI10035',
    name: 'Himanshu Rajwat',
    remark: 'Attendance'
  },
  {
    id: '20BHI10035',
    name: 'Himanshu Rajwat',
    remark: 'Attendance'
  },
  {
    id: '20BHI10035',
    name: 'Himanshu Rajwat',
    remark: 'Attendance'
  },
  {
    id: '20BHI10035',
    name: 'Himanshu Rajwat',
    remark: 'Attendance'
  },
  {
    id: '20BHI10035',
    name: 'Himanshu Rajwat',
    remark: 'Attendance'
  },
  {
    id: '20BHI10035',
    name: 'Himanshu Rajwat',
    remark: 'Attendance'
  },
  {
    id: '20BHI10035',
    name: 'Himanshu Rajwat',
    remark: 'Attendance'
  },
  {
    id: '20BHI10035',
    name: 'Himanshu Rajwat',
    remark: 'Attendance'
  },
  {
    id: '20BHI10035',
    name: 'Himanshu Rajwat',
    remark: 'Attendance'
  },
  {
    id: '20BHI10035',
    name: 'Himanshu Rajwat',
    remark: 'Attendance'
  },
]

// const potteer = [
//   {
//     cont: 'Gajanand ',
//     cont1: 'devloper',
//     cont2: '14 mar 2022',
//     IOI: 'iconu.png',

//   },
//   {
//     cont: 'Gajanand ',
//     cont1: 'devloper',
//     cont2: '14 mar 2022',
//     IOI: 'iconu.png'

//   },
//   {
//     cont: 'Gajanand ',
//     cont1: 'devloper',
//     cont2: '14 mar 2022',
//     IOI: 'iconu.png'
//   },
//   {
//     cont: 'Gajanand ',
//     cont1: 'devloper',
//     cont2: '14 mar 2022',
//     IOI: 'iconu.png'

//   },
//   {
//     cont: 'Gajanand ',
//     cont1: 'devloper',
//     cont2: '14 mar 2022',
//     IOI: 'iconu.png'

//   },
//   {
//     cont: 'Gajanand ',
//     cont1: 'devloper',
//     cont2: '14 mar 2022',
//     IOI: 'iconu.png'

//   },


// ]

const Sidata = [

  {
    image: require("../images/iconu.png"),
    name: 'poter',
    id: "20BH10035",
    course: "bca",
    join: "2022",
  },
  {
    image: require("../images/iconu.png"),
    name: 'ricky',
    id: "20BH10035",
    course: "bca",
    join: "2022"
  },
  {
    image: require("../images/iconu.png"),
    name: 'ricky',
    id: "20BH10035",
    course: "bca",
    join: "2022"
  },
  {
    image: require("../images/iconu.png"),
    name: 'ricky',
    id: "20BH10035",
    course: "bca",
    join: "2022"
  },
  {
    image: require("../images/iconu.png"),
    name: 'ricky',
    id: "20BH10035",
    course: "bca",
    join: "2022"
  },
  {
    image: require("../images/iconu.png"),
    name: 'ricky',
    id: "20BH10035",
    course: "bca",
    join: "2022"
  },
  {
    image: require("../images/iconu.png"),
    name: 'ricky',
    id: "20BH10035",
    course: "bca",
    join: "2022"
  },
  {
    image: require("../images/iconu.png"),
    name: 'ricky',
    id: "20BH10035",
    course: "bca",
    join: "2022"
  },
  {
    image: require("../images/iconu.png"),
    name: 'ricky',
    id: "20BH10035",
    course: "bca",
    join: "2022"
  },
  {
    image: require("../images/iconu.png"),
    name: 'ricky',
    id: "20BH10035",
    course: "bca",
    join: "2022"
  },
  {
    image: require("../images/iconu.png"),
    name: 'ricky',
    id: "20BH10035",
    course: "bca",
    join: "2022"
  },
]


export default function Students() {
  const [data, setData] = useState(Sidata)
  const [tbldata, setTbldata] = useState(tabledata)
  const [marks, setMarks] = useState(marksgraph)

  const [state, setState] = useState(0);
  const [count1, setcount1] = useState(1)
  const [valuee, setvalue] = useState(' ')
  const [duedate, setDuedate] = useState(0)
  const [tabled, setTabled] = useState(0)
  const [Catg, setCatg] = useState("");
  const { apiToken } = useContext(AuthContext);
  const [Active, setActive] = useState('');
  const [drop, setdrop] = useState('');
  const [total, settotal] = useState('');

  const Navigate = useNavigate();

  //useRequireAuth('/code');


  const ActiveApi = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/student/countActive_students`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
      
        if (result.status == "001") {
          setActive(result.active_students);


        }

      })
      .catch(error => console.log('error', error));
  }


  const dropApi = () => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/student/countDropouts_students`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        if (result.status == "001") {
          setdrop(result.dropouts_students);


        }
      })
      .catch(error => console.log('error', error));

  }


  const Total = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/student/countTotal_students`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        if (result.status == "001") {
          settotal(result.total_student);

        }
      })
      .catch(error => console.log('error', error));

  }

  useEffect(() => {
    ActiveApi();
    dropApi();
    Total();


  }, [])


  return (
    <div style={{ width: '100%', height: '100vh', }}>
      <div className='head' style={{ width: '80%', height: '10vh', marginLeft: '16%' }}>
        <Header />
      </div>
      <div style={{ width: '100%', height: '90vh', display: "flex", }}>
        <div style={{ width: '20%', height: '100%', position: 'absolute', top: 0, marginLeft: '1%' }} >
          <SideBar />
        </div>
        <div className='maincontain' style={{ marginLeft: '15%', width: '100%', height: '100%', overflowY: 'scroll', }} >



          {/* add student */}
          <div>
            <div className='text-end px-5 mt-4'>
              {/* <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight1" aria-controls="offcanvasRight"
  style={{border:"1px solid #8147E7",color:"white",width:"150px",height:"45px",borderRadius:"5px"}} >+ Add Student</button> */}
              {/* data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight1" aria-controls="offcanvasRight" */}
              <NavLink to="/addExcel"><button className='me-3' style={{ backgroundColor: "#8147E7", color: "white", border: "none", width: "150px", height: "45px", borderRadius: "5px" }} >+ Add Excel</button></NavLink>
              <NavLink to="/addstudents"><button style={{ backgroundColor: "#8147E7", color: "white", border: "none", width: "150px", height: "45px", borderRadius: "5px" }} >+ Add Student</button></NavLink>
            </div>

            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight1" aria-labelledby="offcanvasRightLabel" style={{ width: "40%" }}>
              <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasRightLabel"></h5>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div class="offcanvas-body">
                {/* Add student*/}
                <div style={{ display: state == 0 ? "block" : "none" }}>

                  <h3 className='' style={{}}>Add Student</h3>
                  <div >

                    <p className='mt-3 ' style={{ color: "#BDBDBD", fontSize: "15px" }}>Enter Name</p>
                    <input type="text" className="form-control" placeholder="naramurthi@gmail.com" style={{ paddingLeft: "20px", width: "90%", height: "32px" }} />

                    <p className='mt-3'>Enter Address</p>
                    <input type="text" placeholder='101-103 Chanda Tower, Opp. Grand Utsave Marriage Garden, Main Gandhi Path Road, Vaishali Nagar,
Jaipur , Rajasthan - 302021 India'className=' form-control px-2' style={{ width: "90%", height: "70px" }} />

                    <p className='mt-3'>Enter Adhar number</p>
                    <input type="text" className="form-control" placeholder="Number.." style={{ paddingLeft: "20px", width: "90%", height: "32px" }} />

                    <div className='row mt-3' >
                      <p>Upload Adhar photo</p>
                      <div className='col-3 mt-2 '>

                        <div class='file'>

                          <label for='input-file' >
                            <FiCamera style={{ color: "#BDBDBD" }} />&nbsp;&nbsp;<span style={{ color: "#BDBDBD" }}>front</span>
                          </label>
                          <input id='input-file' type='file' />
                        </div>
                      </div>

                      <div className='col-4 mt-2 mx-3'>
                        <div class='file'>

                          <label for='input-file' className='' >
                            <FiCamera style={{ color: "#BDBDBD" }} />&nbsp;&nbsp;<span style={{ color: "#BDBDBD" }}>Back</span>
                          </label>
                          <input id='input-file' type='file' />
                        </div>
                      </div>
                    </div>


                    <p className='mt-3'>Enter PAN Nmber (OPTIONAL)</p>
                    <input type="text" className="form-control" placeholder="Number..." style={{ paddingLeft: "20px", width: "90%", height: "32px" }} />

                    <div className='row  mt-3'>
                      <p>Upload PAN photo(OPTIONAL)</p>
                      <div className='col-3 mt-2'>
                        <div class='file'>

                          <label for='input-file' >
                            <FiCamera style={{ color: "#BDBDBD" }} />&nbsp;&nbsp;<span style={{ color: "#BDBDBD" }}>front</span>
                          </label>
                          <input id='input-file' type='file' />
                        </div>
                      </div>

                      <div className='col-4 mt-2  mx-3'>
                        <div class='file'>

                          <label for='input-file'>
                            <FiCamera style={{ color: "#BDBDBD" }} />&nbsp;&nbsp;<span style={{ color: "#BDBDBD" }}>Back</span>
                          </label>
                          <input id='input-file' type='file' />
                        </div>
                      </div>
                    </div>


                    <p className='mt-3'>Enter Email</p>
                    <input type="text" className="form-control" placeholder="Naramurti@gmail.com" style={{ paddingLeft: "40px", width: "90%", height: "32px" }} />
                    <FiMail style={{ marginTop: "-63px", marginLeft: "10px", color: "black" }} />


                    <p className='mt-3'>Enter Contact Number</p>
                    <input type="text" className="form-control" placeholder="985236565564" style={{ paddingLeft: "40px", width: "90%", height: "32px" }} />
                    < FiPhone style={{ marginTop: "-63px", marginLeft: "10px", color: "black" }} />


                    {/* accedmi details */}

                    <div>
                      <p className='mt-4' style={{ fontSize: "20px", fontWeight: "600" }}>Academic Details</p>
                      <p className='mt-3'>Enter 12th Marks</p>
                      <input type="text" className="form-control" placeholder="Enter Precentage" style={{ paddingLeft: "20px", width: "90%", height: "32px" }} />

                      <p className='mt-3'>Enter 10th Marks</p>
                      <input type="text" className="form-control" placeholder="Precentage" style={{ paddingLeft: "20px", width: "90%", height: "32px" }} />

                      <p className='mt-3'>Enter Enrollment ID</p>
                      <input type="text" className="form-control" placeholder="Enter ID" style={{ paddingLeft: "20px", width: "90%", height: "32px" }} />

                      <p className='mt-3'>Upload 12th Marksheet</p>
                      <div class='file'>
                        <label for='input-file'>
                          <FiCamera style={{ color: "#BDBDBD" }} />&nbsp;&nbsp;<span style={{ color: "#BDBDBD" }}>front</span>
                        </label>
                        <input id='input-file' type='file' />
                      </div>

                      <p className='mt-3'>Upload 10th Marksheet</p>
                      <div class='file'>
                        <label for='input-file'>
                          <FiCamera style={{ color: "#BDBDBD" }} />&nbsp;&nbsp;<span style={{ color: "#BDBDBD" }}>front</span>
                        </label>
                        <input id='input-file' type='file' />
                      </div>

                    </div>

                    {/* parents gradution detils */}
                    <p className='mt-4' style={{ fontSize: "20px", fontWeight: "600" }}>Parents /Guardiam Details</p>

                    <p className='mt-3'>Enter Fathers Name</p>
                    <input type="text" className="form-control" placeholder="Enter Name" style={{ paddingLeft: "20px", width: "90%", height: "32px" }} />

                    <p className='mt-3'>Enter Mothers Names</p>
                    <input type="text" className="form-control" placeholder="Enter Name" style={{ paddingLeft: "20px", width: "90%", height: "32px" }} />

                    <p className='mt-3'>Enter Adhar number of guardian</p>
                    <input type="text" className="form-control" placeholder="Enter Number" style={{ paddingLeft: "20px", width: "90%", height: "32px" }} />

                    <div className='row  mt-3'>
                      <p>Upload PAN photo(OPTIONAL)</p>
                      <div className='col-3 mt-2'>
                        <div class='file'>

                          <label for='input-file' >
                            <FiCamera style={{ color: "#BDBDBD" }} />&nbsp;&nbsp;<span style={{ color: "#BDBDBD" }}>front</span>
                          </label>
                          <input id='input-file' type='file' />
                        </div>
                      </div>

                      <div className='col-4 mt-2  mx-3'>
                        <div class='file'>

                          <label for='input-file'>
                            <FiCamera style={{ color: "#BDBDBD" }} />&nbsp;&nbsp;<span style={{ color: "#BDBDBD" }}>Back</span>
                          </label>
                          <input id='input-file' type='file' />
                        </div>
                      </div>
                    </div>

                    <p className='mt-3'>Enter Date of birth of guardian</p>
                    <input type="type" className="form-control" placeholder="DD/MM/YY" style={{ paddingLeft: "20px", width: "90%", height: "32px" }} />
                    <p className='mt-3'>Enter Bank account number</p>
                    <input type="text" className="form-control" placeholder="Number.." style={{ paddingLeft: "20px", width: "90%", height: "32px" }} />

                    <p className='mt-3'>Enter IFSC number</p>
                    <input type="text" className="form-control" placeholder="Number.." style={{ paddingLeft: "20px", width: "90%", height: "32px" }} />

                    <div className='mt-5'>
                      <button onClick={() => { setState(state + 1) }} style={{
                        marginLeft: "auto", marginRight: "auto", display: "block", backgroundColor: "#5A81FE", color: "white",
                        width: "300px", border: "none", height: "40px", borderRadius: "5px"
                      }}> Proceed</button>
                    </div>
                  </div>
                </div>


                {/* setup Payment */}

                <div style={{ display: state == 1 ? "block" : "none" }}>
                  <h3 className='' style={{}}>Setup Payment</h3>
                  <div>
                    <div className='row'>
                      <div className='col-3'>
                        <div class="form-check">
                          <input class="form-check-input" onClick={() => setDuedate(duedate + 1)} type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                          <label class="form-check-label" for="flexRadioDefault1">
                            Pay in EMIs
                          </label>
                        </div>
                      </div>

                      <div className='col-4'>
                        <div class="form-check">
                          <input class="form-check-input" onClick={() => setDuedate(duedate + 1)} type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                          <label class="form-check-label" for="flexRadioDefault1">
                            Pay in One Shot
                          </label>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className='row' >
                        <div className='col-3' style={{ display: duedate == 0 ? "block" : "none" }} >
                          {/*  */}
                          <p style={{ marginTop: "20px" }}>EMI Count</p>
                          <p className='p-2' style={{
                            borderRadius: "10px", width: "150px", height: "45px", backgroundColor: "#E0E0E0"
                            , textAlign: "center", paddingTop: "55px", color: "#7A7A7A"
                          }}>Number of EMIs</p>
                        </div>

                        <div className='col-3 mx-3'>
                          <div >
                            <p style={{ marginTop: "20px" }}>Due Date</p>
                            <p className='pt-2 p-2' style={{
                              borderRadius: "10px", width: "150px", height: "45px",
                              backgroundColor: "#E0E0E0", textAlign: "center", color: "#7A7A7A"
                            }}><FiCalendar />&nbsp;12 Nov 2022</p>
                          </div>
                        </div>

                        <div className='col-3 mt-5'>
                          <button onClick={() => setTabled(tabled + 1)} className='btn btn-outline-dark mx-5' >OK</button>
                          {/*  */}
                        </div>
                      </div>
                    </div>

                    <div style={{ display: tabled == 0 ? "none" : "" }}>
                      <p className='mt-3'>Payment Schedule</p>

                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Remarks</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Due Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">1</th>
                            <td>Hostel Fees</td>
                            <td>₹ 49,965</td>
                            <td>12/11/2022</td>
                          </tr>

                        </tbody>
                      </table>

                    </div>

                    <div className='row mt-5' >
                      <div className='col-6'>
                        <p>Grand Total:₹ 3,99,725</p>
                      </div>


                    </div>
                  </div>
                  <div className='mt-5'>
                    <button onClick={() => { setState(state + 1) }} style={{
                      marginLeft: "auto", marginRight: "auto", display: "block", backgroundColor: "#5A81FE", color: "white",
                      width: "300px", border: "none", height: "40px", borderRadius: "5px"
                    }}>Proceed To Payment</button>
                  </div>

                </div>


                {/* Previsive*/}
                <div style={{ display: state == 2 ? "block" : "none" }}>
                  <h3 className='' style={{}}>Preview</h3>

                  <div>
                    <p>From</p>
                    <div className='row'>
                      <div className='col-6'>
                        <p style={{ fontSize: "22px" }}>RNS IT SOLUTIONS</p>
                        <p style={{ fontSize: "15px" }}>101-201-301, Chanda Tower, Girnar Colony, Gandhi Path Road, Vaishali Nagar, Jaipur - 302021</p>
                        <p className='mt-2' style={{ fontSize: "15px" }}>+91 702 334 0831</p>

                        <p className='mt-3'><b>To</b></p>
                        <p className=""><b>Narayan murthy</b></p>
                        <p className='mt-3' style={{ fontSize: "15px" }}>101-201-301, Chanda Tower, Girnar Colony, Gandhi Path Road, Vaishali Nagar, Jaipur - 302021</p>
                        <p className='mt-3' style={{ fontSize: "15px" }}>+91 702 334 0831</p>
                      </div>

                      <div className='col-6 ' >
                        <p style={{ fontSize: "22px", fontWeight: "500", paddingLeft: "100px" }}>Due Date</p>
                        <p style={{ paddingLeft: "100px" }}><BiCalendar />&nbsp;&nbsp;24 Feb 2023</p>

                        <p className='pt-3 mt-3 p-1' style={{ border: "2px solid #E0E0E0", width: "150px", marginLeft: "100px", height: "90px", fontSize: "14px" }}>Amount to be paid<br />₹ 1,59,963</p>
                      </div>
                    </div>

                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">S.No</th>
                          <th scope="col">Remarks</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Due Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Hostel Fees</td>
                          <td>₹ 49,965</td>
                          <td>12/11/2022</td>
                        </tr>

                      </tbody>
                    </table>

                    <div className='row mt-5' >
                      <div className='col-6'>
                        <p>Grand Total:₹ 3,99,725</p>
                      </div>
                      <div className='col-6'>
                        <div className='mt'>
                          <button onClick={() => { setState(state + 1) }} style={{
                            marginLeft: "auto", marginRight: "auto", display: "block", backgroundColor: "#5A81FE", color: "white",
                            width: "200px", border: "none", height: "40px", borderRadius: "5px"
                          }}>Proceed To Payment</button>
                        </div>
                      </div>


                    </div>
                  </div>

                </div>


                {/* payment link */}

                <div style={{ display: state == 3 ? "block" : "none" }}>
                  <div>
                    <div>
                      <p style={{ fontSize: "25px", fontWeight: "600" }}>Payment Link Sent to Narayanmurthy Successfully</p>
                    </div>


                    <div className='mt-3'>
                      <p>payment Link</p>
                      <input type="text" className='from-control px-3' placeholder="https://easebuzz.in//payment_id%20%manipal%univ." style={{
                        paddingLeft: "0px", width: "80%"
                        , height: "32px", borderRadius: "5px", border: "1px solid #D7D7D7"
                      }} />
                      <span style={{ marginLeft: "-18px" }}><AiOutlineLink /></span>
                    </div>

                    <div className='mt-4'>
                      <p>Remarks</p>
                      <div>
                        <input type="text" maxLength="100" className="" placeholder="Start typing task description..." value1={1} style={{
                          width: "80%", marginTop: "20px", height: "40px", paddingLeft: "10px", borderRadius: "5px", border: "1px solid #D7D7D7"
                        }} onChange={(e) => { setvalue(e.target.value) }} /></div>
                      <p style={{ paddingLeft: "430px", fontSize: "10px" }} >{valuee.length}/200</p>

                    </div>

                    <div className='mt-3'>
                      <p style={{ fontSize: "20px" }}>Contact Number</p>
                      <p>9874563210</p>
                    </div>

                    <div className='mt-3'>
                      <p style={{ fontSize: "20px", fontWeight: "600" }}>Due Date</p>
                      <p>22-03-2023 &nbsp;<BiCalendar /> </p>
                    </div>

                    <div className='mt-4 pt-2' style={{ border: "2px solid #E0E0E0", width: "170px", textAlign: "center", height: "80px" }}>
                      <p>Amount to be paid<br /> ₹1,59,963</p>
                    </div>

                    <div className='mt-5'>
                      <button style={{
                        marginLeft: "auto", marginRight: "auto", display: "block", backgroundColor: "#5A81FE", color: "white",
                        width: "300px", border: "none", height: "40px", borderRadius: "5px"
                      }}>Back To Dashboard</button>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div className='row mx-4 mt-3'>
            <div className='col-7'>
              <div className='card rounded-0 mb-2' style={{ borderColor: '#E4E2F1' }}>
                <div className='row mt-1 m-1'>
                  <p className='text-start py-2' style={{ color: '#54536E', fontSize: '1.5em' }}>Average Marks</p>
                  <div className='col-8 d-flex justify-content-start'>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 25, height: 25, }}>
                      <Clock />
                    </button>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 25, height: 25, fontSize: '0.8em' }}>
                      W
                    </button>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 25, height: 25, fontSize: '0.8em' }}>
                      M
                    </button>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 25, height: 25, fontSize: '0.8em' }}>
                      Y
                    </button>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 120, height: 25, fontSize: '0.8em' }}>
                      Custom Time
                    </button>
                  </div>
                 {/* <div className='col-4'>
                    <button type='button' className='mx-1 justify-content-start align-items-center d-flex download-chart-btn px-2' style={{ width: 150, height: 25, fontSize: '0.8em' }}>
                      Download Chart<span className='px-2'><FiDownloadCloud /></span>
                    </button>
                    </div>*/}
                </div>
                <div className="app">
                  <div className="row">
                    <div className="mixed-chart">
                      <Chart
                        options={marks.options}
                        series={marks.series}
                        color={marks.colors}
                        type="area"
                        width="100%"
                        height="300"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-5'>
              <div className='card rounded-0' style={{ borderColor: '#E4E2F1' }}>
                <div className='row g-0 p-2' style={{ borderBottom: '1px solid #E4E2F1' }}>
                  <p className='col-8 text-start' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "18px", color: "#54536E", }}>Defaulters</p>
                  <button type='button' className='col-4 border-0 rounded-1 mx-auto' style={{ width: 120, height: 40, backgroundColor: '#E4E2F1', color: '#54536E' }}>View Details</button>
                </div>
                <div className='bar7'>
                  <Table >
                    {/* striped bordered hover */}
                    <thead>
                      <tr>
                        <th className='' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#54536E", borderRight: "1px solid #E4E2F1", }}><span className='d-flex justify-content-between'>ID<button type='button' className='border-0 bg-transparent'><svg xmlns="http://www.w3.org/2000/svg" width="30" height="15" viewBox="0 0 34.578 17">
                          <g id="Group_61" data-name="Group 61" transform="translate(-1431.5 -445)" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#54536E", }}>
                            <g id="Group_59" data-name="Group 59" transform="translate(-10 6.5)">
                              <line id="Line_58" data-name="Line 58" x2="17" transform="translate(1442.5 439.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                              <line id="Line_59" data-name="Line 59" x2="11" transform="translate(1448.5 444.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                              <line id="Line_60" data-name="Line 60" x2="7" transform="translate(1452.5 449.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                              <line id="Line_61" data-name="Line 61" x2="5" transform="translate(1454.5 454.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                            </g>
                            <g id="Group_60" data-name="Group 60" transform="translate(0.413 0)">
                              <path id="Icon_awesome-caret-up" data-name="Icon awesome-caret-up" d="M11.216,19.228H1.547a.751.751,0,0,1-.531-1.283L5.85,13.111a.751.751,0,0,1,1.063,0l4.834,4.834A.751.751,0,0,1,11.216,19.228Z" transform="translate(1453.697 433.109)" fill="#6e6b97" />
                              <path id="Icon_awesome-caret-up-2" data-name="Icon awesome-caret-up" d="M10.421,6.337H.753A.751.751,0,0,1,.222,5.054L5.056.22a.751.751,0,0,1,1.063,0l4.834,4.834A.751.751,0,0,1,10.421,6.337Z" transform="translate(1465.665 461) rotate(180)" fill="#6e6b97" />
                            </g>
                          </g>
                        </svg>
                        </button>
                        </span>
                        </th>
                        <th className='' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#54536E", borderRight: "1px solid #E4E2F1", }}><span className='d-flex justify-content-between'>Name<button type='button' className='border-0 bg-transparent'><svg xmlns="http://www.w3.org/2000/svg" width="30" height="15" viewBox="0 0 34.578 17">
                          <g id="Group_61" data-name="Group 61" transform="translate(-1431.5 -445)" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#54536E", }}>
                            <g id="Group_59" data-name="Group 59" transform="translate(-10 6.5)">
                              <line id="Line_58" data-name="Line 58" x2="17" transform="translate(1442.5 439.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                              <line id="Line_59" data-name="Line 59" x2="11" transform="translate(1448.5 444.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                              <line id="Line_60" data-name="Line 60" x2="7" transform="translate(1452.5 449.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                              <line id="Line_61" data-name="Line 61" x2="5" transform="translate(1454.5 454.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                            </g>
                            <g id="Group_60" data-name="Group 60" transform="translate(0.413 0)">
                              <path id="Icon_awesome-caret-up" data-name="Icon awesome-caret-up" d="M11.216,19.228H1.547a.751.751,0,0,1-.531-1.283L5.85,13.111a.751.751,0,0,1,1.063,0l4.834,4.834A.751.751,0,0,1,11.216,19.228Z" transform="translate(1453.697 433.109)" fill="#6e6b97" />
                              <path id="Icon_awesome-caret-up-2" data-name="Icon awesome-caret-up" d="M10.421,6.337H.753A.751.751,0,0,1,.222,5.054L5.056.22a.751.751,0,0,1,1.063,0l4.834,4.834A.751.751,0,0,1,10.421,6.337Z" transform="translate(1465.665 461) rotate(180)" fill="#6e6b97" />
                            </g>
                          </g>
                        </svg>
                        </button>
                        </span>
                        </th>
                        <th className='' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#54536E", borderRight: "1px solid #E4E2F1", }}><span className='d-flex justify-content-between'>Remark<button type='button' className='border-0 bg-transparent'><svg xmlns="http://www.w3.org/2000/svg" width="30" height="15" viewBox="0 0 34.578 17">
                          {/* <g id="Group_61" data-name="Group 61" transform="translate(-1431.5 -445)" style={{fontStyle:"normal",fontWeight:"400",fontSize:"16px",color:"#54536E",}}>
                            <g id="Group_59" data-name="Group 59" transform="translate(-10 6.5)">
                              <line id="Line_58" data-name="Line 58" x2="17" transform="translate(1442.5 439.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                              <line id="Line_59" data-name="Line 59" x2="11" transform="translate(1448.5 444.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                              <line id="Line_60" data-name="Line 60" x2="7" transform="translate(1452.5 449.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                              <line id="Line_61" data-name="Line 61" x2="5" transform="translate(1454.5 454.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                            </g>
                            <g id="Group_60" data-name="Group 60" transform="translate(0.413 0)">
                              <path id="Icon_awesome-caret-up" data-name="Icon awesome-caret-up" d="M11.216,19.228H1.547a.751.751,0,0,1-.531-1.283L5.85,13.111a.751.751,0,0,1,1.063,0l4.834,4.834A.751.751,0,0,1,11.216,19.228Z" transform="translate(1453.697 433.109)" fill="#6e6b97" />
                              <path id="Icon_awesome-caret-up-2" data-name="Icon awesome-caret-up" d="M10.421,6.337H.753A.751.751,0,0,1,.222,5.054L5.056.22a.751.751,0,0,1,1.063,0l4.834,4.834A.751.751,0,0,1,10.421,6.337Z" transform="translate(1465.665 461) rotate(180)" fill="#6e6b97" />
                            </g>
                          </g> */}
                        </svg>
                        </button>
                        </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      
                      {/* {
                        tbldata.map((item, index) => (
                          <BasicExample key={index} item={item} />
                        ))
                      } */}
                    </tbody>
                 
                  </Table>
                  <div className='text-end mt-5'>
                    <h3 className='text-center' style={{color:"green"}}>Coming Soon...</h3>
                    </div>
                </div>
              </div>
            </div>
          </div>

          {/* card */}
          <div className='row mt-4 mb-4 mx-4'>
            <div className='col-4'>
              <div className='card rounded-0' style={{ borderColor: '#E4E2F1' }}>
                <div className='row mt-3'>
                  <div className='col-6'>
                    <p className='text-start px-2' style={{ color: '#6E6B97' }}>Active Students</p>

                    <p className='text-start fs-3 p-2' style={{ color: '#40997E' }}>{Active.length==0?'0':Active}</p>
                  </div>
                  <div className='col-6'>
                    <NavLink to="/studentviewlist/1"><button type='button' className='' style={{ width: 120, height: 30, backgroundColor: '#fff', color: '#8147E7', border: '1px solid #8147E7', }}>View List</button></NavLink>
                    <div className='  border-0 mb-1'>
                      <p className='' style={{ color: '#40997E' }}>+ 0%</p>
                      <p className='' style={{ color: '#6E6B97', fontSize: 12 }}>From Previous Year</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='card rounded-0' style={{ borderColor: '#E4E2F1' }}>
                <div className='row mt-3'>
                  <div className='col-6'>
                    <p className='text-start px-2' style={{ color: '#6E6B97' }}>Dropouts</p>
                    <p className='text-start fs-3 p-2' style={{ color: '#F03838' }}>{drop.length==0?'0':drop}</p>
                  </div>
                  <div className='col-6'>
                    <NavLink to="/studentviewlist/2">
                      <button type='button' className='' style={{ width: 120, height: 30, backgroundColor: '#fff', color: '#8147E7', border: '1px solid #8147E7', }}>View List</button>
                    </NavLink>
                    <div className='  border-0 mb-1'>
                      <p className='' style={{ color: '#F03838' }}>+ 0%</p>
                      <p className='' style={{ color: '#6E6B97', fontSize: 12 }}>From Previous Year</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='card rounded-0' style={{ borderColor: '#E4E2F1' }}>
                <div className='row mt-3'>
                  <div className='col-6'>
                    <p className='text-start px-2' style={{ color: '#6E6B97' }}>Total Students</p>
                    <p className='text-start fs-3 p-2' style={{ color: '#54536E' }}>{total.length==0?'0':total}</p>
                  </div>
                  <div className='col-6'>
                    <NavLink to="/studentviewlist/0">
                      <button type='button' className='' style={{ width: 120, height: 30, backgroundColor: '#fff', color: '#8147E7', border: '1px solid #8147E7', }}>View List</button>
                    </NavLink>
                    <div className='  border-0 mb-1'>
                      <p className='' style={{ color: '#40997E' }}>+ 0%</p>
                      <p className='' style={{ color: '#6E6B97', fontSize: 12 }}>From Previous Year</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ width: "40%" }}>
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasRightLabel">Default List</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <div style={{ overflowY: "scroll", height: "7vh", }}>
            <div className='d-flex '>

              <div className='' style={{ marginLeft: "4rem", }}>
                <p>Name</p>
              </div>
              <div className='' style={{ marginLeft: "7rem", }}>
                <p>ID</p>
              </div>
              <div className='' style={{ marginLeft: "7rem", }}>
                <p>Remark</p>
              </div>
            </div>
          </div>
          {data.map((item, index) => {
            return (
              <>
                <NavLink to="/studentprofile" className="text-decoration-none text-secondary">
                  <div className='row text-center my-3 d-flex align-items-center' >
                    <Sdata item={item} key={index} />
                  </div>
                </NavLink>
              </>
            )
          })}

        </div>
      </div>


    </div>

  )
}

function BasicExample(props) {
  return (
    <tr>
      <td style={{ backgroundColor: '#fff', color: '#6e6b97', borderRight: "1px solid #E4E2F1", }}>{props.item.id}</td>
      <td style={{ backgroundColor: '#fff', color: '#6e6b97', borderRight: "1px solid #E4E2F1", }}>{props.item.name}</td>
      <td style={{ backgroundColor: '#fff', color: '#6e6b97' }}>{props.item.remark}<i class="bi bi-three-dots-vertical"></i></td>
    </tr>
  );
}



function Sdata(props) {
  return (
    <>

      <div className='d-flex ' style={{ border: "1px solid #ECECEC", borderRadius: "10px", padding: "3px", width: "90%", marginLeft: "15px", }}>
        <div className='' style={{ marginLeft: "3rem", }}>
          <img src={props.item.image} style={{ width: "40px" }} />
        </div>
        <div className='mt-2' style={{ marginLeft: "1rem", }}>
          <p>{props.item.name}</p>
        </div>
        <div className='mt-2' style={{ marginLeft: "3rem", }}>
          <p>{props.item.id}</p>
        </div>
        <div className='mt-2' style={{ marginLeft: "5rem", }}>
          <p>{props.item.course}</p>
        </div>
        <div className='mt-2' style={{ marginLeft: "5rem", }}>
          <IoEyeOutline style={{ fontSize: "20px", color: "#0665fb", }} />
        </div>
      </div>

    </>
  );
}



