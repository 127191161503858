import React,{useState,useEffect}from "react";
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { IoPersonOutline } from "react-icons/io5";
import { IoArrowForwardSharp } from "react-icons/io5";
import EE from '../images/iconu.png';
import { IoCalendarNumber } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { API_URL } from "../env";
import { ToastContainer, toast } from "react-toastify";


const Unassigned = () => {
  const [data, setdata] = useState([])
  const [id, setid] = useState([])


  
  const Unassigned = async()=>{
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);


var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${API_URL}/admin/complain/read/unsolved/complain`, requestOptions)
  .then(response => response.json())
  .then(result =>{
     console.log(result)
  if(result.status == 1){
  setdata(result.complains)
  console.log(result.complains.id,'hiiii');
  }
  })
  .catch(error => console.log('error', error));
      
    } catch (error) {
      
    }
  }



  
const update_data = async(id)=>{
  var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);


var raw = JSON.stringify({
  "complain_id": id
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${API_URL}/admin/complain/update/complain/status`, requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if(result.status == 1){

      toast(result.msg);
      Unassigned()

    }
    else{
      toast(result.msg);
    }
  })
  .catch(error => console.log('error', error));
}

useEffect(() => {
  Unassigned()
}, [])

  return (
    <>
      <ToastContainer />

      <div className='container mt-3 mx-5' style={{ overflowY: "scroll", height: "70vh" }}>

{data.length > 0 ? (
  <>
    {data.map((res, key) => (
      <div className="row mt-3" key={key}>
        <div className="col-9" style={{ background: "#E9EBF5", borderRadius: "10px" }}>
          <div>
            <p className="px-2 mt-2" style={{ fontStyle: "normal", fontWeight: "600", fontSize: "22px", color: "#1A1A1A" }}>{res.head}</p>
          </div>
          <div className="row mt-2">
            <div className="col-3">
              <NavLink to="/studentprofile" style={{ textDecoration: "none" }}>
                <div className="d-flex mt-2" style={{ borderRadius: "50px", padding: "6px 0px 6px 0px" }}>
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px" }}>{res.student_name}</p>
                </div>
              </NavLink>
            </div>
            <div className="col-3">
              <p className="mt-3" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94" }}>
                <IoCalendarNumber style={{ color: "#777C94", fontSize: "20px" }} /> {res.create_on}
              </p>
            </div>
            <div className="col-3">
              <button type="button" className="btn" onClick={() => update_data(res.id)} style={{ background: "#CC1313", borderRadius: "39px", fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#FFFFFF", padding: "10px 25px" }}>Marks as Solved</button>
            </div>
          </div>
          <div className="mt-3">
            <p className="px-2 mb-3" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#777C94" }}>{res.description}</p>
          </div>
        </div>
      </div>
    ))}
  </>
) : (
  <p style={{color:'red',textAlign:'center'}}>No data found</p>
)}
</div>;




      {/* <modal></modal> */}
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ width: "45%", }}>
        <div class="offcanvas-header">
          {/* <h5 id="offcanvasRightLabel">Offcanvas right</h5> */}
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <div className="">
            <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "28px", color: "#474747", }}>Complaint Details</p>
            <p className="mt-4" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#7A7A7A", }}>Headline</p>
            <p className="mt-2" style={{ fontStyle: "normal", fontWeight: "600", fontSize: "20px", color: "#474747", }}>Unable to access my smartphone application.</p>
            <p className="mt-4" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#7A7A7A", }}>Description</p>
            <p className="mt-2" style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#5A5A5A", }}>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit.</p>
            <p className="mt-4" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#7A7A7A", }}>Complaint By</p>
            <div className="row mt-3">
              <div className="col-4">
                <NavLink to="/studentprofile" style={{ textDecoration: "none", }}><div className="d-flex mt-2" style={{ border: "1px solid rgb(212 212 212)", borderRadius: "50px", padding: "6px 0px 6px 0px", }}>
                  <img src={EE} style={{ marginLeft: '8px', borderRadius: '50px', width: '15%', border: '1px solid #808080', }} />
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px", }}>Natasha Goswami</p>
                </div></NavLink>
              </div>
              <div className="col-4">
                <p className="mt-3" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", }}><IoCalendarNumber style={{ color: "#777C94", fontSize: "23px", }} /> 12 December 2023 </p>
              </div>
            </div>
            <p className="mt-3" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#7A7A7A", }}>Status</p>

            <button type="button" class="btn mt-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{ background: "#CC1313", borderRadius: "39px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#FFFFFF", padding: "10px 20px", }}> Unassigned</button>
            <p className="mt-3" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#7A7A7A", }}>Assign this Complaint to</p>

            <div className="row mt-3">
              <div className="col-4">
                <div className="d-flex mt-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{ border: "1px solid rgb(212 212 212)", borderRadius: "10px", padding: "6px 0px 6px 0px", }}>
                  <img src={EE} style={{ marginLeft: '8px', borderRadius: '50px', width: '15%', border: '1px solid #808080', }} />
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px", }}>Natasha Goswami</p>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex mt-2" style={{ border: "1px solid rgb(212 212 212)", borderRadius: "10px", padding: "6px 0px 6px 0px", }}>
                  <img src={EE} style={{ marginLeft: '8px', borderRadius: '50px', width: '15%', border: '1px solid #808080', }} />
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px", }}>Natasha Goswami</p>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex mt-2" style={{ border: "1px solid rgb(212 212 212)", borderRadius: "10px", padding: "6px 0px 6px 0px", }}>
                  <img src={EE} style={{ marginLeft: '8px', borderRadius: '50px', width: '15%', border: '1px solid #808080', }} />
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px", }}>Natasha Goswami</p>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <div className="d-flex mt-2" style={{ border: "1px solid rgb(212 212 212)", borderRadius: "10px", padding: "6px 0px 6px 0px", }}>
                  <img src={EE} style={{ marginLeft: '8px', borderRadius: '50px', width: '15%', border: '1px solid #808080', }} />
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px", }}>Natasha Goswami</p>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex mt-2" style={{ border: "1px solid rgb(212 212 212)", borderRadius: "10px", padding: "6px 0px 6px 0px", }}>
                  <img src={EE} style={{ marginLeft: '8px', borderRadius: '50px', width: '15%', border: '1px solid #808080', }} />
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px", }}>Natasha Goswami</p>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex mt-2" style={{ border: "1px solid rgb(212 212 212)", borderRadius: "10px", padding: "6px 0px 6px 0px", }}>
                  <img src={EE} style={{ marginLeft: '8px', borderRadius: '50px', width: '15%', border: '1px solid #808080', }} />
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px", }}>Natasha Goswami</p>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <div className="d-flex mt-2" style={{ border: "1px solid rgb(212 212 212)", borderRadius: "10px", padding: "6px 0px 6px 0px", }}>
                  <img src={EE} style={{ marginLeft: '8px', borderRadius: '50px', width: '15%', border: '1px solid #808080', }} />
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px", }}>Natasha Goswami</p>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex mt-2" style={{ border: "1px solid rgb(212 212 212)", borderRadius: "10px", padding: "6px 0px 6px 0px", }}>
                  <img src={EE} style={{ marginLeft: '8px', borderRadius: '50px', width: '15%', border: '1px solid #808080', }} />
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px", }}>Natasha Goswami</p>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex mt-2" style={{ border: "1px solid rgb(212 212 212)", borderRadius: "10px", padding: "6px 0px 6px 0px", }}>
                  <img src={EE} style={{ marginLeft: '8px', borderRadius: '50px', width: '15%', border: '1px solid #808080', }} />
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px", }}>Natasha Goswami</p>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <div className="d-flex mt-2" style={{ border: "1px solid rgb(212 212 212)", borderRadius: "10px", padding: "6px 0px 6px 0px", }}>
                  <img src={EE} style={{ marginLeft: '8px', borderRadius: '50px', width: '15%', border: '1px solid #808080', }} />
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px", }}>Natasha Goswami</p>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex mt-2" style={{ border: "1px solid rgb(212 212 212)", borderRadius: "10px", padding: "6px 0px 6px 0px", }}>
                  <img src={EE} style={{ marginLeft: '8px', borderRadius: '50px', width: '15%', border: '1px solid #808080', }} />
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px", }}>Natasha Goswami</p>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex mt-2" style={{ border: "1px solid rgb(212 212 212)", borderRadius: "10px", padding: "6px 0px 6px 0px", }}>
                  <img src={EE} style={{ marginLeft: '8px', borderRadius: '50px', width: '15%', border: '1px solid #808080', }} />
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px", }}>Natasha Goswami</p>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <div className="d-flex mt-2" style={{ border: "1px solid rgb(212 212 212)", borderRadius: "10px", padding: "6px 0px 6px 0px", }}>
                  <img src={EE} style={{ marginLeft: '8px', borderRadius: '50px', width: '15%', border: '1px solid #808080', }} />
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px", }}>Natasha Goswami</p>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex mt-2" style={{ border: "1px solid rgb(212 212 212)", borderRadius: "10px", padding: "6px 0px 6px 0px", }}>
                  <img src={EE} style={{ marginLeft: '8px', borderRadius: '50px', width: '15%', border: '1px solid #808080', }} />
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px", }}>Natasha Goswami</p>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex mt-2" style={{ border: "1px solid rgb(212 212 212)", borderRadius: "10px", padding: "6px 0px 6px 0px", }}>
                  <img src={EE} style={{ marginLeft: '8px', borderRadius: '50px', width: '15%', border: '1px solid #808080', }} />
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px", }}>Natasha Goswami</p>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <div className="d-flex mt-2" style={{ border: "1px solid rgb(212 212 212)", borderRadius: "10px", padding: "6px 0px 6px 0px", }}>
                  <img src={EE} style={{ marginLeft: '8px', borderRadius: '50px', width: '15%', border: '1px solid #808080', }} />
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px", }}>Natasha Goswami</p>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex mt-2" style={{ border: "1px solid rgb(212 212 212)", borderRadius: "10px", padding: "6px 0px 6px 0px", }}>
                  <img src={EE} style={{ marginLeft: '8px', borderRadius: '50px', width: '15%', border: '1px solid #808080', }} />
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px", }}>Natasha Goswami</p>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex mt-2" style={{ border: "1px solid rgb(212 212 212)", borderRadius: "10px", padding: "6px 0px 6px 0px", }}>
                  <img src={EE} style={{ marginLeft: '8px', borderRadius: '50px', width: '15%', border: '1px solid #808080', }} />
                  <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px", }}>Natasha Goswami</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <modal></modal> */}
      <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            {/* <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> */}
            <div class="modal-body">
              <div className="text-center mt-4">
                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "25px", color: "#3C3C3C", }}>Complaint Assigned Successfully to</p>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary">Understood</button>
            </div>
          </div>
        </div>
      </div>
    </>

  )
};

export default Unassigned;