import React from 'react';
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { IoPersonOutline } from "react-icons/io5";
import { IoArrowForwardSharp } from "react-icons/io5";
export default function Profileemtopbar() {
   return (
      <>
         <div className='ps-5' style={{ overflowY: "scroll", height: "50vh", }}>
            <div className='mt-5'>
               <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "24px", color: "#434343", }}>Personal Details</p>
               <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#434343", }}>Name : Vishakha Shekhawat</p>
               <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#434343", }}>Date of Joining : 20 Apr 2023</p>
               <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#434343", }}>Date of Birth : 30 Oct 2002</p>
               <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#434343", }}>Contact Number : +91 99999 99999</p>
               <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#434343", }}>Emergency Contact Number : +91 98765 98765</p>
               <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#434343", }}>Email Address : vishakha345@gmail.com</p>
            </div>

            <div className='mt-3'>
               <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "24px", color: "#434343", }}>Educational Details</p>
               <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#434343", }}>Highest Qualification : BBA</p>
               <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#434343", }}>Name of institution : Narsejimohanrao Phule University, Pune</p>
               <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#434343", }}>Year of Qualification : 2017</p>
            </div>

            <div className='mt-3'>
               <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "24px", color: "#434343", }}>Family Details</p>
               <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#434343", }}>Father’s Name : Manohar Lal Shekhawat</p>
               <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#434343", }}>Profession : Businessmen</p>
               <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#434343", }}>Name of Organization : Swami Traders Private Limited</p>
               <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#434343", }}>Contact Number : +91 99999 99999</p>
               <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#434343", }}>Whatsapp Number : +91 99999 99999</p>
               <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#434343", }}>Email Address : swamitraders1@gmail.com</p>
               <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#434343", }}>Date of birth : 30 Apr 1975</p>
               <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#434343", }}>Mother’s Name : Shakti Devi Shekhawat</p>
               <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#434343", }}>Profession : Housewife</p>
            </div>
         </div>



      </>
   );
}



