import React, { useEffect, useState } from "react";
import { API_URL } from "../../env";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Loader } from "../Loader";
import { ToastContainer, toast } from 'react-toastify';
import Offcanvas from 'react-bootstrap/Offcanvas';

const StudUpcDetails = () => {
    const Navigate = useNavigate();
    const [upcoming, setUpcoming] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const {id} = useParams();
    const [show, setShow] = useState(false);
    const [emi,setEmi] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getUpcoming = async () => {
        try {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
          );
    
          var raw = JSON.stringify({
            "batch_id": id
          });
          
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          setIsLoading(true)
          fetch(
            `${API_URL}/admin/acount/student/with/upcoming/amount/in/particular/batch`,
            requestOptions
          )
            .then((response) => response.json())
            .then((result) => {
              setIsLoading(false)
              if (result.status == 1) {
                setUpcoming(result.send_arr);
              } 
              else if (result.status === 'VAL_ERR') {
                toast(result.Backend_Error)
              }
            else if (result.status == 'FILE_ERR') {
                toast(result.Backend_Error)
              }
             else if (result.status == "CUSTOM_ERR") {
                toast(result.Backend_Error);
              } else if (result.status == "TOKEN_ERR") {
                localStorage.removeItem('api_url');
                Navigate('/code')
                toast("Logged in other device! Please login again")
              }
              else {
                console.log(result)
              }
            })
            .catch((error) => console.log("error", error));
        } catch (e) {
          console.log(e);
        }
      };

      const viewEmi = async(arr)=>{
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append(
              "Authorization",
              `Bearer ${localStorage.getItem("token")}`
            );
      
            var raw = JSON.stringify({
                "emi_ids": arr
              });
              
              var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
              };
            setIsLoading(true)
            fetch(
              `${API_URL}/admin/acount/view/emi/array`,
              requestOptions
            )
              .then((response) => response.json())
              .then((result) => {
                setIsLoading(false)
                if (result.status == 1) {
                  setEmi(result.emis);
                } 
                else if (result.status === 'VAL_ERR') {
                  toast(result.Backend_Error)
                }
              else if (result.status == 'FILE_ERR') {
                  toast(result.Backend_Error)
                }
               else if (result.status == "CUSTOM_ERR") {
                  toast(result.Backend_Error);
                } else if (result.status == "TOKEN_ERR") {
                  localStorage.removeItem('api_url');
                  Navigate('/code')
                  toast("Logged in other device! Please login again")
                }
                else {
                  console.log(result)
                }
              })
              .catch((error) => console.log("error", error));
          } catch (e) {
            console.log(e);
          }
      }
    
      useEffect(() => {
        getUpcoming();
      }, [])
  return (
    <>
        <div className="container-fluid">
        <div className="row">
          <div
            className="col-2  bg-white sid"
            style={{ backgroundColor: "#fff", height: "100vh" }}
          >
            <p
              className=" d-flex  align-items-center mt-3 gap-2"
              style={{
                fontWeight: "600",
                color: "#3C3C3C",
                fontSize: "1rem",
              }}
            >
              {" "}
              <BsArrowLeft
                onClick={() => Navigate(-1)}
                style={{
                  color: "#000000",
                  fontSize: "1.2rem",
                  cursor: "pointer",
                }}
              />{" "}
              Upcoming Batches
            </p>
          </div>
          <div className="col-10 m-0 p-0">
            {isLoading ? (
              <Loader show={isLoading} />
            ) : (
              <>
                <div
                  className=""
                  style={{ height: "100vh", overflowY: "scroll" }}
                >
                  <DataTable
                  paginator
                  rows={10}
                    value={upcoming}
                    tableStyle={{ minWidth: "50rem" }}
                  >
                    <Column
                      field="enrollment_id"
                      header="Id"
                      style={{
                        width: "10.6%",
                        padding: "0.5rem 1rem",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                    ></Column>
                    <Column
                      field="name"
                      header="student name"
                      style={{
                        width: "17.8%",
                        padding: "0.5rem 1rem",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      bodyStyle={{ fontWeight: 500 }}
                    ></Column>
                    
                    <Column
                      field="amount"
                      header="Amount"
                      style={{
                        width: "17.8%",
                        padding: "0.5rem 1rem",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      body={(rowData) => (
    <span style={{ fontWeight: 500 }}>
      ₹ {rowData.amount}
    </span>
  )}
                      bodyStyle={{ fontWeight: 500 }}
                    ></Column>
                    <Column
                    //   field="emi_arr"
                    body={(rowData)=>(
                        <span>{rowData.emi_arr.length}</span>
                    )}
                      header="total emi"
                      style={{
                        width: "17.8%",
                        padding: "0.5rem 1rem",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      bodyStyle={{ fontWeight: 500 }}
                    ></Column>
                    <Column
                      body={(rowData)=>(
                        <button onClick={()=>{
                        viewEmi(rowData.emi_arr)
                        handleShow()
                        }}  className="border-0 p-2" style={{backgroundColor: "#8147E7",
                  color: "white",borderRadius:"5px",fontWeight:500}}>emi details</button>
                      )}
                      header="Action"
                      style={{
                        width: "17.8%",
                        padding: "0.5rem 1rem",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      bodyStyle={{ fontWeight: 500 }}
                    ></Column>
                  </DataTable>
                </div>
              </>
            )}
          </div>
        </div>
      </div>











{/* emi offcanvas started  */}
<Offcanvas show={show} onHide={handleClose} placement="end">
  <Offcanvas.Header closeButton>
    <Offcanvas.Title>received amount</Offcanvas.Title>
  </Offcanvas.Header>
  <Offcanvas.Body className="h-100">
    <div style={{ height: "82vh", overflowY: "scroll" }}>
      {emi.map((data, value) => (
        <div className="card bg-transparent mb-3 p-2" key={value}>
          <div className="d-flex align-items-center justify-content-between">
            <p>{data.name}</p>
            <p> ₹ {data.amount}</p>
          </div>
          <p className="mt-2">{data.due_date}</p>
          <p className="mt-2">{data.remark}</p>
        </div>
      ))}
    </div>
  </Offcanvas.Body>
</Offcanvas>

{/* emi offcanvas end  */}







      <ToastContainer/>
    </>
  )
}

export default StudUpcDetails
