import React, { useEffect, useState } from 'react';
import SideBar from './sidebar';
import { BsSearch } from 'react-icons/bs';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { BiMessageRoundedDetail } from 'react-icons/bi';
import { BsChatSquareText } from 'react-icons/bs';
import avtar from '../images/avatar.jpg';
import Tipsglogo from '../images/tipsglogo.png';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Bellicon, Chaticon } from './svgicon';
import { NavLink } from 'react-router-dom';
import { useContext } from 'react';
// import { API_URL } from './MyComponent';
import { AuthContext } from './AuthContext';



// import env from "react-dotenv";
// const {API_URL,img_url} =env;
// 


import { API_URL,img_url } from '../env';


export default function Header() {
  const { profile, profileGet, apiToken, Adname, AdminName } = useContext(AuthContext);
  const { name } = profile;

  useEffect(() => {
     AdminName();
  }, [])

  return (
    <>

      <div className='container-fluid p-0' style={{ backgroundColor: '' }}>
        <div className='row py-2 p-0 ms-3 align-items-center'>
          {/* <div className="col-2 m-auto p-0" style={{border:'1px solid red',}}>
           <img src={Tipsglogo} className='header' style={{color:'black'}} /> 
        </div> */}
          <div className='col-4 text-start pt-2'>
            <div class="widget-content-left header-user-info text-start">
              <h4 class="widget-heading" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "30px",textTransform:"capitalize" }}>Welcome {Adname}</h4>
              <div class="widget-subheading" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "18px", textTransform: "capitalize"}}>{name} </div>
            </div>
          </div>
          <div className='col-3 text-start pt-3'>
            {/* <form className="d-flex" role="search">
              <span className="input-group-text" id="basic-addon1" style={{ backgroundColor: '#fff', borderRight: 'none', borderColor: '#E4E2F1', borderTopRightRadius: 0, borderBottomRightRadius: 0, color: '#B1AFC8' }}><BsSearch /></span>
              <input type="text" className="form-control" placeholder="Search Dashboard" aria-label="Username" aria-describedby="basic-addon1" style={{ borderLeft: 'none', borderColor: '#E4E2F1', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} />
            </form> */}
          </div>
          <div className='col-2 pt-3'>
            {/* <div className='d-flex justify-content-space-around align-items-center'>
              <div style={{ backgroundColor: '#FFFFFF', marginLeft: 10, padding: 5, position: 'relative', borderRadius: 5, height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #ededed', cursor: "pointer" }}><Bellicon />
                <span style={{ backgroundColor: 'red', color: '#fff', padding: 2, fontSize: 10, position: 'absolute', right: 5, top: 2, borderRadius: 50, height: 14, width: 14, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>2</span></div>
              <NavLink to="/Chat"><div style={{ backgroundColor: '#fff', padding: 7, marginLeft: 20, borderRadius: 5, height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #ededed', color: "#000", }}><BsChatSquareText /></div></NavLink>
            </div> */}
          </div>

          <div className='col-3 pt-2'>

            <div style={{ textDecoration: "none", }}><div className='d-flex justify-content-end align-items-center'>
              <img src={avtar} style={{ width: '16%', borderRadius: 5 }} />
              <div class="widget-content-left  ml-3 header-user-info px-2 text-start">
                <div className="text-decoration-none text-dark">
                  <div class="widget-heading" >
                    <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "18px", color: "#000000", textTransform: "capitalize",}}>{Adname}</p>
                  </div>
                  <div class="widget-subheading" >
                    {/* <p style={{ fontStyle: "normal", fontWeight: "300", fontSize: "14px", color: "#000000", }}>Senior Admin</p> */}
                  </div>
                </div>
              </div>
            </div></div>




          </div>

        </div>
      </div>

    </>
  )
}
