import React, { useState } from 'react';
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { IoPersonOutline } from "react-icons/io5";
import { IoArrowForwardSharp } from "react-icons/io5";
import { IoNewspaper } from "react-icons/io5";
import { AiOutlineCalendar } from 'react-icons/ai'
import EE from '../images/attendance analytics.png';
import EE1 from '../images/iconu.png';
import EE2 from '../images/ic_outline-log-in.png';
import EE3 from '../images/ic_outline-log-in (1).png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Attendanceemptopbar() {

    const [startDate, setStartDate] = useState(new Date());
    const [startDate1, setStartDate1] = useState(new Date());

    return (
        <>
            <div className='mt-4 ps-4'>
                <div className='row'>
                    <div className='col-4'>
                        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "24px", color: "#434343", }}>Attendance Report</p>
                        <div className=' mt-5'>
                            <img src={EE} style={{ background: "#FFFFFF", border: "1px solid #E9E9E9", borderRadius: "10px", width: "100%", }} />
                        </div>
                    </div>
                    <div className='col-7'>
                        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "24px", color: "#434343", marginLeft: "50px", }}> Attendance Logs</p>

                        <div className='row mt-3 d-flex'>

                            <div className='col-3 d-flex'>
                                <div className='d-flex' style={{ alignItems: "center", gap: "10px" }}>
                                    <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#474747", }}>From</p>


                                    <span style={{ display: 'flex', borderRadius: "10px", padding: '7px 7px', color: '#b5b5b5', fontSize: '13px', width: "248px", border: " 1px solid rgba(176, 176, 176, 1)", width: "150px", overflow: "hidden", alignItems: "center" }}>

                                        <div style={{ width: '10px', }}>
                                            <span>
                                                <AiOutlineCalendar className='me-2 ' style={{ fontSize: '20px', marginTop: "3px", zIndex: "9999", position: "absolute", }} />
                                            </span>
                                            <DatePicker
                                                className='border-0 w-13 mt-3'
                                        
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                style={{ padding: "15px 11px 15px 25pxpx", border: "1px solid #B0B0B0", borderRadius: "10px" }} />
                                        </div>
                                    </span>
                                </div>
                            </div>

                            <div className='col-4  d-flex'>
                                <div className='d-flex' style={{ alignItems: "center", gap: "10px" }}>
                                    <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#474747", }}>TO</p>
                                    <span style={{ display: 'flex', color: '#b5b5b5', fontSize: '13px', padding: '7px 7px', borderRadius: "10px", border: " 1px solid rgba(176, 176, 176, 1)", width: "150px", overflow: "hidden" }}>

                                        <div style={{ width: '10px' }}><span><AiOutlineCalendar className='me-2 ' style={{ fontSize: '20px', marginTop: "3px", zIndex: "9999", position: "absolute", }} /> </span>
                                            <DatePicker className='border-0' selected={startDate1} onChange={(date) => setStartDate1(date)} style={{ backgroundColor: '#111', }} />
                                        </div>
                                    </span>

                                </div>
                            </div>
                            <div className='col-4'>
                                <button type="button" class="btn btn-success"><IoNewspaper />Export Data</button>
                            </div>
                        </div>


                        <div className='mt-3 ps-3' style={{ overflowY: "scroll", height: "42vh", width: "100%", }}>
                            <div style={{ width: "90%" }}>
                                <div className='row' style={{ background: "#FFFFFF", border: "1px solid #EDEDED", borderRadius: "5px", }}>
                                    <div className='col-2'>
                                        <img src={EE1} style={{ background: "#FFFFFF", borderRadius: "70px", width: "45%", padding: "2px", }} />
                                    </div>
                                    <div className='col-4'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Vishaka Shekhawat</p>
                                    </div>
                                    <div className='col-3'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>
                                            <span><img src={EE2} style={{ width: "10%", }} /></span> 12:30</p>
                                    </div>
                                    <div className='col-2'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>12 May 2023</p>
                                    </div>
                                </div>
                                <div className='row mt-2' style={{ background: "#FFFFFF", border: "1px solid #EDEDED", borderRadius: "5px", }}>
                                    <div className='col-2'>
                                        <img src={EE1} style={{ background: "#FFFFFF", borderRadius: "70px", width: "45%", padding: "2px", }} />
                                    </div>
                                    <div className='col-4'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Vishaka Shekhawat</p>
                                    </div>
                                    <div className='col-3'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>
                                            <span><img src={EE3} style={{ width: "10%", }} /></span> 12:30</p>
                                    </div>
                                    <div className='col-2'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>12 May 2023</p>
                                    </div>
                                </div>
                                <div className='row mt-2' style={{ background: "#FFFFFF", border: "1px solid #EDEDED", borderRadius: "5px", }}>
                                    <div className='col-2'>
                                        <img src={EE1} style={{ background: "#FFFFFF", borderRadius: "70px", width: "45%", padding: "2px", }} />
                                    </div>
                                    <div className='col-4'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Vishaka Shekhawat</p>
                                    </div>
                                    <div className='col-3'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>
                                            <span><img src={EE2} style={{ width: "10%", }} /></span> 12:30</p>
                                    </div>
                                    <div className='col-2'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>12 May 2023</p>
                                    </div>
                                </div>
                                <div className='row mt-2' style={{ background: "#FFFFFF", border: "1px solid #EDEDED", borderRadius: "5px", }}>
                                    <div className='col-2'>
                                        <img src={EE1} style={{ background: "#FFFFFF", borderRadius: "70px", width: "45%", padding: "2px", }} />
                                    </div>
                                    <div className='col-4'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Vishaka Shekhawat</p>
                                    </div>
                                    <div className='col-3'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>
                                            <span><img src={EE2} style={{ width: "10%", }} /></span> 12:30</p>
                                    </div>
                                    <div className='col-2'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>12 May 2023</p>
                                    </div>
                                </div>
                                <div className='row mt-2' style={{ background: "#FFFFFF", border: "1px solid #EDEDED", borderRadius: "5px", }}>
                                    <div className='col-2'>
                                        <img src={EE1} style={{ background: "#FFFFFF", borderRadius: "70px", width: "45%", padding: "2px", }} />
                                    </div>
                                    <div className='col-4'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Vishaka Shekhawat</p>
                                    </div>
                                    <div className='col-3'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>
                                            <span><img src={EE3} style={{ width: "10%", }} /></span> 12:30</p>
                                    </div>
                                    <div className='col-2'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>12 May 2023</p>
                                    </div>
                                </div>
                                <div className='row mt-2' style={{ background: "#FFFFFF", border: "1px solid #EDEDED", borderRadius: "5px", }}>
                                    <div className='col-2'>
                                        <img src={EE1} style={{ background: "#FFFFFF", borderRadius: "70px", width: "45%", padding: "2px", }} />
                                    </div>
                                    <div className='col-4'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Vishaka Shekhawat</p>
                                    </div>
                                    <div className='col-3'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>
                                            <span><img src={EE2} style={{ width: "10%", }} /></span> 12:30</p>
                                    </div>
                                    <div className='col-2'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>12 May 2023</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



        </>
    );
}



