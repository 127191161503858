import React, { useState,useContext, useEffect } from 'react';
import { FiMail } from 'react-icons/fi';
import { BsTelephone } from 'react-icons/bs';
import { AiOutlineLink } from 'react-icons/ai';
import DatePicker from "react-datepicker";
import { FiPhone, FiCalendar, } from "react-icons/fi";
import { BsArrowLeft } from 'react-icons/bs';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { RiDeleteBinLine } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from "./AuthContext";
import { API_URL, img_url } from "../env";

export default function ReportRequest() {

  const { apiToken } = useContext(AuthContext);

  const [startDate, setStartDate] = useState(new Date())
  const [startDate2, setStartDate2] = useState(new Date())
   //const [first, setfirst] = useState(second)
  const [valuee, setValuee] = useState(' ')
  const [state1, setState1] = useState(0);
  const [state11, setState11] = useState(0);
  const [tabled, setTabled] = useState(0)
  const [duedate, setDuedate] = useState(0)
  const [state15, setState15] = useState(0);
  const [Barr, setBarr] = useState('true');
  const [formFields, setFormFields] = useState([]); // State to store the form fields
  const [TotalSalary, setTotalSalary] = useState();
  const [NewData, setNewData] = useState([]);
  const [Emi, setEmi] = useState([]);
  const [Sedule, setSedule] = useState();

  const [Datess, setDatess] = useState("07/12/2024");


  const [formData, setFormData] = useState(
    {
      Customer: '',
      GSTIN: '',
      Address: '',
      Email: '',
      Contact: '',
      EmiCount: '',
    }
  );


   const ConvertData = ()=>{
    const convertedArray = formFields.map(obj => ({ product_name: obj.name,qty: obj.qty,rate:obj.rate }));
    HandleProcess2();
    setNewData(convertedArray)
   }


   console.log(NewData);


  const handleAddField = () => {
    // Add a new form field to the state
    setFormFields([...formFields, {
      sr_no: formFields.length + 1, name: '', qty: "", rate: "", amount: 0
    }]);
  };

  const handleRemoveField = (index) => {


    const updatedFormFields = [...formFields];
    updatedFormFields.splice(index, 1);
    setFormFields(updatedFormFields);


  };


 
  const handleManipulationInvoiceEntry = (e) => {
    const { name, value, className } = e.target;
    // console.log({ [name]: value, className }, 'on chnages in inputs')
    const new_data = formFields.map((el, ind) => ind === +className ?
    name === 'qty' || name === "rate"?{
      ...el,
      [name]: value,
      amount:   name === "qty" ? (+value) * (+el?.rate) : (+el?.qty) * (+value) 
    } :
     {
      ...el,
      [name]: value,
    } 
    : el);

    // console.log(new_data,)

    setFormFields(new_data);

  }




  const handleSubmit = (e) => {
    // Handle form submission
    e.preventDefault();
    // Access the form fields data in the state
    console.log(formFields);
  };




  const InvoceAdd = (show) => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${apiToken}`);

      var formdata = new FormData();
      formdata.append("name", formData.Customer);
      formdata.append("email", formData.Email);
      formdata.append("gst", formData.GSTIN);
      formdata.append("contact", formData.Contact);
      formdata.append("address", formData.Address);
      formdata.append("products", JSON.stringify(NewData));
      formdata.append("countEmi", formData.EmiCount);
      formdata.append("due_date", Sedule);
      formdata.append("show", show);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(`${API_URL}/report_make_payment`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result);
          setEmi(result.Emis);

          if(tabled==0){

          }
          else{
            setState15(state15 + 1);
          }
          
        })
  }



  const HandleProcess4 = () => {
    if (
      formData.Customer.length <= 1 ||
      formData.GSTIN.length <= 1 ||
      formData.Address.length <= 1 ||
      formData.Email.length <= 5 ||
      formData.Contact.length <= 9
      ) {
      toast(
        formData.Customer.length <= 1
          ? 'Please enter Customer name'
          : formData.GSTIN.length <= 1
            ? 'Please enter GSTIN Number'
            : formData.Address.length <= 2
              ? 'Please enter Address'
              :  formData.Email.length <= 1
                    ? 'Please enter Email '
                    : formData.Contact.length <= 9
                      ? 'Please enter Contact Number'
                      : 'Please fill in all the details'
      );
    }
    else {
      setState15(state15 + 1);
    }
  }


  const HandleProcess2 = () => {
    if (
      TotalSalary <= 1 
    ) {
      toast(
        TotalSalary.length <= 1
          ? 'Please enter Products'
          : 'Please enter Products'
      );
    }
    else {
      setState15(state15 + 1)
    }
  }


  const HandleProcess3 = () => {
    if (
      formData.EmiCount.length <= 1 
    ) {
      toast(
        formData.EmiCount.length <= 1
          ? 'Please enter Emi Count'
          : 'Please fill in all the details'
      );
    }


    else {
      setState15(state15 + 1)
    }
  }



  const handleInputChange = (fieldName, event) => {
    setFormData(prevData => ({
      ...prevData,
      [fieldName]: event.target.value,
    }));
  };



  useEffect(() => {
    console.log("dataArrayName",formFields);
    console.log("dataArrayName",formFields);
    const sum = formFields.reduce((accumulator, current) => {
      const value = parseInt(current.amount);
      if (!isNaN(value)) {
        return accumulator + value;
      }
      return accumulator;
    }, 0);
    
  console.log(sum);
  setTotalSalary(sum);
  }, [formFields])


  return (
    <>
    <ToastContainer />
      <div style={{ display: state15 == 0 ? "block" : "none" }}>
        <div className='bgcircle'>


          <div style={{ paddingTop: "100px", marginLeft: "20%" }}  >
            <p className=" " style={{ fontWeight: "600", fontSize: "30px" }}>
              <NavLink to="/Accounts" className="" style={{ zIndex: "999", }}>
                <BsArrowLeft className='' style={{ color: '#000000', fontSize: "25px", cursor: "pointer" }} />
              </NavLink >&nbsp; &nbsp;Create  Invoice </p>
            {/* <p style={{fontSize:"14px",fontWeight:"400"}}>Status : credited</p> */}

            <div className="row mt-5  ">

              <div className="col-6">
                <p className="px-1" style={{ fontWeight: "500", color: "#414141", fontSize: "18px" }}>Enter Customer Name</p>
                <input type="text" className="mt-2 px-4" placeholder="John Doe" form-control
                  style={{ borderRadius: "15px", width: "53%", height: "45px", border: "1px solid #EDEDED", fontSize: "16px" }} value={formData.Customer} onChange={e => handleInputChange('Customer', e)} />

                <p className="mt-4 px-1" style={{ fontWeight: "500", color: "#414141", fontSize: "18px" }}>GSTIN / LLP Reg No. /
                  Prop. Number <span style={{ fontWeight: "400", fontSize: "12px", color: "#A9A9A9" }}>Optional</span></p>
                <input type="text" className="mt-2 px-4" form-control
                  style={{ borderRadius: "15px", width: "56%", height: "55px", border: "1px solid #EDEDED", fontSize: "16px" }} value={formData.GSTIN} onChange={e => handleInputChange('GSTIN', e)} />

                <p className="mt-4 px-1 " style={{ fontWeight: "500", color: "#414141", fontSize: "18px" }}>Enter Address</p>
                <textarea w-10 type="text" className="mt-2 px-4 pb-4" placeholder="Pls Enter Address" form-control
                  style={{ borderRadius: "15px", width: "56%", height: "125px", border: "1px solid #EDEDED", fontSize: "14px" }} value={formData.Address} onChange={e => handleInputChange('Address', e)}></textarea>

              </div>


              <div className="col-6">

                <p className="px-1" style={{ fontWeight: "500", color: "#414141", fontSize: "18px" }}>Enter Email</p>
                <input type="email" className="mt-2 " form-control placeholder='johndoe@gmail.com'
                  style={{ borderRadius: "5px", width: "53%", height: "45px", border: "1px solid #EDEDED", fontSize: "16px", paddingLeft: "40px" }} value={formData.Email} onChange={e => handleInputChange('Email', e)} />
                <FiMail style={{ marginLeft: "-50%" }} />

                <p className="px-1 mt-4" style={{ fontWeight: "500", color: "#414141", fontSize: "18px" }}>Enter Contact No.</p>
                <input type="number" className="mt-2 " form-control
                  style={{ borderRadius: "5px", width: "53%", height: "45px", border: "1px solid #EDEDED", fontSize: "16px", paddingLeft: "40px" }} value={formData.Contact} onChange={e => handleInputChange('Contact', e)} />
                <BsTelephone style={{ marginLeft: "-50%" }} />

                <div>
                  <button className="" onClick={() => { HandleProcess4() }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRighti" aria-controls="offcanvasRight"
                    style={{
                      width: "53%", backgroundColor: " #5A81FE", border: "none", marginTop: "150px",
                      height: "45px", color: " #FFFFFF", fontWeight: "500", boxShadow: "0px 4px 12px rgba(90, 129, 254, 0.25)", borderRadius: "5px"
                    }}>Proceed</button>
                </div>

              </div>



              {/* </div> */}

            </div>
          </div>
        </div>
      </div>

      {/* invoice */}
      <div style={{ display: state15 == 1 ? "block" : "none" }}>
        <div className='bginvoice'>
          <div style={{ paddingTop: "50px", marginLeft: "20%" }}  >
            <p className=" " style={{ fontWeight: "600", fontSize: "27px" }}>
              <BsArrowLeft className='' style={{ color: '#000000', fontSize: "25px", cursor: "pointer" }} onClick={() => { setState15(state15 - 1) }} />
              &nbsp; &nbsp;Invoice </p>
            <p className='ms-5' style={{ fontWeight: "500" }}>#INV - 0081</p>
            <div>
              <div className='row pt-2 mt-4' style={{ border: "2 px solid #DDDDDD", backgroundColor: " #2F264C", width: "87%", color: "#FFFFFF", height: "42px", borderRadius: "5px", fontWeight: "400px" }}>
                <div className='col-2'>
                  <p>#</p>
                </div>
                <div className='col-3'>
                  <p className='ps-4'>Product Name</p>
                </div>
                <div className='col-2'>
                  <p className='ps-5'>QTY.</p>
                </div>
                <div className='col-2'>
                  <p className='' style={{ paddingLeft: "90px" }}>Rate</p>
                </div>
                <div className='col-3'>
                  <p className='' style={{ paddingLeft: "127px" }} >Amount</p>
                </div>
              </div>

              {/*// form for each entry*/}
              <form onSubmit={handleSubmit} style={{ overflowY: "scroll", height: "40vh" }}>
                {formFields.map((field, index) => (
                  <div className='pt-2' key={index}>

                    <input
                      type="text"
                      // value={field.Number}
                      name='sr_no'
                      className={index}
                      value={formFields[index].sr_no}
                      // onChange={handleManipulationInvoiceEntry}
                      // onChange={(e) => handleChange(index, e.target.value, field.name, field.qty, field.rate, field.amount)}
                      placeholder="1" style={{ border: "none", backgroundColor: "transparent", outline: "none" }} />
                    <input

                      type="text"
                      // value={field.name}
                      name='name'
                      className={index}
                      onChange={handleManipulationInvoiceEntry}
                      // onChange={(e) => handleChange(index, field.Number, e.target.value)}
                      placeholder="Machine" style={{ border: "none", backgroundColor: "transparent", outline: "none" }} />

                    <input type="text"
                      onChange={(e) => {
                        handleManipulationInvoiceEntry(e);
                        // handleEntryAmount(e);
                      }}
                      // value={field.qty}
                      name='qty'
                      className={index}
                      //onChange={(e) => handleChange(index, field.Number, e.target.value)}
                      placeholder="10" style={{ border: "none", backgroundColor: "transparent", outline: "none", paddingLeft: "50px" }} />

                    <input type="text"
                      // value={field.rate}
                      name='rate'
                      className={index}
                      onChange={(e) => {
                        handleManipulationInvoiceEntry(e);
                        // handleEntryAmount(e);
                      }}
                      // onChange={(e) => handleChange(index, field.Number, e.target.value)}
                      placeholder="10,000" style={{ border: "none", backgroundColor: "transparent", outline: "none" }} />

                    <input type="text"
                      name='amount'
                      disabled={true}
                      className={index}
                      // onChange={handleManipulationInvoiceEntry}
                      value={formFields[index]?.amount}
                      //onChange={(e) => handleChange(index, field.Number, e.target.value)}
                      placeholder="1,00,000" style={{ border: "none", backgroundColor: "transparent", outline: "none" }} />

                    <span type="button" onClick={() => handleRemoveField(index)} style={{ color: "black", backgroundColor: "#CACACA", width: "30px",marginLeft:-50,borderRadius: "50%", height: "30px", paddingLeft: "6px" }}><RiDeleteBinLine /></span>
                    <div className='mt-2' style={{ border: "2px solid #CACACA", width: "74.7%" }}></div>
                  </div>

                ))}


                <span type="button" onClick={handleAddField} style={{ fontSize: "30px", marginLeft: "37%", }}><BsFillPlusCircleFill style={{ color: "#CACACA" }} /></span>
                {/* <button type="submit">Submit</button> */}
              </form>

              <div className='row ' style={{ marginTop: "10px" }}>
                <div className="col-6">
                  <p className='mt-1' style={{ fontWeight: "500", fontSize: "20px" }}>Bill To:</p>
                  <p className='mt-1' style={{ fontWeight: "500", fontSize: "22px" }}>{formData.Customer}</p>

                  <p className='mt-1' style={{ fontSize: "400", fontSize: "15px" }}>{formData.Address}</p>
                </div>

                <div className='col-6 mt-1 ' >
                  <div style={{ display: "flex" }}>
                    <p style={{ fontSize: "20px", fontWeight: "400", marginLeft: "90px" }}>Sub Total</p>
                    <p className='px-4' style={{ fontWeight: "500", fontSize: "20px" }}>{TotalSalary}</p>
                  </div>
                  <div className='mt-2' style={{ display: "flex" }}>
                    <p className='' style={{ fontSize: "20px", fontWeight: "400", marginLeft: "130px" }}>Total</p>
                    <p className='ps-2' style={{ fontWeight: "500", fontSize: "20px" }}>₹ {TotalSalary}</p>
                  </div>


                  <div>
                    <button className="" 
                    onClick={() => {
                       ConvertData()
                    }}
                     data-bs-toggle="offcanvas" data-bs-target="#offcanvasRighti" aria-controls="offcanvasRight"
                      style={{
                        width: "35%", backgroundColor: " #5A81FE", border: "none", marginTop: "60px", marginLeft: "100px",
                        height: "45px", color: " #FFFFFF", fontWeight: "500", borderRadius: "5px",
                      }}>Setup Payment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* set payment */}
      <div style={{ display: state15 == 2 ? "block" : "none" }}>
        <div className='bginvoice'>
          <div style={{ paddingTop: "50px", marginLeft: "20%" }}>
            <p className=" " style={{ fontWeight: "600", fontSize: "27px" }}>
              <BsArrowLeft className='' style={{ color: '#000000', fontSize: "25px", cursor: "pointer", fontWeight: "600" }} onClick={() => {
                setState15(state15 - 1)
              }} />
              &nbsp; &nbsp;Setup Payment </p>
            <div className='row'>
              <div className='col-6'>
                <div className='mt-5' style={{ border: "1px solid  #000000", width: "200px", height: "95px", borderRadius: "10px" }}>
                  <p className='pt-1 px-3' style={{ fontWeight: "400", fontSize: "20px" }}>Grand Total :</p>
                  <p className='pt-1 px-3' style={{ fontSize: "30px", fontWeight: "500" }}>₹ {TotalSalary}</p>
                </div>
                <div className='row mt-5'>
                  <div className='col-3'>
                    <div class="form-check">
                      <input class="form-check-input" onClick={() => {setDuedate(0);setTabled(0)}} type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                      <label class="form-check-label" for="flexRadioDefault1"><span style={{ fontWeight: "600" }}>Pay in EMIs</span></label>
                    </div>
                  </div>

                  <div className='col-4'>
                    <div class="form-check ms-4">
                      <input class="form-check-input" onClick={() => {setDuedate(1);setTabled(0)}} type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                      <label className="form-check-label" for="flexRadioDefault1"><span style={{ fontWeight: "600" }}>Pay in One Shot</span></label>
                    </div>
                  </div>
                </div>

                <div>
                  <div className='row mt-4' >

                   

                  <div style={{ display: duedate == 0 ? "block" : "none" }}>
                  <div className='row' >
  
  
                  
  
                    <div className='col-6'>
                      <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "18px", color: "#000000", marginTop: 20, }}>EMI Count<span style={{ color: "red" }}>*</span></p>
                      <input type="number" className='mt-2' placeholder='Number of EMIs' style={{ width: "80%", background: "#E0E0E0", borderRadius: "10px", border: "none", padding: "12px", }}  value={formData.EmiCount} onChange={e => handleInputChange('EmiCount', e)}  />
                    </div>
  
  
  
                    <div className='col-3 mx-3'>
                      <div >
                        <p style={{ marginTop: "20px", fontStyle: "normal", fontWeight: "500", fontSize: "18px", color: "#000000", }}>Due Date<span style={{ color: "red" }}>*</span></p>
                        <span className='mt-2 d-flex' style={{
                          borderRadius: "10px", width: "170px", height: "45px", backgroundColor: "#E0E0E0"
                          , textAlign: "center", color: "#7A7A7A", padding: "11px",
                        }}><FiCalendar style={{ fontSize: "1.5rem" }} />
                          <DatePicker className="border-0  datepAdd "
                            showIcon
                            selected={startDate}
                            onChange={(date) => {
                              setStartDate(date);
                              setSedule(date.getFullYear() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getDate());
                            }}
                            style={{ backgroundColor: '#E0E0E0', outline: "0", width: "15px" }} />
                        </span>
                      </div>
                    </div>
                   
  
                    <div className='col-2 mt-5' style={{marginTop:20}}>
                      <button onClick={() => {
                        InvoceAdd(2);
                        setTabled(1);
                      }} className='btn btn-outline-dark mx-5'>OK</button>
                    </div>
                  </div>
                </div>
  
                <div className='' style={{ display: duedate == 1 ? "block" : "none" }}>
  
  
                 
  
  
                  <div >
                    <p style={{ marginTop: "20px", fontStyle: "normal", fontWeight: "500", fontSize: "18px", color: "#000000", }}>Due Date<span style={{ color: "red" }}>*</span></p>
                    <span className='mt-2 d-flex' style={{
                      borderRadius: "10px", width: "180px", height: "45px", backgroundColor: "#E0E0E0"
                      , textAlign: "center", color: "#7A7A7A", padding: "11px",
                    }}><FiCalendar />&nbsp;
                      <DatePicker className="border-0  datepAdd"
                        showIcon
                        selected={startDate2}
                        onChange={(date) => {
                          setStartDate2(date);
                          setSedule(date.getFullYear() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getDate());
                        }}
                        style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                    </span>
                  </div>
  
                </div>




                    

                   
                    
                  </div>
                </div>

                <div>
                  <button className="" onClick={() => { duedate==0?InvoceAdd(0):InvoceAdd(1)}} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRighti" aria-controls="offcanvasRight"
                    style={{
                      width: "57%", backgroundColor: " #5A81FE", border: "none", marginTop: "170px", boxShadow: "0px 4px 12px rgba(90, 129, 254, 0.25)",
                      height: "52px", color: " #FFFFFF", fontWeight: "500", borderRadius: "5px"
                    }}>Proceed To Payment</button>
                </div>

              </div>

              <div className='col-6'>
                <div style={{ display: tabled == 0 ? "none" : "" }}>

                  <div className='ms-2 mt-4' style={{ border: "1px solid  #ECECEC", width: "90%" }}>
                    <div style={{ overflowY: "scroll", height: "75vh" }}>
                      <div className='row  mx-3 mt-2'>
                        <div className='col-4'>
                          <p style={{ fontWeight: "400" }}>S.NO</p>
                        </div>
                        <div className='col-4'>
                          <p style={{ fontWeight: "400" }}>Amount</p>
                        </div>
                        <div className='col-4'>
                          <p style={{ fontWeight: "400" }}>Due Date</p>
                        </div>
                      </div>
                      {Emi?.map((res, key) => {
                        return (
                          <>

                            <div className='row ms-4  mt-3  '
                              style={{ border: "1px solid  #ECECEC", width: "92%", backgroundColor: "transparent", borderRadius: "10px", height: "40px" }} >

                              <Ai res={res} key={key} />
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

      {/* preves */}
      <div style={{ display: state15 == 3 ? "block" : "none" }}>
        <div className='bginvoice'>
          <div style={{ paddingTop: "50px", marginLeft: "20%" }}>
            <p className=" " style={{ fontWeight: "600", fontSize: "26px" }}>
              
              &nbsp; &nbsp;Preview </p>
            <div className='row'>
              <div className='col-6'>
                <div className='row'>
                  <div className='col-sm-12 col-md-12 col-lg-6'>
                    <div className='mt-5' style={{ border: "1px solid  #000000", width:"auto", height:"auto", borderRadius: "10px" }}>
                      <p className='pt-2 px-3' style={{ fontWeight: "400", fontSize: "20px" }}>Amount to Be Paid</p>
                      <p className='px-3' style={{ fontSize: "30px", fontWeight: "500" }}>₹ {TotalSalary} </p>
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-5 ms-3'>
                    <div className='mt-5' style={{ border: "1px solid  #000000", width: "100%", height: "100px", borderRadius: "10px" }}>
                      <p className='pt-2 px-3' style={{ fontWeight: "400", fontSize: "20px" }}>Grand Total :</p>
                      <p className=' px-3' style={{ fontSize: "30px", fontWeight: "500" }}>₹ {TotalSalary} </p>
                    </div>
                  </div>
                </div>
                <p className='mt-5' style={{ fontWeight: "400" }}>To</p>
                <p style={{ fontWeight: "400", fontSize: "22px" }}>{formData.Customer}</p>
                <p className='mt-2' style={{ fontWeight: "400" }}>{formData.Address} </p>
                <p className='mt-2' style={{ fontWeight: "400" }}>+91 {formData.Contact}</p>
                <p className='mt-3' style={{ fontSize: "18px" }}>Due Date</p>
                {/* <p style={{fontWeight:"400" ,fontSize:"22px"}}><BiCalendar/>&nbsp;&nbsp;24 Feb 2023</p> */}
                <span style={{
                  display: 'flex', backgroundColor: '', padding: '',
                  borderRadius: '5px', color: '#b5b5b5', fontSize: '13px',
                }}>
                  <FiCalendar className='' style={{ fontSize: '20px', color: "#3C3C3C", marginTop: "17px", }} />
                  <div className='mx-2' style={{marginTop:14,fontSize:20}}>
                    <p>{Sedule}</p>
                  </div>
                </span>

                <div>
                  <button className="" onClick={() => { setState15(state15 + 1) }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRighti" aria-controls="offcanvasRight"
                    style={{
                      width: "57%", backgroundColor: " #5A81FE", border: "none", marginTop: "72px", boxShadow: "0px 4px 12px rgba(90, 129, 254, 0.25)",
                      height: "45px", color: " #FFFFFF", fontWeight: "500", borderRadius: "5px"
                    }}>Proceed To Payment</button>
                </div>
              </div>


              {/*// <div className='col-6'>
              //   <p className=" " style={{ fontWeight: "600", fontSize: "26px" }}> Payment Schedule </p>
              //   <div className='ms-2 mt-4' style={{ border: "1px solid  #ECECEC", width: "90%" }}>
              //     <div style={{ overflowY: "scroll", height: "71vh" }}>
              //       <div className='row  mx-3 mt-2'>
              //         <div className='col-3'>
              //           <p style={{ fontWeight: "400" }}>S.NO</p>
              //         </div>
              //         <div className='col-3'>
              //           <p style={{ fontWeight: "400" }}>Remarks</p>
              //         </div>

              //         <div className='col-3'>
              //           <p style={{ fontWeight: "400" }}>Amount</p>
              //         </div>
              //         <div className='col-3'>
              //           <p style={{ fontWeight: "400" }}>Due Date</p>
              //         </div>
              //       </div>

              //     </div>
              //   </div>
                  // </div>*/}


            </div>

          </div>
        </div>
      </div>

      {/* payment */}
      <div style={{ display: state15 == 4 ? "block" : "none" }}>
        <div className='bginvoice'>
          <div style={{ paddingTop: "50px", marginLeft: "20%" }}>
            <p className=" " style={{ fontWeight: "600", fontSize: "27px" }}>
              <BsArrowLeft className='' style={{ color: '#000000', fontSize: "25px", cursor: "pointer" }} onClick={() => { setState15(state15 - 1) }} />
              &nbsp; &nbsp;Payment </p>

            <div className='row'>
              <div className='col-6 mt-5'>
                <p className='' style={{ fontWeight: "500", fontSize: "26px", lineHeight: "35px" }}> Payment Link Sent to<br /> {formData.Customer} Successfully ! </p>
                <p className='mt-5' style={{ fontWeight: "500", fontSize: "20px" }}>Payment Link Coming Soon</p>
               {/* // <input type="text" className='from-control px-3 mt-2' placeholder="https://easebuzz.in//payment_id%20%manipal%univ......." style={{
                //   paddingLeft: "0px",
                //   width: "68%", height: "32px", borderRadius: "5px", border: "1px solid #565656"
                // }} />
                <span style={{ marginLeft: "-18px" }}><AiOutlineLink /></span>
*/}
                <p className='mt-3' style={{ fontSize: "19px", fontWeight: "500" }}>Remark</p>
                <div>
                  <input type="text" maxLength="100" className="mt-2" placeholder="" value1={1} style={{
                    width: "68%", height: "55px", paddingLeft: "10px", borderRadius: "5px", border: "1px solid #565656"
                  }} onChange={(e) => { setValuee(e.target.value) }} />
                </div>
                <p style={{ paddingLeft: "66%", fontSize: "10px" }} >{valuee.length}/100</p>

                <p className='mt-3' style={{ fontWeight: "500px", fontSize: "17px" }}>Contact Number</p>
                <p style={{ fontSize: "19px" }}>+91 {formData.Contact}</p>
              </div>

              <div className='col-6'>
                <div className='mt-5' style={{ border: "1px solid  #000000", width: "210px", height: "100px", borderRadius: "10px" }}>
                  <p className='pt-2 px-3' style={{ fontWeight: "400", fontSize: "20px" }}>Amount to Be Paid</p>
                  <p className='px-3' style={{ fontSize: "30px", fontWeight: "500" }}>₹ {TotalSalary} </p>
                </div>

                <p className='mt-5' style={{ fontSize: "18px", fontWeight: "600" }}>Due Date</p>
                {/* <p style={{fontWeight:"400" ,fontSize:"22px"}}><BiCalendar/>&nbsp;&nbsp;24 Feb 2023</p> */}
                <span style={{
                  display: 'flex', backgroundColor: '', padding: '',
                  borderRadius: '5px', color: '#b5b5b5', fontSize: '13px',
                }}>
                  <FiCalendar className='' style={{ fontSize: '20px', color: "#3C3C3C", marginTop: "17px", }} />
                  <div className='mt-2 mx-2'>
                    <p style={{fontSize:20}}>{Sedule}</p>
                  </div>
                </span>

              </div>

            </div>
            <div className=' mt-5 '>
              <NavLink to="/Accounts">
                <button type="button" style={{
                  backgroundColor: "#5A81FE",
                  color: "white", border: "none", width: "230px", height: "48px", borderRadius: "5px", marginLeft: "30%", fontWeight: "500", boxShadow: " 0px 4px 12px rgba(90, 129, 254, 0.25)"
                }}>Back to dashboard</button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>







    </>
  )
}

function Aiii(props) {
  return (
    <>
      <div className='col-3'>

        <p className='pt-2'>{props.res.sn}</p>
      </div>

      <div className='col-3'>

        <p className='pt-2'>{props.res.remark}</p>
      </div>

      <div className='col-3'>

        <p className='pt-2' >{props.res.amount}</p>
      </div>

      <div className='col-3'>
        <p className='pt-2'>{props.res.duedate}</p>
      </div>

    </>
  );
}


function Ai(props) {
  return (
    <>
      <div className='col-4'>

        <p className='pt-2'>{props.res.emi_number}</p>
      </div>


      <div className='col-4'>

        <p className='pt-2' >{props.res.emi_amount}</p>
      </div>

      <div className='col-4'>
        <p className='pt-2'>{props.res.due_date.split(' ',1)}</p>
      </div>

    </>
  );
}