import React, { useState, useContext, useEffect } from "react";
import Back from "../images/wave.png";
import { IoArrowBackOutline } from "react-icons/io5";
import { useParams, useNavigate } from "react-router-dom";
import { API_URL } from "../env";
import { AuthContext } from "./AuthContext";
import jsPDF from "jspdf";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

const GenerateSlipt = () => {
  let { id, due_date } = useParams();
  const Navigate = useNavigate();
  const [logo, setLogo] = useState("");


 const generatePDF = (name, fatherName, totalAmount, date, serialNumber) => {
    const doc = new jsPDF();
    const currentDate = new Date();
    let downloadDate = currentDate.toLocaleDateString("en-GB");
    // let formDate = due_date.toLocaleDateString('en-GB');

    let centerX = (string_data, fontSize) => {
      let pageWidth = doc.internal.pageSize.width;
      let textWidth =
        (doc.getStringUnitWidth(string_data) * fontSize) /
        doc.internal.scaleFactor;
      return (pageWidth - textWidth) / 2;
    };

    let rightX = (string_data, fontSize) => {
      let pageWidth = doc.internal.pageSize.width;
      let textWidth =
        (doc.getStringUnitWidth(string_data) * fontSize) /
        doc.internal.scaleFactor;
      return pageWidth - textWidth;
    };

    // heading mid
    let Heading = "PRINCE SCHOOL";
    let contacts = "Surya Nagar,Murlipura Scheme,Jaipur * Tel.:0141-2421375";
    let heading2 = "FEES RECEIPT";

    let rightField1 = "L.F. No. ....................";
    let rightField2 = "S.R. No. ....................";
    let rightField3 = `Date : ${moment(date).format('DD-MM-YYYY')} `;

    doc.setTextColor("#301934");

    doc.addImage(logo, 'PNG', 5, 3 , 25 ,25);

    let y = 15;
    let x = centerX(Heading, 30);
    doc
      .setFontSize(30)
      .setFont("undefined", "bold")
      .text(Heading, x - 10, y);

    y += 6;
    x = centerX(contacts, 12);
    doc
      .setFontSize(12)
      .setFont("undefined", "bold")
      .text(contacts, x - 10, y);

    y += 6;
    x = centerX(heading2, 15);
    doc
      .setFontSize(15)
      .setFont("undefined", "bold")
      .text(heading2, x - 10, y);

    x = rightX(rightField1, 12) - 5;
    let ry = 10;
    doc.setFontSize(12).setFont("undefined", "bold").text(rightField1, x, ry);
    ry += 8;
    doc.setFontSize(12).setFont("undefined", "bold").text(rightField2, x, ry);
    ry += 8;
    doc.setFontSize(12).setFont("undefined", "bold").text(rightField3, x, ry);

    //  mid-part

    let serial_no = "Serial No. : ";

    let Name = `Name : ${name}`;
    let std = `Std. ................................ : `;

    let Father = `Father's Name : ${fatherName}`;

    y += 10;
    x = 5;
    doc.setFontSize(12).setFont("undefined", "bold").text(serial_no, x, y);
    x = (doc.getStringUnitWidth(serial_no) * 12) / doc.internal.scaleFactor + 5;
    doc
      .setFontSize(15)
      .setTextColor("#8B0000")
      .setFont("undefined", "bold")
      .text(serialNumber, x, y);

    doc.setTextColor("#301934");

    x = 5;
    y += 7;
    doc.setFontSize(12).setFont("undefined", "bold").text(Name, x, y);

    x = rightX(std, 12) - 5;
    doc.setFontSize(12).setFont("undefined", "bold").text(std, x, y);

    x = 5;
    y += 7;
    doc.setFontSize(12).setFont("undefined", "bold").text(Father, x, y);

    // line
    let startX = 0; // Adjust the starting X position
    let startY = y + 3; // Adjust the starting Y position
    let lineLength = doc.internal.pageSize.width; // Adjust the length of the line
    doc.line(startX, startY, startX + lineLength, startY);

    // mid content
    let rupees = "INR........................................................."; // rupee sign here

    x = 5;
    y += 10;
    doc
      .setFontSize(12)
      .setFont("undefined", "bold")
      .text("1. Registration Fees", x, y);
    x = rightX(rupees, 12) - 5;
    doc.setFontSize(12).setFont("undefined", "bold").text(rupees, x, y);

    x = 5;
    y += 8;
    doc
      .setFontSize(12)
      .setFont("undefined", "bold")
      .text("2. Tution Fees (I/II/III/IV Quarter)", x, y);
    x = rightX(rupees, 12) - 5;
    doc.setFontSize(12).setFont("undefined", "bold").text(rupees, x, y);

    x = 5;
    y += 8;
    doc
      .setFontSize(12)
      .setFont("undefined", "bold")
      .text("3. Conveyance Fees (I/II/III/IV Quarter)", x, y);
    x = rightX(rupees, 12) - 5;
    doc.setFontSize(12).setFont("undefined", "bold").text(rupees, x, y);

    x = 5;
    y += 8;
    doc
      .setFontSize(12)
      .setFont("undefined", "bold")
      .text("4. Annual Charge", x, y);
    x = rightX(rupees, 12) - 5;
    doc.setFontSize(12).setFont("undefined", "bold").text(rupees, x, y);

    x = 5;
    y += 8;
    doc
      .setFontSize(12)
      .setFont("undefined", "bold")
      .text("5. Computer Fees", x, y);
    x = rightX(rupees, 12) - 5;
    doc.setFontSize(12).setFont("undefined", "bold").text(rupees, x, y);

    x = 5;
    y += 8;
    doc
      .setFontSize(12)
      .setFont("undefined", "bold")
      .text("6. Other Fees (if any)", x, y);
    x = rightX(rupees, 12) - 5;
    doc.setFontSize(12).setFont("undefined", "bold").text(rupees, x, y);

    x = 5;
    y += 8;
    doc
      .setFontSize(12)
      .setFont("undefined", "bold")
      .text("7. Late Fees (if any)", x, y);
    x = rightX(rupees, 12) - 5;
    doc.setFontSize(12).setFont("undefined", "bold").text(rupees, x, y);

    x = 5;
    y += 10;
    doc
      .setFontSize(18)
      .setFont("undefined", "bold")
      .text("TOTAL", x + 20, y);
    x = rightX(totalAmount, 18) - 20;
    doc
      .setFontSize(18)
      .setFont("undefined", "bold")
      .text(`INR ${totalAmount}`, x, y); // rupee sign here

    // ending line
    startY = y + 3;
    doc.line(startX, startY, startX + lineLength, startY);

    x = 5;
    y += 10;
    doc
      .setFontSize(12)
      .setFont("undefined", "bold")
      .text(
        "Received Rupees (in words)..............................................................................................................................................",
        x,
        y
      );

    x = 5;
    y += 7;
    doc
      .setFontSize(12)
      .setFont("undefined", "bold")
      .text(
        "Cheque / D.D. No. ...................................Date...............................Bank................................Branch..............................",
        x,
        y
      );

    x = 5;
    y += 7;
    doc
      .setFontSize(12)
      .setFont("undefined", "bold")
      .text(
        "1. Fees once deposited is not refundable in any case.   2. E. & O.E.   3. I will not demand for refund of fees",
        x,
        y
      );

    // end part

    x = 5;
    y += 10;
    doc
      .setFontSize(14)
      .setFont("undefined", "bold")
      .text("Signature Guardian / Student", x, y);
    x = rightX("For Prince School", 14) - 5;
    doc
      .setFontSize(14)
      .setFont("undefined", "bold")
      .text("For Prince School", x, y);

    // downloading
    doc.save(`Fees_receipt-${downloadDate}.pdf`);
    setIsGenerate(!isGenerate)

   

    toast.success("Fees Receipt generated successfully")

  };


  

  // states
  const [name, setName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [date, setDate] = useState(new Date());
  const [serialNumber, setSerialNumber] = useState(0);
  const [isGenerate, setIsGenerate] = useState(true);

    // states for handle
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedFile, setSelectedFile] = useState(null);



  const { apiToken } = useContext(AuthContext);


  const getLogo = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${apiToken}`);


    await fetch(`${API_URL}/admin/acount/get/url/for/receipt`, {
      method: "GET",
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((result) => {
          if (result.status == 1) {
              setLogo(result.imageBase64)
          }

      })

      .catch((error) => console.log("error", error));
  };

  const getDetails = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    let raw = JSON.stringify({
      paid_id: Number(id),
    });

    await fetch(`${API_URL}/admin/student/generate/receipt/by/student/id`, {
      method: "POST",
      headers: myHeaders,
      body: raw,
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("details : ", result);
        setName(result.studentsDetails.student_name);
        setFatherName(result.studentsDetails.father_name);
        setTotalAmount(result.studentsDetails.amount);
        setSerialNumber(result.studentsDetails.serial_no);
      })

      .catch((error) => console.log("error", error));
  };



  // send details
  const sendDetails = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    let formData = new FormData();
    formData.append("paid_id", Number(id));
    formData.append("receipt", selectedFile);

    await fetch(`${API_URL}/admin/student/get/receipt/pdf/student/id`, {
      method: "POST",
      headers: myHeaders,
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          console.log("response : ", result);
          toast.success(result.msg)
          handleClose()
          setTimeout(()=>{
            Navigate(-1)
          }, 2000)
        }else{
          toast.error(result.msg)
        }
      })

      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getDetails();
    getLogo()
  }, []);

  return (
    <>
     <ToastContainer/>
      <div
        style={{
          width: "100%",
          height: "100vh",
          background: "#FBFDFF",
          backgroundImage: `url(${Back})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
        }}
      >
        <div className="">
          <IoArrowBackOutline
            onClick={() => {
              Navigate(-1);
            }}
            style={{
              marginLeft: "17px",
              fontSize: "1.5rem",
              marginTop: "1rem",
              color: "#000",
              cursor: "pointer",
            }}
          />
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4">
              <div
                className="card mt-5 ps-3"
                style={{
                  border: "none",
                  width: "100%",
                  background: "#FFFFFF",
                  boxShadow: "0px 4px 12px rgba(158, 158, 158, 0.25)",
                  borderRadius: "10px",
                }}
              >
                {/* <form className="mt-4" onSubmit={submitHandler}> */}

                <div>
                  <label
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "20px",
                      color: "#414141",
                    }}
                  >
                    name
                  </label>
                  <br></br>

                  {/* input for remark */}
                  <input
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    className="mt-2"
                    type="text"
                    value={name}
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #EDEDED",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "95%",
                    }}
                  />
                </div>

                <div className="mt-2">
                  <label
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "20px",
                      color: "#414141",
                    }}
                  >
                    Father's Name
                  </label>
                  <br></br>

                  {/* input for remark */}
                  <input
                    onChange={(e) => {
                      setFatherName(e.target.value);
                    }}
                    className="mt-2"
                    type="text"
                    value={fatherName}
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #EDEDED",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "95%",
                    }}
                  />
                </div>

                <div className="mt-2">
                  <label
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "20px",
                      color: "#414141",
                    }}
                  >
                    Total Amount
                  </label>
                  <br></br>

                  {/* input for amount */}
                  <input
                    onChange={(e) => {
                      setTotalAmount(e.target.value);
                    }}
                    className="mt-2"
                    type="number"
                    value={totalAmount}
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #EDEDED",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "95%",
                    }}
                    d="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>

                <div className="mt-2 mb-2">
                  <label
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "20px",
                      color: "#414141",
                    }}
                  >
                    Date
                  </label>
                  <br></br>

                  {/* input for date */}
                  <input
                    // placeholder={}
                    className="mt-2"
                    type="date"
                    value={moment(date).format('YYYY-MM-DD')}
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #EDEDED",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "95%",
                    }}
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                  />
                </div>

                <div className="mt-2 mb-2">
                  <label
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "20px",
                      color: "#414141",
                    }}
                  >
                    Serial Number
                  </label>
                  <br></br>

                  {/* input for date */}
                  <input
                    // placeholder={date}
                    className="mt-2"
                    type="text"
                    value={serialNumber}
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #EDEDED",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "95%",
                    }}
                    onChange={(e) => {
                      setSerialNumber(e.target.value);
                    }}
                  />
                </div>

                {/* button */}
                <div className="mt-5 mb-4 text-center">

                    {isGenerate === true ?
                                        <button
                                        type="click"
                                        class="btn btn-primary"
                                        style={{
                                          width: "90%",
                                          background: "#3572FA",
                                          boxShadow: "0px 2px 8px #B0BEEF",
                                          borderRadius: "10px",
                                          padding: "15px",
                                        }}
                                        onClick={() => {
                                          generatePDF(
                                            name,
                                            fatherName,
                                            String(totalAmount),
                                            date,
                                            String(serialNumber)
                                          );

                                        }}
                                      >
                                        Generate Slip
                                      </button>
                            :

                            <button
                            type="click"
                            class="btn btn-primary"
                            style={{
                              width: "90%",
                              background: "#3572FA",
                              boxShadow: "0px 2px 8px #B0BEEF",
                              borderRadius: "10px",
                              padding: "15px",
                            }}
                            onClick={
                              handleShow
                            }
                          >
                            Upload PDF
                          </button>

                    }




                </div>
                {/* </form> */}
              </div>
            </div>
            <div className="col-4"></div>
          </div>
        </div>
      </div>



      
      {/* add banner modal started  */}
      <Modal show={show} onHide={handleClose} centered >

        <Modal.Header closeButton>

          <input type="file"
          accept=".pdf"
          onChange={(event)=>{
            setSelectedFile(event.target.files[0]);
          }} />

        </Modal.Header>


        <Modal.Footer centered>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="btn btn-primary" onClick={() => {
            sendDetails()
          }}  >
            Upload PDF
          </Button>
        </Modal.Footer>
      </Modal>
      {/* add banner modal end  */}
    </>
  );
};

export default GenerateSlipt;
