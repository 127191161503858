import React,{useEffect,useState} from "react";
import { useParams } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { API_URL, img_url } from "../env";

const ViewPF = () => {
  const {id} = useParams();
  const [profile,setProfile] = useState([]);
  const viewProfile = async()=>{
    try{
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      var raw = JSON.stringify({
        emp_id: id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${API_URL}/admin/employee/view/profile/employee`, requestOptions)
  .then(response => response.json())
  .then((result) => {
    console.log(result)
    if(result.status==1){
      setProfile([result.data]);
    }
  })
  .catch(error => console.log('error', error));
    }catch(e){
      console.log(e);
    }
  }

  useEffect(() => {
   viewProfile();
  }, [])
  

  return (
    <>
      <div className="container h-100">
        <div className="row h-100 justify-content-center align-items-center">
          <div className="col-12">
          {
            profile.map((data,value)=>(
              <div className="card p-2  w-50 mx-auto" key={value} > 
              <Form.Group className="">
                <Form.Label>Name</Form.Label>
                <p style={{fontWeight:500}} >{data.name}</p> 
              </Form.Group><hr/>
              <Form className="mt-3 mb-2">
                <Row>
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>DOB</Form.Label>
                    <p style={{fontWeight:500}} >{data.dob}</p> 
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>Salary</Form.Label>
                    <p style={{fontWeight:500}} >{`₹ ${data.salary}`}</p> 
                  </Form.Group>
                </Row>
              </Form><hr/>
              <Form.Group className="mt-3">
                <Form.Label>Highest Qualification</Form.Label>
                <p style={{fontWeight:500}} >{data.highest_qual}</p> 
              </Form.Group><hr/>
              <Form className="mt-3 mb-2">
                <Row>
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>email</Form.Label>
                    <p style={{fontWeight:500}} >{data.email}</p> 
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>phone</Form.Label>
                    <p style={{fontWeight:500}} >{data.phone}</p> 
                  </Form.Group>
                </Row>
              </Form><hr/>
              <Form className="mt-3 mb-2">
                <Row>
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>District</Form.Label>
                    <p style={{fontWeight:500}} >{data.district}</p> 
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>Pincode</Form.Label>
                    <p style={{fontWeight:500}} >{data.pincode}</p> 
                  </Form.Group>
                </Row>
              </Form><hr/>
              <Form.Group className="mt-3">
                <Form.Label>Address</Form.Label>
                {/* <Form.Control placeholder={data.address} disabled /> */}
               <p style={{fontWeight:500}} >{data.address}</p> 
              </Form.Group><hr/>
              </div>
            ))
          }
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewPF;
