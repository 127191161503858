import React, { useState, useEffect,useContext } from 'react';
import { BiChevronRight, } from 'react-icons/bi';
import { FiDownloadCloud } from 'react-icons/fi';
import { Creditcard, Share, Debitcard, Sharered, Outstanding, Shareorange, Clock, Shareblue } from './svgicon';
import { CNavItem, CNavLink, CNav, CTabContent, CTabPane } from '@coreui/react';
import Chart from "react-apexcharts";
import { NavLink } from 'react-router-dom';
import { FiMail, FiPhone, FiCalendar, } from "react-icons/fi";
import { BsFillCalendarFill } from "react-icons/bs";
import { BiCalendar } from 'react-icons/bi';
import { API_URL,img_url } from '../env';
import { AuthContext } from './AuthContext';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import './report.css';
import { AiOutlineLink } from 'react-icons/ai';

// import {HiDocumentArrowUp} from 'react-icons/hi';
// import { HiDocumentArrowUp } from "react-icons/hi";
// import {HiDocumentArrowUp} from 'react-icons/hi';
export default function Report() {


  const { apiToken } = useContext(AuthContext);



  let Credithis = {
    options: {
      colors: ['#85FFDA'],
      chart: {
        id: "bar"
      },
      stroke: {
        colors: ['#40997E'],
        width: 2,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [2022,2023]
      },
    },
    series: [
      {
        name: "series-1",
        data: [0,0]
      }
    ]
  };

  let Debithis = {
    options: {
      colors: ['#FF7F7F'],
      chart: {
        id: "bar"
      },
      stroke: {
        colors: ['#FF0D0D'],
        width: 2,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [2022,2023]
      },
    },
    series: [
      {
        name: "series-1",
        data: [0,0]
      }
    ]
  };

  let outstanding = {
    options: {
      colors: ['#FFEE19'],
      chart: {
        id: "bar"
      },
      stroke: {
        colors: ['#FF8000'],
        width: 2,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [2022,2023]
      },
    },
    series: [
      {
        name: "series-1",
        data: [0,0]
      }
    ]
  };

   const [Amount, setAmount] = useState("0");
   const [Amount1, setAmount1] = useState("0");
   const [Amount2, setAmount2] = useState("0");
  const [state, setState] = useState(Credithis)
  const [debitstate, setDebitState] = useState(Debithis)
  const [outst, setOutstanding] = useState(outstanding)
  const [ReqStartDate, setReqStartDate] = useState('');
  const [activeKey, setActiveKey] = useState(1)



  const [state1, setState1] = useState(0);
  const [duedate, setDuedate] = useState(0)
  const [tabled, setTabled] = useState(0)
  const [count1, setcount1] = useState(1)
  const [valuee, setValuee] = useState(' ')
  const [state11, setState11] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const alli = [
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },


  ]

  const allii = [
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },


  ]

 

  const CollectData = (lastDate)=>{
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/student_Collection?date=d&from=${ReqStartDate}&to=${lastDate}`, requestOptions)
      .then(response => response.json())
      .then(result => {console.log(result);
         if(result.status=="001"){
           setAmount(result.total_Expected_amount);
           setAmount1(result.total_collected_amount);
           setAmount2(result.total_reamaining_amount);
         }
      })
      .catch(error => console.log('error', error));
  }


  const CollectData1 = ()=>{
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/student_Collection`, requestOptions)
      .then(response => response.json())
      .then(result => {console.log(result);
         if(result.status=="001"){
           setAmount(result.total_Expected_amount);
           setAmount1(result.total_collected_amount);
           setAmount2(result.total_reamaining_amount);
         }
      })
      .catch(error => console.log('error', error));
  }

    useEffect(() => {
      CollectData();
      CollectData1();
    }, []);


    
  
  return (
    <>

      <div className='row'>
        <div className='col-2 ' >
          <div className='' style={{ display: "flex" }}>
            <p className='pt-3 ' style={{ color: "#434343", fontWeight: "500", fontSize: "17px", paddingLeft: "27px" }} >From</p>
            <span style={{
              display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "10px",
              borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
            }}>
              <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
              <div>
                <DatePicker className="border-0   ps-2 pt-1  datep"
                  showIcon
                  selected={startDate}
                  onChange={(date) => {setStartDate(date);
                    const req_date = date.getFullYear() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getDate();
                    setReqStartDate(req_date);}}
                  style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
              </div>
            </span>
            <p className='pt-3 ps-1' style={{ color: "#434343", fontWeight: "500", fontSize: "17px" }}>to</p>
          </div>
        </div>

        <div className='col-2' style={{ marginLeft: "40px" }} >
          <div>
            <span style={{
              display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "10px",
              borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
            }}>
              <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
              <div>
                <DatePicker className="border-0  ps-2 pt-1  datep"
                  showIcon
                  selected={startDate1}
                  onChange={(date) => {setStartDate1(date);
                    const req_date = date.getFullYear() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getDate();
                   CollectData(req_date);
                  }}
                  style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
              </div>
            </span>
          </div>
        </div>

        <div className='col-5 ms-5 text-end'>
          <NavLink to="/Makepayment"> <button className='' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightm" aria-controls="offcanvasRight"
            style={{ border: "1px solid #B63A3A", backgroundColor: "#FFFFFF", color: "#B63A3A", width: "150px", height: "38px", borderRadius: "5px" }} >Make Payment</button></NavLink>
        </div>
        
         {/*<div className='col-1 text-end'  >
          <NavLink to="/ReportRequest">   <button className='' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRighti" aria-controls="offcanvasRight"
            style={{
              border: "1px solid #3AB690", backgroundColor: "#FFFFFF", color: "#3AB690", width: "150px",
              height: "38px", borderRadius: "5px"
            }}>Request Payment</button></NavLink>
        </div>
        */}

        <div className='col-2'>

        </div>

      </div>

      {/* request paymet offcanvas  */}
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRighti" aria-labelledby="offcanvasRightLabel" style={{ width: "45%" }}>
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasRightLabel"></h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          {/* create invoice */}
          <div style={{ display: state1 == 0 ? "block" : "none" }}>

            <p style={{ fontSize: "30px", fontWeight: "400" }}>Create  Invoice </p>
            <p style={{ fontWeight: "400", fontSize: "16px" }}>Status : credited</p>

            <p className='mt-4' style={{ color: "#9E9E9E", fontSize: "16px" }}>Enter Customer Name </p>
            <input type="text" className="form-control" placeholder="Name.." style={{ paddingLeft: "20px", width: "90%", height: "32px", borderColor: "#BDBDBD" }} />


            <p className='mt-4' style={{ color: "#9E9E9E", fontSize: "16px" }}> GSTIN / LLP Reg No. / Prop. Number(OPTIONAL)</p>
            <input type="text" className="form-control" placeholder="Number.." style={{ paddingLeft: "20px", width: "90%", height: "32px", borderColor: "#BDBDBD" }} />


            <p className='mt-4' style={{ color: "#9E9E9E", fontSize: "16px" }}>Enter Address </p>
            <input type="text" className="form-control pb-5" placeholder="101-103 Chanda Tower, Opp. Grand Utsave Marriage Garden
     Main Gandhi Path Road, Vaishali Nagar,
Jaipur , Rajasthan - 302021 India" style={{ paddingLeft: "20px", width: "90%", height: "70px", borderColor: "#BDBDBD" }} />


            <p className='mt-4' style={{ color: "#9E9E9E", fontSize: "16px" }}>Enter Email </p>
            <input type="text" className="form-control" placeholder="Naramurti@gmail.com" style={{ paddingLeft: "40px", width: "90%", height: "32px", borderColor: "#BDBDBD" }} />
            <FiMail style={{ marginTop: "-63px", marginLeft: "10px", color: "black" }} />

            <p className='mt-4' style={{ color: "#9E9E9E", fontSize: "16px" }}>Enter Contact Number </p>
            <input type="text" className="form-control" placeholder="985236565564" style={{ paddingLeft: "40px", width: "90%", height: "32px", borderColor: "#BDBDBD" }} />
            < FiPhone style={{ marginTop: "-63px", marginLeft: "10px", color: "black" }} />

            <p className='mt-4' style={{ color: "#000000", fontSize: "30px", fontWeight: "400" }}>Product Details</p>
            <input type="text" className="form-control" placeholder="Product Name" style={{ paddingLeft: "20px", width: "90%", height: "32px", borderColor: "#BDBDBD" }} />

            <p className='mt-4' style={{ color: "#9E9E9E", fontSize: "16px" }}> <span style={{ color: "#000000" }}>Enter Product Descrition</span> &nbsp; (OPTIONAL)</p>
            <input type="text" className="form-control pb-5" placeholder=" Enter Product Description " style={{ paddingLeft: "20px", width: "90%", height: "70px", borderColor: "#BDBDBD" }} />

            <p className='mt-4' style={{ color: "#000000", fontSize: "15px" }}>Enter Qty.</p>
            <input type="text" className="form-control" placeholder="Enter Quantity...." style={{ paddingLeft: "20px", width: "90%", height: "32px", borderColor: "#BDBDBD" }} />

            <p className='mt-4' style={{ color: "#000000", fontSize: "16px" }}>Enter Price/Unit</p>
            <input type="text" className="form-control" placeholder="Enter price per unit..." style={{ paddingLeft: "20px", width: "90%", height: "32px", borderColor: "#BDBDBD" }} />

            <p className='mt-4' style={{ color: "#000000", fontSize: "16px" }}>Enter Discount Percentage</p>
            <input type="text" className="form-control" placeholder="Discount..." style={{ paddingLeft: "20px", width: "20%", height: "32px", borderColor: "#BDBDBD" }} />


            <div className='row'>
              <div className='col-6 mt-4' >
                <div className='' style={{ border: "1px solid #BDBDBD", width: "80%", height: "80px", fontWeight: "400", borderRadius: "5px" }}>
                  <p className='ms-3 pt-2' style={{ fontSize: "20px" }}>  Total Amount</p>
                  <p className='ms-3 ' style={{ fontSize: "25px" }}>₹ 12,000</p>
                </div>

              </div>

              <div className=' col-6 mt-5'>
                <button onClick={() => { setState1(state1 + 1) }} style={{
                  backgroundColor: "#5A81FE", color: "white", boxShadow: "0px 4px 12px rgba(90, 129, 254, 0.25)",
                  border: "none", width: "250px", borderRadius: "5px", height: "40px", fontWeight: "500"
                }}> Setup payment</button>
              </div>

            </div>

          </div>
          {/* setup payment */}
          <div style={{ display: state1 == 1 ? "block" : "none" }}>
            <div className='row'>
              <div className='col-3'>
                <div class="form-check">
                  <input class="form-check-input" onClick={() => setDuedate(duedate - 1)} type="radio" name="flexRadioDefault" id="flexRadioDefault1" />

                  <label class="form-check-label" for="flexRadioDefault1">
                    <span style={{ fontWeight: "500" }}>Pay in EMIs</span>
                  </label>
                </div>
              </div>

              <div className='col-4'>
                <div class="form-check">
                  <input class="form-check-input" onClick={() => setDuedate(duedate + 1)} type="radio" name="flexRadioDefault" id="flexRadioDefault1" />

                  <label class="form-check-label" for="flexRadioDefault1">
                    <span style={{ fontWeight: "400" }}>Pay in One Shot</span>
                  </label>
                </div>
              </div>
            </div>

            <div>
              <div className='row mt-4' >
                <div className='col-3' style={{ display: duedate == 0 ? "block" : "none" }} >

                  <p style={{ fontWeight: "400" }}>EMI Count</p>
                  <p className='p-2 mt-1' style={{
                    borderRadius: "10px", width: "150px", height: "45px", color: "#757575",
                    backgroundColor: "#E0E0E0", textAlign: "center", paddingTop: "55px"
                  }}>Number of EMIs</p>
                </div>

                <div className='col-3 mx-3'>
                  <div >
                    <p style={{ fontWeight: "400" }}>Due Date</p>
                    <p className='pt-2 p-2 mt-1' style={{
                      borderRadius: "10px", width: "150px", height: "45px",
                      backgroundColor: "#E0E0E0", textAlign: "center", color: " #757575"
                    }}><FiCalendar />&nbsp;12 Nov 2022</p>
                  </div>
                </div>

                <div className='col-3 ' style={{ marginTop: "32px" }}>
                  <button onClick={() => setTabled(tabled + 1)} className='  mx-5' style={{ backgroundColor: "#FCFCFC", width: "50px", height: "40px", borderRadius: "5px", fontWeight: "500" }} >OK</button>

                </div>
              </div>
            </div>

            <div style={{ display: tabled == 0 ? "none" : "" }}>


              {/* <table class="table table-bordered">
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Remarks</th>
      <th scope="col">Amount</th>
      <th scope="col">Due Date</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Hostel Fees</td>
      <td>₹ 49,965</td>
      <td>12/11/2022</td>
    </tr>
    
  </tbody>
</table> */}
              <div className='ms-2 mt-4' style={{ border: "1px solid  #ECECEC", width: "90%" }}>
                <div style={{ overflowY: "scroll", height: "50vh" }}>
                  <div className='row  mx-3 mt-2'>
                    <div className='col-3'>
                      <p style={{ fontWeight: "400" }}>S.NO</p>
                    </div>
                    <div className='col-3'>
                      <p style={{ fontWeight: "400" }}>Remarks</p>
                    </div>

                    <div className='col-3'>
                      <p style={{ fontWeight: "400" }}>Amount</p>
                    </div>
                    <div className='col-3'>
                      <p style={{ fontWeight: "400" }}>Due Date</p>
                    </div>
                  </div>
                  {alli.map((res, key) => {
                    return (
                      <>

                        <div className='row ms-4  mt-3  '
                          style={{ border: "1px solid  #ECECEC", width: "92%", backgroundColor: "white", borderRadius: "10px", height: "40px" }} >

                          <Ai res={res} key={key} />
                        </div>
                      </>
                    )
                  })}
                </div>
              </div>

            </div>

            <div className='row mt-5' >
              <div className='col-6'>
                <p className='px-2' style={{ fontWeight: "500", color: "#000000", fontSize: "20px" }}>Grand Total:₹ 3,99,725</p>
              </div>
              <div className='col-6'>
                <button onClick={() => { setState1(state1 + 1) }} style={{
                  backgroundColor: "#5A81FE", color: "white", border: "none", fontWeight: "500", boxShadow: " 0px 4px 12px rgba(90, 129, 254, 0.25)",
                  width: "200px", height: "40px", borderRadius: "5px"
                }}>Proceed To Payment</button>
              </div>
            </div>

          </div>
          {/* preview */}
          <div style={{ display: state1 == 2 ? "block" : "none" }}>
            <p style={{ fontWeight: "400", fontSize: "30px" }}>Preview</p>
            <p className='mt-3' style={{ fontWeight: "400" }}>From</p>
            <div className='row'>
              <div className='col-6'>
                <p style={{ fontSize: "22px", fontWeight: "400" }}>RNS IT SOLUTIONS</p>
                <p style={{ fontSize: "15px", fontWeight: "400" }}>101-201-301, Chanda Tower, Girnar Colony, Gandhi Path Road, Vaishali Nagar, Jaipur - 302021</p>
                <p className='mt-2' style={{ fontSize: "15px" }}>+91 702 334 0831</p>

                <p className='mt-3' style={{ fontWeight: "400", fontSize: "20px" }}>To</p>
                <p className="" style={{ fontWeight: "400", fontSize: "25px" }}>Narayan murthy</p>
                <p className='mt-3' style={{ fontSize: "15px" }}>101-201-301, Chanda Tower, Girnar Colony, Gandhi Path Road, Vaishali Nagar, Jaipur - 302021</p>
                <p className='mt-3' style={{ fontSize: "15px" }}>+91 702 334 0831</p>
              </div>

              <div className='col-6 ' >
                <p style={{ fontSize: "25px", fontWeight: "400", paddingLeft: "100px" }}>Due Date</p>
                <p style={{ paddingLeft: "100px", fontWeight: "400" }}><BiCalendar />&nbsp;&nbsp;24 Feb 2023</p>

                <p className='pt-3 mt-3 p-1' style={{
                  border: "2px solid #E0E0E0", width: "150px",
                  marginLeft: "100px", height: "90px", fontSize: "14px", borderRadius: "5px"
                }}>
                  Amount to be paid
                  <p style={{ fontWeight: "400", fontSize: "20px" }}>₹ 1,59,963</p>
                </p>
              </div>
            </div>

            {/* <table class="table table-bordered">
<thead>
  <tr>
    <th scope="col">S.No</th>
    <th scope="col">Remarks</th>
    <th scope="col">Amount</th>
    <th scope="col">Due Date</th>
  </tr>
</thead>
<tbody>
  <tr>
    <th scope="row">1</th>
    <td>Hostel Fees</td>
    <td>₹ 49,965</td>
    <td>12/11/2022</td>
  </tr>
  
</tbody>
</table> */}
            <div className='ms-2 mt-4' style={{ border: "1px solid  #ECECEC", width: "90%" }}>
              <div style={{ overflowY: "scroll", height: "50vh" }}>
                <div className='row  mx-3 mt-2'>
                  <div className='col-3'>
                    <p style={{ fontWeight: "400" }}>S.NO</p>
                  </div>
                  <div className='col-3'>
                    <p style={{ fontWeight: "400" }}>Remarks</p>
                  </div>

                  <div className='col-3'>
                    <p style={{ fontWeight: "400" }}>Amount</p>
                  </div>
                  <div className='col-3'>
                    <p style={{ fontWeight: "400" }}>Due Date</p>
                  </div>
                </div>
                {allii.map((res, key) => {
                  return (
                    <>

                      <div className='row ms-4  mt-3  '
                        style={{ border: "1px solid  #ECECEC", width: "92%", backgroundColor: "white", borderRadius: "10px", height: "40px" }} >

                        <Aiii res={res} key={key} />
                      </div>
                    </>
                  )
                })}
              </div>
            </div>

            <div className='row mt-5 ' >
              <div className='col-6'>
                <p className='px-2' style={{ fontWeight: "500", color: "#000000", fontSize: "20px" }}>Grand Total:₹ 3,99,725</p>
              </div>

              <div className='col-6'>
                <button onClick={() => { setState1(state1 + 1) }} style={{
                  backgroundColor: "#5A81FE", color: "white", border: "none", fontWeight: "500", boxShadow: " 0px 4px 12px rgba(90, 129, 254, 0.25)",
                  width: "200px", height: "40px", borderRadius: "5px"
                }}>Proceed To Payment</button>
              </div>
            </div>
          </div>


          {/* payment link  */}
          <div style={{ display: state1 == 3 ? "block" : "none" }}>

            <div>
              <p style={{ fontSize: "30px", fontWeight: "500" }}>Payment Link Sent to Narayanmurthy Successfully</p>
            </div>

            <div className='mt-3'>
              <p style={{ fontWeight: "500", fontSize: "17px" }}>Payment Link</p>
              <input type="text" className='from-control px-3 mt-2' placeholder="https://easebuzz.in//payment_id%20%manipal%univ......." style={{
                paddingLeft: "0px",
                width: "70%", height: "32px", borderRadius: "5px", border: "1px solid #D7D7D7"
              }} />
              <span style={{ marginLeft: "-18px" }}><AiOutlineLink /></span>
            </div>

            <div className='mt-3' >
              <p style={{ fontWeight: "500", fontSize: "17px" }}>Remark</p>
              <div>
                <input type="text" maxLength="100" className="mt-2" placeholder="" value1={1} style={{
                  width: "70%", height: "40px", paddingLeft: "10px", borderRadius: "5px", border: "1px solid #D7D7D7"
                }} onChange={(e) => { setValuee(e.target.value) }} /></div>
              <p style={{ paddingLeft: "66%", fontSize: "10px" }} >{valuee.length}/100</p>

            </div>


            <div className='mt-3'>
              <p style={{ fontSize: "20px", fontWeight: "500" }}>Contact Number</p>
              <p style={{ fontWeight: "400", }}>+91 9874563210</p>
            </div>

            <div className='mt-3'>
              <p style={{ fontSize: "25px", fontWeight: "400" }}>Due Date</p>
              <p style={{ fontWeight: "400" }}><BiCalendar />  &nbsp;22 Feb 2023</p>
            </div>


            <div className='pt-3 mt-3 p-1' style={{
              border: "2px solid #E0E0E0", width: "150px",
              height: "90px", fontSize: "14px", borderRadius: "5px"
            }}>
              <div>Amount to be paid
                <p style={{ fontWeight: "400", fontSize: "20px" }}> ₹1,59,963</p>
              </div>
            </div>


            <div className='col-6 mt-5'>
              <button type="button" data-bs-dismiss="offcanvas" aria-label="Close" style={{
                backgroundColor: "#5A81FE",
                color: "white", border: "none", width: "200px", height: "40px", borderRadius: "5px", marginLeft: "150px", fontWeight: "500"
              }}>Back to dashboard</button>
            </div>


          </div>

        </div>
      </div>

      {/* end */}

      {/* make payment       */}

      {/* end */}





      <div className='row mt-3'>
        <div className='col-7 mt-0 ms-4'>
          <CNav variant="underline" role="tablist" style={{ backgroundColor: '#fff', padding: 15 }}>
            <CNavItem className='d-flex justify-content-center'>
              <CNavLink
                href="javascript:void(0);"
                active={activeKey === 1}
                onClick={() => setActiveKey(1)}
                className="p-0 tab-1"
              >
                <div className='d-flex align-items-center justify-content-end px-3' style={{ borderRight: '1px solid #6E6B97', width: 150, fontSize: 17, color: '#40997e', }}>
                  <Creditcard />&nbsp;Credit&nbsp;&nbsp;&nbsp;<Share className='' />
                </div>
              </CNavLink>
            </CNavItem>
            <CNavItem className='d-flex justify-content-between'>
              <CNavLink
                href="javascript:void(0);"
                active={activeKey === 2}
                onClick={() => setActiveKey(2)}
                className="p-0 tab-2"
              >
                <div className='d-flex align-items-center justify-content-end px-3'
                  style={{ borderRight: '1px solid #6E6B97', width: 150, fontSize: 17, color: '#F03838', }}>
                  <Debitcard />&nbsp;Debit&nbsp;&nbsp;&nbsp;<Sharered />
                </div>
              </CNavLink>
            </CNavItem>
            <CNavItem className='d-flex justify-content-between'>
              <CNavLink
                href="javascript:void(0);"
                active={activeKey === 3}
                onClick={() => setActiveKey(3)}
                className='p-0 tab-3'
              >
                <div className='d-flex align-items-center justify-content-end px-3' style={{ width: 200, fontSize: 17, color: '#e2a925', }}>
                  <Outstanding />&nbsp;Outstanding&nbsp;&nbsp;&nbsp;<Shareorange />
                </div>
              </CNavLink>
            </CNavItem>
          </CNav>
          <CTabContent className='mt-2'>
            <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeKey === 1}>
              <div className='card rounded-0 mb-2'>
                <div className='row mt-1 '>
                  <div className='col-8 d-flex justify-content-start'>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 25, height: 25, }}>
                      <Clock />
                    </button>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 25, height: 25, fontSize: '0.8em' }}>
                      W
                    </button>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 25, height: 25, fontSize: '0.8em' }}>
                      M
                    </button>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 25, height: 25, fontSize: '0.8em' }}>
                      Y
                    </button>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 120, height: 25, fontSize: '0.8em' }}>
                      Custom Time
                    </button>
                  </div>
                  {/*<div className='col-4'>
                    <button type='button' className='mx-1 justify-content-start align-items-center d-flex download-chart-btn px-2' style={{ width: 180, height: 25, fontSize: '0.8em' }}>
                      Download Chart<span className='px-2'><FiDownloadCloud /></span>
                    </button>
            </div>*/}
                </div>
                <div className="app">
                  <div className="row">
                    <div className="mixed-chart">
                      <Chart
                        options={state.options}
                        series={state.series}
                        color={state.colors}
                        type="area"
                        width="100%"
                        height="300"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CTabPane>
            <CTabPane role="tabpanel" aria-labelledby="profile-tab" visible={activeKey === 2}>
              <div className='card rounded-0 mb-2'>
                <div className='row mt-1 m-1'>
                  <div className='col-8 d-flex justify-content-start'>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 25, height: 25, }}>
                      <Clock />
                    </button>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 25, height: 25, fontSize: '0.8em' }}>
                      W
                    </button>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 25, height: 25, fontSize: '0.8em' }}>
                      M
                    </button>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 25, height: 25, fontSize: '0.8em' }}>
                      Y
                    </button>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 120, height: 25, fontSize: '0.8em' }}>
                      Custom Time
                    </button>
                  </div>
                 { /*<div className='col-4'>
                    <button type='button' className='mx-1 justify-content-start align-items-center d-flex download-chart-btn px-2' style={{ width: 180, height: 25, fontSize: '0.8em' }}>
                      Download Chart<span className='px-2'><FiDownloadCloud /></span>
                    </button>
          </div>*/}
                </div>
                <div className="app">
                  <div className="row">
                    <div className="mixed-chart">
                      <Chart
                        options={debitstate.options}
                        series={debitstate.series}
                        color={debitstate.colors}
                        type="area"
                        width="100%"
                        height="300"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CTabPane>
            <CTabPane role="tabpanel" aria-labelledby="contact-tab" visible={activeKey === 3}>
              <div className='card rounded-0 mb-2'>
                <div className='row mt-1 m-1'>
                  <div className='col-8 d-flex justify-content-start'>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 25, height: 25, }}>
                      <Clock />
                    </button>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 25, height: 25, fontSize: "0.8em" }}>
                      W
                    </button>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 25, height: 25, fontSize: '0.8em' }}>
                      M
                    </button>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 25, height: 25, fontSize: '0.8em' }}>
                      Y
                    </button>
                    <button type='button' className='mx-1 justify-content-center align-items-center d-flex chart-btn' style={{ width: 120, height: 25, fontSize: '0.8em' }}>
                      Custom Time
                    </button>
                  </div>
                 {/* <div className='col-4'>
                    <button type='button' className='mx-1 justify-content-start align-items-center d-flex download-chart-btn px-2' style={{ width: 180, height: 25, fontSize: '0.8em' }}>
                      Download Chart<span className='px-2'><FiDownloadCloud /></span>
                    </button>
        </div>*/}
                </div>
                <div className="app">
                  <div className="row">
                    <div className="mixed-chart">
                      <Chart
                        options={outst.options}
                        series={outst.series}
                        color={outst.colors}
                        type="area"
                        width="100%"
                        height="330"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CTabPane>
          </CTabContent>

          {/* <div className='row g-0'>
                         <div className='col-5 card rounded-0 me-auto' style={{width:'49%'}}>
                           <div className='row align-items-center g-0'>
                              <p className='col-6 text-start fs-5 p-1' style={{color:'#54536E'}}><u>Credit History</u></p>
                              <p className='col-6 text-end fs-5 p-1' style={{color:'#40997E'}}>₹ 3,00,00,000</p>
                              <hr style={{color:'#B1AFC8',}}></hr>           
                               <div class="card bar5" style={{border:'none',overflowX:'hidden'}}>
                                 {
                                   crhistory.map((item, index) => {
                                     return(
                                       <CreditHistory key={index} item={item} />
                                       )
                                      })
                                    }
                              </div>
                            </div> 
                          </div>
                         <div className='col-5 card rounded-0 ms-auto' style={{width:'49%'}}>
                          <div className='row align-items-center g-0'>
                              <p className='col-6 text-start fs-5 p-1' style={{color:'#54536E'}}><u>Debit History</u></p>
                              <p className='col-6 text-end fs-5 p-1' style={{color:'#F03838'}}>₹ 50,00,000</p>
                              <hr style={{color:'#B1AFC8',}}></hr>           
                               <div class="card bar5" style={{border:'none',overflowX:'hidden'}}>
                                 {
                                   dbthisdata.map((item, index) => {
                                     return(
                                       <DebitHistory key={index} item={item} />
                                       )
                                      })
                                    }
                              </div>
                            </div> 
                         </div>
                        </div>  */}


        </div>
        <div className='col-4 mt-0 m-4'>
          <div className='card rounded-0' style={{ border: "0.5px solid #E4E2F1", backgroundColor: "transparent", height: "62vh" }}>
            <div className='card-body'>
              <NavLink to="/Invoce" className="text-decoration-none">
                {/* <div className='d-flex justify-content-center'>
                          <button type='button' className='justify-content-center align-items-center d-flex p-3 mb-3' style={{width:'100%',fontSize:20,backgroundColor:'#8147E7',border:"none",color:'#fff'}}>
                              Invoices
                           </button>
                        </div> */}
              </NavLink>
              {/* <div className='d-flex justify-content-center'>
                        <button type='button' className='justify-content-center align-items-center d-flex p-3 text-start mb-3' style= {{width:'100%',fontSize:20,backgroundColor:'#F3F2F8',border:"0.5px solid #E4E2F1",color:'#54536E'}}>
                              Expenses  
                           </button>
                        </div> */}
              <div className='row mb-3 align-items-center g-0'>
                <p className='col-8 text-start fs-5'>Collection Report</p>
                <div className='col-4 ' style={{}} ><Shareblue /></div>
              </div>
              <hr style={{ borderColor: '#E4E2F1' }}></hr>
              <div className='row mb-3 align-items-center g-0'>
                <p className='col-8 text-start fs-6 p-0 m-0' style={{ color: '#54536E' }}>Total Expected Amount</p>
                <div className='col-4 p-0 m-0'><Shareblue /></div>
                <p className='text-start  p-0 m-0 pt-1' style={{ color: '#E2A925', fontSize: "25px" }}>₹ {Amount}</p>
              </div>
              <hr style={{ borderColor: '#E4E2F1' }}></hr>
              <div className='row mb-3 align-items-center g-0'>
                <p className='col-8 text-start fs-6 p-0 m-0' style={{ color: '#54536E' }}>Total Remaining Amount</p>
                <div className='col-4 p-0 m-0'><Shareblue /></div>
                <p className='text-start  p-0 m-0 pt-1' style={{ color: '#F03838', fontSize: "25px" }}>₹ {Amount1}</p>
              </div>
              <hr style={{ borderColor: '#E4E2F1' }}></hr>
              <div className='row mb-3 align-items-center g-0'>
                <p className='col-8 text-start fs-6 p-0 m-0' style={{ color: '#54536E' }}>Total Collected Amount</p>
                <div className='col-4 p-0 m-0'><Shareblue /></div>
                <p className='text-start  p-0 m-0 pt-1' style={{ color: '#40997E', fontSize: "25px" }}>₹ {Amount2}</p>
              </div>
              <hr style={{ borderColor: '#E4E2F1' }}></hr>
              <div className='row mb-3 align-items-center g-0'>
                <p className='col-6 text-start fs-6 p-0 m-0' style={{ color: '#54536E' }}>Growth Performance</p>
                {/* <div className='col-6 p-0 m-0'><Shareblue/></div> */}
                <p className='text-start  p-0 m-0 pt-1' style={{ color: '#40997E', fontSize: "25px" }}>+ 0%<span className='px-3' style={{ fontSize: 12, color: '#54536E' }}>Change in revenue</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}



const CreditHistory = (props) => {
  return (
    <div>
      <div className='d-flex justify-content-around align-items-center'>
        <p className='text-start p-0 m-0' style={{ fontSize: '0.8em' }}>{props.item.name}</p>
        <p className='text-start p-0 m-0' style={{ color: '#54536E0', fontStyle: "italic", fontSize: '0.8em' }}>{props.item.date}</p>
        <p className='text-start p-0 m-0' style={{ fontSize: '0.8em' }}>{props.item.fees}</p>
        <p className='text-start p-0 m-0' style={{ fontSize: '0.8em' }}>₹ {props.item.amount}</p>
        <BiChevronRight />
      </div>
      <hr style={{ color: '#B1AFC8', }}></hr>
    </div>

  )
}

const DebitHistory = (props) => {
  return (
    <div>
      <div className='d-flex justify-content-around align-items-center'>
        <p className='text-start' style={{ fontSize: '0.8em' }}>{props.item.name}</p>
        <p className='text-start' style={{ color: '#54536E', fontStyle: "italic", fontSize: '0.8em' }}>{props.item.date}</p>
        <p className='text-start' style={{ fontSize: '0.8em' }}>{props.item.fees}</p>
        <p className='text-start' style={{ fontSize: '0.8em' }}>₹ {props.item.amount}</p>
        <BiChevronRight />
      </div>
      <hr style={{ color: '#B1AFC8', }}></hr>
    </div>
  )
}



function Ai(props) {
  return (
    <>
      <div className='col-3'>

        <p className='pt-2'>{props.res.sn}</p>
      </div>

      <div className='col-3'>

        <p className='pt-2'>{props.res.remark}</p>
      </div>

      <div className='col-3'>

        <p className='pt-2' >{props.res.amount}</p>
      </div>

      <div className='col-3'>
        <p className='pt-2'>{props.res.duedate}</p>
      </div>

    </>
  );
}

function Aiii(props) {
  return (
    <>
      <div className='col-3'>

        <p className='pt-2'>{props.res.sn}</p>
      </div>

      <div className='col-3'>

        <p className='pt-2'>{props.res.remark}</p>
      </div>

      <div className='col-3'>

        <p className='pt-2' >{props.res.amount}</p>
      </div>

      <div className='col-3'>
        <p className='pt-2'>{props.res.duedate}</p>
      </div>

    </>
  );
}
