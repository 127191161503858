import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL, img_url } from "../env";

const ViewTK = () => {
  const { id } = useParams();
  const [task, setTask] = useState([]);
  const taskDetails = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      var raw = JSON.stringify({
        emp_id: id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${API_URL}/admin/employee/view/task/list/of/employee`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log
          // console.log("assigned by",result.tasks.title);
          if (result.status == 1) {
            setTask(result.tasks);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    taskDetails();
  }, []);

  const handleFileDownload = (fileUrl, fileName) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
    <div className="container">
      <div className="row pt-4" style={{ maxHeight: "95vh", overflowY: "auto" }}>
        {task.length === 0 ? (
          <div className="col-12 mx-auto mb-1">
            <h3 style={{color:"red",textAlign:'center'}} >No data found</h3>
          </div>
        ) : (
          task.map((data, value) => (
            <div className="col-10 mx-auto mb-1" key={value}>
              <div className="card p-2">
                <div className="d-flex justify-content-between align-items-center">
                  <p>
                    <span style={{ fontWeight: 500 }}>assigned_by :</span>{" "}
                    {data.assigned_by}
                  </p>
                  <span
                    className="p-1 px-3"
                    style={{
                      background:
                        data.status === "completed" ? "#dbfff0" : "#FFE9E9",
                      color:
                        data.status === "completed" ? "#359a73" : "#e91c1c",
                      fontWeight: 600,
                      borderRadius: "5px",
                    }}
                  >
                    {data.status ? data.status : "Incomplete"}
                  </span>
                </div>
                <div>
                  <p>
                    <span style={{ fontWeight: 500 }}>title :</span>{" "}
                    {data.title}
                  </p>
                  <p>
                    <span style={{ fontWeight: 500 }}>description :</span>{" "}
                    {data.des}
                  </p>
                  <p>
                    <span style={{ fontWeight: 500 }}>file:{" "}</span>
                    <a
                      href={data.files}
                      onClick={(e) => {
                        e.preventDefault();
                        handleFileDownload(data.files, data.title);
                      }}
                    >
                      Download File
                    </a>
                  </p>
                  <p>
                    <span style={{ fontWeight: 500 }}>due_date :</span>{" "}
                    {data.due_date}
                  </p>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
    </>
  );
};

export default ViewTK;
