import React from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";

const CreateNewMarksheetWait = () => {
  const Navigate = useNavigate();
  const confirmDetails = [
    {
      resultName: "BCA Sem 3 Result",
      courseName: "B.C.A Specialization in Artificial Intelligence",
      examsList: [["Chemistry Pre Fortly Exam", "12 September 2022"]],
      batchesList: [["3CO-JVY"]],
    },
  ];
  return (
    <>
      <div className="container-fluid">
        <div className="row p-0">
          <div className="col-12 p-0">
            <div
              className=" px-2 pt-0 mt-0 d-flex align-items-center"
              style={{
                height: "9vh",
                width: "100%",
                background: "#fff",
                boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
              }}
            >
              <div className="d-flex align-items-center ms-2">
                <IoIosArrowRoundBack
                  onClick={() => Navigate("/Marksheet")}
                  style={{
                    fontSize: "2rem",
                    color: "#3C3C3C",
                    cursor: "pointer",
                  }}
                />
                <span
                  className="ms-3"
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 600,
                    color: "#3D3D3D",
                    fontSize: "1.25rem",
                  }}
                >
                  Generate New Marksheet
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div
              style={{
                height: "91vh",
                overflowY: "auto",
                width: "100%",
                background: "#F9F9F9",
              }}
            >
              <div className="container-fluid h-100">
                <div className="row justify-content-center align-items-center h-100">
                  <div
                    className="col-5 p-3"
                    style={{
                      background: "#fff",
                      boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
                      maxHeight: "83vh",
                      overflowY: "auto",
                    }}
                  >
                    <h1
                      className="text-center"
                      style={{
                        fontFamily: "Inter",
                        fontSize: "1.5rem",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      Confirm Details
                    </h1>

                    <p
                      className="text-center"
                      style={{
                        fontFamily: "Inter",
                        fontSize: "1rem",
                        fontStyle: "normal",
                        fontWeight: 400,
                      }}
                    >
                      Cumulative Marksheets will be generated with the following
                      Specifications
                    </p>

                    <div className="mt-3">
                      <p
                        style={{
                          fontFamily: "Inter",
                          fontSize: "0.875rem",
                          fontWeight: 600,
                          fontStyle: "normal",
                          color: "#6C6C6C",
                        }}
                      >
                        Name of result
                      </p>
                      <p
                        className="p-2"
                        style={{
                          color: "#707070",
                          background: "#F5F4F7",
                          borderRadius: "5px",
                          userSelect: "none",
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontSize: "1rem",
                          fontWeight: 500,
                        }}
                      >
                        {confirmDetails[0].resultName}
                      </p>
                    </div>

                    <div className="mt-3">
                      <p
                        style={{
                          fontFamily: "Inter",
                          fontSize: "0.875rem",
                          fontWeight: 600,
                          fontStyle: "normal",
                          color: "#6C6C6C",
                        }}
                      >
                        Course
                      </p>
                      <p
                        className="p-2"
                        style={{
                          color: "#707070",
                          background: "#F5F4F7",
                          borderRadius: "5px",
                          userSelect: "none",
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontSize: "1rem",
                          fontWeight: 500,
                        }}
                      >
                        {confirmDetails[0].courseName}
                      </p>
                    </div>

                    <div className="mt-3 w-100">
                      <p
                        style={{
                          fontFamily: "Inter",
                          fontSize: "0.875rem",
                          fontWeight: 600,
                          fontStyle: "normal",
                          color: "#6C6C6C",
                        }}
                      >
                        Exams
                      </p>
                      <div
                        className="d-flex align-items-center gap-3"
                        style={{ width: "100%", overflowX: "scroll" }}
                      >
                        <div
                          className="p-2"
                          style={{
                            color: "#707070",
                            background: "#F5F4F7",
                            borderRadius: "5px",
                            userSelect: "none",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "1rem",
                            fontWeight: 500,
                            flexShrink: 0,
                          }}
                        >
                          <span
                            className="pb-0 mb-0"
                            style={{
                              color: "#4E4E4E",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.875rem",
                              fontWeight: 500,
                            }}
                          >
                            {confirmDetails[0].examsList[0][0]}
                          </span>
                          <p
                            className="mt-0 pt-0"
                            style={{
                              color: "#9b9b9b",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.625rem",
                              fontWeight: 400,
                            }}
                          >
                            {confirmDetails[0].examsList[0][1]}
                          </p>
                        </div>
                        <div
                          className="p-2"
                          style={{
                            color: "#707070",
                            background: "#F5F4F7",
                            borderRadius: "5px",
                            userSelect: "none",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "1rem",
                            fontWeight: 500,
                            flexShrink: 0,
                          }}
                        >
                          <span
                            className="pb-0 mb-0"
                            style={{
                              color: "#4E4E4E",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.875rem",
                              fontWeight: 500,
                            }}
                          >
                            {confirmDetails[0].examsList[0][0]}
                          </span>
                          <p
                            className="mt-0 pt-0"
                            style={{
                              color: "#9b9b9b",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.625rem",
                              fontWeight: 400,
                            }}
                          >
                            {confirmDetails[0].examsList[0][1]}
                          </p>
                        </div>
                        <div
                          className="p-2"
                          style={{
                            color: "#707070",
                            background: "#F5F4F7",
                            borderRadius: "5px",
                            userSelect: "none",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "1rem",
                            fontWeight: 500,
                            flexShrink: 0,
                          }}
                        >
                          <span
                            className="pb-0 mb-0"
                            style={{
                              color: "#4E4E4E",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.875rem",
                              fontWeight: 500,
                            }}
                          >
                            {confirmDetails[0].examsList[0][0]}
                          </span>
                          <p
                            className="mt-0 pt-0"
                            style={{
                              color: "#9b9b9b",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.625rem",
                              fontWeight: 400,
                            }}
                          >
                            {confirmDetails[0].examsList[0][1]}
                          </p>
                        </div>
                        <div
                          className="p-2"
                          style={{
                            color: "#707070",
                            background: "#F5F4F7",
                            borderRadius: "5px",
                            userSelect: "none",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "1rem",
                            fontWeight: 500,
                            flexShrink: 0,
                          }}
                        >
                          <span
                            className="pb-0 mb-0"
                            style={{
                              color: "#4E4E4E",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.875rem",
                              fontWeight: 500,
                            }}
                          >
                            {confirmDetails[0].examsList[0][0]}
                          </span>
                          <p
                            className="mt-0 pt-0"
                            style={{
                              color: "#9b9b9b",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.625rem",
                              fontWeight: 400,
                            }}
                          >
                            {confirmDetails[0].examsList[0][1]}
                          </p>
                        </div>
                        <div
                          className="p-2"
                          style={{
                            color: "#707070",
                            background: "#F5F4F7",
                            borderRadius: "5px",
                            userSelect: "none",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "1rem",
                            fontWeight: 500,
                            flexShrink: 0,
                          }}
                        >
                          <span
                            className="pb-0 mb-0"
                            style={{
                              color: "#4E4E4E",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.875rem",
                              fontWeight: 500,
                            }}
                          >
                            {confirmDetails[0].examsList[0][0]}
                          </span>
                          <p
                            className="mt-0 pt-0"
                            style={{
                              color: "#9b9b9b",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.625rem",
                              fontWeight: 400,
                            }}
                          >
                            {confirmDetails[0].examsList[0][1]}
                          </p>
                        </div>
                        <div
                          className="p-2"
                          style={{
                            color: "#707070",
                            background: "#F5F4F7",
                            borderRadius: "5px",
                            userSelect: "none",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "1rem",
                            fontWeight: 500,
                            flexShrink: 0,
                          }}
                        >
                          <span
                            className="pb-0 mb-0"
                            style={{
                              color: "#4E4E4E",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.875rem",
                              fontWeight: 500,
                            }}
                          >
                            {confirmDetails[0].examsList[0][0]}
                          </span>
                          <p
                            className="mt-0 pt-0"
                            style={{
                              color: "#9b9b9b",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.625rem",
                              fontWeight: 400,
                            }}
                          >
                            {confirmDetails[0].examsList[0][1]}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3 w-100">
                      <p
                        style={{
                          fontFamily: "Inter",
                          fontSize: "0.875rem",
                          fontWeight: 600,
                          fontStyle: "normal",
                          color: "#6C6C6C",
                        }}
                      >
                        Batches
                      </p>
                      <div
                        className="d-flex align-items-center gap-3"
                        style={{ width: "100%", overflowX: "scroll" }}
                      >
                        <div
                          className="p-2"
                          style={{
                            color: "#707070",
                            background: "#F5F4F7",
                            borderRadius: "5px",
                            userSelect: "none",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "1rem",
                            fontWeight: 500,
                            flexShrink: 0,
                          }}
                        >
                          <p
                            className=""
                            style={{
                              color: "#4E4E4E",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.875rem",
                              fontWeight: 500,
                            }}
                          >
                            {confirmDetails[0].batchesList[0]}
                          </p>
                        </div>
                        <div
                          className="p-2"
                          style={{
                            color: "#707070",
                            background: "#F5F4F7",
                            borderRadius: "5px",
                            userSelect: "none",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "1rem",
                            fontWeight: 500,
                            flexShrink: 0,
                          }}
                        >
                          <p
                            className=""
                            style={{
                              color: "#4E4E4E",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.875rem",
                              fontWeight: 500,
                            }}
                          >
                            {confirmDetails[0].batchesList[0]}
                          </p>
                        </div>
                        <div
                          className="p-2"
                          style={{
                            color: "#707070",
                            background: "#F5F4F7",
                            borderRadius: "5px",
                            userSelect: "none",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "1rem",
                            fontWeight: 500,
                            flexShrink: 0,
                          }}
                        >
                          <p
                            className=""
                            style={{
                              color: "#4E4E4E",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.875rem",
                              fontWeight: 500,
                            }}
                          >
                            {confirmDetails[0].batchesList[0]}
                          </p>
                        </div>
                        <div
                          className="p-2"
                          style={{
                            color: "#707070",
                            background: "#F5F4F7",
                            borderRadius: "5px",
                            userSelect: "none",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "1rem",
                            fontWeight: 500,
                            flexShrink: 0,
                          }}
                        >
                          <p
                            className=""
                            style={{
                              color: "#4E4E4E",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.875rem",
                              fontWeight: 500,
                            }}
                          >
                            {confirmDetails[0].batchesList[0]}
                          </p>
                        </div>
                        <div
                          className="p-2"
                          style={{
                            color: "#707070",
                            background: "#F5F4F7",
                            borderRadius: "5px",
                            userSelect: "none",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "1rem",
                            fontWeight: 500,
                            flexShrink: 0,
                          }}
                        >
                          <p
                            className=""
                            style={{
                              color: "#4E4E4E",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.875rem",
                              fontWeight: 500,
                            }}
                          >
                            {confirmDetails[0].batchesList[0]}
                          </p>
                        </div>
                        <div
                          className="p-2"
                          style={{
                            color: "#707070",
                            background: "#F5F4F7",
                            borderRadius: "5px",
                            userSelect: "none",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "1rem",
                            fontWeight: 500,
                            flexShrink: 0,
                          }}
                        >
                          <p
                            className=""
                            style={{
                              color: "#4E4E4E",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.875rem",
                              fontWeight: 500,
                            }}
                          >
                            {confirmDetails[0].batchesList[0]}
                          </p>
                        </div>
                        <div
                          className="p-2"
                          style={{
                            color: "#707070",
                            background: "#F5F4F7",
                            borderRadius: "5px",
                            userSelect: "none",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "1rem",
                            fontWeight: 500,
                            flexShrink: 0,
                          }}
                        >
                          <p
                            className=""
                            style={{
                              color: "#4E4E4E",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.875rem",
                              fontWeight: 500,
                            }}
                          >
                            {confirmDetails[0].batchesList[0]}
                          </p>
                        </div>
                        <div
                          className="p-2"
                          style={{
                            color: "#707070",
                            background: "#F5F4F7",
                            borderRadius: "5px",
                            userSelect: "none",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "1rem",
                            fontWeight: 500,
                            flexShrink: 0,
                          }}
                        >
                          <p
                            className=""
                            style={{
                              color: "#4E4E4E",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "0.875rem",
                              fontWeight: 500,
                            }}
                          >
                            {confirmDetails[0].batchesList[0]}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <div className="container">
                        <div className="row mx-auto">
                            <div className="col-5 mx-auto" >
                                <button className="w-100 py-3 border-0" style={{borderRadius:"5px",fontFamily:"Inter",fontStyle:"normal",fontWeight:500,fontSize:"1rem",backgroundColor:"#E6D8FF",color:"#8147e7"}} onClick={()=>Navigate('/CreateNewMarksheet')} >Cancel</button>
                            </div>
                            <div className="col-5 mx-auto" >
                                <button onClick={()=>{Navigate('/CreateNewMarksheetConfirm')}} className="w-100 py-3 border-0" style={{borderRadius:"5px",fontFamily:"Inter",fontStyle:"normal",fontWeight:500,fontSize:"1rem",backgroundColor:"#8147E7",color:"#fff"}} >Confirm & Create</button>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewMarksheetWait;
