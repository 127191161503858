import { border } from "@mui/system";
import React from "react";
import { FiDownload } from "react-icons/fi";
import Studentprofile from './studentprofile';


export default function Fees(props) {

    
   console.log(props.item);
   
    return (
        <>



            <div className="container-flude">


                <div className="row" style={{ marginLeft: "29px" }}>

                {props.item?.map((res, key) => {
                        return (

                            <div className="col-2" key={key}>
                                <div style={{ border: "1px solid #000000", background: "#FFFFFF", borderRadius: "10px", marginTop: "35px", width: "100%", padding: "15px" }}>
                                    <p className="" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "20px", color: "#434343", }}> Total Amount</p>
                                    <p className="mt-2" style={{ fontStyle: "normal", fontWeight: "600", fontSize: "30px", color: "#434343", }}>₹ {res.finalAmount}</p>
                                </div>
                                <div className="mt-5" style={{ border: "1px solid #000000", backgroundColor: "#FFEFEF", borderRadius: "10px", padding: "10px", marginTop: "30px", width: "100%" }}>
                                    <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "18px", color: "#434343", }}>Amount Remaining</p>
                                    <p className="mt-2" style={{ fontStyle: "normal", fontWeight: "600", fontSize: "30px", color: "#434343", }}>₹ 0</p>
                                </div>
                            </div>)
                    })}


                    <div className="col-4 mx-4" style={{ background: "#FFFFFF", border: "1px solid rgb(230 230 230)", borderRadius: "10px", width: "350px", marginTop: "35px", height: "350px", borderRadius: "10px" }}>
                        <div className="mt-3 mb-3 mx-2" style={{ display: "flex" }}>
                            <p style={{ fontStyle: "normal", fontWeight: "700", fontSize: "14px", color: "#434343", }}>Payment History</p>
                            <p className="" style={{ width: "10%", padding: "3px 7px", background: "#FFFFFF", border: "1px solid #D1D1D1", borderRadius: "5px", marginLeft: "156px", }}><FiDownload /></p>
                        </div>

                        <div style={{ display: "flex" }}>
                            <p className="mx-2" style={{ fontStyle: "normal", fontWeight: "500", fontSize: "14px", color: "#434343", }}>Date</p>
                            <p className="mx-5" style={{ paddingLeft: "58px", fontStyle: "normal", fontWeight: "500", fontSize: "14px", color: "#434343", }}>Amount</p>
                        </div >
                        {/* map */}
                        {/* {borderr.map((res, key) => {
                            return (
                                <div className="mt-2" key={key} style={{ border: "1px solid #D1D1D1", display: "flex", width: "320px", borderRadius: "5px", height: "40px" }} >
                                    <p className="mx-2" style={{ marginTop: "5px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#434343", }}>3 mar 2023</p>
                                    <p className="mx-5 mt-2" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#434343", }}>INR 52,526</p>
                                    <FiDownload style={{ marginLeft: "15px", marginTop: "7px" }} />
                                </div>
                            )
                        })} */}
                        <div className="pt-4">
                            <h4 className="text-center mt-5">Coming soon..</h4>
                        </div>
                    </div>

                    <div className="col-4 mx-4" style={{ background: "#FFFFFF", border: "1px solid rgb(230 230 230)", borderRadius: "10px", width: "350px", marginTop: "35px", height: "350px", borderRadius: "10px" }}>
                        <div className="mt-3 mb-3 mx-1" style={{ display: "flex" }}>
                            <p style={{ fontStyle: "normal", fontWeight: "700", fontSize: "14px", color: "#434343", }}>Payment Expected</p>
                            {/* <p className="" style={{width: "10%",padding:"3px 7px",background:"#FFFFFF",border:"1px solid #D1D1D1",borderRadius:"5px",marginLeft:"155px",}}><FiDownload/></p> */}
                        </div>

                        <div style={{ display: "flex" }}>
                            <p className="mx-2" style={{ fontStyle: "normal", fontWeight: "500", fontSize: "14px", color: "#434343", }}>Date</p>
                            <p className="mx-5" style={{ paddingLeft: "100px", fontStyle: "normal", fontWeight: "500", fontSize: "14px", color: "#434343", }}>Amount</p>
                        </div>
                        {/* map */}
                        {/* {borderr.map((res, key) => {
                            return (
                                <div className="mt-2" key={key} style={{ border: "1px solid #D1D1D1", display: "flex", width: "320px", borderRadius: "5px", height: "40px" }} >
                                    <p className="mx-2" style={{ marginTop: "5px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#434343", }}>3 mar 2023</p>
                                    <p className=" mt-2" style={{ marginLeft: "6rem", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#434343", }}>INR 52,526</p>
                                    {/* <FiDownload style={{marginLeft:"15px",marginTop:"7px"}}/>     
                                </div>
                            )
                        })} */}

                        <div className="pt-4">
                            <h4 className="text-center mt-5">Coming soon..</h4>
                        </div>

                    </div>


                    <div className="col-4 mx-4" style={{ border: "1px solid rgb(230 230 230)", width: "350px", marginTop: "35px", height: "350px", borderRadius: "10px" }}>
                        <div className="mt-3 mb-3 mx-2" style={{ display: "flex" }}>
                            <p style={{ fontStyle: "normal", fontWeight: "700", fontSize: "14px", color: "#434343", }}>Default Payments</p>
                            {/* <p className="" style={{width: "10%",padding:"3px 7px",background:"#FFFFFF",border:"1px solid #D1D1D1",borderRadius:"5px",marginLeft:"155px",}}><FiDownload/></p> */}
                        </div>

                        <div style={{ display: "flex" }}>
                            <p className="mx-2" style={{ fontStyle: "normal", fontWeight: "500", fontSize: "14px", color: "#434343", }}>Date</p>
                            <p className="mx-5" style={{ paddingLeft: "100px", fontStyle: "normal", fontWeight: "500", fontSize: "14px", color: "#434343", }}>Amount</p>
                        </div>
                        {/* map */}
                        {/* {borderr.map((res, key) => {
                            return (
                                <div className="mt-2" key={key} style={{ border: "1px solid #D1D1D1", display: "flex", width: "320px", borderRadius: "5px", height: "40px" }} >
                                    <p className="mx-2" style={{ marginTop: "5px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#434343", }}>3 mar 2023</p>
                                    <p className=" mt-2" style={{ marginLeft: "6rem", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#434343", }}>INR 52,526</p>
                                    {/* <FiDownload style={{marginLeft:"15px",marginTop:"7px"}}/>     
                                </div>
                            )
                        })} */}
                        <div className="pt-4">
                            <h4 className="text-center mt-5">Coming soon..</h4>
                        </div>

                    </div>

                </div>
            </div>

        </>
    )
}