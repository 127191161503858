import React, { useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { BiRefresh } from "react-icons/bi";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";

const CreateNewMarksheet = () => {
  const Navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");
  const [resultName, setResultName] = useState("");
  const [value, onChange] = useState(new Date());
  const [value1, onChange1] = useState(new Date());
  const [loadBatches, setLoadBatches] = useState("0");

  function batchLoad() {
    if (loadBatches == 0) {
      setLoadBatches(1);
    } else {
      setLoadBatches(0);
    }
  }

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const selectExams = [
    {
      examName: "Chemistry Pro Sem Fortly Exam",
      examDate: "12 December 2023",
    },
    {
      examName: "Chemistry Pro Sem Fortly Exam",
      examDate: "12 December 2023",
    },
    {
      examName: "Chemistry Pro Sem Fortly Exam",
      examDate: "12 December 2023",
    },
  ];

  const selectBatches = [
    {
      batchName: "3CO-JVY",
    },
    {
      batchName: "3CO-JVY",
    },
    {
      batchName: "3CO-JVY",
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row p-0">
          <div className="col-12 p-0">
            <div
              className=" px-2 pt-0 mt-0 d-flex align-items-center"
              style={{
                height: "9vh",
                width: "100%",
                background: "#fff",
                boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
              }}
            >
              <div className="d-flex align-items-center ms-2">
                <IoIosArrowRoundBack
                  onClick={() => Navigate("/Marksheet")}
                  style={{
                    fontSize: "2rem",
                    color: "#3C3C3C",
                    cursor: "pointer",
                  }}
                />
                <span
                  className="ms-3"
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 600,
                    color: "#3D3D3D",
                    fontSize: "1.25rem",
                  }}
                >
                  Generate New Marksheet
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div
              style={{
                height: "91vh",
                overflowY: "auto",
                width: "100%",
                background: "#F9F9F9",
              }}
            >
              <div className="container-fluid h-100">
                <div className="row justify-content-center h-100 align-items-center ">
                  <div
                    className="col-5 p-3"
                    style={{
                      background: "#fff",
                      boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
                      maxHeight: "83vh",
                      overflowY: "auto",
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Enter name of result"
                      className="w-100 p-2 border-0"
                      onChange={(e) => setResultName(e.target.value)}
                      style={{ borderRadius: "5px", background: "#F5F4F7" }}
                    />
                    <select
                      class="form-select"
                      className="p-2 w-100 mt-3 border-0"
                      style={{ borderRadius: "5px", background: "#F5F4F7" }}
                      aria-label="Default select example"
                      value={selectedOption}
                      onChange={handleSelectChange}
                    >
                      <option selected disabled hidden>
                        Select Course
                      </option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>

                    <div className="mt-3 d-flex align-items-center gap-2">
                      <span
                        style={{
                          color: "#434343",
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontSize: "1rem",
                          fontWeight: 500,
                        }}
                      >
                        From
                      </span>
                      <DatePicker
                        onChange={onChange}
                        value={value}
                        className="datePicker"
                      />
                      <span
                        style={{
                          color: "#434343",
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontSize: "1rem",
                          fontWeight: 500,
                        }}
                      >
                        to
                      </span>
                      <DatePicker
                        onChange={onChange1}
                        value={value1}
                        className="datePicker"
                      />
                    </div>

                    <div
                      className="mt-3 p-3"
                      style={{
                        border: "1px solid #D7D7D7",
                        height: "25vh",
                        overflowY: "auto",
                      }}
                    >
                      <p
                        style={{
                          color: "#4E4E4E",
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "1rem",
                        }}
                      >
                        Select Exams
                      </p>
                      {selectExams.map((value, index) => (
                        <div
                          key={index}
                          className="d-flex align-items-center gap-2 mt-3 pb-3"
                          style={{ borderBottom: "1px solid #D6D6D6" }}
                        >
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                          />
                          <div style={{ lineHeight: "0px" }}>
                            <h6
                              style={{
                                color: "#4e4e4e",
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "0.875rem",
                              }}
                            >
                              {value.examName}
                            </h6>
                            <span
                              className="p-0 m-0"
                              style={{
                                color: "#9b9b9b",
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "0.625rem",
                              }}
                            >
                              {value.examDate}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3 w-100">
                      <button
                        onClick={batchLoad}
                        className="border-0 p-2 "
                        style={{ background: "#F2EDFD", color: "#8147E7" }}
                      >
                        <BiRefresh /> Load Batches
                      </button>
                    </div>

                    {loadBatches == 1 ? (
                      <>
                        <div
                          className="mt-3 p-3"
                          style={{
                            border: "1px solid #D7D7D7",
                            height: "25vh",
                            overflowY: "auto",
                          }}
                        >
                          <p
                            style={{
                              color: "#4E4E4E",
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "1rem",
                            }}
                          >
                            Select Batches
                          </p>
                          {selectBatches.map((value, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center gap-2 mt-3 pb-3"
                              style={{ borderBottom: "1px solid #D6D6D6" }}
                            >
                              <input
                                type="checkbox"
                                style={{ cursor: "pointer" }}
                              />
                              <div>
                                <h6
                                  style={{
                                    color: "#4e4e4e",
                                    fontFamily: "Inter",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "0.875rem",
                                  }}
                                >
                                  {value.batchName}
                                </h6>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div className="w-100 mt-3 bg-white">
                      <button
                        className="w-100 py-3 border-0"
                        style={{
                          background: "#8147E7",
                          color: "#fff",
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "1rem",
                          borderRadius: "5px",
                        }}
                        onClick={() => Navigate("/CreateNewMarksheetWait")}
                      >
                        +Create New Marksheet
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewMarksheet;
