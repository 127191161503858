import React, { useState,useEffect } from 'react';
 import TotalAllLeads from './TotalAllLeads';
 import TotalInConversation from './TotalInConversation';
 import TotalDropped from './TotalDropped';
 import TotalConverted from './TotalConverted'
 import {NavLink} from 'react-router-dom';
 import {BsArrowLeft} from 'react-icons/bs';
export default function Invoiesa() {
    const [value20,setvalue20]= useState();
     
    useEffect(()=>{
        setvalue20('TotalAllLeads')
       },[])
    return(
        <>
        <div className="row ">
          <div className="col-2 bg-white" style={{backgroundColor:"#fff",height:"100vh",}}>
       <p className='ms-4  ' style={{fontWeight:"600",color:"#3C3C3C",fontSize:"25px",marginTop:"40px"}}>  <NavLink to="/Lead" className="" style={{zIndex:"999",  }}>
       <BsArrowLeft className='ps-1'  style={{color:'#000000',fontSize:"25px",cursor:"pointer"}}/> 
      </NavLink> Leads</p> 
          <span className='mt-4' onClick={()=>{setvalue20('TotalAllLeads')}} 
       style={{ display:"flex",backgroundColor:value20=="TotalAllLeads"?"#F4F2F9":"",border:"none",marginLeft:"30px",height:"45px",borderRadius:"5px",color:"#8A8A8A"}}> 
           <p  className='pt-2' style={{zIndex:'999',paddingLeft:"30px",color:value20==="TotalAllLeads"?"#8147E7":"",fontWeight:"400",cursor:"pointer"}}>All Leads</p></span> 

           <span className='mt-3' onClick={()=>{setvalue20('TotalInConversation')}} 
       style={{ display:"flex",backgroundColor:value20=="TotalInConversation"?"#F4F2F9":"",marginLeft:"30px",height:"45px",borderRadius:"5px",color:"#8A8A8A"}}> 
           <p  className='pt-2' style={{zIndex:'999',paddingLeft:"30px",color:value20==="TotalInConversation"?"#8147E7":"",fontWeight:"400",cursor:"pointer"}}>In Conversation</p></span> 
           
           <span className='mt-3' onClick={()=>{setvalue20('TotalDropped')}} 
       style={{ display:"flex",backgroundColor:value20=="TotalDropped"?"#F4F2F9":"",marginLeft:"30px",height:"45px",borderRadius:"5px",color:" #8A8A8A"}}> 
           <p  className='pt-2' style={{zIndex:'999',paddingLeft:"30px",color:value20==="TotalDropped"?"#8147E7":"",fontWeight:"400",cursor:"pointer"}}>Dropped</p></span> 
           <span className='mt-3' onClick={()=>{setvalue20('TotalConverted')}} 
       style={{ display:"flex",backgroundColor:value20=="TotalConverted"?"#F4F2F9":"",marginLeft:"30px",height:"45px",borderRadius:"5px",color:" #8A8A8A"}}> 
           <p  className='pt-2' style={{zIndex:'999',paddingLeft:"30px",color:value20==="TotalConverted"?"#8147E7":"",fontWeight:"400",cursor:"pointer"}}>Converted</p></span> 

           {/* <span className='mt-3' onClick={()=>{setvalue20('Defaulterstudents')}} 
       style={{ display:"flex",backgroundColor:value20=="Defaulterstudents"?"#F4F2F9":"",marginLeft:"30px",height:"45px",borderRadius:"5px",color:" #8A8A8A"}}> 
           <p className='pt-2' style={{zIndex:'999',paddingLeft:"30px",color:value20==="Defaulterstudents"?"#8147E7":"",fontWeight:"400"}}>Defaulter</p></span>  */}
          </div>

          <div className="col-10" style={{background:"rgb(240 245 254)",}}>
          {
            value20==='TotalAllLeads'?<><TotalAllLeads/></>:<></>
            }
            {
            value20==='TotalInConversation'?<><TotalInConversation/></>:<></>
            }
            {
            value20==='TotalDropped'?<><TotalDropped/></>:<></>
            }
            {
            value20==='TotalConverted'?<><TotalConverted/></>:<></>
            }
          </div>
        </div>

      
       
            
        </>
    )
}