import { useState, useEffect } from "react";
import {AiOutlineDelete} from 'react-icons/ai'
import { API_URL } from "../env";
import {GrUpdate} from 'react-icons/gr'
import Offcanvas from "react-bootstrap/Offcanvas"; 
import { ToastContainer, toast } from 'react-toastify';
 
const BannerData = () => {
  const [show, setShow] = useState(false);
  const [Images, setImages] = useState();
  const [Banner, setBanner] = useState();
  const [Datas, setData] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const send = async () => {
    var formdata = new FormData();
    formdata.append("panel", "");
    formdata.append("image_path", Banner);
    
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${API_URL}/add_banner?panel&image_path`, requestOptions)
      .then(response => response.json())
      .then(result => {console.log(result);
      if(result.status=="001"){
        handleClose();
        Data();
        toast(result.message);
        setImages();
        setBanner();
      }
    else{
      toast(result.message);
      setImages();
      setBanner();
      handleClose();
    }})
      .catch(error => console.log('error', error));

  };

  const Data = async () => {
    try {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      fetch(`${API_URL}/Listbanner`, requestOptions)
        .then(response => response.json())
        .then(result => {console.log(result);
        if(result.status=="001"){
           setData(result.listBanner);
        }})
        .catch(error => console.log('error', error));
    } catch (error) {
      console.log(error);
    }
  };

  //function for hover buttons
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  useEffect(() => {
    Data();
  }, []);

  return (
    <>
    <ToastContainer />
      <div className="row">
        <div className="col-12 mb-2">
          <div className="row">
            <div className="col-2">
              <span
                className="mt-2"
                style={{
                  fontWeight: "600",
                  color: "#000000",
                  fontSize: 17,
                  cursor: "pointer",
                }}
              >
                Banner Images
              </span>
            </div>
            <button
              onClick={handleShow}
              className="col-2"
              style={{
                backgroundColor: "#EDF7FF",
                border: "1px solid #2188E7",
                height: "40px",
                borderRadius: "10px",
                color: "#2188E7",
              }}
            >
              <span className="p-3"> + Banner Images</span>
            </button>
          </div>
        </div>

        <div
          className="col-2"
          style={{
            borderBottom: "2px solid #000000",
          }}
        ></div>
        <hr />
      </div>

      <div
        className="row"
        style={{ height: "72vh", overflowY: "scroll", overflowX: "hidden" }}
      >
        {Datas?.map((res, key) => {
          return (
            <>
              <div  className="col-4 mt-2">

                <div className="card"  
                 onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                  style={{
                  height: 'auto',
                  cursor: "pointer",
                  // backgroundImage: `url(${res.image})`,
                  // backgroundSize: "contain",
                  // backgroundPosition: "top",
                  // background: "cover",
                  // backgroundRepeat: "no-repeat",
                }}> 
                <img alt="#" src={res.image_path} style={{ borderRadius:'5px 5px 0px 0px' }} />
                    <button
                      style={{
                        border: "none",
                        borderRadius: "10",
                        color:'red',
                        backgroundColor:'#ffcaca',
                         padding:'3px 10px',
                      }}
                      // onClick={handleDelete}
                    ><AiOutlineDelete/> Delete</button>
                   {/*<button
                      style={{
                        border: "none",
                        borderRadius: ".5rem",
                        color:'#1e6209',
                        backgroundColor:'#bcffbc',
                         padding:'3px 10px',
                      }}
                      onClick={handleUpdate}
                    > <GrUpdate className="me-2" style={{fontSize:'13px'}}/>Update</button>*/} 
                
             
              </div>
              </div>
            </>
          );
        })}
      </div>

      <Offcanvas
        show={show}
        className="w-50"
        onHide={handleClose}
        placement="end"
        style={{ borderRadius: "20px 5px 0px 0px" }}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row">
            <div className="col-3"></div>
            <div className="col-6">
              <label className="my-2" style={{ fontSize: '18px', fontWeight: 600 }}>
                Upload Banner Image
              </label>
              <label
                className="filelabel1 w-100"
                style={{
                  height: "170px",
                  marginLeft: "0px",
                  backgroundColor: "#F5F5F5",
                }}
              >
                <span className="title1">
                  <img src={Images} className="d-flex" style={{ width: "100%", height: "140px" }}/>
                  150px
                  <span style={{ fontSize: 20, fontWeight: 600 }}> x </span>
                  385px
                </span>
                <input
                  className="FileUpload1"
                  id="FileInput1"
                  name="booking_attachment1"
                  type="file"
                  accept=".png,.jpg"
                  onChange={(e) => {
                    setImages(URL.createObjectURL(e.target.files[0]));
                    setBanner(e.target.files[0]);
                  }}
                />
              </label>
            </div>
            <div className="col-3"></div>
          </div>
         

         
          <div className="row mt-3">
            <div className="col-4"></div>
            <div className="col-4">
              <button
                type="submit"
                className="border-0 w-100 text-white mt-5"
                style={{
                  backgroundColor: "#2188E7",
                  height: "40px",
                  borderRadius: 7,
                }}
                onClick={send}
              >
                Save
              </button>
            </div>
            <div className="col-4"></div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default BannerData;
