import React,{useContext,useEffect,useState}from 'react'
import '../App.css'
import Lottie from 'lottie-react'
import A2 from '../images/user.json';
import { API_URL } from "../env";
import { AuthContext } from "./AuthContext";

function ViewProfile() {
  // const { apiToken } = useContext(AuthContext);
  const [data, setData] = useState([])
  const [data1, setData1] = useState(0)



const profileget = async()=>{
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
  
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  
  fetch(`${API_URL}/admin/profile/view/profile/admin`, requestOptions)
  .then(response => response.json())
  .then((result) => {
   { 
    console.log(result)
    if(result.status == 1){
      setData(result.data)
    }else {
      setData1(1);
    }
  }
  })
  .catch(error => console.log('error', error));
   
}


useEffect(() => {
  profileget()
}, [])
console.log(data.length)
  
  return (
    <div className='container mt-5 ' style={{display:"flex",justifyContent:"center",alignItems:"center"}} >
      
      <div >
      <div class="card card-sadows" style={{width: "18rem"}}>
        <div>

      <Lottie animationData={A2} style={{ width: "90%", height: "50%", marginLeft: "0rem", }} />
        </div>
       
  <div class="card-body ms-3 ">
  {/* {
  data.length > 0 ? 
    <> */}
      {/* <h5 className="card-title"><b>Name</b> : {data.name}</h5>
      <p className="card-text"><b>Salary</b> : {data.salary}</p>
      <p className="card-text"><b>Department</b> :{data.department_name}</p>
      <p className="card-text"><b>email</b> :{data.email}</p>
      <p className="card-text"><b>dob</b> :{data.dob} </p>
      <p className="card-text"><b>join_date</b> :{data.join_date}</p> */}
    {/* </>
  :<>
    <p style={{textAlign:'center',color:'red'}}>No Data Found!</p>
  </> */}
{/* } */}
{
  data1==0?
  <>
  <h5 className="card-title"><b>Name</b> : {data.name}</h5>
      <p className="card-text"><b>Salary</b> : {data.salary}</p>
      <p className="card-text"><b>Department</b> :{data.department_name}</p>
      <p className="card-text"><b>email</b> :{data.email}</p>
      <p className="card-text"><b>dob</b> :{data.dob} </p>
      <p className="card-text"><b>join_date</b> :{data.join_date}</p>
  </>:<>
  <p style={{textAlign:'center',color:'red'}}>No Data Found!</p>
  </>
}
    
   
  </div>    
</div>
      </div>
    </div>
  )
}

export default ViewProfile