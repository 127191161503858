
import "./App.css";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import Dashboard from "./components/dashboard";
import Academics from "./components/academics";
import Accounts from "./components/accounts";
import Accounts2 from "./components/accounts1/Accounts";
import Support from "./components/support";
import Students from "./components/students";
import Analytics from "./components/analytics";
import Team from "./components/team";
import Employeeprofile from "./components/employeeprofile";
import Calendar from "./components/calendar";
import Studentprofile from "./components/studentprofile";
import Course from "./components/course";
import Lead from "./components/lead";
import Invoce from "./components/invoce";
import Profile from "./components/profile";
import Chat from "./components/chat";
import Department from "./components/department";
import Attendance from "./components/attendance";
import Batch from "./components/batch";
import Subject from "./components/subject";
import TestPage from "./components/testpage";
import Grantleave from "./components/grantleave";
import Salarymanagement from "./components/salarymanagement";
import Grantleaveviewdetail from "./components/grantleaveviewdetail";
import Addstudents from "./components/addstudents";
import Invoiesa from "./components/invoiesa";
import Makepayment from "./components/makepayment";
import Reportrequest from "./components/reportrequest";
import Mylead from './components/Mylead';
import TotalLeads from './components/TotalLeads';



import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  NavLink,
} from "react-router-dom";
import Expense from "./components/expence";
import Paymake from "./components/paymake";
//import PartiallyMake from './components/PartiallyMake';
import Partiallymake from "./components/partialmakepayment";
import Studentviewlist from "./components/studentviewlist";
import { AuthProvider } from "./components/AuthContext";
import Setting from "./components/Setting";
import Setting2 from "./components/Setting2";
import Profilestudent from "./components/Profilestudent";
import BatchHistory from "./components/batchHistory";
import BhProfile from "./components/bhProfile";
import BannerImage from "./components/BannerImage";
import AddExcel from "./components/addExcel";
import PaymentSetup from "./components/PaymentSetup";
import PaymentHistory from "./components/PaymentHistory";
import SchoolCode from "./components/SchoolCode";
import AuthenticatedRoute from "./AuthenticatedRoute";
import ProtectedRoute from "./ProtectedRoute";
import Marksheet from "./components/Marksheet";
import CreateNewMarksheet from "./components/CreateNewMarksheet";
import CreateNewMarksheetWait from "./components/CreateNewMarksheetWait";
import CreateNewMarksheetConfirm from "./components/CreateNewMarksheetConfirm";
import CreateNewMarksheetSucces from "./components/CreateNewMarksheetSucces";
import ViewMarksheet from "./components/ViewMarksheet";
import S from "./components/S";
import StudAttendance from "./components/StudAttendance";
import ParticularDepartment from "./components/accounts1/ParticularDepartment";
import StudentReceived from "./components/accounts1/StudentReceived";
import StudentUpcoming from "./components/accounts1/StudentUpcoming";
import StudentMissed from "./components/accounts1/StudentMissed";
import StudRecDetails from "./components/accounts1/StudRecDetails";
import StudUpcDetails from "./components/accounts1/StudUpcDetails";
import StudMissDetails from "./components/accounts1/StudMissDetails";
import Addbutton from "./components/Addbutton";
import EditPaymentDetails from "./components/EditPaymentDetails";
import GenerateSlipt from "./components/GenerateSlipt";
import UpdateSlipt from "./components/UpdateSlipt";



function App() {
  const myVariable = window.myGlobalVariable;

  console.log(myVariable);

  return (
    <AuthProvider>
      <div className="App">
        <BrowserRouter>
          <Routes>
            {/* <Route path="*" element={<Navigate to="/SchoolCode" replace />} /> */}
            {/* <Route path="*" element={<Navigate to="/code" replace />} /> */}
            <Route index element={<Navigate to="/code" replace />} />
            <Route path="/code" element={<SchoolCode />} />
            <Route path='/' element={<ProtectedRoute />}>
              <Route path="/login" element={<TestPage />} />
              {/* <Route path="/" element={<AuthenticatedRoute />}> */}
              <Route path="/Header" element={<Header />} />
              <Route path="/Sidebar" element={<Sidebar />} />
              <Route path="/Dashboard" element={<Dashboard />} />
              <Route path="/Academics" element={<Academics />} />
              <Route path="/Accounts" element={<Accounts />} />
              <Route path="/Accounts2" element={<Accounts2 />} />
              <Route path="/Accounts/student/received" element={<StudentReceived />} />
              <Route path="/Accounts/student/received/:id" element={<StudRecDetails />} />
              <Route path="/Accounts/student/upcoming" element={<StudentUpcoming />} />
              <Route path="/Accounts/student/upcoming/:id" element={<StudUpcDetails />} />
              <Route path="/Accounts/student/missed" element={<StudentMissed />} />
              <Route path="/Accounts/student/missed/:id" element={<StudMissDetails />} />
              <Route path="/Accounts/employee/particular/department/:id/:department" element={<ParticularDepartment />} />
              {/* <Route path="/Support" element={<Support />} />*/}
              <Route path="/Support" element={<Support/>} />
              {/* <Route path="/Students" element={<Students />} /> */}
              <Route path="/Analytics" element={<Analytics />} />
              <Route path="/Team" element={<Team />} />
              <Route path="/Calendar" element={<Calendar />} />
              <Route path="/Employeeprofile" element={<Employeeprofile />} />
              {/* <Route path="/Studentprofile" element={<Studentprofile/>}/> */}
              <Route path="/Profilestudents/:id" element={<Profilestudent />} />
              <Route path="/Course" element={<Course />} />
              {/* <Route path="/Lead" element={<Analytics />} /> */}
              <Route path="/Lead" element={<Lead />} />
              <Route path="/Invoce" element={<Invoce />} />
              <Route path="/Profile" element={<Profile />} />
              <Route path="/Chat" element={<Chat />} />
              <Route path="/Department" element={<Department />} />
              <Route path="/Attendance" element={<Attendance />} />
              <Route path="/Batch" element={<Batch />} />
              <Route path="/Subject" element={<Subject />} />
              <Route path="/Addbutton/:id" element={<Addbutton/>} />



              <Route path="/Grantleave" element={<Grantleave />} />
              <Route path="/Salarymanagement/:id/:name/:department_id/:department/:state_id/:enr_id" element={<Salarymanagement />} />
              <Route
                path="/Grantleaveviewdetail/:id"
                element={<Grantleaveviewdetail />}
              />
              <Route path="/Addstudents" element={<Addstudents />} />
              <Route path="/addExcel" element={<AddExcel />} />
              <Route path="/PaymentSetup/:id" element={<PaymentSetup />} />
              <Route path="/PaymentHistory/:id" element={<PaymentHistory />} />
              <Route path="/StudentAttendance/:id/:enrollment_id" element={<StudAttendance />} />
              <Route path="/Invoiesa" element={<Invoiesa />} />
              <Route path="/Makepayment" element={<Makepayment />} />
              <Route path="/Reportrequest" element={<Reportrequest />} />
              <Route path="/Expense" element={<Expense />} />
              <Route path="/Paymake" element={<Paymake />} />
              {/* <Route path="/Setting" element={<Setting />} /> */}
              <Route path="/Setting" element={<Setting2 />} />
              {/* <Route path='/Partiallymake' element={<Partiallymake />}/> */}
              <Route path="/PartiallyMake" element={<Partiallymake />} />
              <Route
                path="/Studentviewlist/:status"
                element={<Studentviewlist />}
              />
              <Route path="/batchHistory" element={<BatchHistory />} />
              <Route path="/bhprofile/:id" element={<BhProfile />} />
              <Route path="/Myleads" element={<Mylead />} />
              <Route path="/TotalLeads" element={<TotalLeads />} />
              <Route path="/BannerImages" element={<BannerImage />} />
            </Route>
            {/* </Route> */}
            <Route path='/Marksheet' element={<Marksheet/>}/>
            <Route path='/CreateNewMarksheet' element={<CreateNewMarksheet/>}/>
            <Route path='/CreateNewMarksheetWait' element={<CreateNewMarksheetWait/>}/>
            <Route path='/CreateNewMarksheetConfirm' element={<CreateNewMarksheetConfirm/>}/>
            <Route path='/CreateNewMarksheetSucces' element={<CreateNewMarksheetSucces/>}/>
            <Route path='/ViewMarksheet' element={<ViewMarksheet/>}/>
            <Route path='/Students' element={<S/>}/>
            <Route path='/EditStudentPaymentDetails/:id/:remark/:amount/:dueDate/:page' element={<EditPaymentDetails/>}/>
            <Route path='/GenerateSlipt/:id/:due_date' element={<GenerateSlipt/>}/>
            <Route path='/UpdateSlipt/:id/:due_date' element={<UpdateSlipt/>}/>

          </Routes>
        </BrowserRouter>
      </div>
    </AuthProvider>
  );
}

export default App;
