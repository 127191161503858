import Lottie from 'lottie-react'
import React,{useState,useEffect} from 'react'
import A1 from '../../images/A1.json';
import A2 from '../../images/A2.json';
import A3 from '../../images/a3.json';
import lotti from '../../images/129053-thumbs-up-birdie.json';
import { API_URL, img_url } from "../../env";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
function Student() {

  const [total,setTotal] = useState("");
  const Navigate = useNavigate();
  const [received,setReceived] = useState("");
  const [upcoming,setUpcoming] = useState("");
  const [missed,setMissed] = useState("");

  const Api = async()=>{
    try{
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );
      var raw = "";

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${API_URL}/admin/acount/account/dashboard/for/student`, requestOptions)

.then(response => response.json())
.then((result) => {
  if(result.status==1){
    setTotal(result.tot_amount);
    setReceived(result.received_amount);
    setUpcoming(result.upcoming_amount);
    setMissed(result.missed_amount)
  }else{
    toast.error(result.Backend_Error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
  }
})
.catch(error => console.log('error', error));

    }catch(e){
      console.log(e)
    }
  }

  useEffect(() => {
   Api();
  }, [])
  

  return (
    <div className='container' style={{width:"70vw"}} >
        <div className='row container mt-3'>
            <div className='col-lg-12 col-sm-12 col-md-12'> <div className='card' >
                <div className='card-body'>
                    <h5 className="card-title" style={{textAlign:"center"}} >Total Amount</h5>
                    <h6 class="card-subtitle mb-2 text-body-secondary" style={{textAlign:"center"}} > ₹ {total}</h6>
                  <div style={{display:"flex", justifyContent:"center"}}>
                  <Lottie animationData={A2} style={{ width: "10%", height: "", marginLeft: "0rem", }} />
                  </div>
                </div>
                </div> </div>
           
        </div>
        <div className='row container mt-3'>
        <div className='col-lg-4 col-sm-12 col-md-12' style={{cursor:"pointer"}} onClick={()=>Navigate('/Accounts/student/received')} > <div className='card'>
            <div className='card-body'>
                    <h5 className="card-title" style={{textAlign:"center"}} >received</h5>
                    <h6 class="card-subtitle mb-2 text-body-secondary" style={{textAlign:"center"}} > ₹  {received}</h6>
                    <div style={{display:"flex", justifyContent:"center"}}>
                  <Lottie animationData={A1} style={{ width: "61%", height: "", marginLeft: "0rem", }} />
                  </div>
                </div>
                </div> </div>
            <div className='col-lg-4 col-sm-12 col-md-12' onClick={()=>Navigate('/Accounts/student/upcoming')} style={{cursor:"pointer"}}> <div className='card'>
                <div className='card-body'>
                    <h5 className="card-title" style={{textAlign:"center"}} >Upcoming</h5>
                    <h6 class="card-subtitle mb-2 text-body-secondary" style={{textAlign:"center"}} > ₹  {upcoming}</h6>
                    <div style={{display:"flex", justifyContent:"center"}}>
                  <Lottie animationData={A3} style={{ width: "61%", height: "", marginLeft: "0rem", }} />
                  </div>
                </div>
                </div> </div>
            <div className='col-lg-4 col-sm-12 col-md-12' onClick={()=>Navigate('/Accounts/student/missed')} style={{cursor:"pointer"}}> <div className='card'>
            <div className='card-body'>
                    <h5 className="card-title" style={{textAlign:"center"}} >Missed</h5>
                    <h6 class="card-subtitle mb-2 text-body-secondary" style={{textAlign:"center"}} > ₹  {missed}</h6>
                    <div style={{display:"flex", justifyContent:"center"}}>
                  <Lottie animationData={lotti} style={{ width: "61%", height: "", marginLeft: "0rem", }} />
                  </div>
                </div>
                </div> </div>
        </div>
    <ToastContainer/>
    </div>
  )
}

export default Student