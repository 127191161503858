import React, { useState, useEffect } from 'react';
import Header from './header';
import SideBar from './sidebar';
import { FiCalendar, FiFilter, FiList, FiDownload, FiMail, FiPhone, } from "react-icons/fi";
// import Calendar from 'moedim';
import DatePicker from "react-datepicker";
import { AiFillPlusSquare } from "react-icons/ai"
import "react-datepicker/dist/react-datepicker.css";
// import 'react-calendar/dist/Calendar.css';
import { AiOutlineLink } from 'react-icons/ai';
import { BiCalendar } from 'react-icons/bi';


export default function Invoce() {


  // const[value1, setValue]=useState();

  // const [Mode, setMode] = useState(true);

  // if(value1.length>=3){
  //     setMode(true);
  // }


  const del = [
    {
      name: 'ricky',
      date: '2023',
    },
    {
      name: 'ricky',
      date: '2023',
    },
    {
      name: 'ricky',
      date: '2023',
    },
    {
      name: 'ricky',
      date: '2023',
    },
    {
      name: 'ricky',
      date: '2,023',
    },
    {
      name: 'ricky',
      date: '2023',
    },
    {
      name: 'ricky',
      date: '2023',
    },
    {
      name: 'ricky',
      date: '2023',
    },

    {
      name: 'ricky',
      date: '2023',
    },


  ]
  const cre = [
    {
      name: 'ricky',
      date: '2023',
    },
    {
      name: 'ricky',
      date: '2023',
    },
    {
      name: 'ricky',
      date: '2023',
    },
    {
      name: 'ricky',
      date: '2023',
    },
    {
      name: 'ricky',
      date: '2,023',
    },
    {
      name: 'ricky',
      date: '2023',
    },
    {
      name: 'ricky',
      date: '2023',
    },
    {
      name: 'ricky',
      date: '2023',
    },

    {
      name: 'ricky',
      date: '2023',
    },


  ]



  // const table=[
  //     {
  //         sn:'1',
  //         remark:"hostal fess",
  //         amount:"49,965",
  //         due:"25/03/2023",

  //     },
  //     {
  //         sn:'1',
  //         remark:"hostal fess",
  //         amount:"49,965",
  //         due:"25/03/2023",

  //     },
  //     {
  //         sn:'1',
  //         remark:"hostal fess",
  //         amount:"49,965",
  //         due:"25/03/2023",

  //     },  
  // ]


  // const [calender,setCalender] = useState(false)

  const [value, setvalue] = useState();
  const [Catg, setCatg] = useState("");

  const [state, setState] = useState(0);
  const [duedate, setDuedate] = useState(0)
  const [tabled, setTabled] = useState(0)
  // const [valuee, onChange] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date())

  const [count1, setcount1] = useState(1)
  const [valuee, setValue] = useState(' ')

  // console.log(Catg);

  useEffect(() => {
    // setDuedate();
    setvalue('All')
  }, []);


  return (
    <>
      <div style={{ width: '100%', height: '100vh', }}>
        <div className='head' style={{ width: '80%', height: '14vh', marginLeft: '16%' }}>
          <Header />
        </div>
        <div style={{ width: '100%', height: '86vh', display: "flex", }}>
          <div style={{ width: '20%', height: '100%', position: 'absolute', top: 0, marginLeft: '1%' }} >
            <SideBar />
          </div>
          <div className='maincontain' style={{ marginLeft: '15%', width: '100%', height: '100%', }} >



            {/* tabss */}

            <div className='row mt-4' style={{ height: 40, display: "flex", alignItems: 'center', paddingLeft: "30px" }}>

              <div className='col-6'>
                <button className='col-2' onClick={() => { setvalue('All') }} style={{ border: "none", height: "40px", backgroundColor: value == "All" ? "#EEEEEE" : '#fff', borderRadius: "5px" }} >
                  <p style={{ borderBottom: Catg === "All" ? " border:'2px solid red'" : "", width: "100px", borderBottom: "red", paddingLeft: "50px", color: "#E2A925" }}>All</p>
                </button>

                <button className='col-2' onClick={() => { setvalue('Credit') }} style={{ border: "none", height: "40px", backgroundColor: value == "Credit" ? "#EEEEEE" : "#fff", borderRadius: "5px" }}>
                  <p style={{ borderBottom: Catg === "Credit" ? " border:'2px solid red'" : "", width: "100px", color: "#40997E" }}>Credit</p>
                </button>

                <button className='col-2' onClick={() => { setvalue('Debit') }} style={{ border: "none", height: "40px", backgroundColor: value == "Debit" ? "#EEEEEE" : "#fff", borderRadius: "5px" }}>
                  <p style={{ color: "#F03838" }}>Debit</p>
                </button>

              </div>
              <div className='col-3'>
                <div className=''>
                  <button type='button' className='justify-content-center align-items-center d-flex p-3 mb-3'
                    style={{
                      width: '50%', fontSize: '15px', backgroundColor: "white"
                      , border: "none", color: '#8147E7', marginLeft: "150px", border: "2px solid #8147E7", borderRadius: "5px"
                    }}>
                    <b>  <AiFillPlusSquare style={{ fontSize: "20px" }} /> Export Data</b>
                  </button>
                </div>
              </div>

              <div className='col-3'>
                <div className=''>
                  <button type='button' className='justify-content-center align-items-center d-flex p-3 mb-3'
                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasRighti" aria-controls="offcanvasRight" style={{
                      width: '60%', fontSize: '18px',
                      backgroundColor: '#8147E7', border: "none", color: '#fff', marginLeft: "25px", borderRadius: "5px"
                    }}>
                    + Create Invoice
                  </button>
                </div>
              </div>

            </div>

            {/* button calender */}

            <div className='row'>
              <div className='col-12'>
                {
                  value === 'All' ? <>
                    <div className='mt-5 '>

                      <div className='row mx-4'>
                        {/* icon */}
                        <div className="col-1">
                          <div style={{ backgroundColor: "black", marginTop: "0px", width: "40px", height: "40px", fontSize: "15px", borderRadius: "50px" }}>
                            <FiCalendar style={{ marginLeft: "12px", fontSize: "17px", color: "white", marginTop: "10px" }} />
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                          </div>
                        </div>


                        {/* dropdown */}
                        <div className="col-2">
                          <div className="dropdown">
                            <button className="" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{
                              border: "2px solid #E0E0E0", borderRadius: "5px",
                              backgroundColor: "#E0E0E0", height: "40px", paddingLeft: "1px", width: "100px"
                            }}>Filter
                              <FiFilter style={{ fontSize: '1em', color: '#54536E' }} />
                            </button>
                            <ul className="dropdown-menu" style={{ backgroundColor: '#E0E0E0' }} >
                              <li className=''><a className="dropdown-item d-inline-flex justify-content-between" href="#">Credited</a></li>
                              <li><a className="dropdown-item d-inline-flex justify-content-between" href="#">Not Credited</a></li>
                              <li><a className="dropdown-item d-inline-flex justify-content-between" href="#" style={{ fontSize: "13px" }}>Partially Credited</a></li>
                            </ul>
                          </div>


                        </div>
                        <div className="col-2">
                          {/* A-Z */}

                          <button style={{
                            border: "2px solid #8147E7", borderRadius: "5px",
                            backgroundColor: "#8147E7", height: "40px", paddingLeft: "1px", width: "100px"
                          }}>
                            <span style={{ color: "white" }}><FiList /> A-Z</span>
                          </button>

                        </div>

                      </div>

                    </div>
                  </> : <></>
                }

                {/* credit  */}
                {
                  value === 'Credit' ? <>
                    <div className='mt-5 mx-5'>
                      <div className='row mx-4 mt-3' style={{ overflowY: "scroll", height: "60vh" }}>
                        {cre.map((res, key) => {
                          return (
                            <>
                              <div className='col-3 mt-5' key={key}  >
                                <p>ricky</p>
                              </div>

                              <div className='col-3 mt-5'>
                                <p>25 feb 2023</p>
                              </div>

                              <div className='col-3 mt-5'>
                                <p><span style={{ color: "#22E157" }}>50,000</span>/1,00,000</p>
                              </div>

                              <div className='col-3 mt-5'>
                                <button className='btn btn-outline' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                                  style={{ marginLeft: "60px", border: "2px solid #8147E7", color: "#8147E7", width: "180px" }}>VIEW DETAILS</button>
                              </div>
                            </>
                          )
                        })}

                      </div>
                    </div>
                  </> : <></>
                }

                {/* debit */}
                {
                  value === 'Debit' ? <>
                    <div className='mt-5 mx-5'>
                      <div className='row mx-4 mt-3' style={{ overflowY: "scroll", height: "60vh" }}>
                        {del.map((res, key) => {
                          return (
                            <>
                              <div className='col-3 mt-5' key={key}  >
                                <p>ricky</p>
                              </div>

                              <div className='col-3 mt-5'>
                                <p>25 feb 2023</p>
                              </div>

                              <div className='col-3 mt-5'>
                                <p><span style={{ color: "red" }}>50,000</span>/1,00,000</p>
                              </div>

                              <div className='col-3 mt-5'>
                                <button className='btn btn-outline' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                                  style={{ marginLeft: "60px", border: "2px solid #8147E7", color: "#8147E7", width: "180px" }}>VIEW DETAILS</button>
                              </div>
                            </>
                          )
                        })}

                      </div>
                    </div>
                  </> : <></>
                }

              </div>
            </div>

            {/* tabel */}
            <div className='row mx-4 mt-3' style={{ overflowY: "scroll", height: "60vh" }}>
              {del.map((res, key) => {
                return (
                  <>
                    <div className='col-3 mt-5' key={key}  >
                      <p>ricky</p>
                    </div>

                    <div className='col-3 mt-5'>
                      <p>25 feb 2023</p>
                    </div>

                    <div className='col-3 mt-5'>
                      <p><span style={{ color: "#22E157" }}>50,000</span>/1,00,000</p>
                    </div>

                    <div className='col-3 mt-5'>
                      <button className='btn btn-outline' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                        style={{ marginLeft: "60px", border: "2px solid #8147E7", color: "#8147E7", width: "180px" }}>VIEW DETAILS</button>
                    </div>
                  </>
                )
              })}

            </div>




            {/* Dettails  */}
            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ width: "40%" }}>
              <div class="offcanvas-header">
                <h5 id="offcanvasRightLabel"></h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div class="offcanvas-body">
                <div className='row'>
                  <div className='col-6'>
                    <p style={{ fontSize: "25px" }}>Details</p>
                  </div>

                  <div className='col-6'>
                    <p style={{ border: "2px solid #BDBDBD", width: "150px", fontSize: "13px", marginLeft: "110px", textAlign: "center", height: "30px", paddingTop: "5px", borderRadius: "5px" }}>Download Invoce<FiDownload /></p>
                  </div>
                </div>

                <div className='row mt-5' style={{ fontWeight: "600" }} >
                  <div className='col-4 ' >
                    <p style={{ height: "80px", padding: "7px", backgroundColor: "#ECF0FF", borderRadius: "5px" }}>Total Amount<br />₹ 1,59,963</p>
                  </div>
                  <div className='col-4' >
                    <p style={{ height: "80px", padding: "7px", backgroundColor: "#ECF0FF", borderRadius: "5px" }}>Total Amount<br />₹ 1,59,963</p>
                  </div>

                  <div className='col-4'>
                    <p style={{ height: "80px", padding: "7px", backgroundColor: "#ECF0FF", borderRadius: "5px" }}>Total Amount<br />₹ 0</p>
                  </div>

                </div>

                <div className='row'>
                  <p className='mt-4'>Form</p>

                  <div className='col-6'>
                    <p style={{ fontSize: "28px", fontWeight: "500" }}>RNS IT SOLUTIONS</p>
                    <p>101-201-301, Chanda Tower, Girnar Colony, Gandhi Path Road, Vaishali Nagar, Jaipur - 302021</p>
                    <p>+91 702 334 0831</p>

                    <div className='mt-3'>
                      <p style={{ fontSize: "18px" }}>To</p>
                      <p style={{ fontSize: "18px" }}>Narayan murthy</p>
                      <p className='mt-3'>101-201-301, Chanda Tower, Girnar Colony, Gandhi Path Road, Vaishali Nagar, Jaipur - 302021</p>
                      <p className='mt-3'>+91 702 334 0831</p>
                    </div>

                    <div>
                      <p className='mt-3' style={{ fontSize: "25px" }}>Product Details</p>
                      <p className='mt-3'>Name</p>
                      <p>Narayanmurthy</p>

                      <div className='mt-3' style={{ display: "flex" }} >
                        <p>Qty.</p>
                        <p className='px-5'>Price/Unit</p>
                      </div>

                      <div style={{ display: "flex" }} >
                        <p>121</p>
                        <p className='px-5'>&nbsp;&nbsp;&nbsp;₹ 12000</p>
                      </div>

                    </div>
                  </div>
                  <div className='col-6 mx-5 mt-3' style={{ border: "2px solid #BDBDBD", height: "70px", width: "180px", textAlign: "center" }}>
                    <div>
                      <p style={{ fontSize: "25px" }}>Due Date</p>
                      <input type="date" id="start" name="trip-start" value="2018-07-22" min="2018-01-01" max="2018-12-31" style={{ border: "none" }}></input>
                    </div>
                  </div>

                </div>
                <button className='mt-4' style={{ width: "350px", borderRadius: "5px", backgroundColor: "#5A81FE", color: "white", border: "none", marginLeft: "150px", height: "30px" }}>Make Payment</button>
              </div>
            </div>





            {/* invocetable */}
            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRighti" aria-labelledby="offcanvasRightLabel" style={{ width: "40%" }}>
              <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasRightLabel"></h5>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div class="offcanvas-body">
                {/* details */}
                <div style={{ display: state == 0 ? "block" : "none" }}>
                  {/* <div onClick={()=> {setState(state+1)}}> */}
                  <p style={{ fontSize: "25px" }}>Create  Invoice </p>
                  <p>Status : credited</p>

                  <p className='mt-4' style={{ color: "#BDBDBD", fontSize: "15px" }}>Enter Customer Name </p>
                  <input type="text" className="form-control" placeholder="Name.." style={{ paddingLeft: "20px", width: "90%", height: "32px" }} />


                  <p className='mt-4' style={{ color: "#BDBDBD", fontSize: "15px" }}> GSTIN / LLP Reg No. / Prop. Number(OPTIONAL)</p>
                  <input type="text" className="form-control" placeholder="Number.." style={{ paddingLeft: "20px", width: "90%", height: "32px" }} />


                  <p className='mt-4' style={{ color: "#BDBDBD", fontSize: "15px" }}>Enter Address </p>
                  <input type="text" className="form-control" placeholder="Vashali nager jaipur Rajasthan" style={{ paddingLeft: "20px", width: "90%", height: "70px" }} />


                  <p className='mt-4' style={{ color: "#BDBDBD", fontSize: "15px" }}>Enter Email </p>
                  <input type="text" className="form-control" placeholder="Naramurti@gmail.com" style={{ paddingLeft: "40px", width: "90%", height: "32px" }} />
                  <FiMail style={{ marginTop: "-63px", marginLeft: "10px", color: "black" }} />

                  <p className='mt-4' style={{ color: "#BDBDBD", fontSize: "15px" }}>Enter Contact number </p>
                  <input type="text" className="form-control" placeholder="985236565564" style={{ paddingLeft: "40px", width: "90%", height: "32px" }} />
                  < FiPhone style={{ marginTop: "-63px", marginLeft: "10px", color: "black" }} />

                  <p className='mt-4' style={{ color: "#BDBDBD", fontSize: "15px" }}>Product Details</p>
                  <input type="text" className="form-control" placeholder="Product name" style={{ paddingLeft: "20px", width: "90%", height: "32px" }} />

                  <p className='mt-4' style={{ color: "#BDBDBD", fontSize: "15px" }}>Enter product descrition(OPTIONAL)</p>
                  <input type="text" className="form-control" placeholder=" Enter product description " style={{ paddingLeft: "20px", width: "90%", height: "70px" }} />

                  <p className='mt-4' style={{ color: "#BDBDBD", fontSize: "15px" }}>Enter Qty.</p>
                  <input type="text" className="form-control" placeholder="Enter Quality" style={{ paddingLeft: "20px", width: "90%", height: "32px" }} />

                  <p className='mt-4' style={{ color: "#BDBDBD", fontSize: "15px" }}>Enter Price Unit</p>
                  <input type="text" className="form-control" placeholder="Enter price per unit" style={{ paddingLeft: "20px", width: "90%", height: "32px" }} />

                  <div className='row'>
                    <div className='col-6 mt-4' >
                      <p className='pt-3' style={{ border: "1px solid grey", width: "200px", height: "80px", textAlign: "center", fontWeight: "700" }}>
                        Total Amount <br />₹ 12,000</p>
                    </div>

                    <div className=' col-6 mt-5'>
                      <button onClick={() => { setState(state + 1) }} style={{
                        backgroundColor: "blue", color: "white",
                        border: "none", width: "220px", borderRadius: "5px", height: "40px"
                      }}> Setup Paytem</button>
                    </div>

                  </div>

                </div>

                {/* setup payment */}

                <div style={{ display: state == 1 ? "block" : "none" }}>
                  <div className='row'>
                    <div className='col-3'>
                      <div class="form-check">
                        <input class="form-check-input" onClick={() => setDuedate(duedate - 1)} type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        {/*  */}
                        <label class="form-check-label" for="flexRadioDefault1">
                          Pay in EMIs
                        </label>
                      </div>
                    </div>

                    <div className='col-4'>
                      <div class="form-check">
                        <input class="form-check-input" onClick={() => setDuedate(duedate + 1)} type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        {/*  */}
                        <label class="form-check-label" for="flexRadioDefault1">
                          Pay in One Shot
                        </label>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className='row' >
                      <div className='col-3' style={{ display: duedate == 0 ? "block" : "none" }} >
                        {/*  */}
                        <p style={{ marginTop: "20px" }}>EMI Count</p>
                        <p className='p-2' style={{ borderRadius: "10px", width: "150px", height: "45px", color: "#7A7A7A", backgroundColor: "#E0E0E0", textAlign: "center", paddingTop: "55px" }}>Number of EMIs</p>
                      </div>

                      <div className='col-3 mx-3'>
                        <div >
                          <p style={{ marginTop: "20px" }}>Due Date</p>
                          <p className='pt-2 p-2' style={{
                            borderRadius: "10px", width: "150px", height: "45px",
                            backgroundColor: "#E0E0E0", textAlign: "center", color: "#7A7A7A"
                          }}><FiCalendar />&nbsp;12 Nov 2022</p>
                        </div>
                      </div>

                      <div className='col-3 mt-5'>
                        <button onClick={() => setTabled(tabled + 1)} className='btn btn-outline-dark mx-5' >OK</button>
                        {/*  */}
                      </div>
                    </div>
                  </div>
                  {/* <div></div> */}
                  <div style={{ display: tabled == 0 ? "none" : "" }}>
                    <p className='mt-3'>Payment Schedule</p>

                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">S.No</th>
                          <th scope="col">Remarks</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Due Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Hostel Fees</td>
                          <td>₹ 49,965</td>
                          <td>12/11/2022</td>
                        </tr>

                      </tbody>
                    </table>

                  </div>

                  <div className='row mt-5' >
                    <div className='col-6'>
                      <p>Grand Total:₹ 3,99,725</p>
                    </div>
                    <div className='col-6'>
                      <button onClick={() => { setState(state + 1) }} style={{
                        backgroundColor: "#5A81FE", color: "white", border: "none",
                        width: "200px", height: "40px", borderRadius: "10px"
                      }}>Proceed To Payment</button>
                    </div>
                  </div>

                </div>

                {/* process payment */}

                <div style={{ display: state == 2 ? "block" : "none" }}>

                  <p>From</p>
                  <div className='row'>
                    <div className='col-6'>
                      <p style={{ fontSize: "22px" }}>RNS IT SOLUTIONS</p>
                      <p style={{ fontSize: "15px" }}>101-201-301, Chanda Tower, Girnar Colony, Gandhi Path Road, Vaishali Nagar, Jaipur - 302021</p>
                      <p className='mt-2' style={{ fontSize: "15px" }}>+91 702 334 0831</p>

                      <p className='mt-3'><b>To</b></p>
                      <p className=""><b>Narayan murthy</b></p>
                      <p className='mt-3' style={{ fontSize: "15px" }}>101-201-301, Chanda Tower, Girnar Colony, Gandhi Path Road, Vaishali Nagar, Jaipur - 302021</p>
                      <p className='mt-3' style={{ fontSize: "15px" }}>+91 702 334 0831</p>
                    </div>

                    <div className='col-6 ' >
                      <p style={{ fontSize: "22px", fontWeight: "500", paddingLeft: "100px" }}>Due Date</p>
                      <p style={{ paddingLeft: "100px" }}><BiCalendar />&nbsp;&nbsp;24 Feb 2023</p>

                      <p className='pt-3 mt-3 p-1' style={{ border: "2px solid #E0E0E0", width: "150px", marginLeft: "100px", height: "90px", fontSize: "14px" }}>Amount to be paid<br />₹ 1,59,963</p>
                    </div>
                  </div>

                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Remarks</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Due Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>Hostel Fees</td>
                        <td>₹ 49,965</td>
                        <td>12/11/2022</td>
                      </tr>

                    </tbody>
                  </table>

                  <div className='row mt-5' >
                    <div className='col-6'>
                      <p>Grand Total:₹ 3,99,725</p>
                    </div>

                    <div className='col-6'>
                      <button onClick={() => { setState(state + 1) }} style={{
                        backgroundColor: "#5A81FE",
                        color: "white", border: "none", width: "200px", height: "40px", borderRadius: "10px"
                      }}>Proceed To Paymentad</button>
                    </div>
                  </div>
                </div>


                {/* payment link */}

                <div style={{ display: state == 3 ? "block" : "none" }}>

                  <div>
                    <p style={{ fontSize: "25px", fontWeight: "600" }}>Payment Link Sent to Narayanmurthy Successfully</p>
                  </div>

                  <div className='mt-3'>
                    <p>Payment Link</p>
                    <input type="text" className='from-control px-3' placeholder="https://easebuzz.in//payment_id%20%manipal%univ" style={{
                      paddingLeft: "0px",
                      width: "70%", height: "32px", borderRadius: "5px", border: "1px solid #D7D7D7"
                    }} />
                    <span style={{ marginLeft: "-18px" }}><AiOutlineLink /></span>
                  </div>

                  <div className='mt-3' >
                    <p>Remark</p>
                    <div>
                      <input type="text" maxLength="100" className="" placeholder="Start typing task description..." value1={1} style={{
                        width: "70%", marginTop: "20px", height: "40px", paddingLeft: "10px", borderRadius: "5px", border: "1px solid #D7D7D7"
                      }} onChange={(e) => { setValue(e.target.value) }} /></div>
                    <p style={{ paddingLeft: "375px", fontSize: "10px" }} >{valuee.length}/200</p>

                  </div>


                  <div className='mt-3'>
                    <p style={{ fontSize: "20px" }}>Contact Number</p>
                    <p>9874563210</p>
                  </div>

                  <div className='mt-3'>
                    <p style={{ fontSize: "20px", fontWeight: "600" }}>Due Date</p>
                    <p>22-03-2023 &nbsp;<BiCalendar /></p>
                  </div>


                  <div className='mt-4 pt-2' style={{ border: "2px solid #E0E0E0", width: "170px", textAlign: "center", height: "80px" }}>
                    <p>Amount to be paid<br /> ₹1,59,963</p>
                  </div>


                  <div className='col-6 mt-5'>
                    <button style={{
                      backgroundColor: "#5A81FE",
                      color: "white", border: "none", width: "200px", height: "40px", borderRadius: "5px", marginLeft: "150px"
                    }}>Back to dashboard</button>
                  </div>


                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}








