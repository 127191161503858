import React, { useState, useEffect, useContext} from 'react'; //api done
import { AiOutlineEye } from 'react-icons/ai';
import DatePicker from "react-datepicker";
import { BiCalendar } from 'react-icons/bi';
import { BsFillCalendarFill } from "react-icons/bs";
import TIPG from '../images/tipsglogo.png';
import "react-datepicker/dist/react-datepicker.css"
import { FiDownload } from "react-icons/fi"
import { RiArrowDropDownLine } from 'react-icons/ri';
import { RiFileExcel2Line} from 'react-icons/ri';
import { BsArrowLeft} from 'react-icons/bs';
import { BsArrowRight} from 'react-icons/bs';
import { FaUserGraduate } from 'react-icons/fa';
import { AuthContext } from './AuthContext';
// import { API_URL } from './MyComponent';
import { NavLink , useParams} from 'react-router-dom';
import * as XLSX from 'xlsx';

// const {API_URL,img_url} =env;


import { API_URL,img_url } from '../env';


export default function BhProfile() {
  const [startDate, setStartDate] = useState(new Date())


  const [list, setList] = useState([]);
  const { apiToken } = useContext(AuthContext);

  const { id } = useParams();

  const table = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch(`${API_URL}/academic/batchHistoryget_Students?batch_id=${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
      if(result.status=="001"){
        setList(result.batchHistoryget_Students);
      }
      
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    table();
  }, [])



  const cre = [
    {
      sn: '1.',
      name: "SN Potter",
      fees: "₹ 80,000",
      course: "BCA",
      batch: "A",
      status1: "Active",
      view: "View Details",
      status: 0

    },
    {
      sn: '1.',
      name: "SN Potter",
      fees: "₹ 80,000",
      course: "BCA",
      batch: "A",
      status1: "Dropped",
      view: "View Details",
      status: 1
    },
    {
      sn: '1.',
      name: "SN Potter",
      fees: "₹ 80,000",
      course: "BCA",
      batch: "A",
      status1: "Defaulter",
      view: "View Details",
      status: 2
    },
    {
      sn: '1.',
      name: "SN Potter",
      fees: "₹ 80,000",
      course: "BCA",
      batch: "A",
      status1: "Active",
      view: "View Details",
      status: 0

    },
    {
      sn: '1.',
      name: "SN Potter",
      fees: "₹ 80,000",
      course: "BCA",
      batch: "A",
      status1: "Dropped",
      view: "View Details",
      status: 1
    },
    {
      sn: '1.',
      name: "SN Potter",
      fees: "₹ 80,000",
      course: "BCA",
      batch: "A",
      status1: "Defaulter",
      view: "View Details",
      status: 2
    },
    {
      sn: '1.',
      name: "SN Potter",
      fees: "₹ 80,000",
      course: "BCA",
      batch: "A",
      status1: "Active",
      view: "View Details",
      status: 0

    },
    {
      sn: '1.',
      name: "SN Potter",
      fees: "₹ 80,000",
      course: "BCA",
      batch: "A",
      status1: "Dropped",
      view: "View Details",
      status: 1
    },
    {
      sn: '1.',
      name: "SN Potter",
      fees: "₹ 80,000",
      course: "BCA",
      batch: "A",
      status1: "Defaulter",
      view: "View Details",
      status: 2
    },


  ]

  const dd = [
    {
      sn: "1.",
      part: "Admission Fees",
      amount: "Admission Fees"
    },
    {
      sn: "1.",
      part: "Admission Fees",
      amount: "Admission Fees"
    }
  ]


  const convertToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
  
    // Map the array to include only the desired properties
    const selectiveDataArray = list.map((student) => ({
      Name: student.name,
      Batch: student.batch_name,
      Start_Date: student.start_date,
      End_Date: student.end_date,
     
    }));
  
    // Convert the selective data array into a worksheet
    const worksheet = XLSX.utils.json_to_sheet(selectiveDataArray);
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
    // Generate a file name
    const fileName = 'Active_Students_data.xlsx';
  
    // Generate Excel file binary data
    const excelData = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
  
    // Create a blob object from the binary data
    const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
    // Create a download link
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
  
    // Simulate a click to trigger the download
    link.click();
  
    // Clean up
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <div className="row ">
     <div className="col-2 bg-white" style={{ backgroundColor: "#fff", height: "100vh", }}>
          <p className='ms-4  ' style={{ fontWeight: "600", color: "#3C3C3C", fontSize: "25px", marginTop: "40px" }}>  <NavLink to="/batchHistory" className="" style={{ zIndex: "999", }}>
            <BsArrowLeft className='ps-1' style={{ color: '#000000', fontSize: "25px", cursor: "pointer" }} />
          </NavLink> Batch History</p>
          <span className='mt-4' 
            style={{ display: "flex", backgroundColor:"#F4F2F9" , border: "none", marginLeft: "30px", height: "45px", borderRadius: "5px", color: "#8A8A8A" }}>
            <p className='pt-2' style={{ zIndex: '999', paddingLeft: "30px", color: "#8147E7" , fontWeight: "400", cursor: 'pointer' }}><FaUserGraduate className='me-3' />Student details</p></span>

         

          

         
        </div>
        <div className='col-lg-10 col-md-10 col-sm-10'>
      <div className="card mt-4 ms-4" style={{ width: "95%", border: "none" }}>
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <p style={{ fontWeight: "400", color: "#8A8A8A", fontSize: "18px" }}>Date Range</p>
              <div className="row mt-1">
                <div className="col-4">
                  <p style={{ color: "#8A8A8A", fontSize: "400" }}>From</p>
                  <div>

                    <span style={{
                      display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "0px",
                      borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
                    }}>
                      <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
                      <div>
                        <DatePicker className="border-0 ps-0 ps-2 datep"
     
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                      </div>
                    </span>
                  </div>
                </div>
                <div className="col-4">
                  <p style={{ color: "#8A8A8A", fontSize: "400" }}>To</p>
                  <div>

                    <span style={{
                      display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "0px",
                      borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
                    }}>
                      <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
                      <div>
                        <DatePicker className="border-0  ps-0 ps-2 datep"
           
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                      </div>
                    </span>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-3 mt-3">

            </div>

            <div className="col-3">
            
                  <div>
              <p style={{ fontSize: "18px", fontWeight: "400", color: "#8A8A8A" }}>Total Students</p>
              <p style={{ fontWeight: "500", fontSize: "20px", color: "#3C3C3C" }}>{list.length}</p>
              </div>
            
            </div>

            {/* <div className="col-3">
              {list?.map((res, key) => {
                return (
                  <div key={key}>
                    <p style={{ fontSize: "18px", fontWeight: "400", color: "#8A8A8A" }}>
                      Total Students
                    </p>
                    <p style={{ fontWeight: "500", fontSize: "20px", color: "#3C3C3C" }}>
                     8000
                    </p>
                  </div>
                );
              })}
            </div> */}

          </div>
        </div>
      </div>

      {/* Invoice Details */}

      <div className="mt-5">
        <div className='d-flex'>
          <p className="" style={{ color: "#3C3C3C", fontSize: '22px', fontWeight: "600", paddingLeft: "30px" }}>Student Details</p>
          <button className=' ' onClick={()=>convertToExcel()} style={{
            backgroundColor: "#8147E7", borderRadius: "5px", width: "180px", height: "50px", border: "none", marginLeft: "48.6rem",
            color: "#ffffff", fontWeight: "400", fontSize: "20px"
          }}><RiFileExcel2Line style={{ fontSize: "20px" }} /> &nbsp;&nbsp;Export Data</button>
        </div>
        <div className='mt-3'>
          <div className='row mx-4 mt-3 '  >
            <div className='row' style={{ color: "#434343", fontWeight: "600", fontSize: "15px" }}>
              <div className='col-1 pb-2'>
                <p>ID</p>
              </div>
              <div className='col-3 pb-3'>
                <p>Name</p>
              </div>
           
              <div className='col-2 pb-2'>
                <p>Batch</p>
              </div>
              <div className='col-2 pb-2'>
                <p>Start Date</p>
              </div>
              <div className='col-2 pb-2'>
                <p>End Date</p>
              </div>
            </div>

            <div style={{ overflowY: "scroll", height: "60vh" }}>
              {list.length>=0 ? list?.map((res, key) => {
                return (
                  <>
                    <div className='row align-items-center  mt-3' style={{ width: "100%", borderRadius: "5px", height: "55px", backgroundColor: " #FFFFFF" }} >
                      <div className='col-1' key={key} >
                        <p style={{ color: "#434343", fontWeight: "400", fontSize: "15px" }}>{key+1}</p>
                      </div>
                      <div className='col-3'>
                        <p style={{ fontWeight: "400", fontSize: "15px" ,textTransform: "capitalize"}}>{res.name}</p>
                      </div>
                     
                      <div className='col-2'>
                        <p style={{ fontSize: "15px", color: "#434343", fontWeight: "400" }} >{res.batch_name}</p>
                      </div>

                      {/* <div className='col-2'>
<p style={{fontSize:"14px",color:"#434343",fontWeight:"400"}} >{res.transaction}</p>
</div> */}


                      {/* <div className='col-2'>
<p style={{fontSize:"14px",color:"#434343",fontWeight:"400"}} >{res.payment}</p>
</div> */}
                      <div className='col-2'>
                        <p style={{ fontSize: "15px", color: "#434343", fontWeight: "400" }} >{res.start_date}</p>
                      </div>
                      <div className='col-2'>
                        <p style={{ fontSize: "15px", fontWeight: "400", }} >{res.end_date}</p>
                      </div>


                      <div className='col-2'>
                        <NavLink to ={`/Profilestudents/${res.student_id}`} style={{textDecoration:"none" ,fontSize: "15px",  fontWeight: "400", padding: '8px 20px', width: "auto", borderRadius: "5px", border: "none" }} >View Detail<BsArrowRight className='ms-2' style={{ fontSize: "20px" }} /></NavLink>
                      </div>

                    </div>


                  </>
                )
              }):<>j</>
              }
            </div>

          </div>
        </div>
      </div>

      </div>
      </div>

    </>
  )
}