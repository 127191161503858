import React, { useContext, useEffect, useState } from "react";// api done
import Header from './header';
import SideBar from './sidebar';
// import { ImBooks } from 'react-icons/im';
// import { FiSearch } from "react-icons/fi";
// import { MdTableChart } from "react-icons/md";
// import { tableHeadClasses } from "@mui/material";
import { HiArrowLeft } from 'react-icons/hi';
import { NavLink, useNavigate } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
// import { Offcanvas } from 'react-bootstrap/Offcanvas';
import { AiOutlineEye } from 'react-icons/ai';
import { IoTrashSharp } from "react-icons/io5";
import LOGO from '../images/Group 396.png';
// import ToastContainer from "react-bootstrap/ToastContainer";
// import Toast from "react-bootstrap/Toast";
import Lode from './Lode';
import { AuthContext } from "./AuthContext";
// import { API_URL } from './MyComponent';
import { ToastContainer, toast } from "react-toastify";




import { API_URL, img_url } from '../env';


export default function Subject() {


  const [FormFields, setFormFields] = useState("");
  const [count1, setcount1] = useState(1)
  const [Subject, setSubject] = useState([])
  const [Topic, setTopic] = useState("");
  // const [Topic1, setTopic1] = useState();
  const [formFields, setformFields] = useState();
  const [value, setvalue] = useState("");
  const [Array, setArray] = useState([]);
  // const [Message, setmessage] = useState();
  // const [ShowLoader, setShowLoader] = useState(false);
  const { apiToken } = useContext(AuthContext);
  const {Navigate} = useNavigate();

  const ApiForSubject = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/admin/subject/read/subject`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status == "1") {
          setSubject(result.allSubject);
        }
        else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }
      })
      .catch(error => console.log('error', error));
  }



  const handleRemoveField = (index) => {


    const updatedFormFields = [...formFields];
    updatedFormFields.splice(index, 1);
    setFormFields(updatedFormFields);


  };


  //console.log(Array);

  const HnadleAdd = () => {
    const Update = [...Array];

    Update.push(Topic);

    setArray(Update);
    setTopic("");
  }


  const handleRemField = (id) => {
    const Update = [...Array];

    Update.splice(id, 1);

    setArray(Update);

  }


  const AddSubject = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", `Bearer ${apiToken}`)

    var raw = JSON.stringify({
      "subject_name": value
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${API_URL}/admin/subject/create/subject`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status == "1") {
          toast("Subject Added Succesfully");
          ApiForSubject();
          setcount1(1);
          setvalue("");
        }
        else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
          setcount1(1);
          setvalue("");
        }
            })
      .catch(error => console.log('error', error));
    
  }

  useEffect(() => {
    ApiForSubject()

  }, []);


  return (
    <>

      <ToastContainer />
      <div style={{ width: '100%', height: '100vh', }}>
        <div className='head' style={{ width: '80%', height: '14vh', marginLeft: '16%' }}>
          <Header />
        </div>
        <div style={{ width: '100%', height: '86vh', display: "flex", }}>
          <div style={{ width: '20%', height: '100%', position: 'absolute', top: 0, marginLeft: '1%' }} >
            <SideBar />
          </div>

          <div className='maincontain' style={{ marginLeft: '15%', width: '100%', height: '100%', overflowY: 'scroll', }} >
            <div className="row">



              {/* <div className="col-1  ">
    <NavLink to="/academics" className="pt-5" style={{zIndex:"999",}}>
       <HiArrowLeft  /> 
      </NavLink>
      </div>  */}

              <div className="col-4 ps-5">
                <div style={{ display: "flex" }}>
                  <NavLink to="/academics" className="pt-4 px-3" style={{ zIndex: "999", }}>
                    <HiArrowLeft style={{ color: '#646464' }} />
                  </NavLink>
                  <img className="mt-3" src={LOGO} alt="sdf" style={{ width: "13%", height: "49px", }} />
                  <p className="ms-3" style={{ fontSize: "48px", fontWeight: "400" }}>Subjects</p>

                </div>
                <p style={{ paddingLeft: "110px" }}>Number of Subjects -  {Subject.length}</p>
              </div>


              <div className="col-6">
                <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{
                  marginLeft: "95%", backgroundColor: "#8147E7",
                  color: "white", width: "170px", height: "48px", border: "none", borderRadius: "5px", fontSize: "14px"
                }}> <AiOutlinePlus /> Add Subjects</button>
              </div>
            </div>
            <div className="container" style={{ padding: "10px 73px 10px 73px", overflowY: "scroll", height: "70vh" }}>
              <div className="row">
                {Subject?.map((res, key) => {
                  return (
                    <>
                      <div className="col-4" key={key} style={{ textAlign: "center", }}>
                        <div className="card mt-3 d-flex " style={{ width: "90%" }} >
                          <span className="" type="button" >
                            <div className="card-body">
                              <div style={{ paddingTop: "5px", fontWeight: "400" }}>
                                <p style={{ fontSize: "20px" }}>{res.name}</p>
                                {/* <p style={{ fontSize: "16px", color: " #838383" }}>Total No. of topics :{res.topics}</p> */}
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </>
                  )
                })}

              </div>
            </div>
            {/* Offcanvas */}




          </div>
        </div>
      </div>

      {/* +Add modal start */}


      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backdropFilter: "blur(2px)" }}>
        <div className="modal-dialog modal-dialog-centered"  >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Add Subjects</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setcount1(1)}></button>
            </div>
            <div className="modal-body">

              <div style={{ display: count1 === 1 ? "block" : "none" }}>
                <div className="mt-2" style={{ border: "2px solid #FAFAFA", width: "350px", height: "330px", marginLeft: "76px", borderRadius: "5px" }}>
                  <div style={{ margin: "15px" }}><b> Name of Subject</b></div>
                  <div >
                    <input type="text" maxLength="100" className="mx-4" placeholder="Type Course Name" value={value} style={{
                      backgroundColor: "#FAFAFA", borderRadius: "5px",
                      border: "none", width: "300px", marginTop: "20px", height: "40px", paddingLeft: "10px"
                    }} onChange={(e) => { setvalue(e.target.value) }} /></div>
                  <p style={{ paddingLeft: "295px", color: "#B1AFC8", fontSize: "10px" }} >{value.length}/100</p>

                  <div className="">
                    <button className="btn btn-primary mx-4"
                      style={{ width: "300px", marginTop: "100px" }} disabled={value.length > 1 ? false : true}
                      onClick={() => { setcount1(count1 + 1) }}

                    >Proceed</button>
                  </div>
                </div>
              </div>




              {/* second Add staart */}
              {/* <div style={{ display: count1 === 2 ? "block" : "none" }}>
                <div className="mt-2" style={{ border: "2px solid #FAFAFA", width: "350px", height: "330px", marginLeft: "76px", borderRadius: "5px" }}>
                  <div style={{ margin: "15px" }}><b> Select Topic</b></div>
                  <div className="d-flex">
                    <div className="mt-3">
                      <input type="text" className="form-control" placeholder="Enter Text"
                        value={Topic} style={{ border: "none", backgroundColor: " #FAFAFA", paddingLeft: "30px", width: "100%", }} onChange={(e) => { setTopic(e.target.value); }} />
                    </div>
                    <div >
                      <button className="mt-2" onClick={HnadleAdd} style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)", background: "#F3F3F3", border: "none", color: "#696969", fontWeight: "500", padding: "5px 14px 5px 14px", borderRadius: "20px", fontSize: "20px", marginLeft: "17px", }} disabled={Topic.length >= 1 ? false : true}>+</button>
                      {/* <button  className="mt-3" onClick={HnadleAdd}  style={{boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.08)",background:"#F3F3F3",border:"none",color:"#696969",}}>+</button> 
                    </div>

                  </div>
                  <div style={{ height: 150, overflow: "scroll" }}>
                    {
                      Array?.map((res, key) => {
                        return (
                          <>
                            <div className="row">
                              <div className="col-9">
                                <p className="mt-2" style={{ background: "#F3F3F3", borderRadius: "5px", width: "100%", padding: "6px", }}>{res}</p>
                              </div>
                              <div className="col-3">
                                <p className="mt-2" onClick={() => { handleRemField(key) }} style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)", background: "#F3F3F3", border: "none", color: "#696969", padding: "5px 11px 5px 11px", borderRadius: "20px", fontSize: "20px", width: "62%", }}><IoTrashSharp /></p>
                                {/* <button className="mt-2 "  onClick={()=>{handleRemField(key)}}>Remove</button> 
                              </div>
                            </div>
                          </>
                        )
                      })
                    }
                  </div>



                  <div className="">
                    <button className="btn btn-primary mx-4" disabled={Array.length >= 1 ? false : true} onClick={() => { setcount1(count1 + 1) }}
                      style={{ width: "300px", marginTop: "0px" }}>Proceed</button>
                  </div>
                </div>
              </div> */}



              {/* third Add start */}



              {/* couresefourht */}



              <div style={{ display: count1 === 2 ? "block" : "none" }}>
                <div className="mt-2" style={{ width: "350px", height: "330px" }}>
                  <div style={{ margin: "15px" }}><b>Following Subject will be created</b></div>
                  <p className="mx-3">{value}</p>
                  <div style={{ margin: "15px" }}><b>With the following Topic:</b></div>

                  {/* <div className="mt-2" style={{ overflowY: "scroll", height: "20vh" }}>
                    {Array?.map((res, key) => {
                      return (
                        <>
                          <p key={key} className="small mx-3 " style={{ fontSize: "18px", color: "grey", }}>{res}</p>

                        </>
                      )
                    })}

                  </div> */}

                  <div className="mt-4">
                    <button className="btn btn-primary mx-4" data-bs-dismiss="modal" aria-label="Close" onClick={AddSubject}
                      style={{ width: "300px", marginTop: "0px" }}>Proceed</button>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>






      {/* <a className="btn btn-primary" data-bs-toggle="modal" href="#exampleModalToggle" role="button">Open first modal</a> */}


      {/* <ToastContainer position="top-end" className="p-3" >
        <Toast onClose={() => setShowLoader(false)} show={ShowLoader} delay={8000} autohide style={{ backgroundColor: "#000" }}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">tipsg</strong>
            <small>1 mins ago</small>
          </Toast.Header>
          <Toast.Body className="text-white">{Message}</Toast.Body>
        </Toast>
      </ToastContainer> */}


    </>
  )
}

function Sdata(props) {
  return (
    <>
      <div className='col-3'>
        <img src={props.item.batchid} style={{ width: "50px" }} />
      </div>
      <divi className='col-3'>
        <p>{props.item.batchname}</p>
      </divi>
      <div className='col-3'>
        <p>{props.item.stardate}</p>
      </div>
      <div className='col-3' style={{ display: "flex", color: "#8147E7" }}>
        <AiOutlineEye className="m-1" />
        <NavLink to="/Studentprofile" className="text-decoration-none " style={{ fontSize: "14px", color: "#8147E7" }}><p>{props.item.action}</p></NavLink>
      </div>
    </>
  );
}