import React, { useState, useEffect, useContext } from 'react';
import Header from './header';
import SideBar from './sidebar';
import { FiCornerDownRight } from "react-icons/fi";
import { IoArrowBackSharp } from "react-icons/io5";
import { IoMdArrowForward } from "react-icons/io";
import { NavLink, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { AuthContext } from './AuthContext';
import { BsArrowLeft } from 'react-icons/bs';
import './report.css'
import { API_URL } from '../env';
import { ToastContainer, toast } from 'react-toastify';
import { Loader } from './Loader';


export default function Department() {

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const { apiToken } = useContext(AuthContext);
  const [role, setRole] = useState([]);
  const [Name, setName] = useState('');
  const [msg, setmsg] = useState();
  const [state, setState] = useState(0);
  const [Rname, setRname] = useState('');
  const [value, setvalue] = useState(' ')
  const [data, setData] = useState([]);
  const [click, setClick] = useState(0);
  const [roles, setRoles] = useState();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showOff, setShowOff] = useState(false);
  const [req_id, setReqId] = useState()
  const [Users, setUsers] = useState([]);
  const [roname, setroname] = useState();
  const [account, setAccount] = useState([]);
  const [academics, setAcademics] = useState([]);
  const [support, setSupport] = useState([]);
  const [student, setStudent] = useState([]);
  const [leads, setLeads] = useState([]);
  const [calender, setCalender] = useState([]);
  const [team, setTeam] = useState([]);
  const [analytics, setAnalytics] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [permission_id, setpermission_id] = useState([]);
  const [profile, setProfile] = useState([]);
  const [etime, setEtime] = useState('');
  const [stime, setStime] = useState('');
  const [Available, setAvailable] = useState([]);
  const [Granted, setGranted] = useState([]);
  const [add_id, setadd_id] = useState();
  const [deletid, setdeletid] = useState();


  const [did, setDid] = useState();
  const Navigate = useNavigate();

  const handleCloseOff = () => setShowOff(false);
  const handleShowOff = () => setShowOff(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);


  function RoleApi(Id) {
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);


var raw = JSON.stringify({
  "department_id": Id
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${API_URL}/admin/department/read/users/in/department`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)
     if(result.status == 1){
    setRole(result.employees)
     }
  })
  .catch(error => console.log('error', error));
  }

  const DepartApi = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);


    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    setIsLoading(true)
    fetch(`${API_URL}/admin/department/read/department`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setIsLoading(false)
        // console.log(result.department,'of departments')
        if (result.status == "001") {
          setData(result.departments);
         console.log(result.departments,'dadadd')
        }
        else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }
      })
      .catch(error => console.log('error', error));

  }

  const Add = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);


    var raw = JSON.stringify({
      "name": Name
    })
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${API_URL}/admin/department/create/department`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status == "001") {
              toast(result.message)
          setName('');
          DepartApi();
        }
        else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }
      })
      .catch(error => console.log('error', error));
  }

  const Permission = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);


    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/get-all-section`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result, 'id')

        const { status, section } = result;
        const { Leads, Calendar, Team, Analytics, Attendance, Students, Support, Accounts, Academics } = section;
        if (result.status == "001") {
          setAcademics(Academics.data);
          setAccount(Accounts.data);
          setAnalytics(Analytics.data);
          setCalender(Calendar.data);
          setLeads(Leads.data);
          setTeam(Team.data);
          setAttendance(Attendance.data);
          setSupport(Support.data);
          setStudent(Students.data);
        }


      })
      .catch(error => console.log('error', error));
  }

  const AddRole = async (x) => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);


    var raw = JSON.stringify({
      "dept_id": x,
      "name": Rname
    })
    // formdata.append("department_id", x);
    // formdata.append("name", Rname);
    // formdata.append("start_time", stime);
    // formdata.append("end_time", etime);
    // formdata.append("permission_id", JSON.stringify(permission_id));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${API_URL}/admin/role/create/role`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status == "001") {
          toast(result.message)
        }
        else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }

      })
      .catch(error => console.log('error', error))
      .finally(() => {
        RoleApi(did);
        setState(state == 0);
        DepartApi();
        // setpermission_id([]);



      })

  }

  const UProfile = async (ids) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/team/roleEmp_details?emp_id=${ids}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result, 'particular user result');
        if (result.status == "001") {
          setProfile(result.employeesDetails);
        }
      })
      .catch(error => console.log('error', error));
  }

  const handleProcess = () => {
    if (Rname.length <= 1
    ) {
      toast(Rname.length <= 1
        ? 'Please enter name'

        : ''

      )
    }
    else { setState(state + 2) }
  }




  const handleCut = () => {
    setAcademics([]);
    setStudent([]);
    setSupport([]);
    setAttendance([]);
    setTeam([]);
    setLeads([]);
    setCalender([]);
    setAnalytics([]);
    setAccount([]);
    setRname('');
    setEtime('');
    setStime('');
    setpermission_id([]);
    Permission();
    setState(state == 0)
  }

//   const Available_access = async()=>{
//     var myHeaders = new Headers();
// myHeaders.append("Content-Type", "application/json");
// myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);


// var raw = JSON.stringify({
//   "department_id": did
// });

// var requestOptions = {
//   method: 'POST',
//   headers: myHeaders,
//   body: raw,
//   redirect: 'follow'
// };

// fetch(`${API_URL}/admin/department/view/manage/access`, requestOptions)
//   .then(response => response.json())
//   .then(result =>{ console.log(result)
//     if(result.status == 1){
//       setAvailable(result.access_control)
//       setGranted(result.available_control)
//       console.log(result.access_control,'ppppp')

//     }
//   })
//   .catch(error => console.log('error', error));
//   }

//   const add_button = async()=>{
//     var myHeaders = new Headers();
// myHeaders.append("Content-Type", "application/json");
// myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

// var raw = JSON.stringify({
//   "department_id": did,
//   "add_id": add_id
// });

// var requestOptions = {
//   method: 'POST',
//   headers: myHeaders,
//   body: raw,
//   redirect: 'follow'
// };

// fetch(`${API_URL}/admin/department/add/accessitem/indepartment`, requestOptions)
//   .then(response => response.json())
//   .then(result => {console.log(result)
//      if(result.status == 1){
//       toast(result.msg);
//     Available_access()

//      }
//   })
//   .catch(error => console.log('error', error));
//   }

//   const delete_api =async()=>{
//     var myHeaders = new Headers();
// myHeaders.append("Content-Type", "application/json");
// myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);


// var raw = JSON.stringify({
//   "department_id": did,
//   "delete_id": deletid
// });

// var requestOptions = {
//   method: 'POST',
//   headers: myHeaders,
//   body: raw,
//   redirect: 'follow'
// };

// fetch(`${API_URL}/admin/department/delete/accessitem/indepartment`, requestOptions)
//   .then(response => response.json())
//   .then(result => {console.log(result)
//     if(result.status == 1){
//       toast(result.msg);
//     Available_access()

//      }
//   })
//   .catch(error => console.log('error', error));
//   }
//   useEffect(()=>{
//     Available_access()

//   })
  useEffect(() => {
    DepartApi();
    Permission();


  }, [])


  return (
    <>
      <ToastContainer />
      <div style={{ width: '100%', height: '100vh', }}>
        <div className='head' style={{ width: '80%', height: '14vh', marginLeft: '16%' }}>
          <Header />
        </div>
        <div style={{ width: '100%', height: '86vh', display: "flex", }}>
          <div style={{ height: '100%', position: 'absolute', top: 0, marginLeft: '1%' }} >
            <SideBar />
          </div>
          <div className='maincontain' style={{ marginLeft: '15%', width: '100%', height: '100%', overflowY: 'scroll', }} >
            {/* department */}
            <div style={{ display: click === 0 ? "block" : "none" }}>
              <div className="row " >
                <div className="col-9 mt-3" >
                  <p className='demo' style={{ marginLeft: "46px", fontSize: "20px" }} >


                    <NavLink to="/Team" className="dept" >
                      <BsArrowLeft className='ps-1 mx-2' style={{ color: '#000000', fontSize: "25px", cursor: "pointer" }} />
                    </NavLink>Showing total {data.length} department</p>

                </div>

                <div className="col-3 mt-3">
                  <button data-bs-toggle="modal" data-bs-target="#exampleModal"
                    style={{
                      backgroundColor: "#E8DFFA", padding: '5px 20px',
                      border: "2px solid #8147E7", borderRadius: "5px", color: "#8147E7"
                    }}>+ Add Department</button>
                </div>
              </div>

              {/* button model */}
              <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ marginTop: "130px" }}>
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header" >
                      <h1 class="modal-title fs-5" id="exampleModalLabel">Add Department</h1>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setName('')}></button>
                    </div>
                    <div class="modal-body">
                      <p>Enter name of department</p>
                      <div >
                        <input type="text" maxLength="100" className="" placeholder="" value={Name} style={{
                          backgroundColor: "#FAFAFA", borderRadius: "5px",
                          border: "none", width: "440px", marginTop: "20px", height: "40px", paddingLeft: "10px"
                        }} onChange={(e) => { setName(e.target.value) }} /></div>
                      <p style={{ paddingLeft: "400px", color: "#B1AFC8", fontSize: "10px" }} >{Name.length}/100</p>
                    </div>

                    <div className=''>
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                        style={{ width: "200px", backgroundColor: "blue", marginLeft: "140px" }} disabled={Name.length <= 1 ? true : false} onClick={() => { Add(); }} >Confirm</button>
                    </div>
                    <p className='mt-5'></p>
                  </div>
                </div>
              </div>

              {/* card */}
              <div className="row" style={{ overflowY: "scroll", }}>
                {
                  isLoading ? <Loader show={isLoading} /> :
                    <>
                      {
                        data.length > 0 ?

                          data.map((res, key) => {
                            return (
                              <>
                                <div className="col-3 mt-5" key={key} style={{ marginLeft: "46px" }}>

                                  <div class="card" style={{ width: "100%", background: "#FFFFFF", border: "1px solid #ECECEC", borderRadius: "5px", }}>
                                    <div className="card-body">
                                      <h5 className="card-title" style={{ fontStyle: "normal", fontWeight: "500", fontSize: "20px", display: "flex", alignItems: "center", textAlign: "center", letterSpacing: "0.1625px", color: "#000000", }}>{res.department_name}</h5>
                                      <div style={{ display: "flex" }}>
                                        <p style={{ paddingLeft: "5px", fontStyle: "normal", fontWeight: "400", fontSize: "18px", }}><span style={{ color: "#69D458", fontStyle: "normal", fontWeight: "400", fontSize: "18px", }}>{res.users}</span>  Users</p>
                                        <p style={{ paddingLeft: "75px", fontStyle: "normal", fontWeight: "400", fontSize: "18px", }}> <span style={{ color: "#D45858", fontStyle: "normal", fontWeight: "400", fontSize: "18px", }}> {res.role}</span>  </p>
                                      </div>

                                      <div className="mt-3" style={{ display: "flex", }} >
                                        <button onClick={() => {
                                          setRoles(res.role_count);
                                          setClick(click + 1);
                                          setReqId(res.name);
                                          RoleApi(res.id);
                                          setDid(res.id);
                                        }} style={{ width: "120px", backgroundColor: "black", color: "white", borderRadius: "5px" }}>View User</button>
                                        <button className='ms-1' onClick={() => {
                                          setRoles(res.role_count);
                                          setReqId(res.name);
                                          RoleApi(res.id);
                                          setDid(res.id);
                                          Navigate(`/Addbutton/${res.id}`)
                                        }} style={{ width: "120px", backgroundColor: "black", color: "white", borderRadius: "5px" }}>Access</button>


                                        {/* <button style={{ marginLeft: "20px", width: "120px", backgroundColor: "#F4D8D8", border: "none", color: "#E56060", borderRadius: "5px" }}>Edit</button> */}
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }) : <><div className="d-flex mt-5" style={{ justifyContent: "center", color: "red", alignItems: "center" }}><h6>No Data Available</h6></div></>}
                    </>
                }

              </div>
            </div>

            {/* information tecnology */}
            <div style={{ display: click === 1 ? "block" : "none" }}>

              <div className="row " >
                <div className="col-6 mt-3" >
                  <p style={{ marginLeft: "46px", fontSize: "20px" }}><span onClick={() => { setClick(click - 1); }}><IoArrowBackSharp style={{ fontSize: "20px", color: "#000", cursor: "pointer" }} className='mx-2' /></span>Showing <span style={{ color: "red" }}>{role.length}</span> users in {req_id}</p>
                </div>

                {/* <div className="col-6 mt-3">
                  <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                    style={{
                      marginLeft: "65%", width: "25%", height: "40px", backgroundColor: "#E8DFFA",
                      border: "2px solid #8147E7", borderRadius: "5px", color: "#8147E7"
                    }} >+ Add Role</button>
                </div> */}
              </div>
              {/* add role */}

              <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ width: "40%" }}>
                <div class="offcanvas-header" >
                  <h3>Add Role</h3>
                  <h5 class="offcanvas-title" id="offcanvasRightLabel"></h5>
                  <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => handleCut()} ></button>
                </div>
                <div class="offcanvas-body">
                  {/* name role */}
                  <div style={{ display: state == 0 ? "block" : "none" }}>

                    <div style={{ border: "2px solid #efefef", width: "70%", height: "50vh", marginTop: "60px", marginLeft: "90px" }}>
                      <h5 style={{ paddingLeft: "18px", paddingTop: "15px" }}>Enter Name of Role</h5>
                      <div >
                        <input type="text" maxLength="10" className="" placeholder="Role name" value={Rname} style={{
                          backgroundColor: "#FAFAFA", borderRadius: "5px",
                          border: "none", width: "380px", marginTop: "20px", height: "40px", paddingLeft: "10px", marginLeft: "10px"
                        }} onChange={(e) => { setRname(e.target.value) }} /> </div>
                      <p style={{ paddingLeft: "350px", color: "#B1AFC8", fontSize: "10px" }} >{Rname.length}/10</p>


                      {/* <div>
                        <div className="mt-4" style={{ margin: "15px", color: "#292929", fontWeight: "500", fontSize: "16px" }}>Batch Timings</div>
                        <div className="row ms-2">
                          <div className="col-6" style={{ display: "flex" }}>
                            <p className="mt-1">From</p>&nbsp;
                            <input type="time" value={stime} style={{ backgroundColor: "#EDEDF5", borderRadius: "5px", border: "none", padding: "8px 8px", }} onChange={(e) => { setStime(e.target.value) }} />
                          </div>
                          <div className="col-6" style={{ display: "flex" }}>
                            <p className="mt-1">to</p>&nbsp;
                            <input type="time" value={etime} style={{ backgroundColor: "#EDEDF5", borderRadius: "5px", border: "none", padding: "8px 8px", }} onChange={(e) => { setEtime(e.target.value) }} />
                          </div>
                        </div>
                      </div> */}


                      <div className='mt-5'>
                        <button onClick={() => { handleProcess() }} style={{
                          marginLeft: "auto", marginRight: "auto", display: "block", backgroundColor: "#5A81FE", color: "white", marginTop: "170px", width: "300px", border: "none", height: "40px", borderRadius: "5px"
                        }}  >Proceed</button>

                      </div>
                    </div>
                  </div>

                  {/* manage access controler */}
                  {/* <div style={{ display: state == 1 ? "block" : "none" }}>
                    <h5 style={{ paddingLeft: "80px", marginTop: "40px", fontWeight: "600", fontSize: "17px" }}>Manage Access Control</h5>

                    <div style={{ border: "2px solid #FAFAFA", width: "75%", marginLeft: "80px", overflowY: "scroll", height: "60vh" }} >
                      <div className='row' style={{ backgroundColor: "#E5EBFF", width: "100%", marginLeft: "0px" }}>
                        <div className='col-6'>
                          <p>Panels</p>
                        </div>

                        <div className='col-6'>
                          <p style={{ paddingLeft: "73%" }}>Editing</p>
                        </div>
                      </div>

                      <div className='row mt-2'>
                        <div className='col-8' style={{ paddingLeft: "20px" }}>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            <label class="form-check-label" for="defaultCheck1">
                              Academics
                            </label>
                          </div>

                          <div style={{ display: "flex", paddingLeft: "50px" }}>
                            <FiCornerDownRight />
                            <div>
                              {

                                //come back 
                                academics?.map((el) =>
                                  <div class="form-check" >
                                    <input class="form-check-input" type="checkbox" name={el.name} value={el.id} id="defaultCheck1" onChange={handleChange} />
                                    <label class="form-check-label" for="defaultCheck1">
                                      {el.name}
                                    </label>
                                  </div>)

                              }
                            </div>

                          </div>
                        </div>
                        <div className='col-4' style={{ paddingRight: "20px" }}>
                          <div class="form-check form-switch form-check-reverse">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckReverse" />
                            <label class="form-check-label" for="flexSwitchCheckReverse">Active</label>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className='row mt-2'>
                      <div className='col-8' style={{ paddingLeft: "20px" }}>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                          <label class="form-check-label" for="defaultCheck1">
                            Accounts
                          </label>
                        </div>

                        <div style={{ display: "flex", paddingLeft: "50px" }}>
                          <FiCornerDownRight />
                          <div>
                            {

                              //come back 
                              account?.map((el) =>
                                <div class="form-check" >
                                  <input class="form-check-input" type="checkbox" name={el.name} value={el.id} id="defaultCheck1" onChange={handleChange} />
                                  <label class="form-check-label" for="defaultCheck1">
                                    {el.name}
                                  </label>
                                </div>)

                            }
                          </div>

                        </div>
                      </div>
                      <div className='col-4' style={{ paddingRight: "20px" }}>
                        <div class="form-check form-switch form-check-reverse">
                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckReverse" />
                          <label class="form-check-label" for="flexSwitchCheckReverse">Active</label>
                        </div>
                      </div>
                    </div>
                    <hr />
                      <div className='row'>
                        <div className='col-8' style={{ paddingLeft: "20px" }}>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            <label class="form-check-label" for="defaultCheck1">
                              Support
                            </label>
                          </div>

                          <div style={{ display: "flex", paddingLeft: "50px" }}>
                            <FiCornerDownRight />
                            <div>
                              {
                                support?.map((el) =>
                                  <div class="form-check" >
                                    <input class="form-check-input" type="checkbox" value={el.id} id="defaultCheck1" onChange={handleChange} />
                                    <label class="form-check-label" for="defaultCheck1">
                                      {el.name}
                                    </label>
                                  </div>)

                              }
                            </div>

                          </div>


                        </div>
                        <div className='col-4' style={{ paddingRight: "20px" }}>
                          <div class="form-check form-switch form-check-reverse">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckReverse" />
                            <label class="form-check-label" for="flexSwitchCheckReverse">Active</label>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className='row'>
                        <div className='col-8' style={{ paddingLeft: "20px" }}>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            <label class="form-check-label" for="defaultCheck1">
                              Students
                            </label>
                          </div>

                          <div style={{ display: "flex", paddingLeft: "50px" }}>
                            <FiCornerDownRight />

                            <div>
                              {
                                student?.map((el) =>
                                  <div class="form-check" >
                                    <input class="form-check-input" type="checkbox" value={el.id} id="defaultCheck1" onChange={handleChange} />
                                    <label class="form-check-label" for="defaultCheck1">
                                      {el.name}
                                    </label>
                                  </div>)

                              }
                            </div>

                          </div>

                        </div>
                        <div className='col-4' style={{ paddingRight: "20px" }}>
                          <div class="form-check form-switch form-check-reverse">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckReverse" />
                            <label class="form-check-label" for="flexSwitchCheckReverse">Active</label>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className='row'>
                        <div className='col-8' style={{ paddingLeft: "20px" }}>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            <label class="form-check-label" for="defaultCheck1">
                              Analytics
                            </label>
                          </div>

                          <div style={{ display: "flex", paddingLeft: "50px" }}>
                            <FiCornerDownRight />

                            <div>
                              {
                                analytics?.map((el) =>
                                  <div class="form-check" >
                                    <input class="form-check-input" type="checkbox" value={el.id} id="defaultCheck1" onChange={handleChange} />
                                    <label class="form-check-label" for="defaultCheck1">
                                      {el.name}
                                    </label>
                                  </div>)

                              }
                            </div>

                          </div>

                        </div>
                        <div className='col-4' style={{ paddingRight: "20px" }}>
                          <div class="form-check form-switch form-check-reverse">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckReverse" />
                            <label class="form-check-label" for="flexSwitchCheckReverse">Active</label>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className='row'>
                        <div className='col-8' style={{ paddingLeft: "20px" }}>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            <label class="form-check-label" for="defaultCheck1">
                              Team
                            </label>
                          </div>

                          <div style={{ display: "flex", paddingLeft: "50px" }}>
                            <FiCornerDownRight />

                            <div>
                              {
                                team?.map((el) =>
                                  <div class="form-check" >
                                    <input class="form-check-input" type="checkbox" value={el.id} id="defaultCheck1" onChange={handleChange} />
                                    <label class="form-check-label" for="defaultCheck1">
                                      {el.name}
                                    </label>
                                  </div>)

                              }
                            </div>

                          </div>


                        </div>
                        <div className='col-4' style={{ paddingRight: "20px" }}>
                          <div class="form-check form-switch form-check-reverse">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckReverse" />
                            <label class="form-check-label" for="flexSwitchCheckReverse">Active</label>
                          </div>
                        </div>
                      </div>
                      <hr />

                    </div>
                    <div className='mt-5'>
                      <button onClick={() => { handleProcess1() }} style={{
                        marginLeft: "auto", marginRight: "auto", display: "block", backgroundColor: "#5A81FE", color: "white", marginTop: "0px",
                        width: "300px", border: "none", height: "40px", borderRadius: "5px"
                      }}>Proceed</button>
                    </div>
                  </div> */}


                  <div style={{ display: state == 2 ? "block" : "none" }}>

                    <div style={{}}>

                      <p style={{ textAlign: "center", paddingTop: "200px" }}>New role
                        {/* <b>{permission_id?.map((res, index) => {
                        return (
                          <React.Fragment key={index}>
                            {res.Rolename}
                            {index !== permission_id.length - 1 && ", "}
                          </React.Fragment>
                        );
                      })} </b> */}
                        <b className='mx-1'>{Rname}</b>
                        will be added
                        {/* <br />to Roles in  */}
                      </p>

                      <div className='mt-5'>
                        <button
                          type="button"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block",
                            backgroundColor: "#5A81FE",
                            color: "white",
                            marginTop: "170px",
                            width: "300px",
                            border: "none",
                            height: "40px",
                            borderRadius: "5px"
                          }}
                          onClick={() => {

                            AddRole(did);
                            setRname('');
                            setEtime('');
                            setStime('');
                            setAcademics([]);
                            setStudent([]);
                            setSupport([]);
                            setAttendance([]);
                            setTeam([]);
                            setLeads([]);
                            setCalender([]);
                            setAnalytics([]);
                            setAccount([]);
                            setRname('');
                            setEtime('');
                            setStime('');
                            setpermission_id([]);
                            Permission();
                            setState(state == 0)
                            setpermission_id([]); // Empty the permission_id array
                          }}
                        >
                          Confirm
                        </button>

                      </div>
                    </div>
                  </div>

                </div>
              </div>



              {/* SDE 2 */}
              <div className="row" style={{ overflowY: "scroll" }}>
                {
                  loading ? <Loader show={loading} /> :
                    <>
                      {
                        role.length > 0 ?
                          role.map((res, key) => {
                            return (
                              <>
                                <div className="col-3 mt-5" key={key} style={{ marginLeft: "46px" }}>

                                  <div class="card" style={{ width: "100%", border: "none" }}>
                                    <div className="card-body" style={{ border: "1px solid #ECECEC", borderRadius: "5px", }}>
                                      <h5 className="card-title"> Name : {res.name}</h5>
                                      <p className="card-title"> Email : {res.email}</p>
                                      <p className="card-title"> Join Data {res.join_date}</p>
                                      <p className="card-title"> Department : {res.department_name}</p>
                                      
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }) : <><div className="d-flex mt-5" style={{ justifyContent: "center", color: "red", alignItems: "center" }}><h6>No Data Available</h6></div></>}
                    </>
                }

              </div>

{/* <div className='container mt-5'>
<div className='row'>
<div className='col-lg-6 col-md-6 col-sm-12'>

    <div style={{border:'1px solid #b0b3b8',borderRadius:'4px'}}>
<h4 style={{textAlign:'center',borderBottom:'1px solid #b0b3b8',padding:'6px'}}>hedd</h4>
{Available.map((res)=>{
  return( 
<div className='row p-2 mx-1 mb-1' style={{borderBottom:'1px solid #b0b3b8'}}>
<div className='col-10'>
  <p>{res.name}</p>
</div>
<div className='col-2'>
<button
  onClick={() => {
    add_button(); 
    setadd_id(res.id); 
  }}
  style={{
    background: 'blue',
    color: 'wheat',
    padding: '5px 20px 5px 20px',
    border: 'none',
    borderRadius: '10px',
  }}
>
  Edit
</button>

</div>
</div>
 )
})
}
</div>

</div>
<div className='col-lg-6 col-md-6 col-sm-12'>
<div style={{border:'1px solid #b0b3b8',borderRadius:'4px'}}>
<h4 style={{textAlign:'center',borderBottom:'1px solid #b0b3b8',padding:'6px'}}>hedd</h4>

{
Granted.map((res)=>{
  return(
    <>
    <div className='row p-2 mx-1 mb-1' style={{borderBottom:'1px solid #b0b3b8'}}>
<div className='col-10'>
  <p>{res.name}</p>
</div>
<div className='col-2'>
<button  onClick={() => {
   delete_api();
   setdeletid(res.id); 
  }} style={{background:'red',color:'wheat',padding:'5px 20px 5px 20px',border:'none',borderRadius:'10px',}}>Deleta</button>
</div>
</div>
    </>
  )
})
}

</div>
</div>
</div>
</div>
 */}









              {/* offcanvas 1 */}

              <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight1" aria-labelledby="offcanvasRightLabel" style={{ width: "40%" }}>
                <div class="offcanvas-header">
                  <h5 class="offcanvas-title" id="offcanvasRightLabel">{roname}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>

                <div class="offcanvas-body">
                  <p style={{ borderBottom: "1px solid #808080", width: "9%", }}>Users</p>


                  <div className='row text-center mt-3'>
                    <div className='col-3'>
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Name</p>
                    </div>
                    <div className='col-3'>
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Role</p>
                    </div>
                    <div className='col-3'>
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Joining Date</p>
                    </div>
                  </div>




                  {
                    Users.length > 0 ?
                      Users.map((res, index) => {
                        return (
                          <>

                            <div className='row text-center my-3 d-flex align-items-center' style={{ border: "1px solid #ECECEC", borderRadius: "10px", padding: "8px", cursor: 'pointer' }} key={res.emp_id}>
                              <div className='col-3'>
                                <p>{res.employee_name}</p>
                              </div>
                              <div className='col-3'>
                                <p>{res.role_name}</p>
                              </div>
                              <div className='col-3'>
                                <p>{res.created_at}</p>
                              </div>
                              <div className='col-3'>
                                <p onClick={() => {
                                  // console.log(res.emp_id, 'during moving to 2nd 0ffcanvas');
                                  UProfile(res.emp_id);
                                }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop" style={{ fontStyle: "normal", fontWeight: "500", fontSize: "14px", color: "#2188E7", }}>View <IoMdArrowForward style={{ fontSize: "16px", }} /></p>
                              </div>

                            </div>

                          </>
                        )
                      }) : <><div className="d-flex mt-5" style={{ justifyContent: "center", color: "red", alignItems: "center" }}><h6>No Data Available</h6></div></>}
                </div>
              </div>

              {/* offcanvas 2 */}

              <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasWithBackdrop" aria-labelledby="offcanvasWithBackdropLabel" style={{ width: "40%" }}>
                <div class="offcanvas-header">
                  <h5 class="offcanvas-title" id="offcanvasWithBackdropLabel" style={{ fontStyle: "normal", fontWeight: "500", fontSize: "28px", color: "#272727", }}>User Details</h5>
                  <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                {profile?.map((res, key) => {
                  return (
                    <div class="offcanvas-body" >
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#272727", }}>Name</p>
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "20px", color: "#272727", }}>{res.employee_name}</p>
                      <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#272727", }}>Role</p>
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "20px", color: "#272727", }}>{res.role_name}</p>
                      <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#272727", }}>Joining Date</p>
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "20px", color: "#272727", }}>{res.joining_Date}</p>
                      <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#272727", }}>Salary</p>
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "20px", color: "#272727", }}>₹ {res.salary}</p>
                      <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "20px", color: "#272727", }}>Manage Access Control</p>
                      <div className='mt-3' style={{ border: "2px solid #FAFAFA", width: "75%", marginLeft: "80px", overflowY: "scroll", height: "60vh" }} >
                        <div className='row' style={{ backgroundColor: "#E5EBFF", width: "100%", marginLeft: "0px" }}>
                          <div className='col-6'>
                            <p>Panels</p>
                          </div>

                          <div className='col-6'>
                            <p style={{ paddingLeft: "73%" }}>Editing</p>
                          </div>
                        </div>

                        <div className='row mt-2'>
                          <div className='col-8' style={{ paddingLeft: "20px" }}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                              <label class="form-check-label" for="defaultCheck1">
                                Academics
                              </label>
                            </div>

                            <div style={{ display: "flex", paddingLeft: "50px" }}>
                              <FiCornerDownRight />
                              <div class="form-check" >
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label class="form-check-label" for="defaultCheck1">
                                  Create invoice
                                </label>
                              </div>
                            </div>
                            <div class="form-check" style={{ display: "flex", paddingLeft: "90px" }}>
                              <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                              <label class="form-check-label" for="defaultCheck1">
                                <span style={{ paddingLeft: "10px" }}>View details</span>
                              </label>
                            </div>

                          </div>
                          <div className='col-4' style={{ paddingRight: "20px" }}>
                            <div class="form-check form-switch form-check-reverse">
                              <input class="form-check-input" type="checkbox" id="flexSwitchCheckReverse" />
                              <label class="form-check-label" for="flexSwitchCheckReverse">Active</label>
                            </div>
                          </div>
                        </div>
                        <hr />

                        <div className='row'>
                          <div className='col-8' style={{ paddingLeft: "20px" }}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                              <label class="form-check-label" for="defaultCheck1">
                                Support
                              </label>
                            </div>

                            <div style={{ display: "flex", paddingLeft: "50px" }}>
                              <FiCornerDownRight />
                              <div class="form-check" >
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label class="form-check-label" for="defaultCheck1">
                                  Create invoice
                                </label>
                              </div>
                            </div>
                            <div class="form-check" style={{ display: "flex", paddingLeft: "90px" }}>
                              <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                              <label class="form-check-label" for="defaultCheck1">
                                <span style={{ paddingLeft: "10px" }}>View details</span>
                              </label>
                            </div>

                          </div>
                          <div className='col-4' style={{ paddingRight: "20px" }}>
                            <div class="form-check form-switch form-check-reverse">
                              <input class="form-check-input" type="checkbox" id="flexSwitchCheckReverse" />
                              <label class="form-check-label" for="flexSwitchCheckReverse">Active</label>
                            </div>
                          </div>
                        </div>
                        <hr />

                        <div className='row'>
                          <div className='col-8' style={{ paddingLeft: "20px" }}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                              <label class="form-check-label" for="defaultCheck1">
                                Students
                              </label>
                            </div>

                            <div style={{ display: "flex", paddingLeft: "50px" }}>
                              <FiCornerDownRight />
                              <div class="form-check" >
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label class="form-check-label" for="defaultCheck1">
                                  Create invoice
                                </label>
                              </div>
                            </div>
                            <div class="form-check" style={{ display: "flex", paddingLeft: "90px" }}>
                              <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                              <label class="form-check-label" for="defaultCheck1">
                                <span style={{ paddingLeft: "10px" }}>View details</span>
                              </label>
                            </div>

                          </div>
                          <div className='col-4' style={{ paddingRight: "20px" }}>
                            <div class="form-check form-switch form-check-reverse">
                              <input class="form-check-input" type="checkbox" id="flexSwitchCheckReverse" />
                              <label class="form-check-label" for="flexSwitchCheckReverse">Active</label>
                            </div>
                          </div>
                        </div>
                        <hr />

                        <div className='row'>
                          <div className='col-8' style={{ paddingLeft: "20px" }}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                              <label class="form-check-label" for="defaultCheck1">
                                Analytics
                              </label>
                            </div>

                            <div style={{ display: "flex", paddingLeft: "50px" }}>
                              <FiCornerDownRight />
                              <div class="form-check" >
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label class="form-check-label" for="defaultCheck1">
                                  Create invoice
                                </label>
                              </div>
                            </div>
                            <div class="form-check" style={{ display: "flex", paddingLeft: "90px" }}>
                              <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                              <label class="form-check-label" for="defaultCheck1">
                                <span style={{ paddingLeft: "10px" }}>View details</span>
                              </label>
                            </div>

                          </div>
                          <div className='col-4' style={{ paddingRight: "20px" }}>
                            <div class="form-check form-switch form-check-reverse">
                              <input class="form-check-input" type="checkbox" id="flexSwitchCheckReverse" />
                              <label class="form-check-label" for="flexSwitchCheckReverse">Active</label>
                            </div>
                          </div>
                        </div>
                        <hr />

                        <div className='row'>
                          <div className='col-8' style={{ paddingLeft: "20px" }}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                              <label class="form-check-label" for="defaultCheck1">
                                Team
                              </label>
                            </div>

                            <div style={{ display: "flex", paddingLeft: "50px" }}>
                              <FiCornerDownRight />
                              <div class="form-check" >
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label class="form-check-label" for="defaultCheck1">
                                  Create invoice
                                </label>
                              </div>
                            </div>
                            <div class="form-check" style={{ display: "flex", paddingLeft: "90px" }}>
                              <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                              <label class="form-check-label" for="defaultCheck1">
                                <span style={{ paddingLeft: "10px" }}>View details</span>
                              </label>
                            </div>

                          </div>
                          <div className='col-4' style={{ paddingRight: "20px" }}>
                            <div class="form-check form-switch form-check-reverse">
                              <input class="form-check-input" type="checkbox" id="flexSwitchCheckReverse" />
                              <label class="form-check-label" for="flexSwitchCheckReverse">Active</label>
                            </div>
                          </div>
                        </div>
                        <hr />

                      </div>
                      <div className='text-center'>
                        <button type="button" class="btn btn-primary" style={{ borderRadius: "10px", padding: "10px 78px", border: "none", }}>Save Changes</button>
                      </div>
                    </div>
                  );
                })}

              </div>


              {/* Edit  */}
              <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasWithBackdrop1" aria-labelledby="offcanvasWithBackdropLabel" style={{ width: "40%" }}>
                <div class="offcanvas-header">
                  <h5 class="offcanvas-title" id="offcanvasWithBackdropLabel" style={{ fontStyle: "normal", fontWeight: "500", fontSize: "28px", color: "#272727", }}>SDE-2</h5>
                  <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                  <div className=''>
                    <button type="button" class="btn" data-bs-dismiss="offcanvas" aria-label="Close" style={{ width: "30%", color: "#FE5A5A", border: "1px solid #FE5A5A", padding: "9px 4px", }} onClick={handleShow}>Delet Role</button>
                    <button type="button" class="btn ms-5" data-bs-dismiss="offcanvas" aria-label="Close" style={{ width: "40%", color: "#5A81FE", border: "1px solid #5A81FE", padding: "9px 4px", }} onClick={handleShowOff}>Change Department</button>
                  </div>

                  <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "20px", color: "#272727", }}>Manage Access Control</p>
                  <div className='mt-3' style={{ border: "2px solid #FAFAFA", width: "75%", marginLeft: "80px", overflowY: "scroll", height: "60vh" }} >
                    <div className='row' style={{ backgroundColor: "#E5EBFF", width: "100%", marginLeft: "0px" }}>
                      <div className='col-6'>
                        <p>Panels</p>
                      </div>

                      <div className='col-6'>
                        <p style={{ paddingLeft: "73%" }}>Editing</p>
                      </div>
                    </div>

                    <div className='row mt-2'>
                      <div className='col-8' style={{ paddingLeft: "20px" }}>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                          <label class="form-check-label" for="defaultCheck1">
                            Academics
                          </label>
                        </div>

                        <div style={{ display: "flex", paddingLeft: "50px" }}>
                          <FiCornerDownRight />
                          <div class="form-check" >
                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            <label class="form-check-label" for="defaultCheck1">
                              Create invoice
                            </label>
                          </div>
                        </div>
                        <div class="form-check" style={{ display: "flex", paddingLeft: "90px" }}>
                          <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                          <label class="form-check-label" for="defaultCheck1">
                            <span style={{ paddingLeft: "10px" }}>View details</span>
                          </label>
                        </div>

                      </div>
                      <div className='col-4' style={{ paddingRight: "20px" }}>
                        <div class="form-check form-switch form-check-reverse">
                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckReverse" />
                          <label class="form-check-label" for="flexSwitchCheckReverse">Active</label>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div className='row'>
                      <div className='col-8' style={{ paddingLeft: "20px" }}>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                          <label class="form-check-label" for="defaultCheck1">
                            Support
                          </label>
                        </div>

                        <div style={{ display: "flex", paddingLeft: "50px" }}>
                          <FiCornerDownRight />
                          <div class="form-check" >
                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            <label class="form-check-label" for="defaultCheck1">
                              Create invoice
                            </label>
                          </div>
                        </div>
                        <div class="form-check" style={{ display: "flex", paddingLeft: "90px" }}>
                          <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                          <label class="form-check-label" for="defaultCheck1">
                            <span style={{ paddingLeft: "10px" }}>View details</span>
                          </label>
                        </div>

                      </div>
                      <div className='col-4' style={{ paddingRight: "20px" }}>
                        <div class="form-check form-switch form-check-reverse">
                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckReverse" />
                          <label class="form-check-label" for="flexSwitchCheckReverse">Active</label>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div className='row'>
                      <div className='col-8' style={{ paddingLeft: "20px" }}>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                          <label class="form-check-label" for="defaultCheck1">
                            Students
                          </label>
                        </div>

                        <div style={{ display: "flex", paddingLeft: "50px" }}>
                          <FiCornerDownRight />
                          <div class="form-check" >
                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            <label class="form-check-label" for="defaultCheck1">
                              Create invoice
                            </label>
                          </div>
                        </div>
                        <div class="form-check" style={{ display: "flex", paddingLeft: "90px" }}>
                          <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                          <label class="form-check-label" for="defaultCheck1">
                            <span style={{ paddingLeft: "10px" }}>View details</span>
                          </label>
                        </div>

                      </div>
                      <div className='col-4' style={{ paddingRight: "20px" }}>
                        <div class="form-check form-switch form-check-reverse">
                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckReverse" />
                          <label class="form-check-label" for="flexSwitchCheckReverse">Active</label>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div className='row'>
                      <div className='col-8' style={{ paddingLeft: "20px" }}>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                          <label class="form-check-label" for="defaultCheck1">
                            Analytics
                          </label>
                        </div>

                        <div style={{ display: "flex", paddingLeft: "50px" }}>
                          <FiCornerDownRight />
                          <div class="form-check" >
                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            <label class="form-check-label" for="defaultCheck1">
                              Create invoice
                            </label>
                          </div>
                        </div>
                        <div class="form-check" style={{ display: "flex", paddingLeft: "90px" }}>
                          <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                          <label class="form-check-label" for="defaultCheck1">
                            <span style={{ paddingLeft: "10px" }}>View details</span>
                          </label>
                        </div>

                      </div>
                      <div className='col-4' style={{ paddingRight: "20px" }}>
                        <div class="form-check form-switch form-check-reverse">
                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckReverse" />
                          <label class="form-check-label" for="flexSwitchCheckReverse">Active</label>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div className='row'>
                      <div className='col-8' style={{ paddingLeft: "20px" }}>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                          <label class="form-check-label" for="defaultCheck1">
                            Team
                          </label>
                        </div>

                        <div style={{ display: "flex", paddingLeft: "50px" }}>
                          <FiCornerDownRight />
                          <div class="form-check" >
                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            <label class="form-check-label" for="defaultCheck1">
                              Create invoice
                            </label>
                          </div>
                        </div>
                        <div class="form-check" style={{ display: "flex", paddingLeft: "90px" }}>
                          <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                          <label class="form-check-label" for="defaultCheck1">
                            <span style={{ paddingLeft: "10px" }}>View details</span>
                          </label>
                        </div>

                      </div>
                      <div className='col-4' style={{ paddingRight: "20px" }}>
                        <div class="form-check form-switch form-check-reverse">
                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckReverse" />
                          <label class="form-check-label" for="flexSwitchCheckReverse">Active</label>
                        </div>
                      </div>
                    </div>
                    <hr />

                  </div>
                  <div className='text-center'>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="offcanvas" aria-label="Close" onClick={handleShow1} style={{ borderRadius: "10px", padding: "10px 78px", border: "none", }}>Save Changes</button>
                  </div>

                  {/* <!---Save modal--> */}
                  {/* <!---end Save modal--> */}
                </div>
              </div>

            </div>

            <Modal show={show} onHide={handleClose} style={{ marginTop: "12rem", }}>

              <Modal.Body style={{ fontStyle: "normal", fontWeight: "400", fontSize: "30px", textAlign: "center", color: "#272727", }}>Role <span style={{ fontWeight: "600", }}>SDE- 2</span> Will Be<br></br> Deleted From Department <span style={{ fontWeight: "600", }}>Information Technology</span> </Modal.Body>
              <div className='d-flex mb-3'>
                <Button variant=" " onClick={handleClose2} style={{ width: "40%", marginLeft: "16px", border: "1px solid #F93333", padding: "12px", color: "#F93333", fontStyle: "normal", fontWeight: "500", fontSize: "16px", }}>Cancel  </Button>
                <Button variant="" onClick={handleShow2} style={{ width: "40%", marginLeft: "66px", border: "1px solid #40997E", padding: "12px", color: "#40997E", fontStyle: "normal", fontWeight: "500", fontSize: "16px", }}>Confirm</Button>
              </div>

            </Modal>
            <Modal show={show2} onHide={handleClose2} style={{ marginTop: "12rem", }}>

              <Modal.Body style={{ fontStyle: "normal", fontWeight: "400", fontSize: "30px", textAlign: "center", color: "#272727", }}>SDE - 2 Is Deleted From<br></br> Information Technology Successfully! </Modal.Body>
              <div className='d-flex mb-3'>
                <NavLink to="/Dashboard"><Button variant=" " style={{ width: "90%", marginLeft: "120px", background: "#5A81FE", boxShadow: "0px 4px 12px rgba(90, 129, 254, 0.25)", borderRadius: "5px", color: "#fff", border: "none", padding: "13px", }}>Back To Dashboard </Button></NavLink>

              </div>

            </Modal>

            <Modal show={show1} onHide={handleClose1}>
              <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
              </Modal.Header>
              <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose1}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleClose1}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>



            <Offcanvas show={showOff} onHide={handleCloseOff} placement="end" className="w-50">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Offcanvas</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                Some text as placeholder. In real life you can have the elements you
                have chosen. Like, text, images, lists, etc.
              </Offcanvas.Body>
            </Offcanvas>

          </div>
        </div>
      </div>
    </>
  );
}
