import React, { useState, useEffect } from 'react';
import AllLeads from './AllLeads';
import InConversation from './InConversation';
import Dropped from './Dropped';
import Converted from './Converted'
import { NavLink } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';


export default function Invoiesa() {
    const [value20, setvalue20] = useState();

    useEffect(() => {
        setvalue20('AllLeads')
    }, [])
    return (
        <>
            <div className="row ">
                <div className="col-2 bg-white" style={{ backgroundColor: "#fff", height: "100vh", }}>
                    <p className='ms-4  ' style={{ fontWeight: "600", color: "#3C3C3C", fontSize: "25px", marginTop: "40px" }}>  <NavLink to="/Lead" className="" style={{ zIndex: "999", }}>
                        <BsArrowLeft className='ps-1' style={{ color: '#000000', fontSize: "25px", cursor: "pointer" }} />
                    </NavLink> My Leads</p>
                    <span className='mt-4' onClick={() => { setvalue20('AllLeads') }}
                        style={{ display: "flex", backgroundColor: value20 == "AllLeads" ? "#F4F2F9" : "", border: "none", marginLeft: "30px", height: "45px", borderRadius: "5px", color: "#8A8A8A" }}>
                        <p className='pt-2' style={{ zIndex: '999', paddingLeft: "30px", color: value20 === "AllLeads" ? "#8147E7" : "", fontWeight: "400",cursor:"pointer" }}>All Leads</p></span>

                    <span className='mt-3' onClick={() => { setvalue20('InConversation') }}
                        style={{ display: "flex", backgroundColor: value20 == "InConversation" ? "#F4F2F9" : "", marginLeft: "30px", height: "45px", borderRadius: "5px", color: "#8A8A8A" }}>
                        <p className='pt-2' style={{ zIndex: '999', paddingLeft: "30px", color: value20 === "InConversation" ? "#8147E7" : "", fontWeight: "400",cursor:"pointer" }}>In Conversation</p></span>

                    <span className='mt-3' onClick={() => { setvalue20('Dropped') }}
                        style={{ display: "flex", backgroundColor: value20 == "Dropped" ? "#F4F2F9" : "", marginLeft: "30px", height: "45px", borderRadius: "5px", color: " #8A8A8A" }}>
                        <p className='pt-2' style={{ zIndex: '999', paddingLeft: "30px", color: value20 === "Dropped" ? "#8147E7" : "", fontWeight: "400",cursor:"pointer" }}>Dropped</p></span>
                    <span className='mt-3' onClick={() => { setvalue20('Converted') }}
                        style={{ display: "flex", backgroundColor: value20 == "Converted" ? "#F4F2F9" : "", marginLeft: "30px", height: "45px", borderRadius: "5px", color: " #8A8A8A" }}>
                        <p className='pt-2' style={{ zIndex: '999', paddingLeft: "30px", color: value20 === "Converted" ? "#8147E7" : "", fontWeight: "400",cursor:"pointer" }}>Converted</p></span>

                    {/* <span className='mt-3' onClick={()=>{setvalue20('Defaulterstudents')}} 
       style={{ display:"flex",backgroundColor:value20=="Defaulterstudents"?"#F4F2F9":"",marginLeft:"30px",height:"45px",borderRadius:"5px",color:" #8A8A8A"}}> 
           <p className='pt-2' style={{zIndex:'999',paddingLeft:"30px",color:value20==="Defaulterstudents"?"#8147E7":"",fontWeight:"400"}}>Defaulter</p></span>  */}
                </div>

                <div className="col-10" style={{ background: "rgb(240 245 254)", }}>
                    {
                        value20 === 'AllLeads' ? <><AllLeads /></> : <></>
                    }
                    {
                        value20 === 'InConversation' ? <><InConversation /></> : <></>
                    }
                    {
                        value20 === 'Dropped' ? <><Dropped /></> : <></>
                    }
                    {
                        value20 === 'Converted' ? <><Converted /></> : <></>
                    }
                </div>
            </div>




        </>
    )
}