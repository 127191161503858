import React,{useState,useEffect} from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL, img_url } from "../../env";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ParticularDepartment = () => {
  const Navigate = useNavigate();
  const {id} = useParams();
  const {department} = useParams()

  const [cardDetails,setCardDetails] = useState([]);

  const api = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      var raw = JSON.stringify({
        department_id: id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${API_URL}/admin/acount/view/user/in/particular/department`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
            if(result.status == 1){
                setCardDetails(result.employee_list);
            }else{
                toast.error(result.Backend_Error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }
        })
        .catch((error) => console.log("error", error));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    api();
  }, [])
  


  return (
    <>
      <div className="container-fluid">
        <div className="row p-0">
          <div className="col-12 p-0">
            <div
              className="d-flex align-items-center justify-content-between px-2 pt-0 mt-0"
              style={{
                height: "9vh",
                width: "100%",
                background: "#fff",
                boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
              }}
            >
              <div className="d-flex align-items-center ms-2">
                <IoIosArrowRoundBack
                  onClick={() => Navigate(-1)}
                  style={{
                    fontSize: "2rem",
                    color: "#3C3C3C",
                    cursor: "pointer",
                  }}
                />
                <span
                  className="ms-3"
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 600,
                    color: "#3D3D3D",
                    fontSize: "1.25rem",
                  }}
                >
                  particular department list
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div style={{ height: "91vh",overflowY:"auto", width: "100%",}}>
            <div className="container-fluid" >

              <div className="row mt-3" style={{rowGap:"10px"}} >
                {
                  cardDetails.map((value,index)=>(
                    <div className="col-3" key={index} >
                    <div className="card p-2 px-3 h-100" >
                      <h6 style={{fontFamily:"Inter",fontStyle:"normal",fontSize:"1rem",fontWeight:600,color:"#3D3D3D"}}>{value.name}</h6>
                      <div className="" >
                        <p style={{fontFamily:"Inter",fontStyle:"normal",fontSize:"0.875rem",fontWeight:400,color:"#767676"}}>Salary : ₹ {value.salary}</p>
                        <p style={{fontFamily:"Inter",fontStyle:"normal",fontSize:"0.875rem",fontWeight:400,color:"#767676"}}>Total Transactions : {value.tot_transaction}</p>
                        <p style={{fontFamily:"Inter",fontStyle:"normal",fontSize:"0.875rem",fontWeight:400,color:"#767676"}}>Total Amount : ₹ {value.tot_amount}</p>
                      </div>
                      <div className="mx-auto py-2 mt-3 w-100" >
                        <button  onClick={()=>Navigate(`/Salarymanagement/${value.id}/${value.name}/${id}/${department}/2`)} className="w-100 py-2 border-0" style={{background:"#F2EDFD",borderRadius:"3px",color:"#8147E7",fontSize:"1rem",fontFamily:"Inter",fontWeight:400}} >View Transactions</button>
                      </div>
                    </div>
                  </div>
                  ))
                }
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>
  );
};

export default ParticularDepartment;
