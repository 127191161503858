import React, { useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { FaUserGraduate } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import SingleAttendance from "./SingleAttendance";
import BulkAttendance from "./BulkAttendance";

const StudAttendance = () => {
  const [value,setValue] = useState("Attendance");
  return (
    <>
      <div className="container-fluid">
        <div className="row">
        <div
          className="col-2 bg-white sid"
          style={{ backgroundColor: "#fff", height: "100vh"}}
        >
          <p
            className="ms-4  "
            style={{
              fontWeight: "600",
              color: "#3C3C3C",
              fontSize: "20px",
              marginTop: "40px",
            }}
          >
            {" "}
            <NavLink to="/Students" className="" style={{ zIndex: "999" }}>
              <BsArrowLeft
                className="ps-1"
                style={{
                  color: "#000000",
                  fontSize: "25px",
                  cursor: "pointer",
                }}
              />
            </NavLink>{" "}
                Attendance
          </p>
          <span
          onClick={()=>setValue("Attendance")}
            className="mt-3"
            style={{
              display: "flex",
              backgroundColor: value=="Attendance"?"#8147E7":"transparent",
              border: "none",
              // marginLeft: "30px",
              height: "45px",
              borderRadius: "5px",
              color: value=="Attendance"?"#fff":"black",
            }}
          >
            <p
              className="pt-2"
              style={{
                zIndex: "999",
                paddingLeft: "30px",
                // color: "#8147E7",
                fontWeight: "400",
                cursor: "pointer",
              }}
            >
              <FaUserGraduate className="me-1" />
              Attendance
            </p>
          </span>
          <span
          onClick={()=>setValue("Bulk")}
            className="mt-3"
            style={{
              display: "flex",
              backgroundColor: value=="Bulk"?"#8147E7":"transparent",
              border: "none",
              // marginLeft: "30px",
              height: "45px",
              borderRadius: "5px",
              color: value=="Bulk"?"#fff":"black",
            }}
          >
            <p
              className="pt-2"
              style={{
                zIndex: "999",
                paddingLeft: "30px",
                fontWeight: "400",
                cursor: "pointer",
              }}
            >
              <FaUserGraduate className="me-1" />
              Bulk Attendance
            </p>
          </span>
        </div>

        {
          value=="Attendance"?<>
            <SingleAttendance/>
          </>:<></>
        }
        {
          value=="Bulk"?<>
            <BulkAttendance/>
          </>:<></>
        }
        </div>
      </div>
    </>
  );
};

export default StudAttendance;
