import React,{useState,useEffect} from 'react'
import Lottie from 'lottie-react'

import A2 from '../../images/A2.json';
import lotti from '../../images/129053-thumbs-up-birdie.json';
import { API_URL, img_url } from "../../env";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import ParticularDepartment from './ParticularDepartment';

function Employee() {
    const Navigate = useNavigate();

    const [total,setTotal] = useState("");
    const [data,setData] = useState([]);
const Api = async()=>{
    try{
        var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${API_URL}/admin/acount/account/dashboard/for/employee`, requestOptions)
  .then(response => response.json())
  .then((result) => {
    if(result.status==1){
        setTotal(result.total_amount);
        setData(result.department_list)
    }else{
        toast.error(result.Backend_Error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
    }
  })
  .catch(error => console.log('error', error));
    }catch(e){
        console.log(e);
    }
}

useEffect(() => {
Api();
}, [])



    return (
        <div className='container ' style={{paddingLeft:"3rem"}}>
              <div className='row container m-0 p-0 mt-3 '>
            <div className='col-lg-12 col-sm-12 col-md-12  '> <div className='card' >
                <div className='card-body'>
                    <h5 className="card-title" style={{textAlign:"center"}} >Debit Amount</h5>
                    <h6 class="card-subtitle mb-2 text-body-secondary" style={{textAlign:"center"}} > ₹ {total}</h6>
                  <div style={{display:"flex", justifyContent:"center"}}>
                  <Lottie animationData={A2} style={{ width: "10%", height: "", marginLeft: "0rem", }} />
                  </div>
                </div>
                </div> </div>
           
        </div>
            <div className='row container' style={{maxHeight:"45vh", overflowY:"scroll"}} >
        {
            
            data.map((data)=>{
               
                return(
                    <>
                    
                    <div className='col-lg-4 col-sm-12 col-md-12 mt-3' style={{cursor:"pointer"}} onClick={()=>Navigate(`/Accounts/employee/particular/department/${data.id}/${data.name}`)}   >
                        <div className='card p-1' >
                            <h5 className="card-title" style={{textAlign:"center"}} > Department : {data.name}</h5>
                            <h6 class="card-subtitle mb-2 text-body-secondary" style={{textAlign:"center"}} > Amount : ₹ {data.department_amount}</h6>
                            <h6 class="card-subtitle mb-2 text-body-secondary" style={{textAlign:"center"}} > Users : {data.total_users}</h6>
                        </div>
                    </div>
                     </>
                )
            })
        }
        </div>
        <ToastContainer/>
        </div>
    )
}

export default Employee