import React, { useState, useEffect } from 'react';
import Allstudentview from './allstudentview';
import Activestudents from './activestudents';
import Droppedstudents from './droppedstudents';
import Defaulterstudents from './defaulterstudents'
import { NavLink } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import { useParams } from 'react-router-dom';


export default function Invoiesa() {
  const [value20, setvalue20] = useState();
  const { status } = useParams();

  // console.log(status);

  useEffect(() => {

    if (status == 1) {
      setvalue20('Activestudents')
    }
    else if (status == 2) {
      setvalue20("Droppedstudents");
    }
    else {
      setvalue20('Allstudentview')
    }
  }, [])
  return (
    <>
      <div className="row ">
        <div className="col-2 bg-white" style={{ backgroundColor: "#fff", height: "100vh", }}>
          <p className='ms-4  ' style={{ fontWeight: "600", color: "#3C3C3C", fontSize: "25px", marginTop: "40px" }}>  <NavLink to="/Students" className="" style={{ zIndex: "999", }}>
            <BsArrowLeft className='ps-1' style={{ color: '#000000', fontSize: "25px", cursor: "pointer" }} />
          </NavLink> Students</p>
          <span className='mt-4' onClick={() => { setvalue20('Allstudentview') }}
            style={{ display: "flex", backgroundColor: value20 == "Allstudentview" ? "#F4F2F9" : "", border: "none", marginLeft: "30px", height: "45px", borderRadius: "5px", color: "#8A8A8A" }}>
            <p className='pt-2' style={{ zIndex: '999', paddingLeft: "30px", color: value20 === "Allstudentview" ? "#8147E7" : "", fontWeight: "400", cursor: 'pointer' }}>All Students</p></span>

          <span className='mt-3' onClick={() => { setvalue20('Activestudents') }}
            style={{ display: "flex", backgroundColor: value20 == "Activestudents" ? "#F4F2F9" : "", marginLeft: "30px", height: "45px", borderRadius: "5px", color: "#8A8A8A" }}>
            <p className='pt-2' style={{ zIndex: '999', paddingLeft: "30px", color: value20 === "Activestudents" ? "#8147E7" : "", fontWeight: "400", cursor: 'pointer' }}>Active Students</p></span>

          <span className='mt-3' onClick={() => { setvalue20('Droppedstudents') }}
            style={{ display: "flex", backgroundColor: value20 == "Droppedstudents" ? "#F4F2F9" : "", marginLeft: "30px", height: "45px", borderRadius: "5px", color: " #8A8A8A" }}>
            <p className='pt-2' style={{ zIndex: '999', paddingLeft: "30px", color: value20 === "Droppedstudents" ? "#8147E7" : "", fontWeight: "400", cursor: 'pointer' }}>Dropped</p></span>

          <span className='mt-3' onClick={() => { setvalue20('Defaulterstudents') }}
            style={{ display: "flex", backgroundColor: value20 == "Defaulterstudents" ? "#F4F2F9" : "", marginLeft: "30px", height: "45px", borderRadius: "5px", color: " #8A8A8A" }}>
            <p className='pt-2' style={{ zIndex: '999', paddingLeft: "30px", color: value20 === "Defaulterstudents" ? "#8147E7" : "", fontWeight: "400", cursor: 'pointer' }}>Defaulter</p></span>
        </div>

        <div className="col-10" style={{ background: "#E9E9E9", }}>
          
          {
            value20 === 'Allstudentview' ? <><Allstudentview /></> : <></>
          }
          {
            value20 === 'Activestudents' ? <><Activestudents /></> : <></>
          }
          {
            value20 === 'Droppedstudents' ? <><Droppedstudents /></> : <></>
          }
          {
            value20 === 'Defaulterstudents' ? <><Defaulterstudents /></> : <></>
          }
        </div>
      </div>
    </>
  )
}