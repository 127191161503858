import { useState, useContext, useEffect } from "react";
import Allinvoce from "./allinvoce";
import Unpaid from "./unpaid";
import Partially from "./partiallly";
import Paid from "./Paid";
import { NavLink } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { API_URL } from "../env";
import { AuthContext } from "./AuthContext";

export default function Invoiesa() {
  const [value20, setvalue20] = useState();
  const [Data, setData] = useState([]);
  const [State, setState] = useState("");
  const { apiToken } = useContext(AuthContext);

  const Api = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${API_URL}/student/student_invoice`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          setData(result.invoice);
          setState("");
        } else {
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    Api();
  }, [State]);

  useEffect(() => {
    setvalue20("Allinvoce");
  }, []);

  return (
    <>
      <div className="row ">
        <div
          className="col-2 bg-white"
          style={{ backgroundColor: "#fff", height: "100vh" }}
        >
          <p
            className="ms-4  "
            style={{
              fontWeight: "600",
              color: "#3C3C3C",
              fontSize: "25px",
              marginTop: "40px",
            }}
          >
            <NavLink to="/Accounts" className="" style={{ zIndex: "999" }}>
              <BsArrowLeft
                className="ps-1"
                style={{
                  color: "#000000",
                  fontSize: "25px",
                  cursor: "pointer",
                }}
              />
            </NavLink>{" "}
            Invoices
          </p>
          <span
            className="mt-4"
            onClick={() => {
              setvalue20("Allinvoce");
            }}
            style={{
              display: "flex",
              backgroundColor: value20 == "Allinvoce" ? "#F4F2F9" : "",
              border: "none",
              width: "180px",
              marginLeft: "30px",
              height: "45px",
              borderRadius: "5px",
              color: "#8A8A8A",
            }}
          >
            <p
              className="pt-2"
              style={{
                zIndex: "999",
                paddingLeft: "80px",
                color: value20 === "Allinvoce" ? "#8147E7" : "",
                fontWeight: "400",
                cursor: "pointer",
              }}
            >
              All
            </p>
          </span>

          <span
            className="mt-3"
            onClick={() => {
              setvalue20("Unpaid");
            }}
            style={{
              display: "flex",
              backgroundColor: value20 == "Unpaid" ? "#F4F2F9" : "",
              width: "180px",
              marginLeft: "30px",
              height: "45px",
              borderRadius: "5px",
              color: "#8A8A8A",
            }}
          >
            <p
              className="pt-2"
              style={{
                zIndex: "999",
                paddingLeft: "65px",
                color: value20 === "Unpaid" ? "#8147E7" : "",
                fontWeight: "400",
                cursor: "pointer",
              }}
            >
              Unpaid
            </p>
          </span>

          <span
            className="mt-3"
            onClick={() => {
              setvalue20("Partially");
            }}
            style={{
              display: "flex",
              backgroundColor: value20 == "Partially" ? "#F4F2F9" : "",
              width: "180px",
              marginLeft: "30px",
              height: "45px",
              borderRadius: "5px",
              color: " #8A8A8A",
            }}
          >
            <p
              className="pt-2"
              style={{
                zIndex: "999",
                paddingLeft: "45px",
                color: value20 === "Partially" ? "#8147E7" : "",
                fontWeight: "400",
                cursor: "pointer",
              }}
            >
              Partially Paid
            </p>
          </span>

          <span
            className="mt-3"
            onClick={() => {
              setvalue20("Paid");
            }}
            style={{
              display: "flex",
              backgroundColor: value20 == "Paid" ? "#F4F2F9" : "",
              width: "180px",
              marginLeft: "30px",
              height: "45px",
              borderRadius: "5px",
              color: " #8A8A8A",
            }}
          >
            <p
              className="pt-2"
              style={{
                zIndex: "999",
                paddingLeft: "75px",
                color: value20 === "Paid" ? "#8147E7" : "",
                fontWeight: "400",
                cursor: "pointer",
              }}
            >
              Paid
            </p>
          </span>
        </div>

        <div className="col-10" style={{ background: "#E9E9E9" }}>
          {value20 === "Allinvoce" ? (
            <>
              <Allinvoce Data={Data} stay={setState} />
            </>
          ) : (
            <></>
          )}
          {value20 === "Unpaid" ? (
            <>
              <Unpaid />
            </>
          ) : (
            <></>
          )}
          {value20 === "Partially" ? (
            <>
              <Partially />
            </>
          ) : (
            <></>
          )}
          {value20 === "Paid" ? (
            <>
              <Paid />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
