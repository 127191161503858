import React, { useContext, useEffect, useState } from "react"; //api done
import Header from './header';
import SideBar from './sidebar';
import { NavLink, useNavigate } from "react-router-dom";
import { HiArrowLeft } from 'react-icons/hi';
import LOGO from '../images/Group 339.png';
// import { API_URL } from "./MyComponent";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import { AuthContext } from "./AuthContext";


// const {API_URL,img_url} =env;


import { API_URL,img_url } from '../env';
import { toast } from "react-toastify";


export default function BatchHistory() {
    const [Batch, setBatch] = useState([])
    const [Message, setMessage] = useState();
    const [ShowLoader, setShowLoader] = useState(false);
    const { apiToken } = useContext(AuthContext);
    const {Navigate} = useNavigate();

    const batchHistory = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${apiToken}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${API_URL}/admin/batches/read/history/batch`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result)
                if (result.status == "001") {
                    setBatch(result.batchDetails);
                }
                else if (result.status == "CUSTOM_ERR") {
                    toast(result.Backend_Error);
                  } else if (result.status == "TOKEN_ERR") {
                    localStorage.removeItem('api_url');
                    Navigate('/code')
                    toast("Logged in other device! Please login again")
                  }
                  else {
                    console.log(result)
                  }
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        batchHistory();
    }, []);

    return (
        <>
<ToastContainer/>
            <div style={{ width: '100%', height: '100vh', }}>
            
                <div className='head' style={{ width: '80%', height: '14vh', marginLeft: '16%' }}>
                    <Header />
                </div>
                <div style={{ width: '100%', height: '86vh', display: "flex", }}>
                    <div style={{ width: '20%', height: '100%', position: 'absolute', top: 0, marginLeft: '1%' }} >
                        <SideBar />
                    </div>

                    <div className='maincontain' style={{ marginLeft: '15%', width: '100%', height: '100%', overflowY: 'scroll', }} >
                        <div className="row">
                            {/* <div className="col-1">
        <div className='' style={{color:"#F03838",backgroundColor:"#FFDCDC",
     width:"40px",borderRadius:"50px",height:"40px",marginLeft:"50px",paddingLeft:"12px",paddingTop:"6px"}}><ImBooks style={{fontSize:"20px"}} /></div>
      </div> */}

                            {/* <div className="col-2">
          <p style={{fontSize:"32px",}}><b>Batches</b></p>
    </div> */}
                            <div className="col-8 ps-5">
                                <div style={{ display: "flex" }}>
                                    <NavLink to="/Batch" className="pt-4 px-3" style={{ zIndex: "999", }}>
                                        <HiArrowLeft style={{ color: '#646464' }} />
                                    </NavLink>
                                    <img className="mt-3" src={LOGO} alt="sdf" style={{ width: "6%", height: "47px", }} />
                                    <p className="ms-3" style={{ fontSize: "48px", fontWeight: "400" }}>Batch History</p>

                                </div>
                                {/* <p  style={{paddingLeft:"110px"}}>Number of Subjects -  36</p> */}
                            </div>


                        </div>

                        <p className="" style={{ paddingLeft: "155px" }}>Number of Batches - {Batch.length}</p>
                        <div className="container" style={{ padding: "10px 61px 10px 90px" }}>
                            <div className="row" >
                                {
                                    Batch.map((res, key) => {
                                        return (

                                            <div className="col-4 mt-4" type="button" key={key} onClick={() => { batchHistory(res.id) }}>
                                                {/* <NavLink to={`/bhprofile/${res.id}`} className="text-decoration-none text-secondary"> */}
                                                    <div className="card" style={{ padding: "10px", color:"black"}}>
                                                        <p className="mx-3 mt-2" style={{ fontSize: "24px" }}>{res.batch_name}</p>
                                                        <p className="mx-3" style={{ fontSize: "16px" }}>Course : {res.course_name}</p>
                                                    </div>
                                                {/* </NavLink> */}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                       </div>


                    </div>
                </div>
            </div>






            <ToastContainer position="top-end" className="p-3" >
                <Toast onClose={() => setShowLoader(false)} show={ShowLoader} delay={8000} autohide style={{ backgroundColor: "#000" }}>
                    <Toast.Header>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        />
                        <strong className="me-auto">tipsg</strong>
                        <small>1 mins ago</small>
                    </Toast.Header>
                    <Toast.Body className="text-white">{Message}</Toast.Body>
                </Toast>
            </ToastContainer>



        </>
    )
}

