import React, { useState, useContext } from "react";
import Back from "../images/wave.png";
import { IoArrowBackOutline } from "react-icons/io5";
import { NavLink, useParams , useNavigate} from "react-router-dom";
import { API_URL } from "../env";
import { AuthContext } from "./AuthContext";
import { ToastContainer, toast } from "react-toastify";
// import moment from "moment/moment";
import moment from "moment";



const EditPaymentDetails = () => {
  let { id, remark ,amount , dueDate , page} = useParams();
  const { apiToken,setStuPayLoader ,stuPayLoader, setvalue } = useContext(AuthContext);
  const Navigate = useNavigate()
  console.log(dueDate)

  // states
  const [remarks, setRemark] = useState(remark);
  const [amounts, setAmount] = useState(amount);
  const [date, setDate] = useState(dueDate);



// calling update api
  const submitHandler = async( ) =>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${apiToken}`);


    let raw =JSON.stringify({
     id : Number(id) ,
      newRemark : String(remarks),
      newAmount : Number(amounts),
      newDueDate : moment(date).format("YYYY-MM-DD")
    });

    await fetch(`${API_URL}/admin/student/update/payment`, {
      method: "POST",
      headers: myHeaders,
      body : raw ,
    }) 
    .then(response=>response.json())
    .then((result)=>{
      toast.success("Payment Updated Successfully")
      console.log(result)})
      .catch((error) => console.log("error", error));

      

  }

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        background: "#FBFDFF",
        backgroundImage: `url(${Back})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
      }}
    >
      <div className="">
        <NavLink to="/PaymentHistory/1">
          <IoArrowBackOutline
            style={{
              marginLeft: "17px",
              fontSize: "1.5rem",
              marginTop: "1rem",
              color: "#000",
              cursor: "pointer",
            }}
          />
        </NavLink>
        <div className="row">
          <div className="col-4"></div>
          <div className="col-4">
            <div
              className="card mt-5 ps-3"
              style={{
                border: "none",
                width: "100%",
                background: "#FFFFFF",
                boxShadow: "0px 4px 12px rgba(158, 158, 158, 0.25)",
                borderRadius: "10px",
              }}
            >
              {/* <form className="mt-4" onSubmit={submitHandler}> */}


                <div>
                  <label
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "20px",
                      color: "#414141",
                    }}
                  >
                    Remark
                  </label>
                  <br></br>

                  {/* input for remark */}
                  <input
                    onChange={(e) => {
                      setRemark(e.target.value);
                    }}
                    className="mt-2"
                    type="text"
                    value={remarks}
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #EDEDED",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "95%",
                    }}
                  />
                </div>
                <div className="mt-4">
                  <label
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "20px",
                      color: "#414141",
                    }}
                  >
                    Amount
                  </label>
                  <br></br>

                  {/* input for amount */}
                  <input
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                    className="mt-2"
                    type="number"
                    value={amounts}
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #EDEDED",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "95%",
                    }}
                    d="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <label
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "20px",
                      color: "#414141",
                    }}
                  >
                    Date
                  </label>
                  <br></br>

                  {/* input for date */}
                  <input
                    // placeholder={date}
                    className="mt-2"
                    type="date"
                    value={date}
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #EDEDED",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "95%",
                    }}
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                  />
                </div>

                {/* button */}
                <div className="mt-5 mb-4 text-center">
                  <button
                    type="click"
                    class="btn btn-primary"
                    style={{
                      width: "90%",
                      background: "#3572FA",
                      boxShadow: "0px 2px 8px #B0BEEF",
                      borderRadius: "10px",
                      padding: "15px",
                    }}
                    onClick={()=>{
                      submitHandler();
                      setStuPayLoader(!stuPayLoader)
                      Navigate(-1)
                      setvalue(page)
                    }}
                  >
                    Edit
                  </button>
                </div>
              {/* </form> */}
            </div>
          </div>
          <div className="col-4"></div>
        </div>
      </div>
    </div>
  );
};

export default EditPaymentDetails;
