import React, { useState, useContext } from "react";
import img1 from '../images/12-compressed.jpg'
import hand from "../images/hand.png";
import "./test.css";
import OTPInput, { ResendOTP } from "otp-input-react";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useRequireAuth } from './RequireAuth';
import { withRouter } from "react-router-dom";




import { API_URL, img_url } from '../env';


export default function Login() {
  const [count, setcount] = useState(4);
  // const [otp, setOtp] = useState("");
  const [OTP, setOTP] = useState("");

  const { login, profile, apiToken, profileGet, } = useContext(AuthContext);
  const Navigate = useNavigate();

  //  const { login, profile, profileGet ,logout} = useContext(AuthContext);
  const navigate = useNavigate();
  const [Error, seterror] = useState();
  const [Email, setEmail] = useState("");
  const [Pass, setPass] = useState("");
  const [Confirm, setConfirm] = useState("");
  const [Confirm1, setConfirm1] = useState("");
  // const { name, banner } = profile;
  //useRequireAuth('/code');




  const loginApi = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "email": Email,
      "password": Pass
    });
    // var formdata = new FormData();
    // formdata.append("email", Email);
    // formdata.append("password", Pass);

    var requestOptions = {
      headers: myHeaders,
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/admin/authentication/admin/login`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result,'tokenssss');
        const response = login();
        if (result.status == "1") {
          localStorage.setItem("access_control",result.access_control)
          const token = result.token;
          login(token);
          navigate("/Setting");

        } else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem("token")
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }
       
      })
      .catch((error) => console.log("error", error));
  };



  // const Forget = () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Bearer ${apiToken}`);

  //   var formdata = new FormData();
  //   formdata.append("email", Email);

  //   var requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: formdata,
  //     redirect: 'follow'
  //   };

  //   fetch(`${API_URL}/admin-forgot-password`, requestOptions)
  //     .then(response => response.json())
  //     .then(result => {
  //       // console.log(result);
  //       if (result.status == "001") {
  //         if (count == 5) {
  //           setcount(count + 1);
  //         }
  //       }
  //       else {
  //         toast(result.message);
  //       }

  //     })
  //     .catch(error => console.log('error', error));
  // }



  // const OtpValidate = () => {
  //   var formdata = new FormData();
  //   formdata.append("otp", OTP);
  //   formdata.append("email", Email);

  //   var requestOptions = {
  //     method: 'POST',
  //     body: formdata,
  //     redirect: 'follow'
  //   };

  //   fetch(`${API_URL}/otpValidateforgot`, requestOptions)
  //     .then(response => response.json())
  //     .then(result => {
  //       console.log(result);
  //       if (result.status == "001") {
  //         console.log(result);
  //         setcount(count + 1);
  //       }
  //       else {
  //         toast(result.message);
  //       }

  //     })
  //     .catch(error => console.log('error', error));
  // }



  // const ConfirmPass = () => {
  //   var formdata = new FormData();
  //   formdata.append("email", Email);
  //   formdata.append("password", Confirm);
  //   formdata.append("password_confirmation", Confirm1);

  //   var requestOptions = {
  //     method: 'POST',
  //     body: formdata,
  //     redirect: 'follow'
  //   };

  //   fetch(`${API_URL}/passwordUpdate`, requestOptions)
  //     .then(response => response.json())
  //     .then(result => {
  //       console.log(result)
  //       if (result.status == "001") {
  //         setcount(count + 1);
  //       }
  //       else {
  //         toast(result.message);
  //       }
  //     })
  //     .catch(error => console.log('error', error));
  // }




  return (
    <>
      <ToastContainer />

      {/* login screen start */}
      <div style={{ display: count === 4 ? "block" : "none" }}>
        <div className="">
          <div className="row m-0 p-0 g-0">
            <div className="col-lg-6 m-0 p-0 g-0">
              <img
                src={img1}
                alt="nn"
                style={{ width: "100%", height: "45rem" }}
              />
            </div>
            <div className="col-lg-6 m-0 p-0 g-0">
              <div
                className="container-fluid m-0 p-0 g-0"
                style={{ backgroundColor: "#FFFFFF" }}
              >
                <div
                  className="card m-0 p-3 g-0 pb-4 pt-4"
                  style={{ backgroundColor: "#FFFFFF", border: "none" }}
                >
                  <div
                    className="card-header m-0 p-0 g-0  pt-3"
                    style={{ backgroundColor: "#FFFFFF", border: "none" }}
                  >
                    <p className="card-title m-0 p-0 g-0 ">
                      <img
                        // src={`${banner}`}
                        alt="bb"
                        style={{ width: "15%" }}
                      />
                      <p
                        className="card-text ms-3 mt-2"
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          color: "#434343",
                          fontSize: "40px",
                          fontWeight: "600px",
                        }}
                      >
                        Hey, Hello
                        <span>
                          <img src={hand} alt="nn" style={{ width: "5%" }} />
                        </span>
                      </p>
                      <p
                        className="card-text ms-3 mt-2"
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "15px",
                          color: "#BDBDBD",
                        }}
                      >
                        Enter the information you entered while registering
                      </p>
                    </p>
                  </div>
                  <div className="card-body">
                    <div>
                      <p
                        className="card-text display-6 mt-4"
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontSize: "32px",
                          color: "#000000",
                          fontWeight: "500",
                        }}
                      >
                        Login
                      </p>
                      <label
                        for="exampleFormControlInput1"
                        class="form-label"
                        style={{
                          fontSize: "16px",
                          fontWeight: "400px",
                          fontFamily: "Poppins",
                        }}
                      >
                        Email address
                      </label>
                      <div class="input-group mb-3">
                        <span
                          class="input-group-text"
                          id="basic-addon1"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <svg
                            width="20"
                            height="16"
                            viewBox="0 0 20 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.5 2.5L8.10764 6.61227L8.10967 6.61396C8.78785 7.11128 9.12714 7.3601 9.49876 7.45621C9.82723 7.54117 10.1725 7.54117 10.501 7.45621C10.8729 7.36001 11.2132 7.11047 11.8926 6.61227L17.5002 2.5M1 11.8002V4.2002C1 3.08009 1 2.51962 1.21799 2.0918C1.40973 1.71547 1.71547 1.40973 2.0918 1.21799C2.51962 1 3.08009 1 4.2002 1H15.8002C16.9203 1 17.4796 1 17.9074 1.21799C18.2837 1.40973 18.5905 1.71547 18.7822 2.0918C19 2.5192 19 3.07899 19 4.19691V11.8036C19 12.9215 19 13.4805 18.7822 13.9079C18.5905 14.2842 18.2837 14.5905 17.9074 14.7822C17.48 15 16.921 15 15.8031 15H4.19691C3.07899 15 2.5192 15 2.0918 14.7822C1.71547 14.5905 1.40973 14.2842 1.21799 13.9079C1 13.4801 1 12.9203 1 11.8002Z"
                              stroke="#555555"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        <input
                          type="email"
                          class="form-control"
                          placeholder="Please Enter Your Email Address"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          style={{ borderLeft: "none" }}
                          value={Email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                      <label
                        for="exampleFormControlInput1"
                        class="form-label"
                        style={{ fontSize: "16px", fontWeight: "400px" }}
                      >
                        Password
                      </label>
                      <div class="input-group mb-3">
                        <span
                          class="input-group-text"
                          id="basic-addon1"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.23047 9H7.2002C6.08009 9 5.51962 9 5.0918 9.21799C4.71547 9.40973 4.40973 9.71547 4.21799 10.0918C4 10.5196 4 11.0801 4 12.2002V17.8002C4 18.9203 4 19.4801 4.21799 19.9079C4.40973 20.2842 4.71547 20.5905 5.0918 20.7822C5.5192 21 6.07902 21 7.19694 21H16.8031C17.921 21 18.48 21 18.9074 20.7822C19.2837 20.5905 19.5905 20.2842 19.7822 19.9079C20 19.4805 20 18.9215 20 17.8036V12.1969C20 11.079 20 10.5192 19.7822 10.0918C19.5905 9.71547 19.2837 9.40973 18.9074 9.21799C18.4796 9 17.9203 9 16.8002 9H14.7689M9.23047 9H14.7689M9.23047 9C9.10302 9 9 8.89668 9 8.76923V6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6V8.76923C15 8.89668 14.8964 9 14.7689 9"
                              stroke="#555555"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Please Enter Your password"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          style={{ borderLeft: "none", padding: "7px" }}
                          value={Pass}
                          onChange={(e) => {
                            setPass(e.target.value);
                          }}
                        />
                      </div>
                      <div className="mb-3 form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                        />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <label
                            className="form-check-label"
                            for="exampleCheck1"
                            style={{ fontSize: "16px", fontWeight: "400px" }}
                          >
                            Remember me
                          </label>
                          {/* <p
                            className="ms-5"
                            style={{
                              color: "blue",
                              fontSize: "12px",
                              float: "right",
                              cursor: "pointert",
                            }}
                            onClick={() => {
                              setcount(count + 1);
                            }}
                          >
                            Forgotpassword ?
                          </p> */}
                        </div>
                      </div>

                      <div></div>
                      <button
                        onClick={()=>loginApi()}
                        type="submit"
                        className="btn btn-primary"
                        style={{
                          backgroundColor: "#8F6DFF",
                          width: "100%",
                          marginTop: "40px",
                          padding: "11px",
                          border: "none",
                        }}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </div>
      {/* login screen complete */}

      {/* Forget password start */}
      <div style={{ display: count === 5 ? "block" : "none" }}>
        <div className="">
          <div className="row m-0 p-0 g-0">
            <div className="col-lg-6 m-0 p-0 g-0">
              <img
                src={img1}
                alt="nn"
                style={{ width: "100%", height: "45rem" }}
              />
            </div>
            <div className="col-lg-6 m-0 p-0 g-0">
              <div
                className="container-fluid m-0 p-0 g-0"
                style={{ backgroundColor: "#FFFFFF" }}
              >
                <div
                  className="card m-0 p-3 g-0 pb-4 pt-4"
                  style={{ backgroundColor: "#FFFFFF", border: "none" }}
                >
                  <div
                    className="card-header m-0 p-0 g-0  pt-3"
                    style={{ backgroundColor: "#FFFFFF", border: "none" }}
                  >
                    <p className="card-title m-0 p-0 g-0 ">
                      <img
                        // src={`${banner}`}
                        alt="bb"
                        style={{ width: "15%" }}
                      />
                    </p>
                  </div>
                  <div className="card-body">
                    <div style={{ marginTop: "3rem" }}>
                      <p
                        className="card-text display-6 mt-4"
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "32px",
                          lineHeight: "20px",
                          display: "flex",
                          alignItems: "center",
                          textAlign: "center",
                          letterSpacing: "0.1px",
                          color: "#000000",
                        }}
                      >
                        Forgot Password?
                      </p>
                      <p
                        className="card-text mt-2"
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#BDBDBD",
                        }}
                      >
                        No worries, We will send you reset instructions.
                      </p>
                      <label
                        for="exampleFormControlInput1"
                        class="form-label"
                        style={{
                          marginTop: "3rem",
                          fontSize: "16px",
                          fontWeight: "400px",
                          fontFamily: "Poppins",
                        }}
                      >
                        Enter your E-mail here
                      </label>
                      <div class="input-group mb-3">
                        <span
                          class="input-group-text"
                          id="basic-addon1"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <svg
                            width="20"
                            height="16"
                            viewBox="0 0 20 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.5 2.5L8.10764 6.61227L8.10967 6.61396C8.78785 7.11128 9.12714 7.3601 9.49876 7.45621C9.82723 7.54117 10.1725 7.54117 10.501 7.45621C10.8729 7.36001 11.2132 7.11047 11.8926 6.61227L17.5002 2.5M1 11.8002V4.2002C1 3.08009 1 2.51962 1.21799 2.0918C1.40973 1.71547 1.71547 1.40973 2.0918 1.21799C2.51962 1 3.08009 1 4.2002 1H15.8002C16.9203 1 17.4796 1 17.9074 1.21799C18.2837 1.40973 18.5905 1.71547 18.7822 2.0918C19 2.5192 19 3.07899 19 4.19691V11.8036C19 12.9215 19 13.4805 18.7822 13.9079C18.5905 14.2842 18.2837 14.5905 17.9074 14.7822C17.48 15 16.921 15 15.8031 15H4.19691C3.07899 15 2.5192 15 2.0918 14.7822C1.71547 14.5905 1.40973 14.2842 1.21799 13.9079C1 13.4801 1 12.9203 1 11.8002Z"
                              stroke="#555555"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        <input
                          type="email"
                          class="form-control"
                          placeholder="Demo@gmail.com"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          style={{ borderLeft: "none" }}
                          value={Email}
                          onChange={(e) => { setEmail(e.target.value) }}
                        />
                      </div>

                      <div className="mb-3 form-check">
                        {/* <input type="checkbox" className="form-check-input" id="exampleCheck1"/> */}
                        {/* <label className="form-check-label" for="exampleCheck1" style={{fontSize:"16px",fontWeight:"400px"}}>Remember me</label> */}
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // onClick={() => {
                        //   Forget();
                        // }}
                        style={{
                          backgroundColor: "#8F6DFF",
                          width: "100%",
                          marginTop: "9rem",
                          padding: "11px",
                          border: "none",
                        }}
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Forget password end */}

      {/* password Reset start */}
      <div style={{ display: count === 6 ? "block" : "none" }}>
        <div className="">
          <div className="row m-0 p-0 g-0">
            <div className="col-lg-6 m-0 p-0 g-0">
              <img
                src={img1}
                alt="nn"
                style={{ width: "100%", height: "45rem" }}
              />
            </div>
            <div className="col-lg-6 m-0 p-0 g-0">
              <div
                className="container-fluid m-0 p-0 g-0"
                style={{ backgroundColor: "#FFFFFF" }}
              >
                <div
                  className="card m-0 p-3 g-0 pb-4"
                  style={{ backgroundColor: "#FFFFFF", border: "none" }}
                >
                  <div
                    className="card-header m-0 p-0 g-0"
                    style={{ backgroundColor: "#FFFFFF", border: "none" }}
                  >
                    <p className="card-title m-0 p-0 g-0 pt-4 ">
                      <img
                        // src={`${banner}`}
                        alt="bb"
                        style={{ width: "15%" }}
                      />
                      <p
                        className="card-text ms-3 mt-5"
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "40px",
                          lineHeight: "60px",
                          color: "#434343",
                        }}
                      >
                        Password Reset
                      </p>
                      <p
                        className="card-text ms-3"
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "19px",
                          lineHeight: "54px",
                          textAlign: "center",
                          color: "#434343",
                        }}
                      >
                        We Have Sent You An One Time Password On E-mail Address
                      </p>
                      <p
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                          textAlign: "center",
                          color: "#BDBDBD",
                        }}
                      >
                        {Email},
                        <span onClick={() => { setcount(count - 1) }} style={{ color: "#8F6DFF", cursor: "pointer" }}>
                          Change E-mail address
                        </span>
                      </p>
                    </p>
                  </div>

                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    style={{ marginTop: "3rem", justifyContent: "center" }}
                  />

                  <div>
                    <p
                      className="mt-5"
                      style={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "24px",
                        textAlign: "center",
                        color: "#BDBDBD",
                      }}
                    >
                      Don’t Receive the OTP ?
                      <span style={{ color: "#8F6DFF", cursor: "pointer" }} 
                      // onClick={() => { Forget() }}
                      >Resend OTP </span>
                    </p>
                  </div>
                  <div className="card-body">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      // onClick={() => {
                      //   OtpValidate();
                      // }}
                      style={{
                        backgroundColor: "#8F6DFF",
                        width: "100%",
                        marginTop: "5rem",
                        padding: "11px",
                        border: "none",
                      }}
                    >
                      Verify and Proceed
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* password Reset end */}

      {/* passwordriset1 start */}
      <div style={{ display: count === 7 ? "block" : "none" }}>
        <div className="">
          <div className="row m-0 p-0 g-0">
            <div className="col-lg-6 m-0 p-0 g-0">
              <img
                src={img1}
                alt="nn"
                style={{ width: "100%", height: "45rem" }}
              />
            </div>
            <div className="col-lg-6 m-0 p-0 g-0">
              <div
                className="container-fluid m-0 p-0 g-0"
                style={{ backgroundColor: "#FFFFFF" }}
              >
                <div
                  className="card m-0 p-3 g-0 pb-4 pt-4"
                  style={{ backgroundColor: "#FFFFFF", border: "none" }}
                >
                  <div
                    className="card-header m-0 p-0 g-0  pt-3"
                    style={{ backgroundColor: "#FFFFFF", border: "none" }}
                  >
                    <p className="card-title m-0 p-0 g-0 ">
                      <img
                        // src={`${banner}`}
                        alt="bb"
                        style={{ width: "15%" }}
                      />
                      <p
                        className="card-text ms-3 mt-5"
                        style={{
                          color: "#434343",
                          fontSize: "40px",
                          fontWeight: "600px",
                        }}
                      >
                        Forgot Password?
                      </p>
                    </p>
                  </div>
                  <div className="card-body">
                    <div>
                      <label
                        for="exampleFormControlInput1"
                        class="form-label mt-5"
                        style={{
                          fontSize: "16px",
                          fontWeight: "400px",
                          fontFamily: "Poppins",
                        }}
                      >
                        Enter your new password
                      </label>
                      <div class="input-group mb-3">
                        <span
                          class="input-group-text"
                          id="basic-addon1"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.23047 9H7.2002C6.08009 9 5.51962 9 5.0918 9.21799C4.71547 9.40973 4.40973 9.71547 4.21799 10.0918C4 10.5196 4 11.0801 4 12.2002V17.8002C4 18.9203 4 19.4801 4.21799 19.9079C4.40973 20.2842 4.71547 20.5905 5.0918 20.7822C5.5192 21 6.07902 21 7.19694 21H16.8031C17.921 21 18.48 21 18.9074 20.7822C19.2837 20.5905 19.5905 20.2842 19.7822 19.9079C20 19.4805 20 18.9215 20 17.8036V12.1969C20 11.079 20 10.5192 19.7822 10.0918C19.5905 9.71547 19.2837 9.40973 18.9074 9.21799C18.4796 9 17.9203 9 16.8002 9H14.7689M9.23047 9H14.7689M9.23047 9C9.10302 9 9 8.89668 9 8.76923V6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6V8.76923C15 8.89668 14.8964 9 14.7689 9"
                              stroke="#555555"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Please Enter Your New Password"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          style={{ borderLeft: "none", padding: "11px" }}
                          onChange={(e) => { setConfirm(e.target.value) }}
                        />
                      </div>
                      <label
                        for="exampleFormControlInput1"
                        class="form-label mt-4"
                        style={{ fontSize: "16px", fontWeight: "400px" }}
                      >
                        Confirm Password
                      </label>
                      <div class="input-group mb-3">
                        <span
                          class="input-group-text"
                          id="basic-addon1"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.23047 9H7.2002C6.08009 9 5.51962 9 5.0918 9.21799C4.71547 9.40973 4.40973 9.71547 4.21799 10.0918C4 10.5196 4 11.0801 4 12.2002V17.8002C4 18.9203 4 19.4801 4.21799 19.9079C4.40973 20.2842 4.71547 20.5905 5.0918 20.7822C5.5192 21 6.07902 21 7.19694 21H16.8031C17.921 21 18.48 21 18.9074 20.7822C19.2837 20.5905 19.5905 20.2842 19.7822 19.9079C20 19.4805 20 18.9215 20 17.8036V12.1969C20 11.079 20 10.5192 19.7822 10.0918C19.5905 9.71547 19.2837 9.40973 18.9074 9.21799C18.4796 9 17.9203 9 16.8002 9H14.7689M9.23047 9H14.7689M9.23047 9C9.10302 9 9 8.89668 9 8.76923V6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6V8.76923C15 8.89668 14.8964 9 14.7689 9"
                              stroke="#555555"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Please Enter Confirm password"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          style={{ borderLeft: "none", padding: "11px" }}
                          onChange={(e) => { setConfirm1(e.target.value) }}
                        />
                      </div>
                      <div className="mb-3 form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                        />
                        <label
                          className="form-check-label"
                          for="exampleCheck1"
                          style={{ fontSize: "16px", fontWeight: "400px" }}
                        >
                          Remember me
                        </label>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // onClick={() => {
                        //   ConfirmPass();
                        // }}
                        style={{
                          backgroundColor: "#8F6DFF",
                          width: "100%",
                          marginTop: "5rem",
                          padding: "11px",
                          border: "none",
                        }}
                      >
                        Verify and Proceed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* passwordriset1 end */}

      {/* password riset page start */}
      <div style={{ display: count === 8 ? "block" : "none" }}>
        <div className="">
          <div className="row m-0 p-0 g-0">
            <div className="col-lg-6 m-0 p-0 g-0">
              <img
                src={img1}
                alt="nn"
                style={{ width: "100%", height: "45rem" }}
              />
            </div>
            <div className="col-lg-6 m-0 p-0 g-0">
              <div
                className="container-fluid m-0 p-0 g-0"
                style={{ backgroundColor: "#FFFFFF" }}
              >
                <div
                  className="card m-0 p-3 g-0 pb-4"
                  style={{ backgroundColor: "#FFFFFF", border: "none" }}
                >
                  <div
                    className="card-header m-0 p-0 g-0"
                    style={{ backgroundColor: "#FFFFFF", border: "none" }}
                  >
                    <p className="card-title m-0 p-0 g-0 pt-4 ">
                      <img
                        // src={`${banner}`}
                        alt="bb"
                        style={{ width: "15%" }}
                      />
                      <p
                        className="card-text ms-3 mt-2"
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "40px",
                          lineHeight: "60px",
                          color: "#434343",
                        }}
                      >
                        Password Reset
                      </p>
                      <p
                        className="card-text ms-3"
                        style={{
                          marginTop: "8rem",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "20px",
                          lineHeight: "54px",
                          textAlign: "center",
                          color: "#434343",
                        }}
                      >
                        Password Reset has been successful !
                      </p>
                    </p>
                  </div>
                  <div className="card-body" style={{ marginTop: "8rem" }}>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={() => {
                        setcount(4);
                      }}
                      style={{
                        backgroundColor: "#8F6DFF",
                        width: "100%",
                        marginTop: "13px",
                        padding: "11px",
                        border: "none",
                      }}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* password riset page end */}
    </>
  );
}

// export default Login;
