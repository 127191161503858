import React, { useState, useEffect } from 'react';//api done
import { AiOutlineEye } from 'react-icons/ai';
import DatePicker from "react-datepicker";
import { BiCalendar } from 'react-icons/bi';
import { BsFillCalendarFill } from "react-icons/bs";
import TIPG from '../images/tipsglogo.png';
import "react-datepicker/dist/react-datepicker.css"
import { FiDownload } from "react-icons/fi"
import { RiArrowDropDownLine } from 'react-icons/ri';
import { RiFileExcel2Line } from 'react-icons/ri';
import { AuthContext } from './AuthContext';
import { useContext } from 'react';
// import { API_URL } from './MyComponent';
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';




import { API_URL,img_url } from '../env';
import * as XLSX from 'xlsx';



export default function Allinvoce() {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date());
 
  const { apiToken } = useContext(AuthContext);
  const [students, setStudents] = useState([]);
  const [filterStudents, setFilterStudents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalstudents, setTotalstudents] = useState();
  const [req_str_date, setReqStartDate] = useState();
  const [req_end_date, setReqEndDate] = useState();
  const [isFilterApplied, setIsFilterApplied] = useState(false);





  const convertToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
  
    // Map the array to include only the desired properties
    const selectiveDataArray = students.map((student) => ({
      Name: student.name,
      Course: student.course_name,
      Fees: student.finalAmount,
      Batch: student.batch_name,
      Status: student.status == 1 ? "Dropped" : "Active",
    }));
  
    // Convert the selective data array into a worksheet
    const worksheet = XLSX.utils.json_to_sheet(selectiveDataArray);
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
    // Generate a file name
    const fileName = 'Dropped_Students_Data.xlsx';
  
    // Generate Excel file binary data
    const excelData = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
  
    // Create a blob object from the binary data
    const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
    // Create a download link
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
  
    // Simulate a click to trigger the download
    link.click();
  
    // Clean up
    URL.revokeObjectURL(url);
  };
  


  const getstudent = async (page) => {


    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/student/dropouts_students?page=${page}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status == "001") {
          setStudents(result.dropouts_students.data);
          setFilterStudents(result.dropouts_students.data);
          setTotalPages(result.dropouts_students.last_page)
          setTotalstudents(result.dropouts_students.total);
        }
      })
      .catch(error => console.log('error', error));
  }

  const FilterData = async (page) => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var formdata = new FormData();
    formdata.append("start_date", req_str_date);
    formdata.append("end_date", req_end_date);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${API_URL}/student/filter_drop_students?page=${page}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status == '001') {
          setFilterStudents(result.drop_students.data);
          setTotalPages(result.drop_students.last_page)
          setTotalstudents(result.drop_students.total);
          setIsFilterApplied(true);

        }

        else {
          toast(result.message + " " + "please enter the valid dates")
          setFilterStudents([]);
          setTotalPages(1)
          setTotalstudents(0);
        }

      })
      .catch(error => console.log('error', error));
  }

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const calculateSerialNumber = (index) => {
    return (currentPage - 1) * 5 + (index + 1);
  };

  useEffect(() => {
   isFilterApplied? FilterData(currentPage) :  getstudent(currentPage);

  }, [currentPage])


  return (
    <>

    <ToastContainer/>
      <div className="card mt-4 ms-4" style={{ width: "95%", border: "none" }}>
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <p style={{ fontWeight: "400", color: "#8A8A8A", fontSize: "18px" }}>Date Range</p>
              <div className="row mt-1">
                <div className="col-4">
                  <p style={{ color: "#8A8A8A", fontSize: "400" }}>From</p>
                  <div>

                    <span style={{
                      display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "0px",
                      borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
                    }}>
                      <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
                      <div>
                      <DatePicker className="border-0 ps-0 ps-2 datep"
                       
                          selected={startDate}
                          dateFormat="yyyy/MM/dd"
                          onChange={(date) => {
                            const req_date = date.getFullYear() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getDate();
                            // console.log(req_date);
                            setReqStartDate(req_date);
                            setStartDate(date);
                          }
                          }
                          style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                      </div>
                    </span>
                  </div>
                </div>
                <div className="col-4">
                  <p style={{ color: "#8A8A8A", fontSize: "400" }}>To</p>
                  <div>

                    <span style={{
                      display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "0px",
                      borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
                    }}>
                      <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
                      <div>
                      <DatePicker className="border-0  ps-0 ps-2 datep"
                          
                          selected={endDate}
                          dateFormat="yyyy/MM/dd"
                          onChange={(date) => {
                            const req_date = date.getFullYear() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getDate();
                            // console.log(req_date);
                            setEndDate(date);
                            setReqEndDate(req_date);
                          }}
                          style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                      </div>
                    </span>
                  </div>
                </div>
                <div className='col-3 mt-3' >
                  <button className='btn' style={{ backgroundColor: "#8147E7", borderRadius: "5px", color: "#ffffff", fontWeight: "400", fontSize: "17px", }} onClick={() => FilterData()} >
                    <span className=''>Apply</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="col-3 mt-3">

            </div>
            <div className="col-3">
              <p style={{ fontSize: "18px", fontWeight: "400", color: "#8A8A8A" }}>Total Students</p>
              <p style={{ fontWeight: "500", fontSize: "20px", color: "#3C3C3C" }}>{totalstudents}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Invoice Details */}

      <div className="mt-5">
        <div className='d-flex'>
          <p className="" style={{ color: "#3C3C3C", fontSize: '22px', fontWeight: "600", paddingLeft: "30px" }}>Drop Student Details</p>
          <button className=' ' style={{
            backgroundColor: "#8147E7", borderRadius: "5px", width: "180px", height: "50px", border: "none", marginLeft: "48.6rem",
            color: "#ffffff", fontWeight: "400", fontSize: "20px"
          }} onClick={() =>{convertToExcel()}}><RiFileExcel2Line style={{ fontSize: "20px" }} /> &nbsp;&nbsp;Export Data</button>
        </div>
        <div className='mt-3 '>
          <div className='row mx-4 mt-3'  >
            <div className='row' style={{ color: "#434343", fontWeight: "600", fontSize: "15px" }}>
              <div className='col-1'>
                <p>S.No.</p>
              </div>
              <div className='col-3'>
                <p>Name</p>
              </div>
              <div className='col-2'>
                <p>Course</p>
              </div>
              <div className='col-1'>
                <p>Batch</p>
              </div>
              <div className='col-2'>
                <p>Fees</p>
              </div>
              <div className='col-1'>
                <p>Status</p>
              </div>
            </div>
            <div style={{ overflowY: "scroll", height: "60vh" }}>
              {
              filterStudents.length===0 ?<><div className='mt-5' style={{justifyContent:"center", alignItem:"center", width:"100%", display:"flex",height:"100%", color:"red"}}><h6>No Data Available</h6></div></>:
              filterStudents?.map((res, index) => {
                return (
                  <>

                    <div className='row align-items-center  mt-3' style={{ width: "100%", borderRadius: "5px", height: "55px", backgroundColor: " #FFFFFF" }} >
                      <div className='col-1' key={res.id} >
                        <p style={{ color: "#434343", fontWeight: "400", fontSize: "15px" }}>{calculateSerialNumber(index)}</p>
                      </div>
                      <div className='col-3'>
                        <p style={{ fontWeight: "400", fontSize: "15px", textTransform: "capitalize" }}>{res.name}</p>
                      </div>
                      <div className='col-2'>
                        <p style={{ fontSize: "15px", color: "#3C3C3C", fontWeight: "500" }} >{res.course_name}</p>
                      </div>
                      <div className='col-1'>
                        <p style={{ fontSize: "15px", color: "#434343", fontWeight: "400" }} >{res.batch_name}</p>
                      </div>

                      {/* <div className='col-2'>
                  <p style={{fontSize:"14px",color:"#434343",fontWeight:"400"}} >{res.transaction}</p>
                  </div> */}


                      {/* <div className='col-2'>
                  <p style={{fontSize:"14px",color:"#434343",fontWeight:"400"}} >{res.payment}</p>
                  </div> */}
                      <div className='col-2'>
                        <p style={{ fontSize: "15px", color: "#434343", fontWeight: "400" }} >{res.finalAmount}</p>
                      </div>
                      <div className='col-1'>
                        <p style={{ fontSize: "15px", fontWeight: "600", color: "#f93333"  }} >Dropped</p>
                      </div>


                      <div className='col-2'>
                        <NavLink to={`/Profilestudents/${res.id}`} style={{ textDecoration: "none", fontSize: "15px", color: "#8147E7", fontWeight: "400", backgroundColor: "#F2EAFF", padding: '8px 20px', width: "auto", borderRadius: "5px", border: "none" }} ><AiOutlineEye className='me-2' style={{ fontSize: "20px" }} />View Detail</NavLink>
                      </div>

                    </div>

                  </>
                )
              })}

             {filterStudents.length===0?<></>: 
             <div className='col-lg-12 col-md-12 col-sm-12 text-center my-3 ps-5 ms-5 fixed-bottom'  >
                <button className='py-1 px-3 btn' style={{ backgroundColor: "#8147E7", borderRadius: "5px", color: "#ffffff", fontWeight: "400", fontSize: "18px" }} disabled={currentPage === 1} onClick={handlePreviousPage}>
                  <span className=''>Back</span>
                </button>
                <span className='mx-2'>{currentPage}</span> / <span className='mx-2'>{totalPages}</span>
                <button className='py-1 px-3 btn' style={{ backgroundColor: "#8147E7", borderRadius: "5px", color: "#ffffff", fontWeight: "400", fontSize: "18px" }} disabled={currentPage === totalPages} onClick={handleNextPage}>
                  <span className='' >Next</span>
                </button>

              </div>}
            </div>
          </div>
        </div>
      </div>



    </>
  )
}