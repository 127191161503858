import React, { useState, useEffect, useContext } from 'react';
import Header from './header';
import SideBar from './sidebar';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { BsArrowLeft } from 'react-icons/bs';
import './report.css'
import { API_URL } from '../env';
import { ToastContainer, toast } from 'react-toastify';
import { Loader } from './Loader';


export default function Addbutton() {
const {id} = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const { apiToken } = useContext(AuthContext);
  const [role, setRole] = useState([]);
  const [Name, setName] = useState('');
  const [msg, setmsg] = useState();
  const [state, setState] = useState(0);
  const [Rname, setRname] = useState('');
  const [value, setvalue] = useState(' ')
  const [data, setData] = useState([]);
  const [click, setClick] = useState(0);
  const [add_id, setadd_id] = useState();
  const [deletid, setdeletid] = useState();
  const [Available, setAvailable] = useState([]);
  const [Granted, setGranted] = useState([]);   

  const Available_access = async()=>{
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);


var raw = JSON.stringify({
  "department_id":id
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${API_URL}/admin/department/view/manage/access`, requestOptions)
  .then(response => response.json())
  .then(result =>{ console.log(result)
    if(result.status == 1){
      setAvailable(result.access_control)
      setGranted(result.available_control)
    }
  })
  .catch(error => console.log('error', error));
  }

const Add_button =(a_id)=>{
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

var raw = JSON.stringify({
  "department_id": id,
  "add_id": a_id
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${API_URL}/admin/department/add/accessitem/indepartment`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)
   if(result.status == 1){
    toast(result.msg)
    Available_access()
   }
 })
  .catch(error => console.log('error', error));
}


const Deleta_api = async(d_id)=>{
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);


var raw = JSON.stringify({
  "department_id": id,
  "delete_id": d_id
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${API_URL}/admin/department/delete/accessitem/indepartment`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)
    if(result.status == 1){
        toast(result.msg)
        Available_access()
    
       }
})
  .catch(error => console.log('error', error));
}


  useEffect(()=>{
    Available_access()
  },[])


  return (
    <>
      <ToastContainer />
      <div style={{ width: '100%', height: '100vh', }}>
      
        <div className='head' style={{ width: '80%', height: '14vh', marginLeft: '16%' }}>
          <Header />
        </div>
        <div style={{ width: '100%', height: '86vh', display: "flex", }}>
          <div style={{ height: '100%', position: 'absolute', top: 0, marginLeft: '1%' }} >
            <SideBar />
          </div>
          <div className='maincontain' style={{ marginLeft: '15%', width: '100%', height: '100%', overflowY: 'scroll', }} >
            {/* department */}
            <div style={{ display: click === 0 ? "block" : "none" }}>
              <div className="row " >
                <div className="col-9 mt-3" >
                  <p className='demo' style={{ marginLeft: "46px", fontSize: "20px" }} >


                    <NavLink to="/Department" className="dept" >
                      <BsArrowLeft className='ps-1 mx-2' style={{ color: '#000000', fontSize: "25px", cursor: "pointer" }} />
                    </NavLink>Showing department</p>

                </div>

                {/* <div className="col-3 mt-3">
                  <button data-bs-toggle="modal" data-bs-target="#exampleModal"
                    style={{
                      backgroundColor: "#E8DFFA", padding: '5px 20px',
                      border: "2px solid #8147E7", borderRadius: "5px", color: "#8147E7"
                    }}>+ Add Department</button>
                </div> */}
              </div>


 <div className='container mt-5'>
<div className='row gap-2'>
<div className='col-lg-5 col-md-5 col-sm-12 mx-auto' style={{
  borderRadius: '4px',
  background: '#fff',
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
}}
>

<h5 style={{textAlign:'center',borderBottom:'1px solid #e8e6e6',padding:'10px'}}>Granted Access</h5>
 <div style={{height:"50vh",overflowY:"auto",}}>
{Available.map((res)=>{
  return( 
<div className=' d-flex justify-content-between align-items-center p-2 mx-1 mb-1' style={{borderBottom:'1px solid #e8e6e6',}}>
<div className='' >
  <p>{res.name}</p>
</div>
<div className=''>
<button
  onClick={() => {
    Deleta_api(res.id)
  }}
  className='btn btn-danger'
  style={{
    // background: 'red',
    color: 'white',
    // padding: '5px 20px 5px 20px',
    border: 'none',
    borderRadius: '10px',
  }}
>
  Delete
</button>

</div>
</div>
 )
})
}
</div>

</div>
<div className='col-lg-5 col-md-5 col-sm-12 mx-auto' style={{ borderRadius: '4px',
  background: '#fff',
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',}}>
<div style={{height:"40vh",overflowY:"auto"}}>
<h5 style={{textAlign:'center',borderBottom:'1px solid #e8e6e6',padding:'10px'}}>Available Access</h5>

{
Granted.map((res)=>{
  return(
    <>
    <div className=' d-flex justify-content-between align-items-center p-2 mx-1 mb-1' style={{borderBottom:'1px solid #e8e6e6'}}>
<div className=''>
  <p>{res.name}</p>
</div>
<div className=''>
<button className='btn btn-primary'  onClick={() => {
   Add_button(res.id)
  }} style={{color:'white',border:'none',borderRadius:'10px',}}>Add</button>
</div>
</div>
    </>
  )
})
}

</div>
</div>
</div>
</div>


            </div>
        </div>
      </div>
      </div>
    </>
  );
}
