import React, { useState, useEffect } from 'react';
import LeavesManagement from './LeavesManagement';
import LeaveHistory from './LeaveHistory';
import LeaveAnalytics from './LeaveAnalytics';
import Defaulterstudents from './defaulterstudents'
import { NavLink } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
export default function Invoiesa() {
  const [value20, setvalue20] = useState();

  useEffect(() => {
    setvalue20('LeavesManagement')
  }, [])
  return (
    <>
      <div className="row ">
        <div className="col-2 bg-white" style={{ backgroundColor: "#fff", height: "100vh", }}>
          <p className='ms-4  ' style={{ fontWeight: "600", color: "#3C3C3C", fontSize: "25px", marginTop: "40px" }}>  <NavLink to="/Team" className="" style={{ zIndex: "999", }}>
            <BsArrowLeft className='ps-1' style={{ color: '#000000', fontSize: "25px", cursor: "pointer" }} />
          </NavLink> Leaves</p>
          <span className='mt-4' onClick={() => { setvalue20('LeavesManagement') }}
            style={{ display: "flex", backgroundColor: value20 == "LeavesManagement" ? "#F4F2F9" : "", border: "none", marginLeft: "30px", height: "45px", borderRadius: "5px", color: "#8A8A8A" }}>
            <p className='pt-2' style={{ zIndex: '999', paddingLeft: "15px", color: value20 === "LeavesManagement" ? "#8147E7" : "", fontWeight: "400", cursor: "pointer" }}>Leaves Management</p></span>

          <span className='mt-3' onClick={() => { setvalue20('LeaveHistory') }}
            style={{ display: "flex", backgroundColor: value20 == "LeaveHistory" ? "#F4F2F9" : "", marginLeft: "30px", height: "45px", borderRadius: "5px", color: "#8A8A8A" }}>
            <p className='pt-2' style={{ zIndex: '999', paddingLeft: "15px", color: value20 === "LeaveHistory" ? "#8147E7" : "", fontWeight: "400", cursor: "pointer" }}>Leave History</p></span>

          <span className='mt-3' onClick={() => { setvalue20('LeaveAnalytics') }}
            style={{ display: "flex", backgroundColor: value20 == "LeaveAnalytics" ? "#F4F2F9" : "", marginLeft: "30px", height: "45px", borderRadius: "5px", color: " #8A8A8A" }}>
            <p className='pt-2' style={{ zIndex: '999', paddingLeft: "15px", color: value20 === "LeaveAnalytics" ? "#8147E7" : "", fontWeight: "400", cursor: "pointer" }}>Leave Analytics</p></span>

          {/* <span className='mt-3' onClick={()=>{setvalue20('Defaulterstudents')}} 
       style={{ display:"flex",backgroundColor:value20=="Defaulterstudents"?"#F4F2F9":"",marginLeft:"30px",height:"45px",borderRadius:"5px",color:" #8A8A8A"}}> 
           <p className='pt-2' style={{zIndex:'999',paddingLeft:"30px",color:value20==="Defaulterstudents"?"#8147E7":"",fontWeight:"400"}}>Defaulter</p></span>  */}
        </div>

        <div className="col-10" style={{ background: "rgb(240 245 254)" }}>
          {
            value20 === 'LeavesManagement' ? <><LeavesManagement /></> : <></>
          }
          {
            value20 === 'LeaveHistory' ? <><LeaveHistory /></> : <></>
          }
          {
            value20 === 'LeaveAnalytics' ? <><LeaveAnalytics /></> : <></>
          }
        </div>
      </div>




    </>
  )
}