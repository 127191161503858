import React, { useState, useEffect } from 'react';//api done
import { AiOutlineEye } from 'react-icons/ai';
import DatePicker from "react-datepicker";
import { BiCalendar } from 'react-icons/bi';
import { BsFillCalendarFill } from "react-icons/bs";
import TIPG from '../images/tipsglogo.png';
import "react-datepicker/dist/react-datepicker.css"
import { FiDownload } from "react-icons/fi"
import { RiArrowDropDownLine } from 'react-icons/ri';
import { RiFileExcel2Line } from 'react-icons/ri';
import { AuthContext } from './AuthContext';
import { useContext } from 'react';
// import { API_URL } from './MyComponent';



import { API_URL,img_url } from '../env';
export default function Allinvoce() {
  const [startDate, setStartDate] = useState(new Date())
  const [startDate1, setStartDate1] = useState(new Date())
  const { apiToken } = useContext(AuthContext);

  const [defaultstud, setdefaultstud] = useState([]);
  const [liststud, setlistStud] = useState()

  const getstudent = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/student/list_students`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === "001") {
          setlistStud(result.list_students)
          const { list_students } = result;

          const reqstu = list_students.filter((el) => (el.status == 1));
          setdefaultstud([...reqstu]);
          console.log("default", reqstu);
        } else {
          console.log(result.error);
        }
      })
      .catch(error => console.log('error', error));
  }



  useEffect(() => {
    getstudent();
  }, [])


  return (
    <>
      <div className="card mt-4 ms-4" style={{ width: "95%", border: "none" }}>
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <p style={{ fontWeight: "400", color: "#8A8A8A", fontSize: "18px" }}>Date Range</p>
              <div className="row mt-1">
                <div className="col-6">
                  <p style={{ color: "#8A8A8A", fontSize: "400" }}>From</p>
                  <div>

                    <span style={{
                      display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "0px",
                      borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
                    }}>
                      <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
                      <div>
                        <DatePicker className="border-0 ps-0 ps-2  datep"
                      
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                      </div>
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <p style={{ color: "#8A8A8A", fontSize: "400" }}>To</p>
                  <div>

                    <span style={{
                      display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "0px",
                      borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
                    }}>
                      <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
                      <div>
                        <DatePicker className="border-0 ps-0 ps-2  datep"
                       
                          selected={startDate1}
                          onChange={(date) => setStartDate1(date)}
                          style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                      </div>
                    </span>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-3 mt-3">

            </div>
            <div className="col-3">
              <p style={{ fontSize: "18px", fontWeight: "400", color: "#8A8A8A" }}>Total Students</p>
              <p style={{ fontWeight: "500", fontSize: "20px", color: "#3C3C3C" }}>0</p>
            </div>
          </div>
        </div>
      </div>

      {/* Invoice Details */}

      <div className="mt-5">
        <div className='d-flex'>
          <p className="" style={{ color: "#3C3C3C", fontSize: '22px', fontWeight: "600", paddingLeft: "30px" }}>Default Student Details</p>
          <button className=' ' style={{
            backgroundColor: "#8147E7", borderRadius: "5px", width: "180px", height: "50px", border: "none", marginLeft: "48.6rem",
            color: "#ffffff", fontWeight: "400", fontSize: "20px"
          }}><RiFileExcel2Line style={{ fontSize: "20px" }} /> &nbsp;&nbsp;Export Data</button>
        </div>
        <div className='mt-3 '>
          {/* <div className='row mx-4 mt-3'  >
            <div className='row' style={{ color: "#434343", fontWeight: "600", fontSize: "15px" }}>
              <div className='col-1'>
                <p>ID</p>
              </div>
              <div className='col-3'>
                <p>Name</p>
              </div>
              <div className='col-2'>
                <p>Course</p>
              </div>
              <div className='col-1'>
                <p>Batch</p>
              </div>
              <div className='col-2'>
                <p>Fees</p>
              </div>
              <div className='col-1'>
                <p>Status</p>
              </div>
            </div>
            <div style={{ overflowY: "scroll", height: "60vh" }}>
              {defaultstud?.map((res, key) => {
                return (
                  <>

                    <div className='row align-items-center  mt-3' style={{ width: "100%", borderRadius: "5px", height: "55px", backgroundColor: " #FFFFFF" }} >
                      <div className='col-1' key={key} >
                        <p style={{ color: "#434343", fontWeight: "400", fontSize: "15px" }}>{res.id}</p>
                      </div>
                      <div className='col-3'>
                        <p style={{ fontWeight: "400", fontSize: "15px", textTransform: "capitalize"}}>{res.name}</p>
                      </div>
                      <div className='col-2'>
                        <p style={{ fontSize: "15px", color: "#3C3C3C", fontWeight: "500" }} >{res.course_name}</p>
                      </div>
                      <div className='col-1'>
                        <p style={{ fontSize: "15px", color: "#434343", fontWeight: "400" }} >{res.batch_name}</p>
                      </div>

                      {/* <div className='col-2'>
              <p style={{fontSize:"14px",color:"#434343",fontWeight:"400"}} >{res.transaction}</p>
              </div> */}


                      {/* <div className='col-2'>
              <p style={{fontSize:"14px",color:"#434343",fontWeight:"400"}} >{res.payment}</p>
              </div> *
                      <div className='col-2'>
                        <p style={{ fontSize: "15px", color: "#434343", fontWeight: "400" }} >{res.finalAmount}</p>
                      </div>
                      <div className='col-1'>
                        <p style={{ fontSize: "15px", fontWeight: "600", color: res.status === 0 ? "#40997E" : res.status == 1 ? "#f93333" : "#e2a925", }} >{res.status}</p>
                      </div>


                      <div className='col-2'>
                        <button type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{
                          fontSize: "15px", color: "#8147E7", fontWeight: "400", backgroundColor: "#F2EAFF", padding: '8px 20px',
                          width: "auto", borderRadius: "5px", border: "none"
                        }} ><AiOutlineEye className='me-2' style={{ fontSize: "20px" }} />View Detail</button>

                      </div>

                    </div>

                  </>
                )
              })}
            </div>

          </div> */}


          <h4 className='text-center m-5'>Coming Soon....</h4>
        </div>
      </div>




    </>
  )
}