import React, { useState, useEffect } from 'react';
import Allexpence from './AllExpence';
import NotDebit from "./notdebitExp";
import PartialDebit from './partialdebitedExp';
import DebitExpence from './debitexpence';
import Expired from './expired';
import { NavLink } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';

export default function Expense() {
  const [value21, setvalue21] = useState();

  useEffect(() => {
    setvalue21('Allexpence')
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-2 bg-white" style={{ backgroundColor: "#fff", height: "100vh", }}>
          <p className='ms-4 ' style={{ fontWeight: "600", color: "#3C3C3C", fontSize: "25px", marginTop: "40px" }}>
            <NavLink to="/Accounts" className="" style={{ zIndex: "999", }}>
              <BsArrowLeft className='ps-1' style={{ color: '#000000', fontSize: "25px", cursor: "pointer" }} />
            </NavLink>
            Expense</p>
          <span className='mt-4' onClick={() => { setvalue21('Allexpence') }}
            style={{ display: "flex", backgroundColor: value21 == "Allexpence" ? "#F4F2F9" : "", border: "none", width: "180px", marginLeft: "30px", height: "45px", borderRadius: "5px", color: "#8A8A8A" }}>
            <p className='pt-2' style={{ zIndex: '999', paddingLeft: "80px", color: value21 === "Allexpence" ? "#8147E7" : "", fontWeight: "400", cursor: "pointer" }}>All</p></span>

          <span className='mt-3' onClick={() => { setvalue21('NotDebit') }}
            style={{ display: "flex", backgroundColor: value21 == "NotDebit" ? "#F4F2F9" : "", width: "180px", marginLeft: "30px", height: "45px", borderRadius: "5px", color: "#8A8A8A" }}>
            <p className='pt-2' style={{ zIndex: '999', paddingLeft: "50px", color: value21 === "NotDebit" ? "#8147E7" : "", fontWeight: "400", cursor: "pointer" }}>Not Debited</p></span>

          <span className='mt-3' onClick={() => { setvalue21('PartialDebit') }}
            style={{ display: "flex", backgroundColor: value21 == "PartialDebit" ? "#F4F2F9" : "", width: "180px", marginLeft: "30px", height: "45px", borderRadius: "5px", color: " #8A8A8A" }}>
            <p className='pt-2' style={{ zIndex: '999', paddingLeft: "38px", color: value21 === "PartialDebit" ? "#8147E7" : "", fontWeight: "400", cursor: "pointer" }}>Partial Debited</p></span>

          <span className='mt-3' onClick={() => { setvalue21('DebitExpence') }}
            style={{ display: "flex", backgroundColor: value21 == "DebitExpence" ? "#F4F2F9" : "", width: "180px", marginLeft: "30px", height: "45px", borderRadius: "5px", color: " #8A8A8A" }}>
            <p className='pt-2' style={{ zIndex: '999', paddingLeft: "60px", color: value21 === "DebitExpence" ? "#8147E7" : "", fontWeight: "400", cursor: "pointer" }}>Debited</p></span>

          <span className='mt-3' onClick={() => { setvalue21('Expired') }}
            style={{ display: "flex", backgroundColor: value21 == "Expired" ? "#F4F2F9" : "", width: "180px", marginLeft: "30px", height: "45px", borderRadius: "5px", color: " #8A8A8A" }}>
            <p className='pt-2' style={{ zIndex: '999', paddingLeft: "63px", color: value21 === "Expired" ? "#8147E7" : "", fontWeight: "400", cursor: "pointer" }}>Expired</p></span>
        </div>

        <div className="col-10" style={{ background: "#E9E9E9", }}>
          {
            value21 === 'Allexpence' ? <><Allexpence /></> : <></>
          }
          {
            value21 === 'NotDebit' ? <><NotDebit /></> : <></>
          }
          {
            value21 === 'PartialDebit' ? <><PartialDebit /></> : <></>
          }
          {
            value21 === 'DebitExpence' ? <><DebitExpence /></> : <></>
          }
          {
            value21 === 'Expired' ? <><Expired /></> : <></>
          }
        </div>
      </div>
    </>
  )
}