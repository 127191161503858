import React, { useState,useEffect } from 'react';
import Header from './header';
import {AiOutlineEye} from 'react-icons/ai';
import DatePicker from "react-datepicker";
import {BiCalendar} from 'react-icons/bi';
import { BsFillCalendarFill } from "react-icons/bs";
import {FiUpload} from "react-icons/fi";
import {AiOutlineCalendar} from 'react-icons/ai';
import lotti6 from '../images/142632-emoji.json';
import Lottie from "lottie-react";
import CH4 from '../images/material-symbols_calendar-view-month.png';
import CH5 from '../images/ic_outline-log-in (2).png';
import CH6 from '../images/ic_outline-log-in (3).png';
import { IoFunnelOutline } from "react-icons/io5";
import { IoNewspaperOutline } from "react-icons/io5";
import { IoEyeOutline } from "react-icons/io5";
import "./Leav.css";
import "react-datepicker/dist/react-datepicker.css"
import {FiDownload} from "react-icons/fi"
import {RiArrowDropDownLine} from 'react-icons/ri';
import {RiFileExcel2Line} from 'react-icons/ri';
import OTPInput, { ResendOTP } from "otp-input-react";
import{NavLink} from 'react-router-dom';
import { IoPersonSharp } from "react-icons/io5";
import { IoCalendarNumberOutline } from "react-icons/io5";
import {HiPhone} from 'react-icons/hi';
import helo from '../images/Rectangle 5.png';
import fb from '../images/logos_facebook.png';
import { IoSendSharp } from "react-icons/io5";
import { IoArrowBack } from "react-icons/io5";
import {HiUser} from 'react-icons/hi';
// import {HiArrowLongRight} from 'react-icons/hi2';
export default function AllLeads(){
 
    const [startDate, setStartDate] = useState(new Date());
    const [startDate1, setStartDate1] = useState(new Date());
    const [Images,setImages] = useState(); 
    const [Banner, setBanner] = useState("");
    const [value, setvalue] = useState('');
    const [count1, setcount1] = useState(1)
    const [reason, setreason] = useState();
    const [OTP, setOTP] = useState("");
    const [reassignState, setReassignState] = useState(2)
    console.log(reason);

   







    const cre=[
      {
          SNo:'1.',
          Name:"Sonali Beniwal",
          number:"+91 89876 89876",
          date:"12 April 2023",
          source:require("../images/Group 13502.png"),
          attendance:"NEET-English Medium",
          demo:"In Conversation",
        status:1,
        

      },
      {
        SNo:'1.',
        Name:"Sonali Beniwal",
        number:"+91 89876 89876",
        date:"12 April 2023",
        source:require("../images/Group 13502.png"),
        attendance:"NEET-English Medium",
        demo:"In Conversation",
        status:1,
     
      },
      {
        SNo:'1.',
        Name:"Sonali Beniwal",
        number:"+91 89876 89876",
        date:"12 April 2023",
        source:require("../images/Group 13502.png"),
        attendance:"NEET-English Medium",
        demo:"In Conversation",
        status:1,
      
      },
      {
        SNo:'1.',
        Name:"Sonali Beniwal",
        number:"+91 89876 89876",
        date:"12 April 2023",
        source:require("../images/Group 13502.png"),
        attendance:"NEET-English Medium",
        demo:"In Conversation",
        status:1,

    },
    {
      SNo:'1.',
      Name:"Sonali Beniwal",
      number:"+91 89876 89876",
      date:"12 April 2023",
      source:require("../images/Group 13502.png"),
      attendance:"NEET-English Medium",
      demo:"In Conversation",
      status:1,
   
    },
    {
      SNo:'1.',
      Name:"Sonali Beniwal",
      number:"+91 89876 89876",
      date:"12 April 2023",
      source:require("../images/Group 13502.png"),
      attendance:"NEET-English Medium",
      demo:"In Conversation",
      status:1,
    
    },
    {
      SNo:'1.',
      Name:"Sonali Beniwal",
      number:"+91 89876 89876",
      date:"12 April 2023",
      source:require("../images/Group 13502.png"),
      attendance:"NEET-English Medium",
      demo:"In Conversation",
        status:1,
    

  },
  {
    SNo:'1.',
    Name:"Sonali Beniwal",
    number:"+91 89876 89876",
    date:"12 April 2023",
    source:require("../images/Group 13502.png"),
    attendance:"NEET-English Medium",
    demo:"In Conversation",
    status:1,
  },

  {
    SNo:'1.',
    Name:"Sonali Beniwal",
    number:"+91 89876 89876",
    date:"12 April 2023",
    source:require("../images/Group 13502.png"),
    attendance:"NEET-English Medium",
    demo:"In Conversation",
        status:1,
  },
     

 ]

   const dd =[
    {
sn:"1.",
part:"Admission Fees",
amount:"Admission Fees"
    },
    {
      sn:"1.",
      part:"Admission Fees",
      amount:"Admission Fees"
          }
   ]
  
   
   useEffect(() => {
     setvalue('Accounts')
 }, []);
    
    return(
        <>
        <div className='head' style={{width:'100%', height:'14vh',marginLeft:'0%'}}>
    <Header />
    </div>
        
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasRightLabel" style={{width:"50%",}}>
  <div class="offcanvas-header">
   
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    <h5 id="offcanvasRightLabel" style={{fontStyle:"normal",fontWeight:"600",fontSize:"25px",color:"#434343",}}>Lead Details</h5>
    <h5 id="offcanvasRightLabel"><button onClick={()=>{setReassignState(2)}} type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" class="btn" style={{background:"#EADDFF",borderRadius:"5px",border:"none",color:"#8147E7",padding:"10px 15px",}}>Re-Assign Lead</button></h5>
    <h5 id="offcanvasRightLabel"><button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={()=>{setReassignState(4)}} class="btn " style={{background:"#CC1313",borderRadius:"5px", border:"none",color:"#fff",padding:"10px 30px",}}>Drop Lead</button></h5>
    <h5 id="offcanvasRightLabel"><button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={()=>{setReassignState(5)}} class="btn" style={{background:"#8147E7",borderRadius:"5px",border:"none",color:"#FFFFFF",padding:"10px 16px",}}>Convert Lead</button></h5>

    
  
  </div>
  <div class="offcanvas-body">
    <div className=''>
      <p><span><IoPersonSharp style={{fontSize:"1.5rem",}}/></span> Shivangi Malhotra</p>
      <p className='mt-3'><span><IoCalendarNumberOutline style={{fontSize:"1.5rem",}}/></span> 12 April 2023</p>
      <p className='mt-3'> Source <span style={{color:"#1877F2",}}>Facebook <img   src={fb} style={{width:"3%",}} /></span></p>
      <p className='mt-3'>Course</p>
      <p className='mt-2' style={{fontStyle:"normal",fontWeight:"600",fontSize:"16px",color:"#3C3C3C",}}>NEET-English Medium</p>

      <p className='mt-3'>Guardian’s Name</p>
      <p className='mt-2' style={{fontStyle:"normal",fontWeight:"600",fontSize:"16px",color:"#3C3C3C",}}>Ishwar Singh Malhotra</p>
      <div className='row mt-4'>
        <div className='col-4'>
        <p style={{fontStyle:"normal",fontWeight:"600",fontSize:"16px",color:"#3C3C3C",}}>Contact Number</p>
          <p className='mt-4' style={{border:"1px solid #808080",padding:"10px",borderRadius:"5px",marginTop:"10px !important",}}><HiPhone/> +91 78998 78998</p>
        </div>
        <div className='col-4'>
        <p style={{fontStyle:"normal",fontWeight:"600",fontSize:"16px",color:"#3C3C3C",}}>Alternate Contact Number</p>
        <p className='mt-4' style={{border:"1px solid #808080",padding:"10px",borderRadius:"5px",marginTop:"10px !important",}}><HiPhone/> +91 78998 78998</p>
        </div>
      </div>
      <p className='mt-3' style={{fontStyle:"normal",fontWeight:"400",fontSize:"16px",color:"#434343",}}>Assigned to</p>
      <div className='mt-4 d-flex'>
        <img src={helo} style={{width:"5%",}}/>
        <p className='mt-1' style={{fontStyle:"normal",fontWeight:"600",fontSize:"16px",color:"#3C3C3C",marginLeft:"10px",}}>Manish Jangir</p>
      </div>

      <p className='mt-3' style={{fontStyle:"normal",fontWeight:"500",fontSize:"20px",color:"#434343",}}>Remarks History</p>
      <div className='card' style={{background:"#F0F5FE",borderRadius:"5px",border:"none",}}>
       <div style={{overflowY:"scroll",height:"40vh",}}>
      <p className='mt-3 mx-3' style={{background:"#1877F2",borderRadius:"5px",width:"30%",padding:"10px", color:"#fff",}}> Call was not picked up</p>
      <p className='mx-3 mt-1' style={{fontStyle:"normal",fontWeight:"400",fontSize:"14px",color: "#8A8A8A",}}>12:30 | 30 April 2023</p>
      <p className='mt-2 mx-3' style={{background:"#1877F2",borderRadius:"5px",width:"30%",padding:"10px", color:"#fff",}}> Call was not picked up</p>
      <p className='mx-3 mt-1' style={{fontStyle:"normal",fontWeight:"400",fontSize:"14px",color: "#8A8A8A",}}>12:30 | 30 April 2023</p>
      <p className='mt-2 mx-3' style={{background:"#1877F2",borderRadius:"5px",width:"30%",padding:"10px", color:"#fff",}}> Call was not picked up</p>
      <p className='mx-3 mt-1' style={{fontStyle:"normal",fontWeight:"400",fontSize:"14px",color: "#8A8A8A",}}>12:30 | 30 April 2023</p>
      <p className='mt-2 mx-3' style={{background:"#1877F2",borderRadius:"5px",width:"30%",padding:"10px", color:"#fff",}}> Call was not picked up</p>
      <p className='mx-3 mt-1' style={{fontStyle:"normal",fontWeight:"400",fontSize:"14px",color: "#8A8A8A",}}>12:30 | 30 April 2023</p>
      </div>
      {/* <input type="text"  style={{background:"#FFFFFF",borderRadius:"5px",border:"none",boxShadow:"0px 0px 2px 0px #808080",padding:"5px",}}/><IoSendSharp/> */}
       <input type="text" className="form-control" placeholder="Update Lead Remark..."/> <span style={{marginLeft:"44rem",zIndex:"99",marginTop:"-32px",fontSize:"1rem",}}><IoSendSharp /></span>
      </div>
    </div>
  </div>
</div>


{/* Invoice Details */}

<div className="mt-5">
<div className="row mt-5" style={{width:"100%",}}>
    <div className="col-8">
        <p style={{fontStyle:"normal",fontWeight:"600",fontSize:"18px",color:"#3C3C3C",marginLeft:"28px",
}}>Lead Details</p>
    </div>
    <div className="col-2 text-end">
    <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#exampleModal2" style={{width:"70%",background:"#EADDFF",borderRadius:"5px",color:"#8147E7",fontStyle:"normal",fontWeight:"400",fontSize:"14px",border:"none",padding:"8px",}}><IoFunnelOutline/> Filter</button>
    </div>
    <div className="col-2">
    <button type="button" class="btn" style={{width:"70%",background:"#8147E7",borderRadius:"5px",fontStyle:"normal",fontWeight:"400",fontSize:"14px",color:"#FFFFFF",border:"none",padding:"8px",}}><IoNewspaperOutline/> Export Data</button>
    </div>
</div>
    <div className='mt-3 '>
       <div className='row mx-4 mt-3'  >
       <div className='d-flex' style={{color:"#434343",fontWeight:"600",fontSize:"15px"}}> 
         <div className=''style={{marginLeft:"1rem",}}>
           <p style={{fontSize:"13px",}}>S.No</p>
         </div>
         <div className=''style={{marginLeft:"3rem",}}>
           <p style={{fontSize:"13px",}}>Name</p>
         </div>
         <div className=''style={{marginLeft:"5rem",}}>
           <p style={{fontSize:"13px",}}>Contact Number</p>
         </div>
         <div className=''style={{marginLeft:"5.5rem",}}>
           <p style={{fontSize:"13px",}}>Date</p>
         </div>
         <div className=''style={{marginLeft:"6rem",}}>
           <p style={{fontSize:"13px",}}>Source</p>
         </div>
         <div className=''style={{marginLeft:"7rem",}}>
         <p style={{fontSize:"13px",}}>Course</p>
         </div>
         <div className=''style={{marginLeft:"7.5rem",}}>
         <p style={{fontSize:"13px",}}>Status</p>
         </div>
         <div className=''style={{marginLeft:"7rem",}}>
         <p style={{fontSize:"13px",}}></p>
         </div>
        </div>
        <div style={{overflowY:"scroll",height:"60vh"}}>
    {cre.map((res,key)=>{
        return(
            <>
            <div className='d-flex align-items-center  mt-3' style={{width:"100%",borderRadius:"5px" ,height:"55px",backgroundColor:" #FFFFFF"}} >
              <div className='' style={{marginLeft:"2rem",}} key={key} >
<p style={{color:"#434343",fontWeight:"400",fontSize:"10px"}}>{res.SNo}</p>
</div>
<div className='' style={{marginLeft:"3rem",}}>
<p style={{fontWeight:"400",fontSize:"10px"}}>{res.Name}</p>
</div>
<div className='' style={{marginLeft:"5rem",}}>
<p style={{fontSize:"10px",color:"#3C3C3C",fontWeight:"500"}} >{res.number}</p>
</div>
<div className='' style={{marginLeft:"3rem",}}>
<p style={{fontSize:"10px",color:"#434343",fontWeight:"400"}} >{res.date}</p>
</div>
<div className='' style={{marginLeft:"5rem",}}>

<img src={res.source} style={{width:"65px"}}/>
</div>
<div className='' style={{marginLeft:"4rem",}}>
<p style={{fontSize:"10px",color:"#434343",fontWeight:"400"}} >{res.attendance}</p>
</div>
<div className='' style={{marginLeft:"6rem",}}>
{/* <p style={{fontSize:"10px",color:"#434343",fontWeight:"400"}} >{res.attendance}</p> */}
<p style={{fontSize:"10px",color:"#434343",fontWeight:"400" , color:res.status===0?"#40997E":res.status==1?"#f93333":"#e2a925", }} >{res.demo}</p>
</div>

<div className='' style={{marginLeft:"7rem",}}>
<p data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample" style={{height:"30px",fontSize:"15px",color:"#8147E7",fontWeight:"400",
width:"130px",borderRadius:"5px",border:"none",}} ><AiOutlineEye style={{fontSize:"20px"}}/>&nbsp;&nbsp;{res.view}</p>
</div>

  </div>


            </> 
        )
    })}
    </div>
    
</div>
  </div>
</div>














{/* InConversation modal started  */}
<div class="modal fade" id="exampleModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ background: "#F0F5FE", }}>
            <div class="modal-dialog" >
              <div className='' style={{ marginTop: "6rem", }}>
                <p style={{ color: "#2188E7", fontSize: "20px", }}><IoArrowBack />Back</p>
              </div>





              <div style={{ display: reassignState === 2 ? "block" : "none" }}>
                <div class="modal-content" style={{ border: "none", }}>
                  <div class="modal-header" style={{ border: "none", }}>
                    <h5 class="modal-title" id="exampleModalLabel">Re-assign Lead</h5>

                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <form>



                      <label className='mt-2'>Select Department</label>
                      <select name="selectList" id="selectList" style={{ padding: "10px", border: "1px solid #E6E6E6", borderRadius: "5px", width: "100%", color: "#989898", }}>
                        <option value="Accounts">Accounts</option>
                        <option value="Accounts">Accounts</option>
                      </select>
                      <label className='mt-4'>Select Role</label>
                      <select name="selectList" id="selectList" style={{ padding: "10px", width: "100%", border: "1px solid #E6E6E6", borderRadius: "5px", color: "#989898", }}>
                        <option value="Senior Human Resource Manager">Senior Human Resource Manager</option>
                        <option value="Senior Human Resource Manager">Senior Human Resource Manager</option>
                      </select>


                      <label className='mt-4'>Select Employee</label>
                      <select name="selectList" id="selectList" style={{ padding: "10px", width: "100%", border: "1px solid #E6E6E6", borderRadius: "5px", color: "#989898", }}>
                        <option value="Anil Kumble">Anil Kumble</option>
                        <option value="Anil Kumble">Anil Kumble</option>
                      </select>

                      <button type="button" class="btn btn-primary mt-4" style={{ width: "100%", padding: "10px", background: "#8147E7", borderRadius: "5px", }} onClick={() => { setReassignState(reassignState + 1) }}>Assign Inquiry</button>
                    </form>
                  </div>

                </div>
              </div>



              <div style={{ display: reassignState === 3 ? "block" : "none" }}>
                <div class="modal-content" style={{ border: "none", }}>

                  <div class="modal-body">

                    <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "1.5rem", display: "flex", alignItems: "center",justifyContent:"center", textAlign: "center", color: "#3C3C3C", }}>Lead Re-Assigned Successfully to</p>
                    <p className='text-center mt-2' style={{color:"#434343",fontFamily:"Inter",fontStyle:"normal",fontWeight:500,fontSize:"1rem"}} ><HiUser/> Shivangi Malhotra</p>


                    <div className='d-flex mt-5 mb-3' style={{ marginLeft: "8rem", }}>
                      <img src={helo} style={{ width: "16%", height: "4rem" }} />
                      <p className='mt-2' style={{ marginLeft: "15px", fontStyle: "normal", fontWeight: "400", fontSize: "30px", color: "#434343", }}>Anil Kumble</p>
                    </div>


                    <button type="button" class="btn btn-primary mt-4" data-bs-dismiss="modal" aria-label="Close" style={{ width: "100%", padding: "10px", background: "#8147E7", borderRadius: "5px", }}>Back to Leads</button>

                  </div>

                </div>
              </div>

              <div style={{display:reassignState === 4 ? "block" : "none"}} >
              <div class="modal-content" style={{ border: "none", }}>

<div class="modal-body">

  <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "1.5rem", display: "flex", alignItems: "center",justifyContent:"center", textAlign: "center", color: "#3C3C3C", }}>Lead Status Updated Successfully</p>
  <p className='text-center mt-2' style={{color:"#434343",fontFamily:"Inter",fontStyle:"normal",fontWeight:500,fontSize:"1rem"}} ><HiUser/> Shivangi Malhotra</p>
 

  <div className='d-flex align-items-center justify-content-center gap-3 mb-3 mt-2' style={{fontFamily:"Inter",fontStyle:"normal",fontWeight:500,fontSize:"0.875rem"}} >
    <span className='p-1' style={{background:"#FFF4EC",color:"#DC9A00",borderRadius:"5px"}} >In Conservation</span>
    {/* <HiArrowLongRight/> */}
    <span className='p-1' style={{background:"#ECFFEE",color:"#40997E",borderRadius:"5px"}} >Dropped</span>
  </div>


  <button type="button" class="btn btn-primary mt-4" data-bs-dismiss="modal" aria-label="Close" style={{ width: "100%", padding: "10px", background: "#8147E7", borderRadius: "5px", }}>Back to Leads</button>

</div>

</div>
              </div>  

              <div style={{display:reassignState === 5 ? "block" : "none"}} >
              <div class="modal-content" style={{ border: "none", }}>

<div class="modal-body">

  <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "1.5rem", display: "flex", alignItems: "center",justifyContent:"center", textAlign: "center", color: "#3C3C3C", }}>Lead Status Updated Successfully</p>
  <p className='text-center mt-2' style={{color:"#434343",fontFamily:"Inter",fontStyle:"normal",fontWeight:500,fontSize:"1rem"}} ><HiUser/> Shivangi Malhotra</p>
 

  <div className='d-flex align-items-center justify-content-center gap-3 mb-3 mt-2' style={{fontFamily:"Inter",fontStyle:"normal",fontWeight:500,fontSize:"0.875rem"}} >
    <span className='p-1' style={{background:"#FFF4EC",color:"#DC9A00",borderRadius:"5px"}} >In Conservation</span>
    {/* <HiArrowLongRight/> */}
    <span className='p-1' style={{background:"#ECFFEE",color:"#40997E",borderRadius:"5px"}} >Dropped</span>
  </div>


  <button type="button" class="btn btn-primary mt-4" data-bs-dismiss="modal" aria-label="Close" style={{ width: "100%", padding: "10px", background: "#8147E7", borderRadius: "5px", }}>Back to Leads</button>

</div>

</div>
              </div>

            </div>
</div>
{/* InConversation modal end  */}


        </>
    )
}