//export const API_URL = "https://adapitips.intellix360.in/api";
export const img_url = "https://adapitips.intellix360.in/";
//  export const API_URL = "https://demo.intellix360.in";
 export const API_URL = localStorage.getItem("api_url");
// export const API_URL = "https://lms.tipsg.in/api"
// export const img_url = "https://lms.tipsg.in/";
// export const API_URL = "http://localhost/intellix/api"
// export const img_url = "http://localhost/intellix/";


// further comments