import React from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const Marksheet = () => {

  const Navigate = useNavigate();

  const cardDetails = [
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: "1200",
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: 1200,
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row p-0">
          <div className="col-12 p-0">
            <div
              className="d-flex align-items-center justify-content-between px-2 pt-0 mt-0"
              style={{
                height: "9vh",
                width: "100%",
                background: "#fff",
                boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
              }}
            >
              <div className="d-flex align-items-center ms-2">
                <IoIosArrowRoundBack
                onClick={()=>Navigate('/Accounts2')}
                  style={{
                    fontSize: "2rem",
                    color: "#3C3C3C",
                    cursor: "pointer",
                  }}
                />
                <span
                  className="ms-3"
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 600,
                    color: "#3D3D3D",
                    fontSize: "1.25rem",
                  }}
                >
                  Cumulative Marksheet Generator and Manager
                </span>
              </div>

              <div>
                <button
                  onClick={()=>{Navigate('/CreateNewMarksheet')}}
                  className="px-3 py-2 text-white border-0"
                  style={{
                    background: "#8147E7",
                    boxShadow: "0px 4px 12px 0px rgba(126, 57, 246, 0.36)",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "1rem",
                    borderRadius: "5px",
                  }}
                >
                  +Create New Marksheet
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div style={{ height: "91vh",overflowY:"auto", width: "100%",}}>
            <div className="container-fluid" >

              <div className="row mt-3" style={{rowGap:"10px"}} >
                {
                  cardDetails.map((value,index)=>(
                    <div className="col-3" key={index} >
                    <div className="card p-2 px-3 h-100" >
                      <h6 style={{fontFamily:"Inter",fontStyle:"normal",fontSize:"1rem",fontWeight:600,color:"#3D3D3D"}}>{value.heading}</h6>
                      <div className="" >
                        <p style={{fontFamily:"Inter",fontStyle:"normal",fontSize:"0.875rem",fontWeight:400,color:"#767676"}}>Number of Exams : {value.noOfExams}</p>
                        <p style={{fontFamily:"Inter",fontStyle:"normal",fontSize:"0.875rem",fontWeight:400,color:"#767676"}}>Number of Batches : {value.noOfBatches}</p>
                        <p style={{fontFamily:"Inter",fontStyle:"normal",fontSize:"0.875rem",fontWeight:400,color:"#767676"}}>Number of Students : {value.noOfStud}</p>
                      </div>
                      <div className="mx-auto py-2 mt-3 w-100" >
                        <button className="w-100 py-2 border-0" style={{background:"#F2EDFD",borderRadius:"3px",color:"#8147E7",fontSize:"1rem",fontFamily:"Inter",fontWeight:400}} >View Details</button>
                      </div>
                    </div>
                  </div>
                  ))
                }
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Marksheet;
