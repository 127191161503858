import React from 'react';
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { IoPersonOutline } from "react-icons/io5";
import { IoArrowForwardSharp } from "react-icons/io5";
import { IoEyeSharp } from "react-icons/io5";
import { IoCloudUploadOutline } from "react-icons/io5";
export default function Salarytopbar() {
    return (
        <>

            <div className='container mt-4 '>
                <div className='row'>
                    <div className='col-4'>
                        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "24px", color: "#434343", }}>CTC</p>
                        <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "20px", color: "#717171", }}>₹ 15,00,000 per annum</p>
                        <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "500", fontSize: "24px", color: "#434343", }}>CTC Break Down</p>
                        <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}><span style={{ fontSize: "13px", }}>In Hand Salary</span><br /> ₹ 90,000 per month</p>
                        <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}><span style={{ fontSize: "13px", }}> PF</span><br /> ₹ 9,000 per month</p>
                        <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}><span style={{ fontSize: "13px", }}> Loyalty Bonus</span><br /> ₹ 9,000 per month</p>
                        <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}><span style={{ fontSize: "13px", }}>HRA </span><br /> ₹ 5,000 per month</p>
                    </div>
                    <div className='col-8'>
                        <div className='card'>

                            <p className='ps-4 mt-2' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "20px", color: "#434343", }}>Salary History</p>
                            <div className='ps-4 mt-3'>
                                <div className='row'>
                                    <div className='col-2'>
                                        <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "13px", color: "#878787", }}>Amount</p>
                                    </div>
                                    <div className='col-2'>
                                        <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "13px", color: "#878787", }}>Working Days</p>
                                    </div>
                                    <div className='col-2'>
                                        <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "13px", color: "#878787", }}>Present</p>
                                    </div>
                                    <div className='col-2'>
                                        <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "13px", color: "#878787", }}>Absent</p>
                                    </div>
                                    <div className='col-2'>
                                        <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "13px", color: "#878787", }}>Half Day</p>
                                    </div>
                                    <div className='col-2'>
                                        <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "13px", color: "#878787", }}>Actions</p>
                                    </div>
                                </div>
                                <div className='mt-3' style={{ overflowY: "scroll", height: "40vh", }}>
                                    <div className='ms-3'>
                                        <div className='row mb-3 py-2' style={{ background: "#FFFFFF", border: "1px solid #E1E1E1", borderRadius: "10px", width: "100%", }}>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#434343", }}>₹ 87,569</p>
                                            </div>
                                            <div className='col-1'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#434343", }}>24</p>
                                            </div>
                                            <div className='col-1'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#40997E", }}>20</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#F93333", }}>2</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#E2A925", }}>2</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#8147E7", background: "#F2EDFD", borderRadius: "5px", paddingTop: "4px", paddingBottom: "4px", textAlign: "center", }}><IoEyeSharp /> View Details</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#54536E", background: "#EDF0FD", borderRadius: "5px", paddingTop: "4px", paddingBottom: "4px", textAlign: "center", }}><IoCloudUploadOutline /> Salary Slip</p>
                                            </div>
                                        </div>
                                        <div className='row mb-3 py-2' style={{ background: "#FFFFFF", border: "1px solid #E1E1E1", borderRadius: "10px", width: "100%", }}>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#434343", }}>₹ 87,569</p>
                                            </div>
                                            <div className='col-1'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#434343", }}>24</p>
                                            </div>
                                            <div className='col-1'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#40997E", }}>20</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#F93333", }}>2</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#E2A925", }}>2</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#8147E7", background: "#F2EDFD", borderRadius: "5px", paddingTop: "4px", paddingBottom: "4px", textAlign: "center", }}><IoEyeSharp /> View Details</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#54536E", background: "#EDF0FD", borderRadius: "5px", paddingTop: "4px", paddingBottom: "4px", textAlign: "center", }}><IoCloudUploadOutline /> Salary Slip</p>
                                            </div>
                                        </div>
                                        <div className='row mb-3 py-2' style={{ background: "#FFFFFF", border: "1px solid #E1E1E1", borderRadius: "10px", width: "100%", }}>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#434343", }}>₹ 87,569</p>
                                            </div>
                                            <div className='col-1'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#434343", }}>24</p>
                                            </div>
                                            <div className='col-1'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#40997E", }}>20</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#F93333", }}>2</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#E2A925", }}>2</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#8147E7", background: "#F2EDFD", borderRadius: "5px", paddingTop: "4px", paddingBottom: "4px", textAlign: "center", }}><IoEyeSharp /> View Details</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#54536E", background: "#EDF0FD", borderRadius: "5px", paddingTop: "4px", paddingBottom: "4px", textAlign: "center", }}><IoCloudUploadOutline /> Salary Slip</p>
                                            </div>
                                        </div>
                                        <div className='row mb-3 py-2' style={{ background: "#FFFFFF", border: "1px solid #E1E1E1", borderRadius: "10px", width: "100%", }}>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#434343", }}>₹ 87,569</p>
                                            </div>
                                            <div className='col-1'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#434343", }}>24</p>
                                            </div>
                                            <div className='col-1'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#40997E", }}>20</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#F93333", }}>2</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#E2A925", }}>2</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#8147E7", background: "#F2EDFD", borderRadius: "5px", paddingTop: "4px", paddingBottom: "4px", textAlign: "center", }}><IoEyeSharp /> View Details</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#54536E", background: "#EDF0FD", borderRadius: "5px", paddingTop: "4px", paddingBottom: "4px", textAlign: "center", }}><IoCloudUploadOutline /> Salary Slip</p>
                                            </div>
                                        </div>
                                        <div className='row mb-3 py-2' style={{ background: "#FFFFFF", border: "1px solid #E1E1E1", borderRadius: "10px", width: "100%", }}>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#434343", }}>₹ 87,569</p>
                                            </div>
                                            <div className='col-1'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#434343", }}>24</p>
                                            </div>
                                            <div className='col-1'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#40997E", }}>20</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#F93333", }}>2</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#E2A925", }}>2</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#8147E7", background: "#F2EDFD", borderRadius: "5px", paddingTop: "4px", paddingBottom: "4px", textAlign: "center", }}><IoEyeSharp /> View Details</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#54536E", background: "#EDF0FD", borderRadius: "5px", paddingTop: "4px", paddingBottom: "4px", textAlign: "center", }}><IoCloudUploadOutline /> Salary Slip</p>
                                            </div>
                                        </div>
                                        <div className='row mb-3 py-2' style={{ background: "#FFFFFF", border: "1px solid #E1E1E1", borderRadius: "10px", width: "100%", }}>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#434343", }}>₹ 87,569</p>
                                            </div>
                                            <div className='col-1'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#434343", }}>24</p>
                                            </div>
                                            <div className='col-1'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#40997E", }}>20</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#F93333", }}>2</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#E2A925", }}>2</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#8147E7", background: "#F2EDFD", borderRadius: "5px", paddingTop: "4px", paddingBottom: "4px", textAlign: "center", }}><IoEyeSharp /> View Details</p>
                                            </div>
                                            <div className='col-2'>
                                                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#54536E", background: "#EDF0FD", borderRadius: "5px", paddingTop: "4px", paddingBottom: "4px", textAlign: "center", }}><IoCloudUploadOutline /> Salary Slip</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-3 py-2' style={{ background: "#FFFFFF", border: "1px solid #E1E1E1", borderRadius: "10px", width: "100%", }}>
                                        <div className='col-2'>
                                            <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#434343", }}>₹ 87,569</p>
                                        </div>
                                        <div className='col-1'>
                                            <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#434343", }}>24</p>
                                        </div>
                                        <div className='col-1'>
                                            <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#40997E", }}>20</p>
                                        </div>
                                        <div className='col-2'>
                                            <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#F93333", }}>2</p>
                                        </div>
                                        <div className='col-2'>
                                            <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#E2A925", }}>2</p>
                                        </div>
                                        <div className='col-2'>
                                            <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#8147E7", background: "#F2EDFD", borderRadius: "5px", paddingTop: "4px", paddingBottom: "4px", textAlign: "center", }}><IoEyeSharp /> View Details</p>
                                        </div>
                                        <div className='col-2'>
                                            <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#54536E", background: "#EDF0FD", borderRadius: "5px", paddingTop: "4px", paddingBottom: "4px", textAlign: "center", }}><IoCloudUploadOutline /> Salary Slip</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}



