import React,{useState,useEffect}from "react";
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { IoPersonOutline } from "react-icons/io5";
import { IoArrowForwardSharp } from "react-icons/io5";
import EE from '../images/iconu.png';
import { IoCalendarNumber } from "react-icons/io5";
import { API_URL } from "../env";
import { ToastContainer, toast } from "react-toastify";





const Solved = () => {

    const [data, setdata] = useState([])

    const Not = [
        {
            name: "Not Able to access my App",
            message: "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit.",
            button: "Solved on 12 December 2023",
            date: "12 December 2023",
        },
        {
            name: "Not Able to access my App",
            message: "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit.",
            button: "Solved on 12 December 2023",
            date: "12 December 2023",
        },
        {
            name: "Not Able to access my App",
            message: "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit.",
            button: "Solved on 12 December 2023",
            date: "12 December 2023",
        },
        {
            name: "Not Able to access my App",
            message: "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit.",
            button: "Solved on 12 December 2023",
            date: "12 December 2023",
        },


    ]

    const Solveddata =async()=>{
        try {
            
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${API_URL}/admin/complain/read/solved/complain`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)
   if(result.status == 1){
    setdata(result.complains)
   }
    
})
  .catch(error => console.log('error', error));
        } catch (error) {
            
        }
    }

    const update_data = async(id)=>{
        var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
      
      
      var raw = JSON.stringify({
        "complain_id": id
      });
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
      fetch(`${API_URL}/admin/complain/update/complain/status`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          if(result.status == 1){
            toast(result.msg);
            Solveddata()
          }
          else{
            toast(result.msg);
          }
        })
        .catch(error => console.log('error', error));
      }

    useEffect(() => {
        Solveddata()
    }, [])
    


    return (
        <>
              <ToastContainer />
              <div className='container mt-3 mx-5' style={{ overflowY: "scroll", height: "70vh" }}>

{data.length > 0 ? (
  <>
    {data.map((res, key) => (
      <div className="row mt-3" key={key}>
        <div className="col-9" style={{ background: "#E9EBF5", borderRadius: "10px" }}>
          <div>
            <p className="px-2 mt-2" style={{ fontStyle: "normal", fontWeight: "600", fontSize: "22px", color: "#1A1A1A" }}>{res.head}</p>
          </div>
          <div className="row mt-2">
            <div className="col-3">
              <div className="d-flex mt-2" style={{ borderRadius: "50px", padding: "6px 0px 6px 0px" }}>
                <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94", marginLeft: "12px" }}>{res.student_name}</p>
              </div>
            </div>
            <div className="col-3">
              <p className="mt-3" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#777C94" }}>
                <IoCalendarNumber style={{ color: "#777C94", fontSize: "20px" }} /> {res.solve_on}
              </p>
            </div>
            <div className="col-3">
              <button type="button" className="btn" style={{ background: "#40997E", borderRadius: "39px", fontStyle: "normal", fontWeight: "400", fontSize: "13px", color: "#FFFFFF", padding: "10px 11px" }}>{res.solved_by}</button>
            </div>
            <div className="col-3">
              <button type="button" onClick={() => update_data(res.id)} className="btn" style={{ background: "#CC1313", borderRadius: "39px", fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#FFFFFF", padding: "10px 25px" }}>Mark as Unsolved</button>
            </div>
          </div>
          <div className="mt-3">
            <p className="px-2 mb-3" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#777C94" }}>{res.description}</p>
          </div>
        </div>
      </div>
    ))}
  </>
) : (
  <p style={{textAlign:'center',color:'red',marginTop:'30px'}}>No data found</p>
)}
</div>;

        </>
    )
};

export default Solved;