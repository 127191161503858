
import React, { useState, useEffect, useContext } from 'react';
import IOI from '../images/iconu.png';
import image from '../images/vscode-icons_file-type-excel2.png';
import { MdOutlineBed } from 'react-icons/md';
import { BsBoxArrowInRight, BsBoxArrowInLeft } from 'react-icons/bs';
import { API_URL } from '../env';
import { AuthContext } from './AuthContext';
import batch from '../images/batch.png';
import * as XLSX from 'xlsx';
import { BiLogIn, BiLogOut } from 'react-icons/bi';
import { Loader } from './Loader';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';



export default function Out({ formattedDate }) {

  const {Navigate} = useNavigate();

  const convertToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Map the array to include only the desired properties
    const selectiveDataArray = Aa.map((Aa) => ({
      Name: Aa.name,
      Out_Time: Aa.punch_out_time,

      Batch: Aa.batch_name,


    }));

    // Convert the selective data array into a worksheet
    const worksheet = XLSX.utils.json_to_sheet(selectiveDataArray);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a file name
    const fileName = 'Out.xlsx';

    // Generate Excel file binary data
    const excelData = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Create a blob object from the binary data
    const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a download link
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;

    // Simulate a click to trigger the download
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
  };

  const { apiToken } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const alli = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-type", `application/json`);
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var raw = JSON.stringify({
      "isoDate1": formattedDate
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    setIsLoading(true);
    fetch(`${API_URL}/admin/attendence/read/halfday/students`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setIsLoading(false);
        console.log(result)
        if (result.status == "1") {
          setAll(result.data);
        } else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }
      })
      .catch(error => console.log('error', error));
  }
  const [Aa, setAll] = useState([]);

  useEffect(() => {
    alli()
  }, [])

  console.log(Aa, 'students')

  return (
    <>
    <ToastContainer/>
      <div className='mt-4  '>
        <div className='' style={{ border: "2px solid #DBDBDB", width: "70%", marginLeft: "37px", backgroundColor: "white", borderRadius: "10px", }}>
          <div className='row'>
            <div className='col-6'>
              <div style={{ display: "flex" }}>
                <h5 className='px-3 pt-4 '> Live Student Update</h5>
                <div className="livel " style={{ marginTop: "30px" }}>
                  <div className="livel_icon"></div>
                </div>

              </div>
            </div>
            <div className='col-6 text-end '>
              <button className='mt-4 mx-3' style={{ backgroundColor: "#20744A", width: "150px", border: "none", color: "white", height: "35px", borderRadius: "5px" }} onClick={() => { convertToExcel() }}><span><img src={image} style={{ width: "12%" }} /></span> Export Data</button>
            </div>
          </div>
          {
            isLoading ? <Loader show={isLoading} /> :
              <div style={{ overflowY: "scroll", height: "30vh" }}>
                {Aa.length > 0 ? Aa.map((res, key) => {
                  return (
                    <>
                      <div className='row text-center my-3 d-flex align-items-center mx-3 mt-4'
                        style={{ border: "1px solid #DBDBDB", width: "96%", backgroundColor: "white", borderRadius: "10px", height: "48px" }} >

                        <Ai res={res} key={key} />
                      </div>
                    </>
                  )
                }) : <><div className='mt-5' style={{ justifyContent: "center", alignItem: "center", width: "100%", display: "flex", height: "100%", color: "red" }}><h6>No Data Available</h6></div></>}
              </div>
          }

        </div>
      </div>
    </>
  )
}

function Ai(props) {
  return (
    <>
      <div className='col-4' style={{ display: "flex" }}>
        <div
          style={{
            // backgroundColor: "rgb(237, 255, 243)",
            // marginLeft: "20px",
            marginRight: "35px",
            // borderRadius: "50%",
            // width: "60px",
            // border: "1px solid #808080",
            // width: "16%", height: "2.5rem",
          }}
        >
          <p
            style={{
              color: "rgb(23, 165, 54)",
              display: "flex",
              justifyContent: "center",


              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
           {props.res.enrollment_id}
          </p>
        </div>
        <p className='' style={{textTransform:"capitalize"}}>{props.res.student_name}</p>
      </div>

      {/* <div className='col-2' style={{ display: "flex" }}>
      <p style={{ color: 'blue' }} ><BiLogIn className='me-1 mt-0' /><span >{props.res.in_time}</span> </p>&nbsp;&nbsp;
    </div> */}

      <div className='col-2' style={{ display: "flex" }}>
        <p style={{ color: 'blue' }} >{props.res.in_time === null ? <p>No date</p> : <><BiLogIn className='me-1 mt-0' /><span >{props.res.in_time}</span></>} </p>&nbsp;&nbsp;
      </div>

      <div className='col-2' style={{ display: "flex" }}>
        <p style={{ color: 'red' }}>{props.res.out_time === null ? <p>No date</p> : <><BiLogOut className='me-1 mt-0' /><span >{props.res.out_time}</span></>} </p>&nbsp;&nbsp;
      </div>

      <div className='col-4' style={{ display: "flex" }}>
        <img src={batch} style={{ width: "8%", }} />&nbsp;&nbsp;
        <p  >{props.res.batch_name}</p>
      </div>

    </>
  );
}