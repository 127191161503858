import React, { useState, useEffect } from 'react';
import Header from './header';
import SideBar from './sidebar';
import Report from './report';
import Transactions from './transactions';
import Credit from './credit';
import Debit from './debit';
import Upcoming from './upcoming';
import Due from './due';
import { NavLink,useNavigate} from 'react-router-dom';
import { useRequireAuth } from './RequireAuth';
import BannerData from "./BannerData"
import { FiUpload } from "react-icons/fi";


export default function BannerImage() {

  //useRequireAuth('/code');
 

  const [value55, setvalue55] = useState();

  useEffect(() => {
    setvalue55('Report')
  }, [])


  return (
    <div style={{ width: '100%', height: '100vh', }}>
      <div className='head' style={{ width: '80%', height: '14vh', marginLeft: '16%' }}>
        <Header />
      </div>
      <div className='' style={{ width: '100%', height: '86vh', display: "flex" }}>
        <div style={{ width: '20%', height: '100%', position: 'absolute', top: 0, marginLeft: '1%' }} >
          <SideBar />
        </div>
        <div className='maincontain' style={{ marginLeft: '16%', width: '100%', height: '100%', overflowY: 'scroll', }} >
         <BannerData/>
        </div>
      </div>
    </div>
  )
}

