import React from 'react';
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { IoPersonOutline } from "react-icons/io5";
import { IoArrowForwardSharp } from "react-icons/io5";
export default function Taskstopbar() {
    return (
        <>

            <div className='row mt-4'>
                <div className='col-2 ps-4'>
                    <p style={{ background: "#F1F1F1", borderRadius: "5px", textAlign: "center", fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#3C3C3C", padding: "10px", }}>Total Tasks : 245</p>
                </div>

                <div className='col-1'>
                    <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "13px", color: "#6B6B6B", marginTop: "10px", }}>Not Started : 120</p>
                </div>
                <div className='col-1 '>
                    <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "13px", color: "#E2A925", marginTop: "10px", }}>Ongoing : 125</p>
                </div>
                <div className='col-1 '>
                    <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "13px", color: "#55DE64", marginTop: "10px", }}>Completed : 25</p>
                </div>
                <div className='col-1 '>
                    <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "13px", color: "#F93333", marginTop: "10px", }}>Overdue : 25</p>
                </div>
            </div>
            <div className='container mt-5' style={{ overflowY: "scroll", height: "40vh", }}>
                <div className='row  ps-3' data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" style={{
                    border: "1px solid #DBDBDB", padding: "11px", background: "#FFFFFF", borderRadius: "5px",
                    borderLeft: '6px solid #6B6B6B'
                }}>

                    <div className='col-5'>
                        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", }}>Add a Quiz for Students of 3CO - JVY on the ...</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#EFEFEF", borderRadius: "5px", textAlign: "center", padding: "5px", color: "#3C3C3C", }}>06 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#FFF1F1", borderRadius: "5px", padding: "5px", textAlign: "center", color: "#F93333", }}>12 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ textAlign: "center", fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", padding: "5px", }}><IoPersonOutline style={{ color: "#434343", fontSize: "18px", }} /> Devesh Gupta</p>
                    </div>
                    <div className='col-1'>
                        <p className='mt-2'><IoArrowForwardSharp style={{ color: "#2188E7", float: "right", cursor: "pointer" }} /></p>
                    </div>
                </div>


                <div className='row mt-3  ps-3' style={{ border: "1px solid #DBDBDB", padding: "11px", background: "#FFFFFF", borderRadius: "5px", borderLeft: '6px solid #E2A925' }}>

                    <div className='col-5'>
                        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", }}>Add a Quiz for Students of 3CO - JVY on the ...</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#EFEFEF", borderRadius: "5px", textAlign: "center", padding: "5px", color: "#3C3C3C", }}>06 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#FFF1F1", borderRadius: "5px", padding: "5px", textAlign: "center", color: "#F93333", }}>12 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ textAlign: "center", fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", padding: "5px", }}><IoPersonOutline style={{ color: "#434343", fontSize: "18px", }} /> Devesh Gupta</p>
                    </div>
                    <div className='col-1'>
                        <p className='mt-2'><IoArrowForwardSharp style={{ color: "#2188E7", float: "right", cursor: "pointer" }} /></p>
                    </div>
                </div>


                <div className='row mt-3  ps-3' style={{ border: "1px solid #DBDBDB", padding: "11px", background: "#FFFFFF", borderRadius: "5px", borderLeft: '6px solid #60E06E' }}>

                    <div className='col-5'>
                        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", }}>Add a Quiz for Students of 3CO - JVY on the ...</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#EFEFEF", borderRadius: "5px", textAlign: "center", padding: "5px", color: "#3C3C3C", }}>06 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#FFF1F1", borderRadius: "5px", padding: "5px", textAlign: "center", color: "#F93333", }}>12 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ textAlign: "center", fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", padding: "5px", }}><IoPersonOutline style={{ color: "#434343", fontSize: "18px", }} /> Devesh Gupta</p>
                    </div>
                    <div className='col-1'>
                        <p className='mt-2'><IoArrowForwardSharp style={{ color: "#2188E7", float: "right", cursor: "pointer" }} /></p>
                    </div>
                </div>

                <div className='row mt-3  ps-3' style={{ border: "1px solid #DBDBDB", padding: "11px", background: "#FFFFFF", borderRadius: "5px", borderLeft: '6px solid #F93333' }}>

                    <div className='col-5'>
                        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", }}>Add a Quiz for Students of 3CO - JVY on the ...</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#EFEFEF", borderRadius: "5px", textAlign: "center", padding: "5px", color: "#3C3C3C", }}>06 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#FFF1F1", borderRadius: "5px", padding: "5px", textAlign: "center", color: "#F93333", }}>12 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ textAlign: "center", fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", padding: "5px", }}><IoPersonOutline style={{ color: "#434343", fontSize: "18px", }} /> Devesh Gupta</p>
                    </div>
                    <div className='col-1'>
                        <p className='mt-2'><IoArrowForwardSharp style={{ color: "#2188E7", float: "right", cursor: "pointer" }} /></p>
                    </div>
                </div>

                <div className='row mt-3  ps-3' style={{ border: "1px solid #DBDBDB", padding: "11px", background: "#FFFFFF", borderRadius: "5px", borderLeft: '6px solid #6B6B6B' }}>

                    <div className='col-5'>
                        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", }}>Add a Quiz for Students of 3CO - JVY on the ...</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#EFEFEF", borderRadius: "5px", textAlign: "center", padding: "5px", color: "#3C3C3C", }}>06 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#FFF1F1", borderRadius: "5px", padding: "5px", textAlign: "center", color: "#F93333", }}>12 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ textAlign: "center", fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", padding: "5px", }}><IoPersonOutline style={{ color: "#434343", fontSize: "18px", }} /> Devesh Gupta</p>
                    </div>
                    <div className='col-1'>
                        <p className='mt-2'><IoArrowForwardSharp style={{ color: "#2188E7", float: "right", cursor: "pointer" }} /></p>
                    </div>
                </div>

                <div className='row mt-3  ps-3' style={{ border: "1px solid #DBDBDB", padding: "11px", background: "#FFFFFF", borderRadius: "5px", borderLeft: '6px solid #6B6B6B' }}>

                    <div className='col-5'>
                        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", }}>Add a Quiz for Students of 3CO - JVY on the ...</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#EFEFEF", borderRadius: "5px", textAlign: "center", padding: "5px", color: "#3C3C3C", }}>06 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#FFF1F1", borderRadius: "5px", padding: "5px", textAlign: "center", color: "#F93333", }}>12 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ textAlign: "center", fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", padding: "5px", }}><IoPersonOutline style={{ color: "#434343", fontSize: "18px", }} /> Devesh Gupta</p>
                    </div>
                    <div className='col-1'>
                        <p className='mt-2'><IoArrowForwardSharp style={{ color: "#2188E7", float: "right", cursor: "pointer" }} /></p>
                    </div>
                </div>
            </div>

            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" style={{ width: "45%", }}>
                <div class="offcanvas-header">

                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div>
                        <div className='row'>
                            <div className='col-2'>
                                <p>Task Details</p>
                            </div>
                            <div className='col-2'>
                                <select id="cars" name="carlist" form="carform" style={{ background: "#EFEFEF", borderRadius: "5px", padding: "5px 4px", border: "none", fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#8A8A8A", }}>
                                    <option value="volvo">Not Started</option>
                                    <option value="saab">Ongoing</option>
                                    <option value="saab">Completed</option>
                                </select>
                            </div>
                            <div className='col-6'>
                                <p className='text-center' style={{ background: "#FFEBEB", borderRadius: "5px", fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#FD2727", padding: "5px 5px", }}>Due Date : 12:00 PM | 12 Sep 2023</p>
                            </div>
                            <div className='col-2'>
                                <p style={{ background: "#3D70F5", borderRadius: "5px", color: "#fff", width: "100%", padding: "5px 31px", }}>Save</p>
                            </div>
                        </div>
                    </div>
                    <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#787878", }}>Title</p>

                    <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "20px", color: "#3C3C3C", }}>Add a Quiz for Students of 3CO - JVY on the subject Fundamentals of Programming</p>

                    <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#787878", }}>Assigned by</p>

                    <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "22px", color: "#3C3C3C", }}> Harsh Kadyan</p>

                    <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "22px", color: "#787878", }}>Description</p>

                    <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus at sodales sodales. Quisque sagittis orci ut diam condimentum, vel euismod erat placerat. In iaculis arcu eros, eget tempus orci facilisis id. Praesent lorem orci, mattis non efficitur id, ultricies vel nibh. Sed volutpat lacus vitae gravida viverra. Fusce vel tempor elit. Proin tempus, magna id scelerisque vestibulum, nulla ex pharetra sapien, tempor posuere massa neque nec felis. Aliquam sem ipsum, vehicula ac tortor vel, egestas ullamcorper dui. Curabitur at risus sodales, tristique est id, euismod justo. Mauris nec leo non libero sodales lobortis. Quisque a neque pretium, dictum tellus vitae, euismod neque. Nulla facilisi. Phasellus ultricies dignissim nibh ut cursus. Nam et quam sit amet turpis finibus maximus tempor eget augue. Aenean at ultricies lorem. Sed egestas ligula tortor, sit amet mattis ex feugiat non. Duis purus diam, dictum et ante at, commodo iaculis urna. Aenean lacinia, nisl id vehicula condimentum, enim massa tempor nibh, vitae volutpat sapien metus aliquet nisl. Etiam dui eros, tincidunt tristique blandit id, gravida vitae augue. Proin imperdiet mi nec justo convallis gravida. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Mauris consectetur nibh in mattis commodo. Etiam in pellentesque eros. Etiam tempus.</p>

                    <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#787878", }}>Attachments</p>

                    <div className='mt-2'>
                        <div className='row'>
                            <div className='col-3'>
                                <p style={{ background: "#FFFFFF", border: "1px solid #D7D7D7", borderRadius: "5px", padding: "4px 6px", fontSize: "14px", }}>New Assignment .pdf</p>
                            </div>
                            <div className='col-3'>
                                <p style={{ background: "#FFFFFF", border: "1px solid #D7D7D7", borderRadius: "5px", padding: "4px 2px", padding: "4px 6px", fontSize: "14px", }}>New Assignment .pdf</p>
                            </div>
                            <div className='col-3'>
                                <p style={{ background: "#FFFFFF", border: "1px solid #D7D7D7", borderRadius: "5px", padding: "4px 2px", padding: "4px 6px", fontSize: "14px", }}>New Assignment .pdf</p>
                            </div>
                            <div className='col-3'>
                                <p style={{ background: "#FFFFFF", border: "1px solid #D7D7D7", borderRadius: "5px", padding: "4px 2px", padding: "4px 6px", fontSize: "14px", }}>New Assignment .pdf</p>
                            </div>
                        </div>
                    </div>
                    <div className='mt-2'>
                        <div className='row'>
                            <div className='col-3'>
                                <p style={{ background: "#FFFFFF", border: "1px solid #D7D7D7", borderRadius: "5px", padding: "4px 2px", padding: "4px 6px", fontSize: "14px", }}>New Assignment .pdf</p>
                            </div>
                            <div className='col-3'>
                                <p style={{ background: "#FFFFFF", border: "1px solid #D7D7D7", borderRadius: "5px", padding: "4px 2px", padding: "4px 6px", fontSize: "14px", }}>New Assignment .pdf</p>
                            </div>
                            <div className='col-3'>
                                <p style={{ background: "#FFFFFF", border: "1px solid #D7D7D7", borderRadius: "5px", padding: "4px 2px", padding: "4px 6px", fontSize: "14px", }}>New Assignment .pdf</p>
                            </div>
                            <div className='col-3'>
                                <p style={{ background: "#FFFFFF", border: "1px solid #D7D7D7", borderRadius: "5px", padding: "4px 2px", padding: "4px 6px", fontSize: "14px", }}>New Assignment .pdf</p>
                            </div>
                        </div>
                    </div>
                    <div className='mt-2'>
                        <div className='row'>
                            <div className='col-3'>
                                <p style={{ background: "#FFFFFF", border: "1px solid #D7D7D7", borderRadius: "5px", padding: "4px 2px", padding: "4px 6px", fontSize: "14px", }}>New Assignment .pdf</p>
                            </div>
                            <div className='col-3'>
                                <p style={{ background: "#FFFFFF", border: "1px solid #D7D7D7", borderRadius: "5px", padding: "4px 2px", padding: "4px 6px", fontSize: "14px", }}>New Assignment .pdf</p>
                            </div>
                            <div className='col-3'>
                                <p style={{ background: "#FFFFFF", border: "1px solid #D7D7D7", borderRadius: "5px", padding: "4px 2px", padding: "4px 6px", fontSize: "14px", }}>New Assignment .pdf</p>
                            </div>
                            <div className='col-3'>
                                <p style={{ background: "#FFFFFF", border: "1px solid #D7D7D7", borderRadius: "5px", padding: "4px 2px", padding: "4px 6px", fontSize: "14px", }}>New Assignment .pdf</p>
                            </div>
                        </div>
                    </div>
                    <div className='mt-2 mb-2   '>
                        <div className='row'>
                            <div className='col-3'>
                                <p style={{ background: "#FFFFFF", border: "1px solid #D7D7D7", borderRadius: "5px", padding: "4px 2px", padding: "4px 6px", fontSize: "14px", }}>New Assignment .pdf</p>
                            </div>
                            <div className='col-3'>
                                <p style={{ background: "#FFFFFF", border: "1px solid #D7D7D7", borderRadius: "5px", padding: "4px 2px", padding: "4px 6px", fontSize: "14px", }}>New Assignment .pdf</p>
                            </div>
                            <div className='col-3'>
                                <p style={{ background: "#FFFFFF", border: "1px solid #D7D7D7", borderRadius: "5px", padding: "4px 2px", padding: "4px 6px", fontSize: "14px", }}>New Assignment .pdf</p>
                            </div>
                            <div className='col-3'>
                                <p style={{ background: "#FFFFFF", border: "1px solid #D7D7D7", borderRadius: "5px", padding: "4px 2px", padding: "4px 6px", fontSize: "14px", }}>New Assignment .pdf</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}



