import React, { useState, useEffect, useContext } from "react"; //api done
import { IoArrowBackOutline } from "react-icons/io5";
import Back from "../images/wave.png";
import { FiUpload } from "react-icons/fi";
import { IoCalendarOutline } from "react-icons/io5";
import { IoArrowBack } from "react-icons/io5";
import { IoInfiniteOutline } from "react-icons/io5";
import { FiCalendar } from "react-icons/fi";
import { NavLink, useNavigate, useParams } from "react-router-dom";
// import { API_URL, img_url } from "./MyComponent";
import DatePicker from "react-datepicker";
// import ToastContainer from "react-bootstrap/ToastContainer";
// import Toast from "react-bootstrap/Toast";
import { AuthContext } from "./AuthContext";
import { ToastContainer, toast } from "react-toastify";
// import { format } from 'date-fns';
import lotti6 from '../images/142632-emoji.json';
import Lottie from "lottie-react";
import { API_URL, img_url } from "../env";
// import { Toast } from 'react-toastify/dist/components';

export default function PaymentSetup() {
    const { id } = useParams();

    const { profile, profileGet } = useContext(AuthContext);
    const [startDate, setStartDate] = useState(new Date());
    const [startDate1, setStartDate1] = useState(new Date());
    const [startDate2, setStartDate2] = useState(new Date());
    const [count, setcount] = useState(1);
    const [duedate, setDuedate] = useState(0);
    const [tabled, setTabled] = useState(0);
    const [Barr, setBarr] = useState("true");

    const [BatchId, setBatchId] = useState("");
    const [Emis, setEmis] = useState([]);
    const [TotalAmount, setTotalAmount] = useState("");
    const [Sedule, setSedule] = useState();
    const [Sedule1, setSedule1] = useState();
    const [Sedule2, setSedule2] = useState();

    const { apiToken } = useContext(AuthContext);

    const [message, setmessage] = useState();
    const [final, setfinal] = useState();
    const [onefinal, setonefinal] = useState();
    const [isValid, setIsValid] = useState(false);
    const [remark, setRemark] = useState("course fees");
    const [dummy_amount, setDummyAmount] = useState("");
    const [payment_mode, setPaymentMode] = useState("emi");

    const Navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        contact: "",
        address: "",
        AdhaarNo: "",
        PanNo: "",
        Enrollment: "",
        EMI: "",
        Discount: "",
        Father: "",
        Mother: "",
        Guardian: "",
        Bank: "",
        IFSC: "",
    });

    //require destructiong above state objects
    const { banner } = profile;


    const Payment = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", `Bearer ${apiToken}`);

        var raw = JSON.stringify({
            "payment_mode": payment_mode,
            "remark": remark,
            "student_id": id,
            "amount": TotalAmount,
            "discountAmount": formData.Discount,
            "emi_count": formData.EMI,
            "emiEndDate": Sedule1,
            "emiStartDate": Sedule,
        })
     
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${API_URL}/admin/student/add/fees/for/student`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status == 1) {
                    toast(result.msg);
                    setcount(count + 1)
                }
                else if (result.status === 'VAL_ERR') {
                    toast(result.Backend_Error)
                  }
                else if (result.status == 'FILE_ERR') {
                    toast(result.Backend_Error)
                  }
                 else if (result.status == "CUSTOM_ERR") {
                    toast(result.Backend_Error);
                  } else if (result.status == "TOKEN_ERR") {
                    localStorage.removeItem('api_url');
                    Navigate('/code')
                    toast("Logged in other device! Please login again")
                  }
                  else {
                    console.log(result)
                  } 
                
            })
            .catch((error) => console.log("error", error));
    };



    // const handleDummyAmount = (amount) => {
    //     setDummyAmount(TotalAmount - +amount);
    // };



    const HandleProcess5 = () => {
        if (
            // formData.Discount.length <= 4||
            formData.EMI <= 1 ||
            !Sedule ||
            !Sedule1
        ) {
            toast(
                // formData.Discount.length <= 4
                //   ? 'The discount amount should be less than total amount'
                //   :
                formData.EMI <= 1
                    ? "The count EMI field is required"
                    : !Sedule
                        ? "Please set the start date"
                        : !Sedule1
                            ? "Please set the end date"
                            : "Please fill the required field"
            );
            if (duedate == 0) {
                setTabled(0);
            } else {
                setTabled(1);
            }
        } else {
            setcount(count + 1);
        }
    };

    const HandleProcess6 = () => {
        if (!Sedule2) {
            toast("Please set the due date");
        } else {
            setcount(count + 1);
        }
    };

    const HandleProcess7 = () => {
        if (formData.EMI <= 1 || !Sedule || !Sedule1) {
            toast(
                formData.EMI <= 2
                    ? "The count EMI field is required"
                    : !Sedule
                        ? "Please set the start date"
                        : !Sedule1
                            ? "Please set the end date"
                            : "Please fill the required field"
            );
        } else {
            setTabled(1);
            // setTabled(1);
            // AddStudent(false);
        }
    };

    const handleInputChange = (fieldName, event) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: event.target.value,
        }));
    };


    const handleInputChange3 = (fieldName, value) => {
        const truncatedValue = value.slice(0, 6);
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: truncatedValue,
        }));
    };





    useEffect(() => {

        // profileGet();
    }, []);

    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                background: "#FBFDFF",
                backgroundImage: count > 4 ? "" : `url(${Back})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom",
            }}
        >
           
            {/* <div className="d-flex mt-1 px-3">
                <img
                    src={`${banner}`}
                    className="header"
                    style={{
                        color: "black",
                        width: "auto",
                        height: "80px",
                        objectFit: "cover",
                    }}
                />
                {/* <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" style={{marginLeft:"82rem",}}></button> 
            </div> */}



            {/* <!--form  1--> */}
            <div className="px-4" style={{ display: count === 1 ? "block" : "none" }}>
                <div>
                    <div className="row">
                        <div className="col-6" style={{ overflow: "hidden" }}>
                            <h3
                                className="mt-4"
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize: "30px",
                                    color: "#000000",
                                }}
                            >
                                <IoArrowBack
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        Navigate(-1)
                                    }}
                                />{" "}
                                Setup Payment
                            </h3>
                            <div
                                className="ps-3 mt-4"
                                style={{
                                    border: "1px solid #000000",
                                    borderRadius: "10px",
                                    width: "30%",
                                    padding: "15px",
                                }}
                            >
                                <p
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        fontSize: "20px",
                                        display: "flex",
                                        alignItems: "center",
                                        color: "#000000",
                                    }}
                                >
                                    Grand Total :
                                </p>
                                <span
                                    style={{
                                        border: "0",
                                        fontStyle: "normal",
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        color: "#000000",
                                    }}
                                >
                                    ₹  <input
                                        class="number"
                                        value={TotalAmount}
                                        onChange={(e) => setTotalAmount(e.target.value)}
                                        style={{
                                            border: "0",
                                            fontStyle: "normal",
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "#000000",
                                        }}

                                    //   onChange={() => {
                                    //     setBarr("show");
                                    //   }}
                                    />
                                </span>
                            </div>

                            <div className="row mt-4">
                                <div className="col-4">
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            checked={duedate == 0}
                                            onClick={() => {
                                                setDuedate(0); setPaymentMode("emi")
                                            }}
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                            onChange={() => {
                                                setBarr("show");
                                            }}
                                        />
                                        <label
                                            class="form-check-label"
                                            for="flexRadioDefault "
                                            style={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                fontSize: "20px",
                                                color: "#000000",
                                            }}
                                        >
                                            Pay in EMIs
                                        </label>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            onClick={() => { setDuedate(1); setPaymentMode("one_shot") }}
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                            onChange={() => {
                                                setTabled(0);
                                                setBarr("hiden");
                                            }}
                                        />
                                        <label
                                            class="form-check-label"
                                            for="flexRadioDefault1"
                                            style={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                fontSize: "20px",
                                                color: "#000000",
                                            }}
                                        >
                                            Pay in One Shot
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: duedate == 0 ? "block" : "none" }}>
                                <div className="row">
                                    <div className="col-6">
                                        <p
                                            style={{
                                                fontStyle: "normal",
                                                fontWeight: "500",
                                                fontSize: "18px",
                                                color: "#000000",
                                                marginTop: 20,
                                            }}
                                        >
                                            Discount Amount
                                        </p>
                                        <div className="d-flex mt-2 ">
                                            <input
                                                type="number"
                                                placeholder="Enter Amount"
                                                style={{
                                                    width: "80%",
                                                    background: "#E0E0E0",
                                                    borderRadius: "10px",
                                                    border: "none",
                                                    padding: "15px",
                                                }}
                                                value={formData.Discount}
                                                onChange={(e) => {
                                                    handleInputChange3("Discount", e.target.value);
                                                    // handleDummyAmount(e.target.value);
                                                }}
                                            />
                                            <p
                                                className="mt-1"
                                                style={{
                                                    fontStyle: "normal",
                                                    fontWeight: "500",
                                                    fontSize: "28px",
                                                    color: "#000000",
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                RS
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <p
                                            style={{
                                                fontStyle: "normal",
                                                fontWeight: "500",
                                                fontSize: "18px",
                                                color: "#000000",
                                                marginTop: 20,
                                            }}
                                        >
                                            EMI Count<span style={{ color: "red" }}>*</span>
                                        </p>
                                        <input
                                            type="number"
                                            className="mt-2"
                                            placeholder="Number of EMIs"
                                            style={{
                                                width: "80%",
                                                background: "#E0E0E0",
                                                borderRadius: "10px",
                                                border: "none",
                                                padding: "12px",
                                            }}
                                            value={formData.EMI}
                                            onChange={(e) => handleInputChange("EMI", e)}
                                        />
                                    </div>

                                    <div className="col-3 mx-3">
                                        <div>
                                            <p
                                                style={{
                                                    marginTop: "20px",
                                                    fontStyle: "normal",
                                                    fontWeight: "500",
                                                    fontSize: "18px",
                                                    color: "#000000",
                                                }}
                                            >
                                                Start Date<span style={{ color: "red" }}>*</span>
                                            </p>
                                            <span
                                                className="mt-2 d-flex"
                                                style={{
                                                    borderRadius: "10px",
                                                    width: "170px",
                                                    height: "45px",
                                                    backgroundColor: "#E0E0E0",
                                                    textAlign: "center",
                                                    color: "#7A7A7A",
                                                    padding: "11px",
                                                }}
                                            >
                                                <FiCalendar style={{ fontSize: "1.5rem" }} />
                                                <DatePicker
                                                    className="border-0  datepAdd "
                                                    showIcon
                                                    selected={startDate}
                                                    onChange={(date) => {
                                                        setStartDate(date);
                                                        const month = (date.getMonth() + 1).toString().padStart(2, '0');
                                                        const day = date.getDate().toString().padStart(2, '0');
                                                        setSedule(
                                                            date.getFullYear() +
                                                            "-" +
                                                            month +
                                                            "-" +
                                                            day
                                                        );
                                                    }}
                                                    style={{
                                                        backgroundColor: "#E0E0E0",
                                                        outline: "0",
                                                        width: "15px",
                                                    }}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-3 mx-3">
                                        <div>
                                            <p
                                                style={{
                                                    marginTop: "20px",
                                                    fontStyle: "normal",
                                                    fontWeight: "500",
                                                    fontSize: "18px",
                                                    color: "#000000",
                                                }}
                                            >
                                                End Date<span style={{ color: "red" }}>*</span>
                                            </p>
                                            <span
                                                className="mt-2 d-flex"
                                                style={{
                                                    borderRadius: "10px",
                                                    width: "170px",
                                                    height: "45px",
                                                    backgroundColor: "#E0E0E0",
                                                    textAlign: "center",
                                                    color: "#7A7A7A",
                                                    padding: "11px",
                                                }}
                                            >
                                                <FiCalendar style={{ fontSize: "1.5rem" }} />
                                                <DatePicker
                                                    className="border-0  datepAdd"
                                                    showIcon
                                                    selected={startDate1}
                                                    onChange={(date) => {
                                                        setStartDate1(date);
                                                        const month = (date.getMonth() + 1).toString().padStart(2, '0');
                                                        const day = date.getDate().toString().padStart(2, '0');
                                                        setSedule1(
                                                            date.getFullYear() +
                                                            "-" +
                                                            month +
                                                            "-" +
                                                            day
                                                        );
                                                    }}
                                                    style={{ backgroundColor: "#E0E0E0", outline: "0" }}
                                                />
                                            </span>
                                        </div>
                                    </div>

                                    {/* <div className="col-2 mt-5">
                                        <button
                                            onClick={() => {
                                                // setTabled(1);
                                                // setTabled(1);
                                                // AddStudent(false);
                                                HandleProcess7();
                                            }}
                                            className="btn btn-outline-dark mx-5"
                                        >
                                            OK
                                        </button>
                                    </div> */}
                                </div>
                            </div>

                            <div
                                className=""
                                style={{ display: duedate == 1 ? "block" : "none" }}
                            >
                                <div style={{ display: "flex" }}>
                                    <div className="col-4">
                                        <p
                                            style={{
                                                fontStyle: "normal",
                                                fontWeight: "500",
                                                fontSize: "18px",
                                                color: "#000000",
                                                marginTop: 20,
                                            }}
                                        >
                                            Discount Amount
                                        </p>
                                        <div className="d-flex mt-2 ">
                                            <input
                                                type="text"
                                                placeholder="Enter Amount"
                                                style={{
                                                    width: "80%",
                                                    background: "#E0E0E0",
                                                    borderRadius: "10px",
                                                    border: "none",
                                                    padding: "15px",
                                                }}
                                                value={formData.Discount}
                                                onChange={(e) => {
                                                    handleInputChange("Discount", e);
                                                    // handleDummyAmount(e.target.value);
                                                }}
                                            />
                                            {/* <p
                                            className="mt-1"
                                            style={{
                                                fontStyle: "normal",
                                                fontWeight: "500",
                                                fontSize: "28px",
                                                color: "#000000",
                                                marginLeft: "10px",
                                            }}
                                        >
                                            RS
                                        </p> */}
                                        </div>
                                    </div>

                                    <div>
                                        <p
                                            style={{
                                                marginTop: "20px",
                                                fontStyle: "normal",
                                                fontWeight: "500",
                                                fontSize: "18px",
                                                color: "#000000",
                                            }}
                                        >
                                            Due Date<span style={{ color: "red" }}>*</span>
                                        </p>
                                        <span
                                            className="mt-2 d-flex"
                                            style={{
                                                borderRadius: "10px",
                                                width: "180px",
                                                height: "45px",
                                                backgroundColor: "#E0E0E0",
                                                textAlign: "center",
                                                color: "#7A7A7A",
                                                padding: "11px",
                                            }}
                                        >
                                            <FiCalendar />
                                            &nbsp;
                                            <DatePicker
                                                className="border-0  datepAdd"
                                                showIcon
                                                selected={startDate1}
                                                onChange={(date) => {
                                                    setStartDate1(date);
                                                    const month = (date.getMonth() + 1).toString().padStart(2, '0');
                                const day = date.getDate().toString().padStart(2, '0');
                                                    setSedule1(
                                                        date.getFullYear() + "-" + month + "-" + day
                                                    );
                                                }}
                                                style={{ backgroundColor: "#E0E0E0", outline: "0" }}
                                            />
                                        </span>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <p
                                        style={{
                                            fontStyle: "normal",
                                            fontWeight: "500",
                                            fontSize: "18px",
                                            color: "#000000",
                                            marginTop: 20,
                                        }}
                                    >
                                        Remark
                                    </p>
                                    <div className="d-flex mt-2 ">
                                        <input
                                            type="text"
                                            placeholder="Enter Remark"
                                            style={{
                                                // width: "80%",
                                                background: "#E0E0E0",
                                                borderRadius: "10px",
                                                border: "none",
                                                padding: "15px",
                                            }}
                                            value={remark}
                                            onChange={(e) => setRemark(e.target.value)}

                                        />
                                        {/* <p
                                            className="mt-1"
                                            style={{
                                                fontStyle: "normal",
                                                fontWeight: "500",
                                                fontSize: "28px",
                                                color: "#000000",
                                                marginLeft: "10px",
                                            }}
                                        >
                                            RS
                                        </p> */}
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-8">
                                    <div className="mt-5 mb-4 text-center">
                                        <button
                                            type="button"
                                            class="btn btn-primary"
                                            onClick={() => {
                                                Payment()
                                            }}
                                            style={{
                                                width: "100%",
                                                background: "#3572FA",
                                                boxShadow: "0px 2px 8px #B0BEEF",
                                                borderRadius: "10px",
                                                padding: "15px",
                                                border: "none",
                                            }}
                                        >
                                            Proceed To Payment
                                        </button>
                                    </div>
                                </div>
                                <div className="col-4"></div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>


            <div style={{ display: count === 2 ? "block" : "none" }}>
                <div className="mt-4">
                    <p style={{ textAlign: "center", color: "#3C3C3C", fontSize: "35px", fontWeight: "600" }}>Payment Schedule Successfully!!</p>
                </div>


                <Lottie animationData={lotti6} className="d-grid gap-2 col-6 mx-auto" style={{ width: "25%" }} />


                <div className="">
                    <button className="d-grid gap-2 col-6" style={{
                        marginTop: "70px", width: "50%", padding: "15px", fontSize: "18px", marginLeft: "25%",
                        backgroundColor: "#8147E7", border: "none", color: "#fff", boxShadow: " 0px 4px 12px rgba(126, 57, 247, 0.36)", fontWeight: "500", borderRadius: "5px"
                    }} onClick={() => { Navigate(-1) }}>Back to Batch Details</button>
                </div>
                <div className="mt-3"></div>

            </div>

            {/* <!--form  6--> */}
            <div className="px-5" style={{ display: count === 6 ? "block" : "none" }}>
                <div className="row mt-3">
                    <div className="col-6">
                        <p
                            className=""
                            style={{
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "25px",
                                color: "#000000",
                            }}
                        // onClick={() => {
                        //     setcount(count - 1);
                        //     AddStudent(false);
                        // }}
                        >

                            Preview
                        </p>
                        <div>
                            <p
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: "red",
                                }}
                            >
                                *If you filled any incorrect data please fill the form again
                            </p>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4">
                                <div
                                    style={{
                                        border: "1px solid #000000",
                                        borderRadius: "10px",
                                        padding: "13px",
                                        width: "90%",
                                    }}
                                >
                                    <p
                                        style={{
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "18px",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "#000000",
                                        }}
                                    >
                                        Amount to Be Paid
                                    </p>
                                    <p
                                        style={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            fontSize: "25px",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "#000000",
                                        }}
                                    >
                                        ₹ {duedate == 1 ? dummy_amount : final}
                                    </p>
                                </div>
                            </div>
                            <div className="col-4">
                                <div
                                    style={{
                                        border: "1px solid #000000",
                                        borderRadius: "10px",
                                        padding: "13px",
                                        width: "90%",
                                    }}
                                >
                                    <p
                                        style={{
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "18px",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "#000000",
                                        }}
                                    >
                                        Grand Total :
                                    </p>
                                    <p
                                        style={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            fontSize: "25px",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "#000000",
                                        }}
                                    >
                                        ₹ {TotalAmount}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <p
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    color: "#000000",
                                }}
                            >
                                To
                            </p>
                            <p
                                className="mt-1"
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "24px",
                                    color: "#000000",
                                }}
                            >
                                {formData.name}
                            </p>
                            <p
                                className="mt-1"
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "18px",
                                    color: "#000000",
                                }}
                            >
                                {formData.address}{" "}
                            </p>
                            <p
                                className="mt-1"
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "20px",
                                    color: "#000000",
                                }}
                            >
                                +91 {formData.contact}
                            </p>
                            <div style={{ display: duedate === 1 ? "block" : "none" }}>
                                <p
                                    className="mt-3"
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        fontSize: "20px",
                                        color: "#000000",
                                    }}
                                >
                                    Due Date
                                </p>
                                <p
                                    className="mt-1"
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "20px",
                                        color: "#000000",
                                    }}
                                >
                                    <IoCalendarOutline /> {Sedule2}
                                </p>
                            </div>
                            <div className="d-flex mt-2">
                                <input type="checkbox" />
                                <p
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        color: "#000000",
                                    }}
                                >
                                    {" "}
                                    Setup eNACH
                                </p>
                            </div>

                            <div className="mt-3">
                                <button
                                    type="button"
                                    class="btn "
                                    // onClick={() => {
                                    //     setcount(count + 1);
                                    //     AddStudent(true);
                                    // }}
                                    style={{
                                        width: "70%",
                                        padding: "10px",
                                        background: "#5A81FE",
                                        boxShadow: "0px 2px 8px rgba(90, 129, 254, 0.25)",
                                        borderRadius: "5px",
                                        color: "#fff",
                                        border: "none",
                                        fontSize: "18px",
                                    }}
                                >
                                    Proceed To Payment
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* <div> */}
                    <div
                        style={{ display: tabled == 1 ? "block" : "none" }}
                        className="col-6"
                    >
                        <p
                            style={{
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "22px",
                                color: "#000000",
                            }}
                        >
                            Payment Schedule
                        </p>

                        <div>
                            <div>
                                <div className="card" style={{ width: "80%" }}>
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <p
                                                style={{
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    fontSize: "12px",
                                                    color: "#000000",
                                                }}
                                            >
                                                S.No
                                            </p>
                                            <p
                                                style={{
                                                    marginLeft: "6rem",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    fontSize: "12px",
                                                    color: "#000000",
                                                }}
                                            >
                                                Remarks
                                            </p>
                                            <p
                                                style={{
                                                    marginLeft: "6rem",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    fontSize: "12px",
                                                    color: "#000000",
                                                }}
                                            >
                                                Amount
                                            </p>
                                            <p
                                                style={{
                                                    marginLeft: "6rem",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    fontSize: "12px",
                                                    color: "#000000",
                                                }}
                                            >
                                                Due Date
                                            </p>
                                        </div>
                                        <div style={{ overflowY: "scroll", height: "70vh" }}>
                                            {Emis?.map((el, ind) => {
                                                return (
                                                    <>
                                                        <div
                                                            key={el.emi_number}
                                                            className="d-flex mt-4"
                                                            style={{
                                                                borderRadius: "10px",
                                                                border: "1px solid #ECECEC",
                                                                padding: "10px",
                                                            }}
                                                        >
                                                            <p
                                                                style={{
                                                                    fontStyle: "normal",
                                                                    fontWeight: "400",
                                                                    fontSize: "12px",
                                                                    color: "#000000",
                                                                }}
                                                            >
                                                                {el.emi_number}
                                                            </p>
                                                            <p
                                                                style={{
                                                                    marginLeft: "6rem",
                                                                    fontStyle: "normal",
                                                                    fontWeight: "400",
                                                                    fontSize: "12px",
                                                                    color: "#000000",
                                                                }}
                                                            >
                                                                Tution fee
                                                            </p>
                                                            <p
                                                                style={{
                                                                    marginLeft: "6rem",
                                                                    fontStyle: "normal",
                                                                    fontWeight: "400",
                                                                    fontSize: "12px",
                                                                    color: "#000000",
                                                                }}
                                                            >
                                                                {el.emi_amount}
                                                            </p>
                                                            <p
                                                                style={{
                                                                    marginLeft: "6rem",
                                                                    fontStyle: "normal",
                                                                    fontWeight: "400",
                                                                    fontSize: "12px",
                                                                    color: "#000000",
                                                                }}
                                                            >
                                                                {el.emi_date}
                                                            </p>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!--form  7--> */}

            <div className="" style={{ display: count === 7 ? "block" : "none" }}>
                <div className="row">
                    <div className="col-2"></div>

                    <div className="col-4">
                        <div className="">
                            <p
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize: "30px",
                                    color: "#000000",
                                }}
                                onClick={() => {
                                    setcount(count - 1);
                                }}
                            >
                                {/*<IoArrowBack style={{ cursor: "pointer" }} />*/}
                                Payment
                            </p>
                            <p
                                className="mt-4"
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    fontSize: "30px",
                                    color: "#000000",
                                }}
                            >
                                Payment Link Sent to <br></br> {formData.name} !
                            </p>
                            <div className="mt-5">
                                <p
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        fontSize: "20px",
                                        color: "#000000",
                                    }}
                                >
                                    Payment Link
                                </p>
                                <div
                                    className="d-flex"
                                    style={{
                                        border: "1px solid #565656",
                                        width: "32rem",
                                        padding: "5px 3px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <p>Coming Soon</p>
                                    <p style={{ marginLeft: "10px" }}>
                                        <IoInfiniteOutline />
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5">
                                <p
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        fontSize: "20px",
                                        color: "#000000",
                                    }}
                                >
                                    Remark
                                </p>
                                <div className="">
                                    {/*<textarea name="postContent" value={remark} onChange={(e) => setRemark(remark)} style={{ border: "1px solid #565656", width: "32rem", padding: "5px 3px", borderRadius: "5px", }} />
                    <p style={{ float: "right", fontStyle: "normal", fontWeight: "300", fontSize: "13px", color: "#565656", }}>{remark.length}/100</p>*/}

                                    <textarea
                                        name="postContent"
                                        value={remark}
                                        onChange={(e) => setRemark(e.target.value)}
                                        style={{
                                            border: "1px solid #565656",
                                            width: "32rem",
                                            padding: "5px 3px",
                                            borderRadius: "5px",
                                        }}
                                    />
                                    <p
                                        style={{
                                            float: "right",
                                            fontStyle: "normal",
                                            fontWeight: "300",
                                            fontSize: "13px",
                                            color: "#565656",
                                        }}
                                    >
                                        {remark.length}/100
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5">
                                <p
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        fontSize: "20px",
                                        color: "#000000",
                                    }}
                                >
                                    Contact Number
                                </p>
                                <p
                                    className="mt-2"
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        fontSize: "25px",
                                        color: "#000000",
                                    }}
                                >
                                    +91 {formData.contact}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-1"></div>
                    <div className="col-4">
                        <div
                            className=""
                            style={{
                                border: "1px solid #000000",
                                borderRadius: "10px",
                                width: "50%",
                                padding: "15px",
                            }}
                        >
                            <p
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    fontSize: "20px",
                                    color: "#000000",
                                }}
                            >
                                Amount to Be Paid
                            </p>
                            <p>₹ {duedate == 1 ? onefinal : final}</p>
                        </div>

                        <p className="mt-5">Due Date</p>
                        <p className="mt-2"  >
                            <IoCalendarOutline /> {duedate == 1 ? Sedule2 : Sedule1}
                        </p>
                    </div>
                    <div className="col-1"></div>
                </div>
                <div className="text-center mt-1">
                    <button
                        type="button"
                        class="btn "
                        style={{
                            background: "#5A81FE",
                            boxShadow: "0px 4px 12px rgba(90, 129, 254, 0.25)",
                            borderRadius: "5px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "14px",
                            textAlign: "center",
                            color: "#FFFFFF",
                            padding: "15px 70px",
                            border: "none",
                        }}
                    // onClick={() => Remarks()}
                    >
                        Back To Dashboard
                    </button>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}
