import React, { useState, useEffect } from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import DatePicker from "react-datepicker";
import { BiCalendar } from 'react-icons/bi';
import { BsFillCalendarFill } from "react-icons/bs";
import TIPG from '../images/tipsglogo.png';
import "react-datepicker/dist/react-datepicker.css"
import { FiDownload } from "react-icons/fi"
import { RiArrowDropDownLine } from 'react-icons/ri';
import { RiFileExcel2Line } from 'react-icons/ri';
import { ToastContainer, toast } from 'react-toastify';
export default function Allexpence() {
  const [startDate, setStartDate] = useState(new Date())
  const [startDate1, setStartDate1] = useState(new Date())

  const cre = [
    
  ]
  const dd = [
    {
      sn: "1.",
      part: "Machine",
      qty: "10",
      amount: "₹ 10,000"
    },
    {
      sn: "1.",
      part: "Machine",
      qty: "10",
      amount: "₹ 10,000"
    }
  ]
  return (
    <>
      <div className="card mt-4 ms-4" style={{ width: "95%", border: "none" }}>
        <div className="card-body">
          <div className="row">
            <div className="col-4">
              <p style={{ fontWeight: "400", color: "#8A8A8A", fontSize: "18px" }}>Date Range</p>
              <div className="row mt-1">
                <div className="col-6">
                  <p style={{ color: "#8A8A8A", fontSize: "400" }}>From</p>
                  <div>

                    <span style={{
                      display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "0px",
                      borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
                    }}>
                      <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
                      <div>
                        <DatePicker className="border-0 ps-2 pt-1 datep"
               
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                      </div>
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <p style={{ color: "#8A8A8A", fontSize: "400" }}>To</p>
                  <div>

                    <span style={{
                      display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "0px",
                      borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
                    }}>
                      <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
                      <div>
                        <DatePicker className="border-0 ps-2 pt-1  datep"
                
                          selected={startDate1}
                          onChange={(date) => setStartDate1(date)}
                          style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                      </div>
                    </span>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-2 mt-3">
              <p style={{ fontSize: "18px", fontWeight: "400", color: "#8A8A8A" }}>Total Collection</p>
              <p style={{ fontWeight: "500", fontSize: "20px", color: "#3C3C3C" }}>₹ 0</p>
            </div>
            <div className="col-3 mt-3">
              <p style={{ fontSize: "18px", fontWeight: "400", color: "#8A8A8A" }}>Expected Collection</p>
              <p style={{ fontWeight: "500", fontSize: "20px", color: "#3C3C3C" }}>₹ 0</p>
            </div>
            <div className="col-3">
              <button className='ms-5 mt-4' style={{
                backgroundColor: "#8147E7", borderRadius: "5px", width: "180px", height: "50px", border: "none",
                color: "#ffffff", fontWeight: "400", fontSize: "20px"
              }}><RiFileExcel2Line style={{ fontSize: "20px" }} /> &nbsp;&nbsp;Export Data</button>
            </div>
          </div>
        </div>
      </div>

      {/* Invoice Details */}

      <div className="mt-5">
        <p className="" style={{ color: "#3C3C3C", fontSize: '22px', fontWeight: "600", paddingLeft: "30px" }}>Expense Details</p>

        <div className='mt-3 '>
          <div className='row mx-4 mt-3'  >
            <div className='row' style={{ color: "#434343", fontWeight: "600", fontSize: "15px" }}>
              <div className='col-1'>
                <p>S.No</p>
              </div>
              <div className='col-2'>
                <p>Name</p>
              </div>
              <div className='col-1'>
                <p>Amount</p>
              </div>
              <div className='col-2'>
                <p>Phone No.</p>
              </div>


              {/* <div className='col-2'>
           <p>Transaction ID</p>
         </div> */}


              {/* <div className='col-2'>
           <p>Payment Method</p>
         </div> */}
              <div className='col-2'>
                <p>Status</p>
              </div>
              <div className='col-2'>
                <p>Due Date</p>
              </div>
              <div className='col-2'>
                {/* <button>djgufey</button> */}
              </div>
            </div>
            <div style={{ overflowY: "scroll", height: "60vh" }}>
              {cre.length>0?cre.map((res, key) => {
                return (
                  <>
                    <div className='row align-items-center  mt-3' style={{ width: "100%", borderRadius: "5px", height: "55px", backgroundColor: " #FFFFFF" }} >

                      <div className='col-1' key={key} >
                        <p style={{ color: "#434343", fontWeight: "400", fontSize: "15px" }}>{res.sn}</p>
                      </div>

                      <div className='col-2'>
                        <p style={{ fontWeight: "400", fontSize: "15px" }}>{res.name}</p>
                      </div>

                      <div className='col-1'>
                        <p style={{ fontSize: "15px", color: "#3C3C3C", fontWeight: "500" }} >{res.amount}</p>
                      </div>

                      <div className='col-2'>
                        <p style={{ fontSize: "15px", color: "#434343", fontWeight: "400" }} >{res.phone}</p>
                      </div>

                      {/* <div className='col-2'>
<p style={{fontSize:"14px",color:"#434343",fontWeight:"400"}} >{res.transaction}</p>
</div> */}


                      {/* <div className='col-2'>
<p style={{fontSize:"14px",color:"#434343",fontWeight:"400"}} >{res.payment}</p>
</div> */}
                      <div className='col-2'>
                        <p style={{ fontSize: "15px", fontWeight: "600", color: res.status === 0 ? "#40997E" : res.status == 1 ? "#f93333" : "#e2a925", }} >{res.demo}</p>
                      </div>
                      <div className='col-2'>
                        <p style={{ fontSize: "15px", color: "#434343", fontWeight: "400" }} >{res.datetime}</p>
                      </div>

                      <div className='col-2'>
                        <button type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{
                          height: "30px", fontSize: "15px", color: "#8147E7", fontWeight: "400", backgroundColor: "#F2EAFF",
                          width: "130px", borderRadius: "5px", border: "none"
                        }} ><AiOutlineEye style={{ fontSize: "20px" }} />&nbsp;&nbsp;{res.view}</button>
                      </div>

                    </div>


                  </>
                )
              }):<>
              <div className="d-flex mt-5" style={{justifyContent:"center",color:"red",alignItems:"center"}}><h6>No Data Available</h6></div>
           </>}
            </div>

          </div>
        </div>
      </div>

      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ width: "45%" }}>
        <div class="offcanvas-header">

          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        {/* <p className='px-3 pt-1' style={{backgroundColor:"#F0F0F0",width:"35%",marginLeft:'60%',borderRadius:"5px",height:"35px"}}>Invoice no.: #03032023100</p> */}
        <div class="offcanvas-body">
          <img src={TIPG} style={{ width: "148px" }} />
          <p className='mt-4' style={{ color: "#5A5A5A", fontWeight: "400" }}>Redwood High School</p>

          <div className='row mt-4'>
            <div className='col-3'>
              <div className=''>
                {/* <button className='text-center pt-1 ' style={{borderRadius:"5px",color:"#5A5A5A" ,width:"190px",height:"45px",
    backgroundColor:"transparent",border:"1px solid #000000",fontWeight:"400"}}><FiDownload/>&nbsp; &nbsp;Download Receipt</button> */}
                <div class='file' style={{ height: "45px" }}>

                  <label for='input-file' style={{ height: "45px", width: "190px" }} >
                    <FiDownload style={{ color: "#BDBDBD" }} />&nbsp;&nbsp;<span style={{ color: "#BDBDBD" }}>Download Receipt</span>
                  </label>
                  <input id='input-file' type='file' />
                </div>
              </div>
            </div>

            <div className='col-3'>
              <div className='ms-5' >
                <button className='text-center pt-1 ' style={{
                  borderRadius: "5px", color: "#FF4A4A", width: "140px", height: "45px",
                  backgroundColor: "transparent", border: "1px solid #FF4A4A"
                }}>Delete</button>

              </div>
            </div>

            <div className='col-3'>
              <div className='ms-5' >
                <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example" style={{ width: "160px", backgroundColor: "#EEEEEE" }}>
                  <option selected>Status</option>
                  <option value="1">Debited</option>
                  <option value="2">Not debited</option>
                  <option value="3">Portial Debit</option>
                </select>
              </div>

            </div>
          </div>

          <div className='mt-3' style={{ display: "flex" }}>
            <p style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "600" }}>Name :</p>
            <p className='px-2' style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "400" }}>Tushar Singh</p>
          </div>

          <div className='mt-3' style={{ display: "flex" }}>
            <p style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "600" }}>Mobile No.:</p>
            <p className='px-2' style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "400" }}>+91 9845632105</p>
          </div>

          <div className='mt-3' style={{ display: "flex" }}>
            <p style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "600" }}>Date Of Payment:</p>
            <p className='px-2' style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "400" }}>3 April 2023</p>
          </div>

          <div className='mt-3' style={{ display: "flex" }}>
            <p style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "600" }}>Mode Of Payment:</p>
            <p className='px-2' style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "400" }}>UPI</p>
          </div>

          <div className='row  ms-1 mt-4 pt-2' style={{ backgroundColor: "#E6EDFF", width: "84%", height: "40px" }}>
            <div className='col-3'>
              <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}>S.No</p>
            </div>
            <div className='col-3'>
              <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}>Particulars</p>
            </div>
            <div className='col-3'>
              <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}>QTY</p>
            </div>
            <div className='col-3'>
              <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}>Amount</p>
            </div>
          </div>
          {dd.map((res, key) => {
            return (
              <>
                <div className='row ms-1 mt-2 ' style={{ width: "84%" }}>
                  <div className='col-3'>
                    <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}>{res.sn}</p>
                  </div>
                  <div className='col-3'>
                    <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}> {res.part}</p>
                  </div>
                  <div className='col-3'>
                    <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}> {res.qty}</p>
                  </div>
                  <div className='col-3'>

                    <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}> {res.amount}</p>
                  </div>
                  <p className='ms-1' style={{ border: " 1px solid #E8E8E8", width: "100%" }}></p>
                </div>
              </>
            )
          })}


          <div className='row mt-5'>
            <div className='col-6 text-center'>
              <div className='pt-2' style={{ border: "1px solid #E8E8E8", width: "150px", height: "80px", borderRadius: "5px", }}>
                <p style={{ color: "#5A5A5A", fontWeight: "400" }}>Grand Total</p>
                <p style={{ color: "#5A5A5A", fontWeight: "400", fontSize: "25px" }}>₹ 0</p>
              </div>
            </div>

            <div className='col-6 text-center'>
              <button className='mt-4' data-bs-dismiss="offcanvas" aria-label="Close" style={{
                width: "160px", height: "40px",
                backgroundColor: "#8147E7", border: "none", color: "#fff", borderRadius: "5px"
              }}>Back</button>
            </div>
          </div>



        </div>
      </div>


    </>
  )
}