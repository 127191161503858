import React from "react";
import { FaCalendar, } from "react-icons/fa";
import { BiTimeFive, } from "react-icons/bi";

export default function Classes() {
    const cardd = [
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
    ]

    return (
        <>
            <div className="row mt-4">
                <div className="col-2">
                    <p style={{ paddingLeft: "30px", fontSize: "14px" }}>Total Classes: 0</p>
                </div>
                <div className="col-1">
                    <p style={{ fontSize: "14px", color: "#3EDC4E" }}>Present : 0</p>
                </div>

                <div className="col-2">
                    <p style={{ paddingRight: "-10px", fontSize: "14px", color: "red" }}>Absent : 0</p>
                </div>


            </div>

            <div className="row" style={{ overflowY: "scroll", height: "50vh", marginLeft: "30px" }} >
                {/* map */}

                {/* {cardd.map((res, key) => {
                    return ( */}

                        <div className="col-3 mt-4"  style={{}}>
                           { /*<div class="card" style={{ width: "90%", background: "#FFFFFF", border: "1px solid #E6E6E6", borderRadius: "5px", }} >
                                <div className="card-body">

                                    <h6 className="" style={{ fontStyle: "normal", fontWeight: "600", fontSize: "18px", color: "#000000", }}>How to Make an Array and it’s Types in C++</h6>
                                    <p className="mt-2 ps-2" style={{ padding: "3px", color: "#989898", fontSize: "15px", border: "1px solid #D3D3D3", width: "55%", borderRadius: "5px" }}>Batch 3CO - JVY</p>
                                    <div className="mt-3" style={{ display: "flex", }}>
                                        <p style={{ fontSize: "12px", color: "#989898" }}> <FaCalendar />&nbsp;&nbsp;17-03-2023</p>
                                        <p className="mx-4" style={{ fontSize: "12px", color: "#989898" }}><BiTimeFive /> &nbsp;&nbsp;&nbsp; 12:23 PM - 01:50 AM</p>
                                    </div>
                                     <div style={{display:"flex"}}>
                 <p  style={{color:"#3C3C3C",fontSize:"15px"}}>Passing Percentage </p>
                 <p className="mx-3">70%</p>
                 </div>
                 <div style={{display:"flex"}}>
                 <p  style={{color:"#3C3C3C",fontSize:"15px"}}>Scored Percentage </p>
                 <p className="mx-4">10%</p>
                 </div> 

                                    <p className="mt-3" style={{ backgroundColor: "#FFDDDD", width: "150px", padding: "6px", color: "#F12222", borderRadius: "5px" }}>Status : Absent</p>
                                </div>
                            </div>*/}

                        
                {  <div class="card" style={{ width: "90%", background: "#FFFFFF", border: "1px solid #E6E6E6", borderRadius: "5px", }} >
                <div className="card-body">
                    <p className='text-center p-5' style={{color:"red"}}>The data will be visible after creating classes</p>
                </div>
            </div>}
                        </div>
{/* 
                    )
                })} */}

            </div>
        </>
    )
}