import React, { useContext, useEffect, useState } from 'react';
import Header from './header';
import SideBar from './sidebar';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsFillCalendarFill } from "react-icons/bs";
import { IoNewspaperOutline } from "react-icons/io5";
import { NavLink } from 'react-router-dom';
import EE from '../images/mdi_external-link.png';
import helo from '../images/Rectangle 5.png';
import fb from '../images/logos_facebook.png';
import ins from '../images/skill-icons_instagram.png';
import li from '../images/logos_linkedin-icon.png';
import go from '../images/logos_google-icon.png';
import you from '../images/logos_youtube-icon.png';
import twi from '../images/devicon_twitter.png';
import web from '../images/mdi_web.png';
import game from '../images/game-icons_vertical-banner.png';
import hero from '../images/heroicons_newspaper.png';
import park from '../images/icon-park-outline_other.png';
import material from '../images/material-symbols_door-open-outline.png';
import majesticons from '../images/majesticons_paper-fold-text-line.png';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { IoArrowBack } from "react-icons/io5";
import { IoPersonSharp } from "react-icons/io5";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { HiPhone } from 'react-icons/hi';
import { AuthContext } from './AuthContext';
import { API_URL } from '../env';

export default function Leads() {
  const [data_type1, setData_Type1] = useState([]);
  const [data_type0, setData_Type0] = useState([]);
  const [data_type2, setData_Type2] = useState([]);
  const [data_type3, setData_Type3] = useState([]);
  const [data_type4, setData_Type4] = useState([]);
  const [sourcseLowest1, setSourcseLowest1] = useState();
  const [sourchHigh1, setSourchHigh1] = useState();
  const [highest1, setHighest1] = useState();
  const [Lowest1, setLowest1] = useState();
  const [sourcseLowest0, setSourcseLowest0] = useState();
  const [sourchHigh0, setSourchHigh0] = useState();
  const [highest0, setHighest0] = useState();
  const [Lowest0, setLowest0] = useState();
  const [total0, setTotal0] = useState();
  const [total1, setTotal1] = useState();

  const { apiToken } = useContext(AuthContext);


  const Graph = async (x) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);



    var requestOptions = {
      method: 'GET',
      headers: myHeaders,

      redirect: 'follow'
    };

    fetch(`${API_URL}/leads/getAllLeads-graph?type=${x}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        if (x === 'leads') {
          setData_Type1([result.data])
          setLowest1(result.lowest);
          setTotal1(result.total);
          setHighest1(result.highest);
          setSourchHigh1(result.highestSource);
          setSourcseLowest1(result.lowestSource);
        } else {
          setData_Type0([result.data])
          setTotal0(result.total)
          setLowest0(result.lowest);
          setHighest0(result.highest);
          setSourchHigh0(result.highestSource);
          setSourcseLowest0(result.lowestSource);
        }

      })
      .catch(error => console.log('error', error));
  }


  const Leads = async (x) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/leads/get-leads?type=${x}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (x) {

        }
      })
      .catch(error => console.log('error', error));
  }





  // console.log('fees', Lowest1, sourchHigh1, sourcseLowest1, highest1, highest0);

  const [value, setValue] = React.useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const [count1, setcount1] = useState(1)
  const [Num1, setNum1] = useState();
  const [Number, setNumber] = useState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const now = 90;
  const now1 = 85;
  const now2 = 75;
  const now3 = 65;
  const now4 = 55;
  const now5 = 45;
  const now6 = 35;
  const now7 = 25;
  const now8 = 15;
  const now9 = 10;
  const now10 = 5;
  const now11 = 3;
  const now12 = 2;

  const RecentLeads = [


    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      img: require("../images/Group 13502.png"),
      neet: " NEET-English",
    },
    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      img: require("../images/Group 13502.png"),
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      img: require("../images/Group 13502.png"),
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      img: require("../images/Group 13502.png"),
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      img: require("../images/Group 13502.png"),
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      img: require("../images/Group 13502.png"),
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      img: require("../images/Group 13502.png"),
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      img: require("../images/Group 13502.png"),
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      img: require("../images/Group 13502.png"),
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      img: require("../images/Group 13502.png"),
      neet: " NEET-English",
    },

  ]
  const Conversation = [


    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      Conversation: "Manish Sharma",
      neet: " NEET-English",
    },
    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      Conversation: "Manish Sharma",
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      Conversation: "Manish Sharma",
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      Conversation: "Manish Sharma",
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      Conversation: "Manish Sharma",
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      Conversation: "Manish Sharma",
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      Conversation: "Manish Sharma",
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      Conversation: "Manish Sharma",
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      Conversation: "Manish Sharma",
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      Conversation: "Manish Sharma",
      neet: " NEET-English",
    },

  ]
  const LeadsDropped = [


    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      dropped: "Manish Sharma",
      neet: " NEET-English",
    },
    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      dropped: "Manish Sharma",
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      dropped: "Manish Sharma",
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      dropped: "Manish Sharma",
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      dropped: "Manish Sharma",
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      dropped: "Manish Sharma",
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      dropped: "Manish Sharma",
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      dropped: "Manish Sharma",
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      dropped: "Manish Sharma",
      neet: " NEET-English",
    },

    {
      name: "Pooran",
      number: "7062398263",
      dob: "17/05/1997",
      dropped: "Manish Sharma",
      neet: " NEET-English",
    },

  ]

  let readings = [
    {
      value: 70.4,
      backgroundcolor: '#6ADBB9',
      textcolor: '#40997E'
    },
    {
      value: 10,
      backgroundcolor: '#F1B34C',
      textcolor: '#F1B34C'
    },
    {
      value: 12,
      backgroundcolor: '#F03838',
      textcolor: '#F03838'
    },
    {
      value: 5,
      backgroundcolor: '#6E6B97',
      textcolor: '#6E6B97'
    }
  ]
  const [bgcolor, setbgcolor] = useState(readings);


  useEffect(() => {
    Graph('leads');
    Graph('converted');
    Leads('all')
    Leads('conversation')
  }, [])
  return (
    <div style={{ width: '100%', height: '100vh', background: "", }}>
      <div className='head' style={{ width: '80%', height: '14vh', marginLeft: '16%' }}>
        <Header />
      </div>
      <div style={{ width: '100%', height: '86vh', background: "#fff", }}>
        <div style={{ position: 'absolute', top: 0, marginLeft: '1%' }} >
          <SideBar />
        </div>
        <div className='container mt-2' style={{ marginLeft: "14rem", background: "", }}>
          <div className='d-flex'>
            <div className='row mt-1 px-4'>
              <div className='col-2 '>
                <p className='' style={{ marginTop: "2.4rem", fontStyle: "normal", fontWeight: "500", fontSize: "20px", color: "#3C3C3C", }}>Leads</p>
              </div>
              <div className='col-3 mb-2' >
                <div className=''>
                  <p className='pt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#8A8A8A", padding: "14px", }} >From</p>
                  <span style={{
                    display: 'flex', backgroundColor: '#EDEDF5', marginLeft: "10px",
                    borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
                  }}>
                    <BsFillCalendarFill className='ms-1' style={{ fontSize: '30px', color: "#3C3C3C", marginTop: "3px", }} />
                    <div>
                      <DatePicker className="border-0  datep"
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                    </div>
                  </span>

                </div>
              </div>

              <div className='col-4'  >
                <div>
                  <p className='pt-3 ps-3' style={{
                    fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#8A8A8A", padding: "14px", marginLeft: "58px",
                  }}>to</p>
                  <span style={{
                    display: 'flex', backgroundColor: '#EDEDF5', paddingLeft: '0', marginLeft: "66px",
                    borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
                  }}>
                    <BsFillCalendarFill className='ms-1' style={{ fontSize: '30px', color: "#3C3C3C", marginTop: "3px", }} />
                    <div>
                      <DatePicker className="border-0  datep"
                        showIcon
                        selected={startDate1}
                        onChange={(date) => setStartDate1(date)}
                        style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                    </div>
                  </span>
                </div>
              </div>

            </div>
            <div className='mt-4' style={{ marginLeft: "32rem", }}>
              <NavLink to="/Myleads"><button type="button" class="btn " style={{ background: "#EDE6FF", borderRadius: "5px", fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#8147E7", }}>My Leads</button></NavLink>
              <button type="button" class="btn " data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ background: "#8147E7", borderRadius: "5px", fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#fff", marginLeft: "15px", }}>+Add Lead</button>
            </div>
          </div>
          {/* <!Add Lead > */}
          <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                {/* <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> */}
                <div class="modal-body">
                  <div className='text-center'>
                    <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "25px", color: "#474747", }}>Cancel Adding the Inquiry ?</p>
                    <p className='mt-4' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#474747", }}>This action can not be reversed & all<br></br> the progress will be lost</p>
                  </div>
                  <div className='row mb-3 px-2 mt-5'>
                    <div className='col-6'>
                      <button type="button" class="btn" data-bs-dismiss="modal" style={{ width: "100%", background: "#CC1313", boxShadow: "0px 2px 4px rgba(61, 112, 245, 0.25)", borderRadius: "10px", border: "none", color: "#fff", padding: "10px 10px", }}>Yes, Cancel Process</button>
                    </div>
                    <div className='col-6'>
                      <button type="button" class="btn " style={{ width: "100%", background: "#2188E7", boxShadow: "0px 2px 4px rgba(61, 112, 245, 0.25)", borderRadius: "10px", border: "none", color: "#fff", padding: "10px 10px", }}>No, Continue Adding</button>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
          {/* <!-- Modal --> */}
          <div class="modal fade" id="exampleModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ background: "#F0F5FE", }}>
            <div class="modal-dialog" >
              <div className='' style={{ marginTop: "6rem", }}>
                <p style={{ color: "#2188E7", fontSize: "20px", }}><IoArrowBack />Back</p>
              </div>


              <div style={{ display: count1 === 1 ? "block" : "none" }}>
                <div class="modal-content" style={{ border: "none", }}>
                  <div class="modal-header" style={{ border: "none", }}>
                    <h5 class="modal-title" id="exampleModalLabel">Inquiry Form</h5>

                    <button type="button" class="btn-close" data-bs-toggle="modal" data-bs-target="#staticBackdrop" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <form>

                      <input type="text" placeholder='Enter your name' style={{ border: "1px solid #DEDEDE", borderRadius: "5px", padding: "10px", width: "100%", }} />

                      <input type="text" className='mt-2' placeholder='Enter your Father’s or Guardian’s name' style={{ border: "1px solid #DEDEDE", borderRadius: "5px", padding: "10px", width: "100%", }} />
                      <div className="row mt-3">
                        <div className="col-6 " >
                          <div class="input-group">
                            <div class="input-group-text" style={{ color: "#AFAFAF" }}><HiPhone /></div>
                            <input type="number" class="form-control" id="inlineFormInputGroupUsername"
                              placeholder="Contact Number" style={{ border: "1px solid #E9E9E9", padding: "12px" }} value={Number} onChange={(e) => { setNumber(e.target.value) }} />
                          </div>
                        </div>

                        <div className="col-6 " style={{}}>
                          <div class="input-group">
                            <div class="input-group-text" style={{ color: "#AFAFAF" }}><HiPhone /></div>
                            <input type="number" class="form-control" id="inlineFormInputGroupUsername"
                              placeholder="Emergency Contact Number" style={{ border: "1px solid #E9E9E9", padding: "12px" }}
                              value={Num1} onChange={(e) => { setNum1(e.target.value) }}
                            />
                          </div>
                        </div>
                      </div>

                      <input type="text" className='mt-2' placeholder='Alternate Contact Number' style={{ border: "1px solid #DEDEDE", borderRadius: "5px", padding: "10px", width: "100%", }} />


                      <div className='row'>
                        <div className='col-3'>
                          <input type="text" className='mt-2' placeholder='Pincode' style={{ border: "1px solid #DEDEDE", borderRadius: "5px", padding: "10px", width: "100%", }} />
                        </div>
                        <div className='col-3'>
                          <input type="text" className='mt-2' placeholder='District' style={{ border: "1px solid #DEDEDE", borderRadius: "5px", padding: "10px", width: "100%", }} />
                        </div>
                        <div className='col-3'>
                          <input type="text" className='mt-2' placeholder='State' style={{ border: "1px solid #DEDEDE", borderRadius: "5px", padding: "10px", width: "100%", }} />
                        </div>
                      </div>
                      <select name="selectList" id="selectList" style={{ marginTop: "15px", padding: "10px", border: "1px solid #E6E6E6", borderRadius: "5px", width: "100%", color: "#989898", }}>
                        <option value="Course Interested">Course Interested</option>
                        <option value="Course Interested">Course Interested</option>
                      </select>

                      <select name="selectList" id="selectList" style={{ marginTop: "15px", padding: "10px", width: "100%", border: "1px solid #E6E6E6", borderRadius: "5px", color: "#989898", }}>
                        <option value="Source of Information">Source of Information</option>
                        <option value="Source of Information">Source of Information</option>
                      </select>

                      <button type="button" class="btn btn-primary mt-4" style={{ width: "100%", padding: "10px", background: "#8147E7", borderRadius: "5px", }} onClick={() => { setcount1(count1 + 1) }}>Submit</button>
                    </form>
                  </div>

                </div>
              </div>



              <div style={{ display: count1 === 2 ? "block" : "none" }}>
                <div class="modal-content" style={{ border: "none", }}>
                  <div class="modal-header" style={{ border: "none", }}>
                    <h5 class="modal-title" id="exampleModalLabel">Assign Inquiry</h5>

                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <form>



                      <label className='mt-2'>Select Department</label>
                      <select name="selectList" id="selectList" style={{ padding: "10px", border: "1px solid #E6E6E6", borderRadius: "5px", width: "100%", color: "#989898", }}>
                        <option value="Accounts">Accounts</option>
                        <option value="Accounts">Accounts</option>
                      </select>
                      <label className='mt-4'>Select Role</label>
                      <select name="selectList" id="selectList" style={{ padding: "10px", width: "100%", border: "1px solid #E6E6E6", borderRadius: "5px", color: "#989898", }}>
                        <option value="Senior Human Resource Manager">Senior Human Resource Manager</option>
                        <option value="Senior Human Resource Manager">Senior Human Resource Manager</option>
                      </select>


                      <label className='mt-4'>Select Employee</label>
                      <select name="selectList" id="selectList" style={{ padding: "10px", width: "100%", border: "1px solid #E6E6E6", borderRadius: "5px", color: "#989898", }}>
                        <option value="Anil Kumble">Anil Kumble</option>
                        <option value="Anil Kumble">Anil Kumble</option>
                      </select>

                      <button type="button" class="btn btn-primary mt-4" style={{ width: "100%", padding: "10px", background: "#8147E7", borderRadius: "5px", }} onClick={() => { setcount1(count1 + 1) }}>Assign Inquiry</button>
                    </form>
                  </div>

                </div>
              </div>



              <div style={{ display: count1 === 3 ? "block" : "none" }}>
                <div class="modal-content" style={{ border: "none", }}>

                  <div class="modal-body">

                    <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "32px", display: "flex", alignItems: "center", textAlign: "center", color: "#3C3C3C", }}>New Inquiry Assigned Successfully to</p>

                    <div className='d-flex mt-5 mb-3' style={{ marginLeft: "8rem", }}>
                      <img src={helo} style={{ width: "16%", height: "4rem" }} />
                      <p className='mt-2' style={{ marginLeft: "15px", fontStyle: "normal", fontWeight: "400", fontSize: "30px", color: "#434343", }}>Anil Kumble</p>
                    </div>


                    <button type="button" class="btn btn-primary mt-4" data-bs-dismiss="modal" aria-label="Close" style={{ width: "100%", padding: "10px", background: "#8147E7", borderRadius: "5px", }}>Back to Leads</button>

                  </div>

                </div>
              </div>

            </div>
          </div>

          {/* <!--Lead Details modal--> */}
          <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ width: "50%", }}>
            <div class="offcanvas-header">

              <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              <h5 id="offcanvasRightLabel" style={{ fontStyle: "normal", fontWeight: "600", fontSize: "25px", color: "#434343", }}>Lead Details</h5>
              <h5 id="offcanvasRightLabel"><button type="button" class="btn " style={{ background: "#FFF4EC", borderRadius: "5px", border: "none", color: "#DC9A00", padding: "10px 30px", marginLeft: "8rem", }}>Drop Lead</button></h5>
              <h5 id="offcanvasRightLabel"><button type="button" class="btn" style={{ background: "#EADDFF", borderRadius: "5px", border: "none", color: "#8147E7", padding: "10px 15px", }}>Re-Assign Lead</button></h5>

              {/* <h5 id="offcanvasRightLabel"><button type="button" class="btn" style={{background:"#8147E7",borderRadius:"5px",border:"none",color:"#FFFFFF",padding:"10px 16px",}}>Convert Lead</button></h5> */}
            </div>
            <div class="offcanvas-body">
              <div className=''>
                <p><span><IoPersonSharp style={{ fontSize: "1.5rem", }} /></span> Shivangi Malhotra</p>
                <p className='mt-3'><span><IoCalendarNumberOutline style={{ fontSize: "1.5rem", }} /></span> 12 April 2023</p>
                <p className='mt-3'> Source <span style={{ color: "#1877F2", }}>Facebook <img src={fb} style={{ width: "3%", }} /></span></p>
                <p className='mt-3'>Course</p>
                <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#3C3C3C", }}>NEET-English Medium</p>

                <p className='mt-3'>Guardian’s Name</p>
                <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#3C3C3C", }}>Ishwar Singh Malhotra</p>
                <div className='row mt-4'>
                  <div className='col-4'>
                    <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#3C3C3C", }}>Contact Number</p>
                    <p className='mt-4' style={{ border: "1px solid #808080", padding: "10px", borderRadius: "5px", marginTop: "10px !important", }}><HiPhone /> +91 78998 78998</p>
                  </div>
                  <div className='col-4'>
                    <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#3C3C3C", }}>Alternate Contact Number</p>
                    <p className='mt-4' style={{ border: "1px solid #808080", padding: "10px", borderRadius: "5px", marginTop: "10px !important", }}><HiPhone /> +91 78998 78998</p>
                  </div>
                </div>
                <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Assigned to</p>
                <div className='mt-4 d-flex'>
                  <img src={helo} style={{ width: "5%", }} />
                  <p className='mt-1' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#3C3C3C", marginLeft: "10px", }}>Manish Jangir</p>
                </div>

                <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "500", fontSize: "20px", color: "#434343", }}>Remarks History</p>
                <div style={{ overflowY: "scroll", height: "40vh", }}>
                  <div className='card' style={{ background: "#F0F5FE", borderRadius: "5px", border: "none", }}>
                    <p className='mt-3 mx-3' style={{ background: "#1877F2", borderRadius: "5px", width: "30%", padding: "10px", color: "#fff", }}> Call was not picked up</p>
                    <p className='mx-3 mt-1' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#8A8A8A", }}>12:30 | 30 April 2023</p>
                    <p className='mt-2 mx-3' style={{ background: "#1877F2", borderRadius: "5px", width: "30%", padding: "10px", color: "#fff", }}> Call was not picked up</p>
                    <p className='mx-3 mt-1' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#8A8A8A", }}>12:30 | 30 April 2023</p>
                    <p className='mt-2 mx-3' style={{ background: "#1877F2", borderRadius: "5px", width: "30%", padding: "10px", color: "#fff", }}> Call was not picked up</p>
                    <p className='mx-3 mt-1' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#8A8A8A", }}>12:30 | 30 April 2023</p>
                    <p className='mt-2 mx-3' style={{ background: "#1877F2", borderRadius: "5px", width: "30%", padding: "10px", color: "#fff", }}> Call was not picked up</p>
                    <p className='mx-3 mt-1' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#8A8A8A", }}>12:30 | 30 April 2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--Lead Details Modal End--> */}

          <div className='row mt-3 px-4' style={{ overflowY: "scroll", height: "66vh", }}>
            <div className='col-4' >
              <div className='' >
                <div className='card px-2' style={{ background: "#fff", borderRadius: "10px", border: "none", }}>
                  <div className='row'>
                    <div className='col-8 mt-2  '>
                      <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "18px", color: "#3C3C3C", }}>Total Leads : {total1}</p>
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C", }}>Highest Source of Lead Generation</p>
                    </div>
                    <div className='col-2'></div>
                    <div className='col-2 mt-2'>
                      <p data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ background: "#8147E7", borderRadius: "5px", padding: "2px 2px 2px 16px", color: "#fff", fontSize: "20px", cursor: "pointer", }}>+</p>
                    </div>
                  </div>

                  <div className='mt-3' style={{ overflowY: "scroll", height: "30vh", }}>

                    <div className='row'>
                      <div className='col-2' style={{ paddingRight: "1px !important", }}>
                        <img src={fb} style={{ width: "55%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar className='mt-2' now={now} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>




                    <div className='row mt-2'>
                      <div className='col-2' style={{ paddingRight: "1px !important", }}>
                        <img src={ins} style={{ width: "55%", float: "right", }} />
                      </div>
                      <div className='col-10' style={{ borderRadius: "10px", }}>
                        <ProgressBar now={now1} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>



                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={li} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now2} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>



                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={go} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now3} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>


                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={you} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now4} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>



                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={twi} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now5} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>


                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={web} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now6} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>


                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={twi} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now7} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>


                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={game} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now8} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>

                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={hero} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now9} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>



                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={park} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now10} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>



                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={material} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now11} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>



                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={majesticons} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now12} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>


                  </div>
                  <div className='row mt-1'>
                    <div className='col-6'>
                      <p className='px-2' style={{ fontStyle: "normal", fontWeight: "500", fontSize: "14px", color: "#3C3C3C", }}>Highest</p>
                      <div className='d-flex '>
                        <div>
                          <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>{sourchHigh1}</p>
                        </div>
                        <div>
                          <img src={fb} style={{ width: "50%", marginLeft: "10px", }} />
                        </div>
                        <div>
                          <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#1877F2", }}>{highest1} Leads</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-6'>
                      <p className='px-2' style={{ fontStyle: "normal", fontWeight: "500", fontSize: "14px", color: "#3C3C3C", }}>Lowest</p>
                      <div className='d-flex mb-2'>
                        <div>
                          <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>{sourcseLowest1}</p>
                        </div>
                        <div>
                          <img src={hero} style={{ width: "50%", marginLeft: "10px", }} />
                        </div>
                        <div>
                          <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#505050", }}>{Lowest1} Leads</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className=''>
                <div className='card px-2 mt-2' style={{ background: "#fff", borderRadius: "10px", border: "none", }}>
                  <div className='row'>
                    <div className='col-8 mt-2  '>
                      <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "20px", color: "#3C3C3C", }}>Leads Converted : {total0}</p>
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>Highest Source of Lead Conversion</p>
                    </div>
                    <div className='col-2'></div>
                  </div>

                  <div className='mt-3' style={{ overflowY: "scroll", height: "32vh", }}>

                    <div className='row'>
                      <div className='col-2' style={{ paddingRight: "1px !important", }}>
                        <img src={fb} style={{ width: "65%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar className='mt-2' now={now} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>


                    <div className='row mt-2'>
                      <div className='col-2' style={{ paddingRight: "1px !important", }}>
                        <img src={ins} style={{ width: "65%", float: "right", }} />
                      </div>
                      <div className='col-10' style={{ borderRadius: "10px", }}>
                        <ProgressBar now={now1} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>



                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={li} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now2} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>



                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={go} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now3} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>


                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={you} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now4} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>



                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={twi} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now5} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>


                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={web} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now6} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>


                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={twi} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now7} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>


                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={game} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now8} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>

                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={hero} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now9} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>



                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={park} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now10} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>



                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={material} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now11} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>



                    <div className='row mt-2'>
                      <div className='col-2'>
                        <img src={majesticons} style={{ width: "50%", float: "right", }} />
                      </div>
                      <div className='col-10'>
                        <ProgressBar now={now12} style={{ backgroundColor: "#fff", }} />
                      </div>
                    </div>


                  </div>

                  <div className='row mt-1'>
                    <div className='col-6'>
                      <p className='px-2' style={{ fontStyle: "normal", fontWeight: "500", fontSize: "14px", color: "#3C3C3C", }}>Highest</p>
                      <div className='d-flex '>
                        <div>
                          <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>{sourchHigh0}</p>
                        </div>
                        <div>
                          <img src={you} style={{ width: "50%", marginLeft: "10px", }} />
                        </div>
                        <div>
                          <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "red", }}>{highest0} Leads</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-6'>
                      <p className='px-2' style={{ fontStyle: "normal", fontWeight: "500", fontSize: "14px", color: "#3C3C3C", }}>Lowest</p>
                      <div className='d-flex mb-2'>
                        <div>
                          <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>{sourcseLowest0}</p>
                        </div>
                        <div>
                          <img src={hero} style={{ width: "50%", marginLeft: "10px", }} />
                        </div>
                        <div>
                          <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#505050", }}>{Lowest0} Leads</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='' style={{ overflowY: "scroll", height: "40vh", }}>
                <div className='card px-2 mt-2' style={{ background: "#fff", borderRadius: "10px", border: "none", }}>
                  <div className='row'>
                    <div className='col-8 mt-2  '>
                      <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "20px", color: "#3C3C3C", }}>Total Leads : 928</p>
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>Highest Source of Lead Generation</p>
                    </div>
                    <div className='col-2'></div>
                    {/* <div className='col-2 mt-2'>
            <p style={{background:"#8147E7",borderRadius:"5px",padding:"3px 6px 6px 16px",color:"#fff",fontSize:"20px",}}>+</p>
          </div> */}
                  </div>
                  <div className='row pt-2 px-2 x' style={{ width: '100%' }}>
                    {
                      bgcolor.map((item, index) => {
                        return (
                          <Bar key={index} item={item} />
                        )
                      })
                    }
                    <div className='mt-2'>
                      <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "18px", color: "#575757", }}>Total Leads : 6579</p>
                    </div>
                    <div className='row'>
                      <div className='col-6' >
                        <div className='' style={{ background: "#E6FFF7", borderRadius: "10px", padding: "10px 4px", }}>
                          <NavLink to="/TotalLeads" style={{ textDecoration: "none", }}><p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "13px", color: "#40997E", }}>Converted Leads <span>650</span></p></NavLink>
                        </div>
                      </div>
                      <div className='col-6' >
                        <div className='' style={{ background: "#FFFBE6", borderRadius: "10px", padding: "10px 4px", }}>
                          <NavLink to="/TotalLeads" style={{ textDecoration: "none", }}><p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "13px", color: "#40997E", }}>Assigned Leads <span>650</span></p></NavLink>
                        </div>
                      </div>
                    </div>


                    <div className='row mt-2 mb-2'>
                      <div className='col-6' >
                        <div className='' style={{ background: "#F2F2F2", borderRadius: "10px", padding: "10px 4px", }}>
                          <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "12px", color: "#40997E", }}>Unassigned Leads <span>650</span></p>
                        </div>
                      </div>
                      <div className='col-6' >
                        <div className='' style={{ background: "#FFEAEA", borderRadius: "10px", padding: "10px 4px", }}>
                          <NavLink to="/TotalLeads" style={{ textDecoration: "none", }}><p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "13px", color: "#40997E", }}>Dropped Leads <span>650</span></p></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='card' style={{ border: "none", }}>
                <div className='px-3 mt-1 mb-1'>
                  <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#8A8A8A", }}> Highest Conversion</p>
                  <div className='d-flex mt-1' style={{ border: "1px solid #D3D3D3", padding: "10px", width: "80%", borderRadius: "8px", }}>
                    <img src={helo} style={{ width: "10%", }} />
                    <p style={{ marginLeft: "15px", fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", }}>Pooran Yadav</p>
                    <p style={{ marginLeft: "3rem", background: "#E6FFF7", borderRadius: "5px", padding: "3px 8px", color: "#40997E", }}>565</p>
                  </div>
                </div>
                <div className='px-3 mt-1 mb-1'>
                  <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#8A8A8A", }}> Lowest Conversion</p>
                  <div className='d-flex mt-1' style={{ border: "1px solid #D3D3D3", padding: "10px", width: "100%", borderRadius: "8px", }}>
                    <img src={helo} style={{ width: "10%", }} />
                    <p style={{ marginLeft: "15px", fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", }}>Virendra Singh Nathawwat</p>
                    <p style={{ marginLeft: "3rem", background: "#FFE6E6", borderRadius: "5px", padding: "3px 8px", color: "#F93333", }}>35</p>
                  </div>
                  <div className='text-center'>
                    <button type="button" class="btn btn-primary" style={{ background: "#8147E7", borderRadius: "5px", fontStyle: "normal", fontWeight: "500", fontSize: "13px", color: "#FFFFFF", width: "100%", padding: "10px", }}>View Details</button>
                  </div>
                </div>
              </div>

            </div>
            <div className='col-8' >
              <div className='card px-3' style={{ background: "#fff", borderRadius: "10px", border: "none", }}>
                <div className='row' style={{ padding: "1px 0px 10px 0px", }}>
                  <div className='col-4'>
                    <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "20px", color: "#3C3C3C", }}>Recent Leads <span> <NavLink to="/TotalLeads"><img src={EE} style={{ background: "#FFFFFF", borderRadius: "10px", width: "9%", }} /></NavLink></span></p>
                  </div>
                  <div className='col-6'></div>
                  <div className='col-2'>
                    <p data-bs-toggle="modal" data-bs-target="#exampleModal" className='mt-1' style={{ background: "#8147E7", borderRadius: "5px", padding: "3px 14px 6px 14px", color: "#fff", fontSize: "20px", width: "36%", float: "right", cursor: "pointer", }}>+</p>
                  </div>

                </div>


                <div className='' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{ overflowY: "scroll", height: "40vh", cursor: "pointer", }}>
                  {RecentLeads.map((res, key) => {
                    return (
                      <>

                        <div className='mt-2' key={key} style={{ background: "#FFFFFF", border: "1px solid #D3D3D3", borderRadius: "5px", padding: "7px", }}>

                          <div className='d-flex'>
                            <div className=''>
                              <p>{res.name}</p>
                            </div>
                            <div className='' style={{ marginLeft: "4.5rem", }}>
                              <p>{res.number}</p>
                            </div>
                            <div className='' style={{ marginLeft: "4.5rem", }}>
                              <p>{res.dob}</p>
                            </div>
                            <div className='' style={{ marginLeft: "4.5rem", }}>
                              <img src={res.img} style={{ width: "50%", }} />
                            </div>
                            <div className='' >
                              <p>{res.neet}</p>
                            </div>
                          </div>
                        </div>

                      </>
                    )
                  })}
                </div>


              </div>

              <div className='card px-3 mt-2' style={{ background: "#fff", borderRadius: "10px", border: "none", }}>
                <div className='row'>
                  <div className='col-5'>
                    <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "20px", color: "#3C3C3C", }}>Leads In Conversation <span> <NavLink to='/TotalLeads' > <img src={EE} style={{ background: "#FFFFFF", borderRadius: "10px", width: "7%", }} /></NavLink></span></p>
                  </div>
                  <div className='col-5'></div>
                  <div className='col-2'>
                    {/* <p className='mt-1' style={{background:"#8147E7",borderRadius:"5px",padding:"3px 14px 6px 14px",color:"#fff",fontSize:"20px",width:"36%",float:"right",}}>+</p> */}
                  </div>

                </div>


                <div className='' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{ overflowY: "scroll", height: "45vh", }}>
                  {Conversation.map((res, key) => {
                    return (
                      <>

                        <div className='mt-2' key={key} style={{ background: "#FFFFFF", border: "1px solid #D3D3D3", borderRadius: "5px", padding: "7px", }}>

                          <div className='d-flex'>
                            <div className=''>
                              <p>{res.name}</p>
                            </div>
                            <div className='' style={{ marginLeft: "5rem", }}>
                              <p>{res.number}</p>
                            </div>
                            <div className='' style={{ marginLeft: "5rem", }}>
                              <p>{res.dob}</p>
                            </div>
                            <div className='' style={{ marginLeft: "5rem", }}>
                              <p style={{ color: "#8147E7", }}>{res.Conversation}</p>
                            </div>
                            <div className='' style={{ marginLeft: "5rem", }}>
                              <p>{res.neet}</p>
                            </div>
                          </div>
                        </div>

                      </>
                    )
                  })}
                </div>


              </div>

              <div className='card px-3 mt-2' style={{ background: "#fff", borderRadius: "10px", border: "none", }}>
                <div className='row'>
                  <div className='col-4'>
                    <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "20px", color: "#3C3C3C", }}>Leads Dropped <span> <NavLink to='/TotalLeads' > <img src={EE} style={{ background: "#FFFFFF", borderRadius: "10px", width: "9%", }} /></NavLink></span></p>
                  </div>
                  <div className='col-6'></div>
                  <div className='col-2'>
                    {/* <p className='mt-1' style={{background:"#8147E7",borderRadius:"5px",padding:"3px 14px 6px 14px",color:"#fff",fontSize:"20px",width:"36%",float:"right",}}>+</p> */}
                  </div>

                </div>


                <div className='' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{ overflowY: "scroll", height: "47vh", }}>
                  {LeadsDropped.map((res, key) => {
                    return (
                      <>

                        <div className='mt-2' key={key} style={{ background: "#FFFFFF", border: "1px solid #D3D3D3", borderRadius: "5px", padding: "7px", }}>

                          <div className='d-flex'>
                            <div className=''>
                              <p>{res.name}</p>
                            </div>
                            <div className='' style={{ marginLeft: "5rem", }}>
                              <p>{res.number}</p>
                            </div>
                            <div className='' style={{ marginLeft: "5rem", }}>
                              <p>{res.dob}</p>
                            </div>
                            <div className='' style={{ marginLeft: "5rem", }}>
                              <p style={{ color: "red", }}>{res.dropped}</p>
                            </div>
                            <div className='' style={{ marginLeft: "5rem", }}>
                              <p>{res.neet}</p>
                            </div>
                          </div>
                        </div>

                      </>
                    )
                  })}
                </div>


              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  )
}

const Bar = (props) => {
  return (
    <div className="value" style={{ 'backgroundColor': props.item.backgroundcolor, 'width': props.item.value + '%' }}>
      <span style={{ color: props.item.textcolor }}>{props.item.value}%</span>
    </div>
  )
}


