import { TripleMaze } from 'react-spinner-animated';

import 'react-spinner-animated/dist/index.css'

function Lode (){

    return (
      <>
      <div className='row' >
          <TripleMaze text={"Loading..."}  
          center={false} width="100%" height="100%"/>
      </div>
      </>
    )

  
};


export default Lode;