import { useState, useContext, useEffect } from "react";
import { AiOutlineEye } from "react-icons/ai";
import DatePicker from "react-datepicker";
import { BsFillCalendarFill } from "react-icons/bs";
import "react-datepicker/dist/react-datepicker.css";
import { FiDownload } from "react-icons/fi";
import { RiFileExcel2Line } from "react-icons/ri";
import { AuthContext } from "./AuthContext";
import { API_URL } from "../env";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as XLSX from "xlsx";
import { ToastContainer, toast } from "react-toastify";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function Allinvoce(props) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dd, setdd] = useState([]);
  const [Base4, setBase4] = useState("");
  const { apiToken, profile } = useContext(AuthContext);
  const { banner } = profile;
  const [Status, setStatus] = useState();

  const GetPreBase4 = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/preferences_get?type=base64`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "001") {
          setBase4(result.logo);
        } else if (result.status == "009") {
          toast(result.message);
        } else {
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getImageDataUrl = async (imageUrl) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const generatePDF = async () => {
    const imageUrl = Base4;

    const imageDataUrl = await getImageDataUrl(imageUrl);

    const docDefinition = {
      content: [
        {
          columns: [
            {
              image: imageDataUrl,
              width: 100,
            },
            [
              {
                text: "Invoice", // Invoice heading
                style: "header",
              },
              {
                text: `Invoice Number : #${dd[0].id}`, // Invoice heading
                style: "headers",
              },
              {
                style: "headers",
                text: `Invoice Date: ${dd[0].emi_date}`,
              },
            ],
          ],
        },

        {
          text: "Customer Details:", // Customer details section
          style: "subheader",
        },
        {
          columns: [
            {
              width: "50%",
              marginBottom: 20,
              text: `Customer Name: ${dd[0].student_name}`,
            },
          ],
        },

        {
          table: {
            headerRows: 1,
            widths: ["*", "auto", "auto", "auto"],
            body: [
              [
                { text: "Description", style: "tableHeader" },
                { text: "Quantity", style: "tableHeader" },
                { text: "Price", style: "tableHeader" },
                { text: "Total", style: "tableHeader" },
              ],

              [dd[0].course_name, 1, dd[0]?.emi_amount, dd[0]?.emi_amount],
            ],
          },
        },
        {
          text: `Total: ${dd[0]?.emi_amount}`, // Total amount section
          alignment: "right",
          marginTop: 20,
        },
        {
          text: "Authorized Signature.............................", // Invoice items section
          style: "bottoms",
        },
      ],
      styles: {
        header: {
          fontSize: 22,
          bold: true,
          alignment: "right",
          margin: [0, 10, 0, 0],
        },
        headers: {
          fontSize: 12,
          bold: true,
          color: "green",
          alignment: "right",
          margin: [0, 0, 0, 0],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
          marginBottom: 20,
        },
        bottoms: {
          fontSize: 14,
          bold: true,
          alignment: "end",
          margin: [0, 100, 0, 5],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
        },
      },
    };

    pdfMake.createPdf(docDefinition).open();
  };

  const convertToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Map the array to include only the desired properties
    const selectiveDataArray = props.Data?.map((data) => ({
      Name: data.student_name,
      Due_Date: data.emi_date,
      Emi_Amount: data.emi_amount,
      Phone_Number: data.phone,
      Status:
        data.status == 1
          ? "Paid"
          : data.status == 0
          ? "unpaid"
          : "partially paid",
    }));

    // Convert the selective data array into a worksheet
    const worksheet = XLSX.utils.json_to_sheet(selectiveDataArray);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a file name
    const fileName = "invoice_data.xlsx";

    // Generate Excel file binary data
    const excelData = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a blob object from the binary data
    const blob = new Blob([excelData], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;

    // Simulate a click to trigger the download
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
  };

  const Udate = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var formdata = new FormData();
    formdata.append("id", id);
    formdata.append("status", Status);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${API_URL}/student/update_student_invoiceStatus`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "001") {
          Api();
          props.stay(true);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const Api = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/student/student_invoice_view?id=${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setdd([result.invoice_view]);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    GetPreBase4();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="card mt-4 ms-4" style={{ width: "95%", border: "none" }}>
        <div className="card-body">
          <div className="row">
            <div className="col-4">
              <p
                style={{
                  fontWeight: "400",
                  color: "#8A8A8A",
                  fontSize: "18px",
                }}
              >
                Date Range
              </p>
              <div className="row mt-1">
                <div className="col-6">
                  <p style={{ color: "#8A8A8A", fontSize: "400" }}>From</p>
                  <div>
                    <span
                      style={{
                        display: "flex",
                        backgroundColor: "#EDEDF5",
                        padding: "",
                        marginLeft: "0px",
                        borderRadius: "5px",
                        color: "#b5b5b5",
                        fontSize: "13px",
                        width: "150px",
                        height: "37px",
                      }}
                    >
                      <BsFillCalendarFill
                        className="ms-2"
                        style={{
                          fontSize: "18px",
                          color: "#3C3C3C",
                          marginTop: "8px",
                        }}
                      />
                      <div>
                        <DatePicker
                          className="border-0 ps-2 pt-1  datep"
  
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          style={{ backgroundColor: "#E0E0E0", outline: "0" }}
                        />
                      </div>
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <p style={{ color: "#8A8A8A", fontSize: "400" }}>To</p>
                  <div>
                    <span
                      style={{
                        display: "flex",
                        backgroundColor: "#EDEDF5",
                        padding: "",
                        marginLeft: "0px",
                        borderRadius: "5px",
                        color: "#b5b5b5",
                        fontSize: "13px",
                        width: "150px",
                        height: "37px",
                      }}
                    >
                      <BsFillCalendarFill
                        className="ms-2"
                        style={{
                          fontSize: "18px",
                          color: "#3C3C3C",
                          marginTop: "8px",
                        }}
                      />
                      <div>
                        <DatePicker
                          className="border-0 ps-2 pt-1  datep"
                       
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          style={{ backgroundColor: "#E0E0E0", outline: "0" }}
                        />
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2 mt-3">
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#8A8A8A",
                }}
              >
                Total Collection
              </p>
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                  color: "#3C3C3C",
                }}
              >
                ₹ 0
              </p>
            </div>
            <div className="col-3 mt-3">
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#8A8A8A",
                }}
              >
                Expected Collection
              </p>
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                  color: "#3C3C3C",
                }}
              >
                ₹ 0
              </p>
            </div>
            <div className="col-3">
              <button
                className="ms-5 mt-4"
                onClick={() => {
                  convertToExcel();
                }}
                style={{
                  backgroundColor: "#8147E7",
                  borderRadius: "5px",
                  width: "180px",
                  height: "50px",
                  border: "none",
                  color: "#ffffff",
                  fontWeight: "400",
                  fontSize: "20px",
                }}
              >
                <RiFileExcel2Line style={{ fontSize: "20px" }} />{" "}
                &nbsp;&nbsp;Export Data
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Invoice Details */}

      <div className="mt-5">
        <p
          className=""
          style={{
            color: "#3C3C3C",
            fontSize: "22px",
            fontWeight: "600",
            paddingLeft: "30px",
          }}
        >
          Invoice Details
        </p>

        <div className="mt-3 ">
          <div className="row mx-4 mt-3">
            <div
              className="row"
              style={{ color: "#434343", fontWeight: "600", fontSize: "15px" }}
            >
              <div className="col-1">
                <p>S.No</p>
              </div>
              <div className="col-2">
                <p>Name</p>
              </div>
              <div className="col-1">
                <p>Amount</p>
              </div>
              <div className="col-2">
                <p>Phone No.</p>
              </div>

              {/* <div className='col-2'>
           <p>Transaction ID</p>
         </div> */}

              {/* <div className='col-2'>
           <p>Payment Method</p>
         </div> */}
              <div className="col-2">
                <p>Status</p>
              </div>
              <div className="col-2">
                <p>Date</p>
              </div>
              <div className="col-2">{/* <button>djgufey</button> */}</div>
            </div>
            <div style={{ overflowY: "scroll", height: "60vh" }}>
              {props.Data.length > 0 ? (
                props.Data?.map((res, key) => {
                  return (
                    <>
                      <div
                        className="row align-items-center  mt-3"
                        style={{
                          width: "100%",
                          borderRadius: "5px",
                          height: "55px",
                          backgroundColor: " #FFFFFF",
                        }}
                      >
                        <div className="col-1" key={key}>
                          <p
                            style={{
                              color: "#434343",
                              fontWeight: "400",
                              fontSize: "15px",
                            }}
                          >
                            {key + 1}
                          </p>
                        </div>
                        <div className="col-2">
                          <p style={{ fontWeight: "400", fontSize: "15px" }}>
                            {res.student_name}
                          </p>
                        </div>
                        <div className="col-1">
                          <p
                            style={{
                              fontSize: "15px",
                              color: "#3C3C3C",
                              fontWeight: "500",
                            }}
                          >
                            {res.emi_amount}
                          </p>
                        </div>
                        <div className="col-2">
                          <p
                            style={{
                              fontSize: "15px",
                              color: "#434343",
                              fontWeight: "400",
                            }}
                          >
                            {res.phone}
                          </p>
                        </div>
                        <div className="col-2">
                          <p
                            style={{
                              fontSize: "15px",
                              fontWeight: "600",
                              color:
                                res.status == 1
                                  ? "#40997E"
                                  : res.status == 0
                                  ? "#f93333"
                                  : "#e2a925",
                            }}
                          >
                            {res.status == "0"
                              ? "unpaid"
                              : res.status == "1"
                              ? "paid"
                              : " partially paid"}
                          </p>
                        </div>
                        <div className="col-2">
                          <p
                            style={{
                              fontSize: "15px",
                              color: "#434343",
                              fontWeight: "400",
                            }}
                          >
                            {res.emi_date}
                          </p>
                        </div>

                        <div className="col-2">
                          <button
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                            style={{
                              height: "30px",
                              fontSize: "15px",
                              color: "#8147E7",
                              fontWeight: "400",
                              backgroundColor: "#F2EAFF",
                              width: "130px",
                              borderRadius: "5px",
                              border: "none",
                            }}
                            onClick={() => {
                              Api(res.id);
                            }}
                          >
                            <AiOutlineEye style={{ fontSize: "20px" }} />
                            &nbsp;&nbsp;{res.view}
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <>
                  <div
                    className="d-flex mt-5"
                    style={{
                      justifyContent: "center",
                      color: "red",
                      alignItems: "center",
                    }}
                  >
                    <h6>No Data Available</h6>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "45%" }}
      >
        <div class="offcanvas-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <p
          className="px-3 pt-1"
          style={{
            backgroundColor: "#F0F0F0",
            width: "35%",
            marginLeft: "60%",
            borderRadius: "5px",
          }}
        >
          Invoice no.: #0{dd[0]?.id}
        </p>
        <div class="offcanvas-body">
          <img src={`${banner}`} style={{ width: "148px" }} />
          {/*<p className='mt-4' style={{ color: "#5A5A5A", fontWeight: "400" }}>Redwood High School</p>*/}

          <div className="row mt-4">
            <div className="col-3" onClick={() => generatePDF()}>
              <div className="">
                {/* <button className='text-center pt-1 ' style={{borderRadius:"5px",color:"#5A5A5A" ,width:"190px",height:"45px",
backgroundColor:"transparent",border:"1px solid #000000",fontWeight:"400"}}><FiDownload/>&nbsp; &nbsp;Download Receipt</button> */}
                <div class="file" style={{ height: "45px" }}>
                  <label
                    for="input-file"
                    style={{ height: "45px", width: "190px" }}
                  >
                    <FiDownload style={{ color: "#BDBDBD" }} />
                    &nbsp;&nbsp;
                    <span style={{ color: "#BDBDBD" }}>Download Receipt</span>
                  </label>
                </div>
              </div>
            </div>

            {/*<div className='col-3'>
              <div className='ms-5' >
                <button className='text-center pt-1 ' style={{
                  borderRadius: "5px", color: "#FF4A4A", width: "140px", height: "45px",
                  backgroundColor: "transparent", border: "1px solid #FF4A4A"
                }}>Delete</button>

              </div>
            </div>
              */}
            <div className="col-3">
              <div className="ms-5">
                <select
                  class="form-select form-select-lg mb-3"
                  aria-label=".form-select-lg example"
                  style={{ width: "160px", backgroundColor: "#EEEEEE" }}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option selected={dd[0]?.status == 1} value="1">
                    Paid
                  </option>
                  <option selected={dd[0]?.status == 0} value="0">
                    Unpaid
                  </option>
                  <option selected={dd[0]?.status == 2} value="2">
                    Partial Paid
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div className="mt-3" style={{ display: "flex" }}>
            <p
              style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "600" }}
            >
              Name :
            </p>
            <p
              className="px-2"
              style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "400" }}
            >
              {dd[0]?.student_name}
            </p>
          </div>

          {/*<div className='mt-3' style={{ display: "flex" }}>
            <p style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "600" }}>Batch:</p>
            <p className='px-2' style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "400" }}>3CO-JVY</p>
              </div>*/}

          <div className="mt-3" style={{ display: "flex" }}>
            <p
              style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "600" }}
            >
              Due Date:
            </p>
            <p
              className="px-2"
              style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "400" }}
            >
              {dd[0]?.emi_date}
            </p>
          </div>

          <div className="mt-3" style={{ display: "flex" }}>
            <p
              style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "600" }}
            >
              Mode Of Payment:
            </p>
            <p
              className="px-2"
              style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "400" }}
            >
              Others
            </p>
          </div>
          {/*
          <div className='row  ms-1 mt-4 pt-2' style={{ backgroundColor: "#E6EDFF", width: "84%", height: "40px" }}>
            <div className='col-3'>
              <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}>S.No</p>
            </div>
            <div className='col-3'>
              <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}>Product Name</p>
            </div>
            <div className='col-3'>
            <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}>Qty</p>
          </div>
            <div className='col-3'>
              <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}>Amount</p>
            </div>
          </div>
          {dd?.map((res, key) => {
            return (
              <>
                <div className='row ms-1 mt-2 ' style={{ width: "84%" }}>
                  <div className='col-3'>
                    <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}>{key+1}</p>
                  </div>
                  <div className='col-3'>
                    <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}> {res.product_name}</p>
                  </div>
                  <div className='col-3'>
                  <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}> {res.qty}</p>
                </div>
                  <div className='col-3'>
                    <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}> {res.rate}</p>
                  </div>
                  <p className='ms-1' style={{ border: " 1px solid #E8E8E8", width: "100%" }}></p>
                </div>
              </>
            )
          })}

 */}
          <div className="row mt-5">
            <div className="col-6 text-center">
              <div
                className="pt-2"
                style={{
                  border: "1px solid #E8E8E8",
                  width: "150px",
                  height: "80px",
                  borderRadius: "5px",
                }}
              >
                <p style={{ color: "#5A5A5A", fontWeight: "400" }}>
                  Grand Total
                </p>
                <p
                  style={{
                    color: "#5A5A5A",
                    fontWeight: "400",
                    fontSize: "25px",
                  }}
                >
                  ₹ {dd[0]?.emi_amount}
                </p>
              </div>
            </div>

            <div className="col-6 text-center">
              <button
                className="mt-4"
                onClick={() => {
                  Udate(dd[0].id);
                }}
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                style={{
                  width: "160px",
                  height: "40px",
                  backgroundColor: "#8147E7",
                  border: "none",
                  color: "#fff",
                  borderRadius: "5px",
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
