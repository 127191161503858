import React, { useState,useEffect } from 'react';
import Header from './header';
import {AiOutlineEye} from 'react-icons/ai';
import DatePicker from "react-datepicker";
import {BiCalendar} from 'react-icons/bi';
import { BsFillCalendarFill } from "react-icons/bs";
import {FiUpload} from "react-icons/fi";
import {AiOutlineCalendar} from 'react-icons/ai';
import lotti6 from '../images/142632-emoji.json';
import Lottie from "lottie-react";
import CH4 from '../images/material-symbols_calendar-view-month.png';
import CH5 from '../images/ic_outline-log-in (2).png';
import CH6 from '../images/ic_outline-log-in (3).png';
import { IoFunnelOutline } from "react-icons/io5";
import { IoNewspaperOutline } from "react-icons/io5";
import { IoEyeOutline } from "react-icons/io5";
import "./Leav.css";
import "react-datepicker/dist/react-datepicker.css"
import {FiDownload} from "react-icons/fi"
import {RiArrowDropDownLine} from 'react-icons/ri';
import {RiFileExcel2Line} from 'react-icons/ri';
import OTPInput, { ResendOTP } from "otp-input-react";
import{NavLink} from 'react-router-dom';
import { IoPersonSharp } from "react-icons/io5";
import { IoCalendarNumberOutline } from "react-icons/io5";
import {HiPhone} from 'react-icons/hi';
import helo from '../images/Rectangle 5.png';
import fb from '../images/logos_facebook.png';
import { IoSendSharp } from "react-icons/io5";
export default function AllLeads(){
 
    const [startDate, setStartDate] = useState(new Date());
    const [startDate1, setStartDate1] = useState(new Date());
    const [Images,setImages] = useState(); 
    const [Banner, setBanner] = useState("");
    const [value, setvalue] = useState('');
    const [count1, setcount1] = useState(1)
    const [reason, setreason] = useState();
    const [OTP, setOTP] = useState("");
    console.log(reason);

   







    const cre=[
      {
          SNo:'1.',
          Name:"Sonali Beniwal",
          number:"+91 89876 89876",
          date:"12 April 2023",
          source:require("../images/Group 13502.png"),
          Assigned :"Manish Sharma",
          attendance:"NEET-English Medium",
          demo:"Dropped",
          status:2,

      },
      {
        SNo:'1.',
        Name:"Sonali Beniwal",
        number:"+91 89876 89876",
        date:"12 April 2023",
        source:require("../images/Group 13502.png"),
        Assigned :"Manish Sharma",
        attendance:"NEET-English Medium",
        demo:"Dropped",
        status:2,
     
      },
      {
        SNo:'1.',
        Name:"Sonali Beniwal",
        number:"+91 89876 89876",
        date:"12 April 2023",
        source:require("../images/Group 13502.png"),
        Assigned :"Manish Sharma",
        attendance:"NEET-English Medium",
        demo:"Dropped",
        status:2,
      
      },
      {
        SNo:'1.',
        Name:"Sonali Beniwal",
        number:"+91 89876 89876",
        date:"12 April 2023",
        source:require("../images/Group 13502.png"),
        Assigned :"Manish Sharma",
        attendance:"NEET-English Medium",
        demo:"Dropped",
        status:2,

    },
    {
      SNo:'1.',
      Name:"Sonali Beniwal",
      number:"+91 89876 89876",
      date:"12 April 2023",
      source:require("../images/Group 13502.png"),
      Assigned :"Manish Sharma",
      attendance:"NEET-English Medium",
      demo:"Dropped",
      status:2,
   
    },
    {
      SNo:'1.',
      Name:"Sonali Beniwal",
      number:"+91 89876 89876",
      date:"12 April 2023",
      source:require("../images/Group 13502.png"),
      Assigned :"Manish Sharma",
      attendance:"NEET-English Medium",
      demo:"Dropped",
      status:2,
    
    },
    {
      SNo:'1.',
      Name:"Sonali Beniwal",
      number:"+91 89876 89876",
      date:"12 April 2023",
      source:require("../images/Group 13502.png"),
      Assigned :"Manish Sharma",
      attendance:"NEET-English Medium",
      demo:"Dropped",
      status:2,
    

  },
  {
    SNo:'1.',
    Name:"Sonali Beniwal",
    number:"+91 89876 89876",
    date:"12 April 2023",
    source:require("../images/Group 13502.png"),
    Assigned :"Manish Sharma",
    attendance:"NEET-English Medium",
    demo:"Dropped",
    status:2,
  },

  {
    SNo:'1.',
    Name:"Sonali Beniwal",
    number:"+91 89876 89876",
    date:"12 April 2023",
    source:require("../images/Group 13502.png"),
    Assigned :"Manish Sharma",
    attendance:"NEET-English Medium",
    demo:"Dropped",
    status:2,
  },
     

 ]

   const dd =[
    {
sn:"1.",
part:"Admission Fees",
amount:"Admission Fees"
    },
    {
      sn:"1.",
      part:"Admission Fees",
      amount:"Admission Fees"
          }
   ]
  
   
   useEffect(() => {
     setvalue('Accounts')
 }, []);
    
    return(
        <>
        {/* <div className='head' style={{width:'100%', height:'14vh',marginLeft:'0%'}}>
    <Header />
    </div> */}
    <div className="card mt-4 ms-4" style={{width:"95%",border:"none"}}>
  <div className="card-body">
      <div className="row">
        <div className="col-4">
         <p style={{fontWeight:"400",color:"#8A8A8A",fontSize:"18px"}}>Date Range</p>
         <div className="row mt-1">
          <div className="col-5">
            <p style={{color:"#8A8A8A",fontSize:"400"}}>From</p>
            <div>
                    
            <span style={{display:'flex', backgroundColor:'#EDEDF5', padding:'',marginLeft:"0px", 
                    borderRadius:'5px', color:'#b5b5b5', fontSize:'13px', width:'150px',height:"37px"}}>
                       <BsFillCalendarFill  className='ms-2' style={{fontSize:'30px',color:"#3C3C3C",marginTop:"2px",}}    /> 
                   <div>
                <DatePicker className="border-0  datep"
                      showIcon
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                     style={{backgroundColor:'#E0E0E0',outline: "0",}}/>
                     </div>
                      </span>
                  </div>
          </div>
          <div className="col-6">
            <p style={{color:"#8A8A8A",fontSize:"400"}}>To</p>
            <div>
                    
            <span style={{display:'flex', backgroundColor:'#EDEDF5', padding:'',marginLeft:"0px", 
                    borderRadius:'5px', color:'#b5b5b5', fontSize:'13px', width:'150px',height:"37px"}}>
                       <BsFillCalendarFill  className='ms-2' style={{fontSize:'30px',color:"#3C3C3C",marginTop:"2px",}}    /> 
                   <div>
                <DatePicker className="border-0  datep"
                      showIcon
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                     style={{backgroundColor:'#E0E0E0',outline: "0",}}/>
                     </div>
                      </span>
                  </div>
          </div>

         </div>
        </div>
      
        
        <div className="col-6">
        <div className='d-flex mt-3'>
        <div >
        <p style={{fontSize:"12px",fontWeight:"400",color:"#8A8A8A"}}>Total Leads</p>
         <p className='mt-3' style={{fontWeight:"500",fontSize:"15px",color:"#3C3C3C"}}> 245</p>
        </div>
        <div style={{marginLeft:"4.5rem",}}>
        <p style={{fontSize:"12px",fontWeight:"400",color:"#8A8A8A"}}>Converted</p>
         <p className='mt-3' style={{fontWeight:"500",fontSize:"15px",color:"#3C3C3C"}}> 245</p>
        </div>
        <div style={{marginLeft:"4.5rem",}}>
        <p style={{fontSize:"12px",fontWeight:"400",color:"#8A8A8A"}}>In Conversation</p>
         <p className='mt-3' style={{fontWeight:"500",fontSize:"15px",color:"#3C3C3C"}}> 245</p>
        </div>
        <div style={{marginLeft:"4.5rem",}}>
        <p style={{fontSize:"12px",fontWeight:"400",color:"#8A8A8A"}}>Dropped</p>
         <p className='mt-3' style={{fontWeight:"500",fontSize:"15px",color:"#3C3C3C"}}> 245</p>
        </div>
        </div>
        </div>
        <div className="col-2">
            <div className="text-end mt-4">
            <button type="button" class="btn " data-bs-toggle="modal" data-bs-target="#exampleModal" style={{width:"100%",padding:"10px",background:"#8147E7",borderRadius:"5px",color:"#fff", }}><IoNewspaperOutline/> Export Data</button>
            </div>
            </div>
      </div>
  </div>
</div>        
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasRightLabel" style={{width:"50%",}}>
  <div class="offcanvas-header" style={{justifyContent:"start !important"}}>
   
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    <h5 id="offcanvasRightLabel" style={{fontStyle:"normal",fontWeight:"600",fontSize:"25px",color:"#434343",marginLeft:"-22rem",}}>Lead Details</h5>
    {/* <h5 id="offcanvasRightLabel"><button type="button" class="btn " style={{background:"#FFF4EC",borderRadius:"5px", border:"none",color:"#DC9A00",padding:"10px 30px",marginLeft:"8rem",}}>Drop Lead</button></h5> */}
    <h5 id="offcanvasRightLabel"><button type="button" class="btn" style={{background:"#EADDFF",borderRadius:"5px",border:"none",color:"#8147E7",padding:"10px 15px",}}>Re-Assign Lead</button></h5>
    
    {/* <h5 id="offcanvasRightLabel"><button type="button" class="btn" style={{background:"#8147E7",borderRadius:"5px",border:"none",color:"#FFFFFF",padding:"10px 16px",}}>Convert Lead</button></h5> */}
  </div>
  <div class="offcanvas-body">
    <div className=''>
      <p><span><IoPersonSharp style={{fontSize:"1.5rem",}}/></span> Shivangi Malhotra</p>
      <p className='mt-3'><span><IoCalendarNumberOutline style={{fontSize:"1.5rem",}}/></span> 12 April 2023</p>
      <p className='mt-3'> Source <span style={{color:"#1877F2",}}>Facebook <img   src={fb} style={{width:"3%",}} /></span></p>
      <p className='mt-3'>Course</p>
      <p className='mt-2' style={{fontStyle:"normal",fontWeight:"600",fontSize:"16px",color:"#3C3C3C",}}>NEET-English Medium</p>

      <p className='mt-3'>Guardian’s Name</p>
      <p className='mt-2' style={{fontStyle:"normal",fontWeight:"600",fontSize:"16px",color:"#3C3C3C",}}>Ishwar Singh Malhotra</p>
      <div className='row mt-4'>
        <div className='col-4'>
        <p style={{fontStyle:"normal",fontWeight:"600",fontSize:"16px",color:"#3C3C3C",}}>Contact Number</p>
          <p className='mt-4' style={{border:"1px solid #808080",padding:"10px",borderRadius:"5px",marginTop:"10px !important",}}><HiPhone/> +91 78998 78998</p>
        </div>
        <div className='col-4'>
        <p style={{fontStyle:"normal",fontWeight:"600",fontSize:"16px",color:"#3C3C3C",}}>Alternate Contact Number</p>
        <p className='mt-4' style={{border:"1px solid #808080",padding:"10px",borderRadius:"5px",marginTop:"10px !important",}}><HiPhone/> +91 78998 78998</p>
        </div>
      </div>
      <p className='mt-3' style={{fontStyle:"normal",fontWeight:"400",fontSize:"16px",color:"#434343",}}>Assigned to</p>
      <div className='mt-4 d-flex'>
        <img src={helo} style={{width:"5%",}}/>
        <p className='mt-1' style={{fontStyle:"normal",fontWeight:"600",fontSize:"16px",color:"#3C3C3C",marginLeft:"10px",}}>Manish Jangir</p>
      </div>

      <p className='mt-3' style={{fontStyle:"normal",fontWeight:"500",fontSize:"20px",color:"#434343",}}>Remarks History</p>
      
      <div className='card' style={{background:"#F0F5FE",borderRadius:"5px",border:"none",}}>
      <div style={{overflowY:"scroll",height:"40vh",}}>
      <p className='mt-3 mx-3' style={{background:"#1877F2",borderRadius:"5px",width:"30%",padding:"10px", color:"#fff",}}> Call was not picked up</p>
      <p className='mx-3 mt-1' style={{fontStyle:"normal",fontWeight:"400",fontSize:"14px",color: "#8A8A8A",}}>12:30 | 30 April 2023</p>
      <p className='mt-2 mx-3' style={{background:"#1877F2",borderRadius:"5px",width:"30%",padding:"10px", color:"#fff",}}> Call was not picked up</p>
      <p className='mx-3 mt-1' style={{fontStyle:"normal",fontWeight:"400",fontSize:"14px",color: "#8A8A8A",}}>12:30 | 30 April 2023</p>
      <p className='mt-2 mx-3' style={{background:"#1877F2",borderRadius:"5px",width:"30%",padding:"10px", color:"#fff",}}> Call was not picked up</p>
      <p className='mx-3 mt-1' style={{fontStyle:"normal",fontWeight:"400",fontSize:"14px",color: "#8A8A8A",}}>12:30 | 30 April 2023</p>
      <p className='mt-2 mx-3' style={{background:"#1877F2",borderRadius:"5px",width:"30%",padding:"10px", color:"#fff",}}> Call was not picked up</p>
      <p className='mx-3 mt-1' style={{fontStyle:"normal",fontWeight:"400",fontSize:"14px",color: "#8A8A8A",}}>12:30 | 30 April 2023</p>
      </div>
 
  <p className='text-center' style={{background:"#FFE9E9",borderRadius:"5px",fontStyle:"normal",fontWeight:"400",fontSize:"12px",lineHeight:"20px",color:"#CC1313",padding:"8px",}}>Lead Dropped on 12:34 | 23 April 2023</p>
      </div>
    </div>
  </div>
</div>

   {/* modal grant leave */}
   
   <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div  className="modal-dialog modal-lg mt-5"  >
    <div className="modal-content">
      <div className="">
    
      </div>
      <div className="modal-body">

<div style={{display:count1===1?"block":"none"}}> 
    

      <p className="mt-5" style={{color:"#989898",fontWeight:'400',textAlign:"center",fontSize:"21px"}}>Confirm Salary Disburse ?</p>
      <p className="mt-3" style={{textAlign:"center",fontWeight:"500",color:"#3C3C3C",fontSize:"34px"}}>Amount ₹ 4,00,000</p>
      <p style={{fontWeight:"500",color:" #3C3C3C",fontSize:"21px",textAlign:"center"}}>To 64 Staff Members</p>

      <div className="">
        <button className="d-grid gap-2 col-6 mx-auto " style={{marginTop:"70px",width:"40%",padding:"10px",fontSize:"18px",
         backgroundColor:"#8147E7",border:"none",color:"#fff",boxShadow:" 0px 4px 12px rgba(126, 57, 247, 0.36)",fontWeight:"500",borderRadius:"5px"}} onClick={()=>{setcount1(count1+1)}}>Disburse Salary</button>
      </div>

      <div className="">
        <button className="d-grid gap-2 col-6 mx-auto mt-4" style={{width:"40%",padding:"10px",fontSize:"18px",
         backgroundColor:" #F2EDFD",border:"none",color:"#8147E7",fontWeight:"500",borderRadius:"5px"}}>Cancel</button>
      </div>

      <div>
        <p style={{textAlign:"center",marginTop:"70px",color:"#F93333",fontWeight:"400",fontSize:"18px"}}>Note : This action once done, can not be reversed</p>
      </div>
    
     </div>
   



      {/* second Add staart */}
      <div style={{display:count1===2?"block":"none"}}>
      <div className="mt-5" >
        <p style={{color:" #3C3C3C",fontWeight:"600",fontSize:"27px",textAlign:"center"}}>Enter 6 Digit OTP</p>
        <p className="" style={{textAlign:"center",color:"#989898",fontSize:"17px",paddingLeft:"55px"}}>Received on : saraswati@gmail.com</p>
        <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false}  style={{marginTop:"2rem",justifyContent:"center",}}/>
      <p className='mt-3'  style={{textAlign:"center"}}>Resend OTP</p>
      </div>







     <div className="">
        <button className="d-grid gap-2 col-6" style={{marginTop:"70px",width:"40%",padding:"10px",fontSize:"18px",marginLeft:"35.5%",
         backgroundColor:"#8147E7",border:"none",color:"#fff",boxShadow:" 0px 4px 12px rgba(126, 57, 247, 0.36)",fontWeight:"500",borderRadius:"5px"}} onClick={()=>{setcount1(count1+1)}}>Disburse Salary</button>
      </div>
      <div className="">
        <button className="d-grid gap-2 col-6  mt-4" style={{width:"40%",padding:"10px",fontSize:"18px",marginLeft:"35.5%",
         backgroundColor:" #F2EDFD",border:"none",color:"#8147E7",fontWeight:"500",borderRadius:"5px"}}>Cancel</button>
      </div>
      <div className="mt-5"></div>
    
      </div>




      {/* Transaction vali */}
      <div style={{display:count1===3?"block":"none"}}>
       <div className="mt-5">
    <p style={{textAlign:"center",color:"#3C3C3C",fontSize:"30px"}}>Transaction Validated Successfully</p>
         </div>




   
     <div className="mt-5">
        <button className="btn btn-primary mx-auto d-grid gap-2 col-6 " onClick={()=>{setcount1(count1+1)}}    
        style={{width:"300px",marginTop:"0px"}}>demo</button>
      </div>
     
      </div>


          {/* lottify */}
          <div style={{display:count1===4?"block":"none"}}>
       <div className="mt-5">
    <p style={{textAlign:"center",color:"#3C3C3C",fontSize:"35px",fontWeight:"600"}}>Salaries Disbursed Successfully!!</p>
         </div>


         <Lottie animationData={lotti6} className="d-grid gap-2 col-6 mx-auto" style={{width:"30%"}}/>

   
         <div className="">
        <NavLink  to="/Team" style={{textDecoration:"none",}}><button className="d-grid gap-2 col-6" data-bs-dismiss="modal" aria-label="Close" style={{marginTop:"70px",width:"50%",padding:"15px",fontSize:"18px",marginLeft:"25%",
         backgroundColor:"#8147E7",border:"none",color:"#fff",boxShadow:" 0px 4px 12px rgba(126, 57, 247, 0.36)",fontWeight:"500",borderRadius:"5px"}} onClick={()=>{setcount1(count1+1)}}>Back to Salary Management</button></NavLink>
      </div>
      <div className="mt-3"></div>
     
      </div>




      </div>
      
    </div>
  </div>
</div>

{/* Invoice Details */}

<div className="mt-5">
{/* <div className="row mt-5" style={{width:"100%",}}>
    <div className="col-8">
        <p style={{fontStyle:"normal",fontWeight:"600",fontSize:"18px",color:"#3C3C3C",marginLeft:"28px",
}}>Staff Details</p>
    </div>
    <div className="col-2 text-end">
    <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#exampleModal2" style={{width:"70%",background:"#EADDFF",borderRadius:"5px",color:"#8147E7",fontStyle:"normal",fontWeight:"400",fontSize:"14px",border:"none",padding:"8px",}}><IoFunnelOutline/> Filter</button>
    </div>
    <div className="col-2">
    <button type="button" class="btn" style={{width:"70%",background:"#8147E7",borderRadius:"5px",fontStyle:"normal",fontWeight:"400",fontSize:"14px",color:"#FFFFFF",border:"none",padding:"8px",}}><IoNewspaperOutline/> Export Data</button>
    </div>
</div> */}
    <div className='mt-3 '>
       <div className='row mx-4 mt-3'  >
       <div className='d-flex' style={{color:"#434343",fontWeight:"600",fontSize:"15px"}}> 
         <div className=''style={{marginLeft:"1rem",}}>
           <p style={{fontSize:"13px",}}>S.No</p>
         </div>
         <div className=''style={{marginLeft:"3rem",}}>
           <p style={{fontSize:"13px",}}>Name</p>
         </div>
         <div className=''style={{marginLeft:"5rem",}}>
           <p style={{fontSize:"13px",}}>Contact Number</p>
         </div>
         <div className=''style={{marginLeft:"5.5rem",}}>
           <p style={{fontSize:"13px",}}>Date</p>
         </div>
         <div className=''style={{marginLeft:"6rem",}}>
           <p style={{fontSize:"13px",}}>Source</p>
         </div>
         <div className=''style={{marginLeft:"4rem",}}>
           <p style={{fontSize:"13px",}}>Assigned to</p>
         </div>
         <div className=''style={{marginLeft:"5rem",}}>
         <p style={{fontSize:"13px",}}>Course</p>
         </div>
         <div className=''style={{marginLeft:"10rem",}}>
         <p style={{fontSize:"13px",}}>Status</p>
         </div>
         <div className=''style={{marginLeft:"7rem",}}>
         <p style={{fontSize:"13px",}}></p>
         </div>
        </div>
        <div style={{overflowY:"scroll",height:"60vh"}}>
    {cre.map((res,key)=>{
        return(
            <>
            <div className='d-flex align-items-center  mt-3' style={{width:"100%",borderRadius:"5px" ,height:"55px",backgroundColor:" #FFFFFF"}} >
              <div className='' style={{marginLeft:"2rem",}} key={key} >
<p style={{color:"#434343",fontWeight:"400",fontSize:"10px"}}>{res.SNo}</p>
</div>
<div className='' style={{marginLeft:"3rem",}}>
<p style={{fontWeight:"400",fontSize:"10px"}}>{res.Name}</p>
</div>
<div className='' style={{marginLeft:"5rem",}}>
<p style={{fontSize:"10px",color:"#3C3C3C",fontWeight:"500"}} >{res.number}</p>
</div>
<div className='' style={{marginLeft:"3rem",}}>
<p style={{fontSize:"10px",color:"#434343",fontWeight:"400"}} >{res.date}</p>
</div>
<div className='' style={{marginLeft:"5rem",}}>

<img src={res.source} style={{width:"65px"}}/>
</div>
<div className='' style={{marginLeft:"4rem",}}>
<p style={{fontSize:"10px",color:"#434343",fontWeight:"400",color:"#8147E7"}} >{res.Assigned}</p>
</div>
<div className='' style={{marginLeft:"4rem",}}>
<p style={{fontSize:"10px",color:"#434343",fontWeight:"400"}} >{res.attendance}</p>
</div>
<div className='' style={{marginLeft:"9rem",}}>
{/* <p style={{fontSize:"10px",color:"#434343",fontWeight:"400"}} >{res.attendance}</p> */}
<p style={{fontSize:"10px",color:"#434343",fontWeight:"400",padding:"6px 19px",borderRadius:"5px", color:res.status===0?"#40997E":res.status==1?"#f93333":"#e2a925",backgroundColor: res.status === 0 ? "#ECFFEE" : res.status === 1 ? "#FFECEC" : "#FFF4EC", }} >{res.demo}</p>
</div>

{/* <div className='' style={{marginLeft:"7rem",}}>
<p data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample" style={{height:"30px",fontSize:"15px",color:"#8147E7",fontWeight:"400",
width:"130px",borderRadius:"5px",border:"none",}} ><AiOutlineEye style={{fontSize:"20px"}}/>&nbsp;&nbsp;{res.view}</p>
</div> */}

  </div>


            </> 
        )
    })}
    </div>
    
</div>
  </div>
</div>




        </>
    )
}