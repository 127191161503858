import React, { useState, useEffect, useContext } from "react";
import { IoFunnelOutline } from "react-icons/io5";
import { IoNewspaperOutline } from "react-icons/io5";
import "./Leav.css";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, img_url } from "../env";

export default function SalaryHistory() {
  const { apiToken } = useContext(AuthContext);
  const { id } = useParams();
  const [cre, setCre] = useState([]);
  const Navigate = useNavigate();

  const api = async () => {
    // try {
    //   if (cre.length > 0) {
    //     return;
    //   }
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${apiToken}`);

      var raw = JSON.stringify({
        emp_id: id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${API_URL}/admin/employee/salary/history/employee`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            setCre(result.data);
            if (result.data.length === 0) {
              // Show a toast if the data array is empty
              toast.info("No salary history found for this employee.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }else if (result.status === 'VAL_ERR') { 
            toast(result.Backend_Error) 
          } 
        else if (result.status == 'FILE_ERR') { 
            toast(result.Backend_Error) 
          } 
         else if (result.status == "CUSTOM_ERR") { 
            toast(result.Backend_Error); 
          } else if (result.status == "TOKEN_ERR") { 
            localStorage.removeItem('api_url'); 
            Navigate('/code') 
            toast("Logged in other device! Please login again") 
          } 
        })
        .catch((error) => console.log("error", error));
    // } 
    // catch (e) {
    //   console.log(e);
    // }
  };

  useEffect(() => {
    // console.log("Calling api()");
    api();
  }, []);

  return (
    <>
      {/* Invoice Details */}

      <div className="mt-5">
        {/* <div className="row mt-5" style={{ width: "100%" }}> */}
          <div className="col-8">
            <p
              style={{
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                color: "#3C3C3C",
                marginLeft: "28px",
              }}
            >
              Salary Details
            </p>
          </div>
          {/* <div className="col-2 text-end">
            <button
              type="button"
              class="btn"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
              style={{
                width: "70%",
                background: "#EADDFF",
                borderRadius: "5px",
                color: "#8147E7",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                border: "none",
                padding: "8px",
              }}
            >
              <IoFunnelOutline /> Filter
            </button>
          </div>
          <div className="col-2">
            <button
              type="button"
              class="btn"
              style={{
                width: "70%",
                background: "#8147E7",
                borderRadius: "5px",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                color: "#FFFFFF",
                border: "none",
                padding: "8px",
              }}
            >
              <IoNewspaperOutline /> Export Data
            </button>
          </div> */}
        {/* </div> */}
        <div className="mt-3 ">
          <div className="row mx-4 mt-3">
            {/* <div className='d-flex' style={{color:"#434343",fontWeight:"600",fontSize:"15px"}}>  */}
            <div className="col">
              <p style={{ fontSize: "1rem", color: "#3c3c3c" }}>S.No</p>
            </div>
            <div className="col">
              <p style={{ fontSize: "1rem", color: "#3c3c3c" }}>Name</p>
            </div>
            <div className="col">
              <p style={{ fontSize: "1rem", color: "#3c3c3c" }}>Department</p>
            </div>
            <div className="col">
              <p style={{ fontSize: "1rem", color: "#3c3c3c" }}>From</p>
            </div>
            <div className="col">
              <p style={{ fontSize: "1rem", color: "#3c3c3c" }}>To</p>
            </div>
            <div className="col">
              <p style={{ fontSize: "1rem", color: "#3c3c3c" }}>Amount</p>
            </div>
            <div className="col">
              <p style={{ fontSize: "1rem", color: "#3c3c3c" }}>Attendance</p>
            </div>
            {/* </div> */}
            <div style={{ overflowY: "scroll", height: "80vh" }}>
              {cre.map((res, key) => {
                return (
                  <>
                    <div
                      className="row align-items-center  mt-3"
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                        height: "55px",
                        backgroundColor: " #FFFFFF",
                      }}
                    >
                      <div className="col" key={key}>
                        <p
                          style={{
                            color: "#3c3c3c",
                            fontWeight: "400",
                            fontSize: "1rem",
                          }}
                        >
                          {res.id}
                        </p>
                      </div>
                      <div className="col">
                        <p
                          style={{
                            color: "#3c3c3c",
                            fontWeight: "400",
                            fontSize: "1rem",
                          }}
                        >
                          {res.emp_name}
                        </p>
                      </div>
                      <div className="col">
                        <p
                          style={{
                            color: "#3c3c3c",
                            fontWeight: "400",
                            fontSize: "1rem",
                          }}
                        >
                          {res.department_name}
                        </p>
                      </div>
                      <div className="col">
                        <p
                          style={{
                            color: "#3c3c3c",
                            fontWeight: "400",
                            fontSize: "1rem",
                          }}
                        >
                          {res.from_date}
                        </p>
                      </div>
                      <div className="col">
                        <p
                          style={{
                            color: "#3c3c3c",
                            fontWeight: "400",
                            fontSize: "1rem",
                          }}
                        >
                          {res.to_date}
                        </p>
                      </div>
                      <div className="col">
                        <p
                          className="ms-3"
                          style={{
                            color: "#3c3c3c",
                            fontWeight: "400",
                            fontSize: "1rem",
                          }}
                        >
                          ₹ {res.amount}
                        </p>
                      </div>
                      <div className="col">
                        <p
                          className="d-flex gap-3 ms-3"
                          style={{ fontWeight: "400", fontSize: "1rem" }}
                        >
                          <span style={{ color: "#40997E" }}>
                            {res.present}
                          </span>
                          <span style={{ color: "#E2A925" }}>
                            {res.halfday}
                          </span>
                          <span style={{ color: "#F93333" }}>{res.absent}</span>
                        </p>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </>
  );
}
