import React, { useState, useEffect, useContext } from "react";

import Header from "./header";
import SideBar from "./sidebar";
import ReactApexChart from "react-apexcharts/";
import All from "./All";
import In from "./in";
import Out from "./out";
import Leave from "./leave";
import { AuthContext } from "./AuthContext";
import { API_URL } from "../env";
import { BsFillCalendarFill } from "react-icons/bs";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Lottie from "lottie-react";
import animationData from "../images/e.json";

export default function Attendance() {
  const { apiToken } = useContext(AuthContext);
  const [value, setvalue] = useState("All");
  const [Inside, setInside] = useState(0);
  const [Out_Student, setOut] = useState(0);
  const [Leave_Student, setLeave] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [total, settotal] = useState(0);
  const [exportReportState, setExportReportState] = useState(0);
  const { Navigate } = useNavigate();
  const [show, setShow] = useState(false);
  const [startDate1, setStartDate1] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());
  const [Course, setCourse] = useState([]);
  const [Batch, setBatch] = useState([]);
  const [BatchId, setBatchId] = useState("");
  const [CourseId, setCourseId] = useState();
  const [excelUrl, setExcelUrl] = useState("");
  // const [isStudent, setIsStudent] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const formattedDate = format(startDate, "yyyy-MM-dd'T'HH:mm:ssXXX");

  const Total = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-type", `application/json`);
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var raw = JSON.stringify({
      isoDate1: formattedDate,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/admin/attendence/read/attendence/statics`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "1") {
          settotal(result.TOTAL_STUDENTS);
          setInside(result.PRESENT_STUDENTS);
          setOut(result.HALFDAY_STUDENTS);
          setLeave(result.ABSENT_STUDENTS);
        } else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem("api_url");
          Navigate("/code");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const submitAttendence = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-type", `application/json`);
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var raw = JSON.stringify({
      isoDate1: formattedDate,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/admin/attendence/declare/attendence`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "1") {
          toast(result.msg);
        } else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem("api_url");
          Navigate("/code");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const ApiForCourse = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/admin/course/read/list/of/courses`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "001") {
          setCourse(result.courses);
        } else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem("api_url");
          Navigate("/code");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const ApiForBatchDetail = async (ID) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/admin/formfill/batch/by/courseid/${ID}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setBatch(result.batches);
        if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem("api_url");
          Navigate("/code");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result);
        }
        //setTotalAmount(result.Amount);
      })
      .catch((error) => console.log("error", error));
  };

  const ApiForExportReport = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${apiToken}`);
      var raw = JSON.stringify({
        start_date: format(startDate1, "dd-MM-yyyy"),
        end_date: format(startDate2, "dd-MM-yyyy"),
        batch_id: BatchId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${API_URL}/admin/attendence/export/attendence/of/particular/month`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            // toast(result.msg);
            // setcount(count + 1)
            setExcelUrl(result.msg);
            setExportReportState(1);
          } else if (result.status === "VAL_ERR") {
            toast(result.Backend_Error);
          } else if (result.status == "FILE_ERR") {
            toast(result.Backend_Error);
          } else if (result.status == "CUSTOM_ERR") {
            toast(result.Backend_Error);
          } else if (result.status == "TOKEN_ERR") {
            localStorage.removeItem("api_url");
            Navigate("/code");
            toast("Logged in other device! Please login again");
          } else {
            console.log(result);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (e) {
      console.log(e);
    }
  };

  function handleSelectChange1(event) {
    const { id, value } = event.target.options[event.target.selectedIndex];

    ApiForBatchDetail(value);
    setCourseId(value);
  }

  const downloadExcel = () => {
    if (excelUrl) {
      // Create a link element
      const link = document.createElement("a");
      // Set the href attribute to the Excel file URL
      link.href = excelUrl;
      // Set the download attribute to specify the default file name
      link.download = "attendance_report.xlsx";
      // Append the link to the document
      document.body.appendChild(link);
      // Trigger a click event on the link to start the download
      link.click();
      // Remove the link from the document
      document.body.removeChild(link);
    } else {
      // Handle the case where the Excel URL is not available
      console.error("Excel URL is not available");
    }
  };

  useEffect(() => {
    Total();
    ApiForCourse();
    ApiForBatchDetail();
  }, []);

  return (
    <>
      <div style={{ width: "100%", height: "100vh" }}>
        <div
          className="head"
          style={{ width: "80%", height: "14vh", marginLeft: "16%" }}
        >
          <Header />
        </div>
        <div style={{ width: "100%", height: "86vh", display: "flex" }}>
          <div
            style={{
              width: "20%",
              height: "100%",
              position: "absolute",
              top: 0,
              marginLeft: "1%",
            }}
          >
            <SideBar />
          </div>

          <div
            className="maincontain"
            style={{
              marginLeft: "15%",
              width: "100%",
              height: "100%",
              overflowY: "scroll",
            }}
          >

            {/* chart */}
            <div
              style={{
                width: "93%",
                height: "34.5vh",
                marginLeft: "37px",
                backgroundColor: "white",
              }}
            >
              <div className="row">
                <div className="col-7" style={{ borderRadius: "10px" }}>
                  <div className="row">
                    {/* <div className='col-3'>
                      <div className="col-12 mixed-chart mt-4 mx-4" style={{ display: "flex" }}>
                        <span> <MyPieChart /></span>
                      </div>
                    </div> */}
                    <div className="">
                      <div className="row m-2 ">
                        {/* <h3 style={{ textAlign: "center", color: "#5DD9D4" }}>Overview</h3> */}
                        <div className="col-6">
                          <div
                            class="card"
                            style={{
                              border: "1px silid #DBDBDB",
                              borderRadius: "10px",
                            }}
                          >
                            <div class="card-body">
                              <h5
                                class="card-title"
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  color: "#292929",
                                }}
                              >
                                Total Students
                              </h5>
                              <p
                                class="card-text "
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  fontSize: "24px",
                                  color: "#5FE33E",
                                }}
                              >
                                {total }
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            class="card"
                            style={{
                              border: "1px silid #DBDBDB",
                              borderRadius: "10px",
                            }}
                          >
                            <div class="card-body">
                              <h5
                                class="card-title"
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  color: "#292929",
                                }}
                              >
                                Outside Campus
                              </h5>
                              <p
                                class="card-text"
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  fontSize: "24px",
                                  color: "#EC6767",
                                }}
                              >
                                { Out_Student }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3 m-2">
                        <div className="col-6">
                          <div
                            class="card"
                            style={{
                              border: "1px silid #DBDBDB",
                              borderRadius: "10px",
                            }}
                          >
                            <div class="card-body">
                              <h5
                                class="card-title"
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  color: "#292929",
                                }}
                              >
                                Inside Campus
                              </h5>
                              <p
                                class="card-text"
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  fontSize: "24px",
                                  color: "#5DD9D4",
                                }}
                              >
                                {Inside }
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            class="card"
                            style={{
                              border: "1px silid #DBDBDB",
                              borderRadius: "10px",
                            }}
                          >
                            <div class="card-body">
                              <h5
                                class="card-title"
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  color: "#292929",
                                }}
                              >
                                On Leave
                              </h5>
                              <p
                                class="card-text"
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  fontSize: "24px",
                                  color: "#FF05C8",
                                }}
                              >
                                {Leave_Student }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-3  ms-auto" style={{}}>
                  <div className="row">
                    <div className=" mt-4" style={{ display: "flex" }}>
                      <span
                        style={{
                          display: "flex",
                          backgroundColor: "#EDEDF5",
                          padding: "",
                          marginLeft: "0px",
                          borderRadius: "5px",
                          color: "#b5b5b5",
                          fontSize: "13px",
                        }}
                      >
                        <BsFillCalendarFill
                          className="ms-2"
                          style={{
                            fontSize: "15px",
                            color: "#3C3C3C",
                            marginTop: "12px",
                          }}
                        />
                        <div>
                          <DatePicker
                          showIcon
                            className="border-0 ps-0 ps-2 datep mt-2"
                            selected={startDate}
                            dateFormat="yyyy/MM/dd h:mm aa" // Use a date format that includes time
                            showTimeSelect // Show the time select
                            timeFormat="HH:mm" // Define the time format (24-hour)
                            onChange={(date) => {
                              // This will include both date and time
                              setStartDate(date);
                            }}
                            style={{ backgroundColor: "#E0E0E0", outline: "0" }}
                          />
                        </div>
                      </span>
                      <button
                        type="button"
                        class="btn btn-primary mt-0 ms-3"
                        onClick={() => Total()}
                      >
                        Apply
                      </button>
                    </div>
                    <button
                      type="button"
                      class="btn btn-primary mt-4 ms-3"
                      onClick={() => submitAttendence()}
                    >
                      Set Attendence
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary mt-4 ms-3"
                      onClick={handleShow}
                    >
                      Export Report
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Tabls */}

            <div className="row mt-4">
              <button
                className="col-3 ms-5 py-3"
                onClick={() => {
                  setvalue("All");
                }}
                style={{
                  display: "flex",
                  backgroundColor: "transparent",
                  border: "none",
                  borderBottom: value == "All" ? "2px solid blue" : "none",
                  color: value == "All" ? "blue" : "",
                  width: "47px",
                }}
              >
                <p style={{ zIndex: "999" }}>All</p>
              </button>

              <button
                className="col-3"
                onClick={() => {
                  setvalue("In");
                }}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  borderBottom: value == "In" ? "2px solid blue" : "none",
                  color: value == "In" ? "blue" : "",
                  width: "47px",
                  marginLeft: "15px",
                }}
              >
                <p>In</p>
              </button>

              <button
                className="col-3"
                onClick={() => {
                  setvalue("Out");
                }}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  borderBottom: value == "Out" ? "2px solid blue" : "none",
                  color: value == "Out" ? "blue" : "",
                  width: "47px",
                  marginLeft: "15px",
                }}
              >
                <p>Out</p>
              </button>

              <button
                className="col-3"
                onClick={() => {
                  setvalue("Leave");
                }}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  borderBottom: value == "Leave" ? "2px solid blue" : "none",
                  color: value == "Leave" ? "blue" : "",
                  width: "65px",
                  marginLeft: "15px",
                }}
              >
                <p>Absent</p>
              </button>
            </div>
            <div
              style={{
                borderBottom: "1px solid #E4E4E4",
                width: "70%",
                marginLeft: "37px",
              }}
            ></div>
            <div className="row">
              <div className="col-12">
                {value === "All" ? (
                  <>
                    <All formattedDate={formattedDate} />
                  </>
                ) : (
                  <></>
                )}

                {/* in  */}
                {value === "In" ? (
                  <>
                    <In formattedDate={formattedDate} />
                  </>
                ) : (
                  <></>
                )}

                {/* out */}
                {value === "Out" ? (
                  <Out formattedDate={formattedDate} />
                ) : (
                  <></>
                )}

                {/* Leave */}
                {value === "Leave" ? (
                  <Leave formattedDate={formattedDate} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* export report model started  */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Export Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {exportReportState == 0 ? (
            <>
              <div className="d-flex align-items-center gap-3 ">
                <div className="flex-grow-1">
                  <h6 className="mb-0">From</h6>
                  <span
                    className="w-100 py-1 mt-2"
                    style={{
                      display: "flex",
                      backgroundColor: "#EDEDF5",
                      borderRadius: "5px",
                      color: "#b5b5b5",
                      fontSize: "13px",
                    }}
                  >
                    <BsFillCalendarFill
                      className="ms-2"
                      style={{
                        fontSize: "18px",
                        color: "#3C3C3C",
                        marginTop: "8px",
                      }}
                    />
                    <div>
                      <DatePicker
                        className="border-0 ps-0 ps-2 datep mt-2"
                        selected={startDate1}
                        dateFormat="dd/MM/yyyy" // Use a date format that includes time
                        onChange={(date) => {
                          // This will include both date and time
                          setStartDate1(date);
                        }}
                        style={{ backgroundColor: "#E0E0E0", outline: "0" }}
                      />
                    </div>
                  </span>
                </div>

                <div className="flex-grow-1">
                  <h6 className="mb-0">To</h6>
                  <span
                    className="w-100 py-1 mt-2"
                    style={{
                      display: "flex",
                      backgroundColor: "#EDEDF5",
                      borderRadius: "5px",
                      color: "#b5b5b5",
                      fontSize: "13px",
                    }}
                  >
                    <BsFillCalendarFill
                      className="ms-2"
                      style={{
                        fontSize: "18px",
                        color: "#3C3C3C",
                        marginTop: "8px",
                      }}
                    />
                    <div>
                      <DatePicker
                        className="border-0 ps-0 ps-2 datep mt-2"
                        selected={startDate2}
                        dateFormat="dd/MM/yyyy" // Use a date format that includes time
                        onChange={(date) => {
                          // This will include both date and time
                          setStartDate2(date);
                          console.log(startDate2);
                        }}
                        style={{ backgroundColor: "#E0E0E0", outline: "0" }}
                      />
                    </div>
                  </span>
                </div>
              </div>

              <div className="mt-2">
                <div>
                  <label
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "20px",
                      color: "#414141",
                    }}
                  >
                    Select Course
                  </label>
                  <span style={{ color: "red" }}>*</span>
                  <br></br>
                  <select
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #EDEDED",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "95%",
                    }}
                    onChange={handleSelectChange1}
                  >
                    <option defaultvalue="">--Select Course--</option>
                    {Course?.map((res) => {
                      return (
                        <>
                          <option value={res.id} id={res.course_price}>
                            {res.course_name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="mt-4">
                  <label
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "20px",
                      color: "#414141",
                    }}
                  >
                    Select Batch
                  </label>
                  <span style={{ color: "red" }}>*</span>
                  <br></br>
                  <select
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #EDEDED",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "95%",
                    }}
                    value={BatchId}
                    onChange={(e) => {
                      setBatchId(e.target.value);
                    }}
                  >
                    <option defaultvalue="">--Select Batch--</option>
                    {Batch?.map((res) => {
                      return (
                        <>
                          <option value={res.id}>{res.batch_name}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {exportReportState == 1 ? (
            <>
              <div className="d-flex justify-content-center align-items-center">
                <Lottie
                  animationData={animationData}
                  loop
                  autoplay
                  style={{ width: 300, height: 300 }}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          {exportReportState == 0 ? (
            <>
              <button
                className="btn btn-primary w-100 py-2"
                onClick={ApiForExportReport}
              >
                Proceed
              </button>
            </>
          ) : (
            <></>
          )}
          {exportReportState == 1 ? (
            <>
              <button
                className="btn btn-primary w-100 py-2"
                onClick={() => {
                  downloadExcel();
                  setExportReportState(0);
                  handleClose();
                }}
              >
                Download Excel
              </button>
            </>
          ) : (
            <></>
          )}
          {/* {
          exportReportState == 0?<>

          <button className='btn btn-primary w-100 py-2' >Proceed</button>
          </>:<></>
        } */}
        </Modal.Footer>
      </Modal>
      {/* export report model end  */}

      <ToastContainer />
    </>
  );
}

const MyPieChart = () => {
  const state = {
    series: [4, 0, 0],
    options: {
      chart: {
        type: "donut",
      },
      dataLabels: {
        enable: false,
      },
      fill: {
        colors: ["#5FE33E", "#5DD9D4", "#FFE605"],
      },
      legend: {
        show: false,
      },
    },
  };

  const [getState, setState] = useState(state);

  return (
    <>
      <div id="chart">
        <ReactApexChart
          options={getState.options}
          series={getState.series}
          type="donut"
          height={200}
        />
      </div>
    </>
  );
};
