import React, { useState } from 'react';
import Header from './header';
import SideBar from './sidebar';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import lotti from '../images/79561-dh-coming-soon.json';
import Lottie from "lottie-react";
import { NavLink } from 'react-router-dom';
import { useRequireAuth } from './RequireAuth';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
 // useRequireAuth('/code');

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

let mail = [
  {
    name: 'H',
    email: 'harsh.kadyan2020@xyzcollege.com',
    day: 'Leave application for 2 days',
    bgcolor: '#FF2C96'
  },
  {
    name: 'Y',
    email: 'harsh.kadyan2020@xyzcollege.com',
    day: 'Leave application for 2 days',
    bgcolor: '#40997E'
  },
  {
    name: 'D',
    email: 'harsh.kadyan2020@xyzcollege.com',
    day: 'Leave application for 2 days',
    bgcolor: '#FF2C96'
  },

  {
    name: 'A',
    email: 'harsh.kadyan2020@xyzcollege.com',
    day: 'Leave application for 2 days',
    bgcolor: '#40997E'
  },
  {
    email: 'harsh.kadyan2020@xyzcollege.com',
    day: 'Leave application for 2 days',
    bgcolor: '#FF2C96'
  },
  {
    email: 'harsh.kadyan2020@xyzcollege.com',
    day: 'Leave application for 2 days',
    bgcolor: '#40997E'
  },
  {
    email: 'harsh.kadyan2020@xyzcollege.com',
    day: 'Leave application for 2 days',
    bgcolor: '#FF2C96'
  },
  {
    email: 'harsh.kadyan2020@xyzcollege.com',
    day: 'Leave application for 2 days',
    bgcolor: '#40997E'
  },
  {
    email: 'harsh.kadyan2020@xyzcollege.com',
    day: 'Leave application for 2 days',
    bgcolor: '#FF2C96'
  },
  {
    email: 'harsh.kadyan2020@xyzcollege.com',
    day: 'Leave application for 2 days',
    bgcolor: '#40997E'
  },
  {
    email: 'harsh.kadyan2020@xyzcollege.com',
    day: 'Leave application for 2 days',
    bgcolor: '#FF2C96'
  },
]
export default function Academics() {

  const [newmail, setNewmail] = useState(mail)

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ width: '100%', height: '100vh', }}>
      <div className='head' style={{ width: '80%', height: '14vh', marginLeft: '16%' }}>
        <Header />
      </div>
      <div style={{ width: '100%', height: '86vh', }}>
        <div style={{ width: '20%', height: '100%', position: 'absolute', top: 0, marginLeft: '1%' }} >
          <SideBar />
        </div>


        <div className='d-flex ' style={{ justifyContent: "center", alignItems: "center" }}>
          <Lottie animationData={lotti} style={{ width: "31%", height: "25rem", marginLeft: "0rem", }} />
        </div>

        <div className=''>
          <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "40px", textAlign: "center", color: "#3C3C3C", }}>This feature will be live Soon !!</p>
        </div>

        <div className='text-center mt-5'>
          <NavLink to="/Dashboard"><button type="button" class="btn" style={{ background: "#F2EDFD", borderRadius: "5px", padding: "14px 55px", fontSize: "15px", fontWeight: "400", color: "#8147E7", border: "none", }}>Back To Dashboard</button></NavLink>
          {/* <NavLink to=""><button type="button" class="btn" style={{ background: "#8147E7", boxShadow: "0px 4px 12px rgba(126, 57, 247, 0.36)", borderRadius: "5px", color: "#FFFFFF", padding: "14px 55px", fontSize: "15px", fontWeight: "400", marginLeft: "20px", border: "none", }}>Join the Waitlist</button></NavLink> */}
        </div>

      </div>
    </div>
  )
}


const Newemail = (props) => {
  return (
    <a href='#' className='d-flex mb-2 p-2 text-decoration-none supportmail'>
      <p className='d-flex align-items-center justify-content-center fs-5 text-white rounded-pill' style={{ backgroundColor: props.item.bgcolor, height: 40, width: 40, }}>{props.item.name}</p>
      <div class="widget-content-left header-user-info px-2 text-start">
        <div class="widget-heading text-dark">{props.item.email}</div>
        <div class="widget-subheading" style={{ fontSize: 12, color: '#6D6D6D' }}>{props.item.day}</div>
      </div>
    </a>
  )
}