import React from "react";
import { FaCalendar, } from "react-icons/fa";
import { BiTimeFive, } from "react-icons/bi";
import EE from '../images/ee.png';
import { FiEye } from "react-icons/fi";
export default function Assignment() {
    const cardd = [
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
    ]

    const but = [
        {
            cont: 'potter',
            cont: 'dev',
            cont: '2023'
        },
        {
            cont: 'potter',
            cont: 'dev',
            cont: '2023'
        },
        {
            cont: 'potter',
            cont: 'dev',
            cont: '2023'
        },
        {
            cont: 'potter',
            cont: 'dev',
            cont: '2023'
        },
        {
            cont: 'potter',
            cont: 'dev',
            cont: '2023'
        },
        {
            cont: 'potter',
            cont: 'dev',
            cont: '2023'
        },
        {
            cont: 'potter',
            cont: 'dev',
            cont: '2023'
        },
        {
            cont: 'potter',
            cont: 'dev',
            cont: '2023'
        }, {
            cont: 'potter',
            cont: 'dev',
            cont: '2023'
        },

    ]

    const student = [
        {
            cont: 'potter',
            cont: 'dev',
            cont: '2020'
        },
        {
            cont: 'potter',
            cont: 'dev',
            cont: '2020'
        },
        {
            cont: 'potter',
            cont: 'dev',
            cont: '2020'
        },
        {
            cont: 'potter',
            cont: 'dev',
            cont: '2020'
        },
        {
            cont: 'potter',
            cont: 'dev',
            cont: '2020'
        },
        {
            cont: 'potter',
            cont: 'dev',
            cont: '2020'
        },
        {
            cont: 'potter',
            cont: 'dev',
            cont: '2020'
        },
        {
            cont: 'potter',
            cont: 'dev',
            cont: '2020'
        },
        {
            cont: 'potter',
            cont: 'dev',
            cont: '2020'
        },
    ]


    return (
        <>
            <div className="row mt-4">
                <div className="col-2">
                    <p style={{ paddingLeft: "30px", fontSize: "16px" }}>Total Assignment: 0</p>
                </div>
                <div className="col-2">
                    <p style={{ fontSize: "16px", color: "#6B6B6B" }}>Attempted : 0</p>
                </div>

                <div className="col-2">
                    <p style={{ paddingRight: "30px", fontSize: "16px", color: "#9E9E9E" }}>Unattempt : 0</p>
                </div>
                <div className="col-1">
                    <p style={{ fontSize: "16px", color: "#55DE64" }}>Passed : 0</p>
                </div>
                <div className="col-1">
                    <p style={{ fontSize: "16px", color: "#F93333" }}>Failed : 0</p>
                </div>
            </div>

            <div className="row" style={{ overflowY: "scroll", height: "50vh", marginLeft: "30px" }} >
                {/* map */}

                {/* {cardd.map((res, key) => {
                    return ( */}

                        <div className="col-3 mt-4"  style={{}}>
                           {/* <div class="card" style={{ width: "90%", background: "#FFFFFF", border: "1px solid #E6E6E6", borderRadius: "5px", }}>
                                <div className="card-body">
                                    <p style={{ backgroundColor: "#FFEFEF", width: "55px", paddingLeft: "7px", marginTop: "-16px", color: "#F93333", borderRadius: "0px 5px 0px 5px  ", marginLeft: "86%", fontSize: "13px", }}>Failed</p>
                                    <p className="" style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", }}>Articulate structure of C++ and Java in Semester 1</p>
                                    <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#9A9A9A", }}>Subject : Network Engineering</p>

                                    <div className="mt-2" style={{ display: "flex", }}>
                                        <p style={{ fontSize: "13px" }}> <FaCalendar />&nbsp;&nbsp;17-03-2023</p>
                                        <p className="mx-2" style={{ fontSize: "13px" }}><BiTimeFive /> &nbsp;12:23 PM - 01:50 AM</p>
                                    </div>

                                    <div className='mt-2' style={{ display: "flex" }}>
                                        <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C", }}>Passing Percentage </p>
                                        <p className="mx-3" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3D70F5", }}>70%</p>
                                    </div>

                                    <div className='mt-2' style={{ display: "flex" }}>
                                        <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C", }}>Scored Percentage </p>
                                        <p className="mx-4 mb-2" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#F93333", }}>10%</p>
                                    </div>


                                    <button type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" className='col-4 border-0 rounded-1 mx-auto'
                                        style={{ fontSize: "12px", backgroundColor: "#2C62EE", borderRadius: "5px", width: "100%", border: "none", color: "white", height: "35px" }}>View Details</button>
                                </div>
                </div>*/}


                            
                {  <div class="card" style={{ width: "90%", background: "#FFFFFF", border: "1px solid #E6E6E6", borderRadius: "5px", }} >
                <div className="card-body">
                    <p className='text-center p-5' style={{color:"red"}}>The data will be visible after creating Assignment</p>
                </div>
            </div>}
                        </div>

                    {/* )
                })} */}
            </div>

            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ width: "50%" }}>
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasRightLabel"></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">

                    <div className="row">
                        <div className="col-4">
                            <p className="" style={{ fontWeight: "bold" }}>Assignment Details</p>
                        </div>
                        <div className="col-lg-8 ">
                            <p className="" style={{
                                paddingTop: "5px", fontSize: "10px", paddingLeft: "10px",
                                backgroundColor: "#ECF2FF", color: "#3D70F5", width: "270px", height: "24px", borderRadius: "5px", marginLeft: "200px"
                            }}>Result Declared on 12:30 AM | 22 September 2023</p>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-2">
                            <img src={EE} style={{}} />
                        </div>

                        <div className="col-6">
                            <p style={{ fontWeight: "700" }}>Raj Anadkat</p>
                            <p style={{ fontWeight: "700" }}>Student ID : TIPSG5682</p>
                            <span style={{ color: "#2C62EE" }}> <FiEye />View Profile</span>
                        </div>
                    </div>


                    <p className="mt-2">Articulate structure of C++ and Java in Semester 1</p>
                    <div className="row mt-2">
                        <div className="col-2 ">
                            <p style={{ border: "1px solid #D3D3D3", fontSize: "10px", borderRadius: "5px", paddingLeft: "8px", height: "20px", paddingTop: "2px" }}>Batch 3CO - JVY</p>
                        </div>
                        <div className="col-2">
                            <p style={{ fontSize: "10px" }}><BiTimeFive /> &nbsp;12:23 PM</p>
                        </div>
                        <div className="col-2">
                            <p style={{ fontSize: "10px" }}> <FaCalendar />&nbsp;&nbsp;17-03-2023</p>
                        </div>
                        <div className="col-2">
                            <p style={{
                                fontSize: "10px",
                                borderRadius: "5px", paddingLeft: "16px", height: "20px", paddingTop: "2px", backgroundColor: "#ECFFEE", color: "#44CCA3"
                            }}>Status : Pass</p>
                        </div>
                    </div>

                    <p className="mt-3">Course  <span style={{ fontWeight: "bold" }}>B.Tech Spcl. in Health Informatics</span></p>
                    <p>Subject <span style={{ fontWeight: "bold" }}>Networking</span></p>
                    <p className="mt-2" style={{ fontWeight: "700" }}>Total Marks : 50</p>

                    <div>
                        <p className="mt-2">Passing Percentage &nbsp; 70%</p>

                        <p className="mt-1"><b>Instructor’s Attachments</b></p>

                        {/* mapping */}
                        <div className="row ">
                            {but.map((res, key) => {
                                return (
                                    <div className="col-4 mt-3" key={key} >
                                        <p className="py-1" style={{
                                            border: "2px solid #D7D7D7", borderRadius: "5px",
                                            fontSize: "10px", width: "92%", height: "25px"
                                        }}>Networking Mid Sem Question Paper.pdf</p>


                                    </div>


                                )
                            })}
                        </div>


                        <div className="row mt-5">
                            <p><b>Result</b></p>
                            <p>Student’s Attachments</p>
                            {student.map((res, key) => {
                                return (

                                    <div className="col-4 mt-2" key={key}>
                                        <p className="py-1" style={{
                                            border: "2px solid #D7D7D7", borderRadius: "5px",
                                            fontSize: "10px", width: "92%", height: "25px"
                                        }}>Networking Mid Sem Question Paper.pdf</p>
                                    </div>
                                )
                            })}
                        </div>
                        <p className="mt-3">Student’s Score</p>
                        <p style={{ border: "2px solid #D7D7D7", borderRadius: "5px", width: "7%" }}>32/50</p>

                        <p className="mt-2">Student’s Percentage &nbsp; 64%</p>

                        <p className="mt-2" style={{ fontWeight: "700" }}>Instructor’s Notes</p>
                        <p style={{ fontSize: "13px" }}>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit nte fermentum sit amet. Pellentesque commodo lacus at sodales sodales. </p>

                    </div>

                </div>
            </div>
        </>
    )
}