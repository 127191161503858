import React, { useState, useEffect } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import Lottie from "lottie-react";
import animationData from '../images/137560-sea-walk.json';
import lotti6 from '../images/97162-succeed-with-key-frame-assistant.json';
import { BiCalendar } from 'react-icons/bi';
import { RiUser3Fill } from 'react-icons/ri';
import DatePicker from "react-datepicker";
import { FiPhone, FiCalendar, } from "react-icons/fi";



export default function Paymake() {
  const [startDate, setStartDate] = useState(new Date())

  const [state15, setState15] = useState(0);
  const [click, setclick] = useState(false);
  const [Hadding, setHadding] = useState("Hold Tight");
  const [Hadding1, setHadding1] = useState("Analyse Your Questions");
  const [Pra, setPra] = useState('Do not referesh or Close this Screen');
  const [Lotties, setLotties] = useState(animationData);
  const [Da, setDa] = useState(0);

  click ?
    (setTimeout(() => {
      if (Da === 0) {
        setPra('Do not referesh or Close this Screen')
        setHadding("Please wait, while we")
        setHadding1("Translate Questions");
        setLotties(animationData);
        setDa(1)
        setState15(3);
        setclick(false);

      }

    }, 3000))

    : <></>

  const make = [
    {
      s: "1",
      name: "Machine",
      qty: "10",
      rate: "10,000",
      amount: "1,00,00"
    },
    {
      s: "1",
      name: "Machine",
      qty: "10",
      rate: "10,000",
      amount: "1,00,00"
    },
  ]

  return (
    <>
      <div style={{ display: state15 == 0 ? "block" : "none" }}>
        <p className=' mt-3' style={{ fontWeight: "600", color: "#3C3C3C", fontSize: "25px", marginLeft: "22%" }}>  <NavLink to="/Accounts" className="" style={{ zIndex: "999", }}>
          <BsArrowLeft className='' style={{ color: '#000000', fontSize: "25px", cursor: "pointer" }} />
        </NavLink > Make Payment</p>
        <div className="row mt-4" >
          <div className="col-4">
            <div className='card ' style={{ backgroundColor: "#ECF0FF", border: "none", width: "35%", marginLeft: "70%" }}>
              <div className='card-body'>
                <p style={{ fontWeight: "400", fontSize: "19px" }}>Total amount</p>
                <p style={{ fontWeight: "400", fontSize: "19px" }}>₹ 1,59,963</p>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className='card' style={{ backgroundColor: "#ECF0FF", border: "none", width: "35%", marginLeft: "35%" }}>
              <div className='card-body'>
                <p style={{ fontWeight: "400", fontSize: "19px" }}>Amount paid</p>
                <p style={{ fontWeight: "400", fontSize: "19px" }}>₹ 1,59,963</p>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className='card' style={{ backgroundColor: "#ECF0FF", border: "none", width: "35%" }}>
              <div className='card-body'>
                <p style={{ fontWeight: "400", fontSize: "19px" }}>Amount Pending</p>
                <p style={{ fontWeight: "400", fontSize: "19px" }}>₹ 0</p>
              </div>
            </div>
          </div>
        </div>

        <div className='row mt-5' style={{ marginLeft: "22%" }}>
          <div className='col-6'>
            <p style={{ fontSize: "17px", fontWeight: "400" }}>from</p>
            <p style={{ fontSize: "24px", fontWeight: "400" }}>RNS IT Solutions</p>
            <p style={{ fontSize: "400", fontSize: "18px" }}>101-201-301, Chanda Tower, Girnar Colony,<br />
              Gandhi Path Road, Vaishali Nagar, Jaipur - <br />302021</p>

            <p className='mt-2' style={{ fontSize: "18px", fontWeight: "400" }}>+91 702 334 0831</p>

            <div className='mt-4'>
              <p style={{ fontSize: "17px", fontWeight: "400" }}>To</p>
              <p style={{ fontSize: "24px", fontWeight: "400" }}>Narayan murthy</p>
              <p style={{ fontSize: "400", fontSize: "18px" }}>101-201-301, Chanda Tower, Girnar Colony,<br />
                Gandhi Path Road, Vaishali Nagar, Jaipur - <br />302021</p>

              <p className='mt-2' style={{ fontSize: "18px", fontWeight: "400" }}>+91 702 334 0831</p>
            </div>
          </div>

          <div className='col-6'>
            <p className='mt-4' style={{ fontSize: "24px", fontWeight: "400" }}>Product Details</p>
            <p className='mt-4 ' style={{ fontSize: "17px", fontWeight: "400" }}>Name</p>
            <p className='' style={{ fontSize: "24px", fontWeight: "400" }}>Narayanmurthy</p>

            <div className='row mt-4'>
              <div className='col-2'>
                <p style={{ fontSize: "17px", fontWeight: "400" }}>Qty.</p>
                <p style={{ fontSize: "24px", fontWeight: "400" }}>121</p>

              </div>
              <div className='col-3'>
                <p style={{ fontSize: "17px", fontWeight: "400" }}>Price/Unit</p>
                <p style={{ fontSize: "24px", fontWeight: "400" }}>₹ 12000</p>
              </div>
            </div>
          </div>

        </div>

        <button className='mt-4' onClick={() => { setState15(state15 + 1) }} style={{
          width: "20%", height: "40px", marginLeft: "45%",
          backgroundColor: "#5A81FE", border: "none", color: "#fff", borderRadius: "5px", boxShadow: "0px 4px 12px rgba(90, 129, 254, 0.25)"
        }}>Make Payment</button>
      </div>


      <div style={{ display: state15 == 1 ? "block" : "none" }}>
        <div style={{ paddingTop: "50px", marginLeft: "20%" }}  >
          <p className=" " style={{ fontWeight: "600", fontSize: "25px" }}>
            <BsArrowLeft className='' style={{ color: '#000000', fontSize: "25px", cursor: "pointer" }} onClick={() => { setState15(state15 - 1) }} />
            &nbsp; &nbsp;Make Payment </p>
          {/* <p className='ms-5' style={{fontWeight:"500"}}>#INV - 0081</p> */}
          <div>
            <div className='row pt-2 mt-4' style={{ border: "2 px solid #DDDDDD", backgroundColor: " #2F264C", width: "68%", color: "#FFFFFF", height: "42px", borderRadius: "5px", fontWeight: "400px" }}>
              <div className='col-2'>
                <p>#</p>
              </div>
              <div className='col-3'>
                <p>Product Name</p>
              </div>
              <div className='col-2'>
                <p className='ms-5'>QTY.</p>
              </div>
              <div className='col-2'>
                <p className='' style={{ marginLeft: "95px" }} >Rate</p>
              </div>
              <div className='col-3'>
                <p className='' style={{ marginLeft: "105px" }}  >Amount</p>
              </div>
            </div>

            {make.map((res, key) => {
              return (
                <>
                  <div className='row mt-3' style={{ width: "80%" }} key={key}>

                    <div className='col-2 '>
                      <p>{res.s}</p>
                    </div>
                    <div className='col-3'>
                      <p>{res.name}</p>
                    </div>
                    <div className='col-2'>
                      <p>{res.qty}</p>
                    </div>
                    <div className='col-2'>
                      <p>{res.rate}</p>
                    </div>
                    <div className='col-3'>
                      <p>{res.amount}</p>
                    </div>
                    <p className='mt-2' style={{ border: "2px solid #CACACA", width: "85%" }}></p>

                  </div>
                </>
              )
            })}




            <div className='row ' style={{ marginTop: "50px" }}>
              <div className="col-6">
                <p className='mt-1' style={{ fontWeight: "500", fontSize: "20px" }}>Payment To:</p>
                <p className='mt-1' style={{ fontWeight: "500", fontSize: "22px" }}>John Doe</p>

                <p className='mt-1' style={{ fontSize: "400", fontSize: "15px" }}>101-103 Chanda Tower, Opp. Grand Utsave <br />Marriage Garden, Main Gandhi Path Road, Vaishali <br />Nagar,
                  <br />Jaipur , Rajasthan - 302021 India</p>

                <div className="mt-4" style={{ display: "flex" }}>
                  <p style={{ fontSize: "15px" }}>GSTIN / LLP REG NO. / PROP. NUMBER:</p>
                  <p style={{ fontWeight: "500", color: "#000000", fontSize: "15px" }}>37AADCS0472N1Z1</p>

                </div>
                <div className='row mt-5 ms-5 ' style={{}}>
                  <div className='col-6  p-2 px-4' style={{ border: "0.752197px solid #434343", width: "150px", borderRadius: "4px", height: "40px" }}>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                      <label class="form-check-label" for="flexRadioDefault1">
                        <span>Pay Now</span>
                      </label>
                    </div>
                  </div>
                  <div className='col-6 ms-3 p-2 px-4 ' style={{ border: "0.752197px solid #434343", width: "150px", borderRadius: "4px", height: "40px" }}>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                      <label class="form-check-label" for="flexRadioDefault1">
                        <span>Pay Later</span>
                      </label>
                    </div>
                  </div>
                </div>

              </div>

              <div className='col-6 mt-1 ' >
                <div >
                  <p style={{ fontSize: "18px", fontWeight: "600" }}>Due Date</p>

                </div>
                <div>
                  {/* <p className=''  style={{fontSize:"18px",fontWeight:"400"}}><BiCalendar/>24 Feb 2023</p> */}
                  <span style={{
                    display: 'flex', backgroundColor: '', padding: '',
                    borderRadius: '5px', color: '#b5b5b5', fontSize: '13px',
                  }}>
                    <FiCalendar className='' style={{ fontSize: '20px', color: "#3C3C3C", marginTop: "17px", }} />
                    <div>
                      <DatePicker className="border-0  datepii"
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                    </div>
                  </span>
                </div>


                <div className='' style={{ marginTop: "120px" }}>
                  <p className='px-4 mt-5' style={{ display: "flex", }}>
                    <p style={{ fontSize: "20px", fontWeight: "400" }}>Total</p>
                    <p style={{ fontSize: "20px", fontWeight: "500" }}>&nbsp;₹ 5,000,00</p>
                  </p>
                  <button className="mt-5" onClick={() => { setState15(state15 + 1); setclick(true) }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRighti" aria-controls="offcanvasRight"
                    style={{
                      width: "35%", backgroundColor: " #5A81FE", border: "none",
                      height: "45px", color: " #FFFFFF", fontWeight: "500", borderRadius: "5px"
                    }}>Proceed</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Hold tight */}

      <div className="mt-5" style={{ display: state15 == 2 ? "block" : "none", cursor: "pointer" }}>

        <p className='mt-4' style={{ color: "#434343", fontWeight: "500", fontSize: "44px", textAlign: "center" }}>{Hadding}</p>
        <p style={{ textAlign: "center", fontWeight: "400", color: "#434343" }} >Redirecting to Payment Gateway <br></br>of Payment Processing</p>

        <div className='d-flex mt-2' style={{ justifyContent: "center", alignItems: "center" }}>
          <Lottie animationData={Lotties} style={{ width: "20%" }} />
        </div>

        <p className="mt-2" style={{ textAlign: "center", fontWeight: "400", color: "#434343" }}>Do not Refresh or Close this Window</p>




      </div>

      {/* payment success */}
      <div className="mt-5" style={{ display: state15 == 3 ? "block" : "none", cursor: "pointer" }}>

        <div className='d-flex mt-5' style={{ justifyContent: "center", alignItems: "center" }}>
          <Lottie animationData={lotti6} style={{ width: "7%" }} />
        </div>

        <p className='mt-3' style={{ color: " #2B9B2F", textAlign: "center", fontWeight: "500", fontSize: "30px" }}>Payment Successful</p>
        <div className='mt-5' style={{ display: "flex", color: "#434343", fontWeight: "500", fontSize: "22px" }}>
          <p style={{ paddingLeft: "44%" }}>Amount : </p>
          <p >₹ 1,59,698</p>
        </div>

        <div className='mt-5' style={{ border: "1px solid #B1B1B1", width: "29%", marginLeft: "36%", backgroundColor: "#F5F5F5", height: "16vh", borderRadius: "5px" }}>
          <p className='ms-5 mt-4' style={{ color: "#434343", fontWeight: "400" }}>Paid to</p>
          <p className='mt-2' style={{ paddingLeft: "59px", color: "#434343" }}><RiUser3Fill />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Narayanmurthy Furniture Private Limited</p>
        </div>




        <div className='mt-5 ' style={{ marginLeft: "45%" }}>
          <NavLink to="/Accounts">  <button style={{
            backgroundColor: "#5A81FE", color: "white", border: "none", fontWeight: "500", boxShadow: " 0px 4px 12px rgba(90, 129, 254, 0.25)",
            width: "200px", height: "40px", borderRadius: "5px", fontWeight: "500"
          }}>Back to Dashboard</button></NavLink>
        </div>
      </div>

    </>
  )
}
