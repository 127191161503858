import React from "react";
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { IoPersonOutline } from "react-icons/io5";
import { IoArrowForwardSharp } from "react-icons/io5";


const Notstarted = () => {
    return (
        <>
            <div className='container mt-5' style={{ overflowY: "scroll", height: "40vh", }}>
                <div className='row  ps-3' data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" style={{
                    border: "1px solid #DBDBDB", padding: "11px", background: "#FFFFFF", borderRadius: "5px",
                    borderLeft: '6px solid #6B6B6B'
                }}>

                    <div className='col-5'>
                        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", }}>Add a Quiz for Students of 3CO - JVY on the ...</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#EFEFEF", borderRadius: "5px", textAlign: "center", padding: "5px", color: "#3C3C3C", }}>06 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#FFF1F1", borderRadius: "5px", padding: "5px", textAlign: "center", color: "#F93333", }}>12 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ textAlign: "center", fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", padding: "5px", }}><IoPersonOutline style={{ color: "#434343", fontSize: "18px", }} /> Devesh Gupta</p>
                    </div>
                    <div className='col-1'>
                        <p className='mt-2'><IoArrowForwardSharp style={{ color: "#2188E7", float: "right", }} /></p>
                    </div>
                </div>
                <div className='row mt-2 ps-3' data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" style={{
                    border: "1px solid #DBDBDB", padding: "11px", background: "#FFFFFF", borderRadius: "5px",
                    borderLeft: '6px solid #6B6B6B'
                }}>

                    <div className='col-5'>
                        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", }}>Add a Quiz for Students of 3CO - JVY on the ...</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#EFEFEF", borderRadius: "5px", textAlign: "center", padding: "5px", color: "#3C3C3C", }}>06 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#FFF1F1", borderRadius: "5px", padding: "5px", textAlign: "center", color: "#F93333", }}>12 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ textAlign: "center", fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", padding: "5px", }}><IoPersonOutline style={{ color: "#434343", fontSize: "18px", }} /> Devesh Gupta</p>
                    </div>
                    <div className='col-1'>
                        <p className='mt-2'><IoArrowForwardSharp style={{ color: "#2188E7", float: "right", }} /></p>
                    </div>
                </div>
                <div className='row mt-2 ps-3' data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" style={{
                    border: "1px solid #DBDBDB", padding: "11px", background: "#FFFFFF", borderRadius: "5px",
                    borderLeft: '6px solid #6B6B6B'
                }}>

                    <div className='col-5'>
                        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", }}>Add a Quiz for Students of 3CO - JVY on the ...</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#EFEFEF", borderRadius: "5px", textAlign: "center", padding: "5px", color: "#3C3C3C", }}>06 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#FFF1F1", borderRadius: "5px", padding: "5px", textAlign: "center", color: "#F93333", }}>12 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ textAlign: "center", fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", padding: "5px", }}><IoPersonOutline style={{ color: "#434343", fontSize: "18px", }} /> Devesh Gupta</p>
                    </div>
                    <div className='col-1'>
                        <p className='mt-2'><IoArrowForwardSharp style={{ color: "#2188E7", float: "right", }} /></p>
                    </div>
                </div>
                <div className='row mt-2 ps-3' data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" style={{
                    border: "1px solid #DBDBDB", padding: "11px", background: "#FFFFFF", borderRadius: "5px",
                    borderLeft: '6px solid #6B6B6B'
                }}>

                    <div className='col-5'>
                        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", }}>Add a Quiz for Students of 3CO - JVY on the ...</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#EFEFEF", borderRadius: "5px", textAlign: "center", padding: "5px", color: "#3C3C3C", }}>06 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#FFF1F1", borderRadius: "5px", padding: "5px", textAlign: "center", color: "#F93333", }}>12 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ textAlign: "center", fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", padding: "5px", }}><IoPersonOutline style={{ color: "#434343", fontSize: "18px", }} /> Devesh Gupta</p>
                    </div>
                    <div className='col-1'>
                        <p className='mt-2'><IoArrowForwardSharp style={{ color: "#2188E7", float: "right", }} /></p>
                    </div>
                </div>
                <div className='row mt-2 ps-3' data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" style={{
                    border: "1px solid #DBDBDB", padding: "11px", background: "#FFFFFF", borderRadius: "5px",
                    borderLeft: '6px solid #6B6B6B'
                }}>

                    <div className='col-5'>
                        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", }}>Add a Quiz for Students of 3CO - JVY on the ...</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#EFEFEF", borderRadius: "5px", textAlign: "center", padding: "5px", color: "#3C3C3C", }}>06 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#FFF1F1", borderRadius: "5px", padding: "5px", textAlign: "center", color: "#F93333", }}>12 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ textAlign: "center", fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", padding: "5px", }}><IoPersonOutline style={{ color: "#434343", fontSize: "18px", }} /> Devesh Gupta</p>
                    </div>
                    <div className='col-1'>
                        <p className='mt-2'><IoArrowForwardSharp style={{ color: "#2188E7", float: "right", }} /></p>
                    </div>
                </div>
                <div className='row mt-2 ps-3' data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" style={{
                    border: "1px solid #DBDBDB", padding: "11px", background: "#FFFFFF", borderRadius: "5px",
                    borderLeft: '6px solid #6B6B6B'
                }}>

                    <div className='col-5'>
                        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", }}>Add a Quiz for Students of 3CO - JVY on the ...</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#EFEFEF", borderRadius: "5px", textAlign: "center", padding: "5px", color: "#3C3C3C", }}>06 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ background: "#FFF1F1", borderRadius: "5px", padding: "5px", textAlign: "center", color: "#F93333", }}>12 Dec 2023</p>
                    </div>
                    <div className='col-2'>
                        <p style={{ textAlign: "center", fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", padding: "5px", }}><IoPersonOutline style={{ color: "#434343", fontSize: "18px", }} /> Devesh Gupta</p>
                    </div>
                    <div className='col-1'>
                        <p className='mt-2'><IoArrowForwardSharp style={{ color: "#2188E7", float: "right", }} /></p>
                    </div>
                </div>


            </div>
        </>
    )
};

export default Notstarted;