import React, { useState, useEffect, useContext } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { BiCalendar } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';
import { AiOutlineCalendar } from 'react-icons/ai';
import { FiMail, FiPhone, FiCalendar, } from "react-icons/fi";
import { BsFillCalendarFill } from "react-icons/bs";
import TIPG from '../images/tipsglogo.png';
import { FiDownload } from "react-icons/fi"
import { AiOutlineLink } from 'react-icons/ai';
import { HiUser } from 'react-icons/hi';
import lotti from '../images/137560-sea-walk.json';
import lotti6 from '../images/97162-succeed-with-key-frame-assistant.json';
import Lottie from "lottie-react";
import { AuthContext } from './AuthContext';


export default function Debit() {


  const { apiToken } = useContext(AuthContext);

  const [startDate, setStartDate] = useState(new Date())
  const [startDate1, setStartDate1] = useState(new Date())
  const [state1, setState1] = useState(0);
  const [count1, setcount1] = useState(1)
  const [state11, setState11] = useState(0);
  const [valuee, setValuee] = useState(' ')
  const [tabled, setTabled] = useState(0)
  const [duedate, setDuedate] = useState(0)
  const [data, setData] = useState([]);


  const cre = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://lms.tipsg.in/api/debit_transactionsList", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status == "001") {
          setData(result.debit_Transactions);
        }


      })
      .catch(error => console.log('error', error));
  }


  const alli = [
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },


  ]

  const allii = [
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },
    {

      sn: '1',
      remark: "Hostel Fees",
      amount: "₹ 49,965",
      duedate: "12/11/2022"

    },


  ]

  const dd = [
    {
      sn: "1.",
      part: "Admission Fees",
      amount: "₹ 40,000"
    },
    {
      sn: "1.",
      part: "Admission Fees",
      amount: "₹ 40,000"
    }
  ]


  useEffect(() => {
   cre();
  }, [])
  
  return (
    <>


      {/* button */}

      <div className='row'>
        <div className='col-2 ' >
          <div style={{ display: "flex" }}>
            <p className='pt-3 ' style={{ color: "#434343", fontWeight: "500", fontSize: "17px", paddingLeft: "40px" }} >From</p>
            <span style={{
              display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "10px",
              borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
            }}>
              <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
              <div>
                <DatePicker className="border-0  ps-2 pt-1 datep"

                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
              </div>
            </span>
            <p className='pt-3 ps-1' style={{ color: "#434343", fontWeight: "500", fontSize: "17px" }}>to</p>
          </div>
        </div>

        <div className='col-2' style={{ marginLeft: "50px" }} >
          <div>

            <span style={{
              display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "10px",
              borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
            }}>
              <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
              <div>
                <DatePicker className="border-0  ps-2 pt-1 datep"
          
                  selected={startDate1}
                  onChange={(date) => setStartDate1(date)}
                  style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
              </div>
            </span>
          </div>
        </div>

        <div className='col-5 ms-4 text-end'>

        </div>
        <div className='col-1 ms-3 text-end'  >
          <NavLink to="/Makepayment">  <button className='' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightm" aria-controls="offcanvasRight"
            style={{ border: "1px solid #B63A3A", backgroundColor: "#FFFFFF", color: "#B63A3A", width: "150px", height: "38px", borderRadius: "5px" }} >Make Payment</button></NavLink>

          {/* <button className=''   data-bs-toggle="offcanvas" data-bs-target="#offcanvasRighti" aria-controls="offcanvasRight" style={{border:"2px solid #3AB690",backgroundColor:"#FFFFFF",color:"#3AB690",width:"150px",height:"38px",borderRadius:"5px"}}>Request Payment</button> */}
        </div>
        <div className='col-2'>

        </div>

      </div>







      {/* table */}
      <div>

        <div className='mt-4 '>
          <div className='row mx-4 mt-3'  >
          <div className='row' style={{ color: "#434343", fontWeight: "600", fontSize: "15px" }}>
              <div className='col-1 pb-2'>
                <p>S.No.</p>
              </div>
              <div className='col-2 pb-2'>
                <p>Name</p>
              </div>
              <div className='col-2 pb-2' >
                <p>Date & Time</p>
              </div>
              <div className='col-2 pb-2' >
                <p>Transaction ID</p>
              </div>
              <div className='col-2 pb-2'>
                <p>Amount</p>
              </div>

              <div className='col-2 pb-2'>
                <p>Payment Method</p>
              </div>
              <div className='col-1 pb-2'>
                <p>Status</p>
              </div>
            </div>
            <span>
              <div style={{ overflowY: "scroll", height: "60vh" }}>
                {data.length > 0 ? data.map((res, index) => {
                  return (
                    <>
                    <div className='row align-items-center  mt-3' style={{ width: "100%", borderRadius: "5px", height: "55px", backgroundColor: " #FFF4F4" }} >
                        <div className='col-1' key={res.id} >
                          <p style={{ color: "#434343", fontWeight: "400", fontSize: "15px" }}>{index + 1}</p>
                        </div>
                        <div className='col-2'>
                          <p style={{ fontWeight: "400", fontSize: "15px", textTransform: "capitalize" }}>{res.name}</p>
                        </div>

                        <div className='col-2'>
                          <p style={{ fontSize: "15px", color: "#3C3C3C", fontWeight: "500" }} >{res.txn_date}</p>
                        </div>
                        <div className='col-2'>
                          <p style={{ fontSize: "15px", color: "#434343", fontWeight: "400" }} >{res.ref_id.length > 0 ? res.ref_id : "NA"}</p>
                        </div>


                        <div className='col-2'>
                          <p style={{ fontSize: "15px", color: "#434343", fontWeight: "400" }} >{res.amount}</p>
                        </div>
                        <div className='col-2'>
                          <p style={{ fontSize: "15px", fontWeight: "600", color: "green" }} >{res.txn_method}</p>
                        </div>
                        <div className='col-1'>
                          <p style={{ fontSize: "15px", fontWeight: "600", color: "green" }} >{res.txn_status == "1" ? "Success" : ""}</p>
                        </div>




                      </div>


                    </>
                  )
                }) : <>
                  <div className="d-flex mt-5" style={{ justifyContent: "center", color: "red", alignItems: "center" }}><h6>No Data Available</h6></div>
                </>
                }
              </div>
            </span>

            {/* offcanvas */}
            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ width: "45%" }}>
              <div class="offcanvas-header">

                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>  <p className='px-3 pt-1' style={{ backgroundColor: "#F0F0F0", width: "38%", marginLeft: '60%', borderRadius: "5px", height: "auto", padding: "5px", }}>Invoice no.: #03032023100</p>
              <div class="offcanvas-body">
                <img src={TIPG} style={{ width: "148px" }} />
                <p className='mt-4' style={{ color: "#5A5A5A", fontWeight: "400" }}>Redwood High School</p>

                <div className='row mt-4'>
                  <div className='col-3'>
                    <div className=''>
                      {/* <button className='text-center pt-1 ' style={{borderRadius:"5px",color:"#5A5A5A" ,width:"190px",height:"45px",
                      backgroundColor:"transparent",border:"1px solid #000000",fontWeight:"400"}}><FiDownload/>&nbsp; &nbsp;Download Receipt</button> */}
                      <div class='file' style={{ height: "45px" }}>

                        <label for='input-file' style={{ height: "45px", width: "190px" }} >
                          <FiDownload style={{ color: "#BDBDBD" }} />&nbsp;&nbsp;<span style={{ color: "#BDBDBD" }}>Download Receipt</span>
                        </label>
                        <input id='input-file' type='file' />
                      </div>
                    </div>
                  </div>

                  <div className='col-3'>

                  </div>

                  <div className='col-3'>


                  </div>
                </div>

                <div className='mt-4' style={{ display: "flex" }}>
                  <p style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "600" }}>Name of Student :</p>
                  <p className='px-2' style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "400" }}>Tushar Singh</p>
                </div>

                <div className='mt-3' style={{ display: "flex" }}>
                  <p style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "600" }}>Batch:</p>
                  <p className='px-2' style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "400" }}>3CO-JVY</p>
                </div>

                <div className='mt-3' style={{ display: "flex" }}>
                  <p style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "600" }}>Date Of Payment:</p>
                  <p className='px-2' style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "400" }}>3 April 2023</p>
                </div>

                <div className='mt-3' style={{ display: "flex" }}>
                  <p style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "600" }}>Mode Of Payment:</p>
                  <p className='px-2' style={{ fontSize: "17px", color: "#5A5A5A", fontWeight: "400" }}>UPI</p>
                </div>


                <div className='row  ms-1 mt-4 pt-2' style={{ backgroundColor: "#E6EDFF", width: "84%", height: "40px" }}>
                  <div className='col-4'>
                    <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}>S.No</p>
                  </div>
                  <div className='col-4'>
                    <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}>Particulars</p>
                  </div>
                  <div className='col-4'>
                    <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}>Amount</p>
                  </div>
                </div>
                {dd.map((res, key) => {
                  return (
                    <>
                      <div className='row ms-1 mt-2 ' style={{ width: "84%" }}>
                        <div className='col-4'>
                          <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}>{res.sn}</p>
                        </div>
                        <div className='col-4'>
                          <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}> {res.part}</p>
                        </div>
                        <div className='col-4'>
                          <p style={{ fontWeight: "400", color: "#5A5A5A", fontSize: "16px" }}> {res.amount}</p>
                        </div>
                        <p className='ms-1' style={{ border: " 1px solid #E8E8E8", width: "100%" }}></p>
                      </div>
                    </>
                  )
                })}


                <div className='row mt-5'>
                  <div className='col-6 text-center'>
                    <div className='pt-2' style={{ border: "1px solid #E8E8E8", width: "150px", height: "80px", borderRadius: "5px", }}>
                      <p style={{ color: "#5A5A5A", fontWeight: "400" }}>Grand Total</p>
                      <p style={{ color: "#5A5A5A", fontWeight: "400", fontSize: "25px" }}>₹ 80000</p>
                    </div>
                  </div>

                  <div className='col-6 text-center'>
                    <button className='mt-4' data-bs-dismiss="offcanvas" aria-label="Close" style={{ width: "160px", height: "40px", backgroundColor: "#8147E7", border: "none", color: "#fff", borderRadius: "5px" }}>Save</button>
                  </div>
                </div>



              </div>

            </div>


          </div>
        </div>
      </div>
    </>
  )
}





function Ai(props) {
  return (
    <>
      <div className='col-3'>

        <p className='pt-2'>{props.res.sn}</p>
      </div>

      <div className='col-3'>

        <p className='pt-2'>{props.res.remark}</p>
      </div>

      <div className='col-3'>

        <p className='pt-2' >{props.res.amount}</p>
      </div>

      <div className='col-3'>
        <p className='pt-2'>{props.res.duedate}</p>
      </div>

    </>
  );
}

function Aiii(props) {
  return (
    <>
      <div className='col-3'>

        <p className='pt-2'>{props.res.sn}</p>
      </div>

      <div className='col-3'>

        <p className='pt-2'>{props.res.remark}</p>
      </div>

      <div className='col-3'>

        <p className='pt-2' >{props.res.amount}</p>
      </div>

      <div className='col-3'>
        <p className='pt-2'>{props.res.duedate}</p>
      </div>

    </>
  );
}
