import React, { useState, useContext } from 'react';
import Header from './header';
import SideBar from './sidebar';
import { BiPlus } from 'react-icons/bi';
import { HiDotsVertical } from 'react-icons/hi';
import { MdModeEdit } from 'react-icons/md';
import { MdDelete } from 'react-icons/md';
import { ImLocation2 } from 'react-icons/im';
import { ImClock2 } from 'react-icons/im';
import { FaBook } from 'react-icons/fa';
import { BiChevronRight } from 'react-icons/bi';
import { NavLink, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Table from 'react-bootstrap/Table';
import { alignPropType } from 'react-bootstrap/esm/types';
import "@fontsource/poppins";
import { AuthContext } from './AuthContext';
import { useEffect } from 'react';
import { useRequireAuth } from './RequireAuth';
import { API_URL,img_url } from '../env';
import { ToastContainer, toast } from 'react-toastify';

export default function Dashboard() {
 // useRequireAuth('/code');
  const { apiToken } = useContext(AuthContext);
  const [tbldata, setTbldata] = useState([]);
  const [total_student, settotal_student] = useState(0);
  const [total_employee, settotal_employee] = useState(0);
  const [feesOut, setFeesOut] = useState('0');
  const [fees, setFees] = useState('0');
  const [debit,setDebit] = useState(0);
  const [credit,setCredit] = useState(0);
  const [attendance,setAttendance] = useState(0);
  const [todayCollections, setTodayCollections] = useState(0);
  const [closeComplaints,setCloseComplaints] = useState(0);
  const[newComplaints,setNewComplaints] = useState(0);
  const Navigate = useNavigate();


  const getData = async()=>{
    try{
      var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${apiToken}`);

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${API_URL}/admin/dashboard/view/admin/dashboard`, requestOptions)
  .then(response => response.json())
  .then((result) => {
    if (result.status == 1) { 
      setTodayCollections(result.fees.missed_amount);
      setFees(result.fees.received_amount)
      setFeesOut(result.fees.upcoming_amount)
      settotal_student(result.users.TOTAL_STUDENTS)
      settotal_employee(result.users.total_employee)
      setDebit(result.amount.debit_amount);
      setCredit(result.amount.received_amount);
      setAttendance(result.attendence);
      setTbldata(result.department_list)
      setCloseComplaints(result.complaints.solved_complain);
      setNewComplaints(result.complaints.unsolved_complain);
  } 
  else if (result.status === 'VAL_ERR') { 
      toast(result.Backend_Error) 
    } 
  else if (result.status == 'FILE_ERR') { 
      toast(result.Backend_Error) 
    } 
   else if (result.status == "CUSTOM_ERR") { 
      toast(result.Backend_Error); 
    } else if (result.status == "TOKEN_ERR") { 
      localStorage.removeItem('api_url'); 
      Navigate('/code') 
      toast("Logged in other device! Please login again") 
    } 
    else { 
      console.log(result) 
    }
  })
  .catch(error => console.log('error', error));
    }catch(e){
      console.log(e)
    }
  }


  let readings = [
    {
      value: 1,
      backgroundcolor: '#6ADBB9',
      textcolor: '#6ADBB9'
    },
    {
      value: 1,
      backgroundcolor: '#F1B34C',
      textcolor: '#F1B34C'
    },
    {
      value: 1,
      backgroundcolor: '#F03838',
      textcolor: '#F03838'
    },
    {
      value: 97,
      backgroundcolor: '#6E6B97',
      textcolor: '#6E6B97'
    }
  ]

  const [bgcolor, setbgcolor] = useState(readings)
  let exam = [
    {
      examname: 'Get to attend a seminar at Bungee University at',
      time: '15:20 pm',
      location: 'Faridabad',
      time2: '3:00 pm - 4:00 pm'
    },
    {
      examname: 'Get to attend a seminar at Bungee University at',
      time: '15:20 pm',
      location: 'Faridabad',
      time2: '3:00 pm - 4:00 pm'
    },
    {
      examname: 'Get to attend a seminar at Bungee University at',
      time: '15:20 pm',
      location: 'Faridabad',
      time2: '3:00 pm - 4:00 pm'
    },
    {
      examname: 'Get to attend a seminar at Bungee University at',
      time: '15:20 pm',
      location: 'Faridabad',
      time2: '3:00 pm - 4:00 pm'
    },
    {
      examname: 'Get to attend a seminar at Bungee University at',
      time: '15:20 pm',
      location: 'Faridabad',
      time2: '3:00 pm - 4:00 pm'
    },
    {
      examname: 'Get to attend a seminar at Bungee University at',
      time: '15:20 pm',
      location: 'Faridabad',
      time2: '3:00 pm - 4:00 pm'
    },
  ]

  const [test, setTest] = useState(exam);


  useEffect(() => {
    getData();
  }, [])




  return (
    <div style={{ width: '100%', height: '100vh', }}>
      <div className='head' style={{ width: '80%', height: '14vh', marginLeft: '16%' }}>
        <Header />
      </div>
      <div className='' style={{ width: '100%', height: '86vh', display: "flex" }}>
        <div style={{ width: '0%', height: '100%', position: 'absolute', top: 0, marginLeft: '1%' }} >
          <SideBar />
        </div>
        <div className='maincontain' style={{ marginLeft: '15%', width: '100%', height: '100%', overflowY: 'scroll', }} >
          <div className='row mx-4'>
            <div className='col-lg-7 col-md-12 col-sm-12 mb-4'>
              <NavLink to="/Accounts2" style={{ textDecoration: "none", }}><div className="card card1" style={{}}>
                <div className="card-body">
                  <p className='text-start pt-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "20px", color: "#FFFFFF", letterSpacing: "1px", }}>Total Missed Fees : : <span>₹ {todayCollections}</span></p>
                  <div className='d-flex justify-content-between pt-4'>
                    <p className='text-start' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#FFFFFF", }}> Total collect Fees: <span>₹ {fees}</span></p>
                    {/* <p className=' px-5'><u>Download Report</u></p> */}
                  </div>
                  <div className='d-flex justify-content-between'>
                    <p className='text-start mt-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#FFFFFF", }}>Total Upcoming Fees : <span>₹ {feesOut}</span></p>
                    {/* <p className=' px-5'><u>Download Report</u></p> */}
                  </div>
                </div>
              </div></NavLink>
            </div>
            <div className='col-4 mt-0 ms-4'>
              <div className='d-flex align-items-center justify-content-between p-2' style={{ border: "1px solid #E4E2F1", borderRadius: 10, }}>
                <p className='' style={{ color: '#B1AFC8', fontStyle: "normal", fontWeight: "400", fontSize: "12px", }}>Total Debit Amount</p>
                <p className='' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "20px", color: "#13BC84", }}>₹ {debit}</p>
                {/* <div className='row g-0 justify-content-center align-items-center'>
                  <p className='col-7 text-start px-3 mb-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "20px", color: "#13BC84", }}>₹ {debit}</p>
                  <p className='col-5 mb-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#B1AFC8", }}>From Previous Month</p>
                </div> */}
              </div>
              <div className='d-flex align-items-center justify-content-between p-2 mt-2' style={{ border: "1px solid #E4E2F1", borderRadius: 10, }}>
                <p className=' ' style={{ color: '#B1AFC8', fontStyle: "normal", fontWeight: "400", fontSize: "12px", }}>Total credit Amount</p>
                <p className='' style={{ color: '#E2A925', fontStyle: "normal", fontWeight: "400", fontSize: "20px", }}>₹ {credit}</p>
                {/* <div className='row g-0 justify-content-center align-items-center'>
                  <p className='col-7 text-start px-3 mb-2' style={{ color: '#E2A925', fontStyle: "normal", fontWeight: "400", fontSize: "20px", }}>₹ {credit}</p>
                  <p className='col-5 mb-2 text-center' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#B1AFC8", }}>Pending</p>
                </div> */}
              </div>
            </div>
          </div>
          <div className='row mx-4'>
            <div className='col-7 '>
              <div className="card card2" style={{height:'160px'}}>
                <div className="card-body">
                  <p className='text-start px-1' style={{ fontWeight: '600' }}>Attendance</p>
                  <div >
    <Table  >
      {/* striped bordered hover */}
      <thead>
        <tr>
          <th className='' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#54536E", borderRight: "1px solid #E4E2F1", }}><span className='d-flex justify-content-between'>Total Students<button type='button' className='border-0 bg-transparent'>
          </button>
          </span>
          </th>
          <th className='' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#54536E", borderRight: "1px solid #E4E2F1", }}><span className='d-flex justify-content-between'>Total Present<button type='button' className='border-0 bg-transparent'>
            </button>
          </span>
          </th>
          <th className='' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#54536E", borderRight: "1px solid #E4E2F1", }}><span className='d-flex justify-content-between'>Total Absent<button type='button' className='border-0 bg-transparent'>
         
          </button>
          </span>
          </th>
          <th className='' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#54536E",}}><span className='d-flex justify-content-between'>Total Half-Day<button type='button' className='border-0 bg-transparent'><svg xmlns="http://www.w3.org/2000/svg" width="30" height="15" viewBox="0 0 34.578 17">
            {/* <g id="Group_61" data-name="Group 61" transform="translate(-1431.5 -445)" style={{fontStyle:"normal",fontWeight:"400",fontSize:"16px",color:"#54536E",}}>
              <g id="Group_59" data-name="Group 59" transform="translate(-10 6.5)">
                <line id="Line_58" data-name="Line 58" x2="17" transform="translate(1442.5 439.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                <line id="Line_59" data-name="Line 59" x2="11" transform="translate(1448.5 444.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                <line id="Line_60" data-name="Line 60" x2="7" transform="translate(1452.5 449.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                <line id="Line_61" data-name="Line 61" x2="5" transform="translate(1454.5 454.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
              </g>
              <g id="Group_60" data-name="Group 60" transform="translate(0.413 0)">
                <path id="Icon_awesome-caret-up" data-name="Icon awesome-caret-up" d="M11.216,19.228H1.547a.751.751,0,0,1-.531-1.283L5.85,13.111a.751.751,0,0,1,1.063,0l4.834,4.834A.751.751,0,0,1,11.216,19.228Z" transform="translate(1453.697 433.109)" fill="#6e6b97" />
                <path id="Icon_awesome-caret-up-2" data-name="Icon awesome-caret-up" d="M10.421,6.337H.753A.751.751,0,0,1,.222,5.054L5.056.22a.751.751,0,0,1,1.063,0l4.834,4.834A.751.751,0,0,1,10.421,6.337Z" transform="translate(1465.665 461) rotate(180)" fill="#6e6b97" />
              </g>
            </g> */}
          </svg>
          </button>
          </span>
          </th>
        </tr>
      </thead>
    </Table>
    <div className='container' >
    <div className='row'>
    <div className='col-lg-3'>
      <p>{attendance.TOTAL_STUDENTS}</p>
    </div>
    <div className='col-lg-3'>
      <p>{attendance.PRESENT_STUDENTS}</p>
    </div> <div className='col-lg-3'>
      <p>{attendance.ABSENT_STUDENTS}</p>
    </div> <div className='col-lg-3'>
      <p>{attendance.HALFDAY_STUDENTS}</p>
    </div>

    </div>
    </div>
  </div>
  
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-6'>
                  <div className='card p-2' style={{ border: '1px solid #B1AFC8' }}>
                    <div className='row'>


                      <div className='col-10'>
                        <div className='' style={{ display: 'flex', alignItems: 'center',padding:'10px'}}>
                          <div className='d-flex align-items-center justify-content-center' style={{ backgroundColor: '#FFEEEE', width: 50, height: 50, borderRadius: 50 }}>
                            <FaBook style={{ color: '#F03838', fontSize: 22 }} />
                          </div>
                          
                          <div className='px-2'>
                            <p style={{ fontSize: 22, textAlign: 'justify', color: '#F03838' }}>{total_student}</p>
                            
                            <p style={{ color: '#6E6B97', fontSize: 14, fontWeight: 500 }}>Total Students</p>
                          </div>
                          
                        </div>
                      </div>

                      
                      <div className='col-2 d-flex align-items-center text-end'>
                      <NavLink to='/Students' className="text-decoration-none text-dark">
                        <BiChevronRight style={{ textAlign: 'end', fontSize: 30, color: '#000000', cursor: "pointer" }} /></NavLink>
                      </div>
                      
                     
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='card p-2' style={{ border: '1px solid #B1AFC8' }}>
                    <div className='row'>
                      <div className='col-10'>
                        <div className='' style={{ display: 'flex', alignItems: 'center', padding:'10px'}}>
                          <div className='d-flex align-items-center justify-content-center' style={{ backgroundColor: '#FFEEF8', width: 50, height: 50, borderRadius: 50 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="23.648" height="23.954" viewBox="0 0 35.648 24.954">
                              <path id="Icon_awesome-users" data-name="Icon awesome-users" d="M5.347,12.944A3.565,3.565,0,1,0,1.782,9.38,3.568,3.568,0,0,0,5.347,12.944Zm24.954,0A3.565,3.565,0,1,0,26.736,9.38,3.568,3.568,0,0,0,30.3,12.944Zm1.782,1.782H28.519a3.554,3.554,0,0,0-2.512,1.036,8.147,8.147,0,0,1,4.183,6.094h3.676a1.78,1.78,0,0,0,1.782-1.782V18.292A3.568,3.568,0,0,0,32.083,14.727Zm-14.259,0a6.238,6.238,0,1,0-6.238-6.238A6.235,6.235,0,0,0,17.824,14.727ZM22.1,16.509H21.64a8.613,8.613,0,0,1-7.631,0h-.462A6.418,6.418,0,0,0,7.13,22.926v1.6A2.674,2.674,0,0,0,9.8,27.2H25.845a2.674,2.674,0,0,0,2.674-2.674v-1.6A6.418,6.418,0,0,0,22.1,16.509Zm-12.46-.746A3.554,3.554,0,0,0,7.13,14.727H3.565A3.568,3.568,0,0,0,0,18.292v1.782a1.78,1.78,0,0,0,1.782,1.782H5.453A8.168,8.168,0,0,1,9.642,15.763Z" transform="translate(0 -2.25)" fill="#f730a2" />
                            </svg>
                          </div>
                          <div className='px-2'>
                            <p style={{ fontSize: 22, textAlign: 'justify', color: '#28C78D' }}>{total_employee}</p>
                            <p style={{ color: '#6E6B97', fontSize: 14, fontWeight: 500 }}>Total Employees</p>
                          </div>
                        </div>
                      </div>
                      <div className='col-2 d-flex align-items-center text-end'>
                      <NavLink to='/Team' className="text-decoration-none text-dark">
                        <BiChevronRight style={{ textAlign: 'end', fontSize: 30, color: '#000000', cursor: "pointer" }} /></NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-9'>
                  <div className='card p-2' style={{ border: '1px solid #B1AFC8' }}>
                    <div className='row'>
                      <div className='col-10'>
                        <div className='' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',padding:'13px'}}>
                          <p style={{ paddingLeft: 0, fontSize: '1.5em' }}>Complaints</p>
                          <div className='px-2'>
                            <p style={{ fontSize: 22, textAlign: 'center', color: '#40997E' }}>{closeComplaints}</p>
                            <p style={{ color: '#6E6B97', fontSize: 12, fontWeight: 500 }}>Closed</p>
                          </div>
                          {/* <div className='px-2'>
                            <p style={{ fontSize: 22, textAlign: 'justify', color: '#A78B5C' }}>0</p>
                            <p style={{ color: '#6E6B97', fontSize: 12, fontWeight: 500 }}>Open</p>
                          </div> */}
                          <div className='px-2'>
                            <p style={{ fontSize: 22, textAlign: 'center', color: '#F03838' }}>{newComplaints}</p>
                            <p style={{ color: '#6E6B97', fontSize: 12, fontWeight: 500 }}>New</p>
                          </div>
                        </div>
                      </div>
                      <div className='col-2 d-flex align-items-center '>
                      <NavLink to='/Support' className="text-decoration-none text-dark">
                        <BiChevronRight style={{ textAlign: 'end', fontSize: 30, color: '#000000', cursor: "pointer" }} /></NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='col-3'>
                  <div className='card p-2' style={{ border: '1px solid #B1AFC8',}}>
                    <div className='row'>
                      <div className='' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',padding:'10px' }}>
                        <p style={{ color: '#6E6B97', fontSize: 22, }}>0</p>
                        <BiChevronRight style={{ textAlign: 'end', fontSize: 30, color: '#000000', cursor: "pointer" }} />
                      </div>
                      <p style={{ color: '#6E6B97', fontSize: 14, fontWeight: 500, textAlign: 'start' }}>Job Openings</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className='col-lg-4 col-md-12 col-sm-12 mt-2 ms-4'>

              <div className='card rounded-0' style={{ borderColor: '#E4E2F1' }}>
                <div className='row g-0 p-2' style={{ borderBottom: '1px solid #E4E2F1' }}>
                  <p className='col-8 text-start' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "18px", color: "#54536E", }}>Department  </p>
                  {/* <button type='button' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" className='col-4 border-0 rounded-1 mx-auto' style={{ width: 120, height: 40, backgroundColor: '#E4E2F1', color: '#54536E' }}>View Details</button> */}
                </div>
                <div className='bar7'>
                  <Table >
                    {/* striped bordered hover */}
                    <thead>
                      <tr>
                        <th className='' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#54536E", borderRight: "1px solid #E4E2F1", }}><span className='d-flex justify-content-between'>S.No<button type='button' className='border-0 bg-transparent'><svg xmlns="http://www.w3.org/2000/svg" width="30" height="15" viewBox="0 0 34.578 17">
                          <g id="Group_61" data-name="Group 61" transform="translate(-1431.5 -445)" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#54536E", }}>
                            <g id="Group_59" data-name="Group 59" transform="translate(-10 6.5)">
                              <line id="Line_58" data-name="Line 58" x2="17" transform="translate(1442.5 439.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                              <line id="Line_59" data-name="Line 59" x2="11" transform="translate(1448.5 444.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                              <line id="Line_60" data-name="Line 60" x2="7" transform="translate(1452.5 449.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                              <line id="Line_61" data-name="Line 61" x2="5" transform="translate(1454.5 454.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                            </g>
                            <g id="Group_60" data-name="Group 60" transform="translate(0.413 0)">
                              <path id="Icon_awesome-caret-up" data-name="Icon awesome-caret-up" d="M11.216,19.228H1.547a.751.751,0,0,1-.531-1.283L5.85,13.111a.751.751,0,0,1,1.063,0l4.834,4.834A.751.751,0,0,1,11.216,19.228Z" transform="translate(1453.697 433.109)" fill="#6e6b97" />
                              <path id="Icon_awesome-caret-up-2" data-name="Icon awesome-caret-up" d="M10.421,6.337H.753A.751.751,0,0,1,.222,5.054L5.056.22a.751.751,0,0,1,1.063,0l4.834,4.834A.751.751,0,0,1,10.421,6.337Z" transform="translate(1465.665 461) rotate(180)" fill="#6e6b97" />
                            </g>
                          </g>
                        </svg>
                        </button>
                        </span>
                        </th>
                        <th className='' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#54536E", borderRight: "1px solid #E4E2F1", }}><span className='d-flex justify-content-between'>Name<button type='button' className='border-0 bg-transparent'><svg xmlns="http://www.w3.org/2000/svg" width="30" height="15" viewBox="0 0 34.578 17">
                          <g id="Group_61" data-name="Group 61" transform="translate(-1431.5 -445)" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#54536E", }}>
                            <g id="Group_59" data-name="Group 59" transform="translate(-10 6.5)">
                              <line id="Line_58" data-name="Line 58" x2="17" transform="translate(1442.5 439.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                              <line id="Line_59" data-name="Line 59" x2="11" transform="translate(1448.5 444.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                              <line id="Line_60" data-name="Line 60" x2="7" transform="translate(1452.5 449.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                              <line id="Line_61" data-name="Line 61" x2="5" transform="translate(1454.5 454.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                            </g>
                            <g id="Group_60" data-name="Group 60" transform="translate(0.413 0)">
                              <path id="Icon_awesome-caret-up" data-name="Icon awesome-caret-up" d="M11.216,19.228H1.547a.751.751,0,0,1-.531-1.283L5.85,13.111a.751.751,0,0,1,1.063,0l4.834,4.834A.751.751,0,0,1,11.216,19.228Z" transform="translate(1453.697 433.109)" fill="#6e6b97" />
                              <path id="Icon_awesome-caret-up-2" data-name="Icon awesome-caret-up" d="M10.421,6.337H.753A.751.751,0,0,1,.222,5.054L5.056.22a.751.751,0,0,1,1.063,0l4.834,4.834A.751.751,0,0,1,10.421,6.337Z" transform="translate(1465.665 461) rotate(180)" fill="#6e6b97" />
                            </g>
                          </g>
                        </svg>
                        </button>
                        </span>
                        </th>
                        <th className='' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#54536E", borderRight: "1px solid #E4E2F1", }}><span className='d-flex justify-content-between'>Total Users<button type='button' className='border-0 bg-transparent'><svg xmlns="http://www.w3.org/2000/svg" width="30" height="15" viewBox="0 0 34.578 17">
                          {/* <g id="Group_61" data-name="Group 61" transform="translate(-1431.5 -445)" style={{fontStyle:"normal",fontWeight:"400",fontSize:"16px",color:"#54536E",}}>
                            <g id="Group_59" data-name="Group 59" transform="translate(-10 6.5)">
                              <line id="Line_58" data-name="Line 58" x2="17" transform="translate(1442.5 439.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                              <line id="Line_59" data-name="Line 59" x2="11" transform="translate(1448.5 444.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                              <line id="Line_60" data-name="Line 60" x2="7" transform="translate(1452.5 449.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                              <line id="Line_61" data-name="Line 61" x2="5" transform="translate(1454.5 454.5)" fill="none" stroke="#6e6b97" stroke-linecap="round" stroke-width="2" />
                            </g>
                            <g id="Group_60" data-name="Group 60" transform="translate(0.413 0)">
                              <path id="Icon_awesome-caret-up" data-name="Icon awesome-caret-up" d="M11.216,19.228H1.547a.751.751,0,0,1-.531-1.283L5.85,13.111a.751.751,0,0,1,1.063,0l4.834,4.834A.751.751,0,0,1,11.216,19.228Z" transform="translate(1453.697 433.109)" fill="#6e6b97" />
                              <path id="Icon_awesome-caret-up-2" data-name="Icon awesome-caret-up" d="M10.421,6.337H.753A.751.751,0,0,1,.222,5.054L5.056.22a.751.751,0,0,1,1.063,0l4.834,4.834A.751.751,0,0,1,10.421,6.337Z" transform="translate(1465.665 461) rotate(180)" fill="#6e6b97" />
                            </g>
                          </g> */}
                        </svg>
                        </button>
                        </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
  {tbldata.map((item, index) => (
    <BasicExample
      key={index}
      sno={index + 1}
      name={item.name}
      totalUsers={item.total_users}
    />
  ))}
</tbody>


                  </Table>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    <ToastContainer/>
    </div>
  )
}

const Color = (props) => {
  return (
    <div className="value" style={{ 'backgroundColor': props.item.backgroundcolor, 'width': props.item.value + '%' }}>
    </div>
  )
}

const VariantsExample = (props) => {
  return (
    <>
      <div className='row px-2 d-flex align-items-center'>
        <div className='col-7'><p className='text-start py-1' style={{ fontSize: '0.7em' }}>{props.item.examname}&nbsp;{props.item.time}</p></div>
        <div className='col-3'>
          <Dropdown>
            <Dropdown.Toggle variant="" id="dropdown-basic" style={{ display: 'flex', backgroundColor: '#FFEEEE', color: '#FF1414', marginRight: 40, alignItems: 'center', padding: 2 }}>
              <p className='px-1' style={{ fontSize: '0.9em' }}>Pending</p>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#">In Progress</Dropdown.Item>
              <Dropdown.Item href="#">Completed</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className='col-2'>
          <div className="dropdown">
            <button className="" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ border: 'none', backgroundColor: 'transparent' }}>
              <HiDotsVertical style={{ fontSize: '1em', color: '#54536E' }} />
            </button>
            <ul className="dropdown-menu">
              <li className=''><a className="dropdown-item d-inline-flex justify-content-between" href="#">Edit <MdModeEdit /></a></li>
              <li><a className="dropdown-item d-inline-flex justify-content-between" href="#">Delete<MdDelete style={{ color: 'red' }} /></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className='row px-2 py-1 mt-2' style={{ backgroundColor: '#F3F2F8' }}>
        <div className='col-6 text-start fw-bolder' style={{ fontSize: '0.8em' }}><ImLocation2 />&nbsp;{props.item.location}</div>
        <div className='col-6 text-end fw-bolder' style={{ fontSize: '0.8em' }}><ImClock2 />&nbsp;{props.item.time2}</div>
      </div>
    </>
  )
}
function BasicExample({ sno, name, totalUsers }) {
  return (
    <tr>
      <td style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#54536E", borderRight: "1px solid #E4E2F1", }}> {sno}</td>
      <td style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#54536E", borderRight: "1px solid #E4E2F1", }}>{name}</td>
      <td style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#54536E", }}>{totalUsers}</td>
    </tr>
  );
}
