import React from "react";
import { BsPlusCircle } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import CH from '../images/iconu.png';
import { IoMdSend } from "react-icons/io";
import { IoIosArrowRoundBack } from "react-icons/io";
import lotti from '../images/79561-dh-coming-soon.json';
import Lottie from "lottie-react";
import { NavLink } from 'react-router-dom';
export default function Chat() {
    return (
        <>
            <div className="container-flude ">
                {/* <div className="row clearfix">
    <div className="col-lg-12">
        <div className="card chat-app">
            <div id="plist" className="people-list">
                <div className="" >
                <p style={{textAlign:"center",fontSize:"25px",marginTop:"2px"}}><span style={{fontSize:"2.1rem",}}><NavLink to="/Dashboard" style={{color:"#000",}}><IoIosArrowRoundBack/></NavLink></span> Chat</p>
                </div>
                <div style={{borderBottom:"2px solid #efefef",marginTop:"10px" }}></div>
                <div className="mt-3">
                    
                    <input type="text" className="form-control" placeholder="Search..." style={{paddingLeft:"40px"}} />
                    <FiSearch style={{marginTop:"-63px" ,marginLeft:"15px", color:"#efefef"}}/>
                </div>
            
                <ul className="list-unstyled chat-list mt-2 mb-0">
                <li className="clearfix">
                    <img src={CH}/>
                        <div className="about">
                            <div className="name">Ricky Sharma</div>
                            <div className="status"> <i className="fa fa-circle online"></i> online </div>
                        </div>
                    </li>  
                    <li className="clearfix">
                    <img src={CH}/>
                        <div className="about">
                            <div className="name">Ricky Sharma</div>
                            <div className="status"> <i className="fa fa-circle online"></i> online </div>
                        </div>
                    </li>  
                    <li className="clearfix">
                    <img src={CH}/>
                        <div className="about">
                            <div className="name">Sn Potter</div>
                            <div className="status"> <i className="fa fa-circle online"></i> online </div>
                        </div>
                    </li>                                    
                    <li className="clearfix">
                    <img src={CH}/>
                        <div className="about">
                            <div className="name">Sn Potter</div>
                            <div className="status"> <i className="fa fa-circle offline"></i> left 10 hours ago </div>
                        </div>
                    </li>
                    <li className="clearfix">
                    <img src={CH}/>
                        <div className="about">
                            <div className="name">Sn Potter</div>
                            <div className="status"> <i className="fa fa-circle online"></i> online </div>
                        </div>
                    </li>
                    
                 
                </ul>
            </div>
            <div className="chat" style={{height:"100vh"}}>
                <div className="chat-header clearfix">
                    <div className="row">
                        <div className="col-lg-6">
                            <a href="javascript:void(0);" data-toggle="modal" data-target="#view_info">
                            <img src={CH}/>
                            </a>
                            <div className="chat-about">
                                <h6 className="m-b-0">Sn Potter</h6>
                                <small>Graphic designer</small>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div className="chat-history">
                    <ul className="m-b-0">
                        <li className="clearfix">
                            <div className="message-data text-right">
                                <span className="message-data-time">10:10 AM, Today</span>
                        
                            </div>
                            <div className="message other-message float-right"> Hi Aiden, how are you? How is the project coming along? </div>
                        </li>
                        <li className="clearfix">
                            <div className="message-data">
                                <span className="message-data-time">10:12 AM, Today</span>
                            </div>
                            <div className="message my-message">Are we meeting today?</div>                                    
                        </li>                               
                        <li className="clearfix">
                            <div className="message-data">
                                <span className="message-data-time">10:15 AM, Today</span>
                            </div>
                            <div className="message my-message">Project has been already finished and I have results to show you.</div>
                        </li>
                    </ul>
                </div>
                <div className="chat-message clearfix" style={{marginTop:"100px",backgroundColor:"#efefef"}}>
                    <div className="input-group mb-0">
                        <div className="input-group-prepend">
                            <span className="mx-2" ><BsPlusCircle style={{fontSize:"25px",marginTop:"8px"}}/></span>
                        </div>
                        <input type="text" className="form-control" placeholder="Enter text here..."/> <span style={{marginLeft:"-33px",zIndex:"99",marginTop:"-2px",fontSize:"1.5rem",}}><IoMdSend /></span>                                   
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> */}

                <div className='d-flex ' style={{ justifyContent: "center", alignItems: "center" }}>
                    <Lottie animationData={lotti} style={{ width: "31%", height: "25rem", marginLeft: "0rem", }} />
                </div>

                <div className=''>
                    <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "40px", textAlign: "center", color: "#3C3C3C", }}>This feature will be live Soon !!</p>
                </div>

                <div className='text-center mt-5'>
                    <NavLink to="/Dashboard"><button type="button" class="btn" style={{ background: "#F2EDFD", borderRadius: "5px", padding: "14px 55px", fontSize: "15px", fontWeight: "400", color: "#8147E7", border: "none", }}>Back To Dashboard</button></NavLink>
                    {/* <NavLink to=""><button type="button" class="btn" style={{ background: "#8147E7", boxShadow: "0px 4px 12px rgba(126, 57, 247, 0.36)", borderRadius: "5px", color: "#FFFFFF", padding: "14px 55px", fontSize: "15px", fontWeight: "400", marginLeft: "20px", border: "none", }}>Join the Waitlist</button></NavLink> */}
                </div>
            </div>
        </>
    );
}