import React from "react";
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { IoPersonOutline } from "react-icons/io5";
import { IoArrowForwardSharp } from "react-icons/io5";
import { BsSearch } from 'react-icons/bs';
import EE from '../images/iconu.png';
import { IoArrowForward } from "react-icons/io5";
import './AssignedtoMe.css';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBIcon,
  MDBCardFooter,
} from "mdb-react-ui-kit";

import { AiOutlineSend } from 'react-icons/ai'
import { NavLink } from "react-router-dom";


const AssignedtoMe = () => {
  return (
    <>
      <div className="">
        {/* <h3 className=" text-center">Messaging</h3> */}
        <div className="messaging px-4">
          <div className="inbox_msg">
            <div className="inbox_people">
              <div className="headind_srch">
                <div className="recent_heading">
                  {/* <h4>Recent</h4> */}
                </div>
                <div className="srch_bar ">
                  <div className="mb-2">
                    <form className="d-flex" role="search">
                      <span className="input-group-text" id="basic-addon1" style={{ backgroundColor: '#fff', borderRight: 'none', borderColor: '#E4E2F1', borderTopRightRadius: 0, borderBottomRightRadius: 0, color: '#B1AFC8' }}><BsSearch /></span>
                      <input type="text" className="form-control" placeholder="Search " aria-label="Username" aria-describedby="basic-addon1" style={{ borderLeft: 'none', borderColor: '#E4E2F1', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} />
                    </form>
                  </div>
                </div>
              </div>
              <div className="inbox_chat" style={{ overflowY: "scroll", height: "70vh", }}>
                <div className="chat_list active_chat">
                  <div className="chat_people">
                    <div className="chat_img">  <img src={EE} style={{ borderRadius: '50px', width: '100%', border: '1px solid #808080', }} /> </div>
                    <div className="chat_ib">
                      <h5>Sunil Rajput <span className="chat_date">Dec 25</span></h5>
                      <p>Batch - AAF0123</p>
                    </div>
                  </div>
                </div>
                <div className="chat_list">
                  <div className="chat_people">
                    <div className="chat_img"> <img src={EE} style={{ borderRadius: '50px', width: '100%', border: '1px solid #808080', }} /> </div>
                    <div className="chat_ib">
                      <h5>Sunil Rajput <span className="chat_date">Dec 25</span></h5>
                      <p>Batch - AAF0123</p>
                    </div>
                  </div>
                </div>
                <div className="chat_list">
                  <div className="chat_people">
                    <div className="chat_img"> <img src={EE} style={{ borderRadius: '50px', width: '100%', border: '1px solid #808080', }} /> </div>
                    <div className="chat_ib">
                      <h5>Sunil Rajput <span className="chat_date">Dec 25</span></h5>
                      <p>Batch - AAF0123</p>
                    </div>
                  </div>
                </div>
                <div className="chat_list">
                  <div className="chat_people">
                    <div className="chat_img"> <img src={EE} style={{ borderRadius: '50px', width: '100%', border: '1px solid #808080', }} /> </div>
                    <div className="chat_ib">
                      <h5>Sunil Rajput <span className="chat_date">Dec 25</span></h5>
                      <p>Batch - AAF0123</p>
                    </div>
                  </div>
                </div>
                <div className="chat_list">
                  <div className="chat_people">
                    <div className="chat_img"> <img src={EE} style={{ borderRadius: '50px', width: '100%', border: '1px solid #808080', }} /> </div>
                    <div className="chat_ib">
                      <h5>Sunil Rajput <span className="chat_date">Dec 25</span></h5>
                      <p>Batch - AAF0123</p>
                    </div>
                  </div>
                </div>
                <div className="chat_list">
                  <div className="chat_people">
                    <div className="chat_img"> <img src={EE} style={{ borderRadius: '50px', width: '100%', border: '1px solid #808080', }} /> </div>
                    <div className="chat_ib">
                      <h5>Sunil Rajput <span className="chat_date">Dec 25</span></h5>
                      <p>Batch - AAF0123</p>
                    </div>
                  </div>
                </div>
                <div className="chat_list">
                  <div className="chat_people">
                    <div className="chat_img"> <img src={EE} style={{ borderRadius: '50px', width: '100%', border: '1px solid #808080', }} /> </div>
                    <div className="chat_ib">
                      <h5>Sunil Rajput <span className="chat_date">Dec 25</span></h5>
                      <p>Batch - AAF0123</p>
                    </div>
                  </div>
                </div>
                <div className="chat_list">
                  <div className="chat_people">
                    <div className="chat_img"> <img src={EE} style={{ borderRadius: '50px', width: '100%', border: '1px solid #808080', }} /> </div>
                    <div className="chat_ib">
                      <h5>Sunil Rajput <span className="chat_date">Dec 25</span></h5>
                      <p>Batch - AAF0123</p>
                    </div>
                  </div>
                </div>
                <div className="chat_list">
                  <div className="chat_people">
                    <div className="chat_img"> <img src={EE} style={{ borderRadius: '50px', width: '100%', border: '1px solid #808080', }} /> </div>
                    <div className="chat_ib">
                      <h5>Sunil Rajput <span className="chat_date">Dec 25</span></h5>
                      <p>Batch - AAF0123</p>
                    </div>
                  </div>
                </div>
                <div className="chat_list">
                  <div className="chat_people">
                    <div className="chat_img"> <img src={EE} style={{ borderRadius: '50px', width: '100%', border: '1px solid #808080', }} /> </div>
                    <div className="chat_ib">
                      <h5>Sunil Rajput <span className="chat_date">Dec 25</span></h5>
                      <p>Batch - AAF0123</p>
                    </div>
                  </div>
                </div>
                <div className="chat_list">
                  <div className="chat_people">
                    <div className="chat_img"> <img src={EE} style={{ borderRadius: '50px', width: '100%', border: '1px solid #808080', }} /> </div>
                    <div className="chat_ib">
                      <h5>Sunil Rajput <span className="chat_date">Dec 25</span></h5>
                      <p>Batch - AAF0123</p>
                    </div>
                  </div>
                </div>
                <div className="chat_list">
                  <div className="chat_people">
                    <div className="chat_img"> <img src={EE} style={{ borderRadius: '50px', width: '100%', border: '1px solid #808080', }} /> </div>
                    <div className="chat_ib">
                      <h5>Sunil Rajput <span className="chat_date">Dec 25</span></h5>
                      <p>Batch - AAF0123</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mesgs">
              <div className="" style={{ background: "#FFFFFF", boxShadow: "2px 4px 6px 0px rgba(0, 0, 0, 0.15)", padding: "6px", borderRadius: "3px", }}>
                <div className="d-flex px-4">
                  <img src={EE} style={{ borderRadius: '50px', width: '5%', border: '1px solid #808080', }} />
                  <div style={{ marginLeft: "15px", }}>
                    <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#434343", }}>Sunil Rajput</p>
                    <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "12px", color: "#797979", }}>Batch - AAF0123</p>
                  </div>
                </div>
              </div>
              <div className="msg_history mt-3 mx-2" style={{ overflowY: "scroll", height: "52vh", }}>
                <div className="incoming_msg">
                  <div className="incoming_msg_img"> <img src={EE} style={{ borderRadius: '50px', width: '100%', border: '1px solid #808080', }} /> </div>
                  <div className="received_msg">
                    <div className="received_withd_msg">
                      <p>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit.</p>
                      <span className="time_date"> 11:01 AM    |    June 9</span></div>
                  </div>
                </div>
                <div className="outgoing_msg">
                  <div className="sent_msg">
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit.</p>
                    <span className="time_date"> 11:01 AM    |    June 9</span> </div>
                </div>
                <div className="incoming_msg">
                  <div className="incoming_msg_img"> <img src={EE} style={{ borderRadius: '50px', width: '100%', border: '1px solid #808080', }} /> </div>
                  <div className="received_msg">
                    <div className="received_withd_msg">
                      <p>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit.</p>
                      <span className="time_date"> 11:01 AM    |    Yesterday</span></div>
                  </div>
                </div>
                <div className="outgoing_msg">
                  <div className="sent_msg">
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit.</p>
                    <span className="time_date"> 11:01 AM    |    Today</span> </div>
                </div>
                <div className="incoming_msg">
                  <div className="incoming_msg_img"> <img src={EE} style={{ borderRadius: '50px', width: '100%', border: '1px solid #808080', }} /> </div>
                  <div className="received_msg">
                    <div className="received_withd_msg">
                      <p>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit.</p>
                      <span className="time_date"> 11:01 AM    |    Today</span></div>
                  </div>
                </div>
              </div>
              <div className="type_msg">
                <div className="input_msg_write">
                  <input type="text" className="write_msg" placeholder="Start typing here" />
                  <button className="msg_send_btn" type="button"><IoArrowForward /></button>
                </div>
              </div>
            </div>
          </div>
          {/* <p className="text-center top_spac"> Design by <a target="_blank" href="https://www.linkedin.com/in/sunil-rajput-nattho-singh/">Sunil Rajput</a></p> */}
        </div></div>
    </>
  )
};

export default AssignedtoMe;