import React, { useContext, useEffect, useState } from "react";
import Header from "./header";
import SideBar from "./sidebar";

import { IoMdMail } from "react-icons/io";

import { NavLink, useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { IoOptionsOutline } from "react-icons/io5";

import EEi from "../images/outline.png";
import EEi1 from "../images/Group 13160.png";
import EEi2 from "../images/Group 13161.png";
import { IoEyeOutline } from "react-icons/io5";
import { IoPerson } from "react-icons/io5";
import { AiOutlineCloudUpload } from "react-icons/ai";
import Dates from "../images/Vector.png";
import lotti from "../images/129053-thumbs-up-birdie.json";
import Lottie from "lottie-react";
import DatePicker from "react-datepicker";
import { IoPersonSharp } from "react-icons/io5";
// import { SiGooglescholar } from "react-icons/si";
import { IoMdSchool } from "react-icons/io";
import { AiOutlineMail } from "react-icons/ai";
import { FaBirthdayCake } from "react-icons/fa";
import { ImDroplet } from "react-icons/im";
import { BsFillCalendarFill } from "react-icons/bs";
import lotti1 from "../images/86894-add-to-home.json";
import { RiUser3Fill } from "react-icons/ri";
import { FaUserGraduate } from "react-icons/fa";
// import { MdBloodtype } from 'react-icons/md';
import { FiFilter } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";
// import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
// import { useStepContext } from '@mui/material';
import { click } from "@testing-library/user-event/dist/click";

import Lode from "./Lode";
import { AuthContext } from "./AuthContext";
import { ToastContainer, toast } from "react-toastify";
import { useRequireAuth } from "./RequireAuth";
import { AiFillCloseCircle } from "react-icons/ai";
import { Loader } from './Loader';
import { API_URL, img_url } from "../env";
import InputGroup from 'react-bootstrap/InputGroup';

export default function Teams() {
  const handleClick12 = () => { };

  const [TaskValue, setTaskValue] = useState("");
  const Navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  //useRequireAuth("/login");
  const { profile, profileGet, apiToken, Adname, AdminName } =
    useContext(AuthContext);
  const [Salary, setSalary] = useState();
  const [count, setcount] = useState(1);
  const [progress, setProgress] = useState(0);
  const [Images, setImages] = useState([]);
  const [Times, setTimes] = useState();
  const [Sedule, setSedule] = useState();
  const [Banner, setBanner] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [value, setvalue] = useState("");
  const [Data, setData] = useState([]);
  const [Name, setName] = useState("");
  const [Hq, setHq] = useState("");
  const [NOI, setNOI] = useState("");
  const [Number, setNumber] = useState();
  const [Num1, setNum1] = useState();
  const [Email, setEmail] = useState("");
  const [Dob, setDob] = useState("");
  const [Blood, setBlood] = useState("");
  const [Address, setAddress] = useState("");
  const [District, setDistrict] = useState("");
  const [Pin, setPin] = useState("");
  const [State, setState] = useState("");
  const [PAddress, setPAddress] = useState("");
  const [PDistrict, setPDistrict] = useState("");
  const [PPin, setPPin] = useState("");
  const [PState, setPState] = useState("");
  const [Click, setClick] = useState(0);
  const [Click1, setClick1] = useState(0);
  const [Techer, setTecher] = useState(0);
  const [Batchs, setBatchs] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [title, settitle] = useState("");
  const [amount, setamount] = useState("");
  const [DepartDatas, setDepartDatas] = useState([]);
  const [RolesData, setRolesData] = useState([]);
  const [CoursesData, setCoursesData] = useState([]);
  const [AccountNumber, setAccountNumber] = useState("");
  const [IFSC, setIFSC] = useState("");
  const [Holder, setHolder] = useState("");
  const [selectedOption, setSelectedOption] = useState({ id: "", value: "" });
  const [selectedRole, setSelectedRole] = useState({ id: "", value: "" });
  const [CourseId, setCourseId] = useState("");
  const [EmployName, setEmployName] = useState();
  const [Employid, setEmployid] = useState();
  const [taskDes, settaskDes] = useState("");
  const [Seduled, setSeduled] = useState("");
  const [TotalSalary, setTotalSalary] = useState();
  const [inTime,setInTime] = useState();
  const [outTime,setOutTime] = useState();
  const [enrollmentId,setEnrollmentId] = useState("");
  const [StateVali, setStateVali] = useState({
    first: false,
    second: false,
    third: false,
    four: false,
    five: false,
    six: false,
    siven: false,
  });

  console.log("Banner", TotalSalary);

  function handleSelectChange(event) {
    const { id, value } = event.target.options[event.target.selectedIndex];
    setSelectedOption({ id, value });
    RoleData(id);
    // console.log(id);
  }

  function handleSelectChange1(event) {
    const { id, value } = event.target.options[event.target.selectedIndex];
    setSelectedRole({ id, value });
    // console.log('role id', id);
  }

  // console.log("name", selectedOption);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const [cardEmpId,setCardEmpId] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (emp_id) => {
    setCardEmpId(emp_id);
    setShow3(true)
  };

  // console.log(Techer);

  const AddTask = async (Employid, x) => {
    // console.log({Employid,TaskValue,taskDes,Banner,Seduled,x});

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var formdata = new FormData();
    formdata.append("emp_id", Employid);
    formdata.append("title", TaskValue);
    formdata.append("des", taskDes);
    // Banner.forEach((image, index) => {
    //   formdata.append(`files[${index}]`, image);
    // });

    formdata.append("files", Banner);
    formdata.append("due_date", Seduled);
    formdata.append("assigned_by", x);
    // console.log('i am here', x);
    // console.log('files' , Banner);

    // console.log(formdata,'checking the form');

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${API_URL}/admin/employee/assign/task`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status == "003") {
          Navigate("/login");
        }
        if (result.status == 1) {
          setTaskValue("");
          settaskDes("");
          // setBanner();
          setSeduled("");
          toast(result.message);
          Api();
          //console.log('files for ',Banner);
        } 
        else if (result.status == 'FILE_ERR') { 
          toast(result.Backend_Error) 
        } 
        else if (result.status === 'VAL_ERR') { 
          toast(result.Backend_Error) 
        } 
         else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }
      })
      .catch((error) => console.log("error", error));
  };

  const Api = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    // var raw = "";

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      // body: raw,
      redirect: "follow",
    };
    setIsLoading(true);

    fetch(`${API_URL}/admin/employee/get/employee`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false)
        // console.log(result);
        if (result.status == "1") {
          setData(result.employees);
        } 
         else if (result.status === 'VAL_ERR') { 
                    toast(result.Backend_Error) 
                  } 
         else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }
      })
      .catch((error) => console.log("error", error));
  };

  const AddSome = () => {
    setClick(Click + 1);
    if (Click % 2 == 0) {
      setPAddress(Address);
      setPDistrict(District);
      setPPin(Pin);
      setPState(State);
    } else {
      setPAddress("");
      setPDistrict("");
      setPPin("");
      setPState("");
    }
  };

  const AddOnther = () => {
    setClick1(Click1 + 1);
    if (Click1 % 2 == 0) {
      setTecher(1);
    } else {
      setTecher(0);
    }
  };

  const CoursesDataList = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/admin/course/read/list/of/courses`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setCoursesData(result.courses);
         if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }
      })
      .catch((error) => console.log("error", error));
  };

  const Batch = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    // var formdata = new FormData();
    // formdata.append("course_id", id);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      // body: formdata,
      redirect: "follow",
    };

    fetch(`${API_URL}/admin/formfill/batch/by/courseid/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.status == "001") {
          setBatchs(result.batches);
        }  else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }
      })
      .catch((error) => console.log("error", error));
  };

  const Add = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var raw= JSON.stringify({
      "name": Name,
      "highest_qual": Hq,
      "inst_name": NOI,
      "phone": Number,
      "em_phone": Num1,
      "email": Email,
      "dob": Dob,
      "blood_group": Blood,
      "state": State,
      "district": District,
      "pincode": Pin,
      "address": Address,
      "department_id": selectedOption.id,
      // "course_id": CourseId,
      "batch_id": dataId,
      "ifsc": IFSC,
      "salary": Salary,
      // "instruments": dataArrayName,
      "join_date": Sedule,
      "acc_num": AccountNumber,
      "acc_holder_name": Holder,
      "is_instructor": Techer,
      "permanent_address": PAddress,
      "permanent_state": PState,
      "permanent_district": PDistrict,
      "permanent_pincode": PPin,
  
    })
 
   
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(`${API_URL}/admin/employee/add/employee`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status == "1") {
          toast(result.message);
          setName("");
          setHq("");
          setNOI("");
          setHolder("");
          setIFSC("");
          setAccountNumber("");
          setCoursesData([]);
          setRolesData([]);
          setDepartDatas([]);
          setamount("");
          settitle("");
          setTecher(0);
          setPState("");
          setPAddress("");
          setPDistrict("");
          setBlood("");
          settitle("");
          handleClose1();
          setdataId([]);
          setdataArrayName([]);
          setdataArray1([]);
          Api();
          setSalary("");
          setTotalSalary("");
          setcount(1);
          setNumber("");
          setEmail("");
          setDob("");
          setNum1("");
          setAddress("");
          setDistrict("");
          setPin("");
          setState("");
          setPPin("");
          setPState("");
          setPDistrict("");
          setPAddress("");
        }
        else if (result.status === 'VAL_ERR') { 
          toast(result.Backend_Error) 
        } 
        else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } 
        else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
       
        else {
          console.log(result)
      
          // setmessage(result.message)
          toast(result.message);
          setName("");
          setHq("");
          setNOI("");
          setHolder("");
          setIFSC("");
          setAccountNumber("");
          setCoursesData([]);
          setRolesData([]);
          setDepartDatas([]);
          setamount("");
          settitle("");
          setTecher(0);
          setPState("");
          setPAddress("");
          setPDistrict("");
          setBlood("");
          settitle("");
          handleClose1();
          setdataId([]);
          setdataArrayName([]);
          setdataArray1([]);
          Api();
          setcount(1);
          setcount(1);
          setNumber("");
          setEmail("");
          setDob("");
          setNum1("");
          setAddress("");
          setDistrict("");
          setPin("");
          setState("");
          setPPin("");
          setPState("");
          setPDistrict("");
          setPAddress("");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const parsedEnrollment = "5555" + enrollmentId;
  // console.log(parsedEnrollment);

// Convert parsedEnrollment to a number using parseInt
const enrollmentNumber = parseInt(parsedEnrollment, 10);


  const changeTiming = async()=>{
    try {
      var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${apiToken}`);

var raw = JSON.stringify({
  "enrollment_id": enrollmentNumber,
  "emp_id": cardEmpId,
  "start_time": inTime,
  "end_time": outTime
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${API_URL}/admin/employee/setup/attendence/credentails/for/employee`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status==1){
      toast.success(result.msg);
      handleClose3();
      setEnrollmentId("");
      Api();
    }
    else if (result.status === 'VAL_ERR') { 
      toast(result.Backend_Error) 
    } 
    else if (result.status == "CUSTOM_ERR") {
      toast(result.Backend_Error);
    } 
    else if (result.status == "TOKEN_ERR") {
      localStorage.removeItem('api_url');
      Navigate('/code')
      toast("Logged in other device! Please login again")
    }
  })
  .catch(error => console.log('error', error));
    } catch (error) {
      console.log(error);
    }
  }

  // const HandleProcess = () => {
  //   const validateEmail = (Email) => {
  //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //     return emailRegex.test(Email);
  //   };

  //   if (
  //     Name.length <= 1 ||
  //     // Hq.length <= 1 ||
  //     // NOI.length <= 1 ||
  //     Number <= 9 ||
  //     // Num1 <= 9 ||
  //     !validateEmail(Email)
  //     // Dob.length <= 1 ||
  //     // Blood == 0
  //   ) {
  //     toast(
  //       Name.length <= 1
  //         ? "Please enter name"
  //         : Number <= 9
  //           ? "Please enter 10-digit contact number"
  //           : !validateEmail(Email)
  //             ? "Please enter valid e-mail":""
  //     );
  //   } else {
  //     setcount(count + 1);
  //   }
  // };

  const HandleProcess = () => {
    const validateEmail = (Email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(Email);
    };
  
    if (Name.length <= 1) {
      toast("Please enter name");
    }else if (Number.length !== 10) {
      toast("Please enter a 10-digit contact number");
    } 
     else if (!validateEmail(Email)) {
      toast("Please enter a valid e-mail");
    } 
    else {
      setcount(count + 1);
    }
  };

  const HandleProcess2 = () => {
    setcount(count + 1);
    setProgress(progress + 17);
    // if (
    //   Address.length <= 1 ||
    //   District.length <= 1 ||
    //   State.length <= 1 ||
    //   Pin.length <= 1
    // ) {
    //   toast(
    //     Address.length <= 1 ||
    //       District.length <= 1 ||
    //       State.length <= 1 ||
    //       Pin.length <= 1
    //       ? "Please enter Residential Address"
    //       : ""
    //   );
    // } else {
    // }
  };

  const HandleProcess3 = () => {
    if (selectedOption.id == "" ) {
      toast(
        selectedOption.id == ""
          ? "Please select department"
          : ""
      );
    } else {
      setcount(count + 1);
      setProgress(progress + 17);
    }
  };
  const HandleProcess12 = () => {
    if (selectedOption.id == "" ) {
      toast(
        selectedOption.id == "" 
          ? "Please select department"
          : ""
      );
    } else {
      setcount(count + 2);
      setProgress(progress + 17);
    }
  };

  const HandleProcess4 = () => {
    if (dataArray1.length == 0) {
      toast("Please select Batches");
    } else {
      setcount(count + 1);
      setProgress(progress + 17);
    }
  };

  const HandleProcess5 = () => {
    if (Salary.length <= 1) {
      toast("Please enter salary");
    } else {
      setcount(count + 1);
      setProgress(progress + 17);
    }
  };

  const HandleProcess6 = () => {
    setcount(count + 1);
    setProgress(progress + 17);
    // if (AccountNumber.length <= 1 || IFSC.length <= 1 || Holder.length <= 1) {
    //   toast(
    //     AccountNumber.length <= 1
    //       ? "Please enter Account number"
    //       : IFSC.length <= 1
    //         ? "Please enter IFSC code"
    //         : Holder.length <= 1
    //           ? "Please enter Account Holder name"
    //           : ""
    //   );
    // } else {
    // }
  };

  const RoleData = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    // var formdata = new FormData();
    // formdata.append("department_id", id);

    var raw = JSON.stringify({
      "dept_id":id
    })

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/admin/role/read/role`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.status == "001") {
          setRolesData(result.role);
        }  else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }
      })
      .catch((error) => console.log("error", error));
  };

  const TotalArray = (e) => {
    const files = Array.from(e.target.files);
    setBanner(files); // Create URLs for each file
    //setBanner(files);
  };

  const DepartDataApi = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/admin/department/read/department`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.status == "001") {
          setDepartDatas(result.departments);
        }  else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }
      })
      .catch((error) => console.log("error", error));
  };

  const [dataArray1, setdataArray1] = useState([]);
  const [dataArrayName, setdataArrayName] = useState([]);
  const [dataId, setdataId] = useState([]);

  //console.log("xc xvcbm",dataArrayName);

  const handleChange1 = (e) => {
    const id = e.target.getAttribute("id");
    const value = e.target.getAttribute("value");
    console.log(id, value, "id and name");

    // dataArray1.includes(value)
    // ? toast('already selected')
    // : (setdataArray1([...dataArray1, value]), setdataId([...dataId, id]));

    if (dataArray1.includes(value)) {
      toast("already selected");
    } else {
      setdataArray1([...dataArray1, value]);
      setdataId([...dataId, id]);
    }

    // setdataArray1(updatedList1)
    // var updatedList1 = [...dataArray1];
    // var idUpdate = [...dataId];

    // if (e.target.checked) {
    //   updatedList1 = [...dataArray1, e.target.value];
    //   idUpdate = [...dataId, e.target.id];
    // } else {
    //   updatedList1.splice(dataArray1.indexOf(e.target.value), 1);
    //   idUpdate.splice(dataId.indexOf(e.target.id), 1);
    // }
    // setdataArray1(updatedList1);
    // setdataId(idUpdate);
  };

  // const handleDelete = (e) => {
  //   console.log(e,'uuuu')
  //   const id = e.target.getAttribute('id');
  //   const value = e.target.getAttribute('value');
  //   console.log("ID:", id, "Value:", value);
  //   alert(id);
  //   //
  // };

  const handleDelete = (value, id) => {
    // const id = e.target.getAttribute('full'); // Correct way to access attribute value
    // const value = e.target.getAttribute('value'); // Correct way to access attribute value
    console.log("ID:", id, "Value:", value);
    // alert(id, "id");
    setdataArray1(dataArray1.filter((itemValue) => itemValue !== value));
    setdataId(dataId.filter((el) => el !== id));
  };

  // console.log(dataArray1,"dataArray1",dataId,"dataIds in compo team")

  const handleChangeName = () => {
    var updated = [...dataArrayName];

    if (click) {
      updated = [...dataArrayName, { title, amount }];
    }
    setdataArrayName(updated);
    setamount("");
    settitle("");
    setTimeout(() => {
      GetTotalSalary();
    }, 2000);
  };

  const GetTotalSalary = () => { };

  useEffect(() => {
    console.log("dataArrayName", dataArrayName);
    console.log("dataArrayName", dataArrayName);
    const sum = dataArrayName.reduce((accumulator, current) => {
      const value = parseInt(current.amount);
      if (!isNaN(value)) {
        return accumulator + value;
      }
      return accumulator;
    }, 0);

    console.log(sum);

    let Data = sum + parseInt(Salary);
    setTotalSalary(Data);
  }, [dataArrayName, Salary]);

  // console.log( Adname, 'in js part');

  useEffect(() => {
    setvalue("Accounts");
    Api();
    DepartDataApi();
    AdminName();
  }, []);

  return (
    <>
      <div style={{ width: "100%", height: "100vh" }}>
        <div
          className="head"
          style={{ width: "80%", height: "10vh", marginLeft: "16%" }}
        >
          <Header />
        </div>
        <div className="container mt-5" style={{ marginLeft: "15rem" }}>
          <div className="row ">
            <div className="col-4">
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "20px",
                  marginTop: "6px",
                }}
              >
                Showing total
                <span className="ms-2" style={{ color: "#CC1313" }}>
                  {Data.length}
                </span>{" "}
                Users
                <span
                  className="mt-2"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal2"
                  style={{
                    background: "#d9d4d4",
                    padding: "9px",
                    marginLeft: "17px",
                    borderRadius: "10px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontWize: "13px",
                    cursor: "pointer",
                  }}
                >
                  <IoOptionsOutline /> Filters
                </span>
              </p>
            </div>

            <div className="col-6">
              <div className="row">
                {/* <div className="col-lg-3 col-md-6 col-sm-12">
                  <NavLink to="/salarymanagement">
                    <button
                      style={{
                        background: "#EDFFF3",
                        border: "1px solid #17A536",
                        borderRadius: "10px",
                        padding: "9px 2px",
                        fontSize: "14px",
                        color: "#17A536",
                        fontWeight: "500",
                        width: "100%",
                      }}
                    >
                      Salary Management
                    </button>
                  </NavLink>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <NavLink to="/Grantleave">
                    <button
                      style={{
                        background: "#FFEDED",
                        border: "1px solid #E72121",
                        borderRadius: "10px",
                        padding: "9px 10px",
                        fontSize: "14px",
                        color: "#E72121",
                        width: "100%",
                        fontWeight: "500",
                      }}
                    >
                      Grant Leave
                    </button>
                  </NavLink>
                </div> */}
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <NavLink to="/Department">
                    <button
                      style={{
                        background: "#EDF7FF",
                        border: "1px solid #2188E7",
                        borderRadius: "10px",
                        padding: "9px 10px",
                        fontSize: "14px",
                        color: "#2188E7",
                        width: "100%",
                        fontWeight: "500",
                      }}
                    >
                      Departments
                    </button>
                  </NavLink>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <button
                    onClick={handleShow1}
                    style={{
                      background:
                        "linear-gradient(0deg, #462582 0%, #7942D8 100%)",
                      border: "1px solid #17A536",
                      borderRadius: "10px",
                      padding: "9px 5px",
                      fontSize: "14px",
                      color: "#fff",
                      width: "100%",
                      fontWeight: "500",
                    }}
                  >
                    +Add Employee
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "100%", height: "78vh", display: "flex" }}>
          <div style={{ position: "absolute", top: 0, marginLeft: "1%" }}>
            <SideBar />
          </div>
          <div
            className="maincontain"
            style={{
              marginLeft: "16%",
              width: "100%",
              height: "100%",
              overflowY: "scroll",
            }}
          >
            <div className="container " style={{ marginTop: "2rem" }}>
              {/* <!-- Add Employee --> */}
              {/* <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{background:"#E9E9E9",}}> */}

              <Modal size="lg" show={show1} onHide={handleClose1}>
                <Modal.Body>
                  <div className="row mb-3" style={{ justifyContent: "end" }}>
                    <div className="col-11 w-100"> </div>
                    <div className="col-1">
                      <button
                        type="button"
                        onClick={() => {
                          handleShow2();
                          handleClose1();
                        }}
                        style={{ fontWeight: 20 }}
                        className="btn-close"
                      ></button>
                    </div>
                  </div>

                  {/* <01 modal> */}

                  <div style={{ display: count === 1 ? "block" : "none" }}>
                    <div className="row">
                      <div className="col-6">
                        <p
                          className="ps-5"
                          style={{
                            color: "#474747",
                            fontWeight: "600",
                            fontSize: "25px",
                          }}
                        >
                          Add Employee
                        </p>
                      </div>
                      <div className="col-6 text-end">
                        <div
                          style={{
                            backgroundColor: "lightgray",
                            width: "47%",
                            height: "9px",
                            borderRadius: "10px",
                            marginLeft: "9rem",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "green",
                              width: `${progress}%`,
                              height: "9px",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <div>
                        <input
                          type="text"
                          from-control
                          placeholder="Enter the name of employee"
                          className="ms-5 ps-5"
                          style={{
                            borderRadius: "10px",
                            border: "1px solid #E9E9E9",
                            width: "88%",
                            padding: "12px",
                          }}
                          value={Name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                        <RiUser3Fill
                          style={{ marginLeft: "-86%", color: "#BDBDBD" }}
                        />
                      </div>

                      <div className="mt-3">
                        <input
                          type="text"
                          from-control
                          placeholder="Highest Qualification"
                          className="ms-5 ps-5"
                          style={{
                            border: "1px solid #E9E9E9",
                            borderRadius: "10px",
                            width: "88%",
                            padding: "12px",
                          }}
                          value={Hq}
                          onChange={(e) => {
                            setHq(e.target.value);
                          }}
                        />
                        <FaUserGraduate
                          style={{ marginLeft: "-86%", color: "#BDBDBD" }}
                        />
                      </div>

                      <div className="mt-3">
                        <input
                          type="text"
                          from-control
                          placeholder="Name of institution"
                          className="ms-5 ps-5"
                          style={{
                            border: "1px solid #E9E9E9",
                            borderRadius: "10px",
                            width: "88%",
                            padding: "12px",
                          }}
                          value={NOI}
                          onChange={(e) => {
                            setNOI(e.target.value);
                          }}
                        />
                        <IoMdSchool
                          style={{ marginLeft: "-86%", color: "#BDBDBD" }}
                        />
                      </div>

                      <div className="row mt-3">
                        <div className="col-5 ms-5">
                          <div class="input-group">
                            <div
                              class="input-group-text"
                              style={{ color: "#AFAFAF" }}
                            >
                              +91
                            </div>
                            <input
  type="number"
  class="form-control"
  id="inlineFormInputGroupUsername"
  placeholder="Contact Number"
  style={{ padding: "12px" }}
  value={Number}
  onChange={(e) => {
    setNumber(e.target.value.slice(0, 10));
  }}
/>
                          </div>
                        </div>

                        <div className="col-5 " style={{ marginLeft: "40px" }}>
                          <div class="input-group">
                            <div
                              class="input-group-text"
                              style={{ color: "#AFAFAF" }}
                            >
                              +91
                            </div>
                            <input
                              type="number"
                              class="form-control"
                              id="inlineFormInputGroupUsername"
                              placeholder="Emergency Contact Number"
                              style={{
                                border:
                                  StateVali.five == true
                                    ? "1px solid red"
                                    : "1px solid #E9E9E9",
                                padding: "12px",
                              }}
                              value={Num1}
                              onChange={(e) => {
                                setNum1(e.target.value.slice(0, 10));
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <input
                        type="email"
                        from-control
                        placeholder="Enter Email"
                        className="ms-5 ps-5"
                        style={{
                          border:
                            StateVali.six == true
                              ? "1px solid red"
                              : "1px solid #E9E9E9",
                          borderRadius: "10px",
                          width: "88%",
                          padding: "12px",
                        }}
                        d="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={Email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <IoMdMail
                        style={{ marginLeft: "-86%", color: "#BDBDBD" }}
                      />
                    </div>

                    <div className="row mt-3">
                      <div className="col-5 ">
                        <div className="">
                          <input
                            type="date"
                            from-control
                            placeholder="Date of Birth"
                            className="ms-5 ps-5"
                            style={{
                              border:
                                StateVali.siven == true
                                  ? "1px solid red"
                                  : "1px solid #E9E9E9",
                              borderRadius: "10px",
                              width: "100%",
                              padding: "12px",
                              color: "#CBC7C6  ",
                            }}
                            value={Dob}
                            onChange={(e) => {
                              console.log(Dob)
                              setDob(e.target.value);
                            }}
                          />
                          <FaBirthdayCake
                            style={{ marginLeft: "-95%", color: "#BDBDBD" }}
                          />
                        </div>
                      </div>

                      <div className="col-5 " style={{ marginLeft: "40px" }}>
                        <div className="">
                          <select
                            class="form-select"
                            id="inlineFormSelectPref"
                            style={{
                              border: "1px solid #E9E9E9",
                              borderRadius: "10px",
                              width: "100%",
                              padding: "12px",
                              marginLeft: "45px",
                              paddingLeft: "50px",
                            }}
                            value={Blood}
                            onChange={(e) => {
                              setBlood(e.target.value);
                            }}
                          >
                            <option selected style={{ color: " #AFAFAF" }}>
                              Select Blood Group
                            </option>
                            <option value="A" style={{ color: " #AFAFAF" }}>
                              A
                            </option>
                            <option value="A +" style={{ color: " #AFAFAF" }}>
                              A +
                            </option>
                            <option value="B +" style={{ color: " #AFAFAF" }}>
                              B +
                            </option>
                            <option value="B" style={{ color: " #AFAFAF" }}>
                              B
                            </option>
                            <option value="B -" style={{ color: " #AFAFAF" }}>
                              B -
                            </option>
                            <option value="A -" style={{ color: " #AFAFAF" }}>
                              A -
                            </option>
                            <option value="AB +" style={{ color: " #AFAFAF" }}>
                              AB +
                            </option>
                            <option value="AB -" style={{ color: " #AFAFAF" }}>
                              AB -
                            </option>
                            <option value="O +" style={{ color: " #AFAFAF" }}>
                              O +
                            </option>
                            <option value="O -" style={{ color: " #AFAFAF" }}>
                              O -
                            </option>
                          </select>
                          <ImDroplet
                            style={{
                              marginTop: "-80px",
                              marginLeft: "60px",
                              color: "#DA2F47",
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <button
                        className="d-grid gap-2 col-6 mx-auto mt-4"
                        style={{
                          width: "88%",
                          padding: "10px",
                          fontSize: "18px",
                          backgroundColor: "#2188E7",
                          border: "none",
                          color: "#fff",
                          fontWeight: "500",
                          borderRadius: "5px",
                        }}
                        onClick={() => {
                          HandleProcess();
                        }}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                  {/* <02 modal> */}

                  <div style={{ display: count === 2 ? "block" : "none" }}>
                    <div className="row">
                      <div className="col-6">
                        <p
                          className="ps-5"
                          style={{
                            color: "#474747",
                            fontWeight: "600",
                            fontSize: "25px",
                          }}
                        >
                          Address Details
                        </p>
                      </div>
                      <div className="col-6 text-end">
                        <div
                          style={{
                            backgroundColor: "lightgray",
                            width: "47%",
                            height: "9px",
                            borderRadius: "10px",
                            marginLeft: "9rem",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "green",
                              width: `${progress}%`,
                              height: "9px",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <div>
                        <p
                          className="ms-5 "
                          style={{
                            color: "#474747",
                            fontWeight: "600",
                            fontSize: "18px",
                          }}
                        >
                          Residential Address
                        </p>
                        <input
                          type="text"
                          from-control
                          placeholder="Address Line 1"
                          className="ms-5 mt-2"
                          style={{
                            border: "1px solid #E9E9E9",
                            borderRadius: "10px",
                            width: "88%",
                            padding: "12px",
                          }}
                          value={Address}
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                        />
                      </div>

                      <div className="row mt-3 ">
                        <div className="col-3 ms-5">
                          <input
                            type="text"
                            from-control
                            placeholder="District"
                            style={{
                              border: "1px solid #E9E9E9",
                              borderRadius: "10px",
                              width: "100%",
                              padding: "12px",
                            }}
                            value={District}
                            onChange={(e) => {
                              setDistrict(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-3">
                          <input
                            type="text"
                            from-control
                            placeholder="State"
                            style={{
                              border: "1px solid #E9E9E9",
                              borderRadius: "10px",
                              width: "100%",
                              padding: "12px",
                            }}
                            value={State}
                            onChange={(e) => {
                              setState(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-3">
                          <input
                            type="number"
                            from-control
                            placeholder="Pincode"
                            style={{
                              border: "1px solid #E9E9E9",
                              borderRadius: "10px",
                              width: "100%",
                              padding: "12px",
                            }}
                            value={Pin}
                            onChange={(e) => {
                              setPin(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-3"></div>
                      </div>

                      <div className="ms-5 mt-5" style={{ display: "flex" }}>
                        <p
                          style={{
                            fontWeight: "600",
                            fontSize: "18px",
                            color: "#474747",
                          }}
                        >
                          Permanent Address
                        </p>
                        <div className="form-check ms-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="inlineFormCheck"
                            onClick={() => AddSome()}
                          />
                          <label
                            className="form-check-label"
                            for="inlineFormCheck"
                          >
                            <span style={{ color: "#AFAFAF" }}>
                              Same as residential address
                            </span>
                          </label>
                        </div>
                      </div>

                      <div>
                        <input
                          type="text"
                          from-control
                          placeholder="Address Line 1"
                          className="ms-5 mt-2"
                          style={{
                            border: "1px solid #E9E9E9",
                            borderRadius: "10px",
                            width: "88%",
                            padding: "12px",
                          }}
                          value={PAddress}
                          onChange={(e) => {
                            setPAddress(e.target.value);
                          }}
                        />
                      </div>

                      <div className="row mt-3 ">
                        <div className="col-3 ms-5">
                          <input
                            type="text"
                            from-control
                            placeholder="District"
                            style={{
                              border: "1px solid #E9E9E9",
                              borderRadius: "10px",
                              width: "100%",
                              padding: "12px",
                            }}
                            value={PDistrict}
                            onChange={(e) => setPDistrict(e.target.value)}
                          />
                        </div>
                        <div className="col-3">
                          <input
                            type="text"
                            from-control
                            placeholder="State"
                            style={{
                              border: "1px solid #E9E9E9",
                              borderRadius: "10px",
                              width: "100%",
                              padding: "12px",
                            }}
                            value={PState}
                            onChange={(e) => setPState(e.target.value)}
                          />
                        </div>
                        <div className="col-3">
                          <input
                            type="number"
                            from-control
                            placeholder="Pincode"
                            style={{
                              border: "1px solid #E9E9E9",
                              borderRadius: "10px",
                              width: "100%",
                              padding: "12px",
                            }}
                            value={PPin}
                            onChange={(e) => setPPin(e.target.value)}
                          />
                        </div>
                        <div className="col-3"></div>
                      </div>

                      <div className="mt-5">
                        <button
                          className="d-grid gap-2 col-6 mx-auto mt-4"
                          style={{
                            width: "88%",
                            padding: "10px",
                            fontSize: "18px",
                            backgroundColor: "#2188E7",
                            border: "none",
                            color: "#fff",
                            fontWeight: "500",
                            borderRadius: "5px",
                          }}
                          onClick={(e) => {
                            HandleProcess2();
                            handleClick12();
                            Api();
                          }}
                        >
                          Proceed
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <03 modal> */}
                  <div style={{ display: count === 3 ? "block" : "none" }}>
                    <div className="row">
                      <div className="col-6">
                        <p
                          className="ps-5"
                          style={{
                            color: "#474747",
                            fontWeight: "600",
                            fontSize: "25px",
                          }}
                        >
                          Access Control Details
                        </p>
                      </div>
                      <div className="col-6 text-end">
                        <div
                          style={{
                            backgroundColor: "lightgray",
                            width: "47%",
                            height: "9px",
                            borderRadius: "10px",
                            marginLeft: "9rem",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "green",
                              width: `${progress}%`,
                              height: "9px",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="ms-5 mt-4 ">
                      <p
                        style={{
                          color: " #474747",
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        Select Department<span style={{ color: "red" }}>*</span>
                      </p>

                      <select
                        className="form-select mt-3"
                        style={{ width: "94%", padding: "12px" }}
                        onChange={handleSelectChange}
                      >
                        <option selected value="">
                          Select Department
                        </option>
                        {DepartDatas?.map((res) => {
                          return (
                            <>
                              <option value={res.department_name} id={res.id} key={res.id}>
                                {res.department_name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>

                    {/* <div className="ms-5 mt-4 ">
                      <p
                        style={{
                          color: " #474747",
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        Select Role<span style={{ color: "red" }}>*</span>
                      </p>

                      <select
                        className="form-select mt-3"
                        id="inlineFormSelectPref"
                        style={{ width: "94%", padding: "12px" }}
                        onChange={handleSelectChange1}
                      >
                        <option selected value="">
                          Select Role
                        </option>

                        {RolesData?.map((res) => (
                          <option value={res.name} id={res.id}>
                            {res.name}
                          </option>
                        ))}
                      </select>
                    </div> */}

                    <div className="mt-5">
                      <div
                        className="ms-5 "
                        style={{
                          border: "1px solid #E9E9E9",
                          borderRadius: "10px",
                          width: "88%",
                          padding: "12px",
                        }}
                      >
                        <div className="form-check ">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="inlineFormCheck"
                            value={Techer}
                            onClick={() => {
                              AddOnther();
                              HandleProcess3();
                            }}
                          />
                          <label
                            className="form-check-label"
                            for="inlineFormCheck"
                          >
                            <span style={{ color: "#AFAFAF" }}>
                              Add as a Teacher/Professor or Academics Staff
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5">
                      <button
                        className="d-grid gap-2 col-6 mx-auto mt-4"
                        style={{
                          width: "88%",
                          padding: "10px",
                          fontSize: "18px",
                          backgroundColor: "#2188E7",
                          border: "none",
                          color: "#fff",
                          fontWeight: "500",
                          borderRadius: "5px",
                        }}
                        onClick={(e) => {
                          HandleProcess12();
                          handleClick12();
                        }}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                  {/* <04 modal> */}
                  <div style={{ display: count === 4 ? "block" : "none" }}>
                    <div className="row">
                      <div className="col-6">
                        <p
                          className="ps-5"
                          style={{
                            color: "#474747",
                            fontWeight: "600",
                            fontSize: "25px",
                          }}
                        >
                          Assign Academics
                        </p>
                      </div>
                      <div className="col-6 text-end">
                        <div
                          style={{
                            backgroundColor: "lightgray",
                            width: "47%",
                            height: "9px",
                            borderRadius: "10px",
                            marginLeft: "9rem",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "green",
                              width: `${progress}%`,
                              height: "9px",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row ms-5">
                      <div className="col-3 mt-5  ">
                        <p
                          style={{
                            fontWeight: "600",
                            fontSize: "18px",
                            color: "#474747",
                          }}
                        >
                          Select Batches<span style={{ color: "red" }}>*</span>
                        </p>
                      </div>

                      <div className="col-3 mt-5 ps-5  ">
                        <div className="dropdown">
                          <span
                            className=""
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={CoursesDataList}
                            style={{
                              height: "40px",
                              paddingLeft: "1px",
                              width: "100px",
                            }}
                          >
                            <FiFilter
                              style={{ fontSize: "25px", color: "#2188E7" }}
                            />
                            <span style={{ color: "#2188E7" }}>Courses</span>
                          </span>
                          <div>
                            <ul className="dropdown-menu">
                              <div
                                className="form-check "
                                style={{ overflowX: "scroll", height: 90 }}
                              >
                                {CoursesData?.map((res, key) => {
                                  return (
                                    <>
                                      <div className="row  ms-1">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <input
                                            key={key}
                                            className="form-check-input px-2 me-2"
                                            name="ram"
                                            type="radio"
                                            onClick={() => {
                                              Batch(res.id);
                                              setCourseId(res.id);
                                            }}
                                          />
                                          <label
                                            className="form-check-label  "
                                            for="inlineFormCheck"
                                          >
                                            <span
                                              style={{
                                                color: "#AFAFAF",
                                                fontSize: "11px",
                                              }}
                                            >
                                              {res.course_name}
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-6 mt-5 "
                        style={{ paddingLeft: "150px" }}
                      >
                        <p
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#2188E7",
                          }}
                        >
                          {dataArray1.length} : Batches Selected
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div>
                          <input
                            type="text"
                            from-control
                            placeholder="Search for Batches"
                            className="ms-5 ps-5"
                            style={{
                              border: "1px solid #E9E9E9",
                              backgroundColor: " #F7F7F7",
                              width: "87%",
                              padding: "10px",
                              borderRadius: "10px",
                            }}
                          />
                          <AiOutlineSearch
                            style={{ marginLeft: "-82%", color: "#8A8A8A" }}
                          />
                        </div>
                        <div style={{ overflow: "scroll", height: "43vh" }}>
                          {Batchs.length > 0 ? (
                            Batchs?.map((res, key) => {
                              return (
                                <>
                                  <div
                                    className="ms-5 mt-3"
                                    style={{
                                      border: "1px solid #E9E9E9",
                                      width: "87%",
                                      padding: "10px",
                                      borderRadius: "10px",
                                    }}
                                    key={key}
                                  >
                                    <div className="form-check ">
                                      <div
                                        className="form-check-label"
                                        id={res.id}
                                        value={res.batch_name}
                                        onClick={(e) => {
                                          //alert(res.batch_name);
                                          handleChange1(e);
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {res.batch_name}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <>
                              <div
                                className="d-flex mt-5"
                                style={{
                                  justifyContent: "center",
                                  color: "red",
                                  alignItems: "center",
                                }}
                              >
                                <h6>No Batchs Available</h6>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div
                        className="col-6"
                        style={{
                          border: "1px solid #2188E7",
                          width: "43%",
                          height: "50vh",
                          backgroundColor: "#F3F9FF",
                          borderRadius: "5px",
                        }}
                      >
                        {dataArray1?.map((res, ind) => {
                          return (
                            <div
                              className="mt-2"
                              style={{
                                border: "1px solid #2188E7",
                                width: "96%",
                                padding: "10px",
                                borderRadius: "10px",
                                backgroundColor: "#E8F4FF",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              key={ind}
                            >
                              <p>{res}</p>
                              <div
                                // id={}
                                value={res}
                                onClick={() => handleDelete(res, dataId[ind])}
                              >
                                <AiFillCloseCircle />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="mt-5">
                      <button
                        className="d-grid gap-2 col-6 mx-auto mt-4"
                        style={{
                          width: "88%",
                          padding: "10px",
                          fontSize: "18px",
                          backgroundColor: "#2188E7",
                          border: "none",
                          color: "#fff",
                          fontWeight: "500",
                          borderRadius: "5px",
                        }}
                        onClick={(e) => {
                          HandleProcess4();
                          handleClick12();
                        }}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>

                  {/* < 1 modal>  */}
                  <div style={{ display: count === 5 ? "block" : "none" }}>
                    <div className="row">
                      <div className="col-6">
                        <p
                          style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "25px",
                            lineHeight: "44px",
                            color: "#474747",
                          }}
                        >
                          CTC Management
                        </p>
                      </div>
                      <div className="col-6">
                        <div
                          style={{
                            backgroundColor: "lightgray",
                            width: "47%",
                            height: "9px",
                            borderRadius: "10px",
                            marginLeft: "9rem",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "green",
                              width: `${progress}%`,
                              height: "9px",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div style={{ float: "left" }}>
                        <p
                          style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "29px",
                            color: "#474747",
                          }}
                        >
                          Enter in Hand Salary
                          <span style={{ color: "red" }}>*</span>
                        </p>
                        <input
                          className="mt-2"
                          type="number"
                          placeholder="In Hand Salary"
                          style={{
                            background: "#FFFFFF",
                            borderRadius: "10px",
                            border: "1px solid #F4F4F4",
                            padding: "8px",
                          }}
                          value={Salary}
                          onChange={(e) => {
                            setSalary(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: "4.3rem !important" }}>
                      <p
                        style={{
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#3C3C3C",
                          marginTop: "4.2rem",
                        }}
                      >
                        Per month
                      </p>
                    </div>

                    {/* <div className="mt-4 d-flex">
                      <p
                        style={{
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "29px",
                          color: "#474747",
                        }}
                      >
                        Enter Instrument<span style={{ color: "red" }}>*</span>
                      </p>
                      <button
                        onClick={handleShow}
                        style={{
                          marginLeft: "20px",
                          background: "#0844f5",
                          color: "#fff",
                          padding: "3px 10px",
                          borderRadius: "4px",
                          border: "none",
                        }}
                      >
                        +
                      </button>
                    </div>

                    <div className="mt-3" style={{ overflowX: "scroll" }}>
                      <div
                        className="mt-3"
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "100px",
                        }}
                      >
                        {dataArrayName?.map((res) => {
                          return (
                            <>
                              <div
                                className=" me-3"
                                style={{
                                  background: "#FFFFFF",
                                  border: "1px solid #EEEEEE",
                                  borderRadius: "5px",
                                  padding: "10px",
                                }}
                              >
                                <p
                                  style={{
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: "#A5A5A5",
                                  }}
                                >
                                  {res.title}
                                </p>
                                <p
                                  className="mt-2"
                                  style={{
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    fontSize: "20px",
                                    lineHeight: "29px",
                                    color: "#434343",
                                  }}
                                >
                                  ₹ {res.amount} Per month
                                </p>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div> */}
                    <div className="row mt-5">
                      {/* <div className="col-6">
                        <div
                          className=""
                          style={{
                            background: "#F3F9FF",
                            border: "1px solid #2188E7",
                            borderRadius: "5px",
                            padding: "25px",
                          }}
                        >
                          <p>
                            Total Yearly CTC <span>₹ {TotalSalary * 12}</span>
                          </p>
                        </div>
                      </div> */}
                      <div className="col-6">
                        <div
                          className=""
                          style={{
                            background: "#FAFAFA",
                            border: "1px solid #BDBDBD",
                            borderRadius: "5px",
                            padding: "20px",
                          }}
                        >
                          <div className="d-flex">
                            <p className="mt-1">
                              Date of Joining
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <div className="col-2 ">
                              <div
                                className=""
                                style={{  display: "flex" }}
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    backgroundColor: "#EDEDF5",
                                    padding: "",
                                    marginLeft: "10px",
                                    borderRadius: "5px",
                                    color: "#b5b5b5",
                                    fontSize: "13px",
                                    width: "150px",
                                    height: "37px",
                                  }}
                                >
                                  <BsFillCalendarFill
                                    className="ms-2"
                                    style={{
                                      fontSize: "18px",
                                      color: "#3C3C3C",
                                      marginTop: "8px",
                                    }}
                                  />
                                  <div>
                                    <DatePicker
                                      className="border-0 ps-0 ps-2  datep"
                                      showIcon
                                      selected={startDate}
                                      onChange={(date) => {
                                        setStartDate(date);
                                        const month = (date.getMonth() + 1).toString().padStart(2, '0');
                                const day = date.getDate().toString().padStart(2, '0');
                                        setSedule(
                                          date.getFullYear() + "-" + month + "-" + day
                                        );
                                      }}
                                      style={{
                                        backgroundColor: "#E0E0E0",
                                        outline: "0",
                                      }}
                                    />
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt-3">
                      <button
                        type="submit"
                        class="btn"
                        style={{
                          width: "60%",
                          background: "#2188E7",
                          boxShadow: "0px 2px 4px rgba(61, 112, 245, 0.25)",
                          borderRadius: "10px",
                          color: "#fff",
                          padding: "10px",
                          border: "none",
                        }}
                        onClick={(e) => {
                          HandleProcess5();
                          handleClick12();
                        }}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>

                  {/* <2 modal> */}
                  <div style={{ display: count === 6 ? "block" : "none" }}>
                    <div className="row">
                      <div className="col-6">
                        <p
                          style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "25px",
                            color: "#474747",
                          }}
                        >
                          Bank Account Details
                        </p>
                      </div>

                      <div className="col-6">
                        <div
                          style={{
                            backgroundColor: "lightgray",
                            width: "47%",
                            height: "9px",
                            borderRadius: "10px",
                            marginLeft: "9rem",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "green",
                              width: `${progress}%`,
                              height: "9px",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <p
                        style={{
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "29px",
                          color: "#474747",
                        }}
                      >
                        Enter Bank Account Number
                      </p>
                      <input
                        className="mt-2"
                        type="number"
                        placeholder="Enter employee’s bank account number"
                        style={{
                          width: "70%",
                          background: "#FFFFFF",
                          borderRadius: "10px",
                          border: "1px solid #F4F4F4",
                          padding: "8px",
                        }}
                        value={AccountNumber}
                        onChange={(e) => {
                          setAccountNumber(e.target.value);
                        }}
                      />
                    </div>
                    <div className="mt-4">
                      <p
                        style={{
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "29px",
                          color: "#474747",
                        }}
                      >
                        Enter IFSC Code
                      </p>
                      <input
                        className="mt-2"
                        type="text"
                        placeholder="Enter employee’s bank IFSC Code"
                        style={{
                          width: "70%",
                          background: "#FFFFFF",
                          borderRadius: "10px",
                          border: "1px solid #F4F4F4",
                          padding: "8px",
                        }}
                        value={IFSC}
                        onChange={(e) => {
                          setIFSC(e.target.value);
                        }}
                      />
                    </div>
                    <div className="mt-4">
                      <p
                        style={{
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "29px",
                          color: "#474747",
                        }}
                      >
                        Enter Account Holder Name
                      </p>
                      <input
                        className="mt-2"
                        type="text"
                        placeholder="Enter employee’s bank account holder name"
                        style={{
                          width: "70%",
                          background: "#FFFFFF",
                          borderRadius: "10px",
                          border: "1px solid #F4F4F4",
                          padding: "8px",
                        }}
                        value={Holder}
                        onChange={(e) => {
                          setHolder(e.target.value);
                        }}
                      />
                    </div>
                    <div className="text-center mt-3">
                      <button
                        type="button"
                        class="btn"
                        style={{
                          background: "#2188E7",
                          boxShadow: "0px 2px 8px rgba(61, 112, 245, 0.25)",
                          borderRadius: "10px",
                          color: "#fff",
                          width: "80%",
                          padding: "10px",
                        }}
                        onClick={(e) => {
                          HandleProcess6();
                          handleClick12();
                        }}
                      >
                        Add Employee
                      </button>
                    </div>
                  </div>

                  {/* < 3 modal > */}
                  <div style={{ display: count === 7 ? "block" : "none" }}>
                    <div className="row">
                      <div className="col-6">
                        <p
                          style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "25px",
                            color: "#474747",
                          }}
                        >
                          Preview
                        </p>
                      </div>

                      <div className="col-6">
                        <div
                          style={{
                            backgroundColor: "lightgray",
                            width: "47%",
                            height: "9px",
                            borderRadius: "10px",
                            marginLeft: "9rem",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "green",
                              width: `${progress}%`,
                              height: "9px",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ overflowY: "scroll", height: "50vh" }}>
                      <div className="mt-3">
                        <p
                          style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "18px",
                            color: "#474747",
                          }}
                        >
                          Personal Details
                        </p>
                        <p
                          className="mt-3"
                          style={{
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#3C3C3C",
                            border: "1px solid #E9E9E9",
                            borderRadius: "10px",
                            padding: "10px",
                          }}
                        >
                          <IoPersonSharp
                            style={{ fontSize: "15px", margin: "3px 7px" }}
                          />
                          {Name}
                        </p>
                      </div>
                      <div className="mt-3">
                        <p
                          className="mt-3"
                          style={{
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#3C3C3C",
                            border: "1px solid #E9E9E9",
                            borderRadius: "10px",
                            padding: "10px",
                          }}
                        >
                          <FaUserGraduate
                            style={{ fontSize: "15px", margin: "3px 7px" }}
                          />
                          {Hq}
                        </p>
                      </div>
                      <div className="mt-3">
                        <p
                          className="mt-3"
                          style={{
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#3C3C3C",
                            border: "1px solid #E9E9E9",
                            borderRadius: "10px",
                            padding: "10px",
                          }}
                        >
                          <IoMdSchool
                            style={{ fontSize: "15px", margin: "3px 7px" }}
                          />
                          {NOI}
                        </p>
                      </div>
                      <div className="row mt-3">
                        <div className="col-6">
                          <div
                            class="input-group "
                            style={{ color: "#BDBDBD" }}
                          >
                            <span class="input-group-text" id="basic-addon1">
                              +91
                            </span>
                            <input
                              type="text"
                              className="form-control p-2"
                              placeholder=""
                              value={Number}
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            class="input-group "
                            style={{ color: "#BDBDBD" }}
                          >
                            <span class="input-group-text" id="basic-addon1">
                              +91
                            </span>
                            <input
                              type="text"
                              className="form-control p-2"
                              placeholder=""
                              value={Num1}
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              readOnly
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mt-3">
                        <p
                          className="mt-3"
                          style={{
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#3C3C3C",
                            border: "1px solid #E9E9E9",
                            borderRadius: "10px",
                            padding: "10px",
                          }}
                        >
                          <AiOutlineMail
                            style={{ fontSize: "15px", margin: "3px 7px" }}
                          />
                          {Email}
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p
                            className="mt-3"
                            style={{
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#3C3C3C",
                              border: "1px solid #E9E9E9",
                              borderRadius: "10px",
                              padding: "10px",
                            }}
                          >
                            <FaBirthdayCake
                              style={{ fontSize: "15px", margin: "3px 7px" }}
                            />
                            {Dob}
                          </p>
                        </div>
                        <div className="col-6">
                          <p
                            className="mt-3"
                            style={{
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#3C3C3C",
                              border: "1px solid #E9E9E9",
                              borderRadius: "10px",
                              padding: "10px",
                            }}
                          >
                            <ImDroplet
                              style={{
                                fontSize: "15px",
                                margin: "3px 7px",
                                color: "red",
                              }}
                            />
                            {Blood}
                          </p>
                        </div>
                      </div>
                      <div className="mt-4">
                        <p
                          style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "24px",
                            color: "#474747",
                          }}
                        >
                          Address Details
                        </p>
                        <p
                          className="mt-3"
                          style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "18px",
                            color: "#474747",
                          }}
                        >
                          Residential Address
                        </p>
                      </div>

                      <div className="mt-3">
                        <p
                          className="mt-3"
                          style={{
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#3C3C3C",
                            border: "1px solid #E9E9E9",
                            borderRadius: "10px",
                            padding: "10px",
                          }}
                        >
                          {Address}
                        </p>
                      </div>

                      <div className="row">
                        <div className="col-2">
                          <p
                            className="mt-3"
                            style={{
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#3C3C3C",
                              border: "1px solid #E9E9E9",
                              borderRadius: "10px",
                              padding: "13px",
                            }}
                          >
                            {District}
                          </p>
                        </div>
                        <div className="col-3">
                          <p
                            className="mt-3"
                            style={{
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#3C3C3C",
                              border: "1px solid #E9E9E9",
                              borderRadius: "10px",
                              padding: "13px",
                            }}
                          >
                            {State}
                          </p>
                        </div>
                        <div className="col-3">
                          <p
                            className="mt-3"
                            style={{
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#3C3C3C",
                              border: "1px solid #E9E9E9",
                              borderRadius: "10px",
                              padding: "13px",
                            }}
                          >
                            {Pin}
                          </p>
                        </div>
                      </div>
                      <div className="mt-3">
                        <p
                          style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "25px",
                            color: "#474747",
                          }}
                        >
                          Permanent Address
                        </p>
                      </div>

                      <div className="mt-3">
                        <p
                          className="mt-3"
                          style={{
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#3C3C3C",
                            border: "1px solid #E9E9E9",
                            borderRadius: "10px",
                            padding: "10px",
                          }}
                        >
                          {PAddress}
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-2">
                          <p
                            className="mt-3"
                            style={{
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#3C3C3C",
                              border: "1px solid #E9E9E9",
                              borderRadius: "10px",
                              padding: "13px",
                            }}
                          >
                            {PDistrict}
                          </p>
                        </div>
                        <div className="col-3">
                          <p
                            className="mt-3"
                            style={{
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#3C3C3C",
                              border: "1px solid #E9E9E9",
                              borderRadius: "10px",
                              padding: "13px",
                            }}
                          >
                            {PState}
                          </p>
                        </div>
                        <div className="col-3">
                          <p
                            className="mt-3"
                            style={{
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#3C3C3C",
                              border: "1px solid #E9E9E9",
                              borderRadius: "10px",
                              padding: "13px",
                            }}
                          >
                            {PPin}
                          </p>
                        </div>
                      </div>
                      <div className="mt-3">
                        <p
                          style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "24px",
                            color: "#474747",
                          }}
                        >
                          Access Control Details
                        </p>
                      </div>
                      <div className="mt-3">
                        <p
                          style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "#474747",
                          }}
                        >
                          Department
                        </p>
                        <p
                          className="mt-3"
                          style={{
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#3C3C3C",
                            border: "1px solid #E9E9E9",
                            borderRadius: "10px",
                            padding: "13px",
                          }}
                        >
                          {selectedOption.value}
                        </p>
                      </div>
                      
                      <div className="mt-3">
                        <p
                          style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "18px",
                            color: "#474747",
                          }}
                        >
                          Assigned Batches
                        </p>
                        {dataArray1?.map((res) => {
                          return (
                            <>
                              <p
                                className="mt-3"
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  color: "#3C3C3C",
                                  border: "1px solid #E9E9E9",
                                  borderRadius: "10px",
                                  padding: "13px",
                                }}
                              >
                                {res}
                              </p>
                            </>
                          );
                        })}
                      </div>
                      <div className="mt-3">
                        <p
                          style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "24px",
                            color: "#474747",
                          }}
                        >
                          CTC Details
                        </p>
                      </div>
                      <div className="mt-3">
                        <p
                          className="mt-3"
                          style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "#474747",
                          }}
                        >
                          In Hand Salary
                        </p>
                        <p
                          className="mt-3"
                          style={{
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#3C3C3C",
                            border: "1px solid #E9E9E9",
                            borderRadius: "10px",
                            padding: "10px",
                            width: "50%",
                          }}
                        >
                          ₹ {Salary} per month
                        </p>
                      </div>
                      
                      <div
                        className=" mt-4"
                        style={{
                          background: "#FFFFFF",
                          border: "1px solid #EEEEEE",
                          borderRadius: "5px",
                          width: "50%",
                          padding: "10px",
                          height: "5rem",
                        }}
                      >
                        <div className="row mt-4">
                          <div className="col-6">
                            <p
                              style={{
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "20px",
                                color: "#838383",
                                padding: "0px 14px",
                              }}
                            >
                              Date of Joining
                            </p>
                          </div>
                          <div className="col-6">
                            <div
                              className=""
                              style={{ lineHeight: "1px", display: "flex" }}
                            >
                              <span
                                style={{
                                  display: "flex",
                                  backgroundColor: "#EDEDF5",
                                  padding: "",
                                  marginLeft: "10px",
                                  borderRadius: "5px",
                                  color: "#b5b5b5",
                                  fontSize: "13px",
                                  width: "150px",
                                  height: "37px",
                                }}
                              >
                                <BsFillCalendarFill
                                  className="ms-2"
                                  style={{
                                    fontSize: "18px",
                                    color: "#3C3C3C",
                                    marginTop: "8px",
                                  }}
                                />
                                <div style={{ marginTop: 18 }}>
                                  <span style={{ fontSize: 18 }}>{Sedule}</span>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <p
                          style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "36px",
                            color: "#474747",
                          }}
                        >
                          Bank Account Details
                        </p>
                      </div>
                      <div className="mt-3">
                        <p
                          style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "#474747",
                          }}
                        >
                          Bank Account Number
                        </p>
                        <p
                          className="mt-3"
                          style={{
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#3C3C3C",
                            border: "1px solid #E9E9E9",
                            borderRadius: "10px",
                            padding: "13px",
                          }}
                        >
                          {AccountNumber}
                        </p>
                      </div>
                      <div className="mt-3">
                        <p
                          style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "#474747",
                          }}
                        >
                          IFSC Code
                        </p>
                        <p
                          className="mt-3"
                          style={{
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#3C3C3C",
                            border: "1px solid #E9E9E9",
                            borderRadius: "10px",
                            padding: "13px",
                          }}
                        >
                          {IFSC}
                        </p>
                      </div>
                      <div className="mt-3">
                        <p
                          style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "#474747",
                          }}
                        >
                          Account Holder Name
                        </p>
                        <p
                          className="mt-3"
                          style={{
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#3C3C3C",
                            border: "1px solid #E9E9E9",
                            borderRadius: "10px",
                            padding: "13px",
                          }}
                        >
                          {Holder}
                        </p>
                      </div>
                    </div>
                    <div className="mt-3">
                      <button
                        type="button"
                        class="btn"
                        style={{
                          background: "#2188E7",
                          boxShadow: "0px 2px 8px rgba(61, 112, 245, 0.25)",
                          borderRadius: "10px",
                          color: "#fff",
                          width: "100%",
                          padding: "10px",
                        }}
                        onClick={() => {
                          setcount(count + 1);
                          Add();
                          setProgress(progress + 17);
                        }}
                      >
                        Add Employee
                      </button>
                    </div>
                  </div>

                  {/* <4 modal> */}
                  <div style={{ display: count === 8 ? "block" : "none" }}>
                    <div className="text-center">
                      <p
                        style={{
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "30px",
                          color: "#474747",
                        }}
                      >
                        New Employee Added<br></br> Successfully !!
                      </p>
                    </div>
                    <div
                      className="d-flex "
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <Lottie
                        animationData={lotti1}
                        style={{
                          width: "31%",
                          height: "25rem",
                          marginLeft: "0rem",
                        }}
                      />
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        class="btn"
                        data-bs-dismiss="modal"
                        style={{
                          width: "60%",
                          background: "#2188E7",
                          boxShadow: "0px 2px 4px rgba(61, 112, 245, 0.25)",
                          borderRadius: "10px",
                          color: "#fff",
                          padding: "10px",
                          border: "none",
                        }}
                      >
                        Back to Team
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* <Cancel Modal> */}

              <Modal show={show2} onHide={handleClose2} animation={false}>
                <Modal.Body>
                  <div className="text-center">
                    <p
                      className="mt-3"
                      style={{
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "25px",
                        color: "#474747",
                      }}
                    >
                      Cancel Adding the Employee ?
                    </p>
                    <p
                      className="mt-4"
                      style={{
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#474747",
                      }}
                    >
                      This action can not be reversed & all<br></br> the
                      progress will be lost
                    </p>
                  </div>
                  <div className="row mb-3 px-2 mt-5">
                    <div className="col-6">
                      <button
                        type="button"
                        class="btn"
                        data-bs-dismiss="modal"
                        style={{
                          width: "100%",
                          background: "#CC1313",
                          boxShadow: "0px 2px 4px rgba(61, 112, 245, 0.25)",
                          borderRadius: "10px",
                          border: "none",
                          color: "#fff",
                          padding: "10px 10px",
                        }}
                        onClick={() => {
                          handleClose2();
                          setcount(1);
                          setProgress(0);
                          setName("");
                          setHq("");
                          setNOI("");
                          setHolder("");
                          setIFSC("");
                          setAccountNumber("");
                          setCoursesData([]);
                          setRolesData([]);
                          setDepartDatas([]);
                          setamount("");
                          settitle("");
                          setTecher(0);
                          setPState("");
                          setPAddress("");
                          setPDistrict("");
                          setBlood("");
                          settitle("");
                          handleClose1();
                          setdataId([]);
                          setdataArrayName([]);
                          setdataArray1([]);
                          Api();
                          setcount(1);
                          setNumber("");
                          setEmail("");
                          setDob("");
                          setNum1("");
                          setAddress("");
                          setDistrict("");
                          setPin("");
                          setState("");
                          setPPin("");
                          setPState("");
                          setPDistrict("");
                          setPAddress("");
                        }}
                      >
                        Yes, Cancel Process
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        type="button"
                        class="btn "
                        style={{
                          width: "100%",
                          background: "#2188E7",
                          boxShadow: "0px 2px 4px rgba(61, 112, 245, 0.25)",
                          borderRadius: "10px",
                          border: "none",
                          color: "#fff",
                          padding: "10px 10px",
                        }}
                        onClick={() => {
                          handleShow1();
                          handleClose2();
                        }}
                      >
                        No, Continue Adding
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* <!-- end Add Employee--> */}

              {/* <!--Add Instrument--> */}

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Instrument</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Enter Title Of Instrument</p>
                  <input
                    className="mt-2"
                    type="text"
                    placeholder="Eg.: HRA"
                    style={{
                      background: "#FFFFFF",
                      borderRadius: "10px",
                      border: "1px solid #F4F4F4",
                      padding: "8px",
                    }}
                    value={title}
                    onChange={(e) => {
                      settitle(e.target.value);
                    }}
                  />
                  <p
                    style={{
                      marginLeft: "13rem",
                      fontSize: "11px",
                      color: "#8A8A8A",
                    }}
                  >
                    {title.length}/30
                  </p>
                  <div style={{ float: "left" }}>
                    <p>Enter Amount</p>
                    <input
                      className="mt-2"
                      type="number"
                      placeholder="₹ 50,000"
                      style={{
                        background: "#FFFFFF",
                        borderRadius: "10px",
                        border: "1px solid #F4F4F4",
                        padding: "8px",
                      }}
                      value={amount}
                      onChange={(e) => {
                        setamount(e.target.value);
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "2.3rem !important" }}>
                    <p
                      style={{
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#3C3C3C",
                        marginTop: "4.2rem",
                      }}
                    >
                      Per month
                    </p>
                  </div>
                  <div className="mt-4 text-center">
                    <button
                      type="submit"
                      class="btn"
                      disabled={
                        title.length >= 1 && amount.length >= 1 ? false : true
                      }
                      onClick={() => {
                        handleClose();
                        handleChangeName();
                      }}
                      style={{
                        padding: "14px",
                        border: "none",
                        width: "100%",
                        background: "#2188E7",
                        boxShadow: "0px 4px 16px rgba(61, 112, 245, 0.25)",
                        borderRadius: "10px",
                        color: "#fff",
                      }}
                    >
                      Primary
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>

            <div className="container mt-3">
              <div className="row">
              {isLoading ? (
                <>
              <Loader show={isLoading} />
              </>
                ) : (
                  Data?.map((res, key) => {
                    return (
                      <>
                        <div
                          key={key}
                          className="col-sm-12 col-md-4 col-lg-4 mt-2"
                        >
                          <div
                            className="card"
                            style={{
                              width: "100%",
                              height: "auto",
                              background: "#FFFFFF",
                              border: "1px solid #EFEFEF",
                              boxShadow: "0px 4px 4px rgba(57, 80, 126, 0.14)",
                              borderRadius: "10px",
                            }}
                          >
                            <div className="row mt-2 align-items-center">
                              <div className="col-3 mt-2">
                                <div
                                  style={{
                                    backgroundColor: "rgb(237, 255, 243)",
                                    marginLeft: "20px",
                                    borderRadius: "50%",
                                    width: "60px",
                                    border: "1px solid #808080",
                                    height: "60px",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "rgb(23, 165, 54)",
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop: "12px",
                                      fontSize: "25px",
                                      fontWeight: "bold",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {res.name.split("", 1)}
                                  </p>
                                </div>
                              </div>
                              <div className="col-8 ">
                                <p
                                  className="mt-1"
                                  style={{
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    color: "#000000",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {res.name}
                                </p>
                                {/* <p
                                  className="mt-1"
                                  style={{
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: "#8A8A8A",
                                  }}
                                >
                                  {res.role_name}
                                  <span
                                    style={{
                                      background: "#E3FFF3",
                                      padding: "2px 10px",
                                      borderRadius: "4px",
                                      color: "#2E8760",
                                    }}
                                  >
                                    {res.status == "0" ? "Offline" : "Online"}
                                  </span>
                                </p> */}
                                <p
                                  className=""
                                  style={{
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    color: "#8A8A8A",
                                  }}
                                >
                                  {res.department}
                                </p>
                              </div>
                            </div>
                            <div
                              className="mt-2"
                              style={{
                                borderBottom: "1px solid #EFEFEF",
                                marginLeft: "17px",
                                width: "90%",
                              }}
                            ></div>
                            <div className="mt-2 ps-2">
                              <span className="me-5">
                                <img
                                  src={EEi}
                                  alt="s"
                                  style={{
                                    marginLeft: "10px",
                                    borderRadius: "20px",
                                    width: "6%",
                                    border: "1px solid #EFEFEF",
                                    background: "#EFEFEF",
                                  }}
                                />
                                <span
                                  className="ms-2"
                                  style={{
                                    color: "#8A8A8A",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                  }}
                                >
                                  Joined {res.join_date}
                                </span>
                              </span>
                            </div>
                            <div className="mt-2">
                              <div className="row ps-2 ">
                                <div className="col-6">
                                  <span>
                                    <img
                                      className="me-2"
                                      src={EEi1}
                                      style={{
                                        marginLeft: "10px",
                                        width: "15%",
                                      }}
                                    />
                                    <span
                                      className="ms-2"
                                      style={{
                                        color: "#8A8A8A",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Assigned
                                      <span
                                        style={{ color: "#CC1313" }}
                                        className="ms-2"
                                      >
                                        {res.total_task}
                                        
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div className="col-6">
                                  <span>
                                    <img
                                      className="me-2"
                                      src={EEi2}
                                      style={{
                                        marginLeft: "10px",
                                        width: "15%",
                                      }}
                                    />
                                    <span
                                      className="ms-2"
                                      style={{
                                        color: "#8A8A8A",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Completed
                                      <span
                                        style={{ color: "#2E8760" }}
                                        className="ms-2"
                                      >
                                        {res.completed_task_count}
                                      </span>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="mt-2">
                              <div className="row mx-2 mt-2 ">
                                <div className="col-6 ">
                                  <NavLink
                                    to={`/Grantleaveviewdetail/${res.id}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <button
                                      type="button"
                                      class="btn btn-primary"
                                      style={{
                                        width: "100%",
                                        background:
                                          "linear-gradient(180deg, #8147E7 0%, #462483 100%)",
                                        borderRadius: "7px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent:"center"
                                      }}
                                    >
                                      <IoEyeOutline className="me-2" /> View
                                      Profile
                                    </button>
                                  </NavLink>
                                </div>
                                <div className="col-6">
                                  <button
                                    onClick={() => {
                                      setEmployName(res.name);
                                      setEmployid(res.id);
                                    }}
                                    type="button"
                                    class="btn btn-primary "
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasExample1"
                                    aria-controls="offcanvasExample1"
                                    style={{
                                      width: "100%",
                                      background:"transparent",
                                      borderRadius: "7px",
                                      // display: "flex",
                                      alignItems: "center",
                                      border:"1px solid #000",
                                      color:"#000"
                                    }}
                                  >
                                    +Assign Task
                                  </button>
                                </div>
                              </div>
                            </div>



                            <div className="mt-2">
                              <div className="row mb-4 mx-2 mt-2 ">
                              <div className="col-6">
                                  <button
                                    onClick={() => 
                                      Navigate(`/Salarymanagement/${res.id}/${res.name}/${res.department_id}/${res.department}/1/${res.enrollment_id}`)
                                    }
                                    type="button"
                                    class="btn btn-primary "
                                    style={{
                                      width: "100%",
                                      background:"transparent",
                                      borderRadius: "7px",
                                      // display: "flex",
                                      alignItems: "center",
                                      border:"1px solid #000",
                                      color:"#000"
                                    }}
                                  >
                                    Manage Salary
                                  </button>
                                </div>
                                <div className="col-6 ">
                                  {/* <NavLink
                                    to={`/Grantleaveviewdetail/${res.id}`}
                                    style={{ textDecoration: "none" }}
                                  > */}
                                    <button
                                      type="button"
                                      class="btn btn-primary"
                                    
                                      style={{
                                        width: "100%",
                                        background:
                                          "linear-gradient(180deg, #8147E7 0%, #462483 100%)",
                                        borderRadius: "7px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent:"center"
                                      }}
                                      onClick={()=>{
                                        handleShow3(res.id);
                                      }}
                                    >
                                       Change Timing
                                    </button>
                                  {/* </NavLink> */}

                                  <Modal show={show3} onHide={handleClose3} centered >
        <Modal.Header closeButton className="border-0" >
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
        <Form>
      <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>Enrollment Id</Form.Label>
        <div className="">
                          <div class="input-group">
                            <div
                              class="input-group-text"
                              // style={{ color: "#AFAFAF" }}
                            >
                              5555
                            </div>
                            <input
  type="number"
  class="form-control"
  id="inlineFormInputGroupUsernam"
  placeholder="1234"
  // style={{ padding: "12px" }}  
  value={enrollmentId}
  onChange={(e) => {
    setEnrollmentId(e.target.value);
  }}
/>
                          </div>
                        </div>

<div className="row mt-3" >
  <div className="col-6" >
<p>In Time</p>
                        <span
                        className="col-4 rounded"
                        style={{ backgroundColor: "#EFEFEF" }}
                      >
                        <input
                          className="border-0 rounded mt-1 p-2"
                          type="time"
                          style={{
                            backgroundColor: "#EFEFEF",
                            color: "#8A8A8A",
                            outline: "none",
                          }}
                          value={inTime}
                          onChange={(e) => {
                            setInTime(e.target.value);
                          }}
                        />
                      </span>
  </div>
  <div className="col-6" >
  <p>Out Time</p>
                        <span
                        className="col-4 rounded"
                        style={{ backgroundColor: "#EFEFEF", }}
                      >
                        <input
                          className="border-0 rounded mt-1 p-2"
                          type="time"
                          style={{
                            backgroundColor: "#EFEFEF",
                            color: "#8A8A8A",
                            outline: "none",
                          }}
                          value={outTime}
                          onChange={(e) => {
                            setOutTime(e.target.value);
                          }}
                        />
                      </span>
  </div>

</div>



      </Form.Group>
    </Form>
        </Modal.Body> 
        <Modal.Footer className='border-0 justify-content-center' >
          <button onClick={()=>{
            if(enrollmentId<=0){
              toast.error("pls enter enrollment ID")
            }else{
            changeTiming();
            
            }
          }}  className="btn btn-primary w-75 py-2" >Proceed</button>
        </Modal.Footer>
        
      </Modal>
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                )}
              </div>
            </div>

            <div
              class="offcanvas offcanvas-end"
              tabindex="-1"
              id="offcanvasExample1"
              aria-labelledby="offcanvasExampleLabel"
              style={{ width: "40%" }}
            >
              <div class="offcanvas-header">
                <button
                  type="button"
                  class="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  onClick={() => {
                    setcount(1);
                  }}
                ></button>
              </div>
              <div class="offcanvas-body">
                <div style={{ display: count == 1 ? "block" : "none" }}>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "25px",
                      color: "#474747",
                    }}
                  >
                    Assign Task
                  </p>
                  <p
                    className="mt-2"
                    style={{
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#474747",
                    }}
                  >
                    <IoPerson />
                    {EmployName}
                  </p>

                  <input
                    type="text"
                    className="mt-3"
                    placeholder="Task Title"
                    style={{
                      background: "#F5F5F5",
                      borderRadius: "10px",
                      width: "100%",
                      border: "none",
                      padding: "7px 9px",
                    }}
                    value={TaskValue}
                    onChange={(e) => {
                      setTaskValue(e.target.value);
                    }}
                  />
                  <p
                    style={{
                      float: "right",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      color: "#848484",
                    }}
                  >
                    {TaskValue.length}/100
                  </p>

                  <div className="mt-3">
                    <textarea
                      placeholder="Task Description"
                      style={{
                        background: "#F5F5F5",
                        borderRadius: "10px",
                        border: "none",
                        width: "100%",
                        padding: "7px 9px",
                        height: "161px",
                      }}
                      value={taskDes}
                      onChange={(e) => {
                        settaskDes(e.target.value);
                      }}
                    ></textarea>
                    <p
                      style={{
                        float: "right",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#848484",
                      }}
                    >
                      {taskDes.length}/1000
                    </p>
                  </div>

                  <div>
                    <div
                      className="mt-4"
                      style={{ width: "40%", height: "auto" }}
                    >
                      <label
                        className="filelabel1 w-100 ps-3 pt-2"
                        style={{
                          background: "#FFFFFF",
                          border: "1px solid #000000",
                          borderRadius: "5px",
                          color: "#000000",
                          height: "3rem",
                        }}
                      >
                        <div className="title1">
                          <h6
                            className=" pt-1"
                            src={Images}
                            style={{
                              width: "100%",
                              textAlign: "center",
                              color: "#000000",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <AiOutlineCloudUpload
                              className="me-2"
                              style={{ fontSize: "22px" }}
                            />
                            Add Attachments
                          </h6>
                        </div>

                        <input
                          className="FileUpload1"
                          id="FileInput1"
                          name="booking_attachment1"
                          type="file"
                          // multiple
                          // accept=".png,.jpg"
                          accept=".pdf"
                          placeholder="Add attachment"
                          // onChange={TotalArray}
                          onChange={(e) => {
                                setImages(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                setBanner(e.target.files[0]);
                              }}
                          style={{ opacity: "0" }}
                        />
                      </label>
                    </div>

                    <div
                      className="col-9 mt-5  d-flex"
                      style={{
                        background: "#FFFFFF",
                        border: "1px solid #000000",
                        borderRadius: "5px",
                        padding: "4px 2px",
                      }}
                    >
                      <span
                        className="mt-1 ps-2 text-nowrap"
                        style={{
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#000000",
                        }}
                      >
                        Due Date
                      </span>

                      <span
                        className="col-4 ms-2 d-flex rounded"
                        style={{
                          backgroundColor: "#EFEFEF",
                          overflow: "hidden",
                          height: "35px",
                          width: "38%",
                        }}
                      >
                        <img className="p-2" src={Dates} alt="#" />

                        <DatePicker
                          className="Main "
                          dateFormat="yyyy/MM/dd "
                          selected={startDate}
                          onChange={(date: Date) => {
                            setStartDate(date);
                            setSeduled(
                              date.getFullYear() +
                              "-" +
                              (parseInt(date.getMonth()) + 1) +
                              "-" +
                              date.getDate()
                            );
                          }}
                        />
                      </span>
                      <span
                        className="col-4 ms-4 rounded"
                        style={{ backgroundColor: "#EFEFEF", height: "35px" }}
                      >
                        <input
                          className="border-0 rounded mt-1 ms-3"
                          type="time"
                          style={{
                            backgroundColor: "#EFEFEF",
                            color: "#8A8A8A",
                            outline: "none",
                          }}
                          value={Times}
                          onChange={(e) => {
                            setTimes(e.target.value);
                          }}
                        />
                      </span>
                    </div>

                    <div></div>

                    <div className="text-center mt-5">
                      <button
                        type="button"
                        class="btn btn-primary"
                        style={{ width: "50%" }}
                        onClick={() => {
                          setcount(count + 1);
                        }}
                      >
                        Assign Task
                      </button>
                    </div>
                  </div>
                </div>

                {/* second modal start */}

                <div style={{ display: count === 2 ? "block" : "none" }}>
                  <div className="text-center">
                    <p
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "28px",
                        textAlign: "center",
                        color: "#474747",
                      }}
                    >
                      Following Task will be assigned to
                    </p>
                    <p
                      className="mt-3"
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#474747",
                      }}
                    >
                      <IoPerson />
                      {EmployName}
                    </p>
                    <p
                      className="mt-3"
                      style={{
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#000000",
                      }}
                    >
                      Due Date &nbsp;&nbsp;&nbsp;<span>{Seduled}</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span>{Times}</span>
                    </p>
                  </div>

                  <div className="card ">
                    <p
                      className="ps-3 mt-3"
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#989898",
                      }}
                    >
                      Task Title
                    </p>
                    <p
                      className="ps-3 "
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        color: "#474747",
                      }}
                    >
                      {TaskValue}
                    </p>
                    <p
                      className="ps-3 mt-3"
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "13px",
                        color: "#989898",
                      }}
                    >
                      Task Description
                    </p>
                    <div style={{ overflowY: "scroll", height: "30vh" }}>
                      <p
                        className="ps-3 px-3 "
                        style={{
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#696969",
                        }}
                      >
                        {taskDes}
                      </p>
                    </div>

                    <div className="row mt-2 ps-3 px-3">
                      <div className="col-3">
                        <p
                          className="text-center mb-2"
                          style={{
                            background: "#FFFFFF",
                            border: "1px solid #DBDBDB",
                            borderRadius: "5px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "13px",
                            color: "#434343",
                          }}
                        >
                          {Banner?.name}
                        </p>
                      </div>
                    </div>

                    <div className="text-center mt-3 mb-3">
                      <button
                        type="button"
                        class="btn btn-primary"
                        style={{ width: "50%" }}
                        onClick={() => {
                          setcount(count + 1);
                          AddTask(Employid, Adname);
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
                {/* second modal end */}

                {/* third slider start */}

                <div style={{ display: count === 3 ? "block" : "none" }}>
                  <div className="text-center">
                    <p
                      className="mt-5"
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "36px",
                        textAlign: "center",
                        color: "#474747",
                      }}
                    >
                      1 Task assigned Successfully
                      <br /> to {EmployName}
                    </p>
                  </div>

                  <div
                    className="d-flex"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Lottie animationData={lotti} style={{ width: "40%" }} />
                  </div>

                  <div className="text-center mt-3 mb-3">
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      style={{ width: "50%" }}
                      onClick={() => {
                        setcount(1);
                      }}
                    >
                      Back to Dashboard
                    </button>
                  </div>
                </div>
                {/* third slider end */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
