import React, { useState } from "react";
import { BsFillCalendarFill } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { IoIosWarning } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL, img_url } from "../env";

const SingleAttendance = () => {
  const [startDate1, setStartDate1] = useState(new Date());
  const [data, setData] = useState(1);
  const [cardData,setCardData] = useState([]);
  const {enrollment_id} = useParams();
  const Navigate = useNavigate()

    const singleDayAttendance = async()=>{
    try{
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );
      var raw = JSON.stringify({
        "search_date": startDate1.toISOString().split('T')[0],
        "enrollment_id": enrollment_id,
      });
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${API_URL}/admin/student/get/single/day/attendence`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          setCardData(result.data || []);
          setData(2);
        } 
        else if (result.status === 'VAL_ERR') { 
          toast(result.Backend_Error) 
        } 
      else if (result.status == 'FILE_ERR') { 
          toast(result.Backend_Error) 
        } 
       else if (result.status == "CUSTOM_ERR") { 
          toast(result.Backend_Error); 
        } else if (result.status == "TOKEN_ERR") { 
          localStorage.removeItem('api_url'); 
          Navigate('/code') 
          toast("Logged in other device! Please login again") 
        } 
        else { 
          console.log(result) 
        }
      })
  .catch(error => console.log('error', error));

    }catch(e){
      console.log(e);
    }

  }

  return (
    <>
      <div className="col-8" style={{ }}>
        {data == 1 ? (
          <>
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="card w-50 mx-auto p-3">
                <h5 className="mb-0 text-center"> <IoIosWarning /> please select date</h5>
              </div>
            </div>
          </>
        ) : (
          <>
          <div className="d-flex justify-content-center align-items-center h-100 ">
          {cardData.map((data, value) => (
            <div className="card w-50 mx-auto p-3 " key={value}>
              <div className="d-flex justify-content-between align-items-center">
                <span className="p-1 px-3" style={{background:"#FFF3E5",borderRadius:"5px",color:"#C37E16",fontWeight:600 }} >{data.enrollment_id}</span>
                <span className="p-1 px-3" style={{background:
                  data.status === "Present"
                    ? "#DBFFF0"
                    : data.status === "half_day"
                    ? "#F0FAC4"
                    : data.status === "Absent"
                    ? "#FFE9E9"
                    : "white",
                color:
                  data.status === "Present"
                    ? "#359A73"
                    : data.status === "half_day"
                    ? "#C3BC16"
                    : data.status === "Absent"
                    ? "#E91C1C"
                    : "black",fontWeight:600,borderRadius:"5px" }} >{data.status}</span>
              </div>
              <Form.Group className="mt-3">
                <Form.Label>Date</Form.Label>
                <Form.Control placeholder={data.attendence_date} disabled />
              </Form.Group>
              <Form className="mt-3 mb-2">
                <Row>
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>In-Time</Form.Label>
                    <Form.Control placeholder={data.in_time} disabled />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>Out-Time</Form.Label>
                    <Form.Control placeholder={data.out_time} disabled />
                  </Form.Group>
                </Row>
              </Form>
            </div>
          ))}
        </div>
          </>
        )}
        
      </div>

      <div className="col-2 p-2 its d-flex">
        <div className="w-100" style={{marginTop:"40px"}} >
          <h5 className="mb-0">Select a date</h5>
          <span
            className="mt-3 w-100 py-1"
            style={{
              display: "flex",
              backgroundColor: "#EDEDF5",
              borderRadius: "5px",
              color: "#b5b5b5",
              fontSize: "13px",
            //   width: "150px",
            //   height: "37px",
            }}
          >
            <BsFillCalendarFill
              className="ms-2"
              style={{ fontSize: "18px", color: "#3C3C3C", marginTop: "8px" }}
            />
            <div>
              <DatePicker
                className="border-0  ps-2 pt-1  datep"
                showIcon
                selected={startDate1}
                onChange={(date) => {
                  setStartDate1(date);
                  
                  //     const req_date = date.getFullYear() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getDate();
                  //    CollectData(req_date);
                }}
                style={{ backgroundColor: "#E0E0E0", outline: "0" }}
              />
            </div>
          </span>
          <div className="mt-4">
            <button
              onClick={singleDayAttendance}
              className="w-100 py-2 border-0"
              style={{
                borderRadius: "5px",
                backgroundColor: "#8147E7",
                color: "#fff",
              }}
            >
              View Attendance
            </button>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>
  );
};

export default SingleAttendance;
