import React, { useEffect, useContext, memo } from 'react';
// import { IoMdBookmarks } from 'react-icons/io';
// import { MdAccountBalanceWallet } from 'react-icons/md';
// import { FaHeadphones } from 'react-icons/fa';
// import { FaUserGraduate } from 'react-icons/fa';
// import { MdDataUsage } from 'react-icons/md';
// import { FaUsers } from 'react-icons/fa';
// import { IoMdCalendar } from 'react-icons/io';
import { AiOutlineSetting } from 'react-icons/ai';
import { FaImages } from 'react-icons/fa';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
// import Tipsglogo from '../images/tipsglogo.png';
import dashboard1 from '../images/Vector (4).svg';
import dashboard2 from '../images/Vector (5).svg';
import dashboard3 from '../images/Vector (6).svg';
import dashboard4 from '../images/Group 356.svg';
import dashboard5 from '../images/Vector (8).svg';
import dashboard6 from '../images/Group 364.svg';
import dashboard7 from '../images/Vector (10).svg';
import dashboard8 from '../images/Vector (14).svg';
import dashboard9 from '../images/Vector (12).svg';
import dashboard10 from '../images/Vector (13).svg';
import { motion } from "framer-motion";
import { flipInY } from 'react-animations';
import { StyleSheet, css } from 'aphrodite';
import { BiLogOutCircle } from 'react-icons/bi';
// import { BsGraphUp } from 'react-icons/bs';
import { AuthContext } from './AuthContext';
import { API_URL,img_url } from '../env';
import Logoupdate from './Logoupdate';
// import { img_url } from './MyComponent';




const styles = StyleSheet.create({
  bounce: {
    animationName: flipInY,
    animationDuration: '1s'
  }
})



 function SideBar() {

  const Navigate = useNavigate();
  const location = useLocation();
  const { logout, apiToken, profile, profileGet } = useContext(AuthContext);


  //required destructruing from above state objects;
  const { name, banner } = profile;

  let access_control =localStorage.getItem("access_control").split(",");
  console.log(access_control.includes('Academics'))


  // console.log('insidebar', apiToken, banner);


  useEffect(() => {
    // profileGet();
  }, [])

  return (
    <div  >

      <div className='row  ' >
        <ul className="list-group text-start list" style={{ borderRight: '1px solid #B1AFC8', borderRadius: 0, height:'100vh', position: 'fixed', width: 220 }}>
          <div className="mt-3 d-flex justify-content-center" style={{ height: '80px' }} >
            {
              banner === "" ? " " :
                <img src={`${banner}`} className='header' style={{ color: 'black', width: "190px", }} />
            }  

            {/* < Logoupdate/> */}
          </div>
          <div className='mb-5' style={{ height: "100%", overflowY: "auto" }}>
            <li></li>
            {
              access_control.includes("Dashboard")==true?
              <>
              <NavLink to='/Dashboard' className="text-decoration-none text-dark " style={styles.bounce} >
              <li className={"list-group-item mb-4 px-4 text-decoration-none side_list" + (location.pathname == '/Dashboard' ? ' active' : ' ')} aria-current="true" style={{ fontSize: '16px' }}>
                <span style={{}}><img src={dashboard1} style={{ color: 'black', width: '11%', }} />&emsp;Dashboard</span>
              </li>
            </NavLink>
              </>:<></>
            }
            {
              access_control.includes("Academics")==true?<>
              <NavLink to='/Academics' className="text-decoration-none text-dark" style={styles.bounce}>
              <li className={"list-group-item mb-4 px-4 text-decoration-none side_list" + (location.pathname == '/Academics' ? ' active' : '')} aria-current="true" style={{ fontSize: '16px' }}>
                <span style={{}}><img src={dashboard2} style={{ color: 'black', width: '11%', }} />&emsp;Academics</span>
              </li>
            </NavLink>
              </>:<></>
            }
            {
              access_control.includes("Accounts2")==true?<>
              <NavLink to='/Accounts2' className="text-decoration-none text-dark" style={styles.bounce}>
              <li className={"list-group-item mb-4 px-4 text-decoration-none side_list" + (location.pathname == '/Accounts2' ? ' active' : ' ')} aria-current="true" style={{ fontSize: '16px' }}>
                <span style={{}}><img src={dashboard3} style={{ color: 'black', width: '11%', }} />&emsp;Accounts</span>
              </li>
            </NavLink>
              </>:<></>
            }
            
            {
              access_control.includes("Support")==true?
              <>
              <NavLink to='/Support' className="text-decoration-none text-dark" style={styles.bounce}>
              <li className={"list-group-item mb-4 px-4 text-decoration-none side_list" + (location.pathname == '/Support' ? ' active' : ' ')} aria-current="true" style={{ fontSize: '16px' }}>
                <span style={{}}><img src={dashboard4} style={{ color: 'black', width: '11%', }} />&emsp;Support</span>
              </li>
            </NavLink>
              </>:<></>
            }
           
            {
              access_control.includes("Students")==true?
              <>
              <NavLink to='/Students' className="text-decoration-none text-dark" style={styles.bounce}>
              <li className={"list-group-item mb-4 px-4 text-decoration-none side_list" + (location.pathname == '/Students' ? ' active' : ' ')} aria-current="true" style={{ fontSize: '16px' }}>
                <span style={{}}><img src={dashboard5} style={{ color: 'black', width: '11%', }} />&emsp;Students</span>
              </li>
            </NavLink>
              </>:<></>
            }

            {
              access_control.includes("Attendance")==true?
              <>
              <NavLink to='/Attendance' className="text-decoration-none text-dark" style={styles.bounce}>
              <li className={"list-group-item mb-4 px-4 text-decoration-none side_list" + (location.pathname == '/Attendance' ? ' active' : ' ')} aria-current="true" style={{ fontSize: '16px' }}>
                <span style={{}}><img src={dashboard6} style={{ color: 'black', width: '11%', }} />&emsp;Attendance</span>
              </li>
            </NavLink>
              </>:<></>
            }

            {
              access_control.includes("Analytics")==true?
              <>
              <NavLink to='/Analytics' className="text-decoration-none text-dark" style={styles.bounce}>
              <li className={"list-group-item mb-4 px-4 text-decoration-none side_list" + (location.pathname == '/Analytics' ? ' active' : ' ')} aria-current="true" style={{ fontSize: '16px' }}>
                <span style={{}}><img src={dashboard7} style={{ color: 'black', width: '11%', }} />&emsp;Analytics</span>
              </li>
            </NavLink>
              </>:<></>
            }

            {
              access_control.includes('Team')==true?
              <>
              <NavLink to='/Team' className="text-decoration-none text-dark" style={styles.bounce}>
              <li className={"list-group-item mb-4 px-4 text-decoration-none side_list" + (location.pathname == '/Team' ? ' active' : ' ')} aria-current="true" style={{ fontSize: '16px' }}>
                <span style={{}}><img src={dashboard8} style={{ color: 'black', width: '11%', }} />&emsp;Team</span>
              </li>
            </NavLink>
              </>:<></>
            }

            {
              access_control.includes('Calendar')==true?
              <>
              <NavLink to='/Calendar' className="text-decoration-none text-dark" style={styles.bounce}>
              <li className={"list-group-item mb-4 px-4 text-decoration-none side_list" + (location.pathname == '/Calendar' ? ' active' : ' ')} aria-current="true" style={{ fontSize: '16px' }}>
                <span style={{}}><img src={dashboard9} style={{ color: 'black', width: '11%', }} />&emsp;Calendar</span>
              </li>
            </NavLink>
              </>:<></>
            }

            {
              access_control.includes('Lead')==true?<>
              <NavLink to='/Lead' className="text-decoration-none text-dark " style={styles.bounce}>
              <li className={"list-group-item mb-4 px-4 text-decoration-none side_list" + (location.pathname == '/Lead' ? ' active' : ' ')} aria-current="true" style={{ fontSize: '16px' }}>
                <span style={{}}><img src={dashboard10} style={{ color: 'black', width: '11%', }} />&emsp;Leads</span>
              </li>
            </NavLink>
              </>:<></>
            }
            

            

            
            
            
            
            <NavLink to='/Setting' className="text-decoration-none text-dark " style={styles.bounce}>
              <li className={"list-group-item mb-4 px-4 text-decoration-none side_list" + (location.pathname == '/Setting' ? ' active' : ' ')} aria-current="true" style={{ fontSize: '16px' }}>
                <span style={{}}><AiOutlineSetting style={{ color: 'black', width: '11%', }} />&emsp;Settings</span>
              </li>
            </NavLink>
            {
              access_control.includes("BannerImages")==true?
              <>
              <NavLink to='/BannerImages' className="text-decoration-none text-dark " style={styles.bounce}>
            <li className={"list-group-item mb-4 px-4 text-decoration-none side_list" + (location.pathname == '/BannerImages' ? ' active' : ' ')} aria-current="true" style={{ fontSize: '16px' }}>
              <span style={{}}><FaImages style={{ color: 'black', width: '11%', }} />&emsp;Banners</span>
            </li>
          </NavLink>
              </>:<></>
            }
            
            <div onClick={() => { logout(); Navigate("/code") }}>
              <li className={"list-group-item mb-4 px-4 text-decoration-none side_list" + (location.pathname == '/code' ? ' active' : ' ')} aria-current="true" style={{ fontSize: '16px' }}>
                <span style={{}}><BiLogOutCircle style={{ color: 'black', width: '12%', fontSize: "20px" }} />&emsp;Log out</span>
              </li>
            </div>
            <motion.div
              style={styles}
              animate={{ rotate: 360 }}
              transition={{ duration: 2 }}
            />
          </div>
        </ul>
      </div>
    </div>
  )
}

export default memo(SideBar);