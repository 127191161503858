import React, { useState, useEffect, useContext } from "react"; //api done
import { IoArrowBackOutline } from "react-icons/io5";
import Back from "../images/wave.png";
import { FiUpload } from "react-icons/fi";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import { ToastContainer, toast } from "react-toastify";
import lotti6 from '../images/142632-emoji.json';
import Lottie from "lottie-react";
import { API_URL } from "../env";


export default function AddExcel() {
    const { profile, profileGet } = useContext(AuthContext);
    const [count, setcount] = useState(1);
    const [Images, setImages] = useState();
    const [Banner1, setBanner1] = useState("");
    const [Course, setCourse] = useState([]);
    const [Batch, setBatch] = useState([]);
    const [BatchId, setBatchId] = useState("");
    const [CourseId, setCourseId] = useState();
    const { apiToken } = useContext(AuthContext);
    const [message, setmessage] = useState();
    const Navigate = useNavigate();

    //require destructiong above state objects
    const { banner } = profile;

    const AddStudent = async () => {
        var myHeaders = new Headers();
        myHeaders.append("authorization", `Bearer ${apiToken}`);

        var formdata = new FormData();
        formdata.append("course_id", CourseId);
        formdata.append("batch_id", BatchId);
        formdata.append("excel", Banner1);


        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(`${API_URL}/admin/student/import/student/by/excel`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status == 1) {
                    toast(result.msg)
                    setcount(count+1)
                }  
                else if (result.status === 'VAL_ERR') { 
                    toast(result.Backend_Error) 
                  } 
                else if (result.status == 'FILE_ERR') { 
                    toast(result.Backend_Error) 
                  } 
                else if (result.status == "CUSTOM_ERR") {
                    toast(result.Backend_Error);
                  } else if (result.status == "TOKEN_ERR") {
                    localStorage.removeItem('api_url');
                    Navigate('/code')
                    toast("Logged in other device! Please login again")
                  }
                  else {
                    console.log(result)
                  }
            })
            .catch((error) => console.log("error", error));
    };

    const ApiForCourse = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${apiToken}`);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(`${API_URL}/admin/course/read/list/of/courses`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status == "001") {
                    setCourse(result.courses);
                }  else if (result.status == "CUSTOM_ERR") {
                    toast(result.Backend_Error);
                  } else if (result.status == "TOKEN_ERR") {
                    localStorage.removeItem('api_url');
                    Navigate('/code')
                    toast("Logged in other device! Please login again")
                  }
                  else {
                    console.log(result)
                  }
            })
            .catch((error) => console.log("error", error));
    };

    const ApiForBatchDetail = async (ID) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${apiToken}`);

        // var formdata = new FormData();
        // formdata.append("course_id", ID);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            // body: formdata,
            redirect: "follow",
        };

        fetch(`${API_URL}/admin/formfill/batch/by/courseid/${ID}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setBatch(result.batches);
                 if (result.status == "CUSTOM_ERR") {
                    toast(result.Backend_Error);
                  } else if (result.status == "TOKEN_ERR") {
                    localStorage.removeItem('api_url');
                    Navigate('/code')
                    toast("Logged in other device! Please login again")
                  }
                  else {
                    console.log(result)
                  }
                //setTotalAmount(result.Amount);
            })
            .catch((error) => console.log("error", error));
    };

    function handleSelectChange1(event) {
        const { id, value } = event.target.options[event.target.selectedIndex];

        ApiForBatchDetail(value);
        setCourseId(value);
    }

    useEffect(() => {
        ApiForCourse();
        ApiForBatchDetail();
    }, []);

    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                background: "#FBFDFF",
                backgroundImage: count > 4 ? "" : `url(${Back})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom",
            }}
        >
            <ToastContainer />
            <div className="d-flex mt-1 px-3">
                <img
                    src={`${banner}`}
                    className="header"
                    style={{
                        color: "black",
                        width: "auto",
                        height: "80px",
                        objectFit: "cover",
                    }}
                />
                {/* <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" style={{marginLeft:"82rem",}}></button> */}
            </div>

            {/* <!--form  3--> */}
            <div className="" style={{ display: count === 1 ? "block" : "none" }}>
                <div
                    className=""
                    onClick={() => {
                        Navigate(-1);
                    }}
                >
                    <IoArrowBackOutline
                        style={{
                            marginLeft: "17px",
                            fontSize: "1.5rem",
                            marginTop: "1rem",
                            cursor: "pointer",
                        }}
                    />
                </div>
                <div className="row">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <div
                            className="card mt-5 ps-3"
                            style={{
                                border: "none",
                                width: "100%",
                                background: "#FFFFFF",
                                boxShadow: "0px 4px 12px rgba(158, 158, 158, 0.25)",
                                borderRadius: "10px",
                            }}
                        >
                            <form className="mt-4">
                                <div>
                                    <label
                                        style={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            fontSize: "20px",
                                            color: "#414141",
                                        }}
                                    >
                                        Select Course
                                    </label>
                                    <span style={{ color: "red" }}>*</span>
                                    <br></br>
                                    <select
                                        style={{
                                            background: "#FFFFFF",
                                            border: "1px solid #EDEDED",
                                            borderRadius: "10px",
                                            padding: "10px",
                                            width: "95%",
                                        }}
                                        onChange={handleSelectChange1}
                                    >
                                        <option defaultvalue="">--Select Course--</option>
                                        {Course?.map((res) => {
                                            return (
                                                <>
                                                    <option value={res.id} id={res.course_price}>
                                                        {res.course_name}
                                                    </option>
                                                </>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className="mt-4">
                                    <label
                                        style={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            fontSize: "20px",
                                            color: "#414141",
                                        }}
                                    >
                                        Select Batch
                                    </label>
                                    <span style={{ color: "red" }}>*</span>
                                    <br></br>
                                    <select
                                        style={{
                                            background: "#FFFFFF",
                                            border: "1px solid #EDEDED",
                                            borderRadius: "10px",
                                            padding: "10px",
                                            width: "95%",
                                        }}
                                        value={BatchId}
                                        onChange={(e) => {
                                            setBatchId(e.target.value);
                                        }}
                                    >
                                        <option defaultvalue="">--Select Batch--</option>
                                        {Batch?.map((res) => {
                                            return (
                                                <>
                                                    <option value={res.id}>{res.batch_name}</option>
                                                </>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className="row">
                                    <label
                                        className="mt-4"
                                        style={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            fontSize: "20px",
                                            color: "#414141",
                                        }}
                                    >
                                        Upload Excel
                                    </label>
                                    <div className="row">
                                        <div className="col-8">
                                            <div
                                                className="mt-3 px-3"
                                                style={{ width: "100%", height: "auto" }}
                                            >
                                                <label
                                                    className="filelabel1 w-100 "
                                                    style={{
                                                        background: "#FFFFFF",
                                                        border: "1px solid #B0B0B0",
                                                        borderRadius: "5px",
                                                        color: "#000000",
                                                        height: "30px",
                                                    }}
                                                >
                                                    {Banner1 <= 1 ? (
                                                        <span className="title1">
                                                            <h6
                                                                className="d-flex mt-1"
                                                                src={Images}
                                                                style={{
                                                                    width: "100%",
                                                                    textAlign: "center",
                                                                    color: "#C5C5C5",
                                                                    fontSize: "13px",
                                                                }}
                                                            >
                                                                {" "}
                                                                <FiUpload
                                                                    className="mx-4"
                                                                    style={{
                                                                        color: "#B0B0B0",
                                                                        fontSize: "20px",
                                                                    }}
                                                                />{" "}
                                                                Upload excel{" "}
                                                            </h6>
                                                        </span>
                                                    ) : (
                                                        <span style={{ marginLeft: 20, color: "blue" }}>
                                                            {Banner1.name.split("", 10)}...
                                                        </span>
                                                    )}
                                                    <input
                                                        className="FileUpload1"
                                                        id="FileInput1"
                                                        name="booking_attachment1"
                                                        type="file"
                                                        accept=".xlsx,.xls"
                                                        onChange={(e) => {
                                                            setImages(
                                                                URL.createObjectURL(e.target.files[0])
                                                            );
                                                            setBanner1(e.target.files[0]);
                                                        }}
                                                        style={{ opacity: "0" }}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 mb-4 text-center">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        onClick={() => AddStudent()}
                                        style={{
                                            width: "90%",
                                            background: "#3572FA",
                                            boxShadow: "0px 2px 8px #B0BEEF",
                                            borderRadius: "10px",
                                            padding: "15px",
                                        }}
                                    >
                                        Add Students
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-4"></div>
                </div>
            </div>

            <div style={{ display: count === 2 ? "block" : "none" }}>
                <div className="">
                    <p style={{ textAlign: "center", color: "#3C3C3C", fontSize: "35px", fontWeight: "600" }}>Student Added Successfully!!</p>
                </div>


                <Lottie animationData={lotti6} className="d-grid gap-2 col-6 mx-auto" style={{ width: "25%" }} />


                <div className="">
                    <NavLink to="/Students" style={{ textDecoration: "none", }}><button className="d-grid gap-2 col-6" data-bs-dismiss="modal" aria-label="Close" style={{
                        marginTop: "70px", width: "50%", padding: "15px", fontSize: "18px", marginLeft: "25%",
                        backgroundColor: "#8147E7", border: "none", color: "#fff", boxShadow: " 0px 4px 12px rgba(126, 57, 247, 0.36)", fontWeight: "500", borderRadius: "5px"
                    }} onClick={() => { Navigate(-1) }}>Back to Student</button></NavLink>
                </div>
                <div className="mt-3"></div>

            </div>









        </div>
    );
}
