import React from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";

const CreateNewMarksheetSucces = () => {
  const Navigate = useNavigate();

  const cardDetails = [
    {
      heading: "Semester 2 BCA Marksheets",
      noOfExams: 12,
      noOfBatches: 12,
      noOfStud: "1200",
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row p-0">
          <div className="col-12 p-0">
            <div
              className=" px-2 pt-0 mt-0 d-flex align-items-center"
              style={{
                height: "9vh",
                width: "100%",
                background: "#fff",
                boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
              }}
            >
              <div className="d-flex align-items-center ms-2">
                <IoIosArrowRoundBack
                  onClick={() => Navigate("/CreateNewMarksheetConfirm")}
                  style={{
                    fontSize: "2rem",
                    color: "#3C3C3C",
                    cursor: "pointer",
                  }}
                />
                <span
                  className="ms-3"
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 600,
                    color: "#3D3D3D",
                    fontSize: "1.25rem",
                  }}
                >
                  Generate New Marksheet
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div
              style={{
                height: "91vh",
                overflowY: "auto",
                width: "100%",
                background: "#F9F9F9",
              }}
            >
              <div className="container-fluid h-100">
                <div className="row justify-content-center align-items-center h-100">
                  <div
                    className="col-5 p-3 d-flex flex-column justify-content-around"
                    style={{
                      background: "#fff",
                      boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
                      height: "83vh",
                      overflowY: "auto",
                    }}
                  >
                    <h6
                      className="text-center"
                      style={{
                        color: "#3d3d3d",
                        fontFamily: "Inter",
                        fontSize: "1.5rem",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      Marksheets Generated Successfully
                    </h6>

                    <div className="row mt-3" style={{ rowGap: "10px" }}>
                      {cardDetails.map((value, index) => (
                        <div className="col-8 mx-auto" key={index}>
                          <div className="card p-2 px-3 h-100">
                            <h6
                              style={{
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontSize: "1rem",
                                fontWeight: 600,
                                color: "#3D3D3D",
                              }}
                            >
                              {value.heading}
                            </h6>
                            <div className="">
                              <p
                                style={{
                                  fontFamily: "Inter",
                                  fontStyle: "normal",
                                  fontSize: "0.875rem",
                                  fontWeight: 400,
                                  color: "#767676",
                                }}
                              >
                                Number of Exams : {value.noOfExams}
                              </p>
                              <p
                                style={{
                                  fontFamily: "Inter",
                                  fontStyle: "normal",
                                  fontSize: "0.875rem",
                                  fontWeight: 400,
                                  color: "#767676",
                                }}
                              >
                                Number of Batches : {value.noOfBatches}
                              </p>
                              <p
                                style={{
                                  fontFamily: "Inter",
                                  fontStyle: "normal",
                                  fontSize: "0.875rem",
                                  fontWeight: 400,
                                  color: "#767676",
                                }}
                              >
                                Number of Students : {value.noOfStud}
                              </p>
                            </div>
                            <div className="mx-auto py-2 mt-3 w-100">
                              <button
                              onClick={()=>Navigate('/ViewMarksheet')}
                                className="w-100 py-2 border-0"
                                style={{
                                  background: "#F2EDFD",
                                  borderRadius: "3px",
                                  color: "#8147E7",
                                  fontSize: "1rem",
                                  fontFamily: "Inter",
                                  fontWeight: 400,
                                }}
                              >
                                View Details
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="w-100 mx-auto text-center" >
                        <button onClick={()=>{Navigate('/Marksheet')}} className="border-0 w-75 py-3 mx-auto text-center" style={{fontFamily:"Inter",fontStyle:"normal",fontSize:"1rem",fontWeight:500,color:"#fff",background:"#8147E7",boxShadow:"0px 4px 12px 0px rgba(126, 57, 246, 0.36)"}} >Back to Dashboard</button>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewMarksheetSucces;
