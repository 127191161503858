import React from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import Lottie from "lottie-react";
import groovyWalkAnimation from "../images/waitingProgess.json";

const CreateNewMarksheetConfirm = () => {

    const Navigate = useNavigate();

    setTimeout(()=>{
        Navigate('/CreateNewMarksheetSucces');
    },3000);

  return (
    <>
      <div className="container-fluid">
        <div className="row p-0">
          <div className="col-12 p-0">
            <div
              className=" px-2 pt-0 mt-0 d-flex align-items-center"
              style={{
                height: "9vh",
                width: "100%",
                background: "#fff",
                boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
              }}
            >
              <div className="d-flex align-items-center ms-2">
                <IoIosArrowRoundBack
                  onClick={() => Navigate("/CreateNewMarksheetWait")}
                  style={{
                    fontSize: "2rem",
                    color: "#3C3C3C",
                    cursor: "pointer",
                  }}
                />
                <span
                  className="ms-3"
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 600,
                    color: "#3D3D3D",
                    fontSize: "1.25rem",
                  }}
                >
                  Generate New Marksheet
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div
              style={{
                height: "91vh",
                overflowY: "auto",
                width: "100%",
                background: "#F9F9F9",
              }}
            >
              <div className="container-fluid h-100">
                <div className="row justify-content-center align-items-center h-100">
                  <div
                    className="col-5 p-3"
                    style={{
                      background: "#fff",
                      boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
                      height: "83vh",
                      overflowY: "auto",
                    }}
                  >
                    <h6 className="text-center" style={{color:"#3d3d3d",fontFamily:"Inter",fontSize:"1.5rem",fontStyle:"normal",fontWeight:600}} >Genrating Marksheets</h6>
                    <p className="text-center" style={{color:"#3d3d3d",fontFamily:"Inter",fontSize:"1rem",fontStyle:"normal",fontWeight:400}} >Do not Close or refresh this window</p>
                    <p className="text-center" style={{color:"#9d9d9d",fontFamily:"Inter",fontSize:"0.875rem",fontStyle:"normal",fontWeight:400}} >You will be auto redirected</p>
                  <div>
                  <Lottie animationData={groovyWalkAnimation} loop={true}/>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewMarksheetConfirm;
