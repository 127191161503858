import { useState, useEffect } from 'react'; //api done
import Header from './header';
import SideBar from './sidebar';
import { ImBooks } from 'react-icons/im';
import { FaBook } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { NavLink, useNavigate } from 'react-router-dom';
import BATCH from '../images/batch.png';
import { AuthContext } from './AuthContext';
import { useContext } from 'react';
import { API_URL, img_url } from '../env';
import { useRequireAuth } from './RequireAuth';
import { ToastContainer, toast } from 'react-toastify';


export default function Academics() {
  const Navigate = useNavigate();
  const [total_course, settotal_course] = useState("");
  const [total_batch, settotal_batch] = useState("");
  const [total_subject, settotal_subject] = useState("");
  const { apiToken } = useContext(AuthContext);
  console.log(total_batch, total_course, 'sub_length');

  const Data = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    await fetch(`${API_URL}/admin/course/get/academic/overview`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status == "1") {
          settotal_course(result.resp.find(item => item.name === "Courses")?.cou_length);
          settotal_batch(result.resp.find(item => item.name === "Batches")?.bat_length);
          settotal_subject(result.resp.find(item => item.name === "Subjects")?.sub_length);
        }
        else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }
      })
      .catch(error => console.log('error', error));
  };

  useEffect(() => {
    Data();
  }, [])


  return (
    <>

      <ToastContainer />
      <div style={{ width: '100%', height: '100vh', }}>
        <div className='head' style={{ width: '80%', height: '14vh', marginLeft: '16%' }}>
          <Header />
        </div>
        <div style={{ width: '100%', height: '86vh', display: "flex", }}>
          <div style={{ width: '20%', height: '100%', position: 'absolute', top: 0, marginLeft: '1%' }} >
            <SideBar />
          </div>
          <div className='maincontain' style={{ marginLeft: '15%', width: '100%', height: '100%', overflowY: 'scroll', }} >

            <div className='row mt-4  mx-4 '>
              <div className='col-6 px-3'>
                <NavLink to="/Course" className="text-decoration-none text-secondary">
                  <div className='card'>
                    <div className='row pt-2 align-items-center'>
                      <div className='col-3 mx-4' style={{ backgroundColor: '#FFDCDC', width: 45, height: 45, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }}><ImBooks style={{ fontSize: 25, color: '#F03838' }} /></div>
                      <p className='col-6 text-start' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "24px", color: "#000000", }}>Courses / Classes</p>
                      <div className='col-3 text-end'><IoIosArrowForward /></div>
                    </div>
                    <hr className='mx-3'></hr>
                    <p className='text-start mx-3 pb-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#54536E", }}>Total Courses Active :&nbsp;<span>{total_course}</span></p>
                    {/* <p className='text-start mx-3 pb-3'>New Additions :<span>&nbsp;5</span></p>
               <p className='text-start mx-3 pb-3'>Total Streams  :<span>&nbsp;16</span></p> */}
                  </div>
                </NavLink>
              </div>
              {/* <div className='col-6 px-3'>
                <NavLink to="/Marksheet" className="text-decoration-none text-secondary">
                  <div className='card'>
                    <div className='row pt-2 align-items-center'>
                      <div className='col-3 mx-4' style={{ backgroundColor: '#DCFFE6', width: 45, height: 45, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }}><HiOutlineDocumentReport style={{ fontSize: 25, color: '#2E8760' }} /></div>
                      <p className='col-6 text-start' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "24px", color: "#000000", }}>Marksheet</p>
                      <div className='col-3 text-end'><IoIosArrowForward /></div>
                    </div>
                    <hr className='mx-3'></hr>
                    <p className='text-start mx-3 pb-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#54536E", }}>Total Marksheets :&nbsp;<span>0</span></p>
                    {/* <p className='text-start mx-3 pb-3'>New Additions :<span>&nbsp;5</span></p>
               <p className='text-start mx-3 pb-3'>Total Streams  :<span>&nbsp;16</span></p> 
                  </div>
                </NavLink>
              </div> */}
            </div>
            <div className='row mx-4 mt-5'>
              <div className='col-6 pe-4'>
                <NavLink to="/Subject" className="text-decoration-none text-secondary">
                  <div className='card'>
                    <div className='row pt-2 align-items-center'>
                      <div className='col-3 mx-4' style={{ backgroundColor: '#FFDCF9', width: 45, height: 45, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }}><FaBook style={{ fontSize: 20, color: '#F038B9' }} /></div>
                      <p className='col-6 text-start' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "24px", color: "#000000", }}>Subjects</p>
                      <div className='col-3 text-end'><IoIosArrowForward /></div>
                    </div>
                    <hr className='mx-3'></hr>
                    <p className='text-start mx-3 pb-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#54536E", }}>Total Subject Active :&nbsp;<span>{total_subject}</span></p>
                    {/* <p className='text-start mx-3 pb-3'>New Additions :<span>&nbsp;5</span></p>
               <p className='text-start mx-3 pb-3'>Total Streams  :<span>&nbsp;16</span></p> */}
                  </div>
                </NavLink>
              </div>
              <div className='col-6'></div>
            </div>

            {/*            
           <div className='row mx-4 mt-4'>
             <div className='col-6 px-2'>
            
              <div className='card'>
               <div className='row pt-2 align-items-center'>
               <div className='col-3 mx-4' style={{backgroundColor:'#DCFFF4',width:50,height:50,display:'flex',alignItems:'center',justifyContent:'center',borderRadius:50}}><Calendar style={{fontSize:25,}}/></div>
               <p className='col-6 text-start' style={{fontStyle:"normal",fontWeight:"400",fontSize:"24px",color:"#000000",}}>Exams</p>
               <div className='col-3 text-end'><IoIosArrowForward /></div>
               </div>
               <hr className='mx-3'></hr>
               <p className='text-start mx-3 pb-3' style={{fontStyle:"normal",fontWeight:"400",fontSize:"14px",color:"#54536E",}}>Total Exams Active :&nbsp;<span>25</span></p>
              
              </div>
             
             </div>
             <div className='col-6'></div>
             </div> */}
            <div className='row mx-4 mt-5'>
              <div className='col-6 pe-4'>
                <NavLink to="/Batch" className="text-decoration-none text-secondary">
                  <div className='card'>
                    <div className='row pt-2 align-items-center'>
                      <div className='col-3 me-1' style={{ width: "15%", display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }}><img src={BATCH} style={{ width: "60%" }} /></div>
                      <p className='col-6 text-start' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "24px", color: "#000000", }}>Batches / Sections </p>
                      <div className='col-3 text-end'><IoIosArrowForward /></div>
                    </div>
                    <hr className='mx-3'></hr>
                    <p className='text-start mx-3 pb-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#54536E", }}>Total Batches : {total_batch}</p>
                    {/* <p className='text-start mx-3 pb-3'>New Additions :<span>&nbsp;5</span></p>
               <p className='text-start mx-3 pb-3'>Total Streams :<span>&nbsp;16</span></p>
               <p className='text-start mx-3 pb-3'>Next Quiz :<span>&nbsp;25 Jul 2022</span></p> */}
                  </div>
                </NavLink>

              </div>
              <div className='col-6'></div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}


