import React from 'react'
import SideBar from './sidebar'
import Header from './header'
import ChangePassword from './ChangePassword'
import ViewProfile from './ViewProfile'
import Task from './Task'
import { useState } from 'react'

function Setting2() {
  const [show, setshow] = useState(0)
  return (
    <>
     <div style={{ width: '100%', height: '100vh', }}>
        <div className='head' style={{ width: '80%', height: '14vh', marginLeft: '16%' }}>
          <Header />
        </div>
        <div style={{ width: '100%', height: '86vh', display: "flex", }}>
          <div style={{ height: '100%', position: 'absolute', top: 0, marginLeft: '1%' }} >
            <SideBar />
          </div>
          <div className='maincontain' style={{ marginLeft: '16%', width: '100%', height: '100%', overflowY: 'scroll', }} >

          <div className='row' >
            <div className='col-4' >
              <button className='btn btn-primary' onClick={()=>{setshow(0)}} >View Profile</button>
            </div>
            <div className='col-4' >
              <button className='btn btn-primary' onClick={()=>{setshow(1)}} > Change Password</button>
              </div>
            <div className='col-4' >
            <button className='btn btn-primary' onClick={()=>{setshow(2)}} > Task</button>
            </div>
          </div>
          <hr/>
          {/* data all */}
          <div className='container mt-4 ' >

          {
            show == 0 ? <ViewProfile/> : null
          }

          {
            show == 1 ? <ChangePassword/> : null
          }

          {
            show == 2 ?  <Task/> : null
          }
          
          
         
          </div>

        </div>
          </div>
          </div>

          {/* mai data  */}
      {/* </div> */}
    </>
  )
}

export default Setting2