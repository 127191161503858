import React , {useContext, useState}from 'react'
import SideBar from '../sidebar'
import Header from '../header'
import { MdAccountBalance } from "react-icons/md";
import Employee from './Employee';
import Student from './Student';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { API_URL } from "../../env";
import { AuthContext } from "../AuthContext";
import { ToastContainer, toast } from "react-toastify";


function Accounts() {
  const { apiToken } = useContext(AuthContext);
    const [data, setdata] = useState(0)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedFile, setSelectedFile] = useState(null);



      // for uploading image
  const handleFileUpload = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);
    // myHeaders.append("Content-Type", "multipart/form-data");

    const formData = new FormData();
    formData.append('logo', selectedFile);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow'
    };

    fetch(`${API_URL}/admin/acount/upload/logo/for/receipt`, requestOptions)
      .then(res=>res.json()
      .then((result) => {
        if(result.status==1){
          handleClose()
          toast.success("image uploaded successfully");
          setSelectedFile(null)
        }
        else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem("brainbucks_token");
          localStorage.removeItem("username");
        } 
        else if (result.status == 'FILE_ERR') { 
          toast.error(result.Backend_Error) 
        } 
      })
      .catch(error => {
        console.log('Error uploading file:', error);
      }));


  };
  return (
       <div>
         <ToastContainer/>
      <div className='head' style={{ width: '80%', height: '14vh', marginLeft: '16%' }}>
        <Header />
      </div>
      <div className='' style={{ width: '100%', height: '86vh', display: "flex" }}>
        <div style={{ width: '20%', height: '100%', position: 'absolute', top: 0, marginLeft: '1%' }} >
          <SideBar />
        </div>
        <div className='maincontain' style={{ marginLeft: '15%', width: '100%', height: '100%', overflowY: 'scroll', }} >


       <div className='container '>
       <div class="dropdown ms-5 d-flex gap-3" >

  <button class="btn  ms-5 p-2" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{display:"flex",justifyContent:"center",alignItems:"center" , backgroundColor:"rgb(129, 71, 231)" , color:"white"}}>
    <p > <MdAccountBalance size={20} /></p>
    <p className='ms-3' >Accounts</p>
  </button>

  <button className="btn  p-2" style={{backgroundColor:"rgb(129, 71, 231)" , color:"white"}} type="button"  onClick={handleShow} >
    <p >Add Logo</p>
  </button>


  <ul class="dropdown-menu">
    <li onClick={()=>{setdata(0)}} ><a class="dropdown-item">Student</a></li>
    <li onClick={()=>{setdata(1)}} ><a class="dropdown-item">Employee</a></li>
  </ul>
</div>

       </div>


{
    data == 0?
    <Student/>
    :
    <Employee/>
}
        </div>
    </div>

        {/* add banner modal started  */}
        <Modal show={show} onHide={handleClose} centered >

        <Modal.Header closeButton>

        <input type="file" accept="image/*" onChange={(event) => {
            setSelectedFile(event.target.files[0]);
          }} />

  
        </Modal.Header>
  
  
        <Modal.Footer centered>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="btn " style={{backgroundColor:"rgb(129, 71, 231)" , color:"white"}} onClick={handleFileUpload}  >
            Upload Image
          </Button>
        </Modal.Footer>
      </Modal>
      {/* add banner modal end  */}
    </div>

  )
}

export default Accounts 
