import React from 'react';
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { AuthContext } from './AuthContext';
import { useContext } from 'react';

export default function General(props) {

  const {profile, profileUpdater, profileGet} = useContext(AuthContext)


  const {email_id,phone_no,addresss} = profile

  // console.log(props.item);
  return (
    <>
      {props.item?.map((res, key) => {

        return (
          <div className='row'>
            <div className='col-4'>
              <div style={{ padding: '30px', lineHeight: "30px", fontSize: "15px" }}>
                <p style={{ fontWeight: "600", color: "#3C3C3C", fontSize: "17px", }}>Course : {res.course_name}</p>
                <p style={{ fontWeight: "600", color: "#3C3C3C", fontSize: "17px", textTransform: "capitalize" }}>Full name : {res.name}</p>
                <p style={{ fontWeight: "500", color: "#3C3C3C", fontSize: "17px", textTransform: "capitalize" }}>Mother’s name : {res.mother_name}</p>
                <p style={{ fontWeight: "500", color: "#3C3C3C", fontSize: "17px", textTransform: "capitalize" }}>Father’s name : {res.father_name}</p>
                <p style={{ fontWeight: "500", color: "#3C3C3C", fontSize: "17px", }}>Course Start Date : 17 Jan 2023</p>
                <p style={{ fontWeight: "500", color: "#3C3C3C", fontSize: "17px", }}>Course End Date : 17 Jan 2025</p>
              </div>
            </div>

            <div className='col-8 stp' style={{ border: "1px solid #DADADA", width: "35%", height: "170px", marginTop: "40px", marginLeft: "80px", borderRadius: "5px" }}>
              <div className='row' style={{ padding: '10px', lineHeight: "20px", fontSize: "10px", fontWeight: "600" }} >
                <div className='col-6'>
                  <p style={{ fontWeight: "600", color: "#3C3C3C", fontSize: "17px", }}>Center Details</p>
                  {/* <p className='mt-2' style={{ fontWeight: "400", color: "#3C3C3C", fontSize: "12px", }}>Pincode:302021</p>
                  <p style={{ fontWeight: "400", color: "#3C3C3C", fontSize: "12px", }}>Distric:Jaipur</p>
                  <p style={{ fontWeight: "400", color: "#3C3C3C", fontSize: "12px", }}>State:Rajasthan</p> */}
                  <p className='mt-2' style={{ fontWeight: "400", color: "#3C3C3C", fontSize: "12px", }}>Address : </p>
                  <p style={{ fontWeight: "400", color: "#3C3C3C", fontSize: "12px", }}>{addresss}</p>
                </div>
                <div className='col-6' style={{ paddingLeft: "20px" }}>
                  <p style={{ fontWeight: "600", color: "#3C3C3C", fontSize: "17px", }}>Center Contact</p>
                  <p className='mt-2' style={{ fontWeight: "400", color: "#3C3C3C", fontSize: "12px", }}><BsTelephone />&nbsp;{phone_no}</p>
                  <p className='mb-3' style={{ fontWeight: "400", color: "#3C3C3C", fontSize: "12px", }}><BsEnvelope />&nbsp;{email_id}</p>

                </div>

              </div>
            </div>
          </div>
        );
      })}



      <div className="row" style={{ width: "90%", marginLeft: "30px" }}>
        <div className='col-3'>
          <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "20px", color: "#3C3C3C", }}>Attendance</p>
          {/* <div class="card" >
            <div className="card-body">
              <h6 className="" style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#3C3C3C", }}>Total Classes : 0</h6>
              <p className="card-text" style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#60DF6F", }}>Attended : 0</p>
              <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#F93333", }}>Missed : 0</p>

              <button style={{ fontSize: "12px", backgroundColor: "#2C62EE", borderRadius: "5px", width: "100%", border: "none", color: "white", height: "35px", marginTop: "10px" }}>View Details</button>

            </div>
  </div>*/}


          <div class="card" style={{ width: "90%", background: "#FFFFFF", border: "1px solid #E6E6E6", borderRadius: "5px", }} >
            <div className="card-body">
              <p className='text-center p-4' style={{ color: "red" }}>This feature will be available soon </p>
            </div>
          </div>
        </div>

        <div className='col-3'>
          <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "20px", color: "#3C3C3C", }}>Quiz</p>
          { /*<div class="card" >
            <div className="card-body">

              <h6 className="" style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#3C3C3C", }}>Total Quizzes : 0</h6>
              <div style={{ display: "flex", }}>    <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#6B6B6B", }}>Attempted : 0</p>
                <p style={{ paddingLeft: "15px", fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#9E9E9E", }}>Unattempt : 0</p></div>

              <div style={{ display: "flex", }}>    <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#60DF6F", }}>Passed : 0</p>
                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "red", paddingLeft: "50px" }}>Failed : 0</p></div>
              <button style={{ fontSize: "12px", backgroundColor: "#2C62EE", borderRadius: "5px", width: "100%", border: "none", color: "white", height: "35px", marginTop: "10px" }}>View Details</button>
            </div>
</div>*/}

          <div class="card" style={{ width: "90%", background: "#FFFFFF", border: "1px solid #E6E6E6", borderRadius: "5px", }} >
            <div className="card-body">
              <p className='text-center p-4' style={{ color: "red" }}>This feature will be available soon </p>
            </div>
          </div>
        </div>

        <div className='col-3'>
          <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "20px", color: "#3C3C3C", }}>Exams</p>
          {/* <div class="card" >
            <div className="card-body">

              <h6 className="" style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#3C3C3C", }}>Total Exams : 0</h6>
              <div style={{ display: "flex", }}>    <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#6B6B6B", }}>Attempted : 0</p>
                <p style={{ paddingLeft: "15px", fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#9E9E9E", }}>Unattempt : 0</p></div>

              <div style={{ display: "flex", }}>    <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#60DF6F", }}>Passed : 0</p>
                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "red", paddingLeft: "50px" }}>Failed : 0</p></div>
              <button style={{ fontSize: "12px", backgroundColor: "#2C62EE", borderRadius: "5px", width: "100%", border: "none", color: "white", height: "35px", marginTop: "10px" }}>View Details</button>
            </div>
</div>*/}

          <div class="card" style={{ width: "90%", background: "#FFFFFF", border: "1px solid #E6E6E6", borderRadius: "5px", }} >
            <div className="card-body">
              <p className='text-center p-4' style={{ color: "red" }}>This feature will be available soon </p>
            </div>
          </div>
        </div>

        <div className='col-3'>
          <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "20px", color: "#3C3C3C", }}>Assignment</p>
          { /*<div class="card" >
            <div className="card-body">
              <h6 className="" style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#3C3C3C", }}>Total Exams : 0</h6>
              <div style={{ display: "flex", }}>    <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#6B6B6B", }}>Attempted : 0</p>
                <p style={{ paddingLeft: "15px", fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#9E9E9E", }}>Unattempt : 0</p></div>

              <div style={{ display: "flex", }}>    <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#60DF6F", }}>Passed : 0</p>
                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "red", paddingLeft: "50px" }}>Failed : 0</p></div>

              <button style={{ fontSize: "12px", backgroundColor: "#2C62EE", borderRadius: "5px", width: "100%", border: "none", color: "white", height: "35px", marginTop: "10px" }}>View Details</button>
            </div>
</div>*/}

          <div class="card" style={{ width: "90%", background: "#FFFFFF", border: "1px solid #E6E6E6", borderRadius: "5px", }} >
            <div className="card-body">
              <p className='text-center p-4' style={{ color: "red" }}>This feature will be available soon </p>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}



