import React, { useState,useContext,useEffect} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { NavLink } from 'react-router-dom'
import { BsFillCalendarFill } from "react-icons/bs";
import TIPG from '../images/tipsglogo.png';
import { FiDownload } from "react-icons/fi"
import { AuthContext } from './AuthContext';
import { API_URL,img_url } from '../env';



export default function Upcoming() {
  const [startDate, setStartDate] = useState(new Date())
  const [startDate1, setStartDate1] = useState(new Date());
  const [cre, setCre] = useState([]);
  const { apiToken } = useContext(AuthContext);
 
  const dd = [
    {
      sn: "1.",
      part: "Machine",
      qty: "10",
      amount: "₹ 10,000"
    },
    {
      sn: "1.",
      part: "Machine",
      qty: "10",
      amount: "₹ 10,000"
    }
  ]

  const Api = ()=>{
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/upcoming_invoiceList`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.status=="001"){
          setCre(result.upcoming);
        }
      })
      .catch(error => console.log('error', error));
  }

useEffect(() => {
  Api();
}, [])


  return (
    <>


      {/* button */}

      <div className='row'>
        <div className='col-2 ' >
          <div style={{  display: "flex" }}>
            <p className='pt-3 ' style={{ color: "#434343", fontWeight: "500", fontSize: "17px", paddingLeft: "40px" }} >From</p>
            <span style={{
              display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "10px",
              borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
            }}>
              <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
              <div>
                <DatePicker className="border-0  ps-2 pt-1 datep"
                  showIcon
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
              </div>
            </span>
            <p className='pt-3 ps-1' style={{ color: "#434343", fontWeight: "500", fontSize: "17px" }}>to</p>
          </div>
        </div>
        <div className='col-2' style={{ marginLeft: "50px" }} >
          <div>
            <span style={{
              display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "10px",
              borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
            }}>
              <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
              <div>
                <DatePicker className="border-0  ps-2 pt-1 datep"
                  showIcon
                  selected={startDate1}
                  onChange={(date) => setStartDate1(date)}
                  style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
              </div>
            </span>
          </div>
        </div>

        <div className='col-5 ms-5 text-end'>
          <NavLink to="/Makepayment">    <button className='' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightm" aria-controls="offcanvasRight" style={{
            border: "1px solid #B63A3A",
            backgroundColor: "#FFFFFF", color: "#B63A3A", width: "150px", height: "38px", borderRadius: "5px"
          }} >Make Payment</button></NavLink>
        </div>
        {/*
        <div className='col-1 text-end'>
          <NavLink to="/ReportRequest">  <button className='' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRighti" aria-controls="offcanvasRight" style={{
            border: "1px solid #3AB690",
            backgroundColor: "#FFFFFF", color: "#3AB690", width: "150px", height: "38px", borderRadius: "5px"
          }}>Request Payment</button></NavLink>
        </div>
       */}

        <div className='col-2'>

        </div>
      </div>



      {/* make payment */}

      {/* end */}





      {/* table */}
      <div>

        <div className='mt-4  '>
        <div className='row mx-4 mt-3'  >
        <div className='row mx-4 mt-3 '  >
        <div className='row' style={{ color: "#434343", fontWeight: "600", fontSize: "15px" }}>
          <div className='col-1 pb-2'>
            <p>S.No.</p>
          </div>
          <div className='col-3 pb-2'>
            <p>Name</p>
          </div>
          <div className='col-2 pb-2' >
            <p>Date & Time</p>
          </div>
          <div className='col-1 pb-2'>
            <p>Amount</p>
          </div>
          <div className='col-2 pb-2'>
            <p>Phone Number</p>
          </div>
          <div className='col-1 pb-2'>
            <p>Status</p>
          </div>
        </div>
       
        <div style={{ height: "60vh" }}>
          
          {
          cre.length === 0 ? <div className="d-flex mt-5" style={{justifyContent:"center",color:"red",alignItems:"center"}}><h6>No Data Available</h6></div>:
          <div style={{overflowY:"scroll",height:"58vh"}}>
          {
          cre?.map((res, index) => {
            return (
              <>
                <div className='row align-items-center  mt-3' style={{ width: "100%", borderRadius: "5px", height: "55px", backgroundColor: " #FFF4F4" }} >
                  <div className='col-1' key={res.id} >
                    <p style={{ color: "#434343", fontWeight: "400", fontSize: "15px" }}>{index+1}</p>
                  </div>
                  <div className='col-3'>
                    <p style={{ fontWeight: "400", fontSize: "15px", textTransform: "capitalize" }}>{res.student_name}</p>
                  </div>
                
                  <div className='col-2'>
                    <p style={{ fontSize: "15px", color: "#3C3C3C", fontWeight: "500" }} >{res.emi_date}</p>
                  </div>
                  <div className='col-1'>
                    <p style={{ fontSize: "15px", color: "#434343", fontWeight: "400" }} >{res.emi_amount}</p>
                  </div>

                 
                  <div className='col-2'>
                    <p style={{ fontSize: "15px", color: "#434343", fontWeight: "400" }} >{res.phone}</p>
                  </div>
                  
                  <div className='col-1'>
                    <p style={{ fontSize: "15px", fontWeight: "600", color:"red" }} >Due</p>
                  </div>


                

                </div>
              </>
            )
          })
        }
          </div>
        }

          
        </div>

      </div>
    </div>
        </div>
      </div>
    </>
  )
}

