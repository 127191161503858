import React, { useContext, useEffect, useState } from "react"; //api done
import Header from './header';
import SideBar from './sidebar';
import { NavLink, useNavigate } from "react-router-dom";
import { HiArrowLeft } from 'react-icons/hi';
import { MdOutlineWatchLater } from 'react-icons/md';
import { BsFillCalendarFill } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LOGO from '../images/Group 339.png';
import { AuthContext } from "./AuthContext";
import { ToastContainer, toast } from "react-toastify";
import { RiFileExcel2Line } from 'react-icons/ri'
import * as XLSX from 'xlsx';
import { Loader } from './Loader';
import { API_URL, img_url } from '../env';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Offcanvas from 'react-bootstrap/Offcanvas';

export default function Batch() {

  const [startDate1, setStartDate1] = useState(new Date())
  const [startDate, setStartDate] = useState(new Date())
  const [count1, setcount1] = useState(1)
  const [Batch, setBatch] = useState([])
  const [ViewBatchDetail, setViewBatchDetail] = useState([])
  const [Course, setCourse] = useState([]);
  const [BatchName, setBatchName] = useState("");
  const [BatchId, setBatchId] = useState("");
  const [value, setvalue] = useState('')
  const [BatchsStudent, setBatchsStudent] = useState("");
  const [Courses, setCourses] = useState();
  const [time, settime] = useState()
  const [FirstDate, setFirstDate] = useState();
  const [LastDate, setLastDate] = useState();
  const [time1, settime1] = useState()
  const [loader, setLoader] = useState(false);
  const [time2, settime2] = useState()
  const { apiToken } = useContext(AuthContext);
  const {Navigate} = useNavigate();

  const ApiForCourse = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/admin/course/read/list/of/courses`, requestOptions)
      .then(response => response.json())
      .then(result => {
           if (result.status == "001") {
          setCourse(result.courses);
                }
                else if (result.status == "CUSTOM_ERR") {
                  toast(result.Backend_Error);
                } else if (result.status == "TOKEN_ERR") {
                  localStorage.removeItem('api_url');
                  Navigate('/code')
                  toast("Logged in other device! Please login again")
                }
                else {
                  console.log(result)
                }
      })
      .catch(error => console.log('error', error));
  }
  const ApiForBatch = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/admin/batches/read/current/batch`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        if (result.status == "001") {
          setBatch(result.batchDetails);
        }
        else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }
      })
      .catch(error => console.log('error', error));
  }

  const ApiForBatchDetail = async (ID) => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    // var formdata = new FormData();
    // formdata.append("batch_id", ID);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      // body: formdata,
      redirect: 'follow'
    };
    setLoader(true)
    fetch(`${API_URL}/admin/batches/read/batch-by-id/${ID}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setLoader(false)
        setViewBatchDetail(result.batchDetails.student_details);
        console.log(result);
        setBatchName(result.batchDetails.course_name);
        setBatchId(result.batchDetails.batch_name);
        setBatchsStudent(result.batchDetails.total_students);
         if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }

      })
      .catch(error => console.log('error', error));
  }


  const handleTimeChangeEnd = (e) => {
    const inputTime = e.target.value;

    // Split the input time into hours and minutes
    const [hours, minutes] = inputTime.split(':');

    // Validate the hours and minutes
    const validHours = Math.max(0, Math.min(23, parseInt(hours, 10)));
    const validMinutes = Math.max(0, Math.min(59, parseInt(minutes, 10)));

    // Format the time with leading zeros
    const formattedTime = `${validHours.toString().padStart(2, '0')}:${validMinutes.toString().padStart(2, '0')}`;

    settime1(formattedTime);
  };

  const handleTimeChangeStart = (e) => {
    const inputTime = e.target.value;

    // Split the input time into hours and minutes
    const [hours, minutes] = inputTime.split(':');

    // Validate the hours and minutes
    const validHours = Math.max(0, Math.min(23, parseInt(hours, 10)));
    const validMinutes = Math.max(0, Math.min(59, parseInt(minutes, 10)));

    // Format the time with leading zeros
    const formattedTime = `${validHours.toString().padStart(2, '0')}:${validMinutes.toString().padStart(2, '0')}`;

    settime(formattedTime);
  };

  
  const [cardBatchId,setCardBatchId] = useState();
  const [show1, setShow1] = useState(false);

  const handleShow1 = () => setShow1(true);

  const handleClose1 = () => setShow1(false);
  const [show, setShow] = useState(false);
  
  const [startTime,setStartTime] = useState("");
  const [endTime,setEndTime] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => 
  {
    setShow(true)
    // handleClose1();
  };

  const updateBatchTime = async(id)=>{
    try {
      var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${apiToken}`);

var raw = JSON.stringify({
  "batch_id": id,
  "start_time": startTime,
  "end_time": endTime
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${API_URL}/admin/batches/update/batch/time`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status==1){
      setStartTime("");
      setEndTime("");
      toast.success(result.msg)
    }else if (result.status === 'VAL_ERR') { 
      toast(result.Backend_Error) 
    } 
    else if (result.status == "CUSTOM_ERR") {
      toast(result.Backend_Error);
    } 
    else if (result.status == "TOKEN_ERR") {
      localStorage.removeItem('api_url');
      Navigate('/code')
      toast("Logged in other device! Please login again")
    }
  })
  .catch(error => console.log('error', error));
    } catch (error) {
      console.log(error)
    }
  }

  const AddBatch = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var raw = JSON.stringify({

      "course_id": Courses,
      "batch_name": value,
      "start_date": FirstDate,
      "end_date": LastDate,
      "grace_period": time2,
      "start_time": time,
      "end_time": time1

    });
    // formdata.append("course_id", Courses);
    // formdata.append("batch_name", value);
    // formdata.append("start_date", FirstDate);
    // formdata.append("end_date", LastDate);
    // formdata.append("start_time", time);
    // formdata.append("end_time", time1);
    // formdata.append("grace_period", time2);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${API_URL}/admin/batches/create/batch`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result);
        if (result.status == "1") {
          // setMessage(result.message);
          toast(result.message);
          ApiForBatch();
          // setShowLoader(true);
          setcount1(1);
          settime2();
          settime1();
          setLastDate();
          setFirstDate();
          setvalue("");
          ApiForCourse();

        }
        else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
     
      
      
        else {
          console.log(result)
          // setShowLoader(true);
          // setMessage(result.message);
          // toast(result.message);
          setcount1(1);
          settime2();
          settime1();
          setLastDate();
          setFirstDate();
          setvalue("");
        }
      })
      .catch(error => console.log('error', error));
  }

  const convertToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Map the array to include only the desired properties
    const selectiveDataArray = ViewBatchDetail.map((BatchsStudent) => ({
      Enrollment_id: BatchsStudent.enrollment_id,
      Name: BatchsStudent.name,
      // Enrollment_date: BatchsStudent.created_at


    }));

    // Convert the selective data array into a worksheet
    const worksheet = XLSX.utils.json_to_sheet(selectiveDataArray);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a file name
    const fileName = 'Active_Students_data.xlsx';

    // Generate Excel file binary data
    const excelData = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Create a blob object from the binary data
    const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a download link
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;

    // Simulate a click to trigger the download
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
  };

  


  const Sidata = [
    {
      profile: require("../images/iconu.png"),
      name: 'potter',
      env: "28 mar 2023",
      action: "View Details",
    },
    {
      profile: require("../images/iconu.png"),
      name: 'potter',
      env: "28 mar 2023",
      action: "View Details",
    },
    {
      profile: require("../images/iconu.png"),
      name: 'potter',
      env: "28 mar 2023",
      action: "View Details",
    },

    {
      profile: require("../images/iconu.png"),
      name: 'potter',
      env: "28 mar 2023",
      action: "View Details",
    },
    {
      profile: require("../images/iconu.png"),
      name: 'potter',
      env: "28 mar 2023",
      action: "View Details",
    },

  ]

  const [data, setData] = useState(Sidata);

  useEffect(() => {
    ApiForBatch();
    ApiForCourse();
  }, []);

  return (
    <>


      <ToastContainer />
      <div style={{ width: '100%', height: '100vh', }}>
        <div className='head' style={{ width: '80%', height: '14vh', marginLeft: '16%' }}>
          <Header />
        </div>
        <div style={{ width: '100%', height: '86vh', display: "flex", }}>
          <div style={{ width: '20%', height: '100%', position: 'absolute', top: 0, marginLeft: '1%' }} >
            <SideBar />
          </div>

          <div className='maincontain' style={{ marginLeft: '15%', width: '100%', height: '100%', overflowY: 'scroll', }} >
            <div className="row">
              {/* <div className="col-1">
        <div className='' style={{color:"#F03838",backgroundColor:"#FFDCDC",
     width:"40px",borderRadius:"50px",height:"40px",marginLeft:"50px",paddingLeft:"12px",paddingTop:"6px"}}><ImBooks style={{fontSize:"20px"}} /></div>
      </div> */}

              {/* <div className="col-2">
          <p style={{fontSize:"32px",}}><b>Batches</b></p>
    </div> */}
              <div className="col-8 ps-5">
                <div style={{ display: "flex" }}>
                  <NavLink to="/academics" className="pt-4 px-3" style={{ zIndex: "999", }}>
                    <HiArrowLeft style={{ color: '#646464' }} />
                  </NavLink>
                  <img className="mt-3" src={LOGO} alt="sdf" style={{ width: "6%", height: "47px", }} />
                  <p className="ms-3" style={{ fontSize: "48px", fontWeight: "400" }}>Batches/Sections</p>

                </div>
                {/* <p  style={{paddingLeft:"110px"}}>Number of Subjects -  36</p> */}
              </div>
              <div className="col-2">
                <NavLink to='/batchHistory'>
                  <button type="button" style={{
                    background: "#FFFFFF",
                    border: "1px solid #8147E7", borderRadius: "5px", width: "80%", height: "45px", float: "right",
                  }}> <MdOutlineWatchLater /> Batch History</button></NavLink>
              </div>
              <div className="col-2">
                <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{
                  backgroundColor: "#8147E7",
                  color: "white", width: "70%", height: "45px", border: "none", borderRadius: "5px",
                }}> + Add Batches</button>
              </div>
            </div>

            <p className="" style={{ paddingLeft: "150px" }}>Number of Batches / Sections - {Batch.length}</p>
            <div className="container" style={{ padding: "10px 61px 10px 90px" }}>
              <div className="row" >
              {Batch.map((res, key) => {
  return (
    <div key={key}>
      <div
        className="col-4 mt-4"
        type="button"
        
        
       
      >
        <div className=" card" style={{ padding: "10px" }}>
          <p className="" style={{ fontSize: "24px" }}>
            {res.batch_name}
          </p>
          <p className="" style={{ fontSize: "16px" }}>
            Course : {res.course_name}
          </p>
          <div className="d-flex gap-3" >
            <button className="btn btn-primary w-50"  onClick={() => {
          
          ApiForBatchDetail(res.id);
          handleShow1()
        }} >View Details</button>

          <button className="btn btn-primary w-50" onClick={handleShow} >Update Batch Time</button>
          </div>
        </div>
      </div>

      {/* Modal component */}
      <Modal show={show} onHide={handleClose} centered >
        <Modal.Header closeButton className="border-0" >
          <Modal.Title>Update Batch Timing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row mt-3 mx-auto" >
  <div className="col-6" >
<p>Start Time</p>
                        <span
                        className="col-4 rounded"
                        style={{ backgroundColor: "#EFEFEF" }}
                      >
                        <input
                          className="border-0 rounded mt-1 p-2"
                          type="time"
                          style={{
                            backgroundColor: "#EFEFEF",
                            color: "#8A8A8A",
                            outline: "none",
                          }}
                          value={startTime}
                          onChange={(e) => {
                            setStartTime(e.target.value);
                          }}
                        />
                      </span>
  </div>
  <div className="col-6" >
  <p>End Time</p>
                        <span
                        className="col-4 rounded"
                        style={{ backgroundColor: "#EFEFEF", }}
                      >
                        <input
                          className="border-0 rounded mt-1 p-2"
                          type="time"
                          style={{
                            backgroundColor: "#EFEFEF",
                            color: "#8A8A8A",
                            outline: "none",
                          }}
                          value={endTime}
                          onChange={(e) => {
                            setEndTime(e.target.value);
                          }}
                        />
                      </span>
  </div>

</div>
        </Modal.Body>
        <Modal.Footer className="border-0" >
          <Button variant="primary" className="w-100 py-2" onClick={()=>{
            if(startTime.length<=0){
              toast.error("pls select start time");
            }else if(endTime.length<=0){
              toast.error("pls select end time");
            }else{
              updateBatchTime(res.id);
              handleClose();
            }
          }}>
            Update Time
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
})}
              </div>
            </div>


            {/* Offcanvas */}
           

            <Offcanvas show={show1} onHide={handleClose1} placement="end"  style={{ width: "40%" }} >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        {/* <div class="offcanvas-body"> */}
                <p className=""> Course Name</p>
                <p className="mt-3">{BatchName} </p>
                <div className="row mt-5">
                  <div className="col-4">
                    <p >Batch Name</p>
                    <h3 className="mt-3">{BatchId}</h3>
                  </div>

                  <div className="col-4">
                    <p>Total No. Of Students</p>
                    <h3 className="mt-3">{BatchsStudent}</h3>
                  </div>

                  <div className="col-4">

                    <button className=' ' onClick={() => convertToExcel()} style={{
                      backgroundColor: "#8147E7", borderRadius: "5px", width: "170px", height: "40px", border: "none",
                      color: "#ffffff", fontWeight: "400", fontSize: "20px"
                    }}><RiFileExcel2Line style={{ fontSize: "20px" }} /> &nbsp;&nbsp;Export Data</button>


                  </div>
                </div>
                <div className="mt-4" style={{ border: "2px solid #AAAAAA", borderRadius: "5px", overflowY: "scroll", height: "45vh" }}>
                  <div className='row text-center mt-3' style={{ fontSize: "15px", fontWeight: "600" }}>
                    <div className='col-3'>
                      <p>Student ID</p>
                    </div>
                    <div className='col-3'>
                      <p >Name</p>
                    </div>
                    <div className='col-3'>
                      {/* <p >Enrollment Date</p> */}
                      <p>Payments</p>
                    </div>
                    <div className='col-3'>
                      <p>Action</p>
                    </div>
                  </div>
                  {
                    loader ? <Loader show={loader} /> :
                      <>
                        {ViewBatchDetail == "" ? <h4 className="mt-5 text-center " style={{ color: "red" }}>No data available</h4> : ViewBatchDetail?.map((item, index) => {
                          return (
                            <>
                              <div className='row text-center my-3 d-flex align-items-center' >
                                <Sdata item={item} key={index} />
                              </div>
                            </>
                          )
                        })}
                      </>

                  }
                </div>
                <button className="mt-5" style={{
                  width: "250px", marginLeft: "30%",
                  backgroundColor: "#5A81FE", border: "none", color: "white", height: "35px", borderRadius: "5px"
                }} data-bs-dismiss="offcanvas" aria-label="Close">Back</button>
              {/* </div> */}
        </Offcanvas.Body>
      </Offcanvas>
          </div>
        </div>
      </div>

      {/* +Add modal start */}
      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backdropFilter: "blur(2px)" }}
      >
        <div className="modal-dialog modal-dialog-centered"  >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Add Batch</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setcount1(1) }}></button>
            </div>
            <div className="modal-body">

              <div style={{ display: count1 === 1 ? "block" : "none" }}>
                <div className="mt-2" style={{ border: "2px solid #FAFAFA", width: "350px", height: "330px", marginLeft: "76px", borderRadius: "5px" }}>
                  <div style={{ margin: "15px" }}><b> Select Courses</b></div>

                  <div style={{ border: "2px solid #FAFAFA", width: "90%", marginLeft: "20px", paddingLeft: "10px" }}>
                    <li class="nav-item dropdown">

                      <select className="w-100 border-0" onChange={(e) => {
                        setCourses(e.target.value);
                      }}
                        style={{ outLine: "none" }}>
                        <option value="0" >Select Courses</option>
                        {
                          Course?.map((res) => {
                            return (
                              <>
                                <option style={{ outLine: "none", border: 'n' }} value={res.id}>{res.course_name}</option>
                              </>
                            )
                          })
                        }

                      </select>
                    </li>
                  </div>

                  <div className="">
                    <button className="btn btn-primary mx-4"
                      style={{ width: "300px", marginTop: "100px" }} disabled={Courses >= 1 ? false : true} onClick={() => { setcount1(count1 + 1) }}>Proceed</button>
                  </div>
                </div>
              </div>

              {/* second Add staart */}
              <div style={{ display: count1 === 2 ? "block" : "none" }}>
                <div className="mt-2" style={{ border: "2px solid #FAFAFA", width: "350px", height: "330px", marginLeft: "76px", borderRadius: "5px" }}>
                  <div style={{ margin: "15px" }}><b> Name Of Batch</b></div>
                  <div className="mt-3">
                    <div >
                      <input type="text" maxLength="100" className="mx-4" placeholder="Type Batch Name"
                        value={value} style={{
                          backgroundColor: "#FAFAFA", borderRadius: "5px",
                          border: "none", width: "300px", marginTop: "20px", height: "40px", paddingLeft: "10px"
                        }} onChange={(e) => { setvalue(e.target.value) }} /></div>
                    <p style={{ paddingLeft: "295px", color: "#B1AFC8", fontSize: "10px" }} >{value.length}/100</p>
                  </div>
                  <div className="">
                    <button className="btn btn-primary mx-4 mt-5" disabled={value.length >= 1 ? false : true} onClick={() => { setcount1(count1 + 1) }}
                      style={{ width: "300px", marginTop: "0px" }} >Proceed</button>
                  </div>
                </div>
              </div>


              {/* third Add start */}
              <div style={{ display: count1 === 3 ? "block" : "none" }}>
                <div className="ps-3" style={{ border: "0.5px solid #DBDBDB", borderRadius: "5px" }}>
                  <div style={{ color: "#292929", fontWeight: "500", fontSize: "20px" }}>Duration</div>

                  <div className="row mt-1">
                    <div className="col-6">
                      <p className="ps-3" style={{ color: "#8A8A8A", fontSize: "400" }}>From</p>
                      <div >

                        <span style={{
                          display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "10px",
                          borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', 
                        }}>
                          <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
                          <div>
                            <DatePicker className="border-0 px-0 ps-2  datep"
           
                              selected={startDate}
                              onChange={(date) => {
                                setStartDate(date);
                                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                                const day = date.getDate().toString().padStart(2, '0');
                                setFirstDate(date.getFullYear() + "-" + month + "-" + day);
                              }}
                              style={{ backgroundColor: '#E0E0E0', outline: "0",}} />


                          </div>
                          
                        </span>
                      </div>
                    </div>
                    <div className="col-6">
                      <p style={{ color: "#8A8A8A", fontSize: "400" }}>To</p>
                      <div>

                        <span style={{
                          display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "0px",
                          borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
                        }}>
                          <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px",paddingLeft:'0', marginLeft:'0'}} />
                          <div>
                            <DatePicker className="border-0 px-0 ps-2 outline-0  datep "
                 
                              selected={startDate1}
                              onChange={(date) => {
                                setStartDate1(date);
                                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                                const day = date.getDate().toString().padStart(2, '0');
                                setLastDate(date.getFullYear() + "-" + month + "-" + day);
                              }}
                              style={{ backgroundColor: '#E0E0E0', outline: "0", paddingLeft:"0"}} />
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="mt-4" style={{ margin: "15px", color: "#292929", fontWeight: "500", fontSize: "16px" }}>Batch Timings</div>
                    <div className="row ms-2">
                      <div className="col-6" style={{ display: "flex" }}>
                        <p className="mt-1">From</p>&nbsp;
                        <input type="time" value={time} style={{ backgroundColor: "#EDEDF5", borderRadius: "5px", border: "none", padding: "8px 8px", }} onChange={handleTimeChangeStart} />
                      </div>
                      <div className="col-6" style={{ display: "flex" }}>
                        <p className="mt-1">to</p>&nbsp;
                        <input type="time" value={time1} style={{ backgroundColor: "#EDEDF5", borderRadius: "5px", border: "none", padding: "8px 8px", }} onChange={handleTimeChangeEnd} />
                      </div>
                    </div>
                  </div>
                  <div className="mt-4" style={{ margin: "15px", color: "#292929", fontWeight: "500", fontSize: "16px" }}>Grace Period</div>
                  <div style={{ display: "flex", marginLeft: "15px" }} >
                    <input type="text " value={time2} style={{ width: "60px", height: "30px", }} onChange={(e) => { settime2(e.target.value) }} />
                    <p className="ms-5" style={{ backgroundColor: "#EDEDF5", padding: "5px 9px", borderRadius: "5px", }}>Minutes</p>

                  </div>




                  <div className="mt-4 mb-2 text-center">
                    <button data-bs-dismiss="modal" className=" mx-4" style={{ width: "80%", marginTop: "3px", height: "40px", border: "none", color: "#fff", backgroundColor: "#5A81FE", borderRadius: "5px", boxShadow: "0px 4px 12px rgba(90, 129, 254, 0.25)" }}
                      onClick={AddBatch}>Proceed</button>
                  </div>
                </div>
              </div>


            </div>

          </div>

        </div>
      </div >



      {/* <ToastContainer position="top-end" className="p-3" >
        <Toast onClose={() => setShowLoader(false)} show={ShowLoader} delay={8000} autohide style={{ backgroundColor: "#000" }}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">tipsg</strong>
            <small>1 mins ago</small>
          </Toast.Header>
          <Toast.Body className="text-white">{Message}</Toast.Body>
        </Toast>
      </ToastContainer> */}



    </>
  )
}

function Sdata(props) {
  return (
    <>
      <div className='col-3 '>
        <p style={{}} >{props.item.enrollment_id}</p>
      </div>
      <div className='col-3'>
        <p>{props.item.name}</p>
      </div>
      <div className='col-3'>
        {/* <p>{props.item.Enrollment_date.split("", 10)}</p> */}
        <NavLink to={`/PaymentSetup/${props.item.id}`} className="text-decoration-none " style={{ fontSize: "14px" }}>    <p>Add Payment</p></NavLink>
      </div>
      <div className='col-3' style={{ display: "flex", color: "#8147E7" }}>
        {/* <AiOutlineEye className="m-1" /> */}
        <NavLink to={`/PaymentHistory/${props.item.id}`} className="text-decoration-none " style={{ fontSize: "14px" }}>    <p>Payment History</p></NavLink>
      </div>
    </>
  );
}