import React, { useState, useEffect, useContext } from "react"; //api done
import "react-datepicker/dist/react-datepicker.css";
import { BsArrowLeft } from "react-icons/bs";
import { FaUserGraduate } from "react-icons/fa";
import { AuthContext } from "./AuthContext";
import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
// import { API_URL } from './MyComponent';

import {  NavLink, useParams , useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";


// const {API_URL,img_url} =env;

import { API_URL, img_url } from "../env";
import { ToastContainer, toast } from "react-toastify";
import EditPaymentDetails from "./EditPaymentDetails";


export default function PaymentHistory() {
const Navigate  = useNavigate();
 
  const [startDate, setStartDate] = useState(new Date());

  const [missed, setMissed] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [paid, setPaid] = useState([]);
  const { apiToken,stuPayLoader,setStuPayLoader,value, setvalue } = useContext(AuthContext);

  const { id } = useParams();
  const dataId = id;

  const table = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var raw = JSON.stringify({
      student_id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${API_URL}/admin/student/get/transaction/for/particular/student`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status == "1") {
          setMissed(result.missed);
          setUpcoming(result.upcoming);
          setPaid(result.paid);
          // setIsPaid(result.emi_list[0].is_paid)
        }
      })
      .catch((error) => console.log("error", error));
  };

  const payments = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var raw = JSON.stringify({
      emi_id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/admin/student/set/emi/paid/unpaid/by/id`, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == "1") {
          toast(result.msg);
          table();
        }
      });
  };

  useEffect(() => {
    table();
  } ,[stuPayLoader]);

  const convertToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Map the array to include only the desired properties
    const selectiveDataArray = missed.map((student) => ({
      Name: student.name,
      Batch: student.batch_name,
      Start_Date: student.start_date,
      End_Date: student.end_date,
    }));

    // Convert the selective data array into a worksheet
    const worksheet = XLSX.utils.json_to_sheet(selectiveDataArray);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a file name
    const fileName = "Active_Students_data.xlsx";

    // Generate Excel file binary data
    const excelData = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a blob object from the binary data
    const blob = new Blob([excelData], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;

    // Simulate a click to trigger the download
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
  };

  const callingDeleteApi = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    await fetch(`${API_URL}/admin/student/delete/payment`, {
      method: "DELETE",
      headers: myHeaders,
      body : JSON.stringify( {
        id 
      }
      )
    }).then(()=>{
      setStuPayLoader(!stuPayLoader)
      toast.success("Payment Deleted Successfully")
    })

  }

  return (
    <>
      <div className="row ">
        <ToastContainer />
        <div
          className="col-2 bg-white"
          style={{ backgroundColor: "#fff", height: "100vh" }}
        >
          <p
            className="ms-4  "
            style={{
              fontWeight: "600",
              color: "#3C3C3C",
              fontSize: "20px",
              marginTop: "40px",
            }}
          >
            {" "}
            <NavLink to="/Students" className="" style={{ zIndex: "999" }}>
              <BsArrowLeft
                className="ps-1"
                style={{
                  color: "#000000",
                  fontSize: "25px",
                  cursor: "pointer",
                }}
              />
            </NavLink>{" "}
            Payment History
          </p>
          <span
            className="mt-4"
            style={{
              display: "flex",
              backgroundColor: "#F4F2F9",
              border: "none",
              marginLeft: "30px",
              height: "45px",
              borderRadius: "5px",
              color: "#8A8A8A",
            }}
          >
            <p
              className="pt-2"
              style={{
                zIndex: "999",
                paddingLeft: "30px",
                color: "#8147E7",
                fontWeight: "400",
                cursor: "pointer",
              }}
            >
              <FaUserGraduate className="me-3" />
              Payment details
            </p>
          </span>
        </div>
        <div className="col-lg-10 col-md-10 col-sm-10">
          {/* Invoice Details */}

          <div className="mt-5">
            <div className="d-flex">
              <p
                className="mb-3"
                style={{
                  color: "#3C3C3C",
                  fontSize: "22px",
                  fontWeight: "600",
                  paddingLeft: "30px",
                }}
              >
                Payment Details
              </p>
            </div>

            {/* Tabs  */}

            <div className="container">
              <div className="row">



              {/* for changing tabs */}
                <div className="col-12 d-flex gap-3 align-items-center" >
                    <span className="py-2 px-3" onClick={()=>setvalue('Missed')} style={{cursor:"pointer",borderBottom:value=='Missed'?"2px solid #8147E7":"none",color:value=='Missed'?"#8147E7":"",fontWeight:value=='Missed'?700:400}}  >Missed</span>
                    <span className="py-2 px-3" onClick={()=>setvalue('Upcoming')} style={{cursor:"pointer",borderBottom:value=='Upcoming'?"2px solid #8147E7":"none",color:value=='Upcoming'?"#8147E7":"",fontWeight:value=='Upcoming'?700:400}} >Upcoming</span>
                    <span className="py-2 px-3" onClick={()=>setvalue('Paid')} style={{cursor:"pointer",borderBottom:value=='Paid'?"2px solid #8147E7":"none",color:value=='Paid'?"#8147E7":"",fontWeight:value=='Paid'?700:400}} >Paid</span>
                </div>

                <div  style={{borderBottom:"1px solid #e4e4e4"}} ></div>

                <div className="row" >
                    <div className="col-12" >


                      {/* 1 */}
                    {
                        value=="Missed"?<>
                        <div className="mt-3">
              <div className="row mx-4 mt-3 ">
                <div
                  className="row"
                  style={{
                    color: "#434343",
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                >
                  <div className="col-1 pb-2">
                    <p>ID</p>
                  </div>
                  <div className="col-3 pb-3">
                    <p>Remark</p>
                  </div>

                  <div className="col-2 pb-2">
                    <p>Amount</p>
                  </div>
                  <div className="col-2 pb-2">
                    <p>Paid</p>
                  </div>
                  <div className="col-2 pb-2">
                    <p>Due Date</p>
                  </div>
                  <div className="col-2 pb-2">
                    <p>Action</p>
                  </div>
                </div>

                <div style={{ overflowY: "scroll", height: "60vh" }}>
                  {missed.length > 0 ? (
                    missed?.map((res, key) => {
                      return (
                        <>
                          <div
                            className="row align-items-center  mt-3"
                            style={{
                              width: "100%",
                              borderRadius: "5px",
                              height: "55px",
                              backgroundColor: " #FFFFFF",
                            }}
                          >
                            <div className="col-1" key={key}>
                              <p
                                style={{
                                  color: "#434343",
                                  fontWeight: "400",
                                  fontSize: "15px",
                                }}
                              >
                                {res.id}
                              </p>
                            </div>
                            <div className="col-3">
                              {res.remark == null ? (
                                <>
                                  <p
                                    style={{
                                      color: "red",
                                      fontWeight: "400",
                                      fontSize: "15px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    No Remark
                                  </p>
                                </>
                              ) : (
                                <p
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "15px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {res.remark}
                                </p>
                              )}
                            </div>

                            <div   className="col-2">
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "#434343",
                                  fontWeight: "400",
                                }}
                              >
                                {res.amount}
                              </p>
                            </div>


                            <div className="col-2">
                              {res.is_paid == "0" ? (
                                <p
                                  style={{
                                    fontSize: "15px",
                                    color: "#434343",
                                    fontWeight: "400",
                                    color: "red",
                                  }}
                                >
                                  Unpaid
                                </p>
                              ) : (
                                <p
                                  style={{
                                    fontSize: "15px",
                                    color: "#434343",
                                    fontWeight: "400",
                                    color: "green",
                                  }}
                                >
                                  Paid
                                </p>
                              )}
                            </div>
                            <div className="col-2">
                              <p
                                style={{ fontSize: "15px", fontWeight: "400" }}
                              >
                                {res.due_date}
                              </p>
                            </div>


                            <div className="col-2">
                            
                              {res.is_paid == "0" ? (
                                <button className="px-2 py-1"
                                  style={{
                                    backgroundColor: "green",
                                    borderRadius: "5px",
                                    // width: "50%",
                                    border: "none",
                                    color: "#ffffff",
                                  }}
                                  onClick={() => {
                                    payments(res.id);
                                  }}
                                >
                                  Paid
                                </button>
                                
                              ) : (
                                <button
                                className="p-1"
                                  style={{ 
                                    backgroundColor: "red",
                                    borderRadius: "5px",
                                    // width: "50%",
                                    border: "none",
                                    color: "#ffffff",
                                  }}
                                  onClick={() => {
                                    payments(res.id);
                                  }}
                                >
                                  Unpaid
                                </button>
                    ) 
                            }
                            
                            <button

                            onClick={()=>{ 
                              let page = "Missed"
                              Navigate(`/EditStudentPaymentDetails/${res.id}/${res.remark}/${res.amount}/${res.due_date}/${page}`)
                          }
                                
                          } // navigating with id

                             className="p-1" style={{
                                     marginLeft:"10px",
                                    marginRight:"10px",                                 
                                    backgroundColor: "blue",
                                    borderRadius: "5px",
                                    fontSize:"1rem",
                                    border: "none",
                                    color: "#ffffff",
                                
                                      }}><MdModeEdit/>
                                      </button>

                           <button className="p-1" style={{           
                                    backgroundColor: "red",
                                    borderRadius: "5px",
                                    fontSize:"1rem",
                                    // width: "20%",
                                    border: "none",
                                    color: "#ffffff",}} onClick={

                              ()=>{callingDeleteApi(res.id)} 
                            } ><MdDelete/></button>
                            
                            </div>



                          </div>
                        </>
                      );
                    })





                  ) : (
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginTop: "50px",
                      }}
                    >
                      No Payment History
                    </p>
                  )}
                </div>
              </div>
            </div>
                        </> : <></>
                    }
                    {
                        value=="Upcoming"?<>
                        <div className="mt-3">
              <div className="row mx-4 mt-3 ">
                <div
                  className="row"
                  style={{
                    color: "#434343",
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                >
                  <div className="col-1 pb-2">
                    <p>ID</p>
                  </div>
                  <div className="col-3 pb-3">
                    <p>Remark</p>
                  </div>

                  <div className="col-2 pb-2">
                    <p>Amount</p>
                  </div>
                  <div className="col-2 pb-2">
                    <p>Paid</p>
                  </div>
                  <div className="col-2 pb-2">
                    <p>Due Date</p>
                  </div>
                  <div className="col-2 pb-2">
                    <p>Action</p>
                  </div>
                </div>

                <div style={{ overflowY: "scroll", height: "60vh" }}>
                  {upcoming.length > 0 ? (
                    upcoming?.map((res, key) => {
                      return (
                        <>
                          <div
                            className="row align-items-center  mt-3"
                            style={{
                              width: "100%",
                              borderRadius: "5px",
                              height: "55px",
                              backgroundColor: " #FFFFFF",
                            }}
                          >
                            <div className="col-1" key={key}>
                              <p
                                style={{
                                  color: "#434343",
                                  fontWeight: "400",
                                  fontSize: "15px",
                                }}
                              >
                                {res.id}
                              </p>
                            </div>
                            <div className="col-3">
                              {res.remark == null ? (
                                <>
                                  <p
                                    style={{
                                      color: "red",
                                      fontWeight: "400",
                                      fontSize: "15px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    No Remark
                                  </p>
                                </>
                              ) : (
                                <p
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "15px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {res.remark}
                                </p>
                              )}
                            </div>

                            <div className="col-2">
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "#434343",
                                  fontWeight: "400",
                                }}
                              >
                                {res.amount}
                              </p>
                            </div>

                            <div className="col-2">
                              {res.is_paid == "0" ? (
                                <p
                                  style={{
                                    fontSize: "15px",
                                    color: "#434343",
                                    fontWeight: "400",
                                    color: "red",
                                  }}
                                >
                                  Unpaid
                                </p>
                              ) : (
                                <p
                                  style={{
                                    fontSize: "15px",
                                    color: "#434343",
                                    fontWeight: "400",
                                    color: "green",
                                  }}
                                >
                                  Paid
                                </p>
                              )}
                            </div>
                            <div className="col-2">
                              <p
                                style={{ fontSize: "15px", fontWeight: "400" }}
                              >
                                {res.due_date}
                              </p>
                            </div>

                            
                            <div className="col-2">
                            
                            {res.is_paid == "0" ? (
                            <button className="px-2 py-1"
                                style={{
                                  backgroundColor: "green",
                                  borderRadius: "5px",
                                  // width: "50%",
                                  border: "none",
                                  color: "#ffffff",
                                }}
                                onClick={() => {
                                  payments(res.id);
                                }}
                              >
                                Paid
                              </button>
                              
                            ) : (
                              <button
                              className="p-1"
                                style={{ 
                                  backgroundColor: "red",
                                  borderRadius: "5px",
                                  // width: "50%",
                                  border: "none",
                                  color: "#ffffff",
                                }}
                                onClick={() => {
                                  payments(res.id);
                                }}
                              >
                                Unpaid
                              </button>
                  ) 
                          }
                          <button 
                            onClick={()=>{
                              let page = "Upcoming"
                              Navigate(`/EditStudentPaymentDetails/${res.id}/${res.remark}/${res.amount}/${res.due_date}/${page}`)
                          }}
                          className="p-1" style={{marginLeft:"10px", marginRight:"10px",                                 
                                  backgroundColor: "blue",
                                  borderRadius: "5px",
                                  fontSize:"1rem",
                                  border: "none",
                                  color: "#ffffff",  }}><MdModeEdit/></button>

                         <button className="p-1" style={{           
                                  backgroundColor: "red",

                                  borderRadius: "5px",
                                  fontSize:"1rem",
                                  // width: "20%",
                                  border: "none",
                                  color: "#ffffff",}} onClick={

                            ()=>{callingDeleteApi(res.id)} 
                          } ><MdDelete/></button>
                          
                          </div>
                          
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginTop: "50px",
                      }}
                    >
                      No Payment History
                    </p>
                  )}
                </div>
              </div>
            </div>
                        </> : <></>
                    }
                    {
                        value=="Paid"?<>
                        <div className="mt-3">
              <div className="row mx-4 mt-3 ">
                <div
                  className="row"
                  style={{
                    color: "#434343",
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                >
                  <div className="col-1 pb-2">
                    <p>ID</p>
                  </div>
                  <div className="col-3 pb-2">
                    <p>Remark</p>
                  </div>
                  <div className="col-1 pb-3">
                    <p>Serial No</p>
                  </div>

                  <div className="col-2 pb-2">
                    <p>Amount</p>
                  </div>
                  <div className="col-1 pb-1">
                    <p>Paid</p>
                  </div>
                  <div className="col-1 pb-2">
                    <p>Due Date</p>
                  </div>
                  <div className="col-3 pb-3">
                    <p>Action</p>
                  </div>
                </div>

                <div style={{ overflowY: "scroll", height: "60vh" }}>
                  {paid.length > 0 ? (
                    paid?.map((res, key) => {
                      return (
                        <>
                          <div
                            className="row align-items-center  mt-3"
                            style={{
                              width: "100%",
                              borderRadius: "5px",
                              height: "55px",
                              backgroundColor: " #FFFFFF",
                            }}
                          >
                            <div className="col-1" key={key}>
                              <p
                                style={{
                                  color: "#434343",
                                  fontWeight: "400",
                                  fontSize: "15px",
                                }}
                              >
                                {res.id}
                              </p>
                            </div>
                            <div className="col-3">
                              {res.remark == null ? (
                                <>
                                  <p
                                    style={{
                                      color: "red",
                                      fontWeight: "400",
                                      fontSize: "15px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    No Remark
                                  </p>
                                </>
                              ) : (
                                <p
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "15px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {res.remark}
                                </p>
                              )}
                            </div>

                            <div className="col-1">
                              <p
                                style={{ fontSize: "15px", fontWeight: "400" , color: res.serial_no === 0 && "red" }}
                              >
                                {res.serial_no}
                              </p>
                            </div>

                            <div className="col-2">
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "#434343",
                                  fontWeight: "400",
                                }}
                              >
                                {res.amount}
                              </p>
                            </div>

                            <div className="col-1">
                              {res.is_paid == "0" ? (
                                <p
                                  style={{
                                    fontSize: "15px",
                                    color: "#434343",
                                    fontWeight: "400",
                                    color: "red",
                                  }}
                                >
                                  Unpaid
                                </p>
                              ) : (
                                <p
                                  style={{
                                    fontSize: "15px",
                                    color: "#434343",
                                    fontWeight: "400",
                                    color: "green",
                                  }}
                                >
                                  Paid
                                </p>
                              )}
                            </div>
                            <div className="col-1">
                              <p
                                style={{ fontSize: "15px", fontWeight: "400" }}
                              >
                                {res.due_date}
                              </p>
                            </div>
                            <div className="col-3">
                            
                            {res.is_paid == "0" ? (
                            <button className="px-2 py-1"
                                style={{
                                  backgroundColor: "green",
                                  borderRadius: "5px",
                                  // width: "50%",
                                  border: "none",
                                  color: "#ffffff",
                                }}
                                onClick={() => {
                                  payments(res.id);
                                }}
                              >
                                Paid
                              </button>
                              
                            ) : (
                             <button
                              className="p-1"
                                style={{ 
                                  backgroundColor: "red",
                                  borderRadius: "5px",
                                  // width: "50%",
                                  border: "none",
                                  color: "#ffffff",
                                }}
                                onClick={() => {
                                  payments(res.id);
                                }}
                              >
                                Unpaid
                              </button>
                  ) 
                          }
                          <button
                            onClick={()=>{
                              let page = "Paid"
                              Navigate(`/EditStudentPaymentDetails/${res.id}/${res.remark}/${res.amount}/${res.due_date}/${page}`)
                            setvalue("Paid")}}
                          className="p-1" style={{marginLeft:"2px", marginRight:"2px",                                 
                                  backgroundColor: "blue",
                                  borderRadius: "5px",
                                  fontSize:"1rem",
                                  marginLeft :"3px",
                                  border: "none",
                                  color: "#ffffff",  }}><MdModeEdit/></button>

                         <button className="p-1" style={{           
                                  backgroundColor: "red",
                                  borderRadius: "5px",
                                  marginLeft :"1px",
                                  fontSize:"1rem",
                                  border: "none",
                                  color: "#ffffff",}} onClick={

                            ()=>{callingDeleteApi(res.id)} 
                          } ><MdDelete/></button>


{/* generate slip */}
{ res.serial_no == 0 ? <>
  <button className="p-1" style={{           
                                  backgroundColor: "green",
                                 marginLeft :"3px",
                                  borderRadius: "5px",
                                  border: "none",
                                  color: "#ffffff",}}
                                   onClick={
                            ()=>{Navigate(`/GenerateSlipt/${res.id}/${res.due_date}`)} 
                          } > Generate Slip</button>
</> : 
 <>
 <button className="p-1" style={{           
                                 backgroundColor: "green",
                                marginLeft :"3px",
                                 borderRadius: "5px",
                                 border: "none",
                                 color: "#ffffff",}}
                                  onClick={
                           ()=>{Navigate(`/UpdateSlipt/${res.id}/${res.due_date}`)} 
                         } > Update Slip</button>
</>

}

                          
                          </div>

                          </div>
                        </>
                      );
                    })
                  ) : (
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginTop: "50px",
                      }}
                    >
                      No Payment History
                    </p>
                  )}
                </div>
              </div>
            </div>
                        </> : <></>
                    }

                    </div>

                </div>
              </div>
            </div>

            {/* Tabs  */}

            {/* <div className="mt-3">
              <div className="row mx-4 mt-3 ">
                <div
                  className="row"
                  style={{
                    color: "#434343",
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                >
                  <div className="col-1 pb-2">
                    <p>ID</p>
                  </div>
                  <div className="col-3 pb-3">
                    <p>Remark</p>
                  </div>

                  <div className="col-2 pb-2">
                    <p>Amount</p>
                  </div>
                  <div className="col-2 pb-2">
                    <p>Paid</p>
                  </div>
                  <div className="col-2 pb-2">
                    <p>Due Date</p>
                  </div>
                  <div className="col-2 pb-2">
                    <p>Action</p>
                  </div>
                </div>

                <div style={{ overflowY: "scroll", height: "60vh" }}>
                  {list.length > 0 ? (
                    list?.map((res, key) => {
                      return (
                        <>
                          <div
                            className="row align-items-center  mt-3"
                            style={{
                              width: "100%",
                              borderRadius: "5px",
                              height: "55px",
                              backgroundColor: " #FFFFFF",
                            }}
                          >
                            <div className="col-1" key={key}>
                              <p
                                style={{
                                  color: "#434343",
                                  fontWeight: "400",
                                  fontSize: "15px",
                                }}
                              >
                                {res.id}
                              </p>
                            </div>
                            <div className="col-3">
                              {res.remark == null ? (
                                <>
                                  <p
                                    style={{
                                      color: "red",
                                      fontWeight: "400",
                                      fontSize: "15px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    No Remark
                                  </p>
                                </>
                              ) : (
                                <p
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "15px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {res.remark}
                                </p>
                              )}
                            </div>

                            <div className="col-2">
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "#434343",
                                  fontWeight: "400",
                                }}
                              >
                                {res.amount}
                              </p>
                            </div>

                            <div className="col-2">
                              {res.is_paid == "0" ? (
                                <p
                                  style={{
                                    fontSize: "15px",
                                    color: "#434343",
                                    fontWeight: "400",
                                    color: "red",
                                  }}
                                >
                                  Unpaid
                                </p>
                              ) : (
                                <p
                                  style={{
                                    fontSize: "15px",
                                    color: "#434343",
                                    fontWeight: "400",
                                    color: "green",
                                  }}
                                >
                                  Paid
                                </p>
                              )}
                            </div>
                            <div className="col-2">
                              <p
                                style={{ fontSize: "15px", fontWeight: "400" }}
                              >
                                {res.due_date}
                              </p>
                            </div>

                            <div className="col-2">
                              {res.is_paid == "0" ? (
                                <button
                                  style={{
                                    backgroundColor: "red",
                                    borderRadius: "5px",
                                    width: "50%",
                                    border: "none",
                                    color: "#ffffff",
                                  }}
                                  onClick={() => {
                                    payments(res.id);
                                  }}
                                >
                                  Unpaid
                                </button>
                              ) : (
                                <button
                                  style={{
                                    backgroundColor: "green",
                                    borderRadius: "5px",
                                    width: "50%",
                                    border: "none",
                                    color: "#ffffff",
                                  }}
                                  onClick={() => {
                                    payments(res.id);
                                  }}
                                >
                                  Paid
                                </button>
                              )}
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginTop: "50px",
                      }}
                    >
                      No Payment History
                    </p>
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
