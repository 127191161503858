import React,{useContext,useEffect,useState}from 'react'
import { API_URL } from "../env";
import { toast,ToastContainer } from 'react-toastify';


function Task() {

  const [data, setdata] = useState([])
  const [id, setId] = useState();
  const [checkedTasks, setCheckedTasks] = useState({});
  const [data1,setdata1] = useState(0)





const taskdata = async()=>{
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${API_URL}/admin/profile/view/task/in/profile/admin`, requestOptions)
  .then(response => response.json())
  .then(result =>{ 
    console.log(result)
  if(result.status == 1){
    setdata(result.tasks)
    setId(result.tasks.id)

    console.log(result.tasks.id,'mamadata');
  }
  else{
    setdata1(1)
  }
  
  })
  .catch(error => console.log('error', error));
}

const upda_task = async(taskId)=>{
  setCheckedTasks((prevCheckedTasks) => ({
    ...prevCheckedTasks,
    [taskId]: !prevCheckedTasks[taskId],
  }));
  var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

var raw = JSON.stringify({
  "id": taskId
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${API_URL}/admin/profile/update/task/in/profile/admin`, requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if(result.status == 1){
      toast(result.msg)
      taskdata()
    }
    else{
      toast(result.msg)

    }
  })
  .catch(error => console.log('error', error));
}


useEffect(() => {
  taskdata()
}, [])

  return (
    <>
      <ToastContainer />
    <h3> Task</h3>
    <div className='container' style={{ height: "70vh", overflowY: "scroll" }}>
  {  data1 == 0 ? (
    data.map((item) => (
      <div className='card p-3 mt-3 card-sadows mt-5' key={item.id}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h5 className="card-title">
            <i>Title: </i> {item.title}
          </h5>
          <p className='mt-1'>
            <b>
              <i>Task Complete</i>
            </b>{" "}
            <span>
             
                  <input
  type="checkbox"
  checked={item.status === "completed"}
  onChange={() => upda_task(item.id)}
  style={{ fontSize: "20px" }}
/>
       </span>{" "}
          </p>
        </div>
        <h6 className="card-subtitle mb-2 text-muted">
          <i>
            <b> Assigned By: </b>
          </i>{" "}
          {item.assigned_by}
        </h6>
        <h6 className="card-subtitle mb-2 text-muted">
          <i>
            <b> Due Date: </b>
          </i>{" "}
          {item.due_date}
        </h6>
        <p className="card-text">
          <i>
            <b> Description: </b>
          </i>{" "}
          {item.des}
        </p>
      </div>
    ))
  ) : (
    <p style={{color:'red',textAlign:'center'}}>No data found</p>
  )}
</div>;

    </>
  )
}

export default Task