import React, { useState, useEffect } from 'react';
import { FaCalendar, } from "react-icons/fa";
import { BiTimeFive, } from "react-icons/bi";
import EE from '../images/ee.png';
import { FiEye } from "react-icons/fi";
export default function Quiz() {

    const [value, setvalue] = useState();
    const [Catg, setCatg] = useState("");
    // console.log(Catg);

    useEffect(() => {
        setvalue('details')
    }, []);

    const cardd = [
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
        {
            cont: 'potter ',
            cont1: 'rns',
            cont2: '2023',

        },
    ]

    const ss = [
        {
            const: 'potter',
            const: 'sof',
            const: '2023',
        },
        {
            const: 'potter',
            const: 'sof',
            const: '2023',
        },

    ]
    return (
        <>

            <div className="row mt-4">
                <div className="col-2">
                    <p style={{ paddingLeft: "30px", fontSize: "16px" }}>Total Quizzes : 0</p>
                </div>

                <div className="col-2">
                    <p style={{ fontSize: "16px", color: "#6B6B6B" }}>Attempted : 0</p>
                </div>

                <div className="col-2">
                    <p style={{ paddingRight: "30px", fontSize: "16px", color: "#9E9E9E" }}>Unattempt : 0</p>
                </div>

                <div className="col-1">
                    <p style={{ fontSize: "16px", color: "#55DE64" }}>Passed : 0</p>
                </div>

                <div className="col-1">
                    <p style={{ fontSize: "16px", color: "#F93333" }}>Failed : 0</p>
                </div>
            </div>

            <div className="row" style={{ overflowY: "scroll", height: "50vh", marginLeft: "30px" }} >
                {/* map */}

                {/* {cardd.map((res, key) => {
                    return ( */}

                        <div className="col-3 mt-4"  style={{}}>
                          { /* <div class="card" style={{ width: "90%", background: "#FFFFFF", border: "1px solid #E6E6E6", borderRadius: "5px", }} >
                                <div className="card-body">
                                    <p style={{ backgroundColor: "#FFEFEF", width: "55px", paddingLeft: "7px", marginTop: "-16px", color: "#F93333", borderRadius: "0px 5px 0px 5px  ", marginLeft: "86%", fontSize: "13px", }}>Failed</p>
                                    <p className="" style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#3C3C3C", }}>Articulate structure of C++ and Java in Semester 1</p>
                                    <p className="mt-1" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#9A9A9A", }}>Subject : Network Engineering</p>

                                    <div className="mt-2" style={{ display: "flex", }}>
                                        <p style={{ fontSize: "13px" }}> <FaCalendar />&nbsp;&nbsp;17-03-2023</p>
                                        <p className="mx-2" style={{ fontSize: "13px" }}><BiTimeFive /> &nbsp;12:23 PM - 01:50 AM</p>
                                    </div>

                                    <div className='mt-2' style={{ display: "flex" }}>
                                        <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C", }}>Passing Percentage </p>
                                        <p className="mx-3" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3D70F5", }}>70%</p>
                                    </div>

                                    <div className='mt-2' style={{ display: "flex" }}>
                                        <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#3C3C3C", }}>Scored Percentage </p>
                                        <p className="mx-4 mb-2" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#F93333", }}>10%</p>
                                    </div>


                                    <button type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" className='col-4 border-0 rounded-1 mx-auto'
                                        style={{ fontSize: "12px", backgroundColor: "#2C62EE", borderRadius: "5px", width: "100%", border: "none", color: "white", height: "35px" }}>View Details</button>
                                </div>
                </div>*/}

                {  <div class="card" style={{ width: "90%", background: "#FFFFFF", border: "1px solid #E6E6E6", borderRadius: "5px", }} >
                <div className="card-body">
                    <p className='text-center p-5' style={{color:"red"}}>The data will be visible after creating Quiz</p>
                </div>
            </div>}

                        </div>

                    {/* )
                })} */}
            </div>

            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ width: "50%" }}>
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasRightLabel"></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
                </div>
                <div class="offcanvas-body">

                    <div className="row">
                        <div className="col-4">
                            <p className="" style={{ fontWeight: "bold" }}>Quiz Result Details</p>
                        </div>
                        <div className="col-lg-8 ">
                            <p className="" style={{
                                paddingTop: "5px", fontSize: "10px", paddingLeft: "10px",
                                backgroundColor: "#ECF2FF", color: "#3D70F5", width: "270px", height: "24px", borderRadius: "5px", marginLeft: "200px"
                            }}>Result Declared on 12:30 AM | 22 September 2023</p>
                        </div>
                    </div>


                    <div className="row mt-4">
                        <div className="col-2">
                            <img src={EE} style={{}} />
                        </div>

                        <div className="col-6">
                            <p style={{ fontWeight: "700" }}>Raj Anadkat</p>
                            <p style={{ fontWeight: "700" }}>Student ID : TIPSG5682</p>
                            <span style={{ color: "#2C62EE" }}> <FiEye />View Profile</span>
                        </div>
                    </div>

                    <p className="mt-2">Articulate structure of C++ and Java in Semester 1</p>
                    <div className="row mt-2">
                        <div className="col-2 ">
                            <p style={{ border: "1px solid #D3D3D3", fontSize: "10px", borderRadius: "5px", paddingLeft: "8px", height: "20px", paddingTop: "2px" }}>Batch 3CO - JVY</p>
                        </div>
                        <div className="col-2">
                            <p style={{ fontSize: "10px" }}><BiTimeFive /> &nbsp;12:23 PM</p>
                        </div>
                        <div className="col-2">
                            <p style={{ fontSize: "10px" }}> <FaCalendar />&nbsp;&nbsp;17-03-2023</p>
                        </div>
                        <div className="col-2">
                            <p style={{
                                fontSize: "10px",
                                borderRadius: "5px", paddingLeft: "16px", height: "20px", paddingTop: "2px", backgroundColor: "#ECFFEE", color: "#44CCA3"
                            }}>Status : Pass</p>
                        </div>
                    </div>

                    <p className="mt-3">Course  <span style={{ fontWeight: "bold" }}>B.Tech Spcl. in Health Informatics</span></p>
                    <p>Subject <span style={{ fontWeight: "bold" }}>Networking</span></p>

                    <div className='row mt-2'>
                        <div className="col-2" style={{ borderBottom: Catg === "details" ? "2px solid #8147E7" : "", width: "10%" }}>
                            <span className='col-1  ' onClick={() => { setvalue('details') }} >
                                <p style={{
                                    fontSize: "16px", fontWeight: "500", color: "white",
                                    backgroundColor: "#3D70F5", width: "100px", borderRadius: "5px", textAlign: "center", height: "30px", paddingTop: "2px"
                                }}>details</p>
                            </span></div>

                        <span className='col-1' onClick={() => { setvalue('res') }}>
                            <p style={{
                                fontSize: "16px", fontWeight: "500", color: "#7E7E7E",
                                backgroundColor: "#EEEFF9", width: "100px", borderRadius: "5px", marginLeft: "24px", textAlign: "center", height: "30px", paddingTop: "2px"
                            }}>Response</p>
                        </span>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            {
                                value === 'details' ? <>
                                    <div>
                                        <div className='mt-2'>Number of Questions : 50</div>
                                        <p className='mt-4'>Passing Percentage</p>
                                        <input type="range" style={{ width: "300px", backgroundColor: "red" }} />
                                        <p>Quiz Schedule</p>
                                        <div style={{ display: "flex" }}>
                                            <span style={{ fontSize: "13px", backgroundColor: "#EDEDF5", borderRadius: "1px" }}> <FaCalendar /> &nbsp; 10-03-2023</span>
                                            <span style={{ fontSize: "13px", backgroundColor: "#EDEDF5", borderRadius: "1px", marginLeft: "50px" }}><BiTimeFive />&nbsp; 01:55 PM</span>
                                        </div>

                                        <p className='mt-4'><b>Tags Composition</b></p>
                                        <p className='mt-2' style={{ fontSize: "13px" }}><b>Current Affairs</b></p>
                                        <input type="range" style={{ width: "300px" }} />

                                        <p className='mt-2' style={{ fontSize: "13px" }}><b>Logical Reasoning</b></p>
                                        <input type="range" style={{ width: "300px" }} />

                                        <p className='mt-2' style={{ fontSize: "13px" }}><b>Basic Computer</b></p>
                                        <input type="range" style={{ width: "300px" }} />

                                        <p><b>Rules</b></p>
                                    </div>

                                </> : <></>
                            }
                            {
                                value === 'res' ? <>
                                    <div className=''>
                                        {ss.map((res, key) => {
                                            return (


                                                <div className='mt-3'>
                                                    <p>Q1. Which of the following is not a networking protocol ?</p>
                                                    <p className='mt-1' style={{ border: "1px solid #DADADA", width: "37%", paddingLeft: "8px", borderRadius: "3px" }}>a. ACP</p>
                                                    <p className='mt-1' style={{ border: "1px solid #DADADA", width: "37%", paddingLeft: "8px", borderRadius: "3px" }}>b. DCP</p>
                                                    <p className='mt-1' style={{ border: "1px solid #0CC818", width: "37%", backgroundColor: "#EFFFF0", paddingLeft: "8px", borderRadius: "3px" }}>c. BCP</p>
                                                    <p className='mt-1' style={{ border: "1px solid #DADADA", width: "37%", paddingLeft: "8px", borderRadius: "3px" }}>d. CCP</p>
                                                </div>

                                            )
                                        })}

                                    </div>

                                </> : <></>
                            }



                        </div>
                    </div>

                </div>
            </div>


        </>
    );
}
