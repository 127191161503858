import React, { useState, useEffect } from 'react'; //api done
import { AiOutlineEye } from 'react-icons/ai';
import DatePicker from "react-datepicker";
import { BsFillCalendarFill } from "react-icons/bs";
import "react-datepicker/dist/react-datepicker.css"
import { RiFileExcel2Line } from 'react-icons/ri';
import { AuthContext } from './AuthContext';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL, img_url } from '../env';
import * as XLSX from 'xlsx';

export default function Allinvoce() {

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [req_str_date, setReqStartDate] = useState();
  const [req_end_date, setReqEndDate] = useState();
  const { apiToken } = useContext(AuthContext);
  const [students, setStudents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalstudents, setTotalstudents] = useState();
  const [studentFilter, setStudentFilter] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const convertToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Map the array to include only the desired properties
    const selectiveDataArray = students.map((student) => ({
      Name: student.name,
      Course: student.course_name,
      Fees: student.finalAmount,
      Batch: student.batch_name,
      Status: student.status == 1 ? "Dropped" : "Active",
    }));

    // Convert the selective data array into a worksheet
    const worksheet = XLSX.utils.json_to_sheet(selectiveDataArray);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a file name
    const fileName = 'Active_Students_data.xlsx';

    // Generate Excel file binary data
    const excelData = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Create a blob object from the binary data
    const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a download link
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;

    // Simulate a click to trigger the download
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
  };


  const getstudent = async (page) => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/student/list_students?page=${page}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        if (result.status == "001") {
          setStudents(result.list_students.data);
          setStudentFilter(result.list_students.data);
          setTotalPages(result.list_students.last_page)
          setTotalstudents(result.list_students.total);
        }
        else {
          setTotalstudents(0);
        }
      })
      .catch(error => console.log('error', error));
  }


  const FiterData = async (page) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var formdata = new FormData();
    formdata.append("start_date", req_str_date);
    formdata.append("end_date", req_end_date);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${API_URL}/student/filter_active_students?page=${page}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status == "001") {
          setStudentFilter(result.active_students.data);
          setTotalPages(result.active_students.last_page)
          setTotalstudents(result.active_students.total);
          setIsFilterApplied(true);
        }
        else if (result.status == "009") {
          toast(result.message);
        }
        else {
          toast(result.message + " " + ' please fill the correct date');
          setStudentFilter([]);
          setTotalPages(1)
          setTotalstudents(0);
          console.log(result)
        };
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    isFilterApplied ? FiterData(currentPage) : getstudent(currentPage);
  }, [currentPage, startDate, endDate])

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const calculateSerialNumber = (index) => {
    return (currentPage - 1) * 5 + (index + 1);
  };

  return (
    <>

      <ToastContainer />
      <div className="card mt-4 ms-4" style={{ width: "95%", border: "none" }}>
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <p style={{ fontWeight: "400", color: "#8A8A8A", fontSize: "18px" }}>Date Range</p>
              <div className="row mt-1">
                <div className="col-4">
                  <p style={{ color: "#8A8A8A", fontSize: "400" }}>From</p>
                  <div>
                    <span style={{
                      display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "0px",
                      borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
                    }}>
                      <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
                      <div>
                        <DatePicker className="border-0 ps-0 ps-2 datep"
                              selected={startDate}
                          dateFormat="yyyy/MM/dd"
                          onChange={(date) => {
                            const req_date = date.getFullYear() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getDate();
                            // console.log(req_date);
                            setReqStartDate(req_date);
                            setStartDate(date);

                          }
                          }
                          style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                      </div>
                    </span>
                  </div>
                </div>
                <div className="col-4">
                  <p style={{ color: "#8A8A8A", fontSize: "400" }}>To</p>
                  <div>
                    <span style={{
                      display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "0px",
                      borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
                    }}>
                      <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
                      <div>
                        <DatePicker className="border-0  ps-0 ps-2 datep"
                                  selected={endDate}
                          dateFormat="yyyy/MM/dd"
                          onChange={(date) => {
                            const req_date = date.getFullYear() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getDate();
                            setEndDate(date);
                            setReqEndDate(req_date);
                          }}
                          style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                      </div>
                    </span>
                  </div>
                </div>
                <div className='col-3 mt-3' >
                  <button className='btn' style={{ backgroundColor: "#8147E7", borderRadius: "5px", color: "#ffffff", fontWeight: "400", fontSize: "17px", }} onClick={() => FiterData()} >
                    <span className=''>Apply</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="col-3 mt-3">
            </div>
            <div className="col-3">
              <p style={{ fontSize: "18px", fontWeight: "400", color: "#8A8A8A" }}>Total Students</p>
              <p style={{ fontWeight: "500", fontSize: "20px", color: "#3C3C3C" }}>{totalstudents}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Invoice Details */}

      <div className="mt-5">
        <div className='d-flex'>
          <p className="" style={{ color: "#3C3C3C", fontSize: '22px', fontWeight: "600", paddingLeft: "30px" }}>Active Student Details</p>
          <button className=' ' style={{
            backgroundColor: "#8147E7", borderRadius: "5px", width: "180px", height: "50px", border: "none", marginLeft: "48.6rem",
            color: "#ffffff", fontWeight: "400", fontSize: "20px"
          }} onClick={() => { convertToExcel() }} ><RiFileExcel2Line style={{ fontSize: "20px" }} /> &nbsp;&nbsp;Export Data</button>
        </div>
        <div className='mt-3 '>
          <div className='row mx-4 mt-3'  >
            <div className='row' style={{ color: "#434343", fontWeight: "600", fontSize: "15px" }}>
              <div className='col-1'>
                <p>S.No.</p>
              </div>
              <div className='col-3'>
                <p>Name</p>
              </div>
              <div className='col-2'>
                <p>Course</p>
              </div>
              <div className='col-1'>
                <p>Batch</p>
              </div>
              <div className='col-2'>
                <p>Fees</p>
              </div>
              <div className='col-2'>
                <p>Status</p>
              </div>
            </div>
            <div style={{ overflowY: "scroll", height: "60vh" }}>
              {
                studentFilter.length === 0 ? <><div className='mt-5' style={{ justifyContent: "center", alignItem: "center", width: "100%", display: "flex", height: "100%", color: "red" }}><h6>No Data Available</h6></div></> :
                  studentFilter?.map((res, index) => {
                    return (
                      <>
                        {
                          <div className='row align-items-center  mt-3' style={{ width: "100%", borderRadius: "5px", height: "55px", backgroundColor: " #FFFFFF" }} >
                            <div className='col-1' key={res.id} >
                              <p style={{ color: "#434343", fontWeight: "400", fontSize: "15px" }}>{calculateSerialNumber(index)}</p>
                            </div>
                            <div className='col-3'>
                              <p style={{ fontWeight: "400", fontSize: "15px", textTransform: "capitalize" }}>{res.name}</p>
                            </div>
                            <div className='col-2'>
                              <p style={{ fontSize: "15px", color: "#3C3C3C", fontWeight: "500" }} >{res.course_name}</p>
                            </div>
                            <div className='col-1'>
                              <p style={{ fontSize: "15px", color: "#434343", fontWeight: "400" }} >{res.batch_name}</p>
                            </div>
                            <div className='col-2'>
                              <p style={{ fontSize: "15px", color: "#434343", fontWeight: "400" }} >{res.finalAmount}</p>
                            </div>
                            <div className='col-1'>
                              <p style={{ fontSize: "15px", fontWeight: "600", color: 'green' }} >Acitve</p>
                            </div>
                            <div className='col-2'>
                              <NavLink to={`/Profilestudents/${res.id}`} style={{ textDecoration: "none", fontSize: "15px", color: "#8147E7", fontWeight: "400", backgroundColor: "#F2EAFF", padding: '8px 20px', width: "auto", borderRadius: "5px", border: "none" }} ><AiOutlineEye className='me-2' style={{ fontSize: "20px" }} />View Detail</NavLink>
                            </div>
                          </div>
                        }
                      </>
                    );
                  })
              }

              {
                studentFilter.length === 0 ? <></> : <div className='col-lg-12 col-md-12 col-sm-12 text-center my-3 ps-5 ms-5 fixed-bottom'>
                  <button className='py-1 px-3 btn' style={{ backgroundColor: "#8147E7", borderRadius: "5px", color: "#ffffff", fontWeight: "400", fontSize: "18px" }} disabled={currentPage === 1} onClick={handlePreviousPage}>
                    <span className=''>Back</span>
                  </button>
                  <span className='mx-2'>{currentPage}</span> / <span className='mx-2'>{totalPages}</span>
                  <button className='py-1 px-3 btn' style={{ backgroundColor: "#8147E7", borderRadius: "5px", color: "#ffffff", fontWeight: "400", fontSize: "18px" }} disabled={currentPage === totalPages} onClick={handleNextPage}>
                    <span className='' >Next</span>
                  </button>
                </div>
              }
            </div>

          </div>
        </div>
      </div>
    </>
  )
}