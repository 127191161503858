import React, { useState } from 'react';
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { IoPersonOutline } from "react-icons/io5";
import { IoNewspaper } from "react-icons/io5";
import { AiOutlineCalendar } from 'react-icons/ai'
import EE from '../images/attendance analytics.png';
import EE1 from '../images/iconu.png';
import EE2 from '../images/ic_outline-log-in.png';
import EE3 from '../images/ic_outline-log-in (1).png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsFillCalendarFill } from "react-icons/bs";
import { IoNewspaperOutline } from "react-icons/io5";

export default function General() {

    const [startDate, setStartDate] = useState(new Date());
    const [startDate1, setStartDate1] = useState(new Date());

    return (
        <>

            <div className='mt-4 ps-4'>
                <div className='row'>
                    <div className='col-4'>
                        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "24px", color: "#434343", }}>Attendance Report</p>
                        <div className=' mt-5'>
                            <img src={EE} style={{ background: "#FFFFFF", border: "1px solid #E9E9E9", borderRadius: "10px", width: "100%", }} />
                        </div>
                    </div>
                    <div className='col-7'>
                        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "24px", color: "#434343", marginLeft: "50px", }}> Attendance Logs</p>



                        <div className='row mt-3 '>

                            <div className='col-4 ' >
                                <div className='' style={{ display: "flex" }}>
                                    <p className='pt-3 ' style={{ color: "#434343", fontWeight: "500", fontSize: "17px", paddingLeft: "27px" }} >From</p>
                                    <span style={{
                                        display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "10px",
                                        borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
                                    }}>
                                        <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
                                        <div>
                                            <DatePicker className="border-0  datep"
                              
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                                        </div>
                                    </span>
                                    <p className='pt-3 ps-3' style={{ color: "#434343", fontWeight: "500", fontSize: "17px" }}>to</p>
                                </div>
                            </div>

                            <div className='col-2' style={{ marginLeft: "-24px" }} >
                                <div>
                                    <span style={{
                                        display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "10px",
                                        borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
                                    }}>
                                        <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
                                        <div>
                                            <DatePicker className="border-0  datep"
                                 
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div className='col-3' style={{ marginLeft: "37px", marginTop: "-7px", }}>
                                <button type="button" class="btn btn-success"><IoNewspaperOutline /> Export Data</button>
                            </div>
                        </div>


                        <div className='mt-3 ps-3' style={{ overflowY: "scroll", height: "40vh", width: "100%", }}>
                            <div style={{ width: "90%" }}>
                              
                                <div className='row mt-2' style={{ background: "#FFFFFF", border: "1px solid #EDEDED", borderRadius: "5px", }}>
                                    <div className='col-2'>
                                        <img src={EE1} style={{ background: "#FFFFFF", borderRadius: "70px", width: "45%", padding: "2px", }} />
                                    </div>
                                    <div className='col-4'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Vishaka Shekhawat</p>
                                    </div>
                                    <div className='col-3'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>
                                            <span><img src={EE3} style={{ width: "10%", }} /></span> 12:30</p>
                                    </div>
                                    <div className='col-2'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>12 May 2023</p>
                                    </div>
                                </div>
                                <div className='row mt-2' style={{ background: "#FFFFFF", border: "1px solid #EDEDED", borderRadius: "5px", }}>
                                    <div className='col-2'>
                                        <img src={EE1} style={{ background: "#FFFFFF", borderRadius: "70px", width: "45%", padding: "2px", }} />
                                    </div>
                                    <div className='col-4'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Vishaka Shekhawat</p>
                                    </div>
                                    <div className='col-3'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>
                                            <span><img src={EE2} style={{ width: "10%", }} /></span> 12:30</p>
                                    </div>
                                    <div className='col-2'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>12 May 2023</p>
                                    </div>
                                </div>
                                <div className='row mt-2' style={{ background: "#FFFFFF", border: "1px solid #EDEDED", borderRadius: "5px", }}>
                                    <div className='col-2'>
                                        <img src={EE1} style={{ background: "#FFFFFF", borderRadius: "70px", width: "45%", padding: "2px", }} />
                                    </div>
                                    <div className='col-4'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Vishaka Shekhawat</p>
                                    </div>
                                    <div className='col-3'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>
                                            <span><img src={EE2} style={{ width: "10%", }} /></span> 12:30</p>
                                    </div>
                                    <div className='col-2'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>12 May 2023</p>
                                    </div>
                                </div>
                                <div className='row mt-2' style={{ background: "#FFFFFF", border: "1px solid #EDEDED", borderRadius: "5px", }}>
                                    <div className='col-2'>
                                        <img src={EE1} style={{ background: "#FFFFFF", borderRadius: "70px", width: "45%", padding: "2px", }} />
                                    </div>
                                    <div className='col-4'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Vishaka Shekhawat</p>
                                    </div>
                                    <div className='col-3'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>
                                            <span><img src={EE3} style={{ width: "10%", }} /></span> 12:30</p>
                                    </div>
                                    <div className='col-2'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>12 May 2023</p>
                                    </div>
                                </div>
                                <div className='row mt-2' style={{ background: "#FFFFFF", border: "1px solid #EDEDED", borderRadius: "5px", }}>
                                    <div className='col-2'>
                                        <img src={EE1} style={{ background: "#FFFFFF", borderRadius: "70px", width: "45%", padding: "2px", }} />
                                    </div>
                                    <div className='col-4'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Vishaka Shekhawat</p>
                                    </div>
                                    <div className='col-3'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>
                                            <span><img src={EE2} style={{ width: "10%", }} /></span> 12:30</p>
                                    </div>
                                    <div className='col-2'>
                                        <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>12 May 2023</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



        </>
    );
}



