import React, { useEffect, useState } from "react";
import { API_URL } from "../../env";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Loader } from "../Loader";
import { ToastContainer, toast } from 'react-toastify';

const StudentReceived = () => {
  const Navigate = useNavigate();
  const [received, setReceived] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getReceived = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };
      setIsLoading(true)
      fetch(
        `${API_URL}/admin/acount/received/amount/with/batch`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setIsLoading(false)
          if (result.status == 1) {
            setReceived(result.batches);
          } 
          else if (result.status === 'VAL_ERR') {
            toast(result.Backend_Error)
          }
        else if (result.status == 'FILE_ERR') {
            toast(result.Backend_Error)
          }
         else if (result.status == "CUSTOM_ERR") {
            toast(result.Backend_Error);
          } else if (result.status == "TOKEN_ERR") {
            localStorage.removeItem('api_url');
            Navigate('/code')
            toast("Logged in other device! Please login again")
          }
          else {
            console.log(result)
          }
        })
        .catch((error) => console.log("error", error));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getReceived();
  }, [])
  

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-2  bg-white sid"
            style={{ backgroundColor: "#fff", height: "100vh" }}
          >
            <p
              className=" d-flex  align-items-center mt-3 gap-2"
              style={{
                fontWeight: "600",
                color: "#3C3C3C",
                fontSize: "1rem",
              }}
            >
              {" "}
              <BsArrowLeft
                onClick={() => Navigate(-1)}
                style={{
                  color: "#000000",
                  fontSize: "1.2rem",
                  cursor: "pointer",
                }}
              />{" "}
              Received Amount
            </p>
          </div>
          <div className="col-10 m-0 p-0">
            {isLoading ? (
              <Loader show={isLoading} />
            ) : (
              <>
                <div
                  className=""
                  style={{ height: "100vh", overflowY: "scroll" }}
                >
                  <DataTable
                  paginator
                  rows={10}
                    value={received}
                    tableStyle={{ minWidth: "50rem" }}
                  >
                    <Column
                      field="id"
                      header="Id"
                      style={{
                        width: "10.6%",
                        padding: "0.5rem 1rem",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                    ></Column>
                    <Column
                      field="batch_name"
                      header="Batch Name"
                      style={{
                        width: "17.8%",
                        padding: "0.5rem 1rem",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      bodyStyle={{ fontWeight: 500 }}
                    ></Column>
                    <Column
                      field="students"
                      header="Students"
                      style={{
                        width: "17.8%",
                        padding: "0.5rem 1rem",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      bodyStyle={{ fontWeight: 500 }}
                    ></Column>
                    <Column
                      field="amount"
                      header="Amount"
                      style={{
                        width: "17.8%",
                        padding: "0.5rem 1rem",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      body={(rowData) => (
    <span style={{ fontWeight: 500 }}>
      ₹ {rowData.amount}
    </span>
  )}
                      bodyStyle={{ fontWeight: 500 }}
                    ></Column>
                    <Column
                      body={(rowData)=>(
                        <button onClick={()=>Navigate(`/Accounts/student/received/${rowData.id}`)} className="border-0 p-2" style={{backgroundColor: "#8147E7",
                  color: "white",borderRadius:"5px",fontWeight:500}}>View Details</button>
                      )}
                      header="Action"
                      style={{
                        width: "17.8%",
                        padding: "0.5rem 1rem",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      bodyStyle={{ fontWeight: 500 }}
                    ></Column>
                  </DataTable>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>
  );
};

export default StudentReceived;
