import React, { useState, useEffect } from 'react';


import Profileemtopbar from './Profileemtopbar';
import Salarytopbar from './Salarytopbar';
import Attendanceemptopbar from './Attendanceemptopbar';
import { IoArrowBackSharp } from "react-icons/io5";
import { NavLink } from 'react-router-dom';
import EE from '../images/Rectangle (1).png';
import EEi from '../images/outline.png';
import EEi1 from '../images/Group 13160.png';
import EEi2 from '../images/Group 13161.png';
import Dates from '../images/Vector.png';
import lotti from '../images/129053-thumbs-up-birdie.json';
import Lottie from "lottie-react";
import { IoPerson } from "react-icons/io5";
import { AiOutlineCloudUpload } from "react-icons/ai";
import DatePicker from "react-datepicker";
import { AiOutlineCalendar } from 'react-icons/ai';
import "react-datepicker/dist/react-datepicker.css";
import "./test.css";
import { AiFillCalendar } from 'react-icons/ai';
import Taskstopbar from './Taskstopbar';

const Profile = () => {
  const [value, setvalue] = useState('');
  const [Catg, setCatg] = useState("");
  const [Images, setImages] = useState();
  const [Times, setTimes] = useState();
  const [Sedule, setSedule] = useState();
  const [Banner, setBanner] = useState();
  const [startDate, setStartDate] = useState(new Date());
  // console.log(Catg);

  const [count, setcount] = useState(1);

  useEffect(() => {
    setvalue('Taskstopbar')
  }, []);

  return (
    <div>
      <div className='row'>
        <div className='col-5'>
          <p style={{ fontWeight: "700", fontSize: "20px", margin: "40px" }}><NavLink to="/dashboard"><IoArrowBackSharp style={{ fontSize: "25px", color: "#000", }} /></NavLink> My Profile</p>
        </div>
        <div className='col-4'></div>
        <div className='col-1'>
          {/* <button type="button" class="btn btn-primary" style={{marginTop:"50px",color:"#FF0000",background:"#FFFFFF",border:"1px solid #FE0000",borderRadius:"7px",}}>Remove</button> */}
        </div>
        <div className='col-2'>
          {/* <button type="button" class="btn btn-primary" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample1" aria-controls="offcanvasExample1" style={{marginLeft: "-20px",marginTop:"50px",background:"#FFFFFF",border:"1px solid #000000",borderRadius:"7px",color:"#000000",}}>+Assign Task</button> */}
        </div>
      </div>



      <div className='row'>
        <div className='col-4'>
          <div className='row '>
            <div className='col-3 mt-3'>
              <img src={EE} style={{ marginLeft: '20px', borderRadius: '50px', width: '80%', border: '1px solid #808080', }} />
            </div>
            <div className='col-8 '>
              <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "500", fontSize: "20px", color: "#000000", }}>Vishaka Shekhawat</p>
              <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#8A8A8A", }}>Content Curator <span style={{ background: "#E3FFF3", padding: "2px 10px", borderRadius: "4px", color: "#2E8760", }}> Online</span></p>
              <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#2E8760", }}>Logged in Since 8:30 | 23 Sep 2022</p>
            </div>
          </div>
        </div>
        <div className='col-4'>
          <div className='mt-2'>
            <span><img src={EEi} style={{ marginLeft: '10px', borderRadius: '20px', width: '5%', border: '1px solid #EFEFEF', background: "#EFEFEF", }} /> <span style={{ color: "#8A8A8A", fontStyle: "normal", fontWeight: "400", fontSize: "12px", }}>Joined 23-09-2022 Copy </span></span>
          </div>
          <div className='row mt-2'>
            <div className='col-5 '>
              <span><img src={EEi1} style={{ marginLeft: '10px', width: '16%', }} /> <span style={{ color: "#8A8A8A", fontStyle: "normal", fontWeight: "400", fontSize: "16px", }}> Assigned <span style={{ color: "#CC1313", }}> 966</span> </span> </span>
            </div>
            <div className='col-6'>
              <span><img src={EEi2} style={{ marginLeft: '10px', width: '13%', }} /> <span style={{ color: "#8A8A8A", fontStyle: "normal", fontWeight: "400", fontSize: "16px", }}> Completed  <span style={{ color: "#2E8760", }}> 852</span> </span> </span>
            </div>
          </div>
        </div>
        <div className='col-4'>
          <div className='dard' style={{ width: "367px", height: "143px", background: "#F2EDFD", borderRadius: "5px", }}>
            <p className='ps-3 py-2' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "20px", color: "#3C3C3C", }}>Efficiency Score</p>
            <div className='row' mt-3>
              <div className='col-6'>
                <p className='ps-3 mt-2' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "48px", color: "#8147E7", }}>85%</p>
              </div>
              <div className='col-6'>
                <p className='ps-3 mt-4' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "24px", color: "#3EDC4E", }}>85<span style={{ color: "#3C3C3C", }}>/100</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div className='row ' style={{ display: "flex", alignItems: 'center', borderBottom: '1px solid #E4E4E4', paddingLeft: "30px" }}>


        <span className='col-1 py-3' onClick={() => { setvalue("Taskstopbar") }} style={{
          borderBottomColor: "red", borderBottom: value == "Taskstopbar" ? "2px solid blue" : "", color: value == "Taskstopbar" ? "blue" : "", marginTop: "14px", width: "5%", cursor: "pointer",
        }}>
          <p>Tasks</p>
        </span>


        <span className='col-1 py-3' onClick={() => { setvalue('Profileemtopbar') }} style={{ borderBottom: value == "Profileemtopbar" ? "2px solid blue" : "", color: value == "Profileemtopbar" ? "blue" : "", marginTop: "14px", width: "5%", marginLeft: "20px", cursor: "pointer", }}>
          <p >Profile</p>
        </span>

        <span className='col-1 py-3' onClick={() => { setvalue('Salarytopbar') }} style={{ borderBottom: value == "Salarytopbar" ? "2px solid blue" : "", color: value == "Salarytopbar" ? "blue" : "", marginTop: "14px", width: "5%", marginLeft: "20px", cursor: "pointer", }}>
          <p style={{}}>Salary</p>
        </span>

        <span className='col-1 py-3' onClick={() => { setvalue('Attendanceemptopbar') }} style={{ borderBottom: value == "Attendanceemptopbar" ? "2px solid blue" : "", color: value == "Attendanceemptopbar" ? "blue" : "", marginTop: "14px", width: "7%", marginLeft: "20px", cursor: "pointer", }}>
          <p style={{}}>Attendance</p>
        </span>
        {/* <span className='col-1' onClick={()=>{setvalue('Classes')}}style={{borderBottom:value=="Classes"?"2px solid blue":""}}>
                    <p style={{}}>Classes</p>
        </span>
        <span className='col-1' onClick={()=>{setvalue('Fees')}} style={{borderBottom:value=="Fees"?"2px solid blue":""}}>
                    <p style={{}}>Fees</p>
        </span> */}

      </div>

      <div className='row'>
        <div className='col-12'>
          {
            value === 'Taskstopbar' ? <><Taskstopbar /></> : <></>
          }
          {
            value === 'Profileemtopbar' ? <><Profileemtopbar /></> : <></>
          }
          {
            value === 'Salarytopbar' ? <><Salarytopbar /></> : <></>
          }
          {
            value === 'Attendanceemptopbar' ? <><Attendanceemptopbar /></> : <></>
          }




        </div>
      </div>



      {/* first modal */}

      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample1" aria-labelledby="offcanvasExampleLabel" style={{ width: "40%", }}>
        <div class="offcanvas-header">
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { setcount(1) }}></button>
        </div>
        <div class="offcanvas-body">

          <div style={{ display: count == 1 ? "block" : "none" }}>

            <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "25px", color: "#474747", }}>Assign Task</p>
            <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#474747", }}><IoPerson /> Vishakha Shekhwat</p>

            <input type="text" className='mt-3' placeholder='Task Title' style={{ background: "#F5F5F5", borderRadius: "10px", width: "100%", border: "none", padding: "7px 9px", }} />
            <p style={{ float: "right", fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#848484", }}>0/100</p>
            <div className='mt-3'>
              <textarea placeholder='Task Description' style={{ background: "#F5F5F5", borderRadius: "10px", border: "none", width: "100%", padding: "7px 9px", height: "161px", }}>

              </textarea>
              <p style={{ float: "right", fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#848484", }}>0/1000</p>
            </div>
            <div>
              <div className='mt-4' style={{ width: '40%', height: 'auto' }}>
                <label className="filelabel1 w-100 ps-3 pt-2" style={{ background: "#FFFFFF", border: "1px solid #000000", borderRadius: "5px", color: "#000000", height: "3rem", }}>
                  <span className="title1" >
                    <h6 className='d-flex pt-1' src={Images} style={{ width: "100%", textAlign: 'center', color: "#000000", }}> <AiOutlineCloudUpload className='mx-4' /> Add Attachments </h6>
                  </span>
                  <input className="FileUpload1" id="FileInput1" name="booking_attachment1" type="file" accept='.png,.jpg' onChange={(e) => { setImages(URL.createObjectURL(e.target.files[0])); setBanner(e.target.files[0]) }} style={{ opacity: '0' }} />
                </label>
              </div>




              <div className='col-9 mt-5  d-flex' style={{ background: "#FFFFFF", border: "1px solid #000000", borderRadius: "5px", padding: "4px 2px", }}>
                <span className='mt-1 ps-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#000000", }}>Due Date</span>

                <span className="col-4 ms-2 d-flex rounded" style={{ backgroundColor: "#EFEFEF", overflow: "hidden", height: "35px" }}>
                  <img className="" src={Dates} alt="#" style={{ width: "30%", padding: "3px", }} />

                  <DatePicker className="Main" dateFormat="yyyy/MM/dd" selected={startDate} onChange={(date: Date) => {
                    setStartDate(date);
                    setSedule(date.getDate() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getFullYear());
                  }} />
                </span>
                <span className="col-4 ms-4 rounded" style={{ backgroundColor: "#EFEFEF", height: "35px" }}>
                  <input className="border-0 rounded mt-1" type="time" style={{ backgroundColor: "#EFEFEF", color: "#8A8A8A", outline: "none" }} value={Times} onChange={(e) => { setTimes(e.target.value) }} />
                </span>
              </div>







              <div></div>

              <div className='text-center mt-5' >
                <button type="button" class="btn btn-primary" style={{ width: "50%", }} onClick={() => { setcount(count + 1) }}>Assign Task</button>
              </div>

            </div>

          </div>




          {/* second modal start */}

          <div style={{ display: count === 2 ? "block" : "none" }}>
            <div className='text-center'>
              <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "28px", textAlign: "center", color: "#474747", }}>Following Task will be assigned to</p>
              <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#474747", }}><IoPerson /> Vishakha Shekhwat</p>
              <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#000000", }}>Due Date &nbsp;&nbsp;&nbsp;<span>12-01-2023</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span>18:30</span></p>
            </div>

            <div className='card '>
              <p className='ps-3 mt-3' style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#989898", }}>Task Title</p>
              <p className='ps-3 ' style={{ fontStyle: "normal", fontWeight: "500", fontSize: "18px", color: "#474747", }}>Assign a Quiz to 3CO-JVY batch for their Upcoming NEET Exam. </p>
              <p className='ps-3 mt-3' style={{ fontStyle: "normal", fontWeight: "500", fontSize: "13px", color: "#989898", }}>Task Description</p>
              <div style={{ overflowY: "scroll", height: "40vh", }}>
                <p className='ps-3 px-3 ' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "12px", color: "#696969", }}>The objective of this task is to create a comprehensive mock test quiz for Batch 3CO JVY, which is preparing for the National Eligibility cum Entrance Test (NEET). The quiz should cover various subjects and topics that are part of the NEET syllabus and provide an opportunity for students to assess their knowledge and skills in preparation for the actual NEET exam.

                  Scope of Work: The scope of work for this task includes the following:

                  Content Creation: The quiz should cover all the relevant subjects and topics that are part of the NEET syllabus, including but not limited to, Physics, Chemistry, Biology, and Mathematics. The quiz questions should be created in a manner that reflects the difficulty level and format of the actual NEET exam.
                  Question Bank Development: A question bank should be developed, which includes a diverse set of questions covering different concepts and difficulty levels. The question bank should be designed to provide a balanced coverage of the NEET syllabus and should be reviewed and approved by subject matter experts to ensure accuracy and relevance.
                  Quiz Format: The quiz should be created in a digital format that is easily accessible and user-friendly for students. It should include multiple choice questions (MCQs) with options, and may also include other types of questions such as fill in the blanks, true/false, and matching.
                  Test Duration and Marking Scheme: The quiz should have a predefined time duration, preferably similar to the actual NEET exam duration, and a marking scheme should be defined for evaluating the students' performance. The marking scheme should be fair and transparent, and should be communicated to the students before they attempt the quiz.
                  Feedback and Analysis: The quiz should provide feedback to students on their performance, including correct answers and explanations for each question. Additionally, an analysis of students' performance, such as their score, percentile, and comparison with other students, should be provided to help students identify their strengths and weaknesses.
                  Customization: The quiz should be customized for Batch 3CO JVY, taking into consideration the specific requirements and preferences of the students. It should be aligned with their current level of preparation and should be challenging enough to help them improve their knowledge and skills.
                  Testing and Quality Assurance: The quiz should undergo rigorous testing and quality assurance to ensure that it is free from errors and glitches. It should be thoroughly reviewed by subject matter experts for accuracy, consistency, and relevance to the NEET syllabus.
                  Deliverables: The following deliverables are expected from the task:
                  A NEET mock test quiz customized for Batch 3CO JVY, covering all relevant subjects and topics, with a diverse set of questions, and a predefined time duration and marking scheme.
                  A question bank developed and reviewed by subject matter experts, which includes a balanced coverage of the NEET syllabus.
                  A digital quiz format that is user-friendly and accessible for students, with feedback and analysis features.
                  Detailed documentation of the quiz, including instructions for students, marking scheme, and customization details.
                  Testing and quality assurance reports, including identification and resolution of any errors or glitches.
                  Timeline: The timeline for this task will be as follows:
                  Content Creation and Question Bank Development: 1 week
                  Quiz Format Development: 1 week
                  Customization and Testing: 1 week
                  Feedback and Analysis Features Development: 1 week
                  Documentation and Finalization: 1 week
                  Total estimated time: 5 weeks
                  Conclusion: Creating a comprehensive NEET mock test quiz for Batch 3CO JVY is an important task to help students assess their knowledge and skills in preparation for the actual NEET exam.</p>
              </div>

              <div className='row mt-2 ps-3 px-3'>
                <div className='col-3'>
                  <p className='text-center mb-2' style={{ background: "#FFFFFF", border: "1px solid #DBDBDB", borderRadius: "5px", fontStyle: "normal", fontWeight: "400", fontSize: "13px", color: "#434343", }}>revised_quiz.pdf</p>
                </div>
                <div className='col-3'>
                  <p className='text-center mb-2' style={{ background: "#FFFFFF", border: "1px solid #DBDBDB", borderRadius: "5px", fontStyle: "normal", fontWeight: "400", fontSize: "13px", color: "#434343", }}>revised_quiz.pdf</p>
                </div>
                <div className='col-3'>
                  <p className='text-center mb-2' style={{ background: "#FFFFFF", border: "1px solid #DBDBDB", borderRadius: "5px", fontStyle: "normal", fontWeight: "400", fontSize: "13px", color: "#434343", }}>revised_quiz.pdf</p>
                </div>
                <div className='col-3'>
                  <p className='text-center mb-2' style={{ background: "#FFFFFF", border: "1px solid #DBDBDB", borderRadius: "5px", fontStyle: "normal", fontWeight: "400", fontSize: "13px", color: "#434343", }}>revised_quiz.pdf</p>
                </div>
              </div>

              <div className='text-center mt-3 mb-3' >
                <button type="button" class="btn btn-primary" style={{ width: "50%", }} onClick={() => { setcount(count + 1) }}>Confirm</button>
              </div>
            </div>

          </div>
          {/* second modal end */}




          {/* third slider start */}

          <div style={{ display: count === 3 ? "block" : "none" }}>
            <div className='text-center'>
              <p className='mt-5' style={{ fontStyle: "normal", fontWeight: "500", fontSize: "36px", textAlign: "center", color: "#474747", }}>1 Task assigned Successfully<br /> to Vishakha Shekhawat</p>
            </div>

            <div className='d-flex' style={{ justifyContent: "center", alignItems: "center" }}>
              <Lottie animationData={lotti} style={{ width: "40%" }} />
            </div>

            <div className='text-center mt-3 mb-3' >
              <button type="button" class="btn btn-primary" data-bs-dismiss="offcanvas" aria-label="Close" style={{ width: "50%", }} onClick={() => { setcount(1) }}>Back to Dashboard</button>
            </div>




          </div>
          {/* third slider end */}
        </div>
      </div>










    </div>
  );
}

export default Profile;
