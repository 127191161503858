import React, { useState, useEffect } from 'react';
import Header from './header';
import SideBar from './sidebar';
import Report from './report';
import Transactions from './transactions';
import Credit from './credit';
import Debit from './debit';
import Upcoming from './upcoming';
import Due from './due';
import { NavLink} from 'react-router-dom';
import { FiUpload } from "react-icons/fi";

export default function Accounts() {

  const [value55, setvalue55] = useState();

  useEffect(() => {
    setvalue55('Report')
  }, [])


  return (
    <div style={{ width: '100%', height: '100vh', }}>
      <div className='head' style={{ width: '80%', height: '14vh', marginLeft: '16%' }}>
        <Header />
      </div>
      <div className='' style={{ width: '100%', height: '86vh', display: "flex" }}>
        <div style={{ width: '20%', height: '100%', position: 'absolute', top: 0, marginLeft: '1%' }} >
          <SideBar />
        </div>
        <div className='maincontain' style={{ marginLeft: '16%', width: '100%', height: '100%', overflowY: 'scroll', }} >
          {/* tab all  */}
          <div className='row'>
            <button className='col-1 ms-4 pt-2' onClick={() => { setvalue55('Report') }}
              style={{ display: "flex", backgroundColor: "transparent", border: "none", borderBottom: value55 == "Report" ? "2px solid #8147E7" : "none", width: "80px" }}>
              <p style={{ zIndex: '999' }}>Report</p></button>

            <button className='col-1' onClick={() => { setvalue55('Transactions') }}
              style={{ backgroundColor: "transparent", border: "none", borderBottom: value55 == "Transactions" ? "2px solid #8147E7" : "none", width: "110px" }}>
              <p>Transactions</p>
            </button>

            <button className='col-1' onClick={() => { setvalue55('Credit') }}
              style={{ backgroundColor: "transparent", border: "none", borderBottom: value55 == "Credit" ? "2px solid #8147E7" : "none", width: "110px" }}>
              <p>Credit</p>
            </button>

            <button className='col-1 ' onClick={() => { setvalue55('Debit') }}
              style={{ backgroundColor: "transparent", border: "none", borderBottom: value55 == "Debit" ? "2px solid #8147E7" : "none", width: "110px" }}>
              <p>Debit</p>
            </button>

            <button className='col-1' onClick={() => { setvalue55('Upcoming') }}
              style={{ backgroundColor: "transparent", border: "none", borderBottom: value55 == "Upcoming" ? "2px solid #8147E7" : "none", width: "110px" }}>
              <p>Upcoming</p>
            </button>

            <button className='col-1 ' onClick={() => { setvalue55('Due') }}
              style={{ backgroundColor: "transparent", border: "none", borderBottom: value55 == "Due" ? "2px solid #8147E7" : "none", width: "110px" }}>
              <p>Due</p>
            </button>
            <div className='col-1'>

            </div>
            <div className='col-1 ms-3' >
              <NavLink to="/Expense"><button style={{ backgroundColor: "#F4EEFF", color: "#8147E7", fontWeight: "400", border: "none", width: "113px", height: "39px", borderRadius: "5px" }}>Expenses</button></NavLink>
            </div>

            <div className='col-1 ms-3 '>
              <NavLink to="/Invoiesa">  <button style={{ backgroundColor: "#FFFFFF", color: "#8147E7", width: "113px", height: "39px", borderRadius: "5px", border: "2px solid #8147E7", fontWeight: "400" }}>Invoices</button></NavLink>
            </div>

            <div className='col-2 pb-2 ms-3 '>
              <button style={{ backgroundColor: "#8147E7", border: "none", width: "164px", height: "39px", borderRadius: "5px", color: "#ffffff", fontWeight: "400" }}>
                <FiUpload style={{ fontSize: "20px" }} />&nbsp;&nbsp;&nbsp;Export Data</button>
            </div>
            <div className='col-1'>
            </div>
            <div className='' style={{ border: "1px solid #DDDDDD", width: "94.5%" }}>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-12 '>
              {
                value55 === 'Report' ? <><Report /></> : <></>
              }
              {
                value55 === 'Transactions' ? <><Transactions /></> : <></>
              }
              {
                value55 === 'Credit' ? <><Credit /></> : <></>
              }
              {
                value55 === 'Debit' ? <><Debit /></> : <></>
              }
              {
                value55 === 'Upcoming' ? <><Upcoming /></> : <></>
              }
              {
                value55 === 'Due' ? <><Due /></> : <></>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

