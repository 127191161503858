import React, { useEffect, useState, useRef } from "react";//api done
import Header from './header';
import SideBar from './sidebar';
import { ImBooks } from 'react-icons/im';
import { FiSearch } from "react-icons/fi";
import { MdTableChart } from "react-icons/md";
import { duration, tableHeadClasses } from "@mui/material";
import { HiArrowLeft } from 'react-icons/hi';
import { NavLink, useNavigate } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
import { AiOutlineEye } from 'react-icons/ai';
import { IoArrowForwardOutline } from "react-icons/io5";
// import ToastContainer from "react-bootstrap/ToastContainer";
// import Toast from "react-bootstrap/Toast";
// import { API_URL } from './MyComponent';
import Lode from "./Lode";
import { click } from "@testing-library/user-event/dist/click";
import { AuthContext } from "./AuthContext";
import { useContext } from "react";
import { ToastContainer as Toaster, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// const {API_URL,img_url} =env;
import { API_URL, img_url } from '../env';
import { Loader } from "./Loader";

export default function Course() {

  const [count1, setcount1] = useState(1)
  const [Course, setCourse] = useState([])
  const [AddSubject, setAddSubject] = useState([])
  const [ViewCourse, setViewCourse] = useState([])
  const [value, setvalue] = useState("");
  const [CheckBox, setCheckBox] = useState(true)
  const [First, setFirst] = useState();
  const [dataId, setdataId] = useState([]);
  const [dataArray1, setdataArray1] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [Bg, setBg] = useState(false);
  const [check, setcheck] = useState();
  const [Duration, setDuration] = useState();
  const [pune, setpune] = useState(false);
  const [pune1, setpune1] = useState(false);
  const { apiToken } = useContext(AuthContext);
  const [courseName, setCourseName] = useState();
  const [totalBatches, setTotalBatches] = useState();
  const [totalstudents, setTotalstudents] = useState();
  const checkboxRef = useRef(true);
  const [isloading, setIsLoading]= useState(false);
  const {Navigate} = useNavigate();


  //function for getting data for all courses
  const ApiForCourse = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/admin/course/read/list/of/courses`, requestOptions)
      .then(response => response.json())
      .then(result => {

        if (result.status == "1") {
          console.log(result, 'result after adding course')
          setCourse(result.courses);
          setLoader(true);
        }
        else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }
      })
      .catch(error => console.log('error', error));
  }

  const handelChange = () => {

    if (value.length >= 1) {
      setcount1(count1 + 1);
    }
    else {
      setBg(true)
    }
  }

  const handelChange1 = () => {

    if (check.length >= 1 || Duration.length >= 1) {
      setcount1(count1 + 1);
    }
    else {
      // setpune(true);
      setpune1(true);
    }

  }

  const handleChange1 = (e) => {

    var updatedList1 = [...dataArray1];
    var idUpdate = [...dataId]

    if (e.target.checked) {
      updatedList1 = [...dataArray1, e.target.value];
      idUpdate = [...dataId, e.target.id]
    } else {
      updatedList1.splice(dataArray1.indexOf(e.target.value), 1);
      idUpdate.splice(dataId.indexOf(e.target.id), 1);
    }
    setdataArray1(updatedList1);
    setdataId(idUpdate);
  }

  const ApiForAddSubject = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/admin/subject/read/subject`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        if (result.status == "001") {
          setAddSubject(result.allSubject);
        }
        else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }

      })
      .catch(error => console.log('error', error));
  }

  const ApiForViewCourse = async (ID) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    // var formdata = new FormData();
    // formdata.append("course_id", ID);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      // body: formdata,
      redirect: 'follow'
    };

    setIsLoading(true)

    fetch(`${API_URL}/admin/course/read/course-by-id/${ID}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setIsLoading(false)
        setViewCourse(result.courseDetails.batches);
        setCourseName(result.courseDetails.name);
        setTotalBatches(result.courseDetails.batches.length);
        setTotalstudents(result.courseDetails.subject_count);
        //setTotalSub();
        console.log(result);
       if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }
      })
      .catch(error => console.log('error', error));
  }

  //function for add courses 

  const SendData = async () => {
    setAddSubject([]);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var raw = JSON.stringify({
      "name": value,
      "course_type": "online",
      "duration": Duration,
      "subject_id": dataId,
      "course_price": check
      // "name": value,
      // "course_type": "Online",
      // "subject_id": JSON.stringify(dataId),
      // "course_price": check,
      // "duration": Duration,
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${API_URL}/admin/course/create/course`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result);
        if (result.status == 1) {
          // console.log(result.message);
          toast(result.message)
          setvalue('');
          setDuration('');
          setcheck('');
          setdataId([]);
          setdataArray1([]);
          setcount1(1);
          ApiForAddSubject();
          setCheckBox(false);
          ApiForCourse();
        }
        else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
    
        else {
          console.log(result)
          setcount1(1);
        }

      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    ApiForAddSubject();
    ApiForCourse();
  }, [])
  return (
    <>

      <Toaster />
      <div style={{ width: '100%', height: '100vh', }}>
        <div className='head' style={{ width: '80%', height: '14vh', marginLeft: '16%' }}>
          <Header />
        </div>
        <div style={{ width: '100%', height: '86vh', display: "flex", }}>
          <div style={{ width: '20%', height: '100%', position: 'absolute', top: 0, marginLeft: '1%' }} >
            <SideBar />
          </div>

          <div className='maincontain' style={{ marginLeft: '15%', width: '100%', height: '100%', overflowY: 'scroll', }} >
            <div className="row">
              {/* <div className="col-1  ">
    <NavLink to="/academics" className="pt-5" style={{zIndex:"999",}}>
       <HiArrowLeft  /> 
      </NavLink>
      </div>  */}
              <div className="col-4 ps-5">
                <div style={{ display: "flex" }}>
                  <NavLink to="/academics" className="pt-4 px-3" style={{ zIndex: "999", }}>
                    <HiArrowLeft style={{ color: '#646464' }} />
                  </NavLink>
                  <div className='col-2 mx-1 mt-3' style={{ backgroundColor: '#FFDCDC', width: 45, height: 45, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }}><ImBooks style={{ fontSize: 25, color: '#F03838' }} /></div>
                  <p className="ms-3" style={{ fontSize: "48px", fontWeight: "400" }}>Courses/Classes</p>

                </div>
                <p style={{ paddingLeft: "125px" }}>Number of courses / classes -  {Course.length}</p>
              </div>


              <div className="col-6">
                <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{
                  marginLeft: "95%", backgroundColor: "#8147E7",
                  color: "white", width: "170px", height: "48px", border: "none", borderRadius: "5px", fontSize: "14px"
                }}> <AiOutlinePlus /> Add Courses</button>
              </div>
            </div>

            <div iv className="row" style={{ overflowY: "scroll" }}>
              {
                isLoader == false ? <><Lode /></> : Course?.map((res, key) => {
                  return (
                    <>
                      <div className="col-4" key={key} style={{ textAlign: "center", }}>
                        <div className="card mt-4 mx-auto d-flex " style={{ width: "80%" }} >
                          <span className="" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" onClick={() => { ApiForViewCourse(res.id) }}>
                            <div className="card-body">
                              <div style={{ paddingTop: "5px", fontWeight: "400" }}>
                                <p style={{ fontSize: "20px", marginLeft: "-27px", }}>{res.course_name}</p>
                                <p style={{ fontSize: "16px", color: " #838383" }}>Total No. of subjects:{res.total_subjects}</p>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </>
                  )
                })}

            </div>
            {/* Offcanvas */}
            {/* <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel" style={{width:"40%",}}> */}
            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel" style={{ width: "40%", }}>
              <div class="offcanvas-header">
                <p class="offcanvas-title" id="offcanvasRightLabel" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "32px", color: "#434343", }}>Course Details</p>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div class="offcanvas-body">
                <p className="" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "24px", color: "#434343", }}> Course Name</p>
                <p className="mt-2" style={{ fontStyle: "normal", fontWeight: "500", fontSize: "24px", color: "#434343", }}>{courseName}</p>
                <div className="row mt-4">
                  <div className="col-4">
                    <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "20px", color: "#434343", }}>Subject Count</p>
                    <p className="mt-3 ps-2" style={{ fontStyle: "normal", fontWeight: "500", fontSize: "32px", color: "#434343", }}>{totalstudents}</p>
                  </div>

                  <div className="col-5">
                    <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "20px", color: "#434343", }}>Total No. Of Batches</p>
                    <p className="mt-3 ps-2" style={{ fontStyle: "normal", fontWeight: "500", fontSize: "32px", color: "#434343", }}>{totalBatches}</p>
                  </div>
                </div>
                <div className="mt-4" style={{ border: "1px solid #AAAAAA", borderRadius: "5px", overflowY: "scroll", height: "45vh" }}>
                  <div className='row text-center mt-3' style={{ fontSize: "17px", color: "#434343" }}>
                    <div className='col-4'>
                      <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#434343", }}>Batch ID</p>
                    </div>
                    <div className='col-3'>
                      <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#434343", }}>Batch Name</p>
                    </div>
                    <div className='col-5'>
                      <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#434343", }}>Start Date</p>
                    </div>
                    {/* <div className='col-3'>
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Action</p>
                    </div> */}
                  </div>
                  {
                    isloading ? <Loader show={isloading}/> : <>
                      {ViewCourse == "" ? 
                      <h4 className="mt-5 text-center " style={{ color: "red" }}>No data available</h4> 
                      : ViewCourse?.map((item, index) => {
                        return (
                          <>
                            <div className='row text-center my-3 d-flex align-items-center' style={{ border: "1px solid #ECECEC", borderRadius: "10px", margin: "0px 11px", padding: "5px", }}>
                              <Sdata item={item} key={index} />
                            </div>
                          </>
                        )
                      })}
                    </>
                  }

                </div>
                <div className="text-center mt-4">
                  <NavLink to="" className="mt-5 " style={{
                    width: "250px",
                    backgroundColor: "#5A81FE", border: "none", color: "white", height: "40px", borderRadius: "5px", padding: "13px 102px", textDecoration: "none",
                  }} data-bs-dismiss="offcanvas" aria-label="Close">Back</NavLink>
                </div>
              </div>
            </div>



            {/* <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasWithBackdrop" aria-labelledby="offcanvasWithBackdropLabel" style={{ width: "40%", }}>
              <div class="offcanvas-header">
                <p class="offcanvas-title" id="offcanvasRightLabel" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "32px", color: "#434343", }}>Batch Details</p>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div class="offcanvas-body">
                <p className="" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "24px", color: "#434343", }}>  Name</p>
                <p className="mt-3" style={{ fontStyle: "normal", fontWeight: "500", fontSize: "24px", color: "#434343", }}>B.Tech Specialization In Information Technology </p>
                <div className="row mt-4">
                  <div className="col-4">
                    <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "20px", color: "#434343", }}>Course Code</p>
                    <p className="mt-3 " style={{ fontStyle: "normal", fontWeight: "500", fontSize: "32px", color: "#434343", }}>3CO-JVY</p>
                  </div>

                  <div className="col-5">
                    <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "20px", color: "#434343", }}>Total No. Of Batches</p>
                    <p className="mt-3" style={{ fontStyle: "normal", fontWeight: "500", fontSize: "32px", color: "#434343", }}>36</p>
                  </div>
                </div>
                <div className="mt-4" style={{ border: "1px solid #AAAAAA", borderRadius: "5px", overflowY: "scroll", height: "45vh" }}>
                  <div className='row text-center mt-3' style={{ fontSize: "17px", color: "#434343" }}>
                    <div className='col-3'>
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Batch ID</p>
                    </div>
                    <div className='col-3'>
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Batch Name</p>
                    </div>
                    <div className='col-3'>
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Start Date</p>
                    </div>
                    <div className='col-3'>
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#434343", }}>Action</p>
                    </div>
                  </div>

                  {Dat1?.map((item, index) => {
                    return (
                      <>
                        <div className='row text-center my-3 d-flex align-items-center' style={{ border: "1px solid #ECECEC", borderRadius: "10px", margin: "0px 11px", padding: "5px", }}>
                          <Sdatai item={item} key={index} />
                        </div>
                      </>
                    )
                  })}
                </div>
                <button className="mt-4" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop" style={{
                  width: "250px", marginLeft: "30%",
                  backgroundColor: "#5A81FE", border: "none", color: "white", height: "40px", borderRadius: "5px", padding: "13px 102px",
                }}>Back</button>
              </div>
            </div> */}





          </div>
        </div>
      </div>

      {/* +add modal start */}


      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backdropFilter: "blur(2px)" }}>
        <div className="modal-dialog modal-dialog-centered"  >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Add Courses</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal"
                aria-label="Close" onClick={() => setcount1(1)}></button>
            </div>

            <div className="modal-body">
              <div style={{ display: count1 === 1 ? "block" : "none" }}>
                <div className="mt-2" style={{ border: "2px solid #FAFAFA", width: "350px", height: "330px", marginLeft: "76px", borderRadius: "5px" }}>

                  <div style={{ margin: "15px" }}><b> Name of Courses</b></div>

                  <div>
                    <input type="text" maxLength="100" className="mx-4" placeholder="Type Course Name"
                      value={value} style={{
                        backgroundColor: "#FAFAFA", borderRadius: "5px",
                        border: Bg == true ? "1px solid red" : "none", width: "300px", marginTop: "20px", height: "40px", paddingLeft: "10px"
                      }} onChange={(e) => { setvalue(e.target.value) }} />
                  </div>

                  <p style={{ paddingLeft: "295px", color: "#B1AFC8", fontSize: "10px" }} >{value.length}/100</p>

                  <div className="">
                    <button className="btn btn-primary mx-4"
                      style={{ width: "300px", marginTop: "100px", cursor: "pointer" }} onClick={handelChange} disabled={value.length >= 2 ? false : true} >Proceed</button>
                  </div>
                </div>
              </div>


              {/* second Add staart */}
              <div style={{ display: count1 === 2 ? "block" : "none" }}>
                <div className="mt-2" style={{ border: "2px solid #FAFAFA", width: "350px", height: "330px", marginLeft: "76px", borderRadius: "5px" }}>
                  <div style={{ margin: "15px" }}><b> Select Subject</b></div>
                  <div className="mt-3">

                    <input type="text" className="form-control" placeholder="Select subject"
                      value={First} style={{ border: "none", backgroundColor: " #FAFAFA", paddingLeft: "30px", width: "92%", marginLeft: "20px" }} onChange={(e) => { setFirst(e.target.value) }} />
                    <FiSearch style={{ marginTop: "-63px", marginLeft: "20px", color: "#ccc", fontSize: '18px' }} />
                  </div>

                  <div className="mt-3" style={{ overflowY: "scroll", height: "150px" }}>
                    {
                      AddSubject?.map((res, key) => {
                        return (
                          <>
                            <div className="form-check" key={key}  >
                              <input className="form-check-input  mx-2"
                                type="checkbox" id={res.id} value={res.name} onClick={handleChange1} ref={checkboxRef} />
                              <label className="form-check-label " for="flexCheckDefault"  >

                                {res.name}
                              </label>
                            </div><br></br>

                          </>
                        )
                      })}
                  </div>

                  <div className="">
                    <button className="btn btn-primary mx-4 " disabled={dataArray1.length >= 1 ? false : true} onClick={() => { setcount1(count1 + 1) }}
                      style={{ width: "300px", marginTop: "0px", cursor: "pointer" }} >Proceed</button>
                  </div>
                </div>
              </div>




              {/* third Add start */}
              <div style={{ display: count1 === 3 ? "block" : "none" }}>
                <div className="mt-2" style={{ border: "2px solid #FAFAFA", width: "350px", height: "330px", marginLeft: "76px", borderRadius: "5px" }}>
                  <div className="px-3" style={{ margin: "15px" }}><b> Price / Fees Of Course</b></div>
                  <div >
                    <input type="number" className="mx-4 " placeholder="Enter price" value={check} style={{ backgroundColor: "#FAFAFA", borderRadius: "5px", width: "150px", marginTop: "0px", height: "40px", paddingLeft: "10px", border: pune == true ? "1px solid red" : "none" }} onChange={(e) => { setcheck(e.target.value) }} />Rs</div>

                  <div className="px-3" style={{ margin: "15px" }}><b> Duration</b></div>
                  <div>
                    <input type="number" className="mx-4 " placeholder="Enter Duration" value={Duration}
                      style={{ backgroundColor: "#FAFAFA", borderRadius: "5px", width: "150px", marginTop: "0px", height: "40px", paddingLeft: "10px", border: pune1 == true ? "1px solid red" : "none" }} onChange={(e) => { setDuration(e.target.value) }} />Months</div>



                  <div className="mt-5">
                    <button className="btn btn-primary mx-4" onClick={handelChange1}
                      style={{ width: "300px", marginTop: "0px" }} disabled={Duration >= 2 && check >= 2 ? false : true} >Proceed</button>
                  </div>
                </div>
              </div>


              {/* couresefourht */}



              <div style={{ display: count1 === 4 ? "block" : "none" }}>
                <div className="mt-2" style={{ width: "350px", height: "330px" }}>
                  <div style={{ margin: "15px mb-2" }}><b>Following course will be created</b></div>
                  <p className="mx-3">{value}</p>
                  <div style={{ margin: "15px" }}><b>With the following subjects:</b></div>

                  <div className="mt-2" style={{ overflowY: "scroll", height: "9rem" }}>
                    {dataArray1.map((res, key) => {
                      return (
                        <>
                          <p key={key} className="small mx-3 " style={{ fontSize: "12px", color: "grey", height: "2vh" }}></p>
                          {res}
                        </>
                      )
                    })}

                  </div>

                  <div className="mt-4">
                    <button className="btn btn-primary mx-4" data-bs-dismiss="modal" aria-label="Close" onClick={() => { SendData(); checkboxRef.current.checked = false }}
                      style={{ width: "300px", marginTop: "0px" }}>Proceed</button>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>



      {/* <ToastContainer position="top-end" className="p-3" >
        <Toast onClose={() => setShowLoader(false)} show={ShowLoader} delay={8000} autohide style={{ backgroundColor: "#000" }}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt="" />
            <strong className="me-auto">tipsg</strong>
            <small>just show</small>
          </Toast.Header>
          <Toast.Body className="text-white">{message}</Toast.Body>
        </Toast>
      </ToastContainer>
 */}



      {/* <a className="btn btn-primary" data-bs-toggle="modal" href="#exampleModalToggle" role="button">Open first modal</a> */}


    </>
  )
}







function Sdata(props) {
  return (
    <>
      <div className='col-3'>

        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#434343", }}>{props.item.id}</p>
      </div>
      <div className='col-5'>
        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#434343", }}>{props.item.batch_name}</p>
      </div>
      <div className='col-4'>
        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#434343", }}>{props.item.start_date}</p>
      </div>
      {/* <div className='col-3' style={{ display: "flex", color: "#8147E7" }}>

        {/* <NavLink to="/Studentprofile" className="text-decoration-none " style={{fontSize:"14px",color:"#8147E7"}}><p>{props.item.action}</p></NavLink> 
        <p data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop" style={{ fontStyle: "normal", fontWeight: "500", fontSize: "14px", color: "#2188E7", cursor: "pointer" }} className="text-nowrap"> View Details </p>
        <IoArrowForwardOutline className="m-1" style={{ color: "#2188E7", }} />
      </div> */}





    </>
  );
}








function Sdatai(props) {
  return (
    <>
      <div className='col-3'>

        <img src={props.item.batchid} style={{ width: "30px" }} />
      </div>
      <div className='col-3'>
        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#434343", }}>{props.item.batchname}</p>
      </div>
      <div className='col-3'>
        <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#434343", }}>{props.item.stardate}</p>
      </div>
      <div className='col-3' style={{ display: "flex", color: "#8147E7" }}>

        <NavLink to="/Studentprofile" className="text-decoration-none " style={{ fontStyle: "normal", fontWeight: "500", fontSize: "14px", color: "#2188E7", }} ><p>{props.item.action}</p></NavLink>
        <IoArrowForwardOutline className="m-1" style={{ color: "#2188E7", }} />
      </div>


    </>
  );
}