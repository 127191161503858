import React, { useState, useContext } from "react";
import img1 from '../images/12-compressed.jpg'
import hand from "../images/hand.png";
import "./test.css";
import OTPInput, { ResendOTP } from "otp-input-react";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useRequireAuth } from './RequireAuth';
import { withRouter } from "react-router-dom";
import { API_URL, img_url } from '../env';

export default function SchoolCode() {

    //  const { login, profile, profileGet ,logout} = useContext(AuthContext);
    const navigate = useNavigate();
    const [Error, seterror] = useState();
    const [Email, setEmail] = useState("");
    const [Pass, setPass] = useState("");

    // console.log(count);


    const loginApi = async () => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "school_code": Pass

        });
        // var formdata = new FormData();
        // formdata.append("email", Email);
        // formdata.append("password", Pass);

        var requestOptions = {
            headers: myHeaders,
            method: "POST",
            body: raw,
            redirect: "follow",
        };

        fetch(`https://masterapiv2.intellix360.in/get/url/by/school_code`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status == "1") {
                    localStorage.setItem("api_url", result.url)
                    navigate("/login");
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                }
                else {
                    seterror(result.error);
                    toast(result.message);
                }
            })
            .catch((error) => console.log("error", error));
    };



    return (
        <>
            <ToastContainer />

            {/* login screen start */}

            <div className="">
                <div className="row m-0 p-0 g-0">
                    <div className="col-lg-6 m-0 p-0 g-0">
                        <img
                            src={img1}
                            alt="nn"
                            style={{ width: "100%", height: "45rem" }}
                        />
                    </div>
                    <div className="col-lg-6 m-0 p-0 g-0">
                        <div
                            className="container-fluid m-0 p-0 g-0"
                            style={{ backgroundColor: "#FFFFFF" }}
                        >
                            <div
                                className="card m-0 p-3 g-0 pb-4 pt-4"
                                style={{ backgroundColor: "#FFFFFF", border: "none" }}
                            >
                                <div
                                    className="card-header m-0 p-0 g-0  pt-3"
                                    style={{ backgroundColor: "#FFFFFF", border: "none" }}
                                >
                                    <p className="card-title m-0 p-0 g-0 ">
                                        {/* <img
                                                src={`${banner}`}
                                                alt="bb"
                                                style={{ width: "15%" }}
                                            /> */}
                                        <p
                                            className="card-text ms-3 mt-2"
                                            style={{
                                                fontFamily: "Poppins",
                                                fontStyle: "normal",
                                                color: "#434343",
                                                fontSize: "40px",
                                                fontWeight: "600px",
                                            }}
                                        >
                                            Hey, Hello
                                            <span>
                                                <img src={hand} alt="nn" style={{ width: "5%" }} />
                                            </span>
                                        </p>
                                        <p
                                            className="card-text ms-3 mt-2"
                                            style={{
                                                fontFamily: "Poppins",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                fontSize: "15px",
                                                color: "#BDBDBD",
                                            }}
                                        >
                                            Enter the information you entered while registering
                                        </p>
                                    </p>
                                </div>
                                <div className="card-body">
                                    <div>
                                        <p
                                            className="card-text display-6 mt-4"
                                            style={{
                                                fontFamily: "Poppins",
                                                fontStyle: "normal",
                                                fontSize: "32px",
                                                color: "#000000",
                                                fontWeight: "500",
                                            }}
                                        >
                                            Login
                                        </p>
                                        <label
                                            for="exampleFormControlInput1"
                                            className="form-label mt-3"
                                            style={{ fontSize: "16px", fontWeight: "400px" }}
                                        >
                                            Enter School Code
                                        </label>
                                        <div class="input-group mb-3">
                                            <span
                                                class="input-group-text"
                                                id="basic-addon1"
                                                style={{ backgroundColor: "transparent" }}
                                            >
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M9.23047 9H7.2002C6.08009 9 5.51962 9 5.0918 9.21799C4.71547 9.40973 4.40973 9.71547 4.21799 10.0918C4 10.5196 4 11.0801 4 12.2002V17.8002C4 18.9203 4 19.4801 4.21799 19.9079C4.40973 20.2842 4.71547 20.5905 5.0918 20.7822C5.5192 21 6.07902 21 7.19694 21H16.8031C17.921 21 18.48 21 18.9074 20.7822C19.2837 20.5905 19.5905 20.2842 19.7822 19.9079C20 19.4805 20 18.9215 20 17.8036V12.1969C20 11.079 20 10.5192 19.7822 10.0918C19.5905 9.71547 19.2837 9.40973 18.9074 9.21799C18.4796 9 17.9203 9 16.8002 9H14.7689M9.23047 9H14.7689M9.23047 9C9.10302 9 9 8.89668 9 8.76923V6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6V8.76923C15 8.89668 14.8964 9 14.7689 9"
                                                        stroke="#555555"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </span>
                                            <input
                                                type="number"
                                                class="form-control"
                                                placeholder="Please Enter Your Code"
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                                style={{ borderLeft: "none", padding: "7px" }}
                                                value={Pass}
                                                onChange={(e) => {
                                                    setPass(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="mb-3 form-check">
                                            {/* <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                        /> */}
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                {/* <label
                            className="form-check-label"
                            for="exampleCheck1"
                            style={{ fontSize: "16px", fontWeight: "400px" }}
                          >
                            Remember me
                          </label>
                          <p
                            className="ms-5"
                            style={{
                              color: "blue",
                              fontSize: "12px",
                              float: "right",
                              cursor: "pointert",
                            }}
                            onClick={() => {
                              setcount(count + 1);
                            }}
                          >
                            Forgotpassword ?
                          </p> */}
                                            </div>
                                        </div>

                                        <div></div>
                                        <button
                                            onClick={() => loginApi()}
                                            type="submit"
                                            className="btn btn-primary"
                                            style={{
                                                backgroundColor: "#8F6DFF",
                                                width: "100%",
                                                marginTop: "20px",
                                                padding: "11px",
                                                border: "none",
                                            }}
                                        >
                                            Proceed
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1"></div>
                </div>

            </div>
            {/* login screen complete */}


        </>
    );
}

// export default Login;
