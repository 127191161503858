import React, { useState } from "react";
import { BsFillCalendarFill } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoIosWarning } from "react-icons/io";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from "react-router-dom";
import { API_URL, img_url } from "../env";

const BulkAttendance = () => {
  const [startDate1, setStartDate1] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());
  const [data, setData] = useState(1);
  const [value, setvalue] = useState("present");
  const [presentTable,setPresentTable] = useState([]);
  const [halfDay,setHalfDay] = useState([]);
  const [presentDays,setPresentDays] = useState("0");
  const [halfDays,setHalfDays] = useState("0");
  const [absentDays,setAbsentDays] = useState("0");
  const [selectedDays,setSelectedDays] = useState("0");
  const Navigate = useNavigate();

const {enrollment_id} = useParams();
  const BulkAttendanceData = async()=>{
    try{
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );
      var raw = JSON.stringify({
        "enrollment_id": enrollment_id,
        "start_date": startDate1.toISOString().split('T')[0],
        "end_date": startDate2.toISOString().split('T')[0]
      });
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${API_URL}/admin/student/get/bulk/attendence`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          setData(2);
          setPresentTable(result.present_list);
          setHalfDay(result.halfday_list);
          setPresentDays(result.present_days);
          setHalfDays(result.half_days);
          setAbsentDays(result.absent_days);
          setSelectedDays(result.selected_days);
        } 
        else if (result.status === 'VAL_ERR') { 
          toast(result.Backend_Error) 
        } 
      else if (result.status == 'FILE_ERR') { 
          toast(result.Backend_Error) 
        } 
       else if (result.status == "CUSTOM_ERR") { 
          toast(result.Backend_Error); 
        } else if (result.status == "TOKEN_ERR") { 
          localStorage.removeItem('api_url'); 
          Navigate('/code') 
          toast("Logged in other device! Please login again") 
        } 
        else { 
          console.log(result) 
        }
      })
  .catch(error => console.log('error', error));

    }catch(e){
      console.log(e);
    }

  }

  return (
    <>
    
      <div className="col-8" style={{}}>
        {data == 1 ? (
          <>
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="card w-50 mx-auto p-3 ">
                <h5 className="mb-0 text-center">
                  {" "}
                  <IoIosWarning /> please select date
                </h5>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="mt-3 ">
              <div className="row">
                <div className="col-3">
                  <div className="card py-1 px-3">
                    <h5 className="mb-0">Selected Days</h5>
                    <h1 className="mb-0">{selectedDays}</h1>
                  </div>
                </div>
                <div className="col-3">
                  <div className="card py-1 px-3">
                    <h5 className="mb-0">Present Days</h5>
                    <h1 className="mb-0">{presentDays}</h1>
                  </div>
                </div>
                <div className="col-3">
                  <div className="card py-1 px-3">
                    <h5 className="mb-0">Half Days</h5>
                    <h1 className="mb-0">{halfDays}</h1>
                  </div>
                </div>
                <div className="col-3">
                  <div className="card py-1 px-3">
                    <h5 className="mb-0">Absent Days</h5>
                    <h1 className="mb-0">{absentDays}</h1>
                  </div>
                </div>
              </div>
            </div>

            {/* tabs  */}
            <div className="row mt-3">
              <div className="col-12 d-flex gap-3 align-items-center">
                <span
                  className="py-2 px-3"
                  onClick={() => setvalue("present")}
                  style={{
                    cursor: "pointer",
                    borderBottom:
                      value == "present" ? "2px solid #8147E7" : "none",
                    color: value == "present" ? "#8147E7" : "",
                    fontWeight: value == "present" ? 700 : 400,
                  }}
                >
                  Present
                </span>
                <span
                  className="py-2 px-3"
                  onClick={() => setvalue("half_day")}
                  style={{
                    cursor: "pointer",
                    borderBottom:
                      value == "half_day" ? "2px solid #8147E7" : "none",
                    color: value == "half_day" ? "#8147E7" : "",
                    fontWeight: value == "half_day" ? 700 : 400,
                  }}
                >
                  Half Day
                </span>
              </div>

              <div style={{ borderBottom: "1px solid #e4e4e4" }}></div>

              <div className="row">
                <div className="col-12">
                  {
                    value == "present"?
                    <>
                      <div className=" p-2 mt-2" style={{height:"60vh",overflowY:"scroll"}} >
                      <DataTable value={presentTable}  tableStyle={{ minWidth: '50rem'}}>
                <Column field="id" header="Id" style={{ width: '10.6%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }}></Column>
                <Column field="in_time" header="Punch-In" style={{ width: '17.8%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }} bodyStyle={{fontWeight:500}}></Column>
                <Column field="out_time" header="Punch-Out" style={{ width: '17.8%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }} bodyStyle={{fontWeight:500}}></Column>
                <Column field="attendence_date" header="Attendance Date" style={{ width: '17.8%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }} bodyStyle={{fontWeight:500}}></Column>
                <Column field="status" header="Status" style={{ width: '17.8%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }} bodyStyle={{fontWeight:500}}></Column>
                </DataTable>
                      </div>
                    </>:<></>
                  }

                  {
                    value=="half_day"?
                    <>
                    <div className=" p-2 mt-2" style={{height:"60vh",overflowY:"scroll"}} >
                      <DataTable value={halfDay}  tableStyle={{ minWidth: '50rem'}}>
                <Column field="id" header="Id" style={{ width: '10.6%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }}></Column>
                <Column field="in_time" header="Punch-In" style={{ width: '17.8%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }} bodyStyle={{fontWeight:500}}></Column>
                <Column field="out_time" header="Punch-Out" style={{ width: '17.8%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }} bodyStyle={{fontWeight:500}}></Column>
                <Column field="attendence_date" header="Attendance Date" style={{ width: '17.8%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }} bodyStyle={{fontWeight:500}}></Column>
                <Column field="status" header="Status" style={{ width: '17.8%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }} bodyStyle={{fontWeight:500}}></Column>
                </DataTable>
                      </div>
                    </>:<></>
                  }
                </div>
              </div>
            </div>
            {/* tabs  */}
          </>
        )}
      </div>

      <div className="col-2 p-2 its d-flex">
        <div className="w-100" style={{ marginTop: "40px" }}>
          <h5 className="mb-0">Select date range</h5>

          <div>
            <h6 className="mb-0 mt-3">From</h6>
            <span
              className="w-100 py-1"
              style={{
                display: "flex",
                backgroundColor: "#EDEDF5",
                borderRadius: "5px",
                color: "#b5b5b5",
                fontSize: "13px",
              }}
            >
              <BsFillCalendarFill
                className="ms-2"
                style={{ fontSize: "18px", color: "#3C3C3C", marginTop: "8px" }}
              />
              <div>
                <DatePicker
                  className="border-0  ps-2 pt-1  datep"
                  showIcon
                  selected={startDate1}
                  onChange={(date) => {
                    setStartDate1(date);
                    //     const req_date = date.getFullYear() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getDate();
                    //    CollectData(req_date);
                  }}
                  style={{ backgroundColor: "#E0E0E0", outline: "0" }}
                />
              </div>
            </span>
          </div>

          <div>
            <h6 className="mb-0 mt-3">To</h6>
            <span
              className="w-100 py-1"
              style={{
                display: "flex",
                backgroundColor: "#EDEDF5",
                borderRadius: "5px",
                color: "#b5b5b5",
                fontSize: "13px",
              }}
            >
              <BsFillCalendarFill
                className="ms-2"
                style={{ fontSize: "18px", color: "#3C3C3C", marginTop: "8px" }}
              />
              <div>
                <DatePicker
                  className="border-0  ps-2 pt-1  datep"
                  showIcon
                  selected={startDate2}
                  onChange={(date) => {
                    setStartDate2(date);
                    //     const req_date = date.getFullYear() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getDate();
                    //    CollectData(req_date);
                  }}
                  style={{ backgroundColor: "#E0E0E0", outline: "0" }}
                />
              </div>
            </span>
          </div>

          <div className="mt-4">
            <button
              onClick={BulkAttendanceData}
              className="w-100 py-2 border-0"
              style={{
                borderRadius: "5px",
                backgroundColor: "#8147E7",
                color: "#fff",
              }}
            >
              View Attendance
            </button>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>
  );
};

export default BulkAttendance;
