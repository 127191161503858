import React, { useState, useEffect, useContext } from 'react';
import IOI from '../images/iconu.png';
import image from '../images/vscode-icons_file-type-excel2.png';
import { MdOutlineBed } from 'react-icons/md';
import { BsBoxArrowInRight, BsBoxArrowInLeft } from 'react-icons/bs';
import { AiOutlineCalendar } from 'react-icons/ai'
import { IoIosArrowBack } from 'react-icons/io'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoNewspaper } from "react-icons/io5";
import { FiUpload } from "react-icons/fi";
import { NavLink, useNavigate } from 'react-router-dom';
import EE from '../images/Ellipse 10.png';
import EE1 from '../images/Vector (20).png';
import "./Leav.css";
import { AuthContext } from './AuthContext';
import { API_URL } from '../env';
import batch from '../images/batch.png';
import { ToastContainer, toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { FaCalendar } from 'react-icons/fa'
import { Loader } from './Loader';




export default function Leave({ formattedDate }) {


  const [state, setState] = useState(0);
  const [all, setAll] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [Images, setImages] = useState();
  const [Times, setTimes] = useState();
  const [Banner, setBanner] = useState("")
  const [Banner1, setBanner1] = useState("");
  const [data, setData] = useState([]);
  const [stu_id, setstu_id] = useState('');
  const [Students, setStudents] = useState([]);
  const [remark, setremark] = useState('');
  const [Name, setName] = useState('');
  const [Info, setInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  const convertToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Map the array to include only the desired properties
    const selectiveDataArray = all.map((all) => ({
      Name: all.student_name,
      From: all.from,
      to: all.to,
      Batch: all.batch_name,
      Duration: all.duration

    }));

    // Convert the selective data array into a worksheet
    const worksheet = XLSX.utils.json_to_sheet(selectiveDataArray);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a file name
    const fileName = 'Leave_data.xlsx';

    // Generate Excel file binary data
    const excelData = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Create a blob object from the binary data
    const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a download link
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;

    // Simulate a click to trigger the download
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
  };

  const { apiToken } = useContext(AuthContext)
  const { Navigate } = useNavigate();

  const alli = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-type", `application/json`);
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var raw = JSON.stringify({
      "isoDate1": formattedDate
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    setIsLoading(true);
    fetch(`${API_URL}/admin/attendence/read/absent/students`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setIsLoading(false);
        if (result.status == "1") {
          setAll(result.students);
        } else if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }
      })
      .catch(error => console.log('error', error));
  }


  // const details = async (id) => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Bearer ${apiToken}`);

  //   var requestOptions = {
  //     method: 'GET',
  //     headers: myHeaders,
  //     redirect: 'follow'
  //   };

  //   fetch(`${API_URL}/studentLeaveView?leave_id=${id}`, requestOptions)
  //     .then(response => response.json())
  //     .then(result => {
  //       console.log(result)
  //       if (result.status == "001") {
  //         setData([result.View_student_leave]);

  //       }
  //     })
  //     .catch(error => console.log('error', error));
  // }

  //   const students = async() =>{
  //     var myHeaders = new Headers();
  // myHeaders.append("Authorization", `Bearer ${apiToken}`);

  // var requestOptions = {
  //   method: 'GET',
  //   headers: myHeaders,
  //   redirect: 'follow'
  // };

  // fetch(`${API_URL}/studentList`, requestOptions)
  //   .then(response => response.json())
  //   .then(result => {console.log(result)
  //   if(result.status=='001'){
  //     setStudents(result.student_list);

  //   }
  //   })
  //   .catch(error => console.log('error', error));
  //   }


  //   const leave = async(x) =>{
  //     var myHeaders = new Headers();
  // myHeaders.append("Authorization", `Bearer ${apiToken}`);

  // var formdata = new FormData();
  // formdata.append("student_id", stu_id);
  // formdata.append("remark", remark);
  // formdata.append("file", Banner );
  // formdata.append("from", start);
  // formdata.append("to", end);
  // formdata.append("leave", x);

  // var requestOptions = {
  //   method: 'POST',
  //   headers: myHeaders,
  //   body: formdata,
  //   redirect: 'follow'
  // };

  // fetch(`${API_URL}/studentLeave`, requestOptions)
  //   .then(response => response.json())
  //   .then(result => {console.log(result)
  //   if(result.status=='001'){
  //     toast(result.message);
  //     // alli();
  //     setInfo([result.leave])
  //   }
  //   else{toast(result.error)}
  //   })
  //   .catch(error => console.log('error', error));
  //   }


  // const handleProcess = () =>{
  //   if(stu_id.length=='' || remark.length<=1 || Banner.length<=1 || start.length<=1 || end.length<=1 ){
  //     toast(stu_id.length==''
  //     ? 'Please select student'
  //     : remark.length<=1
  //     ? 'Please give remark'
  //     : start.length<=1 ||end.length<=1
  //     ? 'Set dates'
  //     : '')
  //   }

  //   else{leave(0)}
  // }

  useEffect(() => {
    alli();
    // students()
  }, [])

  return (
    <>

      <ToastContainer />
      {/* <button style={{marginLeft:"80%",marginTop:"-50%"}}>fhhjgfhsgyfgy</button> */}
      <div className='mt-4  '>
        <div className='' style={{ border: "1px solid #DBDBDB", width: "70%", marginLeft: "37px", backgroundColor: "white", borderRadius: "10px", }}>
          <div className='row'>
            <div className='col-4'>
              <div style={{ display: "flex" }}>
                <h5 className='px-3 pt-4' style={{ fontStyle: "normal", fontWeight: "500", fontSize: "20px", color: "#292929", }}> Live Student Update</h5>
                {/* <div className="livel " style={{marginTop:"30px"}}>
                      <div className="livel_icon"></div>
                    </div> */}

              </div>
            </div>
            <div className='col-8'>
              <button className='mt-3 '
                style={{ backgroundColor: "#20744A", width: "150px", border: "none", color: "white", height: "37px", borderRadius: "5px" }} onClick={() => { convertToExcel() }}><span><img src={image} style={{ width: "12%" }} /></span> Export Data</button>
              {/* <button type="button" class="btn mt-3 mx-4" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" style={{ float: "right", border: "1px solid #8147E7", color: "#8147E7", width: "25%", }}>Grant Leave</button> */}
            </div>
          </div>
          {
            isLoading ? <Loader show={isLoading} /> :
              <div style={{ overflowY: "scroll", height: "30vh" }}>
                {all.length > 0 ? all.map((res, key) => {
                  return (
                    <>
                      <div className='row text-center my-3 d-flex align-items-center mx-3 mt-4 py-2'
                        style={{ border: "1px solid #DBDBDB", width: "96%", backgroundColor: "white", borderRadius: "10px", height: "auto" }} >


                        <div className='col-4' >
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                // backgroundColor: "rgb(237, 255, 243)",
                                marginLeft: "20px",
                                marginRight: "20px",
                                // borderRadius: "50%",
                                // width: "60px",
                                // border: "1px solid #808080",
                                // width: "16%", height: "2.5rem",
                              }}
                            >
                              <p
                                style={{
                                  color: "rgb(23, 165, 54)",
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                }}
                              >
                                {res.enrollment_id}
                              </p>
                            </div>
                            {/* <p className='' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#474747", }}>{res.name}</p> */}
                          </div>

                          {/* <p className='mt-3' style={{ textAlign: "start", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>Leaves taken on : {res.from}</p> */}
                        </div>

                        <div className='col-4 ' >
                          <div style={{ display: "flex" }}>
                            {/* <p className='ms-3' style={{ fontSize: "20px", color: "#FF7D05", }}>{res.le}</p>&nbsp;&nbsp;
                        <p className='pt-2' style={{ fontSize: "14px", color: "#474747", fontStyle: "normal", fontWeight: "300", }}>{res.created_at.split(" ")[1].split(".")[0]}</p> */}
                          </div>
                          <p className='' style={{ textAlign: 'start', fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#474747",       textTransform: "capitalize",}}>Name : {res.name}</p>
                        </div>

                        <div className='col-4 '>
                          <div style={{ display: "flex" }}>
                            <img src={batch} style={{ width: "9%", }} />&nbsp;&nbsp;
                            <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "15px", color: "#474747", }}>{res.batch_name}</p>
                          </div>
                          {/* <p className='mt-3' style={{ textAlign: "start", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>Expected Arrival at : {res.to}</p> */}
                        </div>

                        {/* <div className='col-2' >
                          <div style={{ display: "flex" }}> */}
                        {/* <img src={res.hoteli}  style={{width:"14%",}} />&nbsp;&nbsp;
                       <p>{res.batch_name}</p> */}
                      </div>

                      {/* <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrople" aria-controls="offcanvasScrolling" style={{ backgroundColor: "#3D70F5", color: "white", border: "none", borderRadius: "5px", padding: "5px 14px", fontSize: "13px", }} >View Detail</button> */}
                      {/* </div>


                      </div> */}
                    </>
                  )
                }) : <><div className='mt-5' style={{ justifyContent: "center", alignItem: "center", width: "100%", display: "flex", height: "100%", color: "red" }}><h6>No Data Available</h6></div></>}
              </div>
          }

        </div>
      </div>


      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel" style={{ width: "40%", }}>
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasScrollingLabel"></h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "28px", color: "#474747", }}>Grant Leave</p>
          <div className='mt-4'>
            <select class="form-select mt-3" aria-label="Default select example" style={{ width: "100%", padding: "10px", }}
              value={stu_id} onChange={(e) => { setstu_id(e.target.value); }}>
              <option selected>Select Student</option>
              {
                Students?.map((res) => {
                  return (
                    <>
                      <option key={res.id} value={res.id}>{res.name}</option>
                    </>
                  )
                })
              }
            </select>
          </div>
          <div className='mt-4'>
            <input type="text" placeholder='Remark for Leave' style={{ fontSize: "13px", background: "#FFFFFF", border: "1px solid #B0B0B0", borderRadius: "5px", width: "100%", padding: "11px", }} value={remark} onChange={(e) => { setremark(e.target.value) }} />
            <p style={{ float: "right", color: "#A6A6A6", fontStyle: "normal", fontWeight: "400", fontSize: "14px", }}>{remark.length}/100</p>
          </div>
          <div className='mt-5'>
            <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "16px", color: "#474747", }}>Leave Duration</p>
          </div>

          <div className='row mt-5 d-flex'>

            <div className='col-3 d-flex'>
              <div className='d-flex' style={{ alignItems: "center", gap: "10px" }}>
                <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#474747", }}>From</p>


                <span style={{ display: 'flex', borderRadius: "10px", padding: '7px 7px', color: '#b5b5b5', fontSize: '13px', width: "248px", border: " 1px solid rgba(176, 176, 176, 1)", width: "150px", overflow: "hidden", alignItems: "center" }}>

                  <div style={{ width: '10px', }}>
                    <span>
                      <AiOutlineCalendar className='me-2 ' style={{ fontSize: '20px', marginTop: "3px", zIndex: "9999", position: "absolute", }} />
                    </span>
                    <DatePicker
                      className='border-0 w-13'
                      showIcon

                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        setStart(date.getFullYear() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getDate())

                      }}
                      style={{ padding: "15px 11px 15px 25pxpx", border: "1px solid #B0B0B0", borderRadius: "10px" }} />
                  </div>
                </span>

              </div>
            </div>
            <div className='col-2'>

            </div>
            <div className='col-4  d-flex'>
              <div className='d-flex' style={{ alignItems: "center", gap: "10px" }}>
                <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#474747", }}>TO</p>
                <span style={{ display: 'flex', color: '#b5b5b5', fontSize: '13px', padding: '7px 7px', borderRadius: "10px", border: " 1px solid rgba(176, 176, 176, 1)", width: "150px", overflow: "hidden" }}>

                  <div style={{ width: '10px' }}><span>
                    <AiOutlineCalendar className='me-2 ' style={{ fontSize: '20px', marginTop: "3px", zIndex: "9999", position: "absolute", }} /> </span>
                    <DatePicker className='border-0' showIcon selected={startDate1} onChange={(date) => {
                      setStartDate1(date);
                      setEnd(date.getFullYear() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getDate())
                    }} style={{ backgroundColor: '#111', }} />
                  </div>
                </span>

              </div>
            </div>
            <div className='col-2'>

            </div>
          </div>
          <div className='mt-5' style={{ width: '50%', height: 'auto' }}>
            <label className="filelabel1 w-100 ps-3 pt-2" style={{ background: "#FFFFFF", border: "1px solid #B0B0B0", borderRadius: "5px", color: "#000000", height: "3rem", }}>
              <span className="title1" >
                {Banner.length == 0 ?
                  <h6 className='d-flex pt-1 text-nowrap' src={Images} style={{ width: "100%", textAlign: 'center', color: "#000000", }}>  Upload leave application  <FiUpload className=' ms-2' style={{ color: "#B0B0B0", fontSize: "20px", }} /></h6> : Banner.name}

              </span>
              <input className="FileUpload1" id="FileInput1" name="booking_attachment1" type="file" accept='.png,.jpg' onChange={(e) => { setImages(URL.createObjectURL(e.target.files[0])); setBanner(e.target.files[0]) }} style={{ opacity: '0' }} />
            </label>
          </div>

          <div className='text-center mt-5'>
            {/* <button type="button" class="btn" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop" style={{ background: "#3D70F5", boxShadow: "0px 4px 12px rgba(61, 112, 245, 0.25)", borderRadius: "5px", color: "#FFFFFF", padding: "10px 5px", width: "59%", border: "none", }}>Grant Leave </button> */}
          </div>
        </div>
      </div>
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasWithBackdrop" aria-labelledby="offcanvasWithBackdropLabel" style={{ width: "40%", }}>



        <div class="offcanvas-header">

          <button type="button" data-bs-dismiss="offcanvas" aria-label="Close" style={{ border: "none", background: "#fff", fontSize: "24px", }}> <NavLink to="/Attendance"><IoIosArrowBack style={{ color: "#000", }} /></NavLink></button>
        </div>
        <div class="offcanvas-body">
          {Info?.map((res, index) => {
            return (
              <>
                <p className='ps-4 ' style={{ fontStyle: "normal", fontWeight: "500", fontSize: "28px", color: "#474747", }}>Confirm Leave</p>


                <div className='ps-4' key={index}>
                  <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#A6A6A6", }}>Name of Student : </p><br></br>
                  <div style={{ width: "100%", background: "#FFFFFF", border: "1px solid #B0B0B0", borderRadius: "10px", padding: "5px", }}>
                    <div className='row'>
                      <div className='col-2'>

                        <div
                          style={{
                            backgroundColor: "rgb(237, 255, 243)",
                            marginLeft: "20px",
                            marginRight: "20px",
                            borderRadius: "50%",
                            width: "50%",
                            border: "1px solid #808080",

                          }}
                        >
                          <p
                            style={{
                              color: "rgb(23, 165, 54)",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "3px",
                              fontSize: "18px",
                              fontWeight: "bold",
                              textTransform: "capitalize",
                            }}
                          >
                            {res?.student_name.split("", 1)}
                          </p>
                        </div>
                      </div>
                      <div className='col-3'>
                        <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>{res?.student_name}</p>
                      </div>
                      <div className='col-3'>
                        <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>Duration : {res?.duration}</p>
                      </div>
                      <div className='col-4'>
                        <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", display: 'flex', alignItems: 'center' }}><img src={EE1} className='me-2' style={{ width: "8%", }} /> Batch : {res?.batch_name}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mt-3 ps-4'>
                  <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#A6A6A6", }}>Remark for Leave</p>
                  <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "20px", color: "#474747", }}>{res?.remark}</p>
                </div>
                <div className='mt-5 ps-4'>
                  <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "20px", color: "#474747", }}>Leave Duration</p>

                  <div className='row mt-3 d-flex'>

                    <div className='col-3 d-flex'>
                      <div className='d-flex' style={{ lineHeight: '1px', alignItems: "center", gap: "10px" }}>
                        <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#474747", }}>From</p>


                        <span style={{ display: 'flex', borderRadius: "10px", padding: '7px 7px', color: '#b5b5b5', fontSize: '13px', width: "248px", border: " 1px solid rgba(176, 176, 176, 1)", width: "150px", overflow: "hidden", alignItems: "center" }}>

                          <FaCalendar style={{ color: "#3C3C3C", }} />
                          <span style={{ color: "#3C3C3C", fontWeight: 400, fontSize: 14, paddingLeft: 5, fontFamily: "inter" }}>{res?.from}</span>

                        </span>

                      </div>
                    </div>
                    <div className='col-2'>

                    </div>
                    <div className='col-4  d-flex'>
                      <div className='d-flex' style={{ lineHeight: '1px', alignItems: "center", gap: "10px" }}>

                        <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#474747", }}>TO</p>

                        <span style={{ display: 'flex', borderRadius: "10px", padding: '7px 7px', color: '#b5b5b5', fontSize: '13px', width: "248px", border: " 1px solid rgba(176, 176, 176, 1)", width: "150px", overflow: "hidden", alignItems: "center" }}>

                          <FaCalendar style={{ color: "#3C3C3C", }} />
                          <span style={{ color: "#3C3C3C", fontWeight: 400, fontSize: 14, paddingLeft: 5, fontFamily: "inter" }}>{res?.to}</span>
                        </span>

                      </div>
                    </div>
                    <div className='col-2'>

                    </div>
                  </div>
                </div>
                <div className='mt-5 ps-4'>
                  <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#A6A6A6", }}>Leave Application</p>

                  {/* <p className='text-center mt-3' style={{background:"#FFFFFF",border:"1px solid #B0B0B0",borderRadius:"10px",width:"30%",padding:"10px",}}>leaveforsick.pdf</p> */}
                  <div className="row mt-4">
                    {/* <p
                          className="col-3 mt-1"
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#303030",
                          }}
                        >
                          Banner Image
                        </p> */}
                    <label className="filelabel2 col-2"
                      style={{ backgroundColor: "#fff" }}
                      onClick={() => { }}>

                      <span className="title2">
                        {res?.file.split("", 15)}
                      </span>

                    </label>
                  </div>
                </div>
              </>)
          })}

          <div className='text-center mt-5'>
            <NavLink to="/Attendance"><button class="btn " data-bs-dismiss="offcanvas" aria-label="Close" style={{ width: "35%", border: "1px solid #FF0000", borderRadius: "5px", color: "#FF2121", padding: "10px 3px", }}>Cancel</button></NavLink>
            <NavLink to="/Attendance">
              <button type="submit" class="btn" data-bs-dismiss="offcanvas" aria-label="Close" style={{ marginLeft: "40px", width: "35%", background: "#3D70F5", boxShadow: "0px 4px 12px rgba(61, 112, 245, 0.25)", borderRadius: "5px", color: "#fff", padding: "10px 3px", }}
              // onClick={() => 
              //   {leave(1);
              // setName('');
              // setstu_id('');
              // setStart('');
              // setBanner('');
              // setEnd('');
              // setremark('');
              // }}
              >Confirm</button></NavLink>
          </div>
        </div>
      </div>
      {/* Leave Details */}
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasWithBackdrople" aria-labelledby="offcanvasWithBackdropLabel" style={{ width: "40%", }}>
        <div class="offcanvas-header">

          <button type="button" data-bs-dismiss="offcanvas" aria-label="Close" style={{ border: "none", background: "#fff", fontSize: "24px", }}> <NavLink to="/Attendance"><IoIosArrowBack style={{ color: "#000", }} /></NavLink></button>
        </div>
        <div class="offcanvas-body">
          <p className='ps-4 ' style={{ fontStyle: "normal", fontWeight: "500", fontSize: "28px", color: "#474747", }}> Leave Details</p>

          {data?.map((res, index) => {
            return (
              <>
                <div className='ps-4' key={index}>
                  <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#A6A6A6", }}>Name of Student</p><br></br>
                  <div style={{ width: "100%", background: "#FFFFFF", border: "1px solid #B0B0B0", borderRadius: "10px", padding: "5px", }}>
                    <div className='row'>
                      <div className='col-2'>

                        <div
                          style={{
                            backgroundColor: "rgb(237, 255, 243)",
                            marginLeft: "20px",
                            marginRight: "20px",
                            borderRadius: "50%",
                            width: "50%",
                            border: "1px solid #808080",

                          }}
                        >
                          <p
                            style={{
                              color: "rgb(23, 165, 54)",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "3px",
                              fontSize: "18px",
                              fontWeight: "bold",
                              textTransform: "capitalize",
                            }}
                          >
                            {res?.student_name.split("", 1)}
                          </p>
                        </div>
                      </div>

                      <div className='col-3'>
                        <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>{res?.student_name}</p>
                      </div>
                      <div className='col-3'>
                        <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}>Duration : {res?.duration}</p>
                      </div>
                      <div className='col-4'>
                        <p className='mt-2' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#474747", }}><img src={batch} className='' style={{ width: "1%", }} /> Batch : {res?.batch_name}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mt-3 ps-4'>
                  <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#A6A6A6", }}>Remark for Leave</p>
                  <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "20px", color: "#474747", }}>{res?.remark}</p>
                </div>
                <div className='mt-5 ps-4'>
                  <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "20px", color: "#474747", }}>Leave Duration</p>

                  <div className='row mt-3 d-flex'>

                    <div className='col-3 d-flex'>
                      <div className='d-flex' style={{ lineHeight: '1px', alignItems: "center", gap: "10px" }}>
                        <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#474747", }}>From</p>


                        <span style={{ display: 'flex', borderRadius: "10px", padding: '7px 7px', color: '#b5b5b5', fontSize: '13px', width: "248px", border: " 1px solid rgba(176, 176, 176, 1)", width: "150px", overflow: "hidden", alignItems: "center" }}>

                          <FaCalendar style={{ color: "#3C3C3C", }} />
                          <span style={{ color: "#3C3C3C", fontWeight: 400, fontSize: 14, paddingLeft: 5, fontFamily: "inter" }}>{res?.from}</span>
                        </span>

                      </div>
                    </div>

                    <div className='col-2'>

                    </div>
                    <div className='col-4  d-flex'>
                      <div className='d-flex' style={{ lineHeight: '1px', alignItems: "center", gap: "10px" }}>
                        <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#474747", }}>TO</p>

                        <span style={{ display: 'flex', borderRadius: "10px", padding: '7px 7px', color: '#b5b5b5', fontSize: '13px', width: "248px", border: " 1px solid rgba(176, 176, 176, 1)", width: "150px", overflow: "hidden", alignItems: "center" }}>

                          <FaCalendar style={{ color: "#3C3C3C", }} />
                          <span style={{ color: "#3C3C3C", fontWeight: 400, fontSize: 14, paddingLeft: 5, fontFamily: "inter" }}>{res?.to}</span>
                        </span>

                      </div>
                    </div>
                    <div className='col-2'>

                    </div>
                  </div>
                </div>
                <div className='mt-5 ps-4'>
                  <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#A6A6A6", }}>Leave Application</p>

                  {/* <p className='text-center mt-3' style={{background:"#FFFFFF",border:"1px solid #B0B0B0",borderRadius:"10px",width:"30%",padding:"10px",}}>leaveforsick.pdf</p> */}
                  <div className="row mt-4">

                    <label className="filelabel2 col-2"
                      style={{ backgroundColor: "#fff" }}
                      onClick={() => { }}>

                      <span className="title2">
                        {res?.file.split("", 15)}
                      </span>
                      <a className="FileUpload2" id="FileInput2"
                      ></a>
                    </label>
                  </div>
                </div>
              </>)
          })}

          <div className='text-center mt-5'>
            {/* <NavLink to="/Attendance"><button  class="btn "style={{width:"35%",border:"1px solid #FF0000",borderRadius:"5px",color:"#FF2121",padding:"10px 3px",}}>Cancel</button></NavLink> */}
            <NavLink to="/dashboard"><button class="btn" style={{ marginLeft: "40px", width: "35%", background: "#3D70F5", boxShadow: "0px 4px 12px rgba(61, 112, 245, 0.25)", borderRadius: "5px", color: "#fff", padding: "10px 3px", }}>Back TO Dashboard</button></NavLink>
          </div>
        </div>
      </div>
    </>



  )
}

