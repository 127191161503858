import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { IoIosWarning } from "react-icons/io";
import { BsFillCalendarFill } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL, img_url } from "../env";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';

const GrantSalary = (props) => {
  const Navigate = useNavigate();
    const [startDate1, setStartDate1] = useState(new Date());
    const [startDate2, setStartDate2] = useState(new Date());
    const [amount,setAmount] = useState("");
    const [present,setPresent] = useState("");
    const [halfDay,setHalfDay] = useState("");
    const [absent,setAbsent] = useState("");
    const [data, setData] = useState(1);
    const [value, setvalue] = useState("present");
    const [presentTable,setPresentTable] = useState([]);
    const [presentDays,setPresentDays] = useState("0");
    const [halfDays,setHalfDays] = useState("0");
    const [absentDays,setAbsentDays] = useState("0");
    const [selectedDays,setSelectedDays] = useState("0");
    const [grantAmount,setGrantAmount] = useState("0")

const [show, setShow] = useState(false);
        const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

const [show1, setShow1] = useState(false);
        const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);



    const getAttendace = async()=>{
      try{
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
          );
          var raw = JSON.stringify({
            "enrollment_id": props.enr_id,
            "start_date": startDate1.toISOString(),
            "end_date": startDate2.toISOString()
          });
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          
          fetch(`${API_URL}/admin/employee/get/bulka/attendence/employee`, requestOptions)
          .then(response=>response.json())
          .then((result)=>{
            if (result.status == 1) {
              setData(2);
              setPresentTable(result.present_list);
              setHalfDay(result.halfday_list);
              setPresentDays(result.present_days);
              setHalfDays(result.half_days);
              setAbsentDays(result.absent_days);
              setSelectedDays(result.selected_days);
              setGrantAmount(result.total_amount)
            } 
            else if (result.status === 'VAL_ERR') { 
              toast(result.Backend_Error) 
            }  
           else if (result.status == "CUSTOM_ERR") { 
              toast(result.Backend_Error); 
            } else if (result.status == "TOKEN_ERR") { 
              localStorage.removeItem('api_url'); 
              Navigate('/code') 
              toast("Logged in other device! Please login again") 
            } 
          })
          .catch((e)=>{
            console.log(e);
          })
      }catch(e){
        console.log(e);
      }
    }


       const salaryGrant = async()=>{
      try{
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
          );
          var raw = JSON.stringify({
            "emp_id": props.id,
            "department_id": props.department_id,
            "emp_name": props.name,
            "department_name": props.department,
            "amount": grantAmount,
            "present": presentDays,
            "absent": absentDays,
            "halfday": halfDays,
            "from_date": startDate1.toISOString(),
            "to_date": startDate2.toISOString()
          });
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          
          fetch(`${API_URL}/admin/employee/grant/salary/to/employee`, requestOptions)
          .then(response=>response.json())
          .then((data)=>{
              if(data.status==1){
                toast.success(data.msg, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                  });
                  setGrantAmount("");
                  setPresentDays("");
                  setAbsentDays("");
                  setHalfDays("");
                  getAttendace();
              }else{
                toast.error(data.Backend_Error, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                  });
              }
          })
          .catch((e)=>{
            console.log(e);
          })
      }catch(e){
        console.log(e);
      }
    }

  return (
    <>
      {/* <div className="container-fluid p-0 h-100">
        <div className="row h-100 p-0"> */}
        <div className="col-8" style={{}}>
        {data == 1 ? (
          <>
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="card w-50 mx-auto p-3 ">
                <h5 className="mb-0 text-center">
                  {" "}
                  <IoIosWarning /> please select date
                </h5>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="mt-3 ">
              <div className="row">
                     {/* <div className="col-10 card py-3 px-3" > */}

                     <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="border-0" closeButton >
        </Modal.Header>
        <Modal.Body >
        <div className="col-12 py-3 pt-1 px-3" >
               <Form>

<Form.Group className="mb-3" controlId="formGridAddress1">
<Form.Label>Amount</Form.Label>
<InputGroup className="mb-3">
<InputGroup.Text id="basic-addon1">₹</InputGroup.Text>
<Form.Control
type="number"
value={grantAmount}
onChange={(e)=>setGrantAmount(e.target.value)}
// placeholder="5,000"
aria-describedby="basic-addon1"
/>
</InputGroup>
</Form.Group>

<Row className="mb-3">
<Form.Group as={Col} controlId="formGridCity">
<Form.Label>Present</Form.Label>
<Form.Control value={presentDays} onChange={(e)=>setPresentDays(e.target.value)} type="number" />
</Form.Group>

<Form.Group as={Col} controlId="formGridZip">
<Form.Label>Absent</Form.Label>
<Form.Control value={absentDays} onChange={(e)=>setAbsentDays(e.target.value)} type="number" />
</Form.Group>

<Form.Group as={Col} controlId="formGridZip">
<Form.Label>Half-Day</Form.Label>
<Form.Control value={halfDays} onChange={(e)=>setHalfDays(e.target.value)} type="number" />
</Form.Group>
</Row>





<Button variant="primary" className="w-100 py-2" onClick={handleShow} >
Submit
</Button>
</Form>
</div>
        </Modal.Body>
      </Modal>
     
<Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton >
        <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body >
        <h5 className="text-center mt-5" >
          Are you sure you want to Grant Salary?
        </h5>
        </Modal.Body>
        {/* <Modal.Footer > */}
          {/* <Button variant="secondary" onClick={()=>{
            salaryGrant()
            handleClose()
          }}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleClose} >No</Button> */}
          <div className='row mb-3 px-2 mt-5'>
                    <div className='col-6'>
                      <button  onClick={()=>{
            salaryGrant()
            handleClose()
            handleClose1()
          }} type="button" class="btn " style={{ width: "100%", background: "#2188E7", boxShadow: "0px 2px 4px rgba(61, 112, 245, 0.25)", borderRadius: "10px", border: "none", color: "#fff", padding: "10px 10px", }}>Yes, I am Sure</button>
                    </div>
                    <div className='col-6'>
                      <button onClick={handleClose}  style={{ width: "100%", background: "#CC1313", boxShadow: "0px 2px 4px rgba(61, 112, 245, 0.25)", borderRadius: "10px", border: "none", color: "#fff", padding: "10px 10px", }}>No, Cancel Process</button>
                    </div>

                  </div>
        {/* </Modal.Footer> */}
      </Modal>




            

            {/* </div> */}
              </div>
            </div>

            {/* tabs  */}
            <div className="row mt-3">
              <div className="col-12 d-flex justify-content-between align-items-center">
              <div className="d-flex gap-3 align-items-center" >
                <span
                  className="py-2 px-3"
                  onClick={() => setvalue("present")}
                  style={{
                    cursor: "pointer",
                    borderBottom:
                      value == "present" ? "2px solid #8147E7" : "none",
                    color: value == "present" ? "#8147E7" : "",
                    fontWeight: value == "present" ? 700 : 400,
                  }}
                >
                  Present
                </span>
                <span
                  className="py-2 px-3"
                  onClick={() => setvalue("half_day")}
                  style={{
                    cursor: "pointer",
                    borderBottom:
                      value == "half_day" ? "2px solid #8147E7" : "none",
                    color: value == "half_day" ? "#8147E7" : "",
                    fontWeight: value == "half_day" ? 700 : 400,
                  }}
                >
                  Half Day
                </span>
              </div>
                <button className="btn btn-primary align-self-end" onClick={handleShow1} >Grant Salary</button>
              </div>

              {/* <div style={{ borderBottom: "1px solid #e4e4e4" }}></div> */}

              <div className="row">
                <div className="col-12">
                  {
                    value == "present"?
                    <>
                      <div className=" p-2 mt-2" style={{height:"60vh",overflowY:"scroll"}} >
                      <DataTable value={presentTable}  tableStyle={{ minWidth: '50rem'}}>
                <Column field="id" header="Id" style={{ width: '10.6%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }}></Column>
                <Column field="in_time" header="Punch-In" style={{ width: '17.8%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }} bodyStyle={{fontWeight:500}}></Column>
                <Column field="out_time" header="Punch-Out" style={{ width: '17.8%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }} bodyStyle={{fontWeight:500}}></Column>
                <Column field="attendence_date" header="Attendance Date" style={{ width: '17.8%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }} bodyStyle={{fontWeight:500}}></Column>
                <Column field="status" header="Status" style={{ width: '17.8%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }} bodyStyle={{fontWeight:500}}></Column>
                  </DataTable>
                      </div>
                    </>:<></>
                  }

                  {
                    value=="half_day"?
                    <>
                    <div className=" p-2 mt-2" style={{height:"60vh",overflowY:"scroll"}} >
                      <DataTable value={halfDay}  tableStyle={{ minWidth: '50rem'}}>
                <Column field="id" header="Id" style={{ width: '10.6%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }}></Column>
                <Column field="in_time" header="Punch-In" style={{ width: '17.8%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }} bodyStyle={{fontWeight:500}}></Column>
                <Column field="out_time" header="Punch-Out" style={{ width: '17.8%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }} bodyStyle={{fontWeight:500}}></Column>
                <Column field="attendence_date" header="Attendance Date" style={{ width: '17.8%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }} bodyStyle={{fontWeight:500}}></Column>
                <Column field="status" header="Status" style={{ width: '17.8%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }} bodyStyle={{fontWeight:500}}></Column>
                </DataTable>
                      </div>
                    </>:<></>
                  }
                </div>
              </div>
            </div>
            {/* tabs  */}
          </>
        )}
      </div>

            <div className="col-2 p-2 bg-white its d-flex">
        <div className="w-100" style={{ marginTop: "40px" }}>
          <h5 className="mb-0">Select date range</h5>

          <div>
            <h6 className="mb-0 mt-3">From</h6>
            <span
              className="w-100 py-1"
              style={{
                display: "flex",
                backgroundColor: "#EDEDF5",
                borderRadius: "5px",
                color: "#b5b5b5",
                fontSize: "13px",
              }}
            >
              <BsFillCalendarFill
                className="ms-2"
                style={{ fontSize: "18px", color: "#3C3C3C", marginTop: "8px" }}
              />
              <div>
                <DatePicker
                  className="border-0  ps-2 pt-1  datep"
                  showIcon
                  selected={startDate1}
                  onChange={(date) => {
                    setStartDate1(date);
                    //     const req_date = date.getFullYear() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getDate();
                    //    CollectData(req_date);
                  }}
                  style={{ backgroundColor: "#E0E0E0", outline: "0" }}
                />
              </div>
            </span>
          </div>

          <div>
            <h6 className="mb-0 mt-3">To</h6>
            <span
              className="w-100 py-1"
              style={{
                display: "flex",
                backgroundColor: "#EDEDF5",
                borderRadius: "5px",
                color: "#b5b5b5",
                fontSize: "13px",
              }}
            >
              <BsFillCalendarFill
                className="ms-2"
                style={{ fontSize: "18px", color: "#3C3C3C", marginTop: "8px" }}
              />
              <div>
                <DatePicker
                  className="border-0  ps-2 pt-1  datep"
                  showIcon
                  selected={startDate2}
                  onChange={(date) => {
                    setStartDate2(date);
                    //     const req_date = date.getFullYear() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getDate();
                    //    CollectData(req_date);
                  }}
                  style={{ backgroundColor: "#E0E0E0", outline: "0" }}
                />
              </div>
            </span>
          </div>

          <div className="mt-4">
            <button
              // onClick={BulkAttendanceData}
              onClick={()=>{
                if(startDate1<=0){
                  toast.error("pls select start data");
                }else if(startDate2<=0){
                  toast.error("pls select end date");
                }else{
                  getAttendace();
                }
              }}
              className="w-100 py-2 border-0"
              style={{
                borderRadius: "5px",
                backgroundColor: "#8147E7",
                color: "#fff",
              }}
            >
              View Attendance
            </button>
          </div>
        </div>
            </div>
        {/* </div>
      </div> */}





      <ToastContainer/>
    </>
  );
};

export default GrantSalary;

