import React, { useState, useEffect, useContext } from 'react';
import EE from '../images/ee.png';
import General from './general';
import Quiz from './quiz';
import Exam from './exam';
import Assignment from './assignment';
import Classes from './classes';
import Fees from './fees';
import { IoArrowBackSharp } from "react-icons/io5";
import { NavLink, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { AuthContext } from './AuthContext';
import { useParams } from 'react-router-dom';
// import { API_URL } from './MyComponent';
import { ToastContainer, toast } from 'react-toastify';


import { API_URL,img_url } from '../env';

const Profilestudent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { apiToken } = useContext(AuthContext);


  const [value, setvalue] = useState();
  const [Catg, setCatg] = useState("");
  const [Show, setShow] = useState(false);
  const [Show1, setShow1] = useState(false);
  const [details, setDetails] = useState([]);
  const [msg, setmsg] = useState();
  const [IdForDel, setIdForDel] = useState("");
  const [date, setDate] = useState('');
const [Amount, setAmount] = useState('');
  // console.log(profile);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);



  const Profile = async () => {
    var myHeaders = new Headers();
    myHeaders.append("authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/student/profile_students?student_id=${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        if (result.status == "001") {
          setDetails([result.total_students]);
  

        }
        else { console.log(result.error) }

      })

      .catch(error => console.log('error', error));


  }

  const dropButton = async () => {
    // console.log(id, 'id for drop')
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/student/drop_studentStatus?student_id=${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        if (result.status == 1) {
          setmsg(result.message);
          handleShow1();
          Profile();
          dropDate();
        }
        else { toast(result.message) }

      })
      .catch(error => console.log('error', error));

  }


  const dropDate = async () => {
    // console.log(id, 'id for drop date');
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/student/drop_studentDate?student_id=${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result, 'drop date');
        if (result.status == "001") {
          if (result.drop_studentDate) {
            setDate(result.drop_studentDate.datetime);
          }
          else {
            console.log('std is active');
          }
        }
        ;
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    setvalue('general')
    Profile();
    dropDate();
  }, []);



  return (
    <div>
      {details?.map((res, key) => {

        return (
          <>
            <div className='row' key={res.id}>


              <div className='col-lg-11 col-md-10 col-sm-12 ' style={{ display: "flex", justifyContent: "space-between" }}>

                <p className='mt-3 ps-3' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "25px", color: "#3C3C3C", }}>

                  {/* <NavLink to="/studentviewlist/0"> */}
                  <IoArrowBackSharp style={{ fontSize: "25px", color: "#000",cursor:"pointer" }} onClick={() => {
                    return navigate(-1);
                  }} />
                  {/* </NavLink>  */}

                  Student Profile</p>
                {
                  res.status == 0 ? <button onClick={() => { handleShow(); setIdForDel(res.id) }} type='button' className='mt-3 px-2' style={{ border: "1px solid #E72121", borderRadius: "10px", color: "red", backgroundColor: "#FFEDED", fontSize: "14px", width: "10%" }} >Drop</button> :
                    <>
                      <p className='text-center mt-3 px-2 py-2' style={{ border: "1px solid #E72121", borderRadius: "10px", color: "red", backgroundColor: "#FFEDED", fontSize: "14px", width: "15%" }}>Dropped On - {date}</p>
                    </>
                }


              </div>
            </div>



            <div className='row mt-5'>
              <div className='col-1 mt-2'>
                <div style={{ backgroundColor: "rgb(237, 255, 243)", marginLeft: '40px', borderRadius: '50%', width: '60px', border: '1px solid #808080', height: "60px" }}>
                  <p style={{ color: "rgb(23, 165, 54)", display: "flex", justifyContent: "center", marginTop: "12px", fontSize: "25px", fontWeight: "bold", textTransform: "capitalize" }}>{res.name.split("", 1)}</p>
                </div>
              </div>

              <div className='col-4'>
                <div className='mt-2'>
                  <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "20px", color: "#3C3C3C", textTransform: "capitalize" }}>{res.name}</p>
                  <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "14px", color: "#3C3C3C", }}>Student ID:{res.enrollment_id}</p>
                  <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "12px", color: "#3C3C3C", }}>Gender:</p>
                </div>
              </div>
              <div className='col-4'>
                <div style={{ backgroundColor: "#F2EDFD", width: "370px", height: "90px", paddingLeft: "20px", paddingTop: "5px", width: "215px", marginLeft: "100px", borderRadius: "5px", }}>
                  <p style={{ fontWeight: "600", color: "#3C3C3C", fontSize: "20px", }}>Attendance</p>

                  <div style={{ display: "flex" }}>
                    <p style={{ color: "#8147E7", fontSize: "40px", fontWeight: "600" }}>0%  </p>
                    <p style={{ paddingLeft: "37px", paddingTop: "16px", fontWeight: "600" }} ><span style={{ color: "#3EDC4E" }}> 0</span>/100 </p>
                  </div>
                </div>
              </div>

            </div>




            <div className='row mt-2' style={{ display: "flex", alignItems: 'center', borderBottom: "1px solid #E4E4E4", paddingLeft: "30px", color: "#707070", fontSize: "16px", }}>


              <span className='col-1 py-3' onClick={() => { setvalue('general') }} style={{ borderBottom: value == "general" ? "2px solid blue" : "", color: value == "general" ? "#3D70F5" : "", width: "6%", marginTop: "14px", cursor: "pointer", }}>
                <p>General</p>
              </span>

              <span className='col-1 py-3' onClick={() => { setvalue('Quiz') }} style={{ borderBottom: value == "Quiz" ? "2px solid blue" : "", color: value == "Quiz" ? "#3D70F5" : "", width: "4%", marginLeft: "50px", marginTop: "14px", cursor: "pointer", }}>
                <p >Quiz</p>
              </span>

              <span className='col-1 py-3' onClick={() => { setvalue('Exams') }} style={{ borderBottom: value == "Exams" ? "2px solid blue" : "", color: value == "Exams" ? "#3D70F5" : "", width: "5%", marginLeft: "50px", marginTop: "14px", cursor: "pointer", }}>
                <p style={{}}>Exams</p>
              </span>

              <span className='col-1 py-3' onClick={() => { setvalue('Assignment') }} style={{ borderBottom: value == "Assignment" ? "2px solid blue" : "", color: value == "Assignment" ? "#3D70F5" : "", width: "7%", marginLeft: "50px", marginTop: "14px", cursor: "pointer", }}>
                <p style={{}}>Assignment</p>
              </span>
              <span className='col-1 py-3' onClick={() => { setvalue('Classes') }} style={{ borderBottom: value == "Classes" ? "2px solid blue" : "", color: value == "Classes" ? "#3D70F5" : "", width: "5%", marginLeft: "50px", marginTop: "14px", cursor: "pointer", }}>
                <p style={{}}>Classes</p>
              </span>
              <span className='col-1 py-3' onClick={() => { setvalue('Fees') }} style={{ borderBottom: value == "Fees" ? "2px solid blue" : "", color: value == "Fees" ? "#3D70F5" : "", width: "4%", marginLeft: "50px", marginTop: "14px", cursor: "pointer", }}>
                <p style={{}}>Fees</p>
              </span>

            </div>

            <div className='row'>
              <div className='col-12'>
                {
                  value === 'general' ? <><General item={details} /></> : <></>
                }
                {
                  value === 'Quiz' ? <> <Quiz  /></> : <></>
                }
                {
                  value === 'Exams' ? <><Exam /></> : <></>
                }
                {
                  value === 'Assignment' ? <><Assignment /></> : <></>
                }
                {
                  value === 'Classes' ? <><Classes /></> : <></>
                }
                {
                  value === 'Fees' ? <><Fees item={details}/></> : <></>
                }

              </div>
            </div>

            <Modal show={Show} onHide={handleClose} animation={false}>

              <Modal.Body>
                <div className='text-center'>
                  <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "25px", color: "#474747", }}>Remove student ?</p>
                  <p className='mt-4' style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#474747", }}>Please Confirm</p>
                </div>
                <div className='row mb-3 px-2 mt-5'>
                  <div className='col-6'>
                    <button type="button" class="btn" data-bs-dismiss="modal" style={{ width: "100%", background: "#CC1313", boxShadow: "0px 2px 4px rgba(61, 112, 245, 0.25)", borderRadius: "10px", border: "none", color: "#fff", padding: "10px 10px", }} onClick={() => { handleClose(); dropButton() }} >Yes, remove this student</button>
                  </div>
                  <div className='col-6'>
                    <button type="button" class="btn " style={{ width: "100%", background: "#2188E7", boxShadow: "0px 2px 4px rgba(61, 112, 245, 0.25)", borderRadius: "10px", border: "none", color: "#fff", padding: "10px 10px", }} onClick={() => { handleClose() }}>No, Cancel this process</button>
                  </div>

                </div>
              </Modal.Body>

            </Modal>

            <Modal show={Show1} onHide={handleClose1} animation={false}>

              <Modal.Body>
                <div className='text-center'>
                  <p className='mt-3' style={{ fontStyle: "normal", fontWeight: "600", fontSize: "25px", color: "#474747", }}>{msg}</p>

                </div>
                <div className='row mb-3 px-2 mt-5 text-center'>

                  <div className='col-12'>

                    <button type="button" class="btn " style={{ width: "50%", background: "#2188E7", boxShadow: "0px 2px 4px rgba(61, 112, 245, 0.25)", borderRadius: "10px", border: "none", color: "#fff", padding: "10px 10px", }} onClick={() => { handleClose1() }}>OK</button>
                  </div>

                </div>
              </Modal.Body>

            </Modal>
            <ToastContainer />
          </>


        )

      })}

    </div>


  );





}

export default Profilestudent;
