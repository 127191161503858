import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


// import { CustomerService } from './service/CustomerService';

import "primereact/resources/themes/lara-light-cyan/theme.css";

import Header from "./header";
import Sidebar from "./sidebar";
import { NavLink, Link } from "react-router-dom";
import { BsSearch } from 'react-icons/bs';
import {API_URL} from "../env"
const S = () => {

    const [studentsData,setStudentsData] = useState([]);
    const [TotalStudents,setTotalStudents] = useState("");
    const [enrollmentIdSearch, setEnrollmentIdSearch] = useState("");
    const [nameSearch, setNameSearch] = useState("");
    const [searchStudent,setSearchStudent] = useState([]);
    // console.log(enrollmentIdSearch);
    // console.log(nameSearch);

    const getData = async()=>{
      try{
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${localStorage.getItem("token")}`
        );
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
   
        fetch(`${API_URL}/admin/student/get/dashboard/students`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("API Result:", result);
          if (result.status === 1) {
            setStudentsData(result.students);
            setTotalStudents(result.total_students);
          } else {
            console.error( result.message);
          }
        })
    .catch(error => console.log('error', error));
  
      }catch(e){
        console.log(e);
      }
  
    }

   
    const filterData =async(id,names) =>{
     
      try {
        var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append(
  "Authorization",
  `Bearer ${localStorage.getItem("token")}`
);

var raw = JSON.stringify({
  "enrollment_id": id,
  "student_name":names
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${API_URL}/admin/student/search/in/student`, requestOptions)
  .then(response => response.json())
  .then(result =>{ console.log(result.students)
  
  setStudentsData(result.students)
}
  )
  .catch(error => console.log('error', error));
      } catch (error) {
        console.log(error);
      }
    }

  
    useEffect(() => {
      getData();
    }, []);

   
  

  return (
    <>
      <div style={{ width: "100%", height: "100vh" }}>
        <div
          className="head"
          style={{ width: "80%", height: "10vh", marginLeft: "16%" }}
        >
          <Header />
        </div>
        <div style={{ width: "100%", height: "90vh", display: "flex" }}>
          <div
            style={{
              width: "20%",
              height: "100%",
              position: "absolute",
              top: 0,
              marginLeft: "1%",
            }}
          >
            <Sidebar />
          </div>

          <div className='maincontain' style={{ marginLeft: '15%', width: '100%', height: '100%', overflowY: 'scroll', }} >
            
            <div className="mt-2 d-flex justify-content-between align-items-center" >
            <div>
                <h5 className="ms-3 mb-0" >Total Students : {TotalStudents}</h5>
            </div>
                <div className='text-end px-5'>
                <NavLink to="/addExcel"><button className='me-3' style={{ backgroundColor: "#8147E7", color: "white", border: "none", width: "150px", height: "45px", borderRadius: "5px" }} >+ Add Excel</button></NavLink>
                <NavLink to="/addstudents"><button style={{ backgroundColor: "#8147E7", color: "white", border: "none", width: "150px", height: "45px", borderRadius: "5px" }} >+ Add Student</button></NavLink>
                </div>
            </div>

          <div className="d-flex gap-3 align-items-center mt-2 px-3" >
          <form className="d-flex" role="search">
              <span className="input-group-text" id="basic-addon1" style={{ backgroundColor: '#fff', borderRight: 'none', borderColor: '#E4E2F1', borderTopRightRadius: 0, borderBottomRightRadius: 0, color: '#B1AFC8' }}><BsSearch /></span>
              <input type="number" value={enrollmentIdSearch} 
            onChange={(e) =>{ setEnrollmentIdSearch(e.target.value); filterData(e.target.value,"")}} className="form-control" placeholder="Search By Enrollment Id" aria-label="Username" aria-describedby="basic-addon1" style={{ borderLeft: 'none', borderColor: '#E4E2F1', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} />
            </form>
            <form className="d-flex" role="search">
              <span className="input-group-text" id="basic-addon1" style={{ backgroundColor: '#fff', borderRight: 'none', borderColor: '#E4E2F1', borderTopRightRadius: 0, borderBottomRightRadius: 0, color: '#B1AFC8' }}><BsSearch /></span>
              <input type="text" value={nameSearch}
            onChange={(e) => {setNameSearch(e.target.value); filterData("",e.target.value)}}  className="form-control" placeholder="Search By Name" aria-label="Username" aria-describedby="basic-addon1" style={{ borderLeft: 'none', borderColor: '#E4E2F1', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} />
            </form>
          </div>


          <div className="mt-1" style={{padding:"1rem",borderRadius:"10px",marginBottom:"1rem"}} >
            <DataTable value={studentsData} paginator rows={7}  tableStyle={{ minWidth: '50rem' }}>
                <Column field="enrollment_id" header="Id" style={{ width: '10.6%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }}></Column>
                <Column field="student_name" header="Student Name" style={{ width: '17.8%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }} bodyStyle={{fontWeight:500}}></Column>
                <Column field="batch_name" header="Batch Name" style={{ width: '17.8%',padding:"1rem 1rem",borderTop:"2px solid black",borderBottom:"2px solid black" }} bodyStyle={{fontWeight:500}}></Column>
                <Column
  body={(rowData) => (
    <Link to={`/PaymentSetup/${rowData.id}`} className='text-decoration-none' >
      <span style={{ color: "#0a58ca", cursor: "pointer", fontWeight: 500 }}>
        Add Payment
      </span>
    </Link>
  )}
  header="+ Add Payment"
  style={{
    width: '17.8%',
    padding: "1rem 1rem",
    borderTop: "2px solid black",
    borderBottom: "2px solid black",
  }}
  bodyStyle={{ color: "#0a58ca", cursor: "pointer", fontWeight: 500 }}
></Column>
                <Column
  body={(rowData) => (
    <Link to={`/PaymentHistory/${rowData.id}`} className='text-decoration-none'>
      <span style={{ color: "#0a58ca", cursor: "pointer", fontWeight: 500 }}>
        Payment History
      </span>
    </Link>
  )}
  header="Action"
  style={{
    width: '17.8%',
    padding: "1rem 1rem",
    borderTop: "2px solid black",
    borderBottom: "2px solid black",
  }}
  bodyStyle={{ color: "#0a58ca", cursor: "pointer", fontWeight: 500 }}
></Column>
                               <Column
  body={(rowData) => (
    <Link to={`/StudentAttendance/${rowData.id}/${rowData.enrollment_id}`} className='text-decoration-none'>
      <span style={{ color: "#0a58ca", cursor: "pointer", fontWeight: 500 }}>
        Attendance
      </span>
    </Link>
  )}
  header="Action"
  style={{
    width: '17.8%',
    padding: "1rem 1rem",
    borderTop: "2px solid black",
    borderBottom: "2px solid black",
  }}
  bodyStyle={{ color: "#0a58ca", cursor: "pointer", fontWeight: 500 }}
></Column>
            </DataTable>
        </div>

          </div>




        </div>
      </div>
    </>
  );
};

export default S;