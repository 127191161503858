import React from 'react'
import lotti from '../images/127549-coming-soon.json';
import Lottie from "lottie-react";
import { NavLink } from 'react-router-dom';

const LeaveHistory = () => {
  return (
    <div style={{ width: '100%', height: '100vh', }}>

    <div style={{ width: '100%', height: '86vh', }}>



      <div className='d-flex ' style={{ justifyContent: "center", alignItems: "center" }}>
        <Lottie animationData={lotti} style={{ width: "31%", height: "25rem", marginLeft: "0rem", }} />
      </div>

      <div className=''>
        <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "40px", textAlign: "center", color: "#3C3C3C", }}>This feature will be live Soon !!</p>
      </div>

      <div className='text-center mt-5'>
        <NavLink to="/Dashboard"><button type="button" class="btn" style={{ background: "#F2EDFD", borderRadius: "5px", padding: "14px 55px", fontSize: "15px", fontWeight: "400", color: "#8147E7", border: "none", }}>Back To Dashboard</button></NavLink>
        {/* <NavLink to=""><button type="button" class="btn" style={{ background: "#8147E7", boxShadow: "0px 4px 12px rgba(126, 57, 247, 0.36)", borderRadius: "5px", color: "#FFFFFF", padding: "14px 55px", fontSize: "15px", fontWeight: "400", marginLeft: "20px", border: "none", }}>Join the Waitlist</button></NavLink> */}
      </div>

    </div>
  </div>
  )
}

export default LeaveHistory
