import React, { useState, useEffect } from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import DatePicker from "react-datepicker";
import { BiCalendar } from 'react-icons/bi';
import { BsFillCalendarFill } from "react-icons/bs";
import { FiUpload } from "react-icons/fi";
import { AiOutlineCalendar } from 'react-icons/ai';
import lotti from '../images/127549-coming-soon.json';
import Lottie from "lottie-react";
import { IoFunnelOutline } from "react-icons/io5";
import { IoNewspaperOutline } from "react-icons/io5";
import { IoEyeOutline } from "react-icons/io5";
import "./Leav.css";
import "react-datepicker/dist/react-datepicker.css";
// import ToastContainer from "react-bootstrap/ToastContainer";
// import Toast from "react-bootstrap/Toast";
import { NavLink } from 'react-router-dom';
 import { API_URL } from '../env';
import { AuthContext } from './AuthContext';
import { useContext } from 'react';
import * as XLSX from 'xlsx';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
//const {API_URL,img_url} =env;


export default function Allinvoce() {



  let Data = new Date();



  const initialDate = moment('Tue Jul 18 2023 16:49:07 GMT+0530', 'ddd MMM D YYYY HH:mm:ss [GMT]ZZ (z)').toDate();


  const { apiToken } = useContext(AuthContext);
  const [IdEmploy, setIdEmploy] = useState();
  const [startDate, setStartDate] = useState();
  const [startDate1, setStartDate1] = useState('');
  const [startDate3, setStartDate3] = useState(new Date());
  const [startDate4, setStartDate4] = useState(new Date());
  const [Images, setImages] = useState();
  const [Banner, setBanner] = useState("");
  const [value, setvalue] = useState('');
  const [Employee, setEmployee] = useState([])
  const [Leaveslist, setLeaveslist] = useState([])
  const [reason, setreason] = useState("");
  const [Sedule, setSedule] = useState("");
  const [Sedule1, setSedule1] = useState("");
  const [ShowLoader, setShowLoader] = useState(false);
  const [message, setmessage] = useState("");
  const [ViewData, setViewData] = useState([]);
  const [Id, setId] = useState("");
  const [ShowModel, setShowModel] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const convertToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
  
    // Map the array to include only the desired properties
    const selectiveDataArray = Leaveslist.map((Leaveslist) => ({
      Name: Leaveslist.employee_name,
      Department: Leaveslist.department_name,
      Role: Leaveslist.role_name,
      From: Leaveslist.from,
      To: Leaveslist.to,
    }));
  
    // Convert the selective data array into a worksheet
    const worksheet = XLSX.utils.json_to_sheet(selectiveDataArray);
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
    // Generate a file name
    const fileName = 'Staff_Details.xlsx';
  
    // Generate Excel file binary data
    const excelData = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
  
    // Create a blob object from the binary data
    const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
    // Create a download link
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
  
    // Simulate a click to trigger the download
    link.click();
  
    // Clean up
    URL.revokeObjectURL(url);
  };
  




  console.log("gfgf  g h",Data);

  const ApiForEmployee = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/team/list_employee`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        if (result.status == "1") {
          setEmployee(result.all_members);
        }
        else {

        }


      })
      .catch(error => console.log('error', error));
  }

  const ApiForLeaveslist = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/team/list_leave`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log("ghjkl", result);
        if (result.status == "001") {
          setLeaveslist(result.leaves);
          const date = new Date(result.fromDate);
          const date1 = new Date(result.toDate)
           setStartDate(moment(date.toString(), 'ddd MMM D YYYY HH:mm:ss [GMT]ZZ (z)').toDate());
           setStartDate1(moment(date1.toString(), 'ddd MMM D YYYY HH:mm:ss [GMT]ZZ (z)').toDate())
         //console.log("hello",date.toString());
          
        }
        else {

        }
      })
      .catch(error => console.log('error', error));
  }

console.log(startDate, 'date');

    

  const HandleProcess = () => {
    if (
      IdEmploy <= 1 ||
      reason.length <= 1 ||
      Banner.length <= 1 ||
      Banner.length <= 1 ||
      Sedule.length <= 1 ||
      Sedule1.length <= 1 
    ) {
      toast(
        IdEmploy <= 1
          ? 'Please Select Employee'
          :  reason.length <= 1
            ? 'Please Enter Reason'
            : Banner.length <= 11
              ? 'Please Upolad Application'
              : Sedule.length <= 1
                ? 'Please Enter Start Date'
                : Sedule1.length <= 1
                  ? 'Please Enter End Date'
                  :  'Please fill in all the details'
      );
    }


    else {
      LiveSend();
    }
  }




  const LiveSend = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var formdata = new FormData();
    formdata.append("emp_id", IdEmploy);
    formdata.append("reason", reason);
    formdata.append("file", Banner);
    formdata.append("from", Sedule);
    formdata.append("to", Sedule1);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${API_URL}/team/leave`, requestOptions)
      .then(response => response.json())
      .then(result => {
         console.log(result);
        if (result.status == "001") {
          setShowModel(true);
          toast(result.message);
          ApiForLeaveslist();
          setIdEmploy("");
          setreason("");
          setBanner("");
          setSedule("");
          setSedule1("");
          setStartDate3(new Date());
          setStartDate4(new Date());
          handleClose();
          handleShow1();
        }
        else {
          setShowLoader(true);
          toast(result.message);
        }
      })
      .catch(error => console.log('error', error));
      //.finally(()=>{setShowModel(true);});

  }



  const ViewProfileList = async (id) => {
    // console.log(id);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_URL}/team/view_leave?leave_id=${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status == "001") {
          setViewData(result.leaves);
          // console.log(result);
        }
        else {
          console.log("error");
        }
      })
      .catch(error => console.log('error', error));
  }



  useEffect(() => {
    setvalue('Accounts');
    ApiForEmployee();
    ApiForLeaveslist();
    ViewProfileList();

  }, []);




  return (
    <>
    <ToastContainer/>
      {/* <div className="card mt-4 ms-4" style={{ width: "95%", border: "none" }}>
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <p style={{ fontWeight: "400", color: "#8A8A8A", fontSize: "18px" }}>Date Range</p>
              <div className="row mt-1">
                <div className="col-6">
                  <p style={{ color: "#8A8A8A", fontSize: "400" }}>From</p>
                  <div >

                    <span style={{
                      display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "0px",
                      borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
                    }}>
                      <BsFillCalendarFill className='ms-2' style={{ fontSize: '30px', color: "#3C3C3C", marginTop: "2px", }} />
                      <div>
                        <DatePicker className="border-0 p-0 ps-2 pt-1 datep"
                        
                        selected={startDate}
                      
                
                          style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                      </div>
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <p style={{ color: "#8A8A8A", fontSize: "400" }}>To</p>
                  <div >

                    <span style={{
                      display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "0px",
                      borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
                    }}>
                      <BsFillCalendarFill className='ms-2' style={{ fontSize: '30px', color: "#3C3C3C", marginTop: "2px", }} />
                      <div>
                        <DatePicker className="border-0 p-0 ps-2 pt-1 datep"
                          showIcon
                          selected={startDate1}
                         
                          style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                      </div>
                    </span>
                  </div>
                </div>

              </div>
            </div>


            <div className="col-3">
              <p style={{ fontSize: "18px", fontWeight: "400", color: "#8A8A8A" }}>On Leave</p>
              <p style={{ fontWeight: "500", fontSize: "20px", color: "#3C3C3C" }}> {Leaveslist.length}</p>
            </div>
            <div className="col-3">
              <div className="text-end mt-4">
                <button type="button" class="btn " onClick={()=>{handleShow()}} style={{ width: "50%", padding: "10px", background: "#8147E7", borderRadius: "5px", color: "#fff", }}>+ Grant Leave</button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div style={{ width: '100%', height: '86vh', }}>



<div className='d-flex ' style={{ justifyContent: "center", alignItems: "center" }}>
  <Lottie animationData={lotti} style={{ width: "31%", height: "25rem", marginLeft: "0rem", }} />
</div>

<div className=''>
  <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "40px", textAlign: "center", color: "#3C3C3C", }}>This feature will be live Soon !!</p>
</div>

<div className='text-center mt-5'>
  <NavLink to="/Dashboard"><button type="button" class="btn" style={{ background: "#F2EDFD", borderRadius: "5px", padding: "14px 55px", fontSize: "15px", fontWeight: "400", color: "#8147E7", border: "none", }}>Back To Dashboard</button></NavLink>
  {/* <NavLink to=""><button type="button" class="btn" style={{ background: "#8147E7", boxShadow: "0px 4px 12px rgba(126, 57, 247, 0.36)", borderRadius: "5px", color: "#FFFFFF", padding: "14px 55px", fontSize: "15px", fontWeight: "400", marginLeft: "20px", border: "none", }}>Join the Waitlist</button></NavLink> */}
</div>

</div>

       

       
      <Modal show={show} onHide={handleClose} size='lg' style={{width:"100%"}}>
     
      <Modal.Body style={{width:"100%"}}>
      

     
              <div className="mt-4">
                <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "28px", color: "#434343", }}>Grant New Leave</p>

                <select class="form-select mt-3" aria-label="Default select example" style={{ width: "100%", padding: "10px", }}
                  value={IdEmploy} onChange={(e) => { setIdEmploy(e.target.value) }}>
                  <option selected>Select Employee</option>
                  {
                    Employee?.map((res) => {
                      return (
                        <>
                          <option key={res.id} value={res.id}>{res.name}</option>
                        </>
                      )
                    })
                  }
                </select>
                <div className="mt-3">
                  <input type="text" placeholder="Reason for Leave" value={reason} style={{ background: "#FFFFFF", border: "1px solid #DEDEDE", borderRadius: "5px", width: "100%", padding: "10px", }} onChange={(e) => { setreason(e.target.value) }} />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className='mt-5' style={{ width: '90%', height: 'auto' }}>
                    <label className="filelabel1 w-100 ps-3 pt-2" style={{ background: "#FFFFFF", border: "1px solid #B0B0B0", borderRadius: "5px", color: "#000000", height: "3rem", }}>
                      <span className="title1" >
                        <h6 className='d-flex pt-1' src={Images} style={{ width: "100%", textAlign: 'center', color: "#000000", }}> {Banner.length == 0 ? "Upload Leave Application" : Banner.name}  <FiUpload className='mx-4' style={{ color: "#B0B0B0", fontSize: "20px", }} /></h6>
                      </span>
                      <input className="FileUpload1" id="FileInput1" name="booking_attachment1" type="file" accept='.png,.jpg' onChange={(e) => { setImages(URL.createObjectURL(e.target.files[0])); setBanner(e.target.files[0]) }} style={{ opacity: '0' }} />
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className='row mt-5 d-flex'>

                    <div className='col-3 d-flex' >
                      <div className='d-flex' style={{ alignItems: "center", gap: "10px" }}>
                        {/* <p  style={{fontStyle:"normal",fontWeight:"400",fontSize:"16px",color:"#474747",}}>From</p> */}


                        <span style={{ display: 'flex', borderRadius: "10px", padding: '7px 7px', color: '#b5b5b5', fontSize: '13px', width: "248px", border: " 1px solid rgba(176, 176, 176, 1)", width: "150px", overflow: "hidden", alignItems: "center" }}>

                          <div style={{ width: '10px', }}>
                            <span>
                              <AiOutlineCalendar className='me-2 ' style={{ fontSize: '20px', marginTop: "3px", zIndex: "9999", position: "absolute", }} />
                            </span>
                            <DatePicker
                              className='border-0'
                              showIcon
                              selected={startDate3}
                              onChange={(date) => {
                                setStartDate3(date);
                                setSedule(date.getFullYear() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getDate());
                              }}
                              style={{ padding: "15px 11px 15px 25pxpx", border: "5px solid #fff", borderRadius: "10px", }} />
                          </div>
                        </span>

                      </div>
                    </div>
                    <div className='col-2'>

                    </div>
                    <div className='col-4  d-flex'>
                      <div className='d-flex' style={{ alignItems: "center", gap: "10px" }}>
                        <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#474747", }}>TO</p>
                        <span style={{ display: 'flex', color: '#b5b5b5', fontSize: '13px', padding: '7px 7px', borderRadius: "10px", border: " 1px solid rgba(176, 176, 176, 1)", width: "150px", overflow: "hidden" }}>

                          <div style={{ width: '10px' }}><span><AiOutlineCalendar className='me-2 ' style={{ fontSize: '20px', marginTop: "3px", zIndex: "9999", position: "absolute", }} /> </span>
                            <DatePicker className='border-0' showIcon selected={startDate4} onChange={(date) => {
                              setStartDate4(date);
                              setSedule1(date.getFullYear() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getDate());
                            }} style={{ backgroundColor: '#111', }} />
                          </div>
                        </span>

                      </div>
                    </div>
                    <div className='col-2'>

                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex mt-3">
                <input type="checkbox" />
                <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "14px", color: "#989898", marginLeft: "10px", }}>Deduct Salary for the leave</p>
              </div>
           
            <div className="text-center mb-3 mt-5">
              <button type="button" class="btn "  style={{ width: "35%", background: "#F2EDFD", borderRadius: "5px", padding: "12px 0px", border: "none", color: "#8147E7", }}
              onClick={() =>{setBanner('');setreason(''); setSedule('');setSedule1(''); handleClose();  setIdEmploy("");}}
              >Cancel</button>
              <button type="button" class="btn " data-bs-toggle={ShowModel==true?"modal":""} data-bs-target="#exampleModal1" style={{ width: "35%", marginLeft: "20px", background: "#8147E7", boxShadow: "0px 4px 12px rgba(126, 57, 247, 0.36)", borderRadius: "5px", padding: "12px 0px", border: "none", color: "#FFFFFF", }} onClick={HandleProcess}>Grant Leave</button>
            </div>
      
      </Modal.Body>
      
    </Modal>








    <Modal show={show1} onHide={handleClose1}>
    
    <Modal.Body>


   
              <div className="text-center">
                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "28px", textAlign: "center", color: "#3C3C3C", }}>Leave Granted Succesfully</p>
              </div>

              <div className='d-flex ' style={{ justifyContent: "center", alignItems: "center" }}>
                <Lottie animationData={lotti} style={{ width: "50%", height: "15rem", marginLeft: "0rem", }} />
              </div>

              <div className="text-center mt-2 mb-3">
                <button type="button" onClick={()=>{handleClose1()}} class="btn"  style={{
                  background: "#8147E7", boxShadow: "0px 4px 12px rgba(126, 57, 247, 0.36)", borderRadius: "5px", fontStyle: "normal",
                  fontWeight: "500", fontSize: "14px", color: "#FFFFFF", border: "none", padding: "14px 53px",
                }}>Back to Leave Management</button>
              </div>
            
    
    </Modal.Body>
    
  </Modal>



      {/* modal grant leave */}
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backgroundColor: "rgb(233, 233, 233)", }}>
        <div class="modal-dialog modal-lg mt-5">
          <div class="modal-content" style={{ border: "none", }}>

            <div class="modal-body">
              <div className="mt-4">
                <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "28px", color: "#434343", }}>Grant New Leave</p>

                <select class="form-select mt-3" aria-label="Default select example" style={{ width: "100%", padding: "10px", }}
                  value={IdEmploy} onChange={(e) => { setIdEmploy(e.target.value) }}>
                  <option selected>Select Employee</option>
                  {
                    Employee?.map((res) => {
                      return (
                        <>
                          <option key={res.id} value={res.id}>{res.name}</option>
                        </>
                      )
                    })
                  }
                </select>
                <div className="mt-3">
                  <input type="text" placeholder="Reason for Leave" value={reason} style={{ background: "#FFFFFF", border: "1px solid #DEDEDE", borderRadius: "5px", width: "100%", padding: "10px", }} onChange={(e) => { setreason(e.target.value) }} />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className='mt-5' style={{ width: '90%', height: 'auto' }}>
                    <label className="filelabel1 w-100 ps-3 pt-2" style={{ background: "#FFFFFF", border: "1px solid #B0B0B0", borderRadius: "5px", color: "#000000", height: "3rem", }}>
                      <span className="title1" >
                        <h6 className='d-flex pt-1' src={Images} style={{ width: "100%", textAlign: 'center', color: "#000000", }}> {Banner.length == 0 ? "Upload Leave Application" : Banner.name}  <FiUpload className='mx-4' style={{ color: "#B0B0B0", fontSize: "20px", }} /></h6>
                      </span>
                      <input className="FileUpload1" id="FileInput1" name="booking_attachment1" type="file" accept='.png,.jpg' onChange={(e) => { setImages(URL.createObjectURL(e.target.files[0])); setBanner(e.target.files[0]) }} style={{ opacity: '0' }} />
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className='row mt-5 d-flex'>

                    <div className='col-3 d-flex' >
                      <div className='d-flex' style={{ alignItems: "center", gap: "10px" }}>
                        {/* <p  style={{fontStyle:"normal",fontWeight:"400",fontSize:"16px",color:"#474747",}}>From</p> */}


                        <span style={{ display: 'flex', borderRadius: "10px", padding: '7px 7px', color: '#b5b5b5', fontSize: '13px', width: "248px", border: " 1px solid rgba(176, 176, 176, 1)", width: "150px", overflow: "hidden", alignItems: "center" }}>

                          <div style={{ width: '10px', }}>
                            <span>
                              <AiOutlineCalendar className='me-2 ' style={{ fontSize: '20px', marginTop: "3px", zIndex: "9999", position: "absolute", }} />
                            </span>
                            <DatePicker
                              className='border-0'
                              showIcon
                              selected={startDate3}
                              onChange={(date) => {
                                setStartDate3(date);
                                setSedule(date.getFullYear() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getDate());
                              }}
                              style={{ padding: "15px 11px 15px 25pxpx", border: "5px solid #fff", borderRadius: "10px", }} />
                          </div>
                        </span>

                      </div>
                    </div>
                    <div className='col-2'>

                    </div>
                    <div className='col-4  d-flex'>
                      <div className='d-flex' style={{  alignItems: "center", gap: "10px" }}>
                        <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#474747", }}>TO</p>
                        <span style={{ display: 'flex', color: '#b5b5b5', fontSize: '13px', padding: '7px 7px', borderRadius: "10px", border: " 1px solid rgba(176, 176, 176, 1)", width: "150px", overflow: "hidden" }}>

                          <div style={{ width: '10px' }}><span><AiOutlineCalendar className='me-2 ' style={{ fontSize: '20px', marginTop: "3px", zIndex: "9999", position: "absolute", }} /> </span>
                            <DatePicker className='border-0' showIcon selected={startDate4} onChange={(date) => {
                              setStartDate4(date);
                              setSedule1(date.getFullYear() + "/" + (parseInt(date.getMonth()) + 1) + "/" + date.getDate());
                            }} style={{ backgroundColor: '#111', }} />
                          </div>
                        </span>

                      </div>
                    </div>
                    <div className='col-2'>

                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex mt-3">
                <input type="checkbox" />
                <p style={{ fontStyle: "normal", fontWeight: "500", fontSize: "14px", color: "#989898", marginLeft: "10px", }}>Deduct Salary for the leave</p>
              </div>
            </div>
            <div className="text-center mb-3 mt-5">
              <button type="button" class="btn " data-bs-dismiss="modal" style={{ width: "35%", background: "#F2EDFD", borderRadius: "5px", padding: "12px 0px", border: "none", color: "#8147E7", }}>Cancel</button>
              <button type="button" class="btn " data-bs-toggle={ShowModel==true?"modal":""} data-bs-target="#exampleModal1" style={{ width: "35%", marginLeft: "20px", background: "#8147E7", boxShadow: "0px 4px 12px rgba(126, 57, 247, 0.36)", borderRadius: "5px", padding: "12px 0px", border: "none", color: "#FFFFFF", }} onClick={HandleProcess}>Grant Leave</button>
            </div>
          </div>
        </div>
      </div>


      {/* <!-- Modal lotty--> */}


      <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backgroundColor: "rgb(233, 233, 233)", }}>
        <div class="modal-dialog " style={{ marginTop: "10rem", }}>
          <div class="modal-content" style={{ border: "none", }}>

            <div class="modal-body">
              <div className="text-center">
                <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "28px", textAlign: "center", color: "#3C3C3C", }}>Leave Granted Succesfully</p>
              </div>

              <div className='d-flex ' style={{ justifyContent: "center", alignItems: "center" }}>
                <Lottie animationData={lotti} style={{ width: "50%", height: "15rem", marginLeft: "0rem", }} />
              </div>

              <div className="text-center mt-2 mb-3">
                <NavLink to="/Grantleave"><button type="button" class="btn " data-bs-dismiss="modal" style={{
                  background: "#8147E7", boxShadow: "0px 4px 12px rgba(126, 57, 247, 0.36)", borderRadius: "5px", fontStyle: "normal",
                  fontWeight: "500", fontSize: "14px", color: "#FFFFFF", border: "none", padding: "14px 53px",
                }}>Back to Leave Management</button></NavLink>
              </div>
            </div>

          </div>
        </div>
      </div>


      {/* <!--Filter modal--> */}
      <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content" style={{ marginLeft: "31rem", marginTop: "16rem", }}>

            <div class="modal-body">
              <div className="row">
                <div className="col-4" >
                  <div className="d-flex" onClick={() => { setvalue("Accounts") }} style={{ background: value == "Accounts" ? "#F4F2F9" : "", borderRadius: "5px", padding: "8px 17px", }}>
                    <input type="checkbox" />
                    <p style={{ marginLeft: "10px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>Accounts</p>
                  </div>
                  <div className="d-flex" onClick={() => { setvalue("Academics") }} style={{ backgroundColor: value == "Academics" ? "#F4F2F9" : " ", borderRadius: "5px", padding: "8px 17px", }}>
                    <input type="checkbox" />
                    <p style={{ marginLeft: "10px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>Academics</p>
                  </div>
                  <div className="d-flex" onClick={() => { setvalue("Teacher") }} style={{ backgroundColor: value == "Teacher" ? "#F4F2F9" : " ", borderRadius: "5px", padding: "8px 17px", }}>
                    <input type="checkbox" />
                    <p style={{ marginLeft: "10px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>Teacher</p>
                  </div>
                  <div className="d-flex" onClick={() => { setvalue("Sports") }} style={{ backgroundColor: value == "Sports" ? "#F4F2F9" : " ", borderRadius: "5px", padding: "8px 17px", }}>
                    <input type="checkbox" />
                    <p style={{ marginLeft: "10px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>Sports</p>
                  </div>
                  <div className="d-flex" onClick={() => { setvalue("Music") }} style={{ backgroundColor: value == "Music" ? "#F4F2F9" : " ", borderRadius: "5px", padding: "8px 17px", }}>
                    <input type="checkbox" />
                    <p style={{ marginLeft: "10px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>Music</p>
                  </div>
                  <div className="d-flex" onClick={() => { setvalue("IT") }} style={{ backgroundColor: value == "IT" ? "#F4F2F9" : " ", borderRadius: "5px", padding: "8px 17px", }}>
                    <input type="checkbox" />
                    <p style={{ marginLeft: "10px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>IT</p>
                  </div>
                </div>

                {
                  value == "Accounts" ? <>
                    <div className="col-8" style={{ background: "#F4F2F9", borderRadius: "5px", padding: "8px 17px", }}>
                      <div className="d-flex" style={{ padding: "8px 17px", }}>
                        <input type="checkbox" />
                        <p style={{ marginLeft: "10px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>Accounts</p>
                      </div>
                      <div className="d-flex" style={{ padding: "8px 17px", }}>
                        <input type="checkbox" />
                        <p style={{ marginLeft: "10px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>Jr. Accountant</p>
                      </div>
                      <div className="d-flex" style={{ padding: "8px 17px", }}>
                        <input type="checkbox" />
                        <p style={{ marginLeft: "10px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>Sr. Accountant</p>
                      </div>
                      <div className="d-flex" style={{ padding: "8px 17px", }}>
                        <input type="checkbox" />
                        <p style={{ marginLeft: "10px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>Accounts Manager</p>
                      </div>
                      <div className="d-flex" style={{ padding: "8px 17px", }}>
                        <input type="checkbox" />
                        <p style={{ marginLeft: "10px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>Substitute Accountant</p>
                      </div>
                      <div className="d-flex" style={{ padding: "8px 17px", }}>
                        <input type="checkbox" />
                        <p style={{ marginLeft: "10px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>Head Accountant</p>
                      </div>


                    </div>
                  </> : <></>
                }


                {
                  value == "Academics" ? <>
                    <div className="col-8" style={{ background: "#F4F2F9", borderRadius: "5px", padding: "8px 17px", }}>
                      <div className="d-flex" style={{ padding: "8px 17px", }}>
                        <input type="checkbox" />
                        <p style={{ marginLeft: "10px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>History</p>
                      </div>
                      <div className="d-flex" style={{ padding: "8px 17px", }}>
                        <input type="checkbox" />
                        <p style={{ marginLeft: "10px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>Geography</p>
                      </div>
                      <div className="d-flex" style={{ padding: "8px 17px", }}>
                        <input type="checkbox" />
                        <p style={{ marginLeft: "10px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>Maths</p>
                      </div>
                      <div className="d-flex" style={{ padding: "8px 17px", }}>
                        <input type="checkbox" />
                        <p style={{ marginLeft: "10px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>Accountancy</p>
                      </div>
                      <div className="d-flex" style={{ padding: "8px 17px", }}>
                        <input type="checkbox" />
                        <p style={{ marginLeft: "10px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>Sanskrit</p>
                      </div>
                      <div className="d-flex" style={{ padding: "8px 17px", }}>
                        <input type="checkbox" />
                        <p style={{ marginLeft: "10px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#3C3C3C", }}>Sociology</p>
                      </div>


                    </div>
                  </> : <></>
                }


              </div>
            </div>

          </div>
        </div>
      </div>
      {/* <!--View Details--> */}

      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" style={{ width: "50%", }}>
        <div class="offcanvas-header">

          <button type="button" class="btn-close text-reset px-4" data-bs-dismiss="offcanvas" aria-label="Close" style={{ backgroundColor: "#ffffff !important", }}></button>
          <NavLink to={`/Grantleaveviewdetail/${Id}`}><button class="offcanvas-title" id="offcanvasExampleLabel" style={{
            background: "#F2EDFD", borderRadius: "5px", padding: "10px 38px", fontStyle: "normal",
            fontWeight: "400", fontSize: "16px", color: "#8147E7", border: "none",
          }}><IoEyeOutline />View Profile</button></NavLink>
        </div>
        <div class="offcanvas-body px-5">
          {
            ViewData?.map((res, key) => {
              return (
                <>
                  <div>
                    <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "30px", color: "#3C3C3C", }}>{res.employee_name}</p>
                  </div>
                  <div className="row mt-2">
                    <div className="col-3">
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#3C3C3C", }}>Department</p>
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#8A8A8A", }}>{res.department_name
                      }</p>
                    </div>
                    <div className="col-2">
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#3C3C3C", }}>Role</p>
                      <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#8A8A8A", }}>{res.role_name
                      }</p>
                    </div>
                  </div>
                  <div className="mt-4">
                    <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#8A8A8A", }}>Leave Duration</p>
                    <div className='row mt-4 ps-2 d-flex'>

                      <div className='col-3 d-flex'>
                        <div className='mb-3' style={{ lineHeight: '1px', alignItems: "center", gap: "10px" }}>
                          <p className="" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#8A8A8A", }}>From</p>


                          <span style={{ display: 'flex', borderRadius: "10px", height: 40, padding: '4px 4px', color: '#b5b5b5', fontSize: '13px', width: "248px", border: " 1px solid rgba(176, 176, 176, 1)", width: "150px", overflow: "hidden", alignItems: "center", marginTop: "10px", }}>

                            <AiOutlineCalendar className='me-2 ' style={{ fontSize: '20px', marginTop: "3px", zIndex: "1", position: "absolute", }} />
                            <span className="ms-4" >{res.from}</span>
                          </span>

                        </div>
                      </div>

                      <div className='col-3  d-flex' style={{}}>
                        <div className='mb-3' style={{ lineHeight: '1px', alignItems: "center", gap: "10px" }}>
                          <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#8A8A8A", }}>TO</p>
                          <span style={{ display: 'flex', color: '#b5b5b5', fontSize: '13px', padding: '4px 4px', borderRadius: "10px", border: " 1px solid rgba(176, 176, 176, 1)", width: "150px", overflow: "hidden", marginTop: "10px", height: 40 }}>

                            <AiOutlineCalendar className='me-2 ' style={{ fontSize: '20px', marginTop: "3px", zIndex: "1", position: "absolute", }} />
                            <span className="ms-4" style={{ marginTop: "13px" }}>{res.to}</span>

                          </span>

                        </div>
                      </div>
                      <div className='col-2'>

                      </div>
                    </div>
                  </div>
                  <div className=" mt-4" style={{ border: "1px solid #DEDEDE", padding: "10px 16px", borderRadius: "5px", }}>
                    <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#8A8A8A", }}>Reason for Leave</p>
                    <p className="mt-2" style={{ fontStyle: "normal", fontWeight: "400", fontSize: "20px", color: "#434343", }}>{res.reason}</p>
                  </div>
                  <div className="mt-3">
                    <p style={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#8A8A8A", }}>Leave Application</p>
                    <div className='mt-5' style={{ width: '60%', height: 'auto' }}>
                      <label className="w-100 ps-3 pt-2" style={{ background: "#FFFFFF", border: "1px solid #DEDEDE", borderRadius: "5px", color: "#000000", height: "3rem", }}>
                        {
                          res.file
                        }
                      </label>
                    </div>
                  </div>
                </>
              )
            })
          }



        </div>
      </div>
      {/* Invoice Details */}

      {/* <div className="mt-5">
        <div className="row mt-5" style={{ width: "100%", }}>
          <div className="col-8">
            <p style={{
              fontStyle: "normal", fontWeight: "600", fontSize: "18px", color: "#3C3C3C", marginLeft: "28px",
            }}>Staff Details</p>
          </div>
          <div className="col-2 text-end">
            <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#exampleModal2" style={{ width: "70%", background: "#EADDFF", borderRadius: "5px", color: "#8147E7", fontStyle: "normal", fontWeight: "400", fontSize: "14px", border: "none", padding: "8px", }}><IoFunnelOutline /> Filter</button>
          </div>
          <div className="col-2">
            <button type="button" class="btn" style={{ width: "70%", background: "#8147E7", borderRadius: "5px", fontStyle: "normal", fontWeight: "400", fontSize: "14px", color: "#FFFFFF", border: "none", padding: "8px", }} onClick={() =>{convertToExcel()}}><IoNewspaperOutline /> Export Data</button>
          </div>
        </div>
        <div className='mt-3 '>
          <div className='row mx-4 mt-3'  >
            <div className='row' style={{ color: "#434343", fontWeight: "600", fontSize: "15px" }}>
              <div className='col-1'>
                <p>S.No</p>
              </div>
              <div className='col-2'>
                <p>Name</p>
              </div>
              <div className='col-2'>
                <p>Department</p>
              </div>
              <div className='col-1'>
                <p>Role</p>
              </div>
              <div className='col-2'>
                <p>From</p>
              </div>
              <div className='col-2'>
                <p>To</p>
              </div>
            </div>
            <div style={{ overflowY: "scroll", height: "60vh" }}>
              {Leaveslist.map((res, key) => {
                return (
                  <>
                    <div className='row align-items-center  mt-3' style={{ width: "100%", borderRadius: "5px", height: "55px", backgroundColor: " #FFFFFF" }} >
                      <div className='col-1' key={key} >
                        <p style={{ color: "#434343", fontWeight: "400", fontSize: "15px" }}>{key+1}</p>
                      </div>
                      <div className='col-2'>
                        <p style={{ fontWeight: "400", fontSize: "15px",textTransform: "capitalize" }}>{res.employee_name}</p>
                      </div>
                      <div className='col-2'>
                        <p style={{ fontSize: "15px", color: "#3C3C3C", fontWeight: "500" }} >{res.department_name}</p>
                      </div>
                      <div className='col-1'>
                        <p style={{ fontSize: "15px", color: "#434343", fontWeight: "400" }} >{res.role_name}</p>
                      </div>
                      <div className='col-2'>
                        <p style={{ fontSize: "15px", color: "#434343", fontWeight: "400" }} >{res.from}</p>
                      </div>
                      <div className='col-2'>
                        <p style={{ fontSize: "15px", color: "#434343", fontWeight: "400" }} >{res.to}</p>
                      </div>


                      <div className='col-2'>
                        <button type="button" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample" style={{
                          height: "30px", fontSize: "15px", color: "#8147E7", fontWeight: "400", backgroundColor: "#F2EAFF",
                          width: "130px", borderRadius: "5px", border: "none"
                        }} onClick={() => { ViewProfileList(res.id); setId(res.emp_id) }}><AiOutlineEye style={{ fontSize: "20px" }} />&nbsp;&nbsp;View Profile</button>
                      </div>

                    </div>


                  </>
                )
              })}
            </div>

          </div>
        </div>
      </div> */}








    </>
  )
}