import React, { useState, useEffect, useContext } from "react"; //api done
import { IoArrowBackOutline } from "react-icons/io5";
import Back from "../images/wave.png";
import { FiUpload } from "react-icons/fi";
import { IoCalendarOutline } from "react-icons/io5";
import { IoInfiniteOutline } from "react-icons/io5";
import { NavLink, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
// import Toast from "react-bootstrap/Toast";
import { AuthContext } from "./AuthContext";
import { ToastContainer, toast } from "react-toastify";
import lotti6 from '../images/142632-emoji.json';
import Lottie from "lottie-react";
import { API_URL, img_url } from "../env";

export default function Academics() {

  const { profile, profileGet } = useContext(AuthContext);
  const [count, setcount] = useState(1);
  const [Images, setImages] = useState();
  const [Banner, setBanner] = useState("");
  const [Banner1, setBanner1] = useState("");
  const [Banner2, setBanner2] = useState("");
  const [Banner3, setBanner3] = useState("");
  const [Banner4, setBanner4] = useState("");
  const [Banner5, setBanner5] = useState("");
  const [duedate, setDuedate] = useState(0);
  const [tabled, setTabled] = useState(0);
  const [Course, setCourse] = useState([]);
  const [Batch, setBatch] = useState([]);
  const [BatchId, setBatchId] = useState("");
  const [Emis, setEmis] = useState([]);
  const [TotalAmount, setTotalAmount] = useState("");
  const [Sedule, setSedule] = useState();
  const [Sedule1, setSedule1] = useState();
  const [Sedule2, setSedule2] = useState();
  const [CourseId, setCourseId] = useState();
  const { apiToken } = useContext(AuthContext);
  const [message, setmessage] = useState();
  const [final, setfinal] = useState();
  const [onefinal, setonefinal] = useState();
  const [panNumber, setPanNumber] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [remark, setRemark] = useState("");
  const [ID, setID] = useState();
  const [dummy_amount, setDummyAmount] = useState("");

  const Navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    address: "",
    AdhaarNo: "",
    PanNo: "",
    Enrollment: "",
    EMI: "",
    Discount: "",
    Father: "",
    Mother: "",
    Guardian: "",
    Bank: "",
    IFSC: "",
  });

  //require destructiong above state objects
  const { banner } = profile;
  const handlePanNumberChange = (event) => {
    const inputValue = event.target.value.toUpperCase();
    setPanNumber(inputValue);
    validatePanNumber(inputValue);
  };

  const validatePanNumber = (value) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    setIsValid(panRegex.test(value));
  };

  const AddStudent = async () => {
    var myHeaders = new Headers();
    myHeaders.append("authorization", `Bearer ${apiToken}`);

    var formdata = new FormData();
    formdata.append("course_id", CourseId);
    formdata.append("batch_id", BatchId);
    formdata.append("name", formData.name);
    formdata.append("address", formData.address);
    formdata.append("adhar_no", formData.AdhaarNo);
    formdata.append("pancard_no", panNumber);
    formdata.append("email", formData.email);
    formdata.append("contact_no", formData.contact);
    formdata.append("father_name", formData.Father);
    formdata.append("mother_name", formData.Mother);
    formdata.append("parent_adhar_no", formData.Guardian);
    formdata.append("parent_account_no", formData.Bank);
    formdata.append("ifsc_no", formData.IFSC);
    formdata.append("adhar_front_back", Banner1);
    formdata.append("pancard_front_back", Banner2);
    formdata.append("parent_adhar_front_back", Banner4);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${API_URL}/admin/student/add/student`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          toast(result.msg);
        }
         else {
          // setmessage(result.Backend_Error);
          toast(result.Backend_Error);

        }
      })
      .catch((error) => console.log("error", error));
  };

  function handleSelectChange1(event) {
    const { id, value } = event.target.options[event.target.selectedIndex];
    setTotalAmount(id);
    ApiForBatchDetail(value);
    setCourseId(value);
  }

  const HandleProcess1 = () => {
    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    if(formData.name.length<=1){
      toast(formData.name.length<=1 ? "Pls enter a name":"")
    }else{
      setcount(count+1);
    }
    // if (formData.name.length <= 1 || formData.contact.length <= 9) {
    //   toast(
    //     formData.name.length <= 1
    //       ? "Please enter a name"
    //       : formData.contact.length <= 9
    //         ? "Please enter a 10-digit number"
    //         : "Please fill in the input fields"
    //   );
    // } else {
    //   setcount(count + 1);
    // }
    // setcount(count+1);
  };

  const HandleProcess2 = () => {
    // if (formData.address.length <= 4) {
    //   toast("Please enter your address");
    // } else if (
    //   (formData.AdhaarNo.length > 0 && formData.AdhaarNo.length <= 11) ||
    //   isValid == true
    // ) {
    //   toast(
    //     formData.AdhaarNo.length > 0 && formData.AdhaarNo.length <= 11
    //       ? "Please enter a 12-digit Aadhaar number"
    //       : isValid == true
    //         ? "Please enter Valid PAN number"
    //         : ""
    //   );
    // }
    // else {
    // }
    setcount(count + 1);
  };

  const HandleProcess3 = () => {
    if (String(BatchId).length === 0) {
      toast("Please select Course and Batch");
    } else {
      setcount(count + 1);
    }
  };

  const HandleProcess4 = () => {

    setcount(count + 1);

    AddStudent();
  };

  const HandleProcess5 = () => {
    // if (
    //   formData.EMI <= 1 ||
    //   !Sedule ||
    //   !Sedule1
    // ) {
    //   toast(
    //     formData.EMI <= 1
    //       ? "The count EMI field is required"
    //       : !Sedule
    //         ? "Please set the start date"
    //         : !Sedule1
    //           ? "Please set the end date"
    //           : "Please fill the required field"
    //   );
 
    // } else {
    // }

    if (duedate == 0) {
      setTabled(0);
    } else {
      setTabled(1);
    }
    setcount(count + 1);
  };


  const HandleProcess6 = () => {
    // if (!Sedule2) {
    //   toast("Please set the due date");
    // } else {
    // }
    setcount(count + 1);
  };

  const HandleProcess7 = () => {
    // if (formData.EMI <= 1 || !Sedule || !Sedule1) {
    //   toast(
    //     formData.EMI <= 2
    //       ? "The count EMI field is required"
    //       : !Sedule
    //         ? "Please set the start date"
    //         : !Sedule1
    //           ? "Please set the end date"
    //           : "Please fill the required field"
    //   );
    // } else {
    // }
    setTabled(1);
  };

  const handleInputChange = (fieldName, event) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: event.target.value,
    }));
  };

  const handleInputChange1 = (fieldName, value) => {
    const truncatedValue = value.slice(0, 10);
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: truncatedValue,
    }));
  };

  const handleInputChange2 = (fieldName, value) => {
    const truncatedValue = value.slice(0, 12);
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: truncatedValue,
    }));
  };

  const handleInputChange4 = (fieldName, value) => {
    const truncatedValue = value.slice(0, 19);
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: truncatedValue,
    }));
  };

  const ApiForCourse = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/admin/course/read/list/of/courses`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "001") {
          setCourse(result.courses);
        } else if (result.status == "009") {
          toast(result.message);
        } else {
          toast(result.message)
        }
      })
      .catch((error) => console.log("error", error));
  };

  const ApiForBatchDetail = async (ID) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    // var formdata = new FormData();
    // formdata.append("course_id", ID);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      // body: formdata,
      redirect: "follow",
    };

    fetch(`${API_URL}/admin/formfill/batch/by/courseid/${ID}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setBatch(result.batches);
        if (result.status == "CUSTOM_ERR") {
          toast(result.Backend_Error);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem('api_url');
          Navigate('/code')
          toast("Logged in other device! Please login again")
        }
        else {
          console.log(result)
        }
        //setTotalAmount(result.Amount);
      })
      .catch((error) => console.log("error", error));
  };

  const Remarks = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiToken}`);

    var formdata = new FormData();
    formdata.append("invoice_id", ID);
    formdata.append("remarks", remark);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${API_URL}/student/updateRemarks_studentsInvoice`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "001") {
          toast(result.update_remarks);
          Navigate("/Dashboard");
        }
        else if (result.status === 'VAL_ERR') { 
          toast(result.Backend_Error) 
        } 
      else if (result.status == 'FILE_ERR') { 
          toast(result.Backend_Error) 
        } 
       else if (result.status == "CUSTOM_ERR") { 
          toast(result.Backend_Error); 
        } else if (result.status == "TOKEN_ERR") { 
          localStorage.removeItem('api_url'); 
          Navigate('/code') 
          toast("Logged in other device! Please login again") 
        } 
         else {
          toast(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    ApiForCourse();
    ApiForBatchDetail();
    // profileGet();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        background: "#FBFDFF",
        backgroundImage: count > 4 ? "" : `url(${Back})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
      }}
    >
      <ToastContainer />
      <div className="d-flex mt-1 px-3">
        <img
          src={`${banner}`}
          className="header"
          style={{
            color: "black",
            width: "auto",
            height: "80px",
            objectFit: "cover",
          }}
        />
      </div>

      {/* <!-- Form 1 --> */}
      <div className="" style={{ display: count === 1 ? "block" : "none" }}>
        <NavLink to="/Students">
          <IoArrowBackOutline
            style={{
              marginLeft: "17px",
              fontSize: "1.5rem",
              marginTop: "1rem",
              color: "#000",
              cursor: "pointer",
            }}
          />
        </NavLink>
        <div className="row">
          <div className="col-4"></div>
          <div className="col-4">
            <div
              className="card mt-5 ps-3"
              style={{
                border: "none",
                width: "100%",
                background: "#FFFFFF",
                boxShadow: "0px 4px 12px rgba(158, 158, 158, 0.25)",
                borderRadius: "10px",
              }}
            >
              <form className="mt-4">
                <div>
                  <label
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "20px",
                      color: "#414141",
                    }}
                  >
                    Enter Name
                  </label>
                  <span style={{ color: "red" }}>*</span>
                  <br></br>
                  <input
                    className="mt-2"
                    type="text"
                    placeholder="Your name"
                    value={formData.name}
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #EDEDED",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "95%",
                    }}
                    onChange={(e) => handleInputChange("name", e)}
                  />
                </div>
                <div className="mt-4">
                  <label
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "20px",
                      color: "#414141",
                    }}
                  >
                    Enter Email
                  </label>
                  {/* <span style={{ color: "red" }}>*</span> */}
                  <br></br>
                              <input
                    className="mt-2"
                    type="email"
                    placeholder="example@gmail.com"
                    value={formData.email}
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #EDEDED",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "95%",
                    }}
                    onChange={(e) => handleInputChange("email", e)}
                    d="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <label
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "20px",
                      color: "#414141",
                    }}
                  >
                    Enter Contact No.
                  </label>
                  {/* <span style={{ color: "red" }}>*</span> */}
                  <br></br>
                  <input
                    className="mt-2"
                    type="number"
                    placeholder="Phone number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={formData.contact}
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #EDEDED",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "95%",
                    }}
                    onChange={(e) =>
                      handleInputChange1("contact", e.target.value)
                    }
                  />
                </div>

                <div className="mt-5 mb-4 text-center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => HandleProcess1()}
                    style={{
                      width: "90%",
                      background: "#3572FA",
                      boxShadow: "0px 2px 8px #B0BEEF",
                      borderRadius: "10px",
                      padding: "15px",
                    }}
                  >
                    Proceed
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-4"></div>
        </div>
      </div>

      {/* <!-- Form 2 --> */}
      <div className="" style={{ display: count === 2 ? "block" : "none" }}>
        <div
          className=""
          onClick={() => {
            setcount(count - 1);
          }}
        >
          <IoArrowBackOutline
            style={{
              marginLeft: "17px",
              fontSize: "1.5rem",
              marginTop: "1rem",
              cursor: "pointer",
            }}
          />
        </div>
        <div className="row">
          <div className="col-3"></div>
          <div className="col-6">
            <div
              className="card mt-5 ps-3"
              style={{
                border: "none",
                width: "100%",
                background: "#FFFFFF",
                boxShadow: "0px 4px 12px rgba(158, 158, 158, 0.25)",
                borderRadius: "10px",
              }}
            >
              <form className="mt-4">
                <div>
                  <label
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "#414141",
                    }}
                  >
                    Enter Address{" "}
                  </label>
                  {/* <span style={{ color: "red" }}>*</span> */}
                  <br></br>

                  <textarea
                    className="mt-2"
                    name="postContent"
                    placeholder="Enter your address"
                    value={formData.address}
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #EDEDED",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "95%",
                    }}
                    onChange={(e) => handleInputChange("address", e)}
                  />
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <label
                      style={{
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#414141",
                      }}
                    >
                      Enter Adhaar Number{" "}
                      {/* <span style={{ color: "red" }}>*</span> */}
                                   </label>
                    <br></br>
                    <input
                      className="mt-2"
                      type="number"
                      placeholder="Adhaar number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      value={formData.AdhaarNo}
                      maxLength={12}
                      style={{
                        background: "#FFFFFF",
                        border: "1px solid #EDEDED",
                        borderRadius: "10px",
                        padding: "10px",
                        width: "95%",
                      }}
                      onChange={(e) =>
                        handleInputChange2("AdhaarNo", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-6">
                    <label
                      style={{
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#414141",
                      }}
                    >
                      Enter Pan No.{" "}
                      {/* <span style={{ color: "red" }}>*</span> */}
                                      </label>
                    <br></br>

                    <input
                      className="mt-2"
                      type="text"
                      id="panNumber"
                      maxLength={10}
                      placeholder="Pan number"
                      value={panNumber}
                      style={{
                        background: "#FFFFFF",
                        border: "1px solid #EDEDED",
                        borderRadius: "10px",
                        padding: "10px",
                        width: "95%",
                      }}
                      onChange={handlePanNumberChange}
                    />

                    {isValid ? (
                      <p style={{ color: "green" }}>
                        PAN card number is valid.
                      </p>
                    ) : (
                      <p style={{ color: "red" }}>
                        PAN card number is invalid.
                      </p>
                    )}
                                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p
                      style={{
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#414141",
                      }}
                    >
                      Upload Aadhar photo{" "}
                      {/* <span style={{ color: "red" }}>*</span> */}
                      <div style={{ color: "#A9A9A9", fontSize: "16px" }}>
                        {/* Optional */}
                        (upload front and back in pdf)
                      </div>
                    </p>
                    <div className="row">
                      {/* <div className="col-6">
                        <div
                          className="mt-3 px-3"
                          style={{ width: "100%", height: "auto" }}
                        >
                          <label
                            className="filelabel1 w-100 "
                            style={{
                              background: "#FFFFFF",
                              border: "1px solid #B0B0B0",
                              borderRadius: "5px",
                              color: "#000000",
                              height: "30px",
                            }}
                          >
                            {Banner <= 1 ? (
                              <span className="title1">
                                <h6
                                  className="d-flex mt-1"
                                  src={Images}
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                    color: "#C5C5C5",
                                    fontSize: "13px",
                                  }}
                                >
                                  {" "}
                                  <FiUpload
                                    className="mx-4"
                                    style={{
                                      color: "#B0B0B0",
                                      fontSize: "20px",
                                    }}
                                  />{" "}
                                  Front{" "}
                                </h6>
                              </span>
                            ) : (
                              <span style={{ marginLeft: 20, color: "blue" }}>
                                {Banner.name.split("", 10)}...
                              </span>
                            )}
                            <input
                              className="FileUpload1"
                              id="FileInput1"
                              name="booking_attachment1"
                              type="file"
                              accept=".png,.jpg"
                              onChange={(e) => {
                                setImages(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                setBanner(e.target.files[0]);
                              }}
                              style={{ opacity: "0" }}
                            />
                          </label>
                        </div>
                      </div> */}
                      <div className="col-8">
                        <div
                          className="mt-3 px-3"
                          style={{ width: "100%", height: "auto" }}
                        >
                          <label
                            className="filelabel1 w-100 "
                            style={{
                              background: "#FFFFFF",
                              border: "1px solid #B0B0B0",
                              borderRadius: "5px",
                              color: "#000000",
                              height: "30px",
                            }}
                          >
                            {Banner1 <= 1 ? (
                              <span className="title1">
                                <h6
                                  className="d-flex mt-1"
                                  src={Images}
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                    color: "#C5C5C5",
                                    fontSize: "13px",
                                  }}
                                >
                                  {" "}
                                  <FiUpload
                                    className="mx-4"
                                    style={{
                                      color: "#B0B0B0",
                                      fontSize: "20px",
                                    }}
                                  />{" "}
                                  Upload pdf{" "}
                                </h6>
                              </span>
                            ) : (
                              <span style={{ marginLeft: 20, color: "blue" }}>
                                {Banner1.name.split("", 10)}...
                              </span>
                            )}
                            <input
                              className="FileUpload1"
                              id="FileInput1"
                              name="booking_attachment1"
                              type="file"
                              accept=".pdf"
                              onChange={(e) => {
                                setImages(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                setBanner1(e.target.files[0]);
                              }}
                              style={{ opacity: "0" }}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <p
                      style={{
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#414141",
                      }}
                    >
                      Upload Pan photo{" "}
                      {/* <span style={{ color: "red" }}>*</span> */}
                      <div style={{ color: "#A9A9A9", fontSize: "16px" }}>
                        {/* Optional */}
                        (upload front and back in pdf)
                      </div>
                    </p>
                    <div className="row">
                      <div className="col-8">
                        <div
                          className="mt-3 px-3"
                          style={{ width: "100%", height: "auto" }}
                        >
                          <label
                            className="filelabel1 w-100 "
                            style={{
                              background: "#FFFFFF",
                              border: "1px solid #B0B0B0",
                              borderRadius: "5px",
                              color: "#000000",
                              height: "30px",
                            }}
                          >
                            {Banner2 <= 1 ? (
                              <span className="title1">
                                <h6
                                  className="d-flex mt-1"
                                  src={Images}
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                    color: "#C5C5C5",
                                    fontSize: "13px",
                                  }}
                                >
                                  {" "}
                                  <FiUpload
                                    className="mx-4"
                                    style={{
                                      color: "#B0B0B0",
                                      fontSize: "20px",
                                    }}
                                  />{" "}
                                  Upload pdf{" "}
                                </h6>
                              </span>
                            ) : (
                              <span style={{ marginLeft: 20, color: "blue" }}>
                                {Banner2.name.split("", 10)}...
                              </span>
                            )}
                            <input
                              className="FileUpload1"
                              id="FileInput1"
                              name="booking_attachment1"
                              type="file"
                              accept=".pdf"
                              onChange={(e) => {
                                setImages(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                setBanner2(e.target.files[0]);
                              }}
                              style={{ opacity: "0" }}
                            />
                          </label>
                        </div>
                      </div>
                      {/* <div className="col-6">
                        <div
                          className="mt-3 px-3"
                          style={{ width: "100%", height: "auto" }}
                        >
                          <label
                            className="filelabel1 w-100 "
                            style={{
                              background: "#FFFFFF",
                              border: "1px solid #B0B0B0",
                              borderRadius: "5px",
                              color: "#000000",
                              height: "30px",
                            }}
                          >
                            {Banner3 <= 1 ? (
                              <span className="title1">
                                <h6
                                  className="d-flex mt-1"
                                  src={Images}
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                    color: "#C5C5C5",
                                    fontSize: "13px",
                                  }}
                                >
                                  {" "}
                                  <FiUpload
                                    className="mx-4"
                                    style={{
                                      color: "#B0B0B0",
                                      fontSize: "20px",
                                    }}
                                  />{" "}
                                  Back{" "}
                                </h6>
                              </span>
                            ) : (
                              <span style={{ marginLeft: 20, color: "blue" }}>
                                {Banner3.name.split("", 10)}...
                              </span>
                            )}
                            <input
                              className="FileUpload1"
                              id="FileInput1"
                              name="booking_attachment1"
                              type="file"
                              accept=".png,.jpg"
                              onChange={(e) => {
                                setImages(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                setBanner3(e.target.files[0]);
                              }}
                              style={{ opacity: "0" }}
                            />
                          </label>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="col-6">
                    <div className="row">
                      <div className="col-6 container"></div>
                      <div className="col-6 container"></div>
                    </div>
                  </div> */}
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6"></div>
                      <div className="col-6"></div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 mb-4 text-center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => HandleProcess2()}
                    style={{
                      width: "90%",
                      background: "#3572FA",
                      boxShadow: "0px 2px 8px #B0BEEF",
                      borderRadius: "10px",
                      padding: "15px",
                    }}
                  >
                    Proceed
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-3"></div>
        </div>
      </div>

      {/* <!--form  3--> */}
      <div className="" style={{ display: count === 3 ? "block" : "none" }}>
        <div
          className=""
          onClick={() => {
            setcount(count - 1);
          }}
        >
          <IoArrowBackOutline
            style={{
              marginLeft: "17px",
              fontSize: "1.5rem",
              marginTop: "1rem",
              cursor: "pointer",
            }}
          />
        </div>
        <div className="row">
          <div className="col-4"></div>
          <div className="col-4">
            <div
              className="card mt-5 ps-3"
              style={{
                border: "none",
                width: "100%",
                background: "#FFFFFF",
                boxShadow: "0px 4px 12px rgba(158, 158, 158, 0.25)",
                borderRadius: "10px",
              }}
            >
              <form className="mt-4">
                <div>
                  <label
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "20px",
                      color: "#414141",
                    }}
                  >
                    Select Course
                  </label>
                  <span style={{ color: "red" }}>*</span>
                  <br></br>
                  <select
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #EDEDED",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "95%",
                    }}
                    onChange={handleSelectChange1}
                  >
                    <option defaultvalue="">--Select Course--</option>
                    {Course?.map((res) => {
                      return (
                        <>
                          <option value={res.id} id={res.course_price}>
                            {res.course_name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="mt-4">
                  <label
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "20px",
                      color: "#414141",
                    }}
                  >
                    Select Batch
                  </label>
                  <span style={{ color: "red" }}>*</span>
                  <br></br>
                  <select
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #EDEDED",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "95%",
                    }}
                    value={BatchId}
                    onChange={(e) => {
                      setBatchId(e.target.value);
                    }}
                  >
                    <option defaultvalue="">--Select Batch--</option>
                    {Batch?.map((res) => {
                      return (
                        <>
                          <option value={res.id}>{res.batch_name}</option>
                        </>
                      );
                    })}
                  </select>
                </div>

                <div className="mt-5 mb-4 text-center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => HandleProcess3()}
                    style={{
                      width: "90%",
                      background: "#3572FA",
                      boxShadow: "0px 2px 8px #B0BEEF",
                      borderRadius: "10px",
                      padding: "15px",
                    }}
                  >
                    Proceed
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-4"></div>
        </div>
      </div>

      {/* <!--form  4--> */}
      <div className="" style={{ display: count === 4 ? "block" : "none" }}>
        <div
          className=""
          onClick={() => {
            setcount(count - 1);
          }}
        >
          <IoArrowBackOutline
            style={{
              marginLeft: "17px",
              fontSize: "1.5rem",
              marginTop: "1rem",
              cursor: "pointer",
            }}
          />
        </div>
        <div className="row">
          <div className="col-3"></div>
          <div className="col-6">
            <div
              className="card mt-5 ps-3"
              style={{
                border: "none",
                width: "100%",
                background: "#FFFFFF",
                boxShadow: "0px 4px 12px rgba(158, 158, 158, 0.25)",
                borderRadius: "10px",
              }}
            >
              <form className="mt-4">
                <div>
                  <label
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "#414141",
                    }}
                  >
                    Enter Father’s Name
                  </label>
                  {/* <span style={{ color: "red" }}>*</span> */}
                  <br></br>

                  <input
                    className="mt-2"
                    type="text"
                    placeholder="Enter Father’s Name"
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #EDEDED",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "70%",
                    }}
                    value={formData.Father}
                    onChange={(e) => handleInputChange("Father", e)}
                  />
                </div>
                <div className="mt-3">
                  <label
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "#414141",
                    }}
                  >
                    Enter Mother’s Name
                  </label>
                  {/* <span style={{ color: "red" }}>*</span> */}
                  <br></br>

                  <input
                    className="mt-2"
                    type="text"
                    placeholder="Enter Mother’s Name"
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #EDEDED",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "70%",
                    }}
                    value={formData.Mother}
                    onChange={(e) => handleInputChange("Mother", e)}
                  />
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <label
                      style={{
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#414141",
                      }}
                    >
                      Enter Guardian’s Aadhar No.
                    </label>
                    {/* <span style={{ color: "red" }}>*</span> */}
                    {/* <span style={{ color: "black" }}> (Optional)</span> */}
                    <br></br>
                    {/* <input className='mt-2' type="number" placeholder='Enter Guardian’s Aadhar No.' style={{ background: "#FFFFFF", border: "1px solid #EDEDED", borderRadius: "10px", padding: "10px", width: "95%", }} value={formData.Guardian} onChange={e => handleInputChange('Guardian', e)} /> */}

                    <input
                      className="mt-2"
                      type="number"
                      placeholder="Enter Guardian’s Aadhar No."
                      inputMode="numeric"
                      pattern="[0-12]*"
                      value={formData.Guardian}
                      style={{
                        background: "#FFFFFF",
                        border: "1px solid #EDEDED",
                        borderRadius: "10px",
                        padding: "10px",
                        width: "95%",
                      }}
                      onChange={(e) =>
                        handleInputChange2("Guardian", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-6">
                    <p
                      style={{
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#414141",
                      }}
                    >
                      Upload Aadhar photo
                      {/* <span style={{ color: "red" }}>*</span> */}
                      {/* <span style={{ color: "red" }}> (Optional)</span> */}
                      <span style={{ color: "red" }}> (pdf)</span>
                    </p>
                    <div className="row">
                      <div className="col-8">
                        <div
                          className="mt-3 px-3"
                          style={{ width: "100%", height: "auto" }}
                        >
                          <label
                            className="filelabel1 w-100 "
                            style={{
                              background: "#FFFFFF",
                              border: "1px solid #B0B0B0",
                              borderRadius: "5px",
                              color: "#000000",
                              height: "30px",
                            }}
                          >
                            {Banner4 <= 1 ? (
                              <span className="title1">
                                <h6
                                  className="d-flex mt-1"
                                  src={Images}
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                    color: "#C5C5C5",
                                    fontSize: "13px",
                                  }}
                                >
                                  {" "}
                                  <FiUpload
                                    className="mx-4"
                                    style={{
                                      color: "#B0B0B0",
                                      fontSize: "20px",
                                    }}
                                  />{" "}
                                  Upload pdf{" "}
                                </h6>
                              </span>
                            ) : (
                              <span
                                className="ms-2"
                                style={{ color: "blue", height: "200px" }}
                              >
                                {Banner4.name.split("", 10)}..
                              </span>
                            )}
                            <input
                              className="FileUpload1"
                              id="FileInput1"
                              name="booking_attachment1"
                              type="file"
                              accept=".pdf"
                              onChange={(e) => {
                                setImages(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                setBanner4(e.target.files[0]);
                              }}
                              style={{ opacity: "0" }}
                            />
                          </label>
                        </div>
                      </div>
                      {/* <div className="col-6">
                        <div
                          className="mt-3 px-3"
                          style={{ width: "100%", height: "auto" }}
                        >
                          <label
                            className="filelabel1 w-100 "
                            style={{
                              background: "#FFFFFF",
                              border: "1px solid #B0B0B0",
                              borderRadius: "5px",
                              color: "#000000",
                              height: "30px",
                            }}
                          >
                            {Banner5 <= 1 ? (
                              <span className="title1">
                                <h6
                                  className="d-flex mt-1"
                                  src={Images}
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                    color: "#C5C5C5",
                                    fontSize: "13px",
                                  }}
                                >
                                  {" "}
                                  <FiUpload
                                    className="mx-4"
                                    style={{
                                      color: "#B0B0B0",
                                      fontSize: "20px",
                                    }}
                                  />{" "}
                                  Back{" "}
                                </h6>
                              </span>
                            ) : (
                              <span
                                className="ms-2"
                                style={{ color: "blue", height: "200px" }}
                              >
                                {Banner5.name.split("", 10)}...
                              </span>
                            )}
                            <input
                              className="FileUpload1"
                              id="FileInput1"
                              name="booking_attachment1"
                              type="file"
                              accept=".png,.jpg"
                              onChange={(e) => {
                                setImages(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                setBanner5(e.target.files[0]);
                              }}
                              style={{ opacity: "0" }}
                            />
                          </label>
                        </div>
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="col-6"></div>
                      <div className="col-6"></div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">
                    {/*   <label style={{ fontStyle: "normal", fontWeight: "600", fontSize: "16px", color: "#414141", }}>Enter Guardian Bank Account No.</label><span style={{ color: "red" }}>*</span><br></br>
                          <input className='mt-2' type="number" placeholder='Enter Guardian Bank Account No.' style={{ background: "#FFFFFF", border: "1px solid #EDEDED", borderRadius: "10px", padding: "10px", width: "95%", }} value={formData.Bank} onChange={e => handleInputChange('Bank', e)} />*/}

                    <label
                      style={{
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#414141",
                      }}
                    >
                      Enter Guardian Bank Account No.
                    </label>
                    {/* <span style={{ color: "red" }}>*</span> */}
                    {/* <span style={{ color: "red" }}> (Optional)</span> */}
                    <br></br>
                    <input
                      className="mt-2"
                      type="number"
                      placeholder="Enter Guardian Bank Account No."
                      inputMode="numeric"
                      pattern="[0-9]*"
                      value={formData.Bank}
                      style={{
                        background: "#FFFFFF",
                        border: "1px solid #EDEDED",
                        borderRadius: "10px",
                        padding: "10px",
                        width: "95%",
                      }}
                      onChange={(e) =>
                        handleInputChange4("Bank", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-6">
                    <label
                      style={{
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#414141",
                      }}
                    >
                      Enter IFSC Code
                    </label>
                    {/* <span style={{ color: "red" }}>*</span> */}
                    {/* <span style={{ color: "red" }}> (Optional)</span> */}
                    <br></br>
                    <input
                      className="mt-2"
                      type="text"
                      placeholder="Enter IFSC Code."
                      style={{
                        background: "#FFFFFF",
                        border: "1px solid #EDEDED",
                        borderRadius: "10px",
                        padding: "10px",
                        width: "95%",
                      }}
                      value={formData.IFSC}
                      onChange={(e) => handleInputChange("IFSC", e)}
                    />
                  </div>
                </div>
                <div className="mt-4 mb-4 text-center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => HandleProcess4()}
                    style={{
                      width: "90%",
                      background: "#3572FA",
                      boxShadow: "0px 2px 8px #B0BEEF",
                      borderRadius: "10px",
                      padding: "15px",
                    }}
                  >
                    Add Student
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-3"></div>
        </div>
      </div>

      {/* <!--form  5--> */}
      <div className="px-4" style={{ display: count === 5 ? "block" : "none" }}>
        <div>


          <div className="">
            <p style={{ textAlign: "center", color: "#3C3C3C", fontSize: "35px", fontWeight: "600" }}>Student Added Successfully!!</p>
          </div>

          <Lottie animationData={lotti6} className="d-grid gap-2 col-6 mx-auto" style={{ width: "25%" }} />

          <div className="">
            <NavLink to="/Students" style={{ textDecoration: "none", }}><button className="d-grid gap-2 col-6" style={{
              marginTop: "70px", width: "50%", padding: "15px", fontSize: "18px", marginLeft: "25%",
              backgroundColor: "#8147E7", border: "none", color: "#fff", boxShadow: " 0px 4px 12px rgba(126, 57, 247, 0.36)", fontWeight: "500", borderRadius: "5px"
            }} onClick={() => { Navigate(-1) }}>Back to Students</button></NavLink>
          </div>
          <div className="mt-3"></div>


          {/* <div className="row">
            <div className="col-6" style={{ overflow: "hidden" }}>
              <h3
                className="mt-4"
                style={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "30px",
                  color: "#000000",
                }}
              >
                <IoArrowBack
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setcount(count - 1);
                  }}
                />{" "}
                Setup Payment
              </h3>
              <div
                className="ps-3 mt-4"
                style={{
                  border: "1px solid #000000",
                  borderRadius: "10px",
                  width: "30%",
                  padding: "15px",
                }}
              >
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "20px",
                    display: "flex",
                    alignItems: "center",
                    color: "#000000",
                  }}
                >
                  Grand Total :
                </p>
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "25px",
                    display: "flex",
                    alignItems: "center",
                    color: "#000000",
                  }}
                >
                  ₹ {TotalAmount}{" "}
                </p>
              </div>

              <div className="row mt-4">
                <div className="col-4">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      checked={duedate == 0}
                      onClick={() => {
                        setDuedate(0);
                      }}
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      onChange={() => {
                        setBarr("show");
                      }}
                    />
                    <label
                      class="form-check-label"
                      for="flexRadioDefault "
                      style={{
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "20px",
                        color: "#000000",
                      }}
                    >
                      Pay in EMIs
                    </label>
                  </div>
                </div>

                <div className="col-6">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      onClick={() => setDuedate(1)}
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      onChange={() => {
                        setTabled(0);
                        setBarr("hiden");
                      }}
                    />
                    <label
                      class="form-check-label"
                      for="flexRadioDefault1"
                      style={{
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "20px",
                        color: "#000000",
                      }}
                    >
                      Pay in One Shot
                    </label>
                  </div>
                </div>
              </div>

              <div style={{ display: duedate == 0 ? "block" : "none" }}>
                <div className="row">
                  <div className="col-6">
                    <p
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        color: "#000000",
                        marginTop: 20,
                      }}
                    >
                      Discount Amount
                    </p>
                    <div className="d-flex mt-2 ">
                      <input
                        type="number"
                        placeholder="Enter Amount"
                        style={{
                          width: "80%",
                          background: "#E0E0E0",
                          borderRadius: "10px",
                          border: "none",
                          padding: "15px",
                        }}
                        value={formData.Discount}
                        onChange={(e) => {
                          handleInputChange3("Discount", e.target.value);
                          handleDummyAmount(e.target.value);
                        }}
                      />
                      <p
                        className="mt-1"
                        style={{
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "28px",
                          color: "#000000",
                          marginLeft: "10px",
                        }}
                      >
                        RS
                      </p>
                    </div>
                  </div>

                  <div className="col-6">
                    <p
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                        color: "#000000",
                        marginTop: 20,
                      }}
                    >
                      EMI Count<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      type="number"
                      className="mt-2"
                      placeholder="Number of EMIs"
                      style={{
                        width: "80%",
                        background: "#E0E0E0",
                        borderRadius: "10px",
                        border: "none",
                        padding: "12px",
                      }}
                      value={formData.EMI}
                      onChange={(e) => handleInputChange("EMI", e)}
                    />
                  </div>

                  <div className="col-3 mx-3">
                    <div>
                      <p
                        style={{
                          marginTop: "20px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "18px",
                          color: "#000000",
                        }}
                      >
                        Start Date<span style={{ color: "red" }}>*</span>
                      </p>
                      <span
                        className="mt-2 d-flex"
                        style={{
                          borderRadius: "10px",
                          width: "170px",
                          height: "45px",
                          backgroundColor: "#E0E0E0",
                          textAlign: "center",
                          color: "#7A7A7A",
                          padding: "11px",
                        }}
                      >
                        <FiCalendar style={{ fontSize: "1.5rem" }} />
                        <DatePicker
                          className="border-0  datepAdd "
                        
                          selected={startDate}
                          onChange={(date) => {
                            setStartDate(date);
                            setSedule(
                              date.getFullYear() +
                              "/" +
                              (parseInt(date.getMonth()) + 1) +
                              "/" +
                              date.getDate()
                            );
                          }}
                          style={{
                            backgroundColor: "#E0E0E0",
                            outline: "0",
                            width: "15px",
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-3 mx-3">
                    <div>
                      <p
                        style={{
                          marginTop: "20px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "18px",
                          color: "#000000",
                        }}
                      >
                        End Date<span style={{ color: "red" }}>*</span>
                      </p>
                      <span
                        className="mt-2 d-flex"
                        style={{
                          borderRadius: "10px",
                          width: "170px",
                          height: "45px",
                          backgroundColor: "#E0E0E0",
                          textAlign: "center",
                          color: "#7A7A7A",
                          padding: "11px",
                        }}
                      >
                        <FiCalendar style={{ fontSize: "1.5rem" }} />
                        <DatePicker
                          className="border-0  datepAdd"
                  
                          selected={startDate1}
                          onChange={(date) => {
                            setStartDate1(date);
                            setSedule1(
                              date.getFullYear() +
                              "/" +
                              (parseInt(date.getMonth()) + 1) +
                              "/" +
                              date.getDate()
                            );
                          }}
                          style={{ backgroundColor: "#E0E0E0", outline: "0" }}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="col-2 mt-5">
                    <button
                      onClick={() => {
                        // setTabled(1);
                        // setTabled(1);
                        // AddStudent(false);
                        HandleProcess7();
                      }}
                      className="btn btn-outline-dark mx-5"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>

              <div
                className=""
                style={{ display: duedate == 1 ? "block" : "none" }}
              >
                <div className="col-4">
                  <p
                    style={{
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "18px",
                      color: "#000000",
                      marginTop: 20,
                    }}
                  >
                    Discount Amount
                  </p>
                  <div className="d-flex mt-2 ">
                    <input
                      type="text"
                      placeholder="Enter Amount"
                      style={{
                        width: "80%",
                        background: "#E0E0E0",
                        borderRadius: "10px",
                        border: "none",
                        padding: "15px",
                      }}
                      value={formData.Discount}
                      onChange={(e) => {
                        handleInputChange("Discount", e);
                        handleDummyAmount(e.target.value);
                      }}
                    />
                    <p
                      className="mt-1"
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "28px",
                        color: "#000000",
                        marginLeft: "10px",
                      }}
                    >
                      RS
                    </p>
                  </div>
                </div>

                <div>
                  <p
                    style={{
                      marginTop: "20px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "18px",
                      color: "#000000",
                    }}
                  >
                    Due Date<span style={{ color: "red" }}>*</span>
                  </p>
                  <span
                    className="mt-2 d-flex"
                    style={{
                      borderRadius: "10px",
                      width: "180px",
                      height: "45px",
                      backgroundColor: "#E0E0E0",
                      textAlign: "center",
                      color: "#7A7A7A",
                      padding: "11px",
                    }}
                  >
                    <FiCalendar />
                    &nbsp;
                    <DatePicker
                      className="border-0  datepAdd"
            
                      selected={startDate2}
                      onChange={(date) => {
                        setStartDate2(date);
                        setSedule2(
                          date.getFullYear() +
                          "/" +
                          (parseInt(date.getMonth()) + 1) +
                          "/" +
                          date.getDate()
                        );
                      }}
                      style={{ backgroundColor: "#E0E0E0", outline: "0" }}
                    />
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-8">
                  <div className="mt-5 mb-4 text-center">
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={() => {
                        duedate == 0 ? HandleProcess5() : HandleProcess6();
                      }}
                      style={{
                        width: "100%",
                        background: "#3572FA",
                        boxShadow: "0px 2px 8px #B0BEEF",
                        borderRadius: "10px",
                        padding: "15px",
                        border: "none",
                      }}
                    >
                      Proceed To Payment
                    </button>
                  </div>
                </div>
                <div className="col-4"></div>
              </div>
            </div>

            <div className="col-6">
              <div style={{ display: tabled == 1 ? "block" : "none" }}>
                <div>
                  <div className="card" style={{ width: "80%" }}>
                    <div className="card-body">
                      <div className="d-flex">
                        <p
                          style={{
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#000000",
                          }}
                        >
                          S.No
                        </p>
                        <p
                          style={{
                            marginLeft: "6rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#000000",
                          }}
                        >
                          Remarks
                        </p>
                        <p
                          style={{
                            marginLeft: "6rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#000000",
                          }}
                        >
                          Amount
                        </p>
                        <p
                          style={{
                            marginLeft: "6rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#000000",
                          }}
                        >
                          Due Date
                        </p>
                      </div>
                      <div style={{ overflowY: "scroll", height: "70vh" }}>
                        {Emis?.map((el, ind) => {
                          return (
                            <>
                              <div
                                key={el.emi_number}
                                className="d-flex mt-4"
                                style={{
                                  borderRadius: "10px",
                                  border: "1px solid #ECECEC",
                                  padding: "10px",
                                }}
                              >
                                <p
                                  style={{
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    color: "#000000",
                                  }}
                                >
                                  {el.emi_number}
                                </p>
                                <p
                                  style={{
                                    marginLeft: "6rem",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    color: "#000000",
                                  }}
                                >
                                  Tution fee
                                </p>
                                <p
                                  style={{
                                    marginLeft: "6rem",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    color: "#000000",
                                  }}
                                >
                                  {el.emi_amount}
                                </p>
                                <p
                                  style={{
                                    marginLeft: "6rem",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    color: "#000000",
                                  }}
                                >
                                  {el.emi_date}
                                </p>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>

                    {/* <ToastContainer position="top-end" className="p-3" >
                      <Toast onClose={() => setShowLoader(false)} show={ShowLoader} delay={8000} autohide style={{ backgroundColor: "#000" }}>
                        <Toast.Header>
                          <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                          />
                          <strong className="me-auto">error</strong>
                          <small>1 mins ago</small>
                        </Toast.Header>
                        <Toast.Body className="text-white">{message}</Toast.Body>
                      </Toast>
                    </ToastContainer> 
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* <!--form  6--> */}
      <div className="px-5" style={{ display: count === 6 ? "block" : "none" }}>
        <div className="row mt-3">
          <div className="col-6">
            <p
              className=""
              style={{
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "25px",
                color: "#000000",
              }}
              onClick={() => {
                setcount(count - 1);
                AddStudent(false);
              }}
            >
              {/* <IoArrowBack />  */}
              Preview
            </p>
            <div>
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "red",
                }}
              >
                *If you filled any incorrect data please fill the form again
              </p>
            </div>
            <div className="row mt-2">
              <div className="col-4">
                <div
                  style={{
                    border: "1px solid #000000",
                    borderRadius: "10px",
                    padding: "13px",
                    width: "90%",
                  }}
                >
                  <p
                    style={{
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "18px",
                      display: "flex",
                      alignItems: "center",
                      color: "#000000",
                    }}
                  >
                    Amount to Be Paid
                  </p>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "25px",
                      display: "flex",
                      alignItems: "center",
                      color: "#000000",
                    }}
                  >
                    ₹ {duedate == 1 ? dummy_amount : final}
                  </p>
                </div>
              </div>
              <div className="col-4">
                <div
                  style={{
                    border: "1px solid #000000",
                    borderRadius: "10px",
                    padding: "13px",
                    width: "90%",
                  }}
                >
                  <p
                    style={{
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "18px",
                      display: "flex",
                      alignItems: "center",
                      color: "#000000",
                    }}
                  >
                    Grand Total :
                  </p>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "25px",
                      display: "flex",
                      alignItems: "center",
                      color: "#000000",
                    }}
                  >
                    ₹ {TotalAmount}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#000000",
                }}
              >
                To
              </p>
              <p
                className="mt-1"
                style={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "24px",
                  color: "#000000",
                }}
              >
                {formData.name}
              </p>
              <p
                className="mt-1"
                style={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "18px",
                  color: "#000000",
                }}
              >
                {formData.address}{" "}
              </p>
              <p
                className="mt-1"
                style={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "20px",
                  color: "#000000",
                }}
              >
                +91 {formData.contact}
              </p>
              <div style={{ display: duedate === 1 ? "block" : "none" }}>
                <p
                  className="mt-3"
                  style={{
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "20px",
                    color: "#000000",
                  }}
                >
                  Due Date
                </p>
                <p
                  className="mt-1"
                  style={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "20px",
                    color: "#000000",
                  }}
                >
                  <IoCalendarOutline /> {Sedule2}
                </p>
              </div>
              <div className="d-flex mt-2">
                <input type="checkbox" />
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#000000",
                  }}
                >
                  {" "}
                  Setup eNACH
                </p>
              </div>

              <div className="mt-3">
                <button
                  type="button"
                  class="btn "
                  onClick={() => {
                    setcount(count + 1);
                    AddStudent(true);
                  }}
                  style={{
                    width: "70%",
                    padding: "10px",
                    background: "#5A81FE",
                    boxShadow: "0px 2px 8px rgba(90, 129, 254, 0.25)",
                    borderRadius: "5px",
                    color: "#fff",
                    border: "none",
                    fontSize: "18px",
                  }}
                >
                  Proceed To Payment
                </button>
              </div>
            </div>
          </div>
          {/* <div> */}
          <div
            style={{ display: tabled == 1 ? "block" : "none" }}
            className="col-6"
          >
            <p
              style={{
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "22px",
                color: "#000000",
              }}
            >
              Payment Schedule
            </p>

            <div>
              <div>
                <div className="card" style={{ width: "80%" }}>
                  <div className="card-body">
                    <div className="d-flex">
                      <p
                        style={{
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#000000",
                        }}
                      >
                        S.No
                      </p>
                      <p
                        style={{
                          marginLeft: "6rem",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#000000",
                        }}
                      >
                        Remarks
                      </p>
                      <p
                        style={{
                          marginLeft: "6rem",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#000000",
                        }}
                      >
                        Amount
                      </p>
                      <p
                        style={{
                          marginLeft: "6rem",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#000000",
                        }}
                      >
                        Due Date
                      </p>
                    </div>
                    <div style={{ overflowY: "scroll", height: "70vh" }}>
                      {Emis?.map((el, ind) => {
                        return (
                          <>
                            <div
                              key={el.emi_number}
                              className="d-flex mt-4"
                              style={{
                                borderRadius: "10px",
                                border: "1px solid #ECECEC",
                                padding: "10px",
                              }}
                            >
                              <p
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "12px",
                                  color: "#000000",
                                }}
                              >
                                {el.emi_number}
                              </p>
                              <p
                                style={{
                                  marginLeft: "6rem",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "12px",
                                  color: "#000000",
                                }}
                              >
                                Tution fee
                              </p>
                              <p
                                style={{
                                  marginLeft: "6rem",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "12px",
                                  color: "#000000",
                                }}
                              >
                                {el.emi_amount}
                              </p>
                              <p
                                style={{
                                  marginLeft: "6rem",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "12px",
                                  color: "#000000",
                                }}
                              >
                                {el.emi_date}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--form  7--> */}

      <div className="" style={{ display: count === 7 ? "block" : "none" }}>
        <div className="row">
          <div className="col-2"></div>

          <div className="col-4">
            <div className="">
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "30px",
                  color: "#000000",
                }}
                onClick={() => {
                  setcount(count - 1);
                }}
              >
                {/*<IoArrowBack style={{ cursor: "pointer" }} />*/}
                Payment
              </p>
              <p
                className="mt-4"
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "30px",
                  color: "#000000",
                }}
              >
                Payment Link Sent to <br></br> {formData.name} !
              </p>
              <div className="mt-5">
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "20px",
                    color: "#000000",
                  }}
                >
                  Payment Link
                </p>
                <div
                  className="d-flex"
                  style={{
                    border: "1px solid #565656",
                    width: "32rem",
                    padding: "5px 3px",
                    borderRadius: "5px",
                  }}
                >
                  <p>Coming Soon</p>
                  <p style={{ marginLeft: "10px" }}>
                    <IoInfiniteOutline />
                  </p>
                </div>
              </div>
              <div className="mt-5">
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "20px",
                    color: "#000000",
                  }}
                >
                  Remark
                </p>
                <div className="">
                  {/*<textarea name="postContent" value={remark} onChange={(e) => setRemark(remark)} style={{ border: "1px solid #565656", width: "32rem", padding: "5px 3px", borderRadius: "5px", }} />
                    <p style={{ float: "right", fontStyle: "normal", fontWeight: "300", fontSize: "13px", color: "#565656", }}>{remark.length}/100</p>*/}

                  <textarea
                    name="postContent"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                    style={{
                      border: "1px solid #565656",
                      width: "32rem",
                      padding: "5px 3px",
                      borderRadius: "5px",
                    }}
                  />
                  <p
                    style={{
                      float: "right",
                      fontStyle: "normal",
                      fontWeight: "300",
                      fontSize: "13px",
                      color: "#565656",
                    }}
                  >
                    {remark.length}/100
                  </p>
                </div>
              </div>
              <div className="mt-5">
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "20px",
                    color: "#000000",
                  }}
                >
                  Contact Number
                </p>
                <p
                  className="mt-2"
                  style={{
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "25px",
                    color: "#000000",
                  }}
                >
                  +91 {formData.contact}
                </p>
              </div>
            </div>
          </div>
          <div className="col-1"></div>
          <div className="col-4">
            <div
              className=""
              style={{
                border: "1px solid #000000",
                borderRadius: "10px",
                width: "50%",
                padding: "15px",
              }}
            >
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "20px",
                  color: "#000000",
                }}
              >
                Amount to Be Paid
              </p>
              <p>₹ {duedate == 1 ? onefinal : final}</p>
            </div>

            <p className="mt-5">Due Date</p>
            <p className="mt-2">
              <IoCalendarOutline /> {duedate == 1 ? Sedule2 : Sedule1}
            </p>
          </div>
          <div className="col-1"></div>
        </div>
        <div className="text-center mt-1">
          <button
            type="button"
            class="btn "
            style={{
              background: "#5A81FE",
              boxShadow: "0px 4px 12px rgba(90, 129, 254, 0.25)",
              borderRadius: "5px",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              textAlign: "center",
              color: "#FFFFFF",
              padding: "15px 70px",
              border: "none",
            }}
            onClick={() => Remarks()}
          >
            Back To Dashboard
          </button>
        </div>
      </div>
    </div>
  );
}
