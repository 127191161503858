import React, { useState, useEffect, useContext } from 'react';
import ViewTK from './ViewTK';
import ViewPF from './ViewPF';
import { BsArrowLeft } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';



const Employeeprofile = () => {

  const [value20,setvalue20] = useState("ViewPF");


  return (
    <>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-2 bg-white" style={{ backgroundColor: "#fff", height: "100vh", }}>
            <p className='ms-4  ' style={{ fontWeight: "600", color: "#3C3C3C", fontSize: "25px", marginTop: "40px" }}>  <NavLink to="/Team" className="" style={{ zIndex: "999", }}>
              <BsArrowLeft className='ps-1' style={{ color: '#000000', fontSize: "25px", cursor: "pointer" }} />
            </NavLink> View Profile</p>
            <span className='mt-4' onClick={() => { setvalue20('ViewPF') }}
              style={{ display: "flex", backgroundColor: value20 == "ViewPF" ? "#F4F2F9" : "", border: "none", marginLeft: "30px", height: "45px", borderRadius: "5px", color: "#8A8A8A" }}>
              <p className='pt-2' style={{ zIndex: '999', paddingLeft: "10px", color: value20 === "ViewPF" ? "#8147E7" : "", fontWeight: "400", cursor: "pointer" }}>Profile</p></span>

            <span className='mt-3' onClick={() => { setvalue20('ViewTK') }}
              style={{ display: "flex", backgroundColor: value20 == "ViewTK" ? "#F4F2F9" : "", marginLeft: "30px", height: "45px", borderRadius: "5px", color: "#8A8A8A" }}>
              <p className='pt-2' style={{ zIndex: '999', paddingLeft: "10px", color: value20 === "ViewTK" ? "#8147E7" : "", fontWeight: "400", cursor: "pointer" }}>Task</p></span>

            
          </div>





          <div className="col-10" style={{ background: "rgb(240 245 254)", }}>
            {
              value20 === 'ViewPF' ? <><ViewPF/></> : <></>
            }
            {
              value20 === 'ViewTK' ? <><ViewTK/></> : <></>
            }
          </div>
        </div>



      </div>
    </>
  );
}

export default Employeeprofile;
