import { TripleMaze } from 'react-spinner-animated';

import 'react-spinner-animated/dist/index.css'

export const Loader = ({ show }) => {
    return (
        <>
            {
                show && (
                    <div className='row' >
                        <TripleMaze text={"Loading..."}
                            center={false} width="100%" height="100%" />
                    </div>
                )
            }
        </>
    )
}