import React, { useState } from 'react';
import Header from './header';
import SideBar from './sidebar';
import Unassigned from './Unassigned';
import AssignedtoMe from './AssignedtoMe';
import Solved from './Solved';
import { useRequireAuth } from './RequireAuth';


export default function Support() {
    const [value, setvalue] = useState('Unassigned');
   // useRequireAuth('/code');
    return (
        <div style={{ width: '100%', height: '100vh', }}>
            <div className='head' style={{ width: '80%', height: '14vh', marginLeft: '16%' }}>
                <Header />
            </div>
            <div style={{ width: '100%', height: '86vh', display: "flex", }}>
                <div style={{ position: 'absolute', top: 0, marginLeft: '1%' }} >
                    <SideBar />
                </div>
                <div className='maincontain' style={{ marginLeft: '15%', width: '100%', height: '100%', }} >
                    <div className='ps-5 mt-2'>
                        <p style={{ fontStyle: "normal", fontWeight: "600", fontSize: "20px", color: "#434343", }}>Tickets</p>
                    </div>
                    <div className='d-flex mt-3' style={{ borderBottom: "2px solid #DDD7EA" }}>


                        <div className='' style={{ marginLeft: "3rem" }}>
                            <p onClick={() => { setvalue("Unassigned") }} style={{ borderBottom: value == "Unassigned" ? "2px solid #8147E7" : "", color: value == "Unassigned" ? " #8147E7" : "#B1AFC8", fontStyle: "normal", fontWeight: "400", fontSize: "14px", cursor: "pointer", }}>Unsolved</p>
                        </div>
                        {/* <div className='' style={{ marginLeft: "2.3rem" }}>
                            <p onClick={() => { setvalue("AssignedtoMe") }} style={{ borderBottom: value == "AssignedtoMe" ? "2px solid #8147E7" : "", color: value == "AssignedtoMe" ? " #8147E7" : "#B1AFC8", fontStyle: "normal", fontWeight: "400", fontSize: "14px", cursor: "pointer", }}>Assigned to Me</p>
                        </div> */}
                        <div className='' style={{ marginLeft: "2.3rem" }}>
                            <p onClick={() => { setvalue("Solved") }} style={{ borderBottom: value == "Solved" ? "2px solid #8147E7" : "", color: value == "Solved" ? " #8147E7" : "#B1AFC8", fontStyle: "normal", fontWeight: "400", fontSize: "14px", cursor: "pointer", }}>Solved</p>
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            {
                                value == "Unassigned" ? <><Unassigned /></> : <></>
                            }
                            {
                                value == "AssignedtoMe" ? <><AssignedtoMe /></> : <></>
                            }
                            {
                                value == "Solved" ? <><Solved /></> : <></>
                            }



                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}


