import React, { useState, useEffect,useContext} from 'react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { API_URL, img_url } from "../env";
import { AuthContext } from "./AuthContext";
import { NavLink } from 'react-router-dom'

import { BsFillCalendarFill } from "react-icons/bs";

export default function Transactions() {
    const [startDate, setStartDate] = useState(new Date());
    const [startDate1, setStartDate1] = useState(new Date());
    const [cre, setcre] = useState([])
    const {  apiToken } = useContext(AuthContext);


      
    const Api = ()=>{
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${apiToken}`);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(`${API_URL}/student_transaction`, requestOptions)
        .then(response => response.json())
        .then(result => {console.log(result);
           if(result.status=="001"){
            setcre(result.students_transactions)
           }
        })
        .catch(error => console.log('error', error));
    }



    useEffect(() => {
       Api();
    }, [])
    



    return (
        <>


            {/* button */}

            <div className='row'>
                <div className='col-2 ' >
                    <div style={{ display: "flex" }}>
                        <p className='pt-3 ' style={{ color: "#434343", fontWeight: "500", fontSize: "17px", paddingLeft: "40px" }} >From</p>
                        <span style={{
                            display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "10px",
                            borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
                        }}>
                            <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
                            <div>
                                <DatePicker className="border-0  ps-2 pt-1 datep"
                                    showIcon
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                            </div>
                        </span>
                        <p className='pt-3 ps-1' style={{ color: "#434343", fontWeight: "500", fontSize: "17px" }}>to</p>
                    </div>
                </div>


                <div className='col-2' style={{ marginLeft: "50px" }} >
                    <div>

                        <span style={{
                            display: 'flex', backgroundColor: '#EDEDF5', padding: '', marginLeft: "10px",
                            borderRadius: '5px', color: '#b5b5b5', fontSize: '13px', width: '150px', height: "37px"
                        }}>
                            <BsFillCalendarFill className='ms-2' style={{ fontSize: '18px', color: "#3C3C3C", marginTop: "8px", }} />
                            <div>
                                <DatePicker className="border-0  ps-2 pt-1  datep"
                                    showIcon
                                    selected={startDate1}
                                    onChange={(date) => setStartDate1(date)}
                                    style={{ backgroundColor: '#E0E0E0', outline: "0", }} />
                            </div>
                        </span>
                    </div>
                </div>

                <div className='col-5 ms-5 text-end'>
                    <NavLink to="/Makepayment">  <button className='' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightm"
                        aria-controls="offcanvasRight" style={{ border: "1px solid #B63A3A", backgroundColor: "#FFFFFF", color: "#B63A3A", width: "150px", height: "38px", borderRadius: "5px" }} >Make Payment</button></NavLink>
                </div>
                 {/*<div className='col-1 text-end'  >
                    <NavLink to="/ReportRequest">  <button className='' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRighti" aria-controls="offcanvasRight" style={{
                        border: "1px solid #3AB690",
                        backgroundColor: "#FFFFFF", color: "#3AB690", width: "150px", height: "38px", borderRadius: "5px"
                    }}>Request Payment</button></NavLink>
                </div>*/}

                <div className='col-2'>

                </div>

            </div>


            {/* make payment */}

            {/* end */}






            {/* table */}
            <div>

                {/*<div className='mt-4 '>
                    <div className='row mx-4 mt-3'  >
                        <div style={{ color: "#434343", fontWeight: "600", fontSize: "14px", display: "flex" }}>

                            <p className='ps-2'>S.No.</p>


                            <p className='ps-4'>Name</p>


                            <p style={{ paddingLeft: "135px" }}>Date&Time</p>

                            <p style={{ paddingLeft: "55px" }}>Transaction ID</p>

                            <p style={{ paddingLeft: "55px" }}>Amount</p>
                            <p style={{ paddingLeft: "55px" }}>Phone Number</p>


                            <p style={{ paddingLeft: "55px" }}>Payment Method</p>


                            <p className='' style={{ paddingLeft: "55px" }}>Status</p>

                        </div>

                        <div style={{ overflowY: "scroll", height: "60vh" }}>
                            {cre.length>0?cre?.map((res, key) => {
                                return (
                                    <>
                                        <div className='mt-3 ' style={{ display: "flex", border: "1px solid #DDDDDD", width: "98.5%", borderRadius: "5px", paddingTop: "10px", height: "45px", backgroundColor: " #FFFFFF" }} >

                                            <p className='ps-2' key={key} style={{ color: "#434343", fontWeight: "400", fontSize: "14px" }}>{key+1}</p>



                                            <p className='ps-5' style={{ fontWeight: "400", fontSize: "14px" }}>{res.name}</p>


                                            <p style={{ fontSize: "14px", color: "#434343", fontWeight: "400", paddingLeft: "115px" }} >{res.datetime}</p>


                                            <p style={{ fontSize: "14px", color: "#434343", fontWeight: "400", paddingLeft: "55px" }} >{res.transaction}</p>

                                            <p style={{ fontSize: "14px",  fontWeight: "500", paddingLeft: "60px", color: res.status === 0 ? "#40997E" : res.status == 1 ? "#F93333" : "#e2a925", }} >{res.amount}</p>


                                            <p style={{ fontSize: "14px", color: "#434343", fontWeight: "400", paddingLeft: "50px" }} >{res.phone}</p>


                                            <p style={{ fontSize: "14px", color: "#434343", fontWeight: "400", paddingLeft: "53px" }} >{res.payment}</p>


                                            <p style={{ fontSize: "14px", color: " #2E8760", fontWeight: "600", paddingLeft: "150px" }} >{res.stat}</p>


                                        </div>


                                    </>
                                )
                            }):<>
                               <div className="d-flex mt-5" style={{justifyContent:"center",color:"red",alignItems:"center"}}><h6>No Data Available</h6></div>
                            </>
                        }
                        </div>

                    </div>
                    </div>*/}

                    <div className='mt-4 '>
                    <div className='row mt-3'  >
                        <div className='row mt-3 '  >
                        <div className='row' style={{ color: "#434343", fontWeight: "600", fontSize: "15px" }}>
                          <div className='col-1 pb-2'>
                            <p>S.No.</p>
                          </div>
                          <div className='col-2 pb-2'>
                            <p>Name</p>
                          </div>
                          <div className='col-2 pb-2' >
                            <p>Date & Time</p>
                          </div>
                          <div className='col-2 pb-2' >
                          <p>Transaction ID</p>
                        </div>
                          <div className='col-2 pb-2'>
                            <p>Amount</p>
                          </div>
                          
                          <div className='col-2 pb-2'>
                            <p>Payment Method</p>
                          </div>
                          <div className='col-1 pb-2'>
                            <p>Status</p>
                          </div>
                        </div>
            
                        <div style={{ height: "60vh" }}>
                          
                          {
                          cre.length === 0 ?<div className="d-flex mt-5" style={{justifyContent:"center",color:"red",alignItems:"center"}}><h6>No Data Available</h6></div>:
                          <div style={{overflowY:"scroll",height:"58vh"}}>
                          {
                          cre?.map((res, index) => {
                            return (
                              <>
                                <div className='row align-items-center  mt-3' style={{ width: "100%", borderRadius: "5px", height: "55px", backgroundColor: " #FFF4F4" }} >
                                  <div className='col-1' key={res.id} >
                                    <p style={{ color: "#434343", fontWeight: "400", fontSize: "15px" }}>{index+1}</p>
                                  </div>
                                  <div className='col-2'>
                                    <p style={{ fontWeight: "400", fontSize: "15px", textTransform: "capitalize" }}>{res.name}</p>
                                  </div>
                                
                                  <div className='col-2'>
                                    <p style={{ fontSize: "15px", color: "#3C3C3C", fontWeight: "500" }} >{res.txn_date}</p>
                                  </div>
                                  <div className='col-2'>
                                    <p style={{ fontSize: "15px", color: "#434343", fontWeight: "400" }} >{res.ref_id.length>0?res.ref_id:"NA"}</p>
                                  </div>
            
                                 
                                  <div className='col-2'>
                                    <p style={{ fontSize: "15px", color: "#434343", fontWeight: "400" }} >{res.amount}</p>
                                  </div>
                                  <div className='col-2'>
                                  <p style={{ fontSize: "15px", fontWeight: "600", color:"green"}} >{res.txn_method}</p>
                                </div>
                                  <div className='col-1'>
                                    <p style={{ fontSize: "15px", fontWeight: "600", color:"green" }} >{res.txn_status=="1"?"Success":""}</p>
                                  </div>
            
            
                                
            
                                </div>
                              </>
                            )
                          })
                        }
                          </div>
                        }
            
                          
                        </div>
            
                      </div>
                    </div>
                </div>
            </div>
        </>
    )
}

